import { useCallback, useMemo } from 'react'
import { ToastId, useToast as useChakraToast } from '@chakra-ui/react'

import { FlashMessageProps as Props } from 'models'

import { FlashMessageComponent } from 'shared/components/flashMessage'

export const useFlashMessage = () => {
	const chakraToast = useChakraToast()

	const close = useCallback((id: ToastId) => chakraToast.close(id), [chakraToast])

	const show = useCallback(
		({ duration = 3000, isClosable = true, ...rest }: Props) => {
			chakraToast({
				id: Math.random() + Date.now(),
				position: 'top',
				duration,
				render: function renderToastComponent({ onClose }) {
					return <FlashMessageComponent {...rest} onClose={onClose} isClosable={isClosable} />
				},
			})
		},
		[chakraToast],
	)

	const info = useCallback((title: string, options?: Props) => show({ ...options, title, status: 'info' }), [show])
	const warning = useCallback(
		(title: string, options?: Props) => show({ ...options, title, status: 'warning' }),
		[show],
	)
	const success = useCallback(
		(title: string, options?: Props) => show({ ...options, title, status: 'success' }),
		[show],
	)
	const error = useCallback((title: string, options?: Props) => show({ ...options, title, status: 'error' }), [show])

	return useMemo(() => ({ close, show, warning, success, error, info }), [close, error, info, show, success, warning])
}
