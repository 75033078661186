import { MessageAgent } from 'shared/models/Message'
import { apiClient } from 'shared/utils'

import { MessagesFetchResponse, SendMessageRequestData, UploadFinishRequestData } from './types'

const API_RESOURCE = 'v2/conversations'

const fetchMessages = (chatId: string) => {
	return apiClient.get<MessagesFetchResponse>(`${API_RESOURCE}/${chatId}/messages?size=500`)
}

const sendMessage = ({ chatId, messageData }: SendMessageRequestData) => {
	return apiClient.post<MessageAgent>(`${API_RESOURCE}/${chatId}`, messageData)
}

const uploadFinish = ({ chatId, token }: UploadFinishRequestData) => {
	return apiClient.post<MessageAgent>(`${API_RESOURCE}/${chatId}/upload-finish`, { token })
}

export const messagesApi = { fetchMessages, sendMessage, uploadFinish }
