import { SmartsuppWindow } from 'shared/types'
import { generateChatCode } from 'shared/utils'

import { RESOURCE_NAME } from './constants'

declare let window: SmartsuppWindow

export const makeField = (type: string) => {
	return type.slice(0, Math.max(0, type.length - 1))
}

export const makeConfig = (path: string, triggerId: string) => ({
	parent: path,
	resource: {
		liveOnly: false,
		name: RESOURCE_NAME,
		firingTriggerId: [triggerId],
		parameter: [
			{
				key: 'html',
				type: 'template',
				value: generateChatCode(),
			},
			{
				key: 'convertJsValuesToExpressions',
				type: 'boolean',
				value: 'true',
			},
			{
				key: 'usePostscribe',
				type: 'boolean',
				value: 'false',
			},
		],
		type: 'html',
	},
})

export const signOut = () => {
	if (!window.gapi) return

	const authInstance = window.gapi.auth2.getAuthInstance()
	const isSignedIn = authInstance.isSignedIn.get()
	if (isSignedIn) authInstance.signOut()
}
