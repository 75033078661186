import { createStandaloneToast } from '@chakra-ui/react'

import { FlashMessageProps as Props } from 'models'
import { theme } from 'styles/theme'

import { FlashMessageComponent } from '../components/flashMessage'

const { ToastContainer, toast } = createStandaloneToast({ theme })

const showFlashMessage = ({
	id = Math.random() + Date.now(),
	duration = 3000,
	isClosable = true,
	position = 'top',
	...rest
}: Props) => {
	toast({
		id,
		position,
		duration,
		render: function renderToastComponent({ onClose }) {
			return <FlashMessageComponent {...rest} onClose={onClose} isClosable={isClosable} />
		},
	})
}

export const flashMessage = {
	error: (title: string, options?: Props) => showFlashMessage({ ...options, title, status: 'error' }),
	warning: (title: string, options?: Props) => showFlashMessage({ ...options, title, status: 'warning' }),
	success: (title: string, options?: Props) => showFlashMessage({ ...options, title, status: 'success' }),
	info: (title: string, options?: Props) => showFlashMessage({ ...options, title, status: 'info' }),
}

export const FlashMessageContainer = () => <ToastContainer />
