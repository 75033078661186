import tinycolor from 'tinycolor2'

import { WIDGET_GRADIENT_ANGLE } from 'styles'

const RED_MULTIPLIER = 0.299
const GREEN_MULTIPLIER = 0.587
const BLUE_MULTIPLIER = 0.114
const HALF_DEGREES = 180

const LIGHT_SPIN = -20
const LIGHT_DESATURATE_RATIO = -0.25
const LIGHT_LIGHTEN_RATIO = 0.2

const DARK_SPIN = -5
const DARK_SATURATE_RATIO = 0.03
const DARK_DARKEN_RATIO = 0.5

// < HALF_DEGREES detects 'isDark'
// > HALF_DEGREES detects 'isLight'
export const detectLight = (color: string): boolean => {
	const rgb = tinycolor(color).toRgb()
	const red = rgb.r
	const green = rgb.g
	const blue = rgb.b

	return (
		Math.sqrt(RED_MULTIPLIER * (red * red) + GREEN_MULTIPLIER * (green * green) + BLUE_MULTIPLIER * (blue * blue)) >
		HALF_DEGREES
	)
}

export const getAdaptiveGradient = (color: string): { start: string; end: string } => {
	const hsl = tinycolor(color).toHsl()
	const { s } = hsl
	const { l } = hsl

	const isLight = detectLight(color)
	const light = tinycolor(color)
		.spin(LIGHT_SPIN)
		.desaturate(s * LIGHT_DESATURATE_RATIO)
		.lighten(l * LIGHT_LIGHTEN_RATIO)
		.toHexString()
	const dark = tinycolor(color)
		.spin(DARK_SPIN)
		.saturate(s * DARK_SATURATE_RATIO)
		.darken(l * DARK_DARKEN_RATIO)
		.toHexString()

	return isLight ? { start: dark, end: color } : { start: color, end: light }
}

export const getWidgetGradient = (color: string) => {
	const { start, end } = getAdaptiveGradient(color)

	return `linear-gradient(${WIDGET_GRADIENT_ANGLE}deg, ${start}, ${end} 130%)`
}
