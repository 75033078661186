import { ChatbotActionType, ChatbotInteraction } from 'models'
import { TranslationService as T } from 'shared/services'
import { ChatbotBuilderMovableOrCopiableNode, ChatbotBuilderMoveOrCopyNodeOption } from 'modules/chatbotDetail'

export const getListOfAllCollapsibleNodes = (interactions: ChatbotInteraction[]): string[] => {
	const interactionIsOfCollapsibleNodes = interactions
		.slice(1)
		.filter((interaction) => {
			const messageAction = interaction.actions.find((a) => a.type === ChatbotActionType.Message)
			const hasReplies =
				messageAction && 'replies' in messageAction.data ? messageAction.data.replies.length > 0 : false

			return !!interaction.id && messageAction && hasReplies
		})
		.map((interaction) => String(interaction.id))

	return interactionIsOfCollapsibleNodes
}

export const getMoveOrCopySelectOptions = (node: ChatbotBuilderMovableOrCopiableNode) => {
	const options: ChatbotBuilderMoveOrCopyNodeOption[] = []
	const createOption = ({ id, nodes, title, isDisabled }: ChatbotBuilderMovableOrCopiableNode, level: number) => {
		options.push({ value: id, label: title ?? T.translate('chatbotBuilder.nodes.welcomeMessage'), level, isDisabled })
		nodes.forEach((n) => createOption(n, level + 1))
	}

	createOption(node, 1)
	return options
}
