import { ConfigurationService } from 'shared/services'

export const loadDevTools = () => {
	const { enableDevtools } = ConfigurationService.getAppEnvData()

	if (enableDevtools === 'true') {
		const load = async () => {
			const devtools = await import('./devtools')
			devtools.install()
		}

		load()
	}
}
