import moment from 'moment'

import { ChatbotChatSearchRequest, ChatsSearchRequestQuery } from 'models'

export const getDateQuery = (dateFrom: Date | null, dateTo: Date | null): ChatsSearchRequestQuery => {
	const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
	const startDate = dateFrom ? moment(dateFrom).startOf('day').format(DATE_FORMAT) : null
	const endDate = dateTo ? moment(dateTo).endOf('day').format(DATE_FORMAT) : null

	return {
		field: 'createdAt',
		type: 'range',
		value: [startDate, endDate],
	}
}

export const getDefaultSizeAndSort = (): Partial<ChatbotChatSearchRequest> => {
	return {
		size: 100,
		sort: [{ finishedAt: 'desc' }],
	}
}
