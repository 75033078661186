import { User } from '@smartsupp/core-client-api'

export { User, UserRole } from '@smartsupp/core-client-api'

export const USER_NAME_MAXLENGTH = 64
export const DESCRIPTION_MAXLENGTH = 64

export type UserGroups = Record<string, string>

export interface SelectedUser extends User {
	isAdmin: boolean
	isOwner: boolean
	isRoot: boolean
}

export interface UserPasswords {
	passwordNew: string
	passwordCurrent: string
}
