import { BillingInfo, BillingInfoUpdateRequest, CountryCode, StripeCustomer } from 'models'
import { getVatCountryCode } from 'modules/countries'

import { BillingInfoFormData, BillingInfoStripeFormData, ValidationVatStatus } from './types'

export const createVatId = (
	countryCode: BillingInfoStripeFormData['countryCode'],
	vatNumber: BillingInfoStripeFormData['vatNumber'],
): string => {
	if (!vatNumber) return ''
	return `${getVatCountryCode(countryCode)}${vatNumber}`
}

export const transformBillingInfoFormDataToUpdateRequest = (
	data: BillingInfoFormData,
	billingInfo: BillingInfo,
): BillingInfoUpdateRequest => {
	let vatNumber = ''
	if (data.countryCode && data.vatNumber && data.vatNumber.length > 0) {
		vatNumber = createVatId(data.countryCode, data.vatNumber)
	}

	return {
		...data,
		vatNumber,
		email: data.email ?? billingInfo.email,
	}
}

export const transformCustomerToBillingInfo = (billingInfo: BillingInfo, customer: StripeCustomer): BillingInfo => {
	const { email, name, country, currency, street, city, postalCode, taxId, ic } = customer
	return {
		...billingInfo,
		city,
		company: name,
		countryCode: country.toLowerCase(),
		currency,
		email,
		postalCode,
		street,
		vatNumber: taxId,
		ico: ic ?? '',
	}
}

export const shouldShowVatConfirmationModal = (
	billingInfo: BillingInfoStripeFormData,
	validationVatStatus: ValidationVatStatus | null,
	hasVat: boolean,
): boolean => {
	const isVatNumberEmpty = !billingInfo.vatNumber
	const isValidCountry = hasVat && billingInfo.countryCode !== CountryCode.Czechia
	const isVatNotValid = validationVatStatus === ValidationVatStatus.Unknown

	return (isVatNumberEmpty || isVatNotValid) && isValidCountry
}

export const getVatConfirmationModalText = (
	isVatNumberEmpty: boolean,
	validationVatStatus: ValidationVatStatus | null,
): string => {
	if (isVatNumberEmpty) return 'empty'
	if (validationVatStatus === ValidationVatStatus.Unknown) return 'unknown'
	return ''
}
