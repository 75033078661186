export const generateHiringMsg = () => {
	if (process.env.NODE_ENV === 'development') return

	// eslint-disable-next-line no-console
	console.log(
		'%cWondering how Smartsupp is written? We use React and TypeScript. \n' +
			'Do you want to be part of our team? Visit https://smartsupp.recruitee.com/ or send an email to stanislav.vasek@smartsupp.com.',
		'color: #408fec',
	)
}
