import { isSameDay, isSameYear } from 'utils'

import IntlTime from './IntlTime'

type Props = {
	value: Date
}

export const AppDateTime = ({ value }: Props) => {
	const currentDate = new Date()

	if (isSameDay(currentDate, value)) {
		return <IntlTime value={value} />
	}
	if (isSameYear(currentDate, value)) {
		return <IntlTime value={value} month="short" day="numeric" />
	}

	return <IntlTime value={value} hour="numeric" minute="numeric" month="short" year="numeric" day="numeric" />
}
