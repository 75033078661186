const emojiMap: Record<string, string> = {
	':)': '🙂',
	':-)': '🙂',
	':D': '😀',
	':-D': '😀',
	';)': '😉',
	';-)': '😉',
	'<3': '❤️',
	':(': '😞',
	':-(': '😞',
	':P': '😛',
	':-P': '😛',
	':-o': '😮',
	':O': '😮',
	':/': '😕',
	':-/': '😕',
}

const createEmojiRegExp = (includeEndOfLine: boolean): RegExp => {
	const capturingGroup = `(${includeEndOfLine ? '$|' : ''} )`
	return new RegExp(
		Object.keys(emojiMap)
			.map((key) => `${key.replaceAll(/[$()*+.?[\\\]^{|}]/g, '\\$&')}${capturingGroup}`)
			.join('|'),
		'gi',
	)
}

export const replaceEmoticonsByEmojis = (text: string, includeEndOfLine = false): string => {
	const emojiRegExp = createEmojiRegExp(includeEndOfLine)
	return text.replace(emojiRegExp, (match) => {
		const emojiKey = Object.keys(emojiMap).find((key) => match.toUpperCase().includes(key))
		if (!emojiKey) return match

		const afterString = match.endsWith(' ') ? ' ' : ''
		return `${emojiMap[emojiKey]}${afterString}`
	})
}
