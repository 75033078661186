import { AiUsage, UsageData } from 'models/Usage'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'usage'
const API_RESOURCE_AI = 'mira/usage'

const fetchUsage = () => {
	return apiClient.get<UsageData>(`${API_RESOURCE}`)
}

const fetchAiUsage = () => {
	return apiClient.get<AiUsage.Account>(`${API_RESOURCE_AI}`)
}

export const usageApi = { fetchUsage, fetchAiUsage }
