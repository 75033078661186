import { AccountSegment, OnboardingStepCode } from 'models'

export type InitData = {
	avatar: string | null
	color: string
	lang: string
	name: string
}

export type OnboardingStep = {
	code: OnboardingStepCode
	title: string
}

export type OnboardingStepsMap = Record<number, OnboardingStep>

export type ReducedAccountSegment = Pick<AccountSegment, 'companySize' | 'customerObjective'>

export type StepTextsType = {
	title: string
	text: string
	buttonText: string
}

export type StepTextsForReplies = Record<number, StepTextsType>

export enum OnboardingLandingPage {
	Chatbot = 'chatbot',
	Home = 'home',
}
