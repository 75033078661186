import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DateRangePreset } from 'models'
import { AppThunkAction, DashboardState } from 'types'
import { RangeType } from 'shared/models/Analytics'
import { getDateRangeMap } from 'utils'
import { fetchConversationsStatisticsSmartHub } from 'modules/conversationsStatistics'
import { fetchSmartHubMetrics } from 'modules/statistics'
import { StatisticsFilter } from 'modules/statisticsFilter'

import { dateRangeMap, SmartHubStatisticsFilterPreset } from './types'

export type SmartHubRootState = Pick<DashboardState, 'smartHub'>
export type SmartHubState = typeof initialState

export const initialState = {
	statisticsRange: DateRangePreset.Past30D as SmartHubStatisticsFilterPreset,
}

export const setStatisticsRangeAndApply =
	(range: SmartHubStatisticsFilterPreset): AppThunkAction =>
	(dispatch) => {
		dispatch(setStatisticsRange(range))
		dispatch(fetchSmartHubMetrics())
		dispatch(fetchConversationsStatisticsSmartHub())
	}

const smartHubSlice = createSlice({
	name: 'smartHub',
	initialState,
	reducers: {
		setStatisticsRange: (state, { payload }: PayloadAction<SmartHubStatisticsFilterPreset>) => {
			state.statisticsRange = payload
		},
	},
})

const getStatisticsRange = (state: SmartHubRootState) => state.smartHub.statisticsRange

const getChatbotStatisticsRange = createSelector([getStatisticsRange], (range): RangeType => {
	return dateRangeMap[range]
})

const getConversationMetricsFilter = createSelector([getStatisticsRange], (range): StatisticsFilter => {
	const dateRange = getDateRangeMap()[range]
	const from = dateRange.start?.toDate() ?? null
	const to = dateRange.end?.toDate() ?? null

	return {
		group: '',
		from,
		to,
		tags: [],
	}
})

const { reducer, actions } = smartHubSlice
const { setStatisticsRange } = actions
export default reducer

export const smartHubSelectors = {
	getStatisticsRange,
	getConversationMetricsFilter,
	getChatbotStatisticsRange,
}
