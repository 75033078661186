import { batch } from 'react-redux'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppThunkAction } from 'types'
import { ConfigurationService, updateDataLayer } from 'shared/services'

import {
	updateDataLayerFirstSignIn,
	updateDataLayerNewSubscription,
	updateDataLayerNewSubscriptionGA,
	updateDataLayerWidgetInstall,
	updateDataLayerWidgetUsage,
} from './actionsExternal'
import { dataLayerApi } from './api'
import { EventsConfirmData, ExternalEvent, ExternalEventName } from './types'

export const updateDataLayerMonthlyServed =
	(count: number): AppThunkAction =>
	() => {
		updateDataLayer({
			monthlyServedConversations: count,
		})
	}

export const updateDataLayerFromExternalEvents = (): AppThunkAction => async (dispatch) => {
	const resultAction = await dispatch(fetchAccountEventQueue())
	const confirmIds: number[] = []
	if (fetchAccountEventQueue.fulfilled.match(resultAction)) {
		const { events } = resultAction.payload
		if (!events) return

		events.forEach((event: ExternalEvent) => {
			switch (event.name) {
				case ExternalEventName.SignUp: {
					dispatch(updateDataLayerFirstSignIn())
					break
				}
				case ExternalEventName.WidgetUsage: {
					dispatch(updateDataLayerWidgetUsage())
					break
				}
				case ExternalEventName.Subscription: {
					batch(() => {
						dispatch(updateDataLayerNewSubscription(event.data))
						dispatch(updateDataLayerNewSubscriptionGA(event.data))
					})
					break
				}
				case ExternalEventName.Install: {
					dispatch(updateDataLayerWidgetInstall())
					break
				}
			}
			confirmIds.push(event.id)
		})

		if (confirmIds.length > 0) {
			dispatch(confirmAccountEventQueue({ ids: confirmIds }))
		}
	}
}

export const updateDataLayerGTMContent = (): AppThunkAction => async (dispatch) => {
	const { lang } = ConfigurationService.getData()
	const resultAction = await dispatch(fetchGTMDataLayerContent())
	if (fetchGTMDataLayerContent.fulfilled.match(resultAction)) {
		updateDataLayer(resultAction.payload)
		updateDataLayer({ lang })
		updateDataLayer({ event: 'smartsupp.dl.changed' })
	}
}

export const fetchAccountEventQueue = createAsyncThunk('dataLayer/FETCH', () => {
	return dataLayerApi.fetchAccountEventQueue()
})

export const confirmAccountEventQueue = createAsyncThunk('dataLayer/CONFIRM', (data: EventsConfirmData) => {
	return dataLayerApi.confirmAccountEventQueue(data)
})

const fetchGTMDataLayerContent = createAsyncThunk('dataLayer/FETCH_GTM_DL_CONTENT', () => {
	return dataLayerApi.fetchGTMDataLayerContent()
})
