/* eslint-disable simple-import-sort/imports */
import 'unfetch/polyfill'
import 'focus-visible/dist/focus-visible'

import { Provider } from 'react-redux'

import { SmartsuppWindow } from 'shared/types'
import { documentVisibilityChanged, documentFocusChanged } from 'modules/app'
import 'shared/services/MomentLanguagePolyfill'
import { generateHiringMsg } from 'shared/utils'
import { clearNotifications } from 'utils'
import MixpanelService from 'services/MixpanelService'
import { createRoot } from 'react-dom/client'
import { loadDevTools } from './devtools'
import store from './store'

import App from './App'

declare const __VERSION__: string
declare const window: SmartsuppWindow

// Set document visibility change listener
document.addEventListener('visibilitychange', () => {
	store.dispatch(documentVisibilityChanged(document.visibilityState))

	// Clear all notifications if dashboard got focus
	if (document.visibilityState === 'visible') {
		clearNotifications()
	}
})

window.addEventListener('focus', () => {
	store.dispatch(documentFocusChanged(true))
})

window.addEventListener('blur', () => {
	store.dispatch(documentFocusChanged(false))
})

// Hiring console msg
generateHiringMsg()

// Load dev tools
loadDevTools()

const container = document.querySelector('#app')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(
	<Provider store={store}>
		<App />
	</Provider>,
)

// Inject webpack variables
if (window.App) {
	window.App.version = __VERSION__
	window.App.env = process.env
}

MixpanelService.init()
