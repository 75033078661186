import { ReactNode } from 'react'

import { StackProps, VStack } from 'core'

type Props = {
	children: ReactNode
	dataTestId: string
} & StackProps

export const Stat = ({ children, dataTestId, ...rest }: Props) => {
	return (
		<VStack spacing={1} data-testid={dataTestId} w="full" {...rest}>
			{children}
		</VStack>
	)
}
