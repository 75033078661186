import { UseToastOptions } from '@chakra-ui/react'

export const createExportNotification = (
	title: string,
	description: string,
	autoHide = false,
): Partial<UseToastOptions> => {
	return {
		title,
		description,
		duration: autoHide ? 5000 : null,
	}
}
