import { ChatbotAiBehavior, ChatbotAiSkills } from 'shared/models/Chatbot'

export enum ChatbotWorkflowSection {
	Basics = 'basics',
	Training = 'training',
	Skills = 'skills',
	Preview = 'preview',
}

export type ChatbotWorkflowProfileInputs = keyof Omit<ChatbotAiBehavior, 'description'>
export type ChatbotWorkflowSkills = keyof ChatbotAiSkills
