import { forwardRef } from 'react'
import type { IconButtonProps as ChakraIconButtonProps } from '@chakra-ui/react'
import { IconButton as ChakraIconButton } from '@chakra-ui/react'

export type CIconButtonProps = ChakraIconButtonProps

export const CIconButton = forwardRef<HTMLButtonElement, CIconButtonProps>((props, ref) => {
	return <ChakraIconButton ref={ref} colorScheme="gray" variant="ghost" fontSize={3} isRound {...props} />
})

CIconButton.displayName = 'CIconButton'
