import { ReactNode } from 'react'
import {
	Popover as CPopover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverProps,
	PopoverTrigger,
} from '@chakra-ui/popover'

export type TooltipProps = Partial<PopoverProps> & {
	children: ReactNode
	content?: string | ReactNode
	hasArrow?: boolean
	maxWidth?: string | number | Record<string, string | number>
}

export const Popover = ({
	children,
	content,
	hasArrow = true,
	variant = 'light',
	maxWidth = { base: 320, md: 360 },
	...rest
}: TooltipProps) => {
	return (
		<CPopover variant={variant} {...rest}>
			<PopoverTrigger>{children}</PopoverTrigger>
			<PopoverContent width="auto" maxW={maxWidth}>
				{hasArrow && <PopoverArrow />}
				<PopoverBody p={1}>{content}</PopoverBody>
			</PopoverContent>
		</CPopover>
	)
}
