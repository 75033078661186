export enum ShortcutsDirection {
	Down = 1,
	Up,
}

// Shortcuts starting with slash char "/"
export const detectShortcutRegex = /(^|\s)\/(\S*)$/

// Shortcuts starting with period char "."
export const detectShortcutLegacyRegex = /^\.(\S*)$/
