const transparentColor = 'rgba(0, 0, 0, 0.1)'

export const CloseButton = {
	baseStyle: {
		borderRadius: '50%',
		_hover: { bg: transparentColor },
		_active: { bg: transparentColor },
		_focus: { bg: transparentColor },
	},
	variants: {
		solid: {
			bg: 'gray.200',
		},
	},
	sizes: {
		xs: {
			w: '16px',
			h: '16px',
			fontSize: '8px',
		},
	},
}
