import { TagColor } from './types'

export const DEFAULT_TAG_COLORS: TagColor = { text: '#2E7AFA', bg: '#ECF4FD' }

export const tagsColorsArray: TagColor[] = [
	{ text: '#D6195A', bg: '#FFE6F4' },
	{ text: '#C52407', bg: '#FFE5E3' },
	{ text: '#D66829', bg: '#FCEFDD' },
	{ text: '#3D7A20', bg: '#DEFCCC' },
	{ text: '#468868', bg: '#E4FBF2' },
	DEFAULT_TAG_COLORS,
	{ text: '#1233DF', bg: '#E5EBFD' },
	{ text: '#7138E4', bg: '#EFE7FD' },
	{ text: '#B743BF', bg: '#FAE9FE' },
	{ text: '#2A2951', bg: '#EEEEFB' },
]
