import { Chatbot } from 'models'
import { AutoMessagesApi } from 'models/AutoMessage'
import { apiClient } from 'shared/utils'

const API_RESOURCE_STATS_NEW = 'analytics-api/v1/auto-messages/stats'

const getStatistics = ({ range, fields }: AutoMessagesApi.StatsQuery) => {
	const fieldsQuery = fields && fields.length > 0 ? `&fields=${fields.join(',')}` : ''
	return apiClient.get<AutoMessagesApi.StatsResponse>(`${API_RESOURCE_STATS_NEW}?range=${range}${fieldsQuery}`)
}

const getAutoMessageStatistics = (autoMessageId: Chatbot['id'], { range }: AutoMessagesApi.StatsSingleQuery) => {
	return apiClient.get<AutoMessagesApi.StatsSingleResponse>(`${API_RESOURCE_STATS_NEW}/${autoMessageId}?range=${range}`)
}

const getAggregatedAutoMessageStatistics = (
	autoMessageId: Chatbot['id'],
	{ range }: AutoMessagesApi.StatsSingleAggregatedQuery,
) => {
	return apiClient.get<AutoMessagesApi.StatsSingleAggregatedResponse>(
		`${API_RESOURCE_STATS_NEW}/${autoMessageId}/aggregated?range=${range}`,
	)
}

export const autoMessageStatisticsApi = {
	getStatistics,
	getAutoMessageStatistics,
	getAggregatedAutoMessageStatistics,
}
