import { keyframes } from '@chakra-ui/react'

import { useSchedule } from 'shared/hooks'
import { theme } from 'styles'

import { Box, BoxProps, Center } from 'core'

type Props = {
	delayMS?: number
	bgColor?: string
}

const bounceAnimation = keyframes({
	'0%, 100%': { transform: 'scale(0.0)' },
	'50%': { transform: 'scale(1.0)' },
})

const BounceLoader = (props: BoxProps) => {
	return (
		<Box
			pos="absolute"
			top={0}
			left={0}
			w="100%"
			h="100%"
			borderRadius="50%"
			opacity={0.6}
			animation={`${bounceAnimation} 2.0s infinite ease-in-out`}
			{...props}
		/>
	)
}

const Loader = ({ delayMS = 750, bgColor }: Props) => {
	const backgroundColor = bgColor || theme.colors.primary
	const { delayComplete } = useSchedule({ delayMS, loop: false })

	if (!delayComplete) return null

	return (
		<Center w="100%" minH="100%">
			<Center h="100px">
				<Box pos="relative" w="40px" h="40px" m="40px">
					<BounceLoader bgColor={backgroundColor} />
					<BounceLoader sx={{ animationDelay: '-1.0s' }} bgColor={backgroundColor} />
				</Box>
			</Center>
		</Center>
	)
}

export default Loader
