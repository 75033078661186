import { Text } from 'core'

const getKey = (key: number): string => `key-${key}`

type Props = {
	text: string
	regex: RegExp
}
// temporary/experimental usage before Chakra Highlight cmp will be used
export const Highlight = ({ text, regex }: Props) => {
	const parts = text.split(regex)
	const match = regex.exec(text)

	if (match !== null) {
		const result = parts.map((part, index) => {
			if (match.includes(part)) {
				return (
					<Text as="span" fontWeight="bold" key={getKey(index)}>
						{part}
					</Text>
				)
			}

			return (
				<Text as="span" key={getKey(index)}>
					{part}
				</Text>
			)
		})
		return <Text as="span">{result}</Text>
	}
	return <Text as="span">{text}</Text>
}
