import moment, { Moment } from 'moment'

import { DateRangePreset } from 'models'
import { TranslationService as T } from 'shared/services'
import { differenceInMilliseconds } from 'shared/utils'

export interface DateRange {
	start: Moment | null
	end: Moment | null
}

const THOUSAND = 1000
const HALF_MIN = 30
const UNDER_HALF_MIN = 29
const MIN = 60
const HOUR = 3600
const DAY = 86400
const DAYS_IN_WEEK = 7
const DAYS_1 = 1
const DAYS_6 = 6
const DAYS_29 = 29
const YEAR_1 = 1

export const getCurrentTime = () => moment().toDate()

export const getTodayDateBegin = () => moment().startOf('day')
export const getTodayDateEnd = () => moment().endOf('day')

export const getYesterdayDateBegin = () => moment().startOf('day').subtract(1, 'days')
export const getYesterdayDateEnd = () => moment().endOf('day').subtract(1, 'days')

export const getThisMonthDateBegin = () => moment().startOf('month')
export const getThisMonthDateEnd = () => moment().endOf('month')

export const getThisWeekDateBegin = () => moment().startOf('week')
export const getThisWeekDateEnd = () => moment().endOf('week')

export const getLastWeekDateBegin = () => moment().startOf('week').subtract(DAYS_IN_WEEK, 'days')
export const getLastWeekDateEnd = () => moment().endOf('week').subtract(DAYS_IN_WEEK, 'days')

export const getLastMonthDateBegin = () => moment().startOf('month').subtract(1, 'months')
export const getLastMonthDateEnd = () => moment().subtract(1, 'months').endOf('month')

export const getPast7DaysDateBegin = () => moment().startOf('day').subtract(DAYS_6, 'days')

export const getPast30DaysDateBegin = () => moment().startOf('day').subtract(DAYS_29, 'days')

export const getPastYearDateBegin = () => moment().startOf('day').subtract(YEAR_1, 'year').add(DAYS_1, 'days')

export const getDateRangeMap = (): Record<DateRangePreset, DateRange> => ({
	[DateRangePreset.All]: { start: null, end: null },
	[DateRangePreset.Today]: { start: getTodayDateBegin(), end: getTodayDateEnd() },
	[DateRangePreset.Yesterday]: { start: getYesterdayDateBegin(), end: getYesterdayDateEnd() },
	[DateRangePreset.ThisWeek]: { start: getThisWeekDateBegin(), end: getThisWeekDateEnd() },
	[DateRangePreset.LastWeek]: { start: getLastWeekDateBegin(), end: getLastWeekDateEnd() },
	[DateRangePreset.ThisMonth]: { start: getThisMonthDateBegin(), end: getThisMonthDateEnd() },
	[DateRangePreset.LastMonth]: { start: getLastMonthDateBegin(), end: getLastMonthDateEnd() },
	[DateRangePreset.Past7D]: { start: getPast7DaysDateBegin(), end: getTodayDateEnd() },
	[DateRangePreset.Past30D]: { start: getPast30DaysDateBegin(), end: getTodayDateEnd() },
	[DateRangePreset.PastYear]: { start: getPastYearDateBegin(), end: getTodayDateEnd() },
})

export const isSameRange = (rangeA: DateRange, rangeB: DateRange): boolean => {
	// All dates all null
	if (rangeA.start === null && rangeB.start === null && rangeA.end === null && rangeB.end === null) {
		return true
	}

	// Null start dates
	if (rangeA.start === null && rangeB.start === null && rangeA.end && rangeB.end) {
		return rangeA.end.isSame(rangeB.end)
	}

	// Null end dates
	if (rangeA.end === null && rangeB.end === null && rangeA.start && rangeB.start) {
		return rangeA.start.isSame(rangeB.start)
	}

	// All values are set
	if (rangeA.start && rangeA.end && rangeB.start && rangeB.end) {
		return rangeA.start.isSame(rangeB.start) && rangeA.end.isSame(rangeB.end)
	}

	return false
}

export const isSameDay = (date1: Date, date2: Date) => {
	const dateA = new Date(date1)
	const dateB = new Date(date2)
	return (
		dateA.getDate() === dateB.getDate() &&
		dateA.getMonth() === dateB.getMonth() &&
		dateA.getFullYear() === dateB.getFullYear()
	)
}

export const isSameYear = (dateA: Date, dateB: Date) => {
	return new Date(dateA).getFullYear() === new Date(dateB).getFullYear()
}

export const getTimeAgoInWords = (date: Date) => {
	const timeDiff = differenceInMilliseconds(new Date(), date) / THOUSAND
	if (timeDiff < HALF_MIN) {
		return T.translate('general.time.now')
	}
	if (timeDiff > UNDER_HALF_MIN && timeDiff < MIN) {
		return `30 ${T.translate('general.time.seconds')}`
	}
	if (timeDiff >= MIN && timeDiff < HOUR) {
		return `${Math.floor(timeDiff / MIN)} ${T.translate('general.time.minutes')}`
	}
	if (timeDiff >= HOUR && timeDiff < DAY) {
		return `${Math.floor(timeDiff / HOUR)} ${T.translate('general.time.hours')}`
	}
	if (timeDiff >= DAY) {
		return `${Math.floor(timeDiff / DAY)} ${T.translate('general.time.days')}`
	}
	return ''
}
