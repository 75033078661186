import moment, { Moment } from 'moment'

import { BrowsingPath, Chat, ChatsSearchRequest, ChatStatus, SelectedChat, UserGroups } from 'models'
import { Message, MessageDeliveryStatus } from 'shared/models/Message'
import { linkifier, sanitizeName } from 'utils'
import { DEFAULT_GROUP } from 'modules/groups/constants'

import { ENCODED_BACKSLASH, FETCH_CHATS_LIMIT_SECONDS } from './constants'

const CHATBOT_CONVERSATION_PREFIX = 'cb'

type OpenChatsFilterRequestParams = Pick<ChatsSearchRequest, 'query'>

const getDisplayedName = (id: string, name: string | null): string => {
	return name && name !== '' ? sanitizeName(name) : id
}

const hasMessageDeliveryFailed = (message: Message): boolean => {
	return (
		message?.deliveryStatus === MessageDeliveryStatus.PermanentFail ||
		message?.deliveryStatus === MessageDeliveryStatus.TemporaryFail
	)
}

export const transformChat = (chat: Chat): SelectedChat => ({
	...chat,
	displayedName: getDisplayedName(chat.contactId, chat?.contactDetails?.name),
	hasLastMessageDeliveryFailed: hasMessageDeliveryFailed(chat.lastMessage),
})

export const createBrowsingPath = <T extends BrowsingPath>({ title, url }: T): BrowsingPath => ({ title, url })

export const openChatsFilterToRequestParams = (): OpenChatsFilterRequestParams => {
	const params: OpenChatsFilterRequestParams = {
		query: [{ field: 'status', value: [ChatStatus.Open, ChatStatus.Served] }],
	}

	return params
}

export const filterUnservedChat =
	(userGroups: UserGroups) =>
	(chat: Chat): boolean => {
		const userGroupsIds = Object.keys(userGroups)
		const isChatUnserved = chat.assignedIds.length === 0
		const hasChatGroup = !!chat.groupId
		const hasChatDefaultGroup = chat.groupId === DEFAULT_GROUP
		const isUserInChatGroup = !!chat.groupId && userGroupsIds.includes(chat.groupId)
		const isUserWithoutGroupAndChatHasGroup = userGroupsIds.length === 0 && hasChatGroup

		return (
			isChatUnserved && (!hasChatGroup || hasChatDefaultGroup || isUserInChatGroup || isUserWithoutGroupAndChatHasGroup)
		)
	}

export const filterMineChat =
	(userId: string) =>
	(chat: Chat): boolean => {
		return chat.assignedIds.length > 0 && !!userId && chat.assignedIds.includes(userId)
	}

export const filterPrimaryChat =
	(userId: string, userGroups: UserGroups) =>
	(chat: Chat): boolean => {
		return filterMineChat(userId)(chat) || filterUnservedChat(userGroups)(chat)
	}

export const isAllowedFetchOpenChats = (lastTimeFetch: null | Moment): boolean => {
	if (lastTimeFetch === null) return true
	return moment().diff(lastTimeFetch, 'seconds') > FETCH_CHATS_LIMIT_SECONDS
}

export const sanitizeLinksBackslashes = (text: string): string => {
	const backslash = /\\/g

	if (text === '') return text
	const matches = linkifier.match(text)
	if (!matches) return text

	let linkifiedText = ''
	let lastIndex = 0

	matches.forEach((match) => {
		let textToAdd = ''
		let sanitizedLinkToAdd = ''
		// Add preceding text if there is any
		if (match.index > lastIndex) {
			textToAdd = text.slice(lastIndex, match.index)
		}

		sanitizedLinkToAdd = match.raw.replaceAll(backslash, ENCODED_BACKSLASH)
		lastIndex = match.lastIndex

		linkifiedText += textToAdd + sanitizedLinkToAdd
	})

	// Add remaining text if there is any
	if (text.length > lastIndex) {
		linkifiedText += text.slice(lastIndex)
	}

	return linkifiedText
}

export const isChatbotConversation = (chatId: string | null) => {
	if (!chatId) return false
	return chatId.startsWith(CHATBOT_CONVERSATION_PREFIX)
}
