import { useSelector } from 'react-redux'

import { TranslationService as T } from 'shared/services'
import { agentsSelectors } from 'modules/agents'
import { userSelectors } from 'modules/user'

import { Box, Flex, Popover, Tooltip } from 'core'
import { AgentAvatar } from 'components/agents/AgentAvatar'

import SidebarAgentMenu from './SidebarAgentMenu'

const SidebarAgent = () => {
	const agent = useSelector(agentsSelectors.getAgentByUser)
	const isRoot = useSelector(userSelectors.isUserRoot)

	return (
		<Popover content={<SidebarAgentMenu />} placement="right-end" offset={[0, 8]}>
			<Box px="10px" my={1}>
				<Box as="button" data-testid="sidebar-agent-profile" w="full">
					<Tooltip content={T.translate('sideBar.agentMenu.tooltip')} placement="right" offset={[0, 20]} closeOnClick>
						<Flex
							transition="background-color 200ms"
							_hover={{ bgColor: 'coal.800' }}
							borderRadius={2}
							alignItems="center"
							justifyContent="center"
							p={1}
						>
							<AgentAvatar
								avatar={agent?.avatar ?? null}
								size="36px"
								status={isRoot ? null : agent?.status}
								statusBorderColor="coal.900"
							/>
						</Flex>
					</Tooltip>
				</Box>
			</Box>
		</Popover>
	)
}

export default SidebarAgent
