import { RefObject, useEffect, useRef } from 'react'

import { RecaptchaAction } from 'models'
import { SmartsuppWindow } from 'shared/types'

import { useRecaptchaDispatch, useRecaptchaState } from './RecaptchaProvider'

declare const window: SmartsuppWindow

const SCRIPT_ID = 'google-recaptcha'
const WIDGET_CLASS_NAME = 'g-recaptcha'

export const useRecaptcha = (badgeRef?: RefObject<Element>) => {
	const { isReady, siteKey } = useRecaptchaState()
	const dispatch = useRecaptchaDispatch()
	const widgetRef = useRef<HTMLDivElement>()

	// Load Recaptcha script
	useEffect(() => {
		const onLoad = () => {
			if (!window.grecaptcha) return

			// Set Recaptcha state as ready
			window.grecaptcha.ready(() => {
				dispatch({ type: 'ready' })
			})
		}

		const loadScript = () => {
			window.captchaOnLoad = onLoad
			const url = 'https://www.google.com/recaptcha/api.js'
			const queryString = '?onload=captchaOnLoad&render=explicit'
			const script = document.createElement('script')
			script.id = SCRIPT_ID
			script.type = 'text/javascript'
			script.src = url + queryString
			script.async = true
			script.defer = true
			document.body.append(script)
		}

		if (siteKey && !document.querySelector(`#${SCRIPT_ID}`)) {
			loadScript()
		}

		if (!siteKey) {
			dispatch({ type: 'ready' })
		}
	}, [dispatch, siteKey])

	// Create Recaptcha widget
	useEffect(() => {
		const createWidget = () => {
			if (!window.grecaptcha || !siteKey) return

			const widget = document.createElement('div')
			widget.className = WIDGET_CLASS_NAME

			// Render badge into custom element if ref is provided
			if (badgeRef && badgeRef.current) {
				badgeRef.current.append(widget)
			} else {
				document.body.append(widget)
			}

			widgetRef.current = widget
			window.grecaptcha.render(widget, {
				sitekey: siteKey,
				size: 'invisible',
			})
		}

		if (isReady && !widgetRef.current) {
			createWidget()
		}

		return () => {
			if (widgetRef.current) {
				widgetRef.current.remove()
			}
		}
	}, [badgeRef, isReady, siteKey])

	const execute = (action: RecaptchaAction) => {
		if (!siteKey) return null

		if (!window.grecaptcha) {
			throw new Error('Recaptcha is not loaded!')
		}

		if (!isReady) {
			throw new Error('Recaptcha is not ready!')
		}

		return window.grecaptcha.execute({ action })
	}

	return {
		isRecaptchaReady: isReady,
		executeRecaptcha: execute,
	}
}
