import { EntityId } from '@reduxjs/toolkit'

import { ChatbotTemplate, ChatbotTemplateSimple, ChatbotTemplateSuggestion } from 'models'
import { apiClient } from 'shared/utils'

export const API_RESOURCE_TEMPLATES = 'chatbot/templates'

let templatesLang: string

const getChatbotTemplates = (lang: string) => {
	templatesLang = lang
	return apiClient.get<ChatbotTemplateSimple[]>(`${API_RESOURCE_TEMPLATES}?lang=${lang}`)
}

// NOTE chatbot templates stil returns handover instead of handover_v2
const getChatbotTemplate = (id: EntityId) => {
	return apiClient.get<ChatbotTemplate>(`${API_RESOURCE_TEMPLATES}/${id}?lang=${templatesLang}`)
}

const getChatbotOnboardingTemplate = (lang: string) => {
	templatesLang = lang
	return apiClient.get<ChatbotTemplate>(`${API_RESOURCE_TEMPLATES}/onboarding?lang=${templatesLang}`)
}

const sendTemplateSuggestion = (message: string) => {
	return apiClient.post<ChatbotTemplateSuggestion>(`${API_RESOURCE_TEMPLATES}/suggest-new`, {
		text: message,
	})
}

export const chatbotTemplatesApi = {
	getChatbotTemplate,
	getChatbotTemplates,
	getChatbotOnboardingTemplate,
	sendTemplateSuggestion,
}
