import { ReactNode } from 'react'
import { UseToastOptions } from '@chakra-ui/react'

import { CloseButton, Flex, Stack, Text } from 'core'

type PropsToastComponent = {
	onClose: () => void
	button?: ReactNode
	testId?: string
} & UseToastOptions

export const ProductNotificationComponent = ({
	title,
	description,
	isClosable,
	onClose,
	button,
	testId,
}: PropsToastComponent) => {
	return (
		<Stack
			p={2}
			position="absolute"
			bottom={1}
			left={9}
			spacing={2}
			boxShadow="shadow3"
			bg="white"
			w={{ base: '220px', sm: '400px' }}
			data-testid={testId}
			borderRadius={1}
		>
			<Flex alignItems="flex-start" direction="row" w="full">
				<Stack flex={1} mx={1} spacing={1}>
					{title && (
						<Text fontSize={2} fontWeight="bold">
							{title}
						</Text>
					)}
					{description && <Text fontWeight="light">{description}</Text>}
				</Stack>
				{isClosable && <CloseButton size="sm" onClick={onClose} />}
			</Flex>
			{button && <Flex w="full">{button}</Flex>}
		</Stack>
	)
}
