import { useBreakpointValue } from '@chakra-ui/react'

import { TranslationService as T } from 'shared/services'
import { routes } from 'configuration/routes'

import { Box } from 'shared/components/core/Box'
import { iconChatbot } from 'shared/components/icons'

import SidebarMenuIcon from '../sidebarMenu/SidebarMenuIcon'
import SidebarMenuItem from '../sidebarMenu/SidebarMenuItem'
import SidebarMenuItemPopover from '../sidebarMenu/SidebarMenuItemPopover'

import SidebarAutomationMenu from './SidebarAutomationsMenu'

const SidebarAutomationsMenuItem = () => (
	<SidebarMenuItem path={routes.automations.path} exactPath={false} tooltip={T.translate('headerBar.automations')}>
		<SidebarMenuIcon icon={iconChatbot} />
	</SidebarMenuItem>
)

const SidebarAutomations = () => {
	const isMobile = useBreakpointValue({ base: true, lg: false })

	return (
		<Box data-testid="sidebar-automations" data-userpilot="sidebar-automations">
			{isMobile ? (
				<SidebarMenuItemPopover popoverContent={<SidebarAutomationMenu />}>
					<SidebarMenuIcon icon={iconChatbot} />
				</SidebarMenuItemPopover>
			) : (
				<SidebarAutomationsMenuItem />
			)}
		</Box>
	)
}

export default SidebarAutomations
