import { createAction } from '@reduxjs/toolkit'

import { AgentEvents } from 'shared/models/Agent'

export const updateAgentFromWebsocket = createAction(
	'agents/UPDATE_WEBSOCKET',
	(payload: AgentEvents.AgentUpdated) => ({
		payload,
	}),
)
