export enum HttpMethod {
	Delete = 'DELETE',
	Get = 'GET',
	Patch = 'PATCH',
	Post = 'POST',
	Put = 'PUT',
}

export enum HttpStatusCode {
	S200OK = 200,
	S204NoContent = 204,
	S300MultipleChoices = 300,
	S401Unauthorized = 401,
}
