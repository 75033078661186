import { useEffect, useMemo } from 'react'
import throttle from 'lodash/throttle'

const THROTTLE_WAIT = 1000

export const useThrottleFn = <T extends (...args: unknown[]) => void>(fn: T, waitTime = THROTTLE_WAIT) => {
	const throttledFn = useMemo(() => {
		return throttle(fn, waitTime)
	}, [fn, waitTime])

	useEffect(() => {
		return () => {
			throttledFn.cancel()
		}
	}, [throttledFn])

	return throttledFn
}
