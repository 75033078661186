export type Translations = {
	[key: string]: string
}

export enum WidgetTranslations {
	Defaults = 'defaults',
	Custom = 'custom',
}

type WidgetAppearanceTranslationKeys =
	| 'onlineTitle'
	| 'onlineStatus'
	| 'offlineTitle'
	| 'offlineStatus'
	| 'textarea'
	| 'chatButton'

export type WidgetAppearanceTranslations = {
	[key in WidgetAppearanceTranslationKeys]: string
}

export type WidgetAuthFormTranslations = {
	authFormTitle: string
	authFormDesc: string
}
