export const TRIGGER_NAME = 'Smartsupp-trigger-allPages'
export const VERSION_NAME = 'Smartsupp-version'
export const RESOURCE_NAME = 'Smartsupp-chatbox'

export const gtmConfig = {
	apiKey:
		process.env.NODE_ENV === 'production'
			? 'AIzaSyApHmXXdRd6QxrYX3bHi_StHyqtLfKOxVc'
			: 'AIzaSyDYN3d0BibJzWVxLNSfs0upHKmc6oBoOmM',
	clientId:
		process.env.NODE_ENV === 'production'
			? '832799688217-94d2qaoio288bclmpieia8kjqki6ptv0.apps.googleusercontent.com'
			: '608917608101-s1ilbsq3llaie2ptlat1lbloo6accao8.apps.googleusercontent.com',
	restUrl: 'https://content.googleapis.com/discovery/v1/apis/tagmanager/v2/rest',
	scope:
		'https://www.googleapis.com/auth/tagmanager.edit.containers https://www.googleapis.com/auth/tagmanager.readonly https://www.googleapis.com/auth/tagmanager.publish https://www.googleapis.com/auth/tagmanager.edit.containerversions',
}
