import { createAsyncThunk } from '@reduxjs/toolkit'

import { chatbotSettingsApi } from './api'
import { ChatbotTriggerUpdateParams } from './types'

export const updateChatbotTriggerThunk = createAsyncThunk(
	'chatbotSettings/PATCH',
	({ botId, changes }: ChatbotTriggerUpdateParams) => {
		return chatbotSettingsApi.updateChatbotTrigger({ botId, changes })
	},
)

export const fetchChatbotTrigger = createAsyncThunk('chatbotSettings/GET', (botId: string) => {
	return chatbotSettingsApi.getChatbotTrigger(botId)
})
