import { Redirect, RedirectProps } from 'react-router-dom'

import { getParams, QueryParams } from 'utils/history'

type Props = {
	to: RedirectProps['to']
	params?: QueryParams
} & RedirectProps

const RouterRedirect = ({ to, params, ...rest }: Props) => {
	return <Redirect to={`${to}?${getParams(params)}`} {...rest} />
}

export default RouterRedirect
