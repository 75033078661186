import {
	SubscriptionCalculateRequest,
	SubscriptionCalculateResponse,
	SubscriptionCreateRequest,
	SubscriptionResumeRequest,
	SubscriptionUpdateRequest,
} from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'subscription'

const createSubscription = <Return>(data: SubscriptionCreateRequest) => {
	return apiClient.post<Return>(`${API_RESOURCE}`, data)
}

const updateSubscription = <Return>(data: SubscriptionUpdateRequest) => {
	return apiClient.patch<Return>(`${API_RESOURCE}`, data)
}

const pauseSubscription = () => {
	return apiClient.patch(`${API_RESOURCE}/pause`)
}

const resumeSubscription = (data: SubscriptionResumeRequest) => {
	return apiClient.patch(`${API_RESOURCE}/resume`, data)
}

const cancelSubscription = () => {
	return apiClient.patch(`${API_RESOURCE}/cancel`)
}

const calculateSubscription = (data: SubscriptionCalculateRequest) => {
	const queryParams = new URLSearchParams()
	Object.entries(data).forEach(([key, val]) => {
		queryParams.append(key, val as string)
	})
	return apiClient.get<SubscriptionCalculateResponse>(`${API_RESOURCE}/calculate?${queryParams.toString()}`)
}

export const subscriptionApi = {
	createSubscription,
	updateSubscription,
	pauseSubscription,
	resumeSubscription,
	cancelSubscription,
	calculateSubscription,
}
