const baseStyle = {
	header: {
		px: 3,
		py: 2,
	},
	body: {
		px: 3,
		py: 1,
	},
	dialog: {
		borderRadius: 3,
	},
	footer: {
		px: 3,
		py: 2,
	},
	closeButton: {
		top: 1,
		insetEnd: 2,
	},
}

export const Modal = {
	baseStyle,
}
