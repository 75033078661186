import { AccountSegment } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'segment'

const updateAccountSegment = (data: Partial<AccountSegment>) => {
	return apiClient.patch<AccountSegment>(`${API_RESOURCE}`, data)
}

const fetchAccountSegment = () => {
	return apiClient.get<AccountSegment>(`${API_RESOURCE}`)
}

export const segmentationApi = { updateAccountSegment, fetchAccountSegment }
