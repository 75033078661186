import { FieldError, FieldErrors } from 'react-hook-form'
import get from 'lodash/get'

import { TranslationService as T } from 'shared/services'

import { Box } from 'core'

import { validationErrorsMap } from './constants'

type ErrorMessageContentProps = {
	message: string
	type: string
}
const ErrorMessageContent = ({ message, type }: ErrorMessageContentProps) => (
	<Box color="red.500" data-testid={`error-message-${type}`}>
		{T.translate(message)}
	</Box>
)

type ErrorMessageProps = {
	name: string
	errors: FieldErrors
	customErrors?: Record<string, FieldError>
}

export const ErrorMessage = ({ name, errors, customErrors }: ErrorMessageProps) => {
	const error = get(errors, name)
	if (!error || !error?.type) return null

	const errorType = String(error.type)

	const customErrorMessage = customErrors && customErrors[errorType] && customErrors[errorType].message

	const errorMessage = customErrorMessage || (validationErrorsMap[errorType] ?? validationErrorsMap.default)
	return <ErrorMessageContent message={errorMessage} type={errorType} />
}

type ArrayFieldErrorMessageProps = {
	errorsArrayIndex: number
	propertyName: string
} & ErrorMessageProps

export const ArrayFieldErrorMessage = ({
	errors,
	name,
	errorsArrayIndex,
	propertyName,
	customErrors,
}: ArrayFieldErrorMessageProps) => {
	const errorField = errors[name]
	if (!errorField || !Array.isArray(errorField) || !errorField[errorsArrayIndex]) return null

	const error = get(errorField[errorsArrayIndex], propertyName)

	if (!error || !error?.type) return null

	const errorType = String(error.type)

	const customErrorMessage = customErrors && customErrors[errorType] && customErrors[errorType].message

	const errorMessage = (customErrorMessage || validationErrorsMap[errorType]) ?? validationErrorsMap.default
	return <ErrorMessageContent message={errorMessage} type={errorType} />
}
