import { ReactNode } from 'react'
import { Anchorme } from 'react-anchorme'

import { TranslationService as T } from 'shared/services'

import { Flex, Image, Text } from 'core'

type Props = {
	icon?: string
	iconMb?: number
	iconSize?: IconSize
	title?: string
	titleFontSize?: number
	description?: string
	extra?: ReactNode
	dataTestId?: string
}

type IconSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg'

const iconSizesMap: Record<IconSize, number> = {
	xxs: 48,
	xs: 80,
	sm: 100,
	md: 128,
	lg: 338,
}

const DEFAULT_ICON_SIZE = 'md'
const DEFAULT_TITLE_FONT_SIZE = 4

const getMaxHeight = (iconSize: IconSize): string => {
	if (iconSizesMap[iconSize] === undefined) return `${DEFAULT_ICON_SIZE}px`
	return `${iconSizesMap[iconSize]}px`
}

const getIconUrl = (icon: string): string => {
	return `assets/illustrations/${icon}`
}

export const Empty = ({
	icon,
	iconMb,
	iconSize = DEFAULT_ICON_SIZE,
	title,
	titleFontSize = DEFAULT_TITLE_FONT_SIZE,
	description,
	extra,
	dataTestId,
}: Props) => {
	return (
		<Flex direction="column" alignItems="center" data-testid={dataTestId}>
			{icon && (
				<Image path={getIconUrl(icon)} maxH={getMaxHeight(iconSize)} mb={iconMb ?? (title ? 3 : 1)} alt="empty-state" />
			)}
			{title && (
				<Text align="center" fontSize={titleFontSize}>
					{T.translate(title)}
				</Text>
			)}
			{description && (
				<Text align="center" color="gray.500" mb={extra ? 3 : 0} maxW="640px">
					<Anchorme>{description}</Anchorme>
				</Text>
			)}
			{extra && extra}
		</Flex>
	)
}
