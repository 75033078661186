import { ConditionNameLegacy, ConditionOpLegacy, TriggerType } from 'shared/models/Trigger'
import { ChatbotSettingsSection } from 'modules/chatbotSettings/constants'

export const legacyTriggersTextMap: Record<ConditionNameLegacy, string> = {
	[ConditionNameLegacy.VisitorPageUrl]: 'autoMessages.conditionsSimple.webpageUrl',
	[ConditionNameLegacy.VisitorPageTitle]: 'autoMessages.conditionsSimple.webpageTitle',
	[ConditionNameLegacy.VisitorReferer]: 'autoMessages.conditionsSimple.referer',
	[ConditionNameLegacy.NumberOfVisits]: 'autoMessages.conditionsSimple.numberOfVisits',
	[ConditionNameLegacy.NumberOfChats]: 'autoMessages.conditionsAdv.numberOfChats',
	[ConditionNameLegacy.IsVisitorSendMessage]: 'autoMessages.conditionsAdv.visitorSentMessage',
	[ConditionNameLegacy.IsVisitorServed]: 'autoMessages.conditionsAdv.visitorServed',
	[ConditionNameLegacy.VisitorIp]: 'autoMessages.conditionsAdv.IPaddress',
	[ConditionNameLegacy.VisitorCity]: 'autoMessages.conditionsSimple.city',
	[ConditionNameLegacy.VisitorCountryCode]: 'chatbotSettings.audience.countryCode',
	[ConditionNameLegacy.VisitorGroup]: 'autoMessages.conditionsSimple.group',
	[ConditionNameLegacy.HourOfDay]: 'autoMessages.conditionsAdv.hourOfTheDay',
	[ConditionNameLegacy.DayOfWeek]: 'autoMessages.conditionsAdv.dayOfTheWeek',
	[ConditionNameLegacy.MonthOfYear]: 'autoMessages.conditionsAdv.Month',
	[ConditionNameLegacy.VisitorBrowser]: 'autoMessages.conditionsAdv.Browser',
	[ConditionNameLegacy.VisitorOs]: 'autoMessages.conditionsAdv.OperatingSystem',
	[ConditionNameLegacy.VisitorPlatform]: 'autoMessages.conditionsAdv.platform',
	[ConditionNameLegacy.AccountStatus]: 'autoMessages.conditionsAdv.accountStatus',
	[ConditionNameLegacy.NoAnswer]: '',
	[ConditionNameLegacy.DelayPage]: '',
	[ConditionNameLegacy.DelaySite]: '',
	[ConditionNameLegacy.IsVisitorCommunicate]: '',
	[ConditionNameLegacy.IsTriggered]: 'autoMessages.conditionsAdv.visitorReceivedMsg',
}

export const disallowedLegacyTriggers: ConditionNameLegacy[] = [
	ConditionNameLegacy.IsVisitorServed,
	ConditionNameLegacy.VisitorPlatform,
	ConditionNameLegacy.NoAnswer,
	ConditionNameLegacy.IsVisitorCommunicate,
	ConditionNameLegacy.DelayPage,
]

export const legacyTriggersOpMap: Record<ConditionOpLegacy, string> = {
	[ConditionOpLegacy.Eq]: 'chatbotBuilder.triggerForm.equal',
	[ConditionOpLegacy.Neq]: 'chatbotBuilder.triggerForm.notEqual',
	[ConditionOpLegacy.Lt]: 'chatbotBuilder.triggerForm.lt',
	[ConditionOpLegacy.Gt]: 'chatbotBuilder.triggerForm.gt',
	[ConditionOpLegacy.Lte]: 'chatbotBuilder.triggerForm.lte',
	[ConditionOpLegacy.Gte]: 'chatbotBuilder.triggerForm.gte',
	[ConditionOpLegacy.Like]: 'chatbotBuilder.triggerForm.contains',
	[ConditionOpLegacy.Notlike]: 'chatbotBuilder.triggerForm.doesntContain',
	[ConditionOpLegacy.Nlike]: 'chatbotBuilder.triggerForm.doesntContain',
	[ConditionOpLegacy.Regex]: 'chatbot.comparators.regex',
}

export const legacyTriggersBySectionMap: Record<ChatbotSettingsSection, ConditionNameLegacy[]> = {
	[ChatbotSettingsSection.When]: [
		ConditionNameLegacy.DelayPage,
		ConditionNameLegacy.DelaySite,
		ConditionNameLegacy.IsVisitorSendMessage,
	],
	[ChatbotSettingsSection.Where]: [ConditionNameLegacy.VisitorPageUrl, ConditionNameLegacy.VisitorPageTitle],
	[ChatbotSettingsSection.Audience]: [
		ConditionNameLegacy.NumberOfVisits,
		ConditionNameLegacy.NumberOfChats,
		ConditionNameLegacy.VisitorReferer,
		ConditionNameLegacy.VisitorIp,
		ConditionNameLegacy.VisitorCity,
		ConditionNameLegacy.VisitorCountryCode,
		ConditionNameLegacy.VisitorGroup,
		ConditionNameLegacy.VisitorBrowser,
		ConditionNameLegacy.VisitorOs,
	],
	[ChatbotSettingsSection.Scheduling]: [
		ConditionNameLegacy.AccountStatus,
		ConditionNameLegacy.HourOfDay,
		ConditionNameLegacy.DayOfWeek,
		ConditionNameLegacy.MonthOfYear,
	],
	[ChatbotSettingsSection.Behavior]: [ConditionNameLegacy.IsTriggered],
}

export const legacyTriggersTriggerTypeMap: Record<TriggerType, string> = {
	[TriggerType.PageLoad]: 'autoMessages.launchMessage.ChatBoxVisible',
	[TriggerType.Message]: 'autoMessages.launchMessage.visitorSendsMessage',
	[TriggerType.WidgetOpen]: 'autoMessages.launchMessage.visitorOpensChat',
	[TriggerType.AgentBusy]: '',
	[TriggerType.PageUnchanged]: '',
	[TriggerType.AutomationIgnored]: '',
}
