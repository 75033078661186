export { WidgetButtonStyle, WidgetConfig, WidgetOrientation } from '@smartsupp/core-client-api'

export enum WidgetBanner {
	Arrow = 'arrow',
	Bubble = 'bubble',
	Image = 'image',
}

export enum WidgetPreviewType {
	Default,
	Chatbot,
}

export type WidgetPreviewAvatar = {
	isNewUpload: boolean
	image: string | null
}

export type WidgetStatus = 'online' | 'offline'
