import { mdiMessageFlashOutline, mdiRobotOutline, mdiShoppingOutline } from '@mdi/js'

import { TranslationService as T } from 'shared/services/TranslationService'
import { routes } from 'configuration/routes'

import { SubmenuLinkContent } from 'components/layout/submenu/SubmenuLinkContent'
import RouterLink from 'components/router/RouterLink'
import { PopoverMenu, PopoverMenuItem, PopoverMenuSection } from 'shared/components/core/Popover'

const SidebarAutomationsMenu = () => {
	return (
		<PopoverMenu width="225px">
			<PopoverMenuSection noPadding noBorder>
				<PopoverMenuItem>
					<RouterLink to={routes.templatesAll.path} data-testid="automations-templates">
						<SubmenuLinkContent pl={0} icon={mdiShoppingOutline}>
							{T.translate('general.templates')}
						</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem>
					<RouterLink to={routes.chatbots.path} data-testid="automations-chatbots">
						<SubmenuLinkContent pl={0} icon={mdiRobotOutline}>
							{T.translate('sideBar.automations.chatbots')}
						</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem>
					<RouterLink to={routes.autoMessages.path} data-testid="automations-autoMessages">
						<SubmenuLinkContent pl={0} icon={mdiMessageFlashOutline}>
							{T.translate('sideBar.automations.autoMessages')}
						</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
			</PopoverMenuSection>
		</PopoverMenu>
	)
}

export default SidebarAutomationsMenu
