export default class Logger {
	prefix: string

	prefixStyle: string

	constructor(prefix?: string, prefixStyle?: string) {
		this.prefix = prefix || 'Default'
		this.prefixStyle = prefixStyle || 'background: #4d4d4d; color: #fff'
	}

	log(name: string, data?: unknown) {
		if (process.env.NODE_ENV === 'production') return

		if (data) {
			/* eslint-disable-next-line no-console */
			console.log(`%c ${this.prefix}`, this.prefixStyle, name, data)
		} else {
			/* eslint-disable-next-line no-console */
			console.log(`%c ${this.prefix}`, this.prefixStyle, name)
		}
	}
}
