import { ChatsSearchRequest } from 'models/Chat'
import { DEFAULT_TIMEZONE } from 'constants/app'

import { ChatsFilter, ChatsFilterAdvanced } from './types'

export const defaultAdvancedFilter: ChatsFilterAdvanced = {
	agentIds: [],
	dateFrom: null,
	dateTo: null,
	groupId: '',
	locIp: '',
	query: '',
	pageUrl: '',
	ratings: [],
	visitorId: '',
	tags: [],
	botIds: [],
}

export const defaultRequestFilter: ChatsSearchRequest = {
	size: 100,
	query: [],
	timezone: DEFAULT_TIMEZONE,
	sort: [],
}

export const defaultFilter: ChatsFilter = {
	advanced: defaultAdvancedFilter,
	isAdvancedFilterActive: false,
	isDateFilterActive: false,
	moreAgentsVisible: true,
	filteredVisitorName: null,
	lastResponseAfter: null,
	requestFilterParams: defaultRequestFilter,
	moreChatbotsVisible: true,
	moreAutoMessagesVisible: true,
}
