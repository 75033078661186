import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { InvitationErrorItem, InvitationInfo, ResendInvitationsParams, SendInvitationsParams } from 'models'
import { DashboardState } from 'types'
import { ERROR_ALREADY_INVITED, ERROR_EMAIL_EXISTS, ERROR_INVITE_ERRORS, ERROR_TOO_MANY_AGENTS } from 'constants/errors'
import { agentsSelectors } from 'modules/agents'

import { invitationsApi } from './api'

export type InvitationsRootState = Pick<DashboardState, 'invitations'>
export type InvitationsState = typeof initialState

type SendInvitationsError = {
	errorCode?: string
}

const invitationsAdapter = createEntityAdapter<InvitationInfo>()

export const initialState = invitationsAdapter.getInitialState({
	isFetching: false,
	isPendingSend: false,
	isPendingCancel: false,
})

export const fetchInvitations = createAsyncThunk('invitations/FETCH', async () => {
	return invitationsApi.getInvitations()
})

export const sendInvitations = createAsyncThunk<
	InvitationInfo[],
	SendInvitationsParams,
	{ rejectValue: SendInvitationsError }
>('invitations/SEND', async (args, { rejectWithValue }) => {
	try {
		return await invitationsApi.sendInvitations(args)
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		let errorCode
		if (error.code === ERROR_INVITE_ERRORS) {
			const hasEmailExistsError = error.invitations?.some((i: InvitationErrorItem) => i.status === ERROR_EMAIL_EXISTS)
			if (hasEmailExistsError) {
				errorCode = ERROR_EMAIL_EXISTS
			}

			const hasAlreadyInvitedError = error.invitations?.some(
				(i: InvitationErrorItem) => i.status === ERROR_ALREADY_INVITED,
			)
			if (hasAlreadyInvitedError) {
				errorCode = ERROR_ALREADY_INVITED
			}
		}
		if (error.code === ERROR_TOO_MANY_AGENTS) {
			errorCode = ERROR_TOO_MANY_AGENTS
		}
		return rejectWithValue({ errorCode })
	}
})

export const resendInvitations = createAsyncThunk('invitations/RESEND', async (args: ResendInvitationsParams) => {
	return invitationsApi.resendInvitations(args)
})

export const cancelInvitations = createAsyncThunk('invitations/CANCEL', async (invitationIds: number[]) => {
	return invitationsApi.cancelInvitations(invitationIds)
})

const invitationsSlice = createSlice({
	name: 'invitations',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// Fetch invitations
		builder.addCase(fetchInvitations.pending, (state) => {
			state.isFetching = true
		})
		builder.addCase(fetchInvitations.fulfilled, (state, { payload }) => {
			state.isFetching = false
			invitationsAdapter.setAll(state, payload)
		})
		builder.addCase(fetchInvitations.rejected, (state) => {
			state.isFetching = false
		})

		// Send invitations
		builder.addCase(sendInvitations.pending, (state) => {
			state.isPendingSend = true
		})
		builder.addCase(sendInvitations.fulfilled, (state, { payload }) => {
			state.isPendingSend = false
			invitationsAdapter.addMany(state, payload)
		})
		builder.addCase(sendInvitations.rejected, (state) => {
			state.isPendingSend = false
		})

		// Cancel invitations
		builder.addCase(cancelInvitations.pending, (state) => {
			state.isPendingCancel = true
		})
		builder.addCase(cancelInvitations.fulfilled, (state, { meta }) => {
			state.isPendingCancel = false
			invitationsAdapter.removeMany(state, meta.arg)
		})
		builder.addCase(cancelInvitations.rejected, (state) => {
			state.isPendingCancel = false
		})
	},
})

const { reducer } = invitationsSlice
export default reducer

const entitySelectors = invitationsAdapter.getSelectors<InvitationsRootState>((state) => state.invitations)
const getIsPendingCreate = (state: InvitationsRootState) => state.invitations.isPendingSend
const getIsFetching = (state: InvitationsRootState) => state.invitations.isFetching
const getInvitations = (state: InvitationsRootState) => entitySelectors.selectAll(state)

const getInvitationSlots = createSelector(
	[getInvitations, agentsSelectors.getActiveAgentsCount, agentsSelectors.getMaxActiveAgentsCount],
	(invitations, activeAgentsCount, maxActiveAgentsCount) => {
		if (maxActiveAgentsCount === null) return null

		const activeSlots = activeAgentsCount + invitations.length
		return { activeSlots, maxSlots: maxActiveAgentsCount }
	},
)

export const invitationsSelectors = {
	getIsFetching,
	getIsPendingCreate,
	getInvitations,
	getInvitationSlots,
}
