import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Restriction } from 'models'
import { ApiError } from 'shared/types'
import { DashboardState } from 'types'
import { CreateRestrictionRequest, restrictionsApi } from 'modules/restrictions'

export type RestrictionsRootState = Pick<DashboardState, 'restrictions'>
export type RestrictionsState = typeof initialState

export const initialState = {
	restrictions: [] as Restriction[],
	isFetchingRestrictions: false,
	isCreatingRestrictions: false,
	isRestrictionsFormDirty: false,
	isError: false,
}

export const fetchRestrictions = createAsyncThunk('RESTRICTIONS/FETCH', () => {
	return restrictionsApi.fetchRestrictions()
})

export const deleteRestriction = createAsyncThunk('RESTRICTIONS/DELETE', (id: number) => {
	return restrictionsApi.deleteRestriction(id)
})

export const createRestriction = createAsyncThunk<Restriction, CreateRestrictionRequest, { rejectValue: ApiError }>(
	'RESTRICTIONS/CREATE',
	async (data: CreateRestrictionRequest, { rejectWithValue }) => {
		try {
			return await restrictionsApi.createRestriction(data)
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

const restrictionsSlice = createSlice({
	name: 'restrictions',
	initialState,
	reducers: {
		setIsFormDirty: (state, { payload }: PayloadAction<{ isFormDirty: boolean }>) => {
			const { isFormDirty } = payload
			state.isRestrictionsFormDirty = isFormDirty
		},
	},
	extraReducers: (builder) => {
		// FETCH RESTRICTIONS
		builder
			.addCase(fetchRestrictions.fulfilled, (state, { payload }) => {
				state.restrictions = payload
				state.isFetchingRestrictions = false
				state.isError = false
			})
			.addCase(fetchRestrictions.rejected, (state) => {
				state.isFetchingRestrictions = false
				state.isError = true
			})
			.addCase(fetchRestrictions.pending, (state) => {
				state.isFetchingRestrictions = true
				state.isError = false
			})

		// DELETE RESTRICTION
		builder.addCase(deleteRestriction.fulfilled, (state, { meta }) => {
			state.restrictions = state.restrictions.filter((restriction) => restriction.id !== meta.arg)
		})

		// CREATE RESTRICTION
		builder
			.addCase(createRestriction.fulfilled, (state, { payload }) => {
				state.isCreatingRestrictions = false
				state.restrictions = [...state.restrictions, payload]
			})
			.addCase(createRestriction.rejected, (state) => {
				state.isCreatingRestrictions = false
			})
			.addCase(createRestriction.pending, (state) => {
				state.isCreatingRestrictions = true
			})
	},
})

const getRestrictions = (state: RestrictionsRootState) => state.restrictions.restrictions
const getIsCreatingRestrictions = (state: RestrictionsRootState) => state.restrictions.isCreatingRestrictions
const getIsFetchingRestrictions = (state: RestrictionsRootState) => state.restrictions.isFetchingRestrictions
const getIsRestrictionsFormDirty = (state: RestrictionsRootState) => state.restrictions.isRestrictionsFormDirty
const getIsError = (state: RestrictionsRootState) => state.restrictions.isError

const { reducer, actions } = restrictionsSlice

export const { setIsFormDirty } = actions

export default reducer

export const restrictionsSelectors = {
	getRestrictions,
	getIsCreatingRestrictions,
	getIsFetchingRestrictions,
	getIsRestrictionsFormDirty,
	getIsError,
}
