import { Channel, ChannelType } from 'models'
import { apiClient } from 'shared/utils'
import { ChannelData, ChannelRequestData } from 'modules/channels/types'

const API_RESOURCE = 'channels'

const getChannels = () => {
	return apiClient.get<Channel[]>(`${API_RESOURCE}/list`)
}

const getChannelById = (channel: ChannelType, id: string) => {
	return apiClient.get<Channel>(`${API_RESOURCE}/${channel}/${id}`)
}

const createChannel = (channelType: ChannelType, data: ChannelData) => {
	return apiClient.post<Channel[]>(`${API_RESOURCE}/${channelType}`, data)
}

const deleteChannelById = (data: ChannelRequestData) => {
	const { type, id } = data
	return apiClient.delete(`${API_RESOURCE}/${type}/${id}`)
}

const deleteChannelsByType = (channelType: ChannelType) => {
	return apiClient.delete(`${API_RESOURCE}/${channelType}`)
}

export const channelsApi = {
	getChannels,
	getChannelById,
	createChannel,
	deleteChannelById,
	deleteChannelsByType,
}
