import {
	AutoMessageStatisticsDataType,
	TransformedAutoMessageStatistics,
	TransformedAutoMessageSummaryStatistics,
} from './types'

export const DEFAULT_STATISTICS_VALUE = 0

export const DEFAULT_AUTO_MESSAGE_STATISTICS_ARRAY: TransformedAutoMessageStatistics = [
	{ name: AutoMessageStatisticsDataType.Triggered, value: DEFAULT_STATISTICS_VALUE },
	{ name: AutoMessageStatisticsDataType.IncomingConversations, value: DEFAULT_STATISTICS_VALUE },
]

export const DEFAULT_AUTO_MESSAGE_SUMMARY_ARRAY: TransformedAutoMessageSummaryStatistics = {
	[AutoMessageStatisticsDataType.Triggered]: {
		value: DEFAULT_STATISTICS_VALUE,
		trend: DEFAULT_STATISTICS_VALUE,
	},
	[AutoMessageStatisticsDataType.IncomingConversations]: {
		value: DEFAULT_STATISTICS_VALUE,
		trend: DEFAULT_STATISTICS_VALUE,
	},
}

export const autoMessageTestingAttributes = {
	drawer: {
		drawer: 'auto-message-statistics-drawer',
		closeButton: 'auto-message-statistics-drawer-close',
	},
	chart: {
		lineChart: 'auto-message-statistics-line-chart',
		emptyNoData: 'auto-message-statistics-chart-empty-no-data',
		emptyNoDataSelected: 'auto-message-statistics-chart-empty-no-data-selected',
		legend: 'auto-message-statistics-chart-legend',
	},
	summary: {
		summary: 'auto-message-statistics-summary',
		summaryButton: 'auto-message-statistics-summary-button',
	},
}
