import { Contact, Visitor, VisitorsOpenChatResponse, VisitorsSearchRequest, VisitorsSearchResponse } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'v2/visitors'

const searchVisitors = (data: VisitorsSearchRequest) => {
	return apiClient.post<VisitorsSearchResponse>(`${API_RESOURCE}/search`, data)
}

const getVisitorChatId = (visitorId: string) => {
	return apiClient.post<VisitorsOpenChatResponse>(`${API_RESOURCE}/${visitorId}/chats`)
}

const identifyVisitor = (visitorId: string) => {
	return apiClient.post<Contact>(`${API_RESOURCE}/${visitorId}/identify`)
}

const fetchVisitor = (visitorId: string) => {
	return apiClient.get<Visitor>(`${API_RESOURCE}/${visitorId}`)
}

export const visitorsApi = {
	searchVisitors,
	getVisitorChatId,
	identifyVisitor,
	fetchVisitor,
}
