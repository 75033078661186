import { batch } from 'react-redux'
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunkAction, DashboardState } from 'types'
import { flashMessage } from 'shared/utils'
import { widgetSelectors } from 'modules/widget'
import {
	getTranslationsFormSubmitData,
	getWidgetAppearanceTranslations,
	Translations,
	WidgetAppearanceTranslations,
	WidgetAuthFormTranslations,
	WidgetSettingsTranslationsType,
	widgetTranslationsApi,
} from 'modules/widgetTranslations'

export const fetchCustomWidgetTranslations = createAsyncThunk('translations/FETCH/custom', (lang: string) => {
	return widgetTranslationsApi.fetchCustomWidgetTranslations(lang)
})

export const fetchDefaultWidgetTranslations = createAsyncThunk('translations/FETCH/defaults', (lang: string) => {
	return widgetTranslationsApi.fetchDefaultWidgetTranslations(lang)
})

export const putWidgetTranslations = createAsyncThunk(
	'translations/PUT',
	(args: { lang: string; data: Translations }) => {
		const { lang, data } = args
		return widgetTranslationsApi.putWidgetTranslations(lang, data)
	},
)

export const initWidgetTranslations =
	(widgetLanguage?: string): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const lang = widgetLanguage || widgetSelectors.getWidgetLang(state)

		if (!lang) return
		batch(() => {
			dispatch(fetchCustomWidgetTranslations(lang))
			dispatch(fetchDefaultWidgetTranslations(lang))
		})
	}

export const submitTranslationsForm =
	(formData: Translations): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const widgetLanguage = widgetSelectors.getWidgetLang(state)
		const defaultTranslations = widgetTranslationsSelectors.getDefaultWidgetTranslations(state)
		if (!widgetLanguage || !defaultTranslations) return

		const formSubmitData = getTranslationsFormSubmitData(formData, defaultTranslations)
		const resultAction = await dispatch(putWidgetTranslations({ lang: widgetLanguage, data: formSubmitData }))
		if (putWidgetTranslations.fulfilled.match(resultAction)) {
			flashMessage.success('general.changesSaved', { testId: 'flashMessage-success-translations-form' })
			dispatch(setIsFormDirty({ isFormDirty: false }))
		} else flashMessage.error('general.error')
	}

const initialState = {
	customTranslations: null as null | Translations,
	defaultTranslations: null as null | Translations,
	isFetchingCustomTranslations: false,
	isFetchingDefaultTranslations: false,
	isSubmitting: false,
	isFormDirty: false,
	errors: {
		isErrorCustom: false,
		isErrorDefault: false,
	},
}

const widgetTranslationsSlice = createSlice({
	name: 'widgetTranslations',
	initialState,
	reducers: {
		setIsFormDirty: (state, { payload }: PayloadAction<{ isFormDirty: boolean }>) => {
			const { isFormDirty } = payload
			state.isFormDirty = isFormDirty
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCustomWidgetTranslations.fulfilled, (state, { payload }) => {
				state.customTranslations = payload
				state.isFetchingCustomTranslations = false
				state.errors.isErrorCustom = false
			})
			.addCase(fetchCustomWidgetTranslations.pending, (state) => {
				state.isFetchingCustomTranslations = true
				state.errors.isErrorCustom = false
			})
			.addCase(fetchCustomWidgetTranslations.rejected, (state) => {
				state.isFetchingCustomTranslations = false
				state.errors.isErrorCustom = true
			})
		builder
			.addCase(fetchDefaultWidgetTranslations.fulfilled, (state, { payload }) => {
				state.defaultTranslations = payload
				state.isFetchingDefaultTranslations = false
				state.errors.isErrorDefault = false
			})
			.addCase(fetchDefaultWidgetTranslations.pending, (state) => {
				state.isFetchingDefaultTranslations = true
				state.errors.isErrorDefault = false
			})
			.addCase(fetchDefaultWidgetTranslations.rejected, (state) => {
				state.isFetchingDefaultTranslations = false
				state.errors.isErrorDefault = true
			})
		builder
			.addCase(putWidgetTranslations.fulfilled, (state) => {
				state.isSubmitting = false
			})
			.addCase(putWidgetTranslations.pending, (state) => {
				state.isSubmitting = true
			})
			.addCase(putWidgetTranslations.rejected, (state) => {
				state.isSubmitting = false
			})
	},
})

const { reducer, actions } = widgetTranslationsSlice
export const { setIsFormDirty } = actions
export default reducer

const getCustomWidgetTranslations = (state: DashboardState) => state.widgetTranslations.customTranslations
const getDefaultWidgetTranslations = (state: DashboardState) => state.widgetTranslations.defaultTranslations
const getIsFetchingCustomWidgetTranslations = (state: DashboardState) =>
	state.widgetTranslations.isFetchingCustomTranslations
const getIsFetchingDefaultWidgetTranslations = (state: DashboardState) =>
	state.widgetTranslations.isFetchingDefaultTranslations
const getIsSubmittingWidgetTranslations = (state: DashboardState) => state.widgetTranslations.isSubmitting
const getIsFormDirty = (state: DashboardState) => state.widgetTranslations.isFormDirty
const getErrors = (state: DashboardState) => state.widgetTranslations.errors

const getIsFetchingWidgetTranslations = createSelector(
	[getIsFetchingCustomWidgetTranslations, getIsFetchingDefaultWidgetTranslations],
	(isFetchingCustom, isFetchingDefault) => {
		return isFetchingCustom || isFetchingDefault
	},
)

const getFullWidgetTranslations = createSelector(
	[getCustomWidgetTranslations, getDefaultWidgetTranslations],
	(customTranslations, defaultTranslations): Translations | null => {
		if (!customTranslations || !defaultTranslations) return null

		return { ...defaultTranslations, ...customTranslations }
	},
)

const getWidgetAppearanceFormTranslations = createSelector(
	[getFullWidgetTranslations],
	(widgetTranslations): WidgetAppearanceTranslations | null => {
		if (!widgetTranslations) return null
		return getWidgetAppearanceTranslations(widgetTranslations)
	},
)

const getWidgetAuthFormTranslations = createSelector(
	[getFullWidgetTranslations],
	(widgetTranslations): WidgetAuthFormTranslations | null => {
		if (!widgetTranslations) return null
		return {
			authFormTitle: widgetTranslations[WidgetSettingsTranslationsType.AuthFormGreeting],
			authFormDesc: widgetTranslations[WidgetSettingsTranslationsType.AuthFormDescription],
		}
	},
)

const getIsError = createSelector([getErrors], (errors): boolean => {
	const { isErrorCustom, isErrorDefault } = errors
	return isErrorCustom || isErrorDefault
})

export const widgetTranslationsSelectors = {
	getCustomWidgetTranslations,
	getDefaultWidgetTranslations,
	getIsFetchingWidgetTranslations,
	getIsSubmittingWidgetTranslations,
	getIsFormDirty,
	getIsError,
	getFullWidgetTranslations,
	getWidgetAppearanceFormTranslations,
	getWidgetAuthFormTranslations,
}
