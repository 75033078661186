export {
	AiAssistAction,
	AiAssistTransformRequest,
	AiAssistTransformResponse,
} from '@smartsupp/smartsupp-backend-client'

export enum AiAssistActions {
	GrammarCheck = 'grammar_check',
	MakeFriendly = 'make_friendly',
	MakeProfessional = 'make_professional',
	ExpandMessage = 'expand_message',
}
