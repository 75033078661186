import { forwardRef, Ref } from 'react'
import { Text as ChakraText, TextProps } from '@chakra-ui/react'

type Props = TextProps & {
	isTruncated?: boolean
}

export const Text = forwardRef<HTMLParagraphElement, Props>(
	({ isTruncated = false, ...rest }, ref: Ref<HTMLParagraphElement>) => {
		return <ChakraText ref={ref} {...(isTruncated && { noOfLines: 1, wordBreak: 'break-all' })} {...rest} />
	},
)

Text.displayName = 'Text'

export type { TextProps } from '@chakra-ui/react'
