import { array, date, InferType, mixed, number, object, string } from 'yup'

import { ChatRating, ChatsSearchRequest, OpenChatsFilterType } from 'models'

const baseAdvancedFilterSchema = object({
	agentIds: array().of(number().nonNullable().defined()).strict(true).defined().nonNullable(),
	groupId: string().strict(true).defined().nonNullable(),
	locIp: string().strict(true).defined().nonNullable(),
	pageUrl: string().strict(true).defined().nonNullable(),
	query: string().strict(true).defined().nonNullable(),
	ratings: array()
		.of(mixed<ChatRating>().oneOf([1, 3, 5]).strict(true).defined())
		.defined()
		.nonNullable(),
	visitorId: string().strict(true).defined().nonNullable(),
	tags: array().of(string().nonNullable().defined()).strict(true).defined().nonNullable(),
	dateFrom: date().nullable().defined(),
	dateTo: date().nullable().defined(),
	botIds: array().of(string().nonNullable().defined()).strict(true).defined().nonNullable(),
}).defined()

export const serializedAdvancedFilterSchema = baseAdvancedFilterSchema
	.shape({
		dateFrom: number().positive().defined().nullable(),
		dateTo: number().positive().defined().nullable(),
	})
	.defined()

export type ChatsFilterAdvanced = InferType<typeof baseAdvancedFilterSchema>

export type SerializedChatsFilterAdvanced = Omit<ChatsFilterAdvanced, 'dateFrom' | 'dateTo'> & {
	dateFrom: number | null
	dateTo: number | null
}

export interface ChatsFilter {
	advanced: ChatsFilterAdvanced
	isAdvancedFilterActive: boolean
	isDateFilterActive: boolean
	moreAgentsVisible: boolean
	filteredVisitorName: string | null
	lastResponseAfter: number[] | null
	requestFilterParams: ChatsSearchRequest
	moreChatbotsVisible: boolean
	moreAutoMessagesVisible: boolean
}

export type ChatsCounters = Record<OpenChatsFilterType, number>
