import { FeatureUsageName } from 'models'
import { Dictionary } from 'types'
import { UserPreference } from 'shared/constants'
import { Account } from 'shared/models/Account'
import { User } from 'shared/models/User'
import { normalize } from 'utils/normalize'
import {
	FeatureUsageAccount,
	FeatureUsageUser,
	ProgressBarCounts,
	ProgressBarItemsUsage,
	ProgressBarUserItem,
} from 'modules/features/types'

export const getEnvFeatureFlags = (): string[] => {
	const featureFlags = process.env.APP_FEATURE_FLAGS
	return featureFlags?.split(';') ?? []
}

export const getLocalStorageFeatureFlags = (): string[] => {
	const featureFlags = window.localStorage.getItem(UserPreference.FeatureFlags)
	if (!featureFlags) return []

	try {
		return JSON.parse(featureFlags)
	} catch {
		return []
	}
}

export const getBasicProgressBarData = (
	usageUser: Partial<FeatureUsageUser>,
	user: User,
	chatCodeInstalledFirstAt: Account['installedFirstAt'],
	isUserAdmin: boolean,
): Dictionary<ProgressBarItemsUsage> => {
	const limitedAccessItems: ProgressBarItemsUsage[] = [
		{
			name: FeatureUsageName.AvatarUploaded,
			isUsed: usageUser.avatarUploaded?.lastUsedAt !== null,
		},
		{
			name: FeatureUsageName.ConversationsVisited,
			isUsed: usageUser.conversationsVisited?.lastUsedAt !== null,
		},
		{ name: ProgressBarUserItem.MobileAppLastUsed, isUsed: !!user?.lastLoginMobile },
	]

	const remainingItems: ProgressBarItemsUsage[] = [
		{ name: ProgressBarUserItem.ChatCodeInstalledFirstAt, isUsed: !!chatCodeInstalledFirstAt },
		{
			name: FeatureUsageName.AutoMessagesVisited,
			isUsed: usageUser.autoMessagesVisited?.lastUsedAt !== null,
		},
	]

	if (isUserAdmin) return normalize('name', [...limitedAccessItems, ...remainingItems])

	return normalize('name', limitedAccessItems)
}

export const getSupportProgressBarData = (
	usageUser: Partial<FeatureUsageUser>,
	usageAccount: Partial<FeatureUsageAccount>,
): Dictionary<ProgressBarItemsUsage> => {
	const data: ProgressBarItemsUsage[] = [
		{
			name: FeatureUsageName.OfficeHoursEnabled,
			isUsed: usageAccount.officeHoursEnabled?.lastUsedAt !== null,
		},
		{
			name: FeatureUsageName.ShortcutsTeam,
			isUsed: usageUser.shortcutsTeam?.lastUsedAt !== null || usageUser.shortcutsPersonal?.lastUsedAt !== null,
		},
		{
			name: FeatureUsageName.ActivateChatbot,
			isUsed: usageAccount.activateChatbot?.lastUsedAt !== null,
		},
		{
			name: FeatureUsageName.ConversationTagged,
			isUsed: usageUser.conversationTagged?.lastUsedAt !== null,
		},
		{
			name: FeatureUsageName.ContactFormEnabled,
			isUsed: usageAccount.contactFormEnabled?.lastUsedAt !== null,
		},
	]

	return normalize('name', data)
}

export const getSalesProgressBarData = (
	usageUser: Partial<FeatureUsageUser>,
	usageAccount: Partial<FeatureUsageAccount>,
): Dictionary<ProgressBarItemsUsage> => {
	const data: ProgressBarItemsUsage[] = [
		{
			name: FeatureUsageName.AutoMessageCreated,
			isUsed: usageAccount.autoMessageCreated?.lastUsedAt !== null,
		},
		{
			name: FeatureUsageName.VisitorListLastVisited,
			isUsed: usageUser.visitorListLastVisited?.lastUsedAt !== null,
		},
		{
			name: FeatureUsageName.ActivateChatbot,
			isUsed: usageAccount.activateChatbot?.lastUsedAt !== null,
		},
		{
			name: FeatureUsageName.ContactFormEnabled,
			isUsed: usageAccount.contactFormEnabled?.lastUsedAt !== null,
		},
	]

	return normalize('name', data)
}

export const getProgressBarCounts = (itemsUsage: Dictionary<ProgressBarItemsUsage>): ProgressBarCounts => {
	const itemsUsageArray = Object.values(itemsUsage)
	const countTotal = itemsUsageArray.length
	const countDone = itemsUsageArray.filter((item) => item.isUsed).length

	return { countTotal, countDone }
}
