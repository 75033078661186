export type ValueUnit = 'px' | 'rem' | '%'

export const unit =
	(valueUnit: ValueUnit) =>
	(value: number): string => {
		if (valueUnit === 'px') {
			return `${value}${valueUnit}`
		}
		if (valueUnit === '%') {
			return `${(value / 14) * 100}${valueUnit}`
		}
		if (valueUnit === 'rem') {
			return `${value / 14}${valueUnit}`
		}
		return `${value}`
	}

export const px = unit('px')
