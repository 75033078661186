import { PropertiesCreateRequest, PropertiesUpdateRequest } from '@smartsupp/smartsupp-backend-client'

export { Property } from '@smartsupp/smartsupp-backend-client'
export { Properties } from '@smartsupp/websocket-client-agent'

export const ERROR_CODE_PROPERTY_KEY_EXISTS = 'duplicate_key'
export const FIELD_PROPERTY_KEY = 'key'

export type PropertyCreateBody = PropertiesCreateRequest
export type PropertyUpdate = PropertiesUpdateRequest

export type PropertyUpdateBody = {
	id: string
	updatedName: string
}

export enum SelectOptionsLabels {
	String = 'string',
	Number = 'number',
	Boolean = 'boolean',
}

export type PropertySelectOptionType = {
	value: string
	label: string
}
