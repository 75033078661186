import { PackageName } from '@smartsupp/billing-client-api'

import { AppThunkAction } from 'types'
import { updateDataLayer } from 'shared/services'
import { checkoutSelectors } from 'modules/checkout'
import { packageSelectors } from 'modules/package'
import { userSelectors } from 'modules/user'

export const updateDataLayerPackageSelect =
	(packageName: PackageName): AppThunkAction =>
	(_, getState) => {
		const state = getState()
		const interval = checkoutSelectors.getCheckoutInterval(state)
		const packageInfo = packageSelectors.getPackageInfo(state)

		// exclude cases for expired package
		if (!packageInfo || packageInfo.isExpired) return

		updateDataLayer({
			event: 'ec.packageChosen',
			ecommerce: {
				purchase: {
					products: [
						{
							name: packageName,
							variant: interval,
						},
					],
				},
			},
		})
	}

export const updateDataLayerConfiguredPackage = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const calculation = checkoutSelectors.getCheckoutCalculation(state)
	const packageConfig = checkoutSelectors.getCheckoutPackageConfiguration(state)
	const packageInfo = packageSelectors.getPackageInfo(state)

	if (!calculation || !packageConfig) return
	const { currencyCode, priceTotalUntaxed } = calculation

	// exclude cases for expired package
	if (!packageInfo || packageInfo.isExpired) return

	const data = {
		event: 'ec.packageReady',
		ecommerce: {
			currencyCode: currencyCode.toUpperCase(),
			purchase: {
				actionField: {
					revenue: priceTotalUntaxed,
				},
				products: [
					{
						name: packageConfig.package,
						price: priceTotalUntaxed,
						variant: packageConfig.interval,
						quantity: 1,
					},
				],
			},
		},
	}

	updateDataLayer(data)
}

export const updateDataLayerSignUpComplete = () => {
	updateDataLayer({ event: 'signup_complete' })
}

export const updateDataLayerMiraPublish = (): AppThunkAction => (_, getState) => {
	const state = getState()
	const user = userSelectors.getActiveUser(state)
	updateDataLayer({ event: 'mira_publish', eventLabel: `role=${user?.role}` })
}
