import { Chatbot } from 'models'

export enum ChatbotWizardStepType {
	Message = 'message',
	Reply = 'reply',
}

export enum ChatbotWizardSource {
	Url = 'url',
	Onboarding = 'onboarding',
	AiOnboarding = 'aiOnboarding',
}

export type ChatbotWizardStep = {
	id: string
	type: ChatbotWizardStepType
}

export type ChatbotWizardReplyFormData = {
	question: string
	answer: string
}

export interface ChatbotQuestionNode {
	id: string
	isActive: boolean
	textMessage: string
	interactions: ChatbotQuestionNodeInteraction[]
}

export interface ChatbotQuestionNodeInteraction {
	id: string
	question: string
	answer: string
}

export interface CreateChatbotFromWizardConfig {
	isActive: boolean
	onSuccess: (botId?: string) => void
	appendReplies?: boolean
}

export type ChatbotForWizard = Pick<Chatbot, 'id' | 'interactions' | 'isActive'>
