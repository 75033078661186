import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { Shortcut, ShortcutCreateRequest, ShortcutEditParams, ShortcutExtended } from 'models'
import { ApiError } from 'shared/types'
import { AppThunkAction, DashboardState, Dictionary } from 'types'
import { normalizeString } from 'shared/utils'
import { normalize } from 'utils'
import { packageSelectors } from 'modules/package'

import { shortcutsApi } from './api'
import { ShortcutsDirection } from './constants'
import { markHasShortcutsUniqueName } from './utils'

export type ShortcutsState = typeof initialState
export type ShortcutsRootState = Pick<DashboardState, 'shortcuts'>

const shortcutsAdapter = createEntityAdapter<ShortcutExtended>()

export const initialState = shortcutsAdapter.getInitialState({
	filterText: '',
	isFetching: false,
	isCreating: false,
	modalOpen: false,
	initialModalText: '',
	selectedIndex: 0,
	visible: false,
})

export const fetchShortcuts = createAsyncThunk('SHORTCUTS/FETCH', () => {
	return shortcutsApi.fetchShortcuts()
})

export const createShortcut = createAsyncThunk<Shortcut, ShortcutCreateRequest, { rejectValue: ApiError }>(
	'SHORTCUTS/CREATE',
	async (data: ShortcutCreateRequest, { rejectWithValue }) => {
		try {
			return await shortcutsApi.createShortcut(data)
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

export const deleteShortcut = createAsyncThunk('SHORTCUTS/DELETE', (id: number) => {
	return shortcutsApi.deleteShortcut(id)
})

export const editShortcut = createAsyncThunk<Shortcut, ShortcutEditParams, { rejectValue: ApiError }>(
	'SHORTCUTS/EDIT',
	async ({ id, changes }, { rejectWithValue }) => {
		try {
			return await shortcutsApi.editShortcut(id, changes)
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

export const logUsageShortcutId = createAsyncThunk('SHORTCUTS/USAGE_ID', (id: Shortcut['id']) => {
	shortcutsApi.logUsageShortcutId(id)
})

export const onChangeSelect =
	(moveDirection: ShortcutsDirection): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const shortcutsCount = shortcutsSelectors.getFilteredShortcutsCount(state)
		const selectedIndex = shortcutsSelectors.getSelectedIndex(state)

		let newIndex = 0

		switch (moveDirection) {
			case ShortcutsDirection.Up: {
				newIndex = selectedIndex <= 0 ? 0 : selectedIndex - 1
				break
			}
			case ShortcutsDirection.Down: {
				newIndex = selectedIndex >= shortcutsCount - 1 ? shortcutsCount - 1 : selectedIndex + 1
				break
			}
		}
		dispatch(shortcutsSlice.actions.selectIndex({ newIndex }))
	}

const shortcutsSlice = createSlice({
	name: 'shortcuts',
	initialState,
	reducers: {
		modalOpen(state, action) {
			const { text } = action.payload
			state.modalOpen = true
			state.initialModalText = text
		},
		modalClose(state) {
			state.modalOpen = false
			state.initialModalText = ''
		},
		selectIndex(state, action) {
			const { newIndex } = action.payload
			state.selectedIndex = newIndex
		},
		setFilterText(state, action) {
			const { filterText } = action.payload
			state.filterText = filterText
			state.selectedIndex = 0
		},
		showShortcuts(state) {
			state.visible = true
		},
		hideShortcuts(state) {
			state.visible = false
			state.selectedIndex = 0
		},
	},
	extraReducers: (builder) => {
		// FETCH ALL SHORTCUTS
		builder.addCase(fetchShortcuts.fulfilled, (state, { payload }) => {
			shortcutsAdapter.setAll(state, markHasShortcutsUniqueName(payload))
			state.isFetching = false
		})
		builder.addCase(fetchShortcuts.rejected, (state) => {
			state.isFetching = false
		})
		builder.addCase(fetchShortcuts.pending, (state) => {
			state.isFetching = true
		})

		// CREATE NEW SHORTCUT
		builder.addCase(createShortcut.fulfilled, (state) => {
			state.isCreating = false
			state.modalOpen = false
			state.initialModalText = ''
		})
		builder.addCase(createShortcut.rejected, (state) => {
			state.isCreating = false
		})
		builder.addCase(createShortcut.pending, (state) => {
			state.isCreating = true
		})

		// Edit shortcut
		builder.addCase(editShortcut.fulfilled, (state) => {
			state.isCreating = false
		})
		builder.addCase(editShortcut.rejected, (state) => {
			state.isCreating = false
		})
		builder.addCase(editShortcut.pending, (state) => {
			state.isCreating = true
		})
	},
})

const { actions, reducer } = shortcutsSlice
export const { modalOpen, modalClose, hideShortcuts, showShortcuts, setFilterText } = actions
export default reducer

const entitySelectors = shortcutsAdapter.getSelectors<ShortcutsRootState>((state) => state.shortcuts)
export const getShortcuts = (state: ShortcutsRootState): ShortcutExtended[] => entitySelectors.selectAll(state)
export const getShortcutsTotal = (state: ShortcutsRootState) => entitySelectors.selectTotal(state)
const getFilterText = (state: ShortcutsRootState) => state.shortcuts.filterText
export const getModalOpen = (state: ShortcutsRootState) => state.shortcuts.modalOpen
export const getSelectedIndex = (state: ShortcutsRootState) => state.shortcuts.selectedIndex
export const getIsCreating = (state: ShortcutsRootState) => state.shortcuts.isCreating
export const getInitialModalText = (state: ShortcutsRootState) => state.shortcuts.initialModalText
export const isVisible = (state: ShortcutsRootState) => state.shortcuts.visible
const getShortcutId = (state: ShortcutsRootState, shortcutId: number | null) => shortcutId

const getShortcutsEntities = createSelector([getShortcuts], (shortcuts): Dictionary<ShortcutExtended> => {
	return normalize('id', shortcuts)
})

const makeGetShortcutById = () => {
	return createSelector([getShortcutsEntities, getShortcutId], (shortcuts, shortcutId) => {
		if (!shortcutId) return null
		return shortcuts[shortcutId]
	})
}

export const getFilteredShortcuts = createSelector(
	[getShortcuts, getFilterText],
	(shortcuts, filterText): ShortcutExtended[] => {
		const filterByText = (text: string) => (shortcut: ShortcutExtended) =>
			normalizeString(shortcut.name).startsWith(normalizeString(text))
		const sortByName = (a: ShortcutExtended, b: ShortcutExtended) => a.name.localeCompare(b.name)
		return shortcuts
			.filter((item) => item.active && item.hasUniqueName)
			.filter(filterByText(filterText))
			.sort(sortByName)
	},
)

export const getAllSortedShortcuts = createSelector([getShortcuts], (shortcuts) => {
	const sortByName = (a: ShortcutExtended, b: ShortcutExtended) => a.name.localeCompare(b.name)
	return shortcuts.sort(sortByName)
})

export const getActiveShortcutsCount = createSelector([getShortcuts], (shortcuts): number => {
	return shortcuts.filter((item) => item.active).length
})

export const getFilteredShortcutsCount = createSelector([getFilteredShortcuts], (shortcuts): number => {
	if (!shortcuts) return 0
	return shortcuts.length
})

export const getSelectedShortcut = createSelector(
	[getFilteredShortcuts, getSelectedIndex],
	(shortcuts, selectedIndex): ShortcutExtended | null => {
		if (!shortcuts || !shortcuts[selectedIndex]) return null
		return shortcuts[selectedIndex]
	},
)

export const canShortcutBeCreated = createSelector(
	[getActiveShortcutsCount, packageSelectors.getPackageInfo],
	(shortcutsCount, packageInfo): boolean => {
		if (!packageInfo) return false
		if (!packageInfo.userShortcuts) return true // unlimited shortcuts
		return shortcutsCount < packageInfo.userShortcuts
	},
)
export const shortcutsSelectors = {
	getShortcuts,
	getModalOpen,
	getSelectedIndex,
	getIsCreating,
	getInitialModalText,
	isVisible,
	getFilteredShortcuts,
	getAllSortedShortcuts,
	getActiveShortcutsCount,
	getFilteredShortcutsCount,
	getSelectedShortcut,
	canShortcutBeCreated,
	makeGetShortcutById,
}
