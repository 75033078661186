import { MetricsRequestBodyParams, MetricsResponse } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'v2/metrics'

const getSingleMetric = (body: { items: MetricsRequestBodyParams[] }) => {
	return apiClient.post<MetricsResponse[]>(`${API_RESOURCE}/bulk`, body)
}

export const tagsStatisticsApi = { getSingleMetric }
