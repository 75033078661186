import { RangeType } from 'models/Analytics'
import { Chatbot } from 'models/Chatbot'
import { ChatbotApi } from 'models/ChatbotStatistics'
import { apiClient } from 'shared/utils'

const API_RESOURCE_CHATBOT_STATS = 'analytics-api/v1/chatbot/stats'

const getStatistics = ({ range, fields }: ChatbotApi.StatsQuery) => {
	const fieldsQuery = fields && fields.length > 0 ? `&fields=${fields.join(',')}` : ''
	return apiClient.get<ChatbotApi.StatsResponse>(`${API_RESOURCE_CHATBOT_STATS}?range=${range}${fieldsQuery}`)
}

const getBotStatistics = (botId: Chatbot['id'], range: RangeType) => {
	return apiClient.get<ChatbotApi.StatsResponse>(`${API_RESOURCE_CHATBOT_STATS}/${botId}?range=${range}`)
}

const getBotInteractionsStatistics = (botId: Chatbot['id'], { range, sort }: ChatbotApi.InteractionsStatsQuery) => {
	return apiClient.get<ChatbotApi.InteractionsStatsResponse>(
		`${API_RESOURCE_CHATBOT_STATS}/${botId}/interactions?range=${range}&sort=${sort}`,
	)
}

const getAggregatedBotStatistics = (botId: Chatbot['id'], { range }: ChatbotApi.StatsSingleAggregatedQuery) => {
	return apiClient.get<ChatbotApi.StatsSingleAggregatedResponse>(
		`${API_RESOURCE_CHATBOT_STATS}/${botId}/aggregated?range=${range}`,
	)
}

export const chatbotStatsApi = {
	getStatistics,
	getBotStatistics,
	getBotInteractionsStatistics,
	getAggregatedBotStatistics,
}
