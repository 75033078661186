import { ReactNode } from 'react'

import {
	AllowedActionTypes,
	ChatbotAction,
	ChatbotActionContactRetrieveProperty,
	ChatbotActionHandoverV2Data,
	ChatbotActionType,
	SelectedChatbotReply,
} from 'models'

export enum ChatbotBuilderNodeType {
	ChatMessage = 'chat_message',
	Trigger = 'trigger',
}

export interface ChatbotBuilderNode {
	id: string
	title?: string
	content?: string
	nodes: ChatbotBuilderNode[]
	type: AllowedActionTypes
}

// Extended ChatbotBuilderNode structure used to move the node
export interface ChatbotBuilderMovableOrCopiableNode extends ChatbotBuilderNode {
	isDisabled: boolean
	nodes: ChatbotBuilderMovableOrCopiableNode[]
}

export interface ChatbotInteractionData {
	id: string | null
	type?: ChatbotActionType
	group?: string
	appId?: string
	actionId?: string
	actions?: ChatbotAction[]
}

export interface ChatbotTitleFormData {
	name: string
}

export interface ChatbotBuilderListViewProps {
	onNodeClick: (nodeType: ChatbotBuilderNodeType, id?: string, level?: number) => void
	drawer: ReactNode
}

export type ChatbotAdditionalActionTypes = Extract<
	ChatbotActionType,
	typeof ChatbotActionType.ChatAddTags | typeof ChatbotActionType.ContactAddTags
>

export type ChatbotAdditionalAction = {
	name: ChatbotAdditionalActionTypes
	data: string[]
}

export type ChatbotInteractionRequireApprovalData = {
	isEnabled: boolean
	url: string | null
	text: string | null
}

export type ChatbotBuilderInteractionFormData = {
	interactionId: string
	title?: string
	group?: string
	botTransfer: string | null
	replies: SelectedChatbotReply[]
	message: string | null
	appId?: string
	actionId?: string
	actions: ChatbotAdditionalAction[]
	retrievePropertyActions: ChatbotActionContactRetrieveProperty[]
	requireApprovalAction: ChatbotInteractionRequireApprovalData
	online: ChatbotActionHandoverV2Data['online'] | null
	offline: ChatbotActionHandoverV2Data['offline'] | null
}

export interface ChatbotBuilderMoveOrCopyNodeOption {
	label: string
	value: string
	level: number
	isDisabled: boolean
}

export interface ChatbotBuilderBotSelectOption {
	label: string
	value: string
	isSelectedBot: boolean
}

export enum SelectedChatbotSection {
	Design = 'design',
	Statistics = 'statistics',
	Settings = 'settings',
	Workflow = 'workflow',
}

export enum PublishButtonPopoverType {
	Publish = 'publish',
	SavedAsDraft = 'savedAsDraft',
}

export enum ChatbotPublishSourceType {
	Button = 'publishButton',
	Modal = 'publishModal',
	Workflow = 'publishWorkflow',
}
