import { alertAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, ThemingProps } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(alertAnatomy.keys)

const baseStyle = definePartsStyle({
	container: {
		px: 2,
		py: 1.5,
	},
	icon: {
		mr: 1.5,
	},
})

const variants = {
	'left-accent': (props: ThemingProps) => ({
		container: {
			pl: 1.5,
			borderColor: `${props.colorScheme}.500`,
		},
		icon: {
			color: `${props.colorScheme}.500`,
		},
	}),
	'top-accent': (props: ThemingProps) => ({
		container: {
			pt: 1,
			borderColor: `${props.colorScheme}.500`,
		},
		icon: {
			color: `${props.colorScheme}.500`,
		},
	}),
	subtle: (props: ThemingProps) => ({
		icon: {
			color: `${props.colorScheme}.500`,
		},
	}),
	solid: (props: ThemingProps) => ({
		icon: {
			color: `${props.colorScheme}.500`,
		},
	}),
}

export const Alert = defineMultiStyleConfig({
	baseStyle,
	variants,
})
