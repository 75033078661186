/* eslint-disable global-require */
import { AnyAction, configureStore, getDefaultMiddleware, Middleware } from '@reduxjs/toolkit'
import { ThunkMiddleware } from 'redux-thunk'

import { SharedThunkAction } from 'shared/types'
import { ApiClient, apiClient } from 'shared/utils'
import ActionsThrottleDispatcher from 'dispatchers/ActionsThrottleDispatcher'
import { Dispatcher } from 'dispatchers/Dispatcher'
import { dispatcherMiddleware, notificationsMiddleware } from 'middleware'
import { Logger } from 'services'
import { sortVisitors } from 'modules/visitors'

import rootReducer, { DashboardState } from './rootReducer'
import { createStoreObserver } from './utils'

interface HotNodeModule extends NodeModule {
	hot?: {
		accept: (id: string, cb: () => void) => void
	}
}

declare const module: HotNodeModule

const isEnvProduction = process.env.NODE_ENV === 'production'

const THROTTLE_DISPATCHER_TIMEOUT = 5000

const throttleDispatcherLogger = new Logger('Throttle dispatcher', 'background: #19B719; color: #fff')

const getBundleDispatchers = () => {
	const throttleDispatcher = new ActionsThrottleDispatcher(
		THROTTLE_DISPATCHER_TIMEOUT,
		sortVisitors.type,
		throttleDispatcherLogger,
	)

	const bundleDispatchers = new Map<string, Dispatcher>()
	bundleDispatchers.set(sortVisitors.type, throttleDispatcher)
	return bundleDispatchers
}

const defaultMiddleware = getDefaultMiddleware<
	DashboardState,
	{ thunk: { extraArgument: { api: ApiClient } }; immutableCheck: boolean; serializableCheck: boolean }
>({
	thunk: {
		extraArgument: {
			api: apiClient,
		},
	},
	immutableCheck: false,
	serializableCheck: false,
})

const middleware: Array<
	Middleware<unknown, DashboardState> | ThunkMiddleware<DashboardState, AnyAction, { api: ApiClient }>
> = [...defaultMiddleware, dispatcherMiddleware(getBundleDispatchers()), notificationsMiddleware]

const store = configureStore({
	reducer: rootReducer,
	middleware,
	devTools: !isEnvProduction,
})

if (process.env.NODE_ENV === 'development' && module.hot) {
	module.hot.accept('./rootReducer', async () => {
		const newRootReducer = await import('./rootReducer')
		store.replaceReducer(newRootReducer.default)
	})
}

export type AppDispatch = typeof store.dispatch

export type AppThunkAction<ReturnType = void> = SharedThunkAction<ReturnType, DashboardState>

export default store

export const storeObserver = createStoreObserver<DashboardState>(store)
