import { UserRole } from 'models'
import { ConfigurationService, logException } from 'shared/services'
import { flashMessage } from 'shared/utils'
import { agentClientProvider } from 'utils/smartsuppClient'
import { signOut } from 'modules/gtm'
import { isRoleAdmin } from 'modules/user/utils'

export const allowAdminUser = (userRole: UserRole) => isRoleAdmin(userRole)

export const logout = async () => {
	const agentClient = agentClientProvider.getAgentClient()
	const { keycloakLogoutUrl } = ConfigurationService.getData()
	const restIdToken = ConfigurationService.getRestIdToken()

	const logoutUrl = `${keycloakLogoutUrl}&id_token_hint=${restIdToken}`

	signOut()

	try {
		if (agentClient) {
			await agentClient.disconnect()
		}
		document.location.href = logoutUrl
	} catch (error) {
		if (error instanceof Error) {
			logException(error)
		}
		flashMessage.error('general.error')
	}
}
