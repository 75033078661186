type ParsedIntPrice = {
	formattedValue: string
	prefix?: string
	suffix?: string
}

export const parseIntlPrice = (value: string): ParsedIntPrice | null => {
	const prefixMatch = value.trim().match(/^(\D+)\s*([\d\s,.]+)/)
	const suffixMatch = value.trim().match(/([\d\s,.]+)\s*(\D+)$/)

	if (prefixMatch) {
		const formattedValue = prefixMatch[2].trim()
		const prefix = prefixMatch[1].trim()

		if (formattedValue === '' || prefix === '') return null

		return {
			formattedValue,
			prefix,
		}
	}

	if (suffixMatch) {
		const formattedValue = suffixMatch[1].trim()
		const suffix = suffixMatch[2].trim()

		if (formattedValue === '' || suffix === '') return null

		return {
			formattedValue,
			suffix,
		}
	}

	return null
}
