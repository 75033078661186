import { useSelector } from 'react-redux'

import { chatbotSettingsSelectors } from 'modules/chatbotSettings/slice'
import { upsellSelectors } from 'modules/upsell'

interface ChatbotSettingsErrorType {
	hasAudienceSettingsError: boolean
}

export const useChatbotSettingsError = (): ChatbotSettingsErrorType => {
	const { hasInvalidGroupConditions, hasGroupConditions } = useSelector(
		chatbotSettingsSelectors.getAudienceGroupConditionsState,
	)
	const hasGroupsEnabled = useSelector(upsellSelectors.hasEnabledGroupsFeature)

	return {
		hasAudienceSettingsError: hasInvalidGroupConditions || (hasGroupConditions && !hasGroupsEnabled),
	}
}
