import { ReactNode } from 'react'
import { css } from '@emotion/react'

import { Theme } from 'styles'

type Props = {
	children?: ReactNode
	center?: boolean
	noBorder?: boolean
	noPadding?: boolean
}

const PopoverMenuSection = ({ children, center, noBorder, noPadding }: Props) => {
	return <div css={menuSectionCss(center, noBorder, noPadding)}>{children}</div>
}

const menuSectionCss =
	(center = false, noBorder = false, noPadding = false) =>
	(theme: Theme) =>
		css(
			{
				padding: `12px ${theme.space[3]}`,
			},
			!noBorder && {
				borderBottom: `${theme.borders[1]} ${theme.colors.gray[300]}`,
			},
			center && {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			},
			noPadding && {
				padding: 0,
			},
		)

export default PopoverMenuSection
