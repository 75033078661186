import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Channel, ChannelType, FacebookTokenStatus } from 'models'
import { SmartsuppWindow } from 'shared/types'
import { AppThunkAction, DashboardState } from 'types'
import { FeatureUsageName } from 'shared/models/Feature'
import { ConfigurationService } from 'shared/services'
import { flashMessage } from 'shared/utils'
import { channelsApi } from 'modules/channels/api'
import { logFeatureUsage } from 'modules/features'

import { ChannelData, ChannelDataPage, ChannelRequestData, PagesListResponse } from './types'

declare const window: SmartsuppWindow

const channelsAdapter = createEntityAdapter<Channel>()

export const initialState = channelsAdapter.getInitialState({
	isFacebookLogged: false,
	isChannelsLoaded: false,
	selectedChannelDetail: null as null | Channel,
	isDeletePending: false,
	isDisconnectingChannels: false,
})

export type ChannelsState = typeof initialState
export type ChannelsRootState = Pick<DashboardState, 'channels'>

export const createFacebookChannel = createAsyncThunk('channels/CREATE', async (data: ChannelData) => {
	return channelsApi.createChannel(ChannelType.FacebookMessenger, data)
})

export const fetchChannels = createAsyncThunk('channels/FETCH', async () => {
	return channelsApi.getChannels()
})

export const deleteChannel = createAsyncThunk('channels/DELETE', async (data: ChannelRequestData) => {
	return channelsApi.deleteChannelById(data)
})

export const fetchChannelDetailById = createAsyncThunk('channels/GET_CHANNEL', async (id: string) => {
	return channelsApi.getChannelById(ChannelType.FacebookMessenger, id)
})

export const disconnectChannelsByType = createAsyncThunk('channels/DELETE_CHANNELS', async (type: ChannelType) => {
	return channelsApi.deleteChannelsByType(type)
})

export const fbInit = (): AppThunkAction => (dispatch) => {
	const { facebookAppId } = ConfigurationService.getData()
	if (facebookAppId) {
		window.fbAsyncInit = () => {
			FB.init({
				appId: facebookAppId,
				autoLogAppEvents: true,
				xfbml: true,
				version: 'v18.0',
			})

			FB.getLoginStatus((response) => {
				if (response.status === 'connected') {
					dispatch(setIsFacebookLogged({ isLogged: true }))
				} else {
					dispatch(setIsFacebookLogged({ isLogged: false }))
				}
			})
		}
	} else {
		flashMessage.error('channels.facebook.error.init')
	}
}

export const getPagesList =
	(userId: string, userToken: string): AppThunkAction =>
	async (dispatch) => {
		try {
			FB.api(`/${userId}/accounts`, { access_token: userToken }, async (response: PagesListResponse) => {
				if (response.data && !response.error) {
					const pages: ChannelDataPage[] = []
					response.data.forEach((page: ChannelDataPage) => {
						pages.push({ id: page.id, name: page.name })
					})
					const resultAction = await dispatch(createFacebookChannel({ userId, userToken, pages }))
					if (createFacebookChannel.fulfilled.match(resultAction)) {
						dispatch(logFeatureUsage(FeatureUsageName.ConnectFacebookChannel))
					}
				} else {
					/* eslint-disable-next-line no-console */
					console.error(response.error)
					flashMessage.error('channels.facebook.error.connecting')
				}
			})
		} catch {
			flashMessage.error('channels.facebook.error.connecting')
		}
	}

export const fbLogin =
	(isBusiness: boolean): AppThunkAction =>
	(dispatch) => {
		let configScope = `email, pages_show_list, pages_manage_metadata, pages_messaging`
		if (isBusiness) configScope += ', business_management'
		try {
			FB.login(
				(response) => {
					const { authResponse } = response
					if (authResponse) {
						const { userID, accessToken } = authResponse
						dispatch(getPagesList(userID, accessToken))
						dispatch(setIsFacebookLogged({ isLogged: true }))
					} else {
						flashMessage.warning('channels.facebook.loginError')
					}
				},
				{ scope: configScope },
			)
		} catch {
			flashMessage.error('channels.facebook.error.login')
		}
	}

export const disconnectChannelType =
	(type: ChannelType, onSuccess?: () => void, onError?: () => void): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const isFacebookLogged = getIsFacebookLogged(state)
		if (type === ChannelType.FacebookMessenger && isFacebookLogged) {
			try {
				FB.logout(() => {
					dispatch(setIsFacebookLogged({ isLogged: false }))
				})
			} catch {
				flashMessage.error('channels.facebook.error.disconnect')
			}
		}
		const resultAction = await dispatch(disconnectChannelsByType(type))
		if (disconnectChannelsByType.fulfilled.match(resultAction)) {
			onSuccess && onSuccess()
		} else if (disconnectChannelsByType.rejected.match(resultAction)) {
			onError && onError()
		}
	}

const channelsSlice = createSlice({
	name: 'channels',
	initialState,
	reducers: {
		setIsFacebookLogged: (state, { payload }: PayloadAction<{ isLogged: boolean }>) => {
			const { isLogged } = payload
			state.isFacebookLogged = isLogged
		},
		clearSelectedChannelDetail: (state) => {
			state.selectedChannelDetail = null
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(createFacebookChannel.fulfilled, (state, { payload }) => {
				channelsAdapter.addMany(state, payload)
			})
			.addCase(createFacebookChannel.rejected, () => {
				flashMessage.error('channels.facebook.error.create')
			})
		builder
			.addCase(deleteChannel.pending, (state) => {
				state.isDeletePending = true
			})
			.addCase(deleteChannel.fulfilled, (state, { meta }) => {
				channelsAdapter.removeOne(state, meta.arg.id)
				state.isDeletePending = false
			})
			.addCase(deleteChannel.rejected, (state) => {
				flashMessage.error('channels.facebook.error.delete')
				state.isDeletePending = false
			})
		builder.addCase(fetchChannels.fulfilled, (state, { payload }) => {
			channelsAdapter.setAll(state, payload)
			state.isChannelsLoaded = true
		})
		builder
			.addCase(fetchChannelDetailById.fulfilled, (state, { payload }) => {
				state.selectedChannelDetail = payload
			})
			.addCase(fetchChannelDetailById.rejected, (state) => {
				state.selectedChannelDetail = null
			})
		builder
			.addCase(disconnectChannelsByType.pending, (state) => {
				state.isDisconnectingChannels = true
			})
			.addCase(disconnectChannelsByType.fulfilled, (state) => {
				channelsAdapter.removeAll(state)
				state.isDisconnectingChannels = false
			})
			.addCase(disconnectChannelsByType.rejected, (state) => {
				state.isDisconnectingChannels = false
			})
	},
})

const { reducer, actions } = channelsSlice
export const { setIsFacebookLogged, clearSelectedChannelDetail } = actions
export default reducer

const entitySelectors = channelsAdapter.getSelectors<ChannelsRootState>((state) => state.channels)
const getChannels = (state: ChannelsRootState) => entitySelectors.selectAll(state)
const getIsFacebookLogged = (state: ChannelsRootState) => state.channels.isFacebookLogged
const getIsChannelsLoaded = (state: ChannelsRootState) => state.channels.isChannelsLoaded
const getIsDeletePending = (state: ChannelsRootState) => state.channels.isDeletePending
const getIsDisconnectingChannels = (state: ChannelsRootState) => state.channels.isDisconnectingChannels
const getSelectedChannelDetail = (state: ChannelsRootState) => state.channels.selectedChannelDetail
const isSelectedChannelDeleted = (state: ChannelsRootState) => {
	const { selectedChannelDetail } = state.channels
	if (!selectedChannelDetail) return true

	return !!selectedChannelDetail.deletedAt
}

const makeGetIsConnectedChannelsByType = (type: ChannelType) => {
	return createSelector([makeGetChannelsByType(type)], (channels): boolean => {
		return channels && channels.length > 0
	})
}

const makeGetChannelsByType = (type: ChannelType) => {
	return createSelector([getChannels], (channels): Channel[] => {
		return channels.filter((channel) => channel.type === type)
	})
}

const getIsInvalidFacebookChannels = createSelector(
	[makeGetChannelsByType(ChannelType.FacebookMessenger)],
	(channels): boolean => {
		if (!channels || channels.length === 0) return false
		return channels.some((channel) => channel.facebook?.status !== FacebookTokenStatus.Ok)
	},
)

export const channelsSelectors = {
	makeGetChannelsByType,
	getIsFacebookLogged,
	getIsChannelsLoaded,
	isSelectedChannelDeleted,
	getSelectedChannelDetail,
	makeGetIsConnectedChannelsByType,
	getIsDeletePending,
	getIsDisconnectingChannels,
	getIsInvalidFacebookChannels,
}
