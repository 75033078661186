import WebsocketVisitorClient, { BotIdentity } from '@smartsupp/websocket-client-visitor'

import { Message } from 'models'
import { DEFAULT_IDENTITY_NAME } from 'modules/chatbotIdentities'
import { ChatbotWorkflowSection } from 'modules/chatbotWorkflow'

import { PreviewQuestions } from './types'

export const DEFAULT_PATH = 'default'
export const DEFAULT_BOT_GENDER = 'female'

export const DEFAULT_IDENTITY_ID = 'botIdentity1'

export const WIDGET_EVENT_MESSAGE_SENT = 'previewMessageSent'

export const generalBlockingOptions = {
	limitShadow: true,
	disableExpandButton: true,
	disableOptionsButton: true,
	disableCloseButton: true,
	enforceDesktopMode: true,
}

export enum AbortType {
	PreviewSection = 'preview_section',
	AnotherRequest = 'another_request',
}

export const sectionToWorkflowMap = {
	[ChatbotWorkflowSection.Basics]: 'behavior',
	[ChatbotWorkflowSection.Training]: 'training',
	[ChatbotWorkflowSection.Skills]: 'skills',
	[ChatbotWorkflowSection.Preview]: 'preview',
}

export const previewQuestions: PreviewQuestions = {
	behavior: {
		default: 'previewQuestions.behavior.default',
		tone: 'previewQuestions.behavior.tone',
		talkativeness: 'previewQuestions.behavior.talkativeness',
		emoji: 'previewQuestions.behavior.emoji',
		confidence: 'previewQuestions.behavior.confidence',
	},
	skills: {
		default: 'previewQuestions.skills.default',
		productRecommendation: {
			default: 'previewQuestions.skills.productRecommendation.default',
			disabled: 'previewQuestions.skills.productRecommendation.disabled',
			curated: 'previewQuestions.skills.productRecommendation.curated',
			engaging: 'previewQuestions.skills.productRecommendation.engaging',
		},
		handover: {
			default: 'previewQuestions.skills.handover.default',
			disabled: 'previewQuestions.skills.handover.disabled',
			enabled: 'previewQuestions.skills.handover.default',
		},
	},
}

export const botIdentity: BotIdentity = {
	id: DEFAULT_IDENTITY_ID,
	name: DEFAULT_IDENTITY_NAME,
	avatarUrl: '',
	gender: 'female',
}

export const baseConnectedData: WebsocketVisitorClient.ConnectedData = {
	serverVersion: 3,
	sessionId: '',
	browser: {
		isMobile: false,
		isTablet: true,
		isDesktop: false,
		isSmartTV: false,
		isIE: false,
		isEdge: false,
	},
	visitor: {
		id: 'visitor',
		lang: 'en',
		chatId: 'preview',
		name: null,
		email: null,
		phone: null,
		group: null,
		bannedAt: null,
		triggerable: true,
		variables: {},
		visits: 1,
		gdprApproved: true,
	},
	account: {
		status: 'online',
		groups: [],
		botIdentities: [],
		agents: [],
	},
	chat: {
		id: 'preview',
		assignedIds: [],
		rating: null,
		status: 'pending',
		isClosed: false,
		readInfo: {
			type: 'agent',
			lastReadAt: new Date().toISOString(),
		},
		unreadInfo: {
			type: 'contact',
			count: 0,
			lastReadAt: new Date().toISOString(),
		},
		messages: [],
	},
	fileUpload: {
		acceptedFileTypes: [],
		acceptedFileExtensions: [],
		maxFileSize: 0,
	},
}

export const message: Message.Message.Bot = {
	id: 'm-1',
	type: 'message',
	subType: 'bot',
	channel: {
		type: 'default',
		id: null,
	},
	chatId: 'preview',
	visitorId: 'visitor',
	groupId: 'default',
	agentId: '',
	createdAt: new Date().toISOString(),
	content: {
		type: 'text',
		text: '',
		data: '',
	},
	attachments: [],
	tags: [],
	responseTime: null,
	deliveryTo: null,
	deliveredAt: null,
	deliveryStatus: null,
	deliveryFailReason: null,
	trigger: {
		id: '',
		name: '',
		identityId: DEFAULT_IDENTITY_ID,
	},
	widgetOptions: {
		disableInput: true,
	},
}
