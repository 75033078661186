import { mdiStar } from '@mdi/js'

import { TranslationService as T } from 'shared/services'

import { Box, Icon, Tag, TagProps } from 'core'

type Props = {
	noText?: boolean
	noMargin?: boolean
} & TagProps

export const TagPremium = ({ noText = false, noMargin, ...rest }: Props) => {
	return (
		<Tag colorScheme="warning" ml={noMargin ? 0 : 1} {...rest}>
			<Icon path={mdiStar} />
			{!noText && <Box mx="0.5">{T.translate('general.tag.premium')}</Box>}
		</Tag>
	)
}
