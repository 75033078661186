import { OfficeHoursTimesFormData } from 'models'
import { OfficeHoursValidationOutput } from 'modules/officeHours'

export const EMPTY_INTERVAL: OfficeHoursTimesFormData = { from: '00:00', to: '00:00' }
export const DEFAULT_INTERVAL: OfficeHoursTimesFormData = { from: '08:00', to: '17:00' }

export const DEFAULT_VALIDATION_RESULT: OfficeHoursValidationOutput = {
	count: 0,
	errors: {},
}
