import { HTMLProps, ReactNode } from 'react'

export type LinkProps = {
	href: string
	external?: boolean
	children?: ReactNode
} & HTMLProps<HTMLAnchorElement>

export const Link = ({ external = false, children, href, ...props }: LinkProps) => {
	const target = external ? '_blank' : undefined
	const rel = external ? 'noopener noreferrer' : undefined
	return (
		<a target={target} {...props} href={href} rel={rel}>
			{children}
		</a>
	)
}
