import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
	Chatbot,
	ChatbotApi,
	ChatbotStatisticsDataType,
	ChatbotStats,
	ChatbotStatsSummary,
	DataValidityObject,
	GetChatbotInteractionsStatsParams,
	GetChatbotStatsParams,
	InteractionsSort,
	InteractionStats,
} from 'models'
import { AppThunkAction, DashboardState, Dictionary } from 'types'
import { RangeType } from 'shared/models/Analytics'
import { normalize } from 'utils'

import { chatbotStatsApi } from './api'
import { DEFAULT_CHATBOT_STATISTICS_ITEMS, DEFAULT_STATISTICS_ARRAY } from './constants'
import { ChartData, InteractionTableStats, TransformedChatbotStatistic } from './types'
import { getChartData, getChatbotInteractionsData, transformChatbotStatistics } from './utils'

export type ChatbotStatisticsRootState = Pick<DashboardState, 'chatbotStatistics'>
export type ChatbotStatisticsState = typeof initialState

export const initialState = {
	summary: null as null | ChatbotStatsSummary,
	bots: null as null | Dictionary<ChatbotStats>,
	lastUpdate: null as null | string,
	isFetching: false,
	isDrawerOpen: true,
	selectedChatbot: {
		summary: null as null | ChatbotStatsSummary,
		lastUpdate: null as null | string,
		validFrom: null as null | DataValidityObject,
		isFetchingStats: false,
	},
	selectedChatbotInteractions: {
		interactions: [] as InteractionStats[],
		isFetchingInteractions: false,
		isError: false,
	},
	aggregatedStats: {
		statistics: null as null | ChatbotApi.StatsSingleAggregatedResponse,
		isFetching: false,
		isError: false,
	},
	selectedItems: DEFAULT_CHATBOT_STATISTICS_ITEMS,
}

export const fetchChatbotStatisticsThunk = createAsyncThunk(
	'chatbotStatistics/FETCH',
	(data: ChatbotApi.StatsQuery) => {
		return chatbotStatsApi.getStatistics(data)
	},
)

export const fetchSingleChatbotStatisticsThunk = createAsyncThunk(
	'chatbotStatistics/FETCH_SINGLE',
	({ botId, range }: GetChatbotStatsParams) => {
		return chatbotStatsApi.getBotStatistics(botId, range)
	},
)

const fetchChatbotInteractionsStatisticsThunk = createAsyncThunk(
	'chatbotStatistics/FETCH_INTERACTIONS',
	({ botId, range, sort }: GetChatbotInteractionsStatsParams) => {
		return chatbotStatsApi.getBotInteractionsStatistics(botId, { range, sort })
	},
)

const fetchChatbotAggregatedStatisticsThunk = createAsyncThunk(
	'chatbotStatistics/FETCH_AGGREGATED',
	({ botId, range }: GetChatbotStatsParams) => {
		return chatbotStatsApi.getAggregatedBotStatistics(botId, { range })
	},
)

export const fetchChatbotStatisticsBots = (): AppThunkAction => (dispatch) => {
	dispatch(
		fetchChatbotStatisticsThunk({
			fields: [
				ChatbotApi.StatsQueryFields.Bots,
				ChatbotApi.StatsQueryFields.Summary,
				ChatbotApi.StatsQueryFields.LastUpdate,
			],
			range: RangeType.Last30days,
		}),
	)
}

export const fetchSelectedChatbotStatistics =
	(botId: string): AppThunkAction =>
	(dispatch) => {
		dispatch(
			fetchSingleChatbotStatisticsThunk({
				botId,
				range: RangeType.Last30days,
			}),
		)
	}

export const fetchSelectedChatbotInteractionsStatistics =
	(botId: string): AppThunkAction =>
	(dispatch) => {
		dispatch(
			fetchChatbotInteractionsStatisticsThunk({
				botId,
				range: RangeType.Last30days,
				sort: InteractionsSort.Clicked,
			}),
		)
	}

export const fetchSelectedChatbotAggregatedStatistics =
	(botId: string): AppThunkAction =>
	(dispatch) => {
		dispatch(
			fetchChatbotAggregatedStatisticsThunk({
				botId,
				range: RangeType.Last30days,
			}),
		)
	}

export const selectStatisticsItem =
	(chatbotStatName: ChatbotStatisticsDataType): AppThunkAction =>
	(dispatch, getState) => {
		const selectedItems = getSelectedItems(getState())
		const isItemSelected = selectedItems.includes(chatbotStatName)

		const newItemArray = isItemSelected
			? selectedItems.filter((selectedItem) => selectedItem !== chatbotStatName)
			: [...selectedItems, chatbotStatName]

		dispatch(setSelectedStatisticsItems(newItemArray))
	}

const chatbotStatisticsSlice = createSlice({
	name: 'chatbotStatistics',
	initialState,
	reducers: {
		setIsDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
			state.isDrawerOpen = payload
		},
		setSelectedStatisticsItems: (state, { payload }: PayloadAction<ChatbotStatisticsDataType[]>) => {
			state.selectedItems = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchChatbotStatisticsThunk.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchChatbotStatisticsThunk.fulfilled, (state, { payload }) => {
				state.isFetching = false
				const { summary, bots, lastUpdate } = payload
				if (summary) {
					state.summary = summary
				}
				if (bots) {
					state.bots = normalize('botId', bots)
				}
				if (lastUpdate) {
					state.lastUpdate = lastUpdate
				}
			})
			.addCase(fetchChatbotStatisticsThunk.rejected, (state) => {
				state.isFetching = false
			})
		builder
			.addCase(fetchSingleChatbotStatisticsThunk.pending, (state) => {
				state.selectedChatbot.isFetchingStats = true
			})
			.addCase(fetchSingleChatbotStatisticsThunk.fulfilled, (state, { payload }) => {
				state.selectedChatbot.isFetchingStats = false
				const { summary, lastUpdate, validFrom } = payload
				if (summary) {
					state.selectedChatbot.summary = summary
				}
				if (lastUpdate) {
					state.selectedChatbot.lastUpdate = lastUpdate
				}
				if (validFrom) {
					state.selectedChatbot.validFrom = validFrom
				}
			})
			.addCase(fetchSingleChatbotStatisticsThunk.rejected, (state) => {
				state.selectedChatbot.isFetchingStats = false
			})
		builder
			.addCase(fetchChatbotInteractionsStatisticsThunk.pending, (state) => {
				state.selectedChatbotInteractions.isFetchingInteractions = true
				state.selectedChatbotInteractions.isError = false
			})
			.addCase(fetchChatbotInteractionsStatisticsThunk.fulfilled, (state, { payload }) => {
				state.selectedChatbotInteractions.isFetchingInteractions = false
				state.selectedChatbotInteractions.isError = false
				if (payload.interactions) {
					state.selectedChatbotInteractions.interactions = payload.interactions
				}
			})
			.addCase(fetchChatbotInteractionsStatisticsThunk.rejected, (state) => {
				state.selectedChatbotInteractions.isFetchingInteractions = false
				state.selectedChatbotInteractions.isError = true
			})
		builder
			.addCase(fetchChatbotAggregatedStatisticsThunk.pending, (state) => {
				state.aggregatedStats.isFetching = true
				state.aggregatedStats.isError = false
			})
			.addCase(fetchChatbotAggregatedStatisticsThunk.fulfilled, (state, { payload }) => {
				state.aggregatedStats.isFetching = false
				state.aggregatedStats.isError = false
				state.aggregatedStats.statistics = payload
			})
			.addCase(fetchChatbotAggregatedStatisticsThunk.rejected, (state) => {
				state.aggregatedStats.isFetching = false
				state.aggregatedStats.isError = true
			})
	},
})

const getSummary = (state: ChatbotStatisticsRootState) => state.chatbotStatistics.summary
const getLastUpdate = (state: ChatbotStatisticsRootState) => state.chatbotStatistics.lastUpdate
const getBots = (state: ChatbotStatisticsRootState) => state.chatbotStatistics.bots
const getIsFetching = (state: ChatbotStatisticsRootState) => state.chatbotStatistics.isFetching
const getIsDrawerOpened = (state: ChatbotStatisticsRootState) => state.chatbotStatistics.isDrawerOpen
const getSelectedChatbotStatistics = (state: ChatbotStatisticsRootState) =>
	state.chatbotStatistics.selectedChatbot.summary
const getSelectedChatbotIsFetchingStats = (state: ChatbotStatisticsRootState) =>
	state.chatbotStatistics.selectedChatbot.isFetchingStats
const getSelectedChatbotLastUpdated = (state: ChatbotStatisticsRootState) =>
	state.chatbotStatistics.selectedChatbot.lastUpdate
const getSelectedChatbotValidFrom = (state: ChatbotStatisticsRootState) =>
	state.chatbotStatistics.selectedChatbot.validFrom
const getSelectedChatbotInteractions = (state: ChatbotStatisticsRootState) =>
	state.chatbotStatistics.selectedChatbotInteractions.interactions
const getSelectedChatbotIsFetchingInteractions = (state: ChatbotStatisticsRootState) =>
	state.chatbotStatistics.selectedChatbotInteractions.isFetchingInteractions
const getSelectedChatbotInteractionsIsError = (state: ChatbotStatisticsRootState) =>
	state.chatbotStatistics.selectedChatbotInteractions.isError
const getAggregatedStatistics = (state: ChatbotStatisticsRootState) =>
	state.chatbotStatistics.aggregatedStats.statistics
const getIsFetchingAggregatedStatistics = (state: ChatbotStatisticsRootState) =>
	state.chatbotStatistics.aggregatedStats.isFetching
const getIsErrorAggregatedStatistics = (state: ChatbotStatisticsRootState) =>
	state.chatbotStatistics.aggregatedStats.isError
const getSelectedItems = (state: ChatbotStatisticsRootState) => state.chatbotStatistics.selectedItems

const makeGetBotStatisticsById = (id: string) => {
	return createSelector([getBots], (bots): ChatbotStats | null => {
		if (!bots || !bots[id]) return null

		return bots[id]
	})
}

const makeGetBotStatisticsStringsById = (id: string) => {
	return createSelector([getBots], (bots): TransformedChatbotStatistic[] | null => {
		if (!bots) return null

		return transformChatbotStatistics(bots[id])
	})
}

const getSelectedChatbotStatisticsStrings = createSelector(
	[getSelectedChatbotStatistics],
	(stats): TransformedChatbotStatistic[] | null => {
		if (!stats) return null
		const transformedStatistics = transformChatbotStatistics(stats)

		return transformedStatistics.filter((item) => item.name !== ChatbotStatisticsDataType.Resolved)
	},
)

const getBotSummaryStrings = createSelector([getSummary], (summary): Dictionary<TransformedChatbotStatistic> => {
	if (!summary) return normalize('name', DEFAULT_STATISTICS_ARRAY)

	const botSummaryStrings = transformChatbotStatistics(summary)
	return normalize('name', botSummaryStrings)
})

// Interaction statistics selectors
const getSelectedChatbotInteractionsStatistics = (selectedChatbot: Chatbot | null) => {
	return createSelector([getSelectedChatbotInteractions], (interactions): InteractionTableStats[] => {
		if (!selectedChatbot) return []
		return getChatbotInteractionsData(selectedChatbot, interactions)
	})
}

// Chart selectors
const getSelectedChatbotChartData = createSelector(
	[getAggregatedStatistics, getSelectedItems],
	(statistics, selectedItems): ChartData[] => {
		if (!statistics) return []

		const chartData = getChartData(statistics)
		return chartData.filter((dataItem) => selectedItems.includes(dataItem.id))
	},
)

const getSelectedChatbotChartMaxValue = createSelector([getSelectedChatbotChartData], (chartData): number => {
	const values = chartData.map((dataItem) => {
		const dataY = dataItem.data.map((data) => data.y)
		return Math.max(...dataY)
	})
	return Math.max(...values)
})

const hasSelectedItems = createSelector([getSelectedItems], (selectedItems): boolean => {
	return selectedItems.length > 0
})

const hasChatbotDataForSelectedItems = createSelector([getSelectedChatbotChartMaxValue], (maxValue): boolean => {
	return maxValue > 0
})

const { reducer, actions } = chatbotStatisticsSlice
export const { setIsDrawerOpen, setSelectedStatisticsItems } = actions
export default reducer

export const chatbotStatisticsSelectors = {
	getBots,
	getLastUpdate,
	getSummary,
	getIsFetching,
	getIsDrawerOpened,
	getBotSummaryStrings,
	makeGetBotStatisticsById,
	makeGetBotStatisticsStringsById,
	getSelectedChatbotStatisticsStrings,
	getSelectedChatbotIsFetchingStats,
	getSelectedChatbotLastUpdated,
	getSelectedChatbotValidFrom,
	getSelectedChatbotIsFetchingInteractions,
	getSelectedChatbotInteractionsIsError,
	getSelectedChatbotInteractionsStatistics,
	getSelectedChatbotChartData,
	getSelectedChatbotChartMaxValue,
	getIsFetchingAggregatedStatistics,
	getIsErrorAggregatedStatistics,
	getSelectedItems,
	hasSelectedItems,
	hasChatbotDataForSelectedItems,
}
