import {
	AccountData,
	AppConfigData,
	AppEnvData,
	ConnectionData,
	IdentityData,
	SmartsuppWindow,
	TokenData,
} from 'shared/types'

import { ApiError } from '../utils'
import { bottleneck } from '../utils/bottleneck'

declare let window: SmartsuppWindow

interface ConfigData {
	account: AccountData | null
	appEnv: AppEnvData | null
	data: AppConfigData | null
	identity: IdentityData | null
	connection: ConnectionData | null
	tokens: TokenData
}

const isEnvDevelopment = process.env.NODE_ENV === 'development'

const EXPIRED_IN_RESERVE_SECONDS = 10

const innerData: ConfigData = {
	account: null,
	appEnv: null,
	data: null,
	identity: null,
	connection: null,
	tokens: {
		restAccessToken: null,
		restIdToken: null,
		restRefreshToken: null,
		restAccessTokenExpiresIn: 0,
	},
}

const refreshTokenRequest = bottleneck(async () => {
	const apiUrl = ConfigurationService.getApiUrl()
	const refreshTokenResponse = await fetch(`${apiUrl}/auth/refresh-token`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			clientId: 'smartsupp-dash',
			refreshToken: ConfigurationService.getRestRefreshToken(),
		}),
	})

	if (!refreshTokenResponse.ok) {
		if (refreshTokenResponse.status !== 400) {
			throw new ApiError(refreshTokenResponse)
		}

		window.location.reload()
	}

	return (await refreshTokenResponse.json()) as {
		accessToken: string
		idToken: string
		refreshToken: string
		expiresIn: number
	}
})

const getLogoutUrl = (): string => {
	const el = document.querySelector('meta[name="x-logout-url"]')
	if (!el) return ''
	return el.getAttribute('content') ?? ''
}

const getRestRefreshToken = (): string => {
	const tokenEl = document.querySelector('meta[name="x-refresh-token"]')
	if (!tokenEl) return ''
	return tokenEl.getAttribute('content') ?? ''
}

const transformConfig = (config: AppConfigData) => {
	if (!config) return null

	const distUrl = config.dashboardDistUrl
	const envPublicUrl = process.env.PUBLIC_URL

	return {
		...config,
		distUrl: isEnvDevelopment && envPublicUrl ? envPublicUrl : distUrl,
		keycloakLogoutUrl: getLogoutUrl(),
	}
}

export const ConfigurationService = {
	getAppEnvData() {
		if (!innerData.appEnv) {
			innerData.appEnv = window.appEnvConfig
		}
		return innerData.appEnv
	},

	setData(data: AppConfigData) {
		innerData.data = transformConfig(data)
	},

	getData() {
		if (!innerData.data) {
			throw new Error('No config data provided!')
		}
		return innerData.data
	},

	getApiUrl(): string {
		return this.getAppEnvData().apiUrl
	},

	getAccountData() {
		if (!innerData.data) {
			throw new Error('No config data provided!')
		}
		return innerData.data.account
	},

	getIdentityData() {
		return innerData && innerData.data ? innerData.data.identity : null
	},

	getConnectionData() {
		return innerData && innerData.data ? innerData.data.connection : null
	},

	getTokens() {
		return innerData.tokens
	},

	async getRestAccessToken() {
		const expiresIn = ConfigurationService.getRestAccessTokenExpiresAt()
		const now = Date.now()

		if (now >= expiresIn) {
			const response = await refreshTokenRequest()

			ConfigurationService.setRestAccessToken(response.accessToken)
			ConfigurationService.setRestIdToken(response.idToken)
			ConfigurationService.setRestRefreshToken(response.refreshToken)
			ConfigurationService.setRestAccessTokenExpiresAt(response.expiresIn, now)
		}

		return ConfigurationService.getTokens().restAccessToken ?? ''
	},

	setRestAccessToken(accessToken: string) {
		innerData.tokens.restAccessToken = accessToken
	},

	getRestIdToken(): string {
		return this.getTokens().restIdToken ?? ''
	},

	setRestIdToken(idToken: string): void {
		innerData.tokens.restIdToken = idToken
	},

	getRestRefreshToken(): string {
		const { restRefreshToken } = this.getTokens()
		if (!restRefreshToken) return getRestRefreshToken()
		return restRefreshToken ?? ''
	},

	setRestRefreshToken(refreshToken: string) {
		innerData.tokens.restRefreshToken = refreshToken
	},

	setRestAccessTokenExpiresAt(expiresIn: number, timestamp: number) {
		const date = new Date(timestamp)
		date.setSeconds(date.getSeconds() + expiresIn - EXPIRED_IN_RESERVE_SECONDS)
		innerData.tokens.restAccessTokenExpiresIn = new Date(date).getTime()
	},

	getRestAccessTokenExpiresAt() {
		return this.getTokens().restAccessTokenExpiresIn
	},
}
