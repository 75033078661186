import { ReactNode } from 'react'
import { StackProps } from '@chakra-ui/react'

import { VStack } from 'shared/components/core/Layout'

type Props = {
	children?: ReactNode
} & StackProps

const SidebarMenu = ({ children, ...rest }: Props) => {
	return (
		<VStack w="full" gap={1} {...rest}>
			{children}
		</VStack>
	)
}

export default SidebarMenu
