import { createSelector } from 'reselect'

import {
	ChannelType,
	Chat,
	ChatChannel,
	ChatsTab,
	ChatStatus,
	OpenChatsFilterType,
	SelectedChat,
	SelectedUser,
} from 'models'
import { DashboardState } from 'types'
import { Visitor } from 'shared/models/Visitor'
import { getChatId, getContactId, getVisitorId } from 'selectors/commonSelectors'
import { channelsSelectors } from 'modules/channels'
import { AUTO_CLOSE_ALERT_ID, ChatsRootState } from 'modules/chats'
import { ChatsCounters, chatsFilterSelectors } from 'modules/chatsFilter'
import { chatsSliceSelectors } from 'modules/chatsSlice'
import { contactsSelectors } from 'modules/contacts'
import { userSelectors } from 'modules/user'
import { visitorsSelectors } from 'modules/visitors'

import { filterMineChat, filterPrimaryChat, filterUnservedChat, transformChat } from './utils'

type ChatFilterFn = (chat: SelectedChat) => boolean

const getChatDetailData = (state: DashboardState) => state.chats.chatDetail
export const getChatDetailId = (state: DashboardState) => state.chats.chatDetailId
export const getAgentsChatMap = (state: DashboardState) => state.chats.chats
export const getClosedAgentsChatsTotal = (state: DashboardState) => state.chats.closedChatsTotal
export const wasChatSelected = (state: DashboardState) => state.chats.wasChatSelected
export const isChatDetailError = (state: DashboardState) => state.chats.chatDetailError
export const getAutoCloseCount = (state: ChatsRootState) => state.chats.autoCloseCount
export const getLastFetchOpenChatsTime = (state: ChatsRootState) => state.chats.lastFetchOpenChatsTime
export const isNeedForceJoin = (state: ChatsRootState) => state.chats.needForceJoin
const getOpenChatsLoaded = (state: ChatsRootState) => state.chats.openChatsLoaded

export const canJoinChat = createSelector([getChatDetailData, userSelectors.getUserId], (chat, userId): boolean => {
	if (!chat || !userId) return false
	return !chat.assignedIds.includes(userId)
})

export const canForceJoinChat = createSelector([canJoinChat, isNeedForceJoin], (canJoin, needForceJoin): boolean => {
	return canJoin && needForceJoin
})

const canResolveChat = (chat: Chat, user: SelectedUser) => {
	const { id: userId, isAdmin } = user
	const isChatOpen = chat.status === ChatStatus.Open
	const isUserAssigned = chat.assignedIds.includes(String(userId))
	const isChatUnserved = chat.assignedIds.length === 0
	return isChatOpen && (isAdmin || isUserAssigned || isChatUnserved)
}

const sortByLastMessageTime = (c1?: Chat, c2?: Chat) => {
	if (c1 && c2) {
		const timeToSortC1 = c1.lastMessage?.createdAt ?? c1.createdAt
		const timeToSortC2 = c2.lastMessage?.createdAt ?? c2.createdAt
		if (timeToSortC1 > timeToSortC2) return -1
		if (timeToSortC1 < timeToSortC2) return 1
	}
	return 0
}

export const getChatsMap = createSelector(
	[
		getAgentsChatMap,
		chatsSliceSelectors.getChatbotChatsMap,
		chatsSliceSelectors.getActiveTab,
		chatsFilterSelectors.getIsChatbotChatsContext,
	],
	(chats, chatbotChats, activeTab, isChatbotsChatsContext) => {
		const isActiveTabClosed = activeTab === ChatsTab.Closed
		return isActiveTabClosed && isChatbotsChatsContext ? chatbotChats : chats
	},
)

export const getSelectedChat = createSelector([getChatDetailData], (chat): SelectedChat | null => {
	if (!chat) return null
	return transformChat(chat)
})

export const getSelectedChatContactId = createSelector([getChatDetailData], (chat) => {
	return chat?.contactId
})

export const getIsSelectedChatUnserved = createSelector([getChatDetailData], (chat): boolean => {
	if (!chat) return false
	return chat.assignedIds.length === 0
})

export const getChats = createSelector([getChatsMap], (chats): SelectedChat[] => {
	return Object.values(chats).map(transformChat)
})

export const getAgentsChats = createSelector([getAgentsChatMap], (agentsChats): SelectedChat[] => {
	return Object.values(agentsChats).map(transformChat)
})

export const isExistsChat = createSelector([getChatsMap, getChatId], (chats, chatId) => {
	if (!chatId) return false

	return !!chats[chatId]
})

export const getIsChatUnread = createSelector([getChatsMap, getChatId], (chats, chatId) => {
	if (!chatId) return false
	const chat = chats[chatId]

	if (!chat || !chat.lastMessage) return false

	// if lastReadAt is null => never read
	if (!chat?.unreadInfo?.lastReadAt) return true

	return chat.lastMessage.createdAt > chat.unreadInfo.lastReadAt
})

export const getIsChatOpen = createSelector([getChatsMap, getChatId], (chats, chatId): boolean => {
	if (!chatId) return false
	const chat = chats[chatId]

	if (!chat) return false

	return chat.status === ChatStatus.Open
})

export const getOpenChats = createSelector([getAgentsChats], (chats): SelectedChat[] => {
	return chats.filter((c) => c.status === ChatStatus.Open)
})

export const getClosedChats = createSelector([getAgentsChats], (chats): SelectedChat[] => {
	return chats.filter((c) => c.status === ChatStatus.Closed)
})

export const getIsAutoCloseChats = createSelector([getAutoCloseCount], (count) => {
	return count > 0
})

export const hasCustomerChats = createSelector([getChats], (chats): boolean => {
	return chats.some((chat) => chat.channel.type !== ChannelType.Smartsupp)
})

export const getOpenChatIdsByFilter = createSelector(
	[
		getOpenChats,
		chatsFilterSelectors.getOpenChatsFilter,
		userSelectors.getUserId,
		userSelectors.getUserGroups,
		getIsAutoCloseChats,
	],
	(chats, openChatsFilter, userId, userGroups, isAutoCloseChats) => {
		const filtersMap: Record<OpenChatsFilterType, ChatFilterFn | undefined> = {
			[OpenChatsFilterType.All]: undefined,
			[OpenChatsFilterType.Primary]: filterPrimaryChat(userId ?? '', userGroups),
			[OpenChatsFilterType.Unserved]: filterUnservedChat(userGroups),
			[OpenChatsFilterType.Mine]: filterMineChat(userId ?? ''),
		}

		const filter = filtersMap[openChatsFilter]
		const filteredChats = filter ? chats.filter(filter) : chats
		const openChatIds = filteredChats.sort(sortByLastMessageTime).map((c) => c.id)

		// append auto-close alert as a last item of chat ids array
		return isAutoCloseChats ? [...openChatIds, AUTO_CLOSE_ALERT_ID] : openChatIds
	},
)

export const getClosedChatIdsByFilter = createSelector(
	[getClosedChats, chatsSliceSelectors.getChatbotChats, chatsFilterSelectors.getIsChatbotChatsContext],
	(closedChats, chatbotChats, isChatbotsChatsContext) => {
		return isChatbotsChatsContext ? chatbotChats.map((c) => c.id) : closedChats.map((c) => c.id)
	},
)

export const getTotalChatCounters = createSelector(
	[getOpenChats, userSelectors.getUserId, userSelectors.getUserGroups],
	(chats, userId, userGroups): ChatsCounters => {
		const primaryCount = chats.filter(filterPrimaryChat(userId ?? '', userGroups)).length
		const unservedCount = chats.filter(filterUnservedChat(userGroups)).length
		const mineCount = chats.filter(filterMineChat(userId ?? '')).length

		return {
			[OpenChatsFilterType.All]: chats.length,
			[OpenChatsFilterType.Primary]: primaryCount,
			[OpenChatsFilterType.Unserved]: unservedCount,
			[OpenChatsFilterType.Mine]: mineCount,
		}
	},
)

export const getUnreadChatCounters = createSelector(
	[getOpenChats, userSelectors.getUserId, userSelectors.getUserGroups],
	(chats, userId, userGroups): ChatsCounters => {
		const unreadChats = chats.filter((c) => c.unreadInfo.count > 0)
		const primaryCount = unreadChats.filter(filterPrimaryChat(userId ?? '', userGroups)).length
		const unservedCount = unreadChats.filter(filterUnservedChat(userGroups)).length
		const mineCount = unreadChats.filter(filterMineChat(userId ?? '')).length

		return {
			[OpenChatsFilterType.All]: primaryCount,
			[OpenChatsFilterType.Primary]: primaryCount,
			[OpenChatsFilterType.Unserved]: unservedCount,
			[OpenChatsFilterType.Mine]: mineCount,
		}
	},
)

export const getAllUnreadChatsCount = createSelector(
	[chatsSliceSelectors.getUnreadChatsCount, getOpenChatsLoaded, getUnreadChatCounters],
	(unreadChatsCount, openChatsLoaded, unreadCounters): number => {
		return openChatsLoaded ? unreadCounters.all : unreadChatsCount
	},
)

export const canJoinChatAfterFirstChar = createSelector(
	[getSelectedChat, canJoinChat, canForceJoinChat],
	(chat, canJoin, canForceJoin): boolean => {
		if (!chat) return false
		return canJoin && !canForceJoin && chat.status === ChatStatus.Open
	},
)

export const getSelectedChatVisitor = createSelector(
	[getSelectedChat, visitorsSelectors.getVisitorsData],
	(chat, visitors): Visitor | null => {
		if (!chat) return null

		const visitor = visitors[chat.visitorId]
		return visitor ?? null
	},
)

export const getHasSelectedChatVisitorAnotherChat = createSelector(
	[getSelectedChat, getSelectedChatVisitor],
	(chat, visitor): boolean => {
		if (!chat || !visitor) return false

		const isVisitorConnected = !!visitor.connectedAt
		return isVisitorConnected && visitor.chatId !== null && visitor.chatId !== chat.id
	},
)

export const getHasSelectedChatVisitorPersistedChat = createSelector([getSelectedChatVisitor], (visitor): boolean => {
	if (!visitor) return false

	return !!visitor.isChatPersisted
})

export const getBaseChatChannel = createSelector([getSelectedChat], (chat): ChatChannel | null => {
	if (!chat) return null
	return chat.channel
})

export const getBaseChatChannelType = createSelector([getBaseChatChannel], (channel): ChannelType | null => {
	if (!channel) return null
	return channel.type
})

export const getBaseChatChannelId = createSelector([getBaseChatChannel], (channel): string | null => {
	if (!channel) return null
	return channel.id
})

export const getHasSelectedChatAvailableChannel = createSelector(
	[
		getSelectedChatVisitor,
		contactsSelectors.getSelectedContact,
		getBaseChatChannelType,
		channelsSelectors.isSelectedChannelDeleted,
		getHasSelectedChatVisitorAnotherChat,
	],
	(visitor, contact, baseChatChannel, isSelectedChannelDeleted, hasAnotherChat): boolean => {
		if (!baseChatChannel) return false

		if (baseChatChannel === ChannelType.Default) {
			return !hasAnotherChat
		}
		if (baseChatChannel === ChannelType.FacebookMessenger) {
			if (isSelectedChannelDeleted && contact) {
				return !!contact.email
			}

			return !isSelectedChannelDeleted
		}
		if (baseChatChannel === ChannelType.App) {
			return true
		}

		return false
	},
)

export const getIsSmartsuppChannel = createSelector([getBaseChatChannelType], (channel) => {
	return channel === ChannelType.Smartsupp
})

export const getIsSelectedChatContactBanned = createSelector(
	[contactsSelectors.getSelectedContact],
	(contact): boolean => {
		if (!contact) return false
		return !!contact.bannedAt
	},
)

export const canResolveSelectedChat = createSelector(
	[getSelectedChat, userSelectors.getActiveUser],
	(chat, user): boolean => {
		if (!chat || !user) return false
		return canResolveChat(chat, user)
	},
)

export const makeCanResolveChatById = () =>
	createSelector([getChatsMap, getChatId, userSelectors.getActiveUser], (chats, chatId, user): boolean => {
		if (!chatId || !user) return false

		const chat = chats[chatId]
		if (!chat) return false

		return canResolveChat(chat, user)
	})

export const makeGetChatById = () =>
	createSelector([getChatsMap, getChatId], (chats, chatId) => {
		if (!chatId) return null
		const chat = chats[chatId]
		return chat ? transformChat(chat) : null
	})

export const makeGetChatByVisitorId = () =>
	createSelector([getChats, getVisitorId], (chats, visitorId) => {
		const chat = chats.find((c) => c.visitorId === visitorId)
		return chat ? transformChat(chat) : null
	})

export const makeGetChatsByVisitorId = () =>
	createSelector([getChats, getVisitorId], (chats, visitorId) => {
		const filteredChats = chats.filter((c) => c.visitorId === visitorId)
		return filteredChats ?? null
	})

export const makeGetChatsByContactId = () =>
	createSelector([getChats, getContactId], (chats, contactId) => {
		const filteredChats = chats.filter((c) => c.contactId === contactId)
		return filteredChats ?? null
	})

export const makeGetChatVisitorIsTyping = () =>
	createSelector([chatsSliceSelectors.getTypingVisitors, getChatId], (typingVisitors, chatId): boolean => {
		return !!chatId && typingVisitors.includes(chatId)
	})

export const makeGetIsMessagesCounterVisible = () =>
	createSelector(
		[getChatsMap, userSelectors.getUserId, userSelectors.getUserGroups, getChatId],
		(chats, userId, userGroups, chatId): boolean => {
			if (!chatId) return false

			const chat = chats[chatId]
			if (!chat) return false

			return filterPrimaryChat(userId ?? '', userGroups)(chat)
		},
	)

export const getClosedChatsTotal = createSelector(
	[getClosedAgentsChatsTotal, chatsSliceSelectors.getChatbotChatsTotal, chatsFilterSelectors.getIsChatbotChatsContext],
	(agentsChatsTotal, chatbotChatsTotal, isChatbotsChatsContext) => {
		return isChatbotsChatsContext ? chatbotChatsTotal : agentsChatsTotal
	},
)
