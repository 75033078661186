import { AppLang } from 'shared/types'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'chatbot/bots'

const initChatbots = (lang: AppLang) => {
	return apiClient.post(`${API_RESOURCE}/init`, { lang })
}

export const chatbotApi = {
	initChatbots,
}
