/* eslint-disable prefer-destructuring */
import filter from 'lodash/filter'
import find from 'lodash/find'
import intersectionBy from 'lodash/intersectionBy'

import { Shortcut, ShortcutExtended } from 'models'

import { detectShortcutLegacyRegex, detectShortcutRegex } from './constants'

export const detectShortcutInText = (text: string): string | null => {
	const result = detectShortcutRegex.exec(text)
	const resultLegacy = detectShortcutLegacyRegex.exec(text)

	if (!result && !resultLegacy) return null

	// Return capture group 2
	if (result) return result[2]

	// Return capture group 1
	if (resultLegacy) return resultLegacy[1]

	return null
}

export const replaceShortcutInText = (text: string, replaceValue: string): string => {
	const result = detectShortcutRegex.exec(text)
	const resultLegacy = detectShortcutLegacyRegex.exec(text)
	if (!result && !resultLegacy) return text

	let searchValue = null
	if (result) {
		searchValue = result[0]
	} else if (resultLegacy) {
		searchValue = resultLegacy[0]
	}

	if (searchValue === null) return text

	// Trim search value to not replace spaces
	searchValue = searchValue.trim()

	// Replace value at the end of text
	return text.replace(new RegExp(`${searchValue}$`), replaceValue)
}

// Finds personal shortcuts with the same name as team shortcuts and creates
// an "hasUniqueName" key on the shortcuts
export function markHasShortcutsUniqueName(json: Shortcut[]): ShortcutExtended[] {
	const personal = filter(json, (o) => {
		return o.type === 'personal'
	})
	const team = filter(json, (o) => {
		return o.type === 'team'
	})
	const dup = intersectionBy(personal, team, (o) => {
		return o.name
	})

	return json.map((s: Shortcut): ShortcutExtended => {
		const r = find(dup, (t) => t.id === s.id)
		if (r !== undefined) {
			return { ...s, hasUniqueName: false }
		}
		return { ...s, hasUniqueName: true }
	})
}
