import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunkAction, DashboardState, Dictionary } from 'types'
import { MetricsResponse, MetricsType, StatisticsInterval } from 'shared/models/Statistics'
import { normalize } from 'utils'
import { accountSelectors } from 'modules/account'
import { statisticsFilterSelectors } from 'modules/statisticsFilter'
import { tagsSelectors } from 'modules/tags'

import { tagsStatisticsApi } from './api'
import { DEFAULT_CHART_MAX_VALUE } from './constants'
import { SelectedTag, TagsChartAggregateFilterState, TagsChartData, TagsWithStatistics, TagWithMetrics } from './types'
import {
	formatBucketData,
	getAggregateFilterState,
	getAggregateInterval,
	getRequestBodyParamsTags,
	getTagsChartData,
	getTagsStatisticsSummaryData,
	mergeTagsWithMetrics,
} from './utils'

const initialState = {
	statistics: null as null | MetricsResponse,
	isFetching: false,
	selectedTags: [] as SelectedTag[],
	interval: StatisticsInterval.Day,
}

export const fetchTagsStatisticsThunk = createAsyncThunk('metrics/FETCH_TAGS_STATISTICS', async (arg, { getState }) => {
	const state = getState() as DashboardState
	const filter = statisticsFilterSelectors.getFilter(state)
	const timezone = accountSelectors.getAccountTimezone(state)
	const interval = getTagsInterval(state)

	const aggregateOption = {
		type: 'date',
		valueFormat: 'YYYY_MM_DD:HH_mm_ss',
	}
	const metric = { metricsType: MetricsType.NewConversation, interval }

	const body = getRequestBodyParamsTags(metric, filter, timezone, aggregateOption)

	return tagsStatisticsApi.getSingleMetric({ items: [body] })
})

export const keepSelectedTags = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const selectedTags = getSelectedTags(state)
	const tagsWithMetrics = getTagsWithMetrics(state)

	const tagsWithMetricsKeys = new Set(tagsWithMetrics.map((tag) => tag.key))
	const newSelectedTags = selectedTags.filter((tag) => tagsWithMetricsKeys.has(tag.key))
	dispatch(setSelectedTags(newSelectedTags))
}

export const setTopSelectedTags = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()

	const selectedTags = getSelectedTags(state)
	const topTags = getTopSelectedTagsWithMetrics(state)

	if (selectedTags.length === 0) {
		dispatch(setSelectedTags(topTags))
	}
}

export const fetchAggregatedTagsStatistics = (): AppThunkAction => async (dispatch, getState) => {
	const state = getState()
	const filter = statisticsFilterSelectors.getFilter(state)
	const interval = getTagsInterval(state)
	const selectedTags = getSelectedTags(state)

	const usedInterval = getAggregateInterval(filter, interval)

	dispatch(setTagsInterval(usedInterval))
	await dispatch(fetchTagsStatisticsThunk())

	if (selectedTags.length === 0) {
		dispatch(setTopSelectedTags())
	} else {
		dispatch(keepSelectedTags())
	}
}

const tagsStatisticsSlice = createSlice({
	name: 'tagsStatistics',
	initialState,
	reducers: {
		setSelectedTags(state, { payload }: PayloadAction<SelectedTag[]>) {
			state.selectedTags = payload
		},
		setTagsInterval(state, { payload }: PayloadAction<StatisticsInterval>) {
			state.interval = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchTagsStatisticsThunk.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchTagsStatisticsThunk.fulfilled, (state, { payload }) => {
				state.statistics = payload.length > 0 ? payload[0] : null
				state.isFetching = false
			})
			.addCase(fetchTagsStatisticsThunk.rejected, (state) => {
				state.isFetching = false
			})
	},
})

const getTagsStatistics = (state: DashboardState) => state.tagsStatistics.statistics
const getIsFetchingTagsStatistics = (state: DashboardState) => state.tagsStatistics.isFetching
const getSelectedTags = (state: DashboardState) => state.tagsStatistics.selectedTags
const getTagsInterval = (state: DashboardState) => state.tagsStatistics.interval

// Table selectors
const getTagsWithStatistics = createSelector([getTagsStatistics], (statistics): TagsWithStatistics | null => {
	if (!statistics?.buckets) return null
	return getTagsStatisticsSummaryData(statistics.buckets)
})

const getTagsStatisticsCount = createSelector([getTagsStatistics], (statistics): number => {
	if (!statistics) return 0
	return statistics.count
})

const getTagsWithMetrics = createSelector(
	[getTagsWithStatistics, getTagsStatisticsCount, tagsSelectors.getSortedTags],
	(statistics, count, tags): TagWithMetrics[] => {
		const tagsWithMetrics = mergeTagsWithMetrics(statistics, count, tags)
		return tagsWithMetrics.sort((a, b) => b.count - a.count)
	},
)

// Chart selectors
const getChartData = createSelector(
	[
		getTagsStatistics,
		statisticsFilterSelectors.getFilter,
		getSelectedTags,
		getTagsInterval,
		tagsSelectors.getSortedTags,
	],
	(statistics, filter, selectedTags, interval, tags): TagsChartData[] | null => {
		if (!statistics?.buckets) return null
		const tagKeys = new Set(selectedTags.map((tag) => tag.key))
		const normalizedTags = normalize('key', tags)
		const currentTagKeys = new Set(tags.map((tag) => tag.key))

		return getTagsChartData(
			formatBucketData(statistics.buckets, currentTagKeys),
			filter,
			interval,
			normalizedTags,
		).filter((data) => tagKeys.has(data.id))
	},
)

const getChartMaxValue = createSelector([getChartData], (chartData): number => {
	if (!chartData) return DEFAULT_CHART_MAX_VALUE

	const values = chartData.map((tag) => {
		const dataY = tag.data.map((data) => data.y)
		return Math.max(...dataY)
	})
	return Math.max(...values)
})

const getSelectedTagsWithMetrics = createSelector(
	[getTagsWithMetrics, getSelectedTags],
	(tagsWithMetrics, selectedTags): TagWithMetrics[] => {
		const tagKeys = new Set(selectedTags.map((tag) => tag.key))

		return tagsWithMetrics.filter((tag) => tagKeys.has(tag.key))
	},
)

const getNormalizedSelectedTagsWithMetrics = createSelector(
	[getSelectedTagsWithMetrics],
	(selectedTags): Dictionary<TagWithMetrics> => {
		return normalize('key', selectedTags)
	},
)

const hasSelectedTagsData = createSelector([getSelectedTagsWithMetrics], (selectedTags): boolean => {
	return selectedTags.some((tag) => tag.count > 0)
})

const getTopSelectedTagsWithMetrics = createSelector([getTagsWithMetrics], (tags) => {
	const topTags: SelectedTag[] = tags.slice(0, 4).map((tag) => {
		return { key: tag.key, color: tag.color }
	})
	return topTags
})

const getTagsStatisticsAggregateFilterState = createSelector(
	[statisticsFilterSelectors.getFilter],
	(filter): TagsChartAggregateFilterState => {
		return getAggregateFilterState(filter)
	},
)

const { actions, reducer } = tagsStatisticsSlice
export const { setSelectedTags, setTagsInterval } = actions
export const tagsStatisticsSelectors = {
	getTagsStatistics,
	getTagsStatisticsCount,
	getIsFetchingTagsStatistics,
	getTagsInterval,
	getTagsWithMetrics,
	getChartData,
	getChartMaxValue,
	getSelectedTags,
	getSelectedTagsWithMetrics,
	getNormalizedSelectedTagsWithMetrics,
	getTagsStatisticsAggregateFilterState,
	hasSelectedTagsData,
}
export default reducer
