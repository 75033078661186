import { Group, GroupCreateRequest } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'groups'

const getGroups = () => {
	return apiClient.get<Group[]>(`${API_RESOURCE}`)
}

const createGroup = (data: GroupCreateRequest) => {
	return apiClient.post<Group>(`${API_RESOURCE}`, data)
}

const editGroup = (id: number, changes: Partial<Group>) => {
	return apiClient.patch<Group>(`${API_RESOURCE}/${id}`, changes)
}

const deleteGroup = (id: number) => {
	return apiClient.delete(`${API_RESOURCE}/${id}`)
}

export const groupsApi = { getGroups, createGroup, editGroup, deleteGroup }
