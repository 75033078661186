import { createAsyncThunk, createEntityAdapter, createSlice, isFulfilled, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import {
	ChatbotGroupType,
	ChatbotSimple,
	ListQueryGroupType,
	SelectedChatbotSimple,
	SelectedChatbotSimpleWithStatistics,
} from 'models'
import { AppThunkAction, DashboardState, Dictionary } from 'types'
import {
	automationsApi,
	AutomationSorting,
	defaultSorting,
	fetchAllBots,
	makeSortAutomations,
	transformSimpleChatbot,
} from 'modules/automations'
import { autoMessageStatisticsSelectors } from 'modules/autoMessageStatistics'
import { deleteChatbot, fetchChatbot, postChatbot, updateChatbot } from 'modules/chatbotDetail/actions'
import { getFilterChatbotIds } from 'modules/chatbots'
import { chatsFilterSelectors, sortAutomations } from 'modules/chatsFilter'
import { packageSelectors } from 'modules/package'
import { FeatureUsageLimitsType } from 'modules/usage'

import { DEFAULT_AUTO_MESSAGES_ACTIVE_COUNT } from './constants'
import { getSimpleAutoMessagesWithStatistics } from './utils'

export type AutoMessagesRootState = Pick<DashboardState, 'autoMessages'>
export type AutoMessagesState = typeof initialState

const autoMessagesAdapter = createEntityAdapter<ChatbotSimple>()

export const initialState = autoMessagesAdapter.getInitialState({
	lastFetchedTime: null as null | number,
	isFetchingAutoMessages: false,
	activeAutoMessages: DEFAULT_AUTO_MESSAGES_ACTIVE_COUNT,
	sorting: defaultSorting,
	isEditDrawerOpen: false,
})

export const fetchAutoMessages = createAsyncThunk(
	'autoMessages/FETCH_MESSAGES',
	() => {
		return automationsApi.getBotsList({ groupType: ListQueryGroupType.Message })
	},
	{
		condition: (_, { getState }) => {
			const state = getState() as DashboardState
			const lastFetchedTime = getAutoMessagesLastFetchedTime(state)
			const currentTime = Date.now()
			return !lastFetchedTime || currentTime - lastFetchedTime > 10000
		},
	},
)

export const fetchAutoMessagesCounts = createAsyncThunk('autoMessages/FETCH_COUNTS', () => {
	return automationsApi.getBotsCounts({ groupType: ChatbotGroupType.Message })
})

export const selectBasicAutoMessages =
	(chatbot: SelectedChatbotSimple): AppThunkAction =>
	(dispatch) => {
		dispatch(fetchChatbot(chatbot.id))
		dispatch(toggleEditDrawer(true))
	}

const autoMessagesSlice = createSlice({
	name: 'autoMessages',
	reducers: {
		setAutoMessagesSorting: (state, { payload }: PayloadAction<AutomationSorting>) => {
			state.sorting = payload
		},
		toggleEditDrawer: (state, { payload }: PayloadAction<boolean>) => {
			state.isEditDrawerOpen = payload
		},
	},
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(fetchAutoMessages.pending, (state) => {
				state.isFetchingAutoMessages = true
			})
			.addCase(fetchAutoMessages.fulfilled, (state, { payload }) => {
				state.isFetchingAutoMessages = false
				autoMessagesAdapter.setAll(state, payload)
				state.lastFetchedTime = Date.now()
			})
			.addCase(fetchAutoMessages.rejected, (state) => {
				state.isFetchingAutoMessages = false
			})
		builder.addCase(fetchAutoMessagesCounts.fulfilled, (state, { payload }) => {
			state.activeAutoMessages = payload.active
		})
		builder.addCase(postChatbot.fulfilled, (state, { payload }) => {
			if (payload.groupType === ChatbotGroupType.Message) {
				autoMessagesAdapter.addOne(state, payload)
			}
		})
		builder.addCase(updateChatbot.fulfilled, (state, { payload }) => {
			autoMessagesAdapter.updateOne(state, { id: payload.id, changes: payload })
		})
		builder.addCase(deleteChatbot.fulfilled, (state, { meta }) => {
			autoMessagesAdapter.removeOne(state, meta.arg)
		})
		builder.addCase(fetchAllBots.pending, (state) => {
			state.isFetchingAutoMessages = true
		})
		builder.addCase(fetchAllBots.fulfilled, (state, { payload }) => {
			const filteredPayload = payload.filter((bot) => bot.groupType === ChatbotGroupType.Message || !bot.groupType)
			autoMessagesAdapter.setAll(state, filteredPayload)
			state.isFetchingAutoMessages = false
		})
		builder.addCase(fetchAllBots.rejected, (state) => {
			state.isFetchingAutoMessages = false
		})
		builder.addMatcher(isFulfilled(postChatbot, updateChatbot, deleteChatbot, fetchAutoMessages), (state) => {
			state.activeAutoMessages = Object.values(state.entities).filter((e) => e?.isActive && !e?.isBasic).length
		})
	},
})

const { actions, reducer } = autoMessagesSlice
export const { setAutoMessagesSorting, toggleEditDrawer } = actions
export default reducer

const entitySelectors = autoMessagesAdapter.getSelectors<AutoMessagesRootState>((state) => state.autoMessages)
const getAutoMessagesList = (state: AutoMessagesRootState) => entitySelectors.selectAll(state)
const getIsFetchingAutoMessages = (state: AutoMessagesRootState) => state.autoMessages.isFetchingAutoMessages
const getAutoMessagesSorting = (state: AutoMessagesRootState) => state.autoMessages.sorting
const getAutoMessagesLastFetchedTime = (state: AutoMessagesRootState) => state.autoMessages.lastFetchedTime
const getActiveAutoMessagesCount = (state: AutoMessagesRootState) => state.autoMessages.activeAutoMessages
const getIsEditDrawerOpen = (state: AutoMessagesRootState) => state.autoMessages.isEditDrawerOpen

const getAutoMessages = createSelector([getAutoMessagesList], (messages): SelectedChatbotSimple[] => {
	return messages.map(transformSimpleChatbot)
})

const getBasicAutoMessages = createSelector([getAutoMessages], (messages): SelectedChatbotSimple[] => {
	return messages.filter((autoMessage) => autoMessage.isBasic)
})

const hasBasicAutoMessages = createSelector([getBasicAutoMessages], (messages): boolean => {
	return messages.length > 0
})

const getMaxActiveAutoMessagesCount = createSelector(
	[packageSelectors.getPackageInfo],
	(packageInfo): number | null => {
		if (!packageInfo) return DEFAULT_AUTO_MESSAGES_ACTIVE_COUNT
		return packageInfo.maxActiveMessageChatbots
	},
)

const getAutoMessagesWithStatistics = createSelector(
	[getAutoMessages, autoMessageStatisticsSelectors.getMessages],
	(autoMessages, stats): Dictionary<SelectedChatbotSimpleWithStatistics> => {
		return getSimpleAutoMessagesWithStatistics(
			autoMessages.filter((autoMessage) => !autoMessage.isBasic),
			stats,
		)
	},
)

const getSortedAutoMessages = createSelector(
	[getAutoMessagesWithStatistics, getAutoMessagesSorting],
	(autoMessages, sorting): SelectedChatbotSimpleWithStatistics[] => {
		return Object.values(autoMessages).sort(makeSortAutomations(sorting))
	},
)

const getCanActivateAutoMessage = createSelector(
	[getActiveAutoMessagesCount, getMaxActiveAutoMessagesCount],
	(activeAutoMessagesCount, maxActiveAutoMessagesCount): boolean => {
		if (maxActiveAutoMessagesCount === null) return true // Unlimited auto messages
		return activeAutoMessagesCount < maxActiveAutoMessagesCount
	},
)

const getAutoMessagesLimits = createSelector(
	[getActiveAutoMessagesCount, getMaxActiveAutoMessagesCount],
	(active, max): FeatureUsageLimitsType => {
		const limit = max

		return {
			count: active,
			limit,
			isOverThreshold75: false,
			isOverThreshold90: false,
		}
	},
)

const isAutoMessageCreated = createSelector([getAutoMessages], (autoMessages): boolean => {
	return autoMessages.length > 0
})

const getAutoMsgsIds = createSelector([getAutoMessages], (autoMessages) => {
	return autoMessages.map((msg) => msg.id)
})

const getAutoMsgsIdsInFilter = (triggersIds: string[]) => {
	return createSelector(
		[getAutoMsgsIds, chatsFilterSelectors.getClosedChatsFilterBotIds],
		(autoMsgsIds, filterBotsIds) => {
			const allAutoMessages = [...autoMsgsIds, ...triggersIds]
			return getFilterChatbotIds(filterBotsIds, allAutoMessages)
		},
	)
}

const getAutoMessagesSortedAlphabetically = createSelector([getAutoMessages], (autoMessages) => {
	return sortAutomations(autoMessages)
})

export const autoMessagesSelectors = {
	getAutoMessages,
	getAutoMessagesSorting,
	getIsFetchingAutoMessages,
	getActiveAutoMessagesCount,
	getSortedAutoMessages,
	getCanActivateAutoMessage,
	getAutoMessagesLimits,
	isAutoMessageCreated,
	getBasicAutoMessages,
	hasBasicAutoMessages,
	getIsEditDrawerOpen,
	getMaxActiveAutoMessagesCount,
	getAutoMsgsIdsInFilter,
	getAutoMessagesSortedAlphabetically,
}
