import {
	Chatbot,
	ChatbotTrigger,
	ChatbotTriggerCondition,
	ChatbotTriggerConditionOp,
	ChatbotTriggerConditionOpNumber,
	ChatbotTriggerConditionOpString,
	ChatbotTriggerType,
} from 'models'

import {
	ChatbotTriggerAccountStatusValue,
	ChatbotTriggerConditionGroup,
	ChatbotTriggerDeviceTypeValue,
	ChatbotTriggerFormField,
	ChatbotTriggerFormPageDisplayValue,
	ChatbotTriggerSchedulingConditionGroup,
} from './constants'

export type ChatbotAllowedStringConditionOperations =
	| typeof ChatbotTriggerConditionOpString.Contains
	| typeof ChatbotTriggerConditionOpString.NotContains
	| typeof ChatbotTriggerConditionOpString.Eq
	| typeof ChatbotTriggerConditionOpString.NotEq

export type ChatbotAllowedSelectConditionOperations =
	| typeof ChatbotTriggerConditionOpString.Eq
	| typeof ChatbotTriggerConditionOpString.NotEq

export type ChatbotAllowedNumberConditionOperations =
	| typeof ChatbotTriggerConditionOpNumber.Eq
	| typeof ChatbotTriggerConditionOpNumber.NotEq
	| typeof ChatbotTriggerConditionOpNumber.Gt
	| typeof ChatbotTriggerConditionOpNumber.Gte
	| typeof ChatbotTriggerConditionOpNumber.Lt
	| typeof ChatbotTriggerConditionOpNumber.Lte

export interface ChatbotTriggerUpdateParams {
	botId: Chatbot['id']
	changes: Partial<ChatbotTrigger>
}

export interface ChatbotTriggerDateGroupConditions {
	[ChatbotTriggerSchedulingConditionGroup.Months]: string[]
	[ChatbotTriggerSchedulingConditionGroup.Days]: string[]
	[ChatbotTriggerSchedulingConditionGroup.Times]: ChatbotTriggerTimeRangeObject[]
}

export interface ChatbotTriggerTimeRangeObject {
	from: string
	to: string
}

export interface ChatbotTriggerGroupConditions {
	[ChatbotTriggerConditionGroup.PageConditions]: ChatbotTriggerCondition[]
	[ChatbotTriggerConditionGroup.AudienceConditions]: ChatbotTriggerCondition[]
	audienceConditionsOp: ChatbotTriggerConditionOp
	pageConditionsOp: ChatbotTriggerConditionOp
}

export interface ChatbotTriggerFormData extends ChatbotTriggerGroupConditions, ChatbotTriggerDateGroupConditions {
	[ChatbotTriggerFormField.Type]: ChatbotTriggerType
	[ChatbotTriggerFormField.Delay]: number
	[ChatbotTriggerFormField.DisableInput]: boolean
	[ChatbotTriggerFormField.DeviceType]: ChatbotTriggerDeviceTypeValue
	[ChatbotTriggerFormField.AccountStatus]: ChatbotTriggerAccountStatusValue
	[ChatbotTriggerFormField.VisitsCount]: number
	[ChatbotTriggerFormField.VisitsRange]: VisitsCountRange
	[ChatbotTriggerFormField.PageDisplay]: ChatbotTriggerFormPageDisplayValue
	[ChatbotTriggerFormField.IsAllowedWhenChatIsTriggered]: boolean
}

export enum VisitsCountRangeType {
	Greater = 'gt',
	Interval = 'int',
}

export interface VisitsCountRange {
	type: VisitsCountRangeType
	from: number
	to: number
}

export interface VisitsCountObject {
	triggerType: number
	range: VisitsCountRange
}

export type SectionTabSummary = {
	text: string
	bold?: string
}

export interface ChatbotAudienceGroupConditions {
	hasGroupConditions: boolean
	hasInvalidGroupConditions: boolean
}
