import { linkifier } from 'utils'

import { LINKS_LIMIT } from './constants'

export const trimDraftMessage = (draftMessage: string): string => {
	const trimmedDraftMessage = draftMessage.trim()
	if (trimmedDraftMessage.length === 0) return ''

	// Add empty space to end, if emoji is last character as solution for selecting text (emoji included) in FF
	return isEmojiOrSpecialLastChar(trimmedDraftMessage) ? `${trimmedDraftMessage} ` : trimmedDraftMessage
}

const isEmojiOrSpecialLastChar = (text: string): boolean => {
	const lastCharUnicode = text.codePointAt(text.length - 1)
	const LAST_LATIN_CHAR_UNICODE = 591

	if (!lastCharUnicode) return false
	return lastCharUnicode > LAST_LATIN_CHAR_UNICODE
}

export const isLinkLimitReached = (text: string) => {
	const links = linkifier.match(text)
	return links && links?.length > LINKS_LIMIT
}
