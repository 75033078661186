import { ReactNode } from 'react'
import { matchPath, NavLink, useLocation } from 'react-router-dom'

import { useAutomationColorScheme } from 'modules/automations'

import { BadgeDot, CBadge } from 'shared/components/core/Badge'
import { Box, BoxProps } from 'shared/components/core/Box'
import { Link } from 'shared/components/core/Link'
import { Tooltip } from 'shared/components/core/Tooltip'

type OwnProps = {
	children?: ReactNode
	path?: string
	external?: boolean
	badgeCount?: number
	badgeDot?: boolean
	isNew?: boolean
	tooltip?: string
	exactPath?: boolean
}

export type Props = OwnProps & BoxProps

const getBadgeType = (badgeCount: number, badgeDot: boolean, isNew: boolean, isActive: boolean) => {
	if (isNew) {
		return (
			<CBadge
				size="sm"
				variant="solid"
				position="absolute"
				top="-18px"
				right="-20px"
				transition="colors .2s ease"
				backgroundColor={isActive ? 'white' : 'purple.500'}
				color={isActive ? 'purple.500' : 'white'}
			>
				NEW
			</CBadge>
		)
	}

	if (badgeDot) {
		return (
			<BadgeDot
				boxShadow={isActive ? 'shadowPrimary' : 'shadow12'}
				position="absolute"
				top="-6px"
				right="-6px"
				className="badge-dot"
				data-testid="badge-dot"
				backgroundColor="red.600"
				transition="box-shadow .2s ease"
			/>
		)
	}

	if (badgeCount > 0) {
		return (
			<CBadge
				variant="solid"
				position="absolute"
				top="-10px"
				right="-10px"
				className="badge-count"
				data-testid="badge-count"
				boxShadow={isActive ? 'shadowPrimary' : 'shadow12'}
				transition="box-shadow .2s ease"
				backgroundColor="red.600"
			>
				{badgeCount}
			</CBadge>
		)
	}

	return null
}

const SidebarMenuItem = ({
	badgeCount = 0,
	badgeDot = false,
	isNew = false,
	children,
	path,
	external,
	tooltip,
	exactPath = true,
	...rest
}: Props) => {
	const colorScheme = useAutomationColorScheme()
	const location = useLocation()
	const isActive = path ? !!matchPath(location.pathname, { path }) : false

	const badgeWithChildren = (
		<Box position="relative">
			{getBadgeType(badgeCount, badgeDot, isNew, isActive)}
			{children}
		</Box>
	)

	const getChildren = () => {
		if (path && !external) {
			const { search } = window.location
			return (
				<NavLink
					to={`${path}${search}`}
					exact={exactPath}
					activeClassName="is-active"
					aria-label={tooltip}
					style={{ padding: `14px` }}
				>
					{badgeWithChildren}
				</NavLink>
			)
		}

		if (path && external) {
			return (
				<Link href={path} aria-label={tooltip} external style={{ padding: '6px' }}>
					{children}
				</Link>
			)
		}

		return badgeWithChildren
	}

	return (
		<Tooltip disabled={!tooltip} content={tooltip} placement="right" offset={[0, 20]}>
			<Box
				transition="colors .2s ease"
				display="flex"
				alignItems="center"
				justifyContent="center"
				cursor="pointer"
				mx="10px"
				borderRadius={2}
				onFocus={(e) => e.preventDefault()}
				_hover={{ backgroundColor: isActive ? `${colorScheme}.500` : 'coal.800' }}
				{...(isActive && { backgroundColor: `${colorScheme}.500` })}
				{...(!path && { padding: `2 0 ` })}
				{...rest}
			>
				{getChildren()}
			</Box>
		</Tooltip>
	)
}

export default SidebarMenuItem
