import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ChatbotInteraction } from 'models'
import { DashboardState } from 'types'
import { ChatbotAdditionalActionTypes } from 'modules/chatbotDetail'
import { updateChatbot } from 'modules/chatbotDetail/actions'

import { SelectInteractionsParams } from './types'
import { getListOfAllCollapsibleNodes } from './utils'

export type ChatbotBuilderRootState = Pick<DashboardState, 'chatbotBuilder'>
export type ChatbotBuilderState = typeof initialState

export const initialState = {
	selectedInteractionId: null as null | string,
	isChatbotBuilderDrawerOpen: false,
	isMoveNodeModalOpen: false,
	moveNodeInteractionId: null as null | string,
	copyNodeModal: {
		copyNodeInteractionId: null as null | string,
		isCopyNodeModalOpen: false,
	},
	selectedInteractionLevel: null as number | null,
	collapsedNodes: [] as string[],
	isSaveButtonLoading: false,
	isPublishButtonLoading: false,
	isInteractionFormDirty: false,
	selectedInteractionTabIndex: 0,
	openedTagsFilter: null as null | ChatbotAdditionalActionTypes,
	currentPropertyIndex: null as null | number,
}

const chatbotBuilderSlice = createSlice({
	name: 'chatbotBuilder',
	initialState,
	reducers: {
		selectInteraction: (state: ChatbotBuilderState, { payload }: PayloadAction<SelectInteractionsParams>) => {
			const { id, level } = payload
			state.selectedInteractionId = id
			state.selectedInteractionLevel = level
			state.isChatbotBuilderDrawerOpen = true
		},
		setSelectedInteractionId: (state: ChatbotBuilderState, { payload }: PayloadAction<string | null>) => {
			state.selectedInteractionId = payload
		},
		closeInteraction: (state: ChatbotBuilderState) => {
			state.selectedInteractionId = null
			state.isInteractionFormDirty = false
			state.isChatbotBuilderDrawerOpen = false
		},
		submitInteraction: (state: ChatbotBuilderState) => {
			state.isInteractionFormDirty = false
		},
		openMoveNodeModal: (state: ChatbotBuilderState, { payload }: PayloadAction<string>) => {
			state.moveNodeInteractionId = payload
			state.isMoveNodeModalOpen = true
		},
		closeMoveNodeModal: (state: ChatbotBuilderState) => {
			state.moveNodeInteractionId = null
			state.isMoveNodeModalOpen = false
		},
		openCopyNodeModal: (state: ChatbotBuilderState, { payload }: PayloadAction<string>) => {
			state.copyNodeModal = {
				copyNodeInteractionId: payload,
				isCopyNodeModalOpen: true,
			}
		},
		closeCopyNodeModal: (state: ChatbotBuilderState) => {
			state.copyNodeModal = {
				copyNodeInteractionId: null,
				isCopyNodeModalOpen: false,
			}
		},
		setIsSaveButtonLoading: (state: ChatbotBuilderState, { payload }: PayloadAction<boolean>) => {
			state.isSaveButtonLoading = payload
		},
		addOrRemoveCollapsedNode: (state: ChatbotBuilderState, { payload }: PayloadAction<{ id: string }>) => {
			const { id } = payload
			if (state.collapsedNodes.includes(id)) {
				state.collapsedNodes = state.collapsedNodes.filter((nodeId) => nodeId !== id)
			} else {
				state.collapsedNodes.push(id)
			}
		},
		setIsInteractionFormDirty: (state: ChatbotBuilderState, { payload }: PayloadAction<boolean>) => {
			state.isInteractionFormDirty = payload
		},
		setIsPublishButtonLoading: (state: ChatbotBuilderState, { payload }: PayloadAction<boolean>) => {
			state.isPublishButtonLoading = payload
		},
		expandNodes: (state: ChatbotBuilderState) => {
			state.collapsedNodes = []
		},
		collapseNodes: (state: ChatbotBuilderState, { payload }: PayloadAction<ChatbotInteraction[] | undefined>) => {
			if (payload === undefined) return
			const listOfAllCollapsibleNodes = getListOfAllCollapsibleNodes(payload)
			state.collapsedNodes = listOfAllCollapsibleNodes
		},
		setSelectedInteractionTabIndex: (state: ChatbotBuilderState, { payload }: PayloadAction<number>) => {
			state.selectedInteractionTabIndex = payload
		},
		setOpenedFilter: (state: ChatbotBuilderState, { payload }: PayloadAction<ChatbotAdditionalActionTypes | null>) => {
			state.openedTagsFilter = payload
		},
		setCurrentPropertyIndex: (state: ChatbotBuilderState, { payload }: PayloadAction<number>) => {
			state.currentPropertyIndex = payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(updateChatbot.fulfilled, (state) => {
			state.isPublishButtonLoading = false
		})
	},
})

const getIsChatbotBuilderDrawerOpen = (state: ChatbotBuilderRootState) =>
	state.chatbotBuilder.isChatbotBuilderDrawerOpen
const getSelectedInteractionId = (state: ChatbotBuilderRootState) => state.chatbotBuilder.selectedInteractionId
const getIsMoveNodeModalOpen = (state: ChatbotBuilderRootState) => state.chatbotBuilder.isMoveNodeModalOpen
const getMoveNodeInteractionId = (state: ChatbotBuilderRootState) => state.chatbotBuilder.moveNodeInteractionId
const getCopyNodeModalData = (state: ChatbotBuilderRootState) => state.chatbotBuilder.copyNodeModal
const getSelectedInteractionLevel = (state: ChatbotBuilderRootState) => state.chatbotBuilder.selectedInteractionLevel
const getCollapsedNodes = (state: ChatbotBuilderRootState) => state.chatbotBuilder.collapsedNodes
const getIsSaveButtonLoading = (state: ChatbotBuilderRootState) => state.chatbotBuilder.isSaveButtonLoading
const getIsPublishButtonLoading = (state: ChatbotBuilderRootState) => state.chatbotBuilder.isPublishButtonLoading
const getNodeId = (_: ChatbotBuilderRootState, id: string) => id
const getIsInteractionFormDirty = (state: ChatbotBuilderRootState) => state.chatbotBuilder.isInteractionFormDirty
const getSelectedInteractionTabIndex = (state: ChatbotBuilderRootState) =>
	state.chatbotBuilder.selectedInteractionTabIndex
const getOpenedTagsFilter = (state: ChatbotBuilderRootState) => state.chatbotBuilder.openedTagsFilter
const getCurrentPropertyIndex = (state: ChatbotBuilderRootState) => state.chatbotBuilder.currentPropertyIndex

const makeGetIsNodeCollapsed = () => {
	return createSelector([getCollapsedNodes, getNodeId], (nodes, id): boolean => {
		return nodes.includes(id)
	})
}

const getIsSelectedChatbotDirty = createSelector([getIsInteractionFormDirty], (isInteractionFormDirty): boolean => {
	return isInteractionFormDirty
})

const shouldDisplayDiscardChangesModal = createSelector(
	[getIsSelectedChatbotDirty, getSelectedInteractionId],
	(isChatbotDirty, interactionId): boolean => {
		return isChatbotDirty && !!interactionId
	},
)

const { actions, reducer } = chatbotBuilderSlice

export const {
	closeInteraction,
	selectInteraction,
	setSelectedInteractionId,
	openMoveNodeModal,
	closeMoveNodeModal,
	openCopyNodeModal,
	closeCopyNodeModal,
	addOrRemoveCollapsedNode,
	setSelectedInteractionTabIndex,
	setIsInteractionFormDirty,
	setIsPublishButtonLoading,
	submitInteraction,
	expandNodes,
	collapseNodes,
	setOpenedFilter,
	setCurrentPropertyIndex,
} = actions

export const chatbotBuilderSelectors = {
	getIsChatbotBuilderDrawerOpen,
	getSelectedInteractionId,
	getSelectedInteractionLevel,
	getCollapsedNodes,
	makeGetIsNodeCollapsed,
	getIsSaveButtonLoading,
	getIsPublishButtonLoading,
	getIsMoveNodeModalOpen,
	getMoveNodeInteractionId,
	getCopyNodeModalData,
	getIsInteractionFormDirty,
	getIsSelectedChatbotDirty,
	shouldDisplayDiscardChangesModal,
	getSelectedInteractionTabIndex,
	getOpenedTagsFilter,
	getCurrentPropertyIndex,
}

export default reducer
