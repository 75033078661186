export enum DateRangePreset {
	All = 'all',
	Today = 'today',
	Yesterday = 'yesterday',
	ThisWeek = 'thisWeek',
	ThisMonth = 'thisMonth',
	LastWeek = 'lastWeek',
	LastMonth = 'lastMonth',
	Past7D = 'past7days',
	Past30D = 'past30days',
	PastYear = 'pastYear',
}

export const dateRangePresetsTextsMap: Record<DateRangePreset, string> = {
	[DateRangePreset.All]: 'calendar.all',
	[DateRangePreset.Today]: 'calendar.today',
	[DateRangePreset.Yesterday]: 'calendar.yesterday',
	[DateRangePreset.ThisWeek]: 'calendar.thisWeek',
	[DateRangePreset.LastWeek]: 'calendar.lastWeek',
	[DateRangePreset.ThisMonth]: 'calendar.thisMonth',
	[DateRangePreset.LastMonth]: 'calendar.lastMonth',
	[DateRangePreset.Past7D]: 'calendar.past7days',
	[DateRangePreset.Past30D]: 'calendar.past30days',
	[DateRangePreset.PastYear]: 'calendar.pastYear',
}

export const MONTH_IN_DAYS = 31
export const YEAR_IN_DAYS = 365
