import { FeatureFlags, FeatureUsageName } from 'models'
import { apiClient } from 'shared/utils'

import { FeatureUsageAccount, FeatureUsageUser } from './types'

const API_FEATURE_FLAGS = `beta/features`
const API_FEATURE_USAGE = `feature-usage`

// Feature usage
const fetchFeatureUsageUser = (featureNames: FeatureUsageName[]) => {
	return apiClient.get<FeatureUsageUser>(`${API_FEATURE_USAGE}/user?name=${featureNames.join(',')}`)
}

const fetchFeatureUsageAccount = (featureNames: FeatureUsageName[]) => {
	return apiClient.get<FeatureUsageAccount>(`${API_FEATURE_USAGE}/account?name=${featureNames.join(',')}`)
}

const logFeatureUsage = (name: FeatureUsageName) => {
	return apiClient.post(API_FEATURE_USAGE, { name })
}

// Feature flags
const fetchFeatureFlags = () => {
	return apiClient.get<FeatureFlags>(API_FEATURE_FLAGS)
}

const updateFeatureFlags = (featureFlags: Partial<FeatureFlags>) => {
	return apiClient.patch<FeatureFlags>(API_FEATURE_FLAGS, featureFlags)
}

export const featuresApi = {
	logFeatureUsage,
	updateFeatureFlags,
	fetchFeatureUsageUser,
	fetchFeatureUsageAccount,
	fetchFeatureFlags,
}
