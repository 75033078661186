import {
	ChatbotCategory,
	ChatbotGroupType,
	ChatbotSimple,
	SelectedChatbotSimple,
	SelectedChatbotSimpleWithStatistics,
} from 'models'

import { AutomationSortDirection, AutomationSorting } from './types'

export const makeSortAutomations = (filter: AutomationSorting) => {
	const sortOrder = filter.direction === AutomationSortDirection.Desc ? -1 : 1

	return (
		firstChatbotCard: SelectedChatbotSimpleWithStatistics,
		secondChatbotCard: SelectedChatbotSimpleWithStatistics,
	): number => {
		if (firstChatbotCard[filter.type] > secondChatbotCard[filter.type]) {
			return sortOrder
		}
		if (firstChatbotCard[filter.type] < secondChatbotCard[filter.type]) {
			return -1 * sortOrder
		}
		return 0
	}
}

export const transformSimpleChatbot = (chatbot: ChatbotSimple): SelectedChatbotSimple => {
	return {
		...chatbot,
		isTypeFaq: isChatbotTypeFaq(chatbot),
		isGroupTypeChatbot: isGroupTypeChatbot(chatbot),
		isGroupTypeMessage: isGroupTypeMessage(chatbot),
		isGroupTypeAi: isGroupTypeAi(chatbot),
	}
}

export const isChatbotTypeFaq = (chatbot: ChatbotSimple): boolean => chatbot.type === ChatbotCategory.Faq

export const isGroupTypeChatbot = (chatbot: ChatbotSimple): boolean => chatbot.groupType === ChatbotGroupType.Chatbot
export const isGroupTypeMessage = (chatbot: ChatbotSimple): boolean => chatbot.groupType === ChatbotGroupType.Message
export const isGroupTypeAi = (chatbot: ChatbotSimple): boolean => chatbot.groupType === ChatbotGroupType.Ai
