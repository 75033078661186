import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { ProductNews } from 'models'
import { DashboardState } from 'types'

import { productNewsApi } from './api'

export type ProductNewsRootState = Pick<DashboardState, 'productNews'>
export type ProductNewsState = typeof initialState

const productNewsAdapter = createEntityAdapter<ProductNews>()

export const initialState = productNewsAdapter.getInitialState({
	isOpen: false,
	isFetching: false,
	isProductNewsFetched: false,
	unreadCount: 0,
})

export const fetchProductNews = createAsyncThunk('productNews/FETCH', (lang: string) => {
	return productNewsApi.getProductNews(lang)
})

export const fetchProductNewsCount = createAsyncThunk('productNews/FETCH_COUNT', () => {
	return productNewsApi.getProductNewsCount()
})

export const markProductNewsAsRead = createAsyncThunk('productNews/READ', async (_, { getState }) => {
	const state = getState() as ProductNewsRootState
	if (state.productNews.unreadCount === 0) return
	await productNewsApi.markProductNewsAsRead()
})

const productNewsSlice = createSlice({
	name: 'productNews',
	initialState,
	reducers: {
		openProductNews: (state) => {
			state.isOpen = true
		},
		closeProductNews: (state) => {
			state.isOpen = false
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchProductNews.pending, (state) => {
			state.isFetching = true
		})
		builder.addCase(fetchProductNews.fulfilled, (state, { payload }) => {
			state.isFetching = false
			state.isProductNewsFetched = true
			productNewsAdapter.setAll(state, payload)
		})
		builder.addCase(fetchProductNews.rejected, (state) => {
			state.isFetching = false
		})
		builder.addCase(fetchProductNewsCount.pending, (state) => {
			state.isFetching = true
		})
		builder.addCase(fetchProductNewsCount.fulfilled, (state, { payload }) => {
			state.isFetching = false
			state.unreadCount = payload.unreadCount
		})
		builder.addCase(fetchProductNewsCount.rejected, (state) => {
			state.isFetching = false
		})
		builder.addCase(markProductNewsAsRead.fulfilled, (state) => {
			Object.values(state.entities).forEach((item) => {
				if (item && item.isUnread) {
					item.isUnread = false
				}
			})
			state.unreadCount = 0
		})
	},
})

const { actions, reducer } = productNewsSlice
export const { openProductNews, closeProductNews } = actions
export default reducer

const entitySelectors = productNewsAdapter.getSelectors<ProductNewsRootState>((state) => state.productNews)
const getIsOpen = (state: ProductNewsRootState) => state.productNews.isOpen
const getIsFetching = (state: ProductNewsRootState) => state.productNews.isFetching
const getProductNews = (state: ProductNewsRootState) => entitySelectors.selectAll(state)
const isProductNewsFetched = (state: ProductNewsRootState) => state.productNews.isProductNewsFetched
const getUnreadProductNewsCount = (state: ProductNewsRootState) => state.productNews.unreadCount

export const productNewsSelectors = {
	getIsOpen,
	getIsFetching,
	getProductNews,
	isProductNewsFetched,
	getUnreadProductNewsCount,
}
