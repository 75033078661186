import { createAsyncThunk } from '@reduxjs/toolkit'

import {
	StripeSubscriptionCalculationRequest,
	StripeSubscriptionCalculationResponse,
	StripeSubscriptionSaveRequest,
} from 'models'
import { ApiError } from 'shared/types'

import { stripeApi } from './api'

export const calculateStripeSubscription = createAsyncThunk<
	StripeSubscriptionCalculationResponse,
	StripeSubscriptionCalculationRequest,
	{ rejectValue: ApiError }
>('stripe/CALCULATE', async (data, { rejectWithValue }) => {
	try {
		return await stripeApi.calculateSubscription(data)
	} catch (error) {
		return rejectWithValue(error as ApiError)
	}
})

export const validatePromoCodeCalculate = createAsyncThunk<
	StripeSubscriptionCalculationResponse,
	StripeSubscriptionCalculationRequest,
	{ rejectValue: ApiError }
>('stripe/VALIDATE_PROMO_CODE', async (data, { rejectWithValue }) => {
	try {
		return await stripeApi.calculateSubscription(data)
	} catch (error) {
		return rejectWithValue(error as ApiError)
	}
})

export const createStripeSubscription = createAsyncThunk<{ stripeClientSecret: string }, StripeSubscriptionSaveRequest>(
	'stripe/CREATE',
	(data) => {
		return stripeApi.createSubscription(data)
	},
)

export const setStripeSubscriptionAutoRenewal = createAsyncThunk('stripe/AUTO_RENEWAL', (enabled: boolean) => {
	return stripeApi.setSubscriptionAutoRenewal(enabled)
})

export const fetchStripeSubscription = createAsyncThunk('stripe/FETCH_SUBSCRIPTION', () => {
	return stripeApi.getSubscription()
})

export const fetchStripeBalance = createAsyncThunk('stripe/FETCH_BALANCE', () => {
	return stripeApi.getBalance()
})

export const fetchInvoice = createAsyncThunk('stripe/FETCH_INVOICE', (invoiceId: string) => {
	return stripeApi.getInvoice(invoiceId)
})

export const fetchPaymentIntent = createAsyncThunk('stripe/FETCH_PAYMENT_INTENT', (paymentIntentId: string) => {
	return stripeApi.getPaymentIntent(paymentIntentId)
})

export const fetchChurnkeyAuth = createAsyncThunk('stripe/FETCH_CHURNKEY_AUTH', () => {
	return stripeApi.getChurnkeyAuth()
})
