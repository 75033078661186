import moment from 'moment'

import {
	ChatbotTrigger,
	ChatbotTriggerCondition,
	ChatbotTriggerConditionName,
	ChatbotTriggerConditionOp,
	ChatbotTriggerConditionOpGroup,
	ChatbotTriggerConditionOpNumber,
	ChatbotTriggerConditionOpString,
	ChatbotTriggerConditionSection,
	Group,
} from 'models'
import { TranslationService as T } from 'shared/services'
import { negate } from 'utils'

import {
	chatbotGroupOperatorTranslationsMapper,
	chatbotNumberComparatorTranslationsMapper,
	ChatbotSettingsSection,
	chatbotStringComparatorTranslationsMapper,
	chatbotTriggerAccountStatusTextMap,
	ChatbotTriggerAccountStatusValue,
	chatbotTriggerConditionNumberOperationsMap,
	chatbotTriggerConditionSelectOperationsMap,
	chatbotTriggerConditionStringOperationsMap,
	chatbotTriggerConditionTextMap,
	ChatbotTriggerDeviceTypeValue,
	chatbotTriggerTypesMap,
	chatbotTriggerVisitsCountTextMap,
	daysMap,
	monthsMap,
} from './constants'
import { ChatbotTriggerTimeRangeObject, SectionTabSummary } from './types'

export const isAccountStatusCondition = (condition: ChatbotTriggerCondition): boolean => {
	return ChatbotTriggerConditionName.AccountStatus.includes(condition.name)
}
export const isNotAccountStatusCondition = negate(isAccountStatusCondition)

export const isVisitsCountCondition = (condition: ChatbotTriggerCondition): boolean => {
	return ChatbotTriggerConditionName.VisitsCount.includes(condition.name)
}

const isDeviceTypeCondition = (condition: ChatbotTriggerCondition): boolean => {
	return ChatbotTriggerConditionName.DeviceType.includes(condition.name)
}

export const mapTriggerToSummary = (
	trigger: ChatbotTrigger | null,
	disabledInput: boolean,
	isAllowedWhenChatIsTriggered: boolean,
	groups: Group[],
): Record<string, SectionTabSummary[]> => {
	if (!trigger) return {}

	return {
		[ChatbotSettingsSection.When]: whenMapper(trigger),
		[ChatbotSettingsSection.Where]: whereMapper(trigger),
		[ChatbotSettingsSection.Audience]: audienceMapper(trigger, groups),
		[ChatbotSettingsSection.Scheduling]: schedulingMapper(trigger),
		[ChatbotSettingsSection.Behavior]: behaviorMapper(disabledInput, isAllowedWhenChatIsTriggered),
	}
}

const getGroupOperatorText = (operator: ChatbotTriggerConditionOp | null, index: number): string => {
	if (operator && index > 0 && chatbotGroupOperatorTranslationsMapper[operator])
		return T.translate(chatbotGroupOperatorTranslationsMapper[operator])
	return ''
}

const whenMapper = (trigger: ChatbotTrigger): SectionTabSummary[] => {
	const { type, options } = trigger

	return [
		{ text: `${T.translate(chatbotTriggerTypesMap[type].text)}` },
		{
			text: `${T.translate('chatbotSettings.when.await')} `,
			bold: ` ${options?.delay} ${T.translate('chatbotSettings.when.seconds')}`,
		},
	]
}

export const getArrayOfConditionGroupValuesBySection = (
	section: ChatbotTriggerConditionSection,
	triggerConditions?: ChatbotTriggerCondition[],
): { value: ChatbotTriggerCondition[]; op: ChatbotTriggerConditionOp | null } => {
	const condition = triggerConditions?.find(
		(c) => c.name === ChatbotTriggerConditionName.ConditionGroup && c.section === section,
	)
	if (condition && Array.isArray(condition.value) && condition.value.some((v) => typeof v !== 'string')) {
		return { value: condition.value as ChatbotTriggerCondition[], op: condition.op }
	}
	return { value: [], op: null }
}

export const isConditionWithNumberInput = (conditionName: ChatbotTriggerConditionName) => {
	return (
		conditionName === ChatbotTriggerConditionName.ChatsCount || conditionName === ChatbotTriggerConditionName.PageViews
	)
}

const getChatbotComparatorTranslation = (
	conditionName: ChatbotTriggerConditionName,
	operator: ChatbotTriggerConditionOp,
) => {
	if (isConditionWithNumberInput(conditionName)) {
		return chatbotNumberComparatorTranslationsMapper[operator]
	}
	return chatbotStringComparatorTranslationsMapper[operator]
}

const whereMapper = (trigger: ChatbotTrigger): SectionTabSummary[] => {
	const { value, op } =
		getArrayOfConditionGroupValuesBySection(ChatbotTriggerConditionSection.Where, trigger.conditions) ?? []

	let conditions = [{ text: T.translate('chatbotSettings.where.allPages') }]

	if (value && op) {
		conditions = value.map((item, index) => {
			const textMap = chatbotTriggerConditionTextMap[item.name]
			if (!textMap || !item.op) return { text: '' }

			return {
				text: `${getGroupOperatorText(op, index)} ${T.translate(textMap.text)} ${T.translate(
					getChatbotComparatorTranslation(item.name, item.op),
				).toLocaleLowerCase()} `,
				bold: item.value,
			}
		})
	}

	return conditions
}

const audienceMapper = (trigger: ChatbotTrigger, groups: Group[]): SectionTabSummary[] => {
	const audience = trigger.conditions?.find((condition) => condition.name === ChatbotTriggerConditionName.VisitsCount)
	const number = audience ? (audience.op === ChatbotTriggerConditionOpString.Eq ? 1 : 2) : 0

	const deviceType = getDeviceTypeValue(trigger.conditions)
	const { value, op } =
		getArrayOfConditionGroupValuesBySection(ChatbotTriggerConditionSection.Audience, trigger.conditions) ?? []

	let conditions: SectionTabSummary[] = []
	if (value && op) {
		conditions = value.map((item, index) => {
			let conditionValue = item.value
			if (item.name === ChatbotTriggerConditionName.VisitorGroup) {
				const selectedGroup = groups.find((group: Group) => group.key === item.value)
				conditionValue = selectedGroup?.name || ''
			}

			const textMap = chatbotTriggerConditionTextMap[item.name]
			if (!textMap || !item.op || !op) return { text: '' }

			return {
				text: `${getGroupOperatorText(op, index)} ${T.translate(textMap.text)} ${T.translate(
					getChatbotComparatorTranslation(item.name, item.op),
				).toLocaleLowerCase()} `,
				bold: `${conditionValue}`,
			}
		})
	}

	// add device type condition if it's different than default
	if (deviceType !== ChatbotTriggerDeviceTypeValue.Both)
		conditions.unshift({
			text: `${T.translate('chatbotSettings.device.type.summary.prefix')} `,
			bold: `${T.translate(`chatbotSettings.device.type.${deviceType}`)}`,
		})

	return [{ text: `${T.translate(chatbotTriggerVisitsCountTextMap[number].text)}` }, ...conditions]
}

const getMonthsSummaryTexts = (months: string[]): SectionTabSummary | null => {
	if (months.length === 0) return null
	const monthStrings = months.map((month) => T.translate(monthsMap[month]))
	return { text: `${T.translate('chatbotSettings.scheduling.months.prefix')}: `, bold: monthStrings.join(', ') }
}

export const getSortedDateItemsStrings = (
	conditionName: typeof ChatbotTriggerConditionName.MonthOfYear | typeof ChatbotTriggerConditionName.DayOfWeek,
	conditions?: ChatbotTriggerCondition[],
): string[] => {
	if (!conditions) return []
	const cond = conditions.filter((c) => c.name === ChatbotTriggerConditionName.ConditionGroup)
	const chatbotDateGroup = cond.find(
		(c) =>
			Array.isArray(c.value) && c.value.some((item) => typeof item !== 'string' && conditionName.includes(item.name)),
	)
	const items: number[] = []

	const dateConditions = chatbotDateGroup && Array.isArray(chatbotDateGroup.value) ? chatbotDateGroup.value : []
	dateConditions.forEach((dateCondition) => {
		if (
			typeof dateCondition !== 'string' &&
			dateCondition.name === conditionName &&
			dateCondition.op === ChatbotTriggerConditionOpString.Eq &&
			typeof dateCondition.value === 'number'
		) {
			items.push(dateCondition.value)
		}
	})
	return items.sort((a, b) => a - b).map((item) => item.toString())
}

export const getTimeIntervals = (conditions?: ChatbotTriggerCondition[]): ChatbotTriggerTimeRangeObject[] => {
	if (!conditions) return []
	const cond = conditions.filter((c) => c.name === ChatbotTriggerConditionName.ConditionGroup)
	const chatbotTimesGroup = cond.find(
		(c) =>
			Array.isArray(c.value) &&
			c.value.some((item) => typeof item !== 'string' && item.name === ChatbotTriggerConditionName.TimeOfDay),
	)
	const items: ChatbotTriggerTimeRangeObject[] = []

	const timesConditions = chatbotTimesGroup && Array.isArray(chatbotTimesGroup.value) ? chatbotTimesGroup.value : []
	timesConditions.forEach((timeCondition) => {
		if (
			typeof timeCondition !== 'string' &&
			timeCondition.name === ChatbotTriggerConditionName.TimeOfDay &&
			Array.isArray(timeCondition.value) &&
			typeof timeCondition.value[0] === 'string' &&
			typeof timeCondition.value[1] === 'string'
		) {
			items.push({ from: timeCondition.value[0], to: timeCondition.value[1] })
		}
	})
	return items
}

const getTimesSummaryStrings = (intervals: ChatbotTriggerTimeRangeObject[]): SectionTabSummary | null => {
	if (intervals.length === 0) return null
	const intervalTexts = intervals.map((interval) => {
		const from = moment(interval.from, 'HHmm').format('LT')
		const to = moment(interval.to, 'HHmm').format('LT')
		return `${from} - ${to}`
	})

	return { text: `${T.translate('chatbotSettings.scheduling.times.prefix')}: `, bold: intervalTexts.join(', ') }
}

const getDaysSummaryTexts = (days: string[]): SectionTabSummary | null => {
	const daysStrings: string[] = []
	const SUNDAY_VALUE = '0'

	if (days.length === 0) return null

	const isSundaySelected = days.includes(SUNDAY_VALUE)
	const filteredDays = isSundaySelected ? [...days.filter((d) => d !== SUNDAY_VALUE), SUNDAY_VALUE] : days

	filteredDays.forEach((day) => {
		const dayText = daysMap.find((d) => d.value === day)
		dayText && daysStrings.push(T.translate(dayText.text))
	})

	return { text: `${T.translate('chatbotSettings.scheduling.days.prefix')}: `, bold: daysStrings.join(', ') }
}

const schedulingMapper = (trigger: ChatbotTrigger): SectionTabSummary[] => {
	const scheduler = trigger.conditions?.find(
		(condition) => condition.name === ChatbotTriggerConditionName.AccountStatus,
	)

	const schedulingTexts: SectionTabSummary[] = []

	const schedulerStatus = scheduler?.value
		? chatbotTriggerAccountStatusTextMap[scheduler.value as ChatbotTriggerAccountStatusValue].text
		: chatbotTriggerAccountStatusTextMap[ChatbotTriggerAccountStatusValue.Any].text

	const status = {
		text: `${T.translate('chatbotSettings.scheduling.status.prefix')}: `,
		bold: T.translate(schedulerStatus),
	}

	const months = getMonthsSummaryTexts(
		getSortedDateItemsStrings(ChatbotTriggerConditionName.MonthOfYear, trigger.conditions),
	)
	const days = getDaysSummaryTexts(getSortedDateItemsStrings(ChatbotTriggerConditionName.DayOfWeek, trigger.conditions))
	const times = getTimesSummaryStrings(getTimeIntervals(trigger.conditions))

	if (months) schedulingTexts.push(months)
	if (days) schedulingTexts.push(days)
	if (times) schedulingTexts.push(times)

	return [status, ...schedulingTexts]
}

const behaviorMapper = (disabledInput: boolean, isAllowedWhenChatIsTriggered: boolean): SectionTabSummary[] => {
	return [
		{
			text: `${T.translate('chatbotSettings.behavior.summaryDisableTextField')} `,
			bold: `${T.translate(
				disabledInput ? 'notifications.desktop.denied' : 'notifications.desktop.enabled',
			).toLocaleLowerCase()}`,
		},
		{
			text: T.translate(`chatbotSettings.behavior.isAllowedTriggered.description.${isAllowedWhenChatIsTriggered}`),
			bold: '',
		},
	]
}

export const getChatbotTriggerConditionOperationsMap = (
	type: ChatbotTriggerConditionName,
): { name: ChatbotTriggerConditionOpString | ChatbotTriggerConditionOpNumber; text: string }[] => {
	switch (type) {
		case ChatbotTriggerConditionName.VisitorGroup: {
			return Object.values(chatbotTriggerConditionSelectOperationsMap)
		}
		case ChatbotTriggerConditionName.ChatsCount:
		case ChatbotTriggerConditionName.PageViews: {
			return Object.values(chatbotTriggerConditionNumberOperationsMap)
		}
		// Use only "contains" and "not_contains" operators for OS condition
		case ChatbotTriggerConditionName.Os: {
			return Object.values(chatbotTriggerConditionStringOperationsMap).filter((operation) => {
				return (
					operation.name === ChatbotTriggerConditionOpString.Contains ||
					operation.name === ChatbotTriggerConditionOpString.NotContains
				)
			})
		}
		default: {
			return Object.values(chatbotTriggerConditionStringOperationsMap)
		}
	}
}

export const getDeviceTypeValue = (conditions: ChatbotTriggerCondition[]): ChatbotTriggerDeviceTypeValue => {
	const deviceTypeCondition = conditions.find(isDeviceTypeCondition)
	if (!deviceTypeCondition) return ChatbotTriggerDeviceTypeValue.Both
	return deviceTypeCondition.value as ChatbotTriggerDeviceTypeValue
}

export const getVisitsCountCondition = (value: number, op: ChatbotTriggerConditionOpNumber) => {
	return {
		name: ChatbotTriggerConditionName.VisitsCount,
		op,
		value,
	}
}

export const getSchedulingConditionGroup = (items: string[], name: ChatbotTriggerConditionName) => {
	const conditions: ChatbotTriggerCondition[] = items.map((item) => {
		return {
			name,
			op: ChatbotTriggerConditionOpString.Eq,
			value: Number(item),
		}
	})
	const conditionGroup: ChatbotTriggerCondition = {
		name: ChatbotTriggerConditionName.ConditionGroup,
		op: ChatbotTriggerConditionOpGroup.Or,
		value: conditions,
		section: ChatbotSettingsSection.Scheduling,
	}
	return conditionGroup
}

export const getTimesConditionGroup = (items: ChatbotTriggerTimeRangeObject[]) => {
	const conditions: ChatbotTriggerCondition[] = items.map((item) => {
		return {
			name: ChatbotTriggerConditionName.TimeOfDay,
			op: ChatbotTriggerConditionOpString.Eq,
			value: [item.from, item.to],
		}
	})
	const conditionGroup: ChatbotTriggerCondition = {
		name: ChatbotTriggerConditionName.ConditionGroup,
		op: ChatbotTriggerConditionOpGroup.Or,
		value: conditions,
		section: ChatbotSettingsSection.Scheduling,
	}
	return conditionGroup
}

export const validateTimeInterval = (interval: ChatbotTriggerTimeRangeObject): boolean => {
	const { from, to } = interval
	return from <= to
}
