import store from 'store'

import {
	VisitorDisconnected,
	VisitorFilter,
	VisitorFilterType,
	VisitorSimple,
	VisitorsSearchRequest,
	VisitorStatus,
	VisitorUpdated,
} from 'shared/models/Visitor'
import { normalizeString, pick } from 'shared/utils'

type VisitorsFilterRequestParams = Pick<VisitorsSearchRequest, 'filters' | 'query'> | undefined

const VISITORS_FILTER_KEY = 'ss-visitorsFilter'

const STATUS_UNSERVED = 6
const STATUS_SERVED = 5
const STATUS_CLICKED_ACTIVE = 4
const STATUS_IDLE = 3
const STATUS_BANNED = 2

const isFilterValid = (filter: VisitorFilterType) => Object.values(VisitorFilterType).includes(filter)

export const isVisitorsFilterActive = (filter: VisitorFilterType, textFilter: string) =>
	filter !== VisitorFilterType.All || textFilter.length > 0

const statusToNumber = (status: VisitorStatus, isBanned: boolean) => {
	if (isBanned) return STATUS_BANNED

	switch (status) {
		case VisitorStatus.Unserved: {
			return STATUS_UNSERVED
		}
		case VisitorStatus.Served: {
			return STATUS_SERVED
		}
		case VisitorStatus.Triggered:
		case VisitorStatus.Clicked:
		case VisitorStatus.Active: {
			return STATUS_CLICKED_ACTIVE
		}
		case VisitorStatus.Idle: {
			return STATUS_IDLE
		}
		default: {
			return 1
		}
	}
}

export const visitorsSorter = (v1: VisitorSimple, v2: VisitorSimple) => {
	const hasConnectedAt = !!v1.connectedAt && !!v2.connectedAt
	if (hasConnectedAt && statusToNumber(v1.status, !!v1.bannedAt) === statusToNumber(v2.status, !!v2.bannedAt)) {
		return new Date(v2.connectedAt!).getTime() - new Date(v1.connectedAt!).getTime()
	}
	return statusToNumber(v2.status, !!v2.bannedAt) - statusToNumber(v1.status, !!v1.bannedAt)
}

export const loadVisitorsFilter = (): VisitorFilterType => {
	if (!store.enabled) return VisitorFilterType.All

	const storedFilter = store.get(VISITORS_FILTER_KEY)
	return storedFilter && isFilterValid(storedFilter as VisitorFilterType) ? storedFilter : VisitorFilterType.All
}

export const storeVisitorsFilter = (value: VisitorFilterType) => store.set(VISITORS_FILTER_KEY, value)

export const isVisitorIdentified = (visitorName: string | null): boolean => !!(visitorName && visitorName.length > 0)

export const isVisitorActive = (status: VisitorStatus) => {
	return (
		status === VisitorStatus.Active ||
		status === VisitorStatus.Clicked ||
		status === VisitorStatus.Served ||
		status === VisitorStatus.Unserved ||
		status === VisitorStatus.Triggered
	)
}

const getVisitorStatusFilter = (filter: VisitorFilter) => {
	return Object.entries(filter).reduce<VisitorStatus[]>((acc, [key, value]) => {
		if (value) acc.push(key as VisitorStatus)
		return acc
	}, [])
}

export const visitorsFilterToRequestParams = (
	filterType: VisitorFilterType,
	textFilter: string,
): VisitorsFilterRequestParams => {
	const params: VisitorsFilterRequestParams = {}
	const filters: VisitorsSearchRequest['filters'] = {}
	const query: VisitorsSearchRequest['query'] = []

	if (filterType === VisitorFilterType.Active) {
		filters.status = getVisitorStatusFilter(activeFilter)
		filters.banned = false
	}
	if (filterType === VisitorFilterType.Chatting) {
		filters.status = getVisitorStatusFilter(chattingFilter)
		filters.banned = false
	}
	if (filterType === VisitorFilterType.Triggered) {
		filters.status = getVisitorStatusFilter(triggeredFilter)
		filters.banned = false
	}
	if (filterType === VisitorFilterType.Identified) {
		filters.customers = true
		filters.banned = false
	}
	if (filterType === VisitorFilterType.Blocked) {
		filters.banned = true
	}
	if (textFilter.length > 0) {
		query.push({ fields: ['id', 'name', 'contactId'], value: textFilter, op: 'contains' })
	}

	if (Object.keys(filters).length > 0) {
		params.filters = filters
	}
	if (query.length > 0) {
		params.query = query
	}

	return Object.keys(params).length > 0 ? params : undefined
}

const defaultFilter: VisitorFilter = {
	[VisitorStatus.Active]: true,
	[VisitorStatus.Clicked]: true,
	[VisitorStatus.Idle]: true,
	[VisitorStatus.Served]: true,
	[VisitorStatus.Triggered]: true,
	[VisitorStatus.Unserved]: true,
}

const activeFilter: VisitorFilter = {
	...defaultFilter,
	[VisitorStatus.Idle]: false,
}

const chattingFilter: VisitorFilter = {
	...defaultFilter,
	[VisitorStatus.Active]: false,
	[VisitorStatus.Clicked]: false,
	[VisitorStatus.Idle]: false,
	[VisitorStatus.Triggered]: false,
	[VisitorStatus.Unserved]: false,
}

const identifiedFilter = { ...defaultFilter }

const triggeredFilter: VisitorFilter = {
	...defaultFilter,
	[VisitorStatus.Active]: false,
	[VisitorStatus.Clicked]: false,
	[VisitorStatus.Idle]: false,
	[VisitorStatus.Served]: false,
	[VisitorStatus.Unserved]: false,
}

const blockedFilter: VisitorFilter = {
	...defaultFilter,
	[VisitorStatus.Clicked]: false,
}

export const visitorFiltersMap: Record<VisitorFilterType, VisitorFilter> = {
	[VisitorFilterType.Active]: activeFilter,
	[VisitorFilterType.All]: defaultFilter,
	[VisitorFilterType.Blocked]: blockedFilter,
	[VisitorFilterType.Chatting]: chattingFilter,
	[VisitorFilterType.Identified]: identifiedFilter,
	[VisitorFilterType.Triggered]: triggeredFilter,
}

export const visitorContainsText = (visitor: VisitorSimple | VisitorDisconnected, text: string): boolean => {
	const searchText = normalizeString(text)
	const visitorName = visitor.name ?? visitor.id
	const normalizedName = normalizeString(visitorName)
	return normalizedName.includes(searchText)
}

const createVisitorBaseFilter =
	(filterType: VisitorFilterType) =>
	(visitor: VisitorSimple | VisitorDisconnected): boolean => {
		const filter = visitorFiltersMap[filterType]
		const filterOutBlocked = filterType === VisitorFilterType.Blocked ? !!visitor.bannedAt : !visitor.bannedAt
		const filterByIdentified = filterType === VisitorFilterType.Identified ? isVisitorIdentified(visitor.name) : true
		const filterByStatus = filter[visitor.status]
		return filterOutBlocked && filterByIdentified && filterByStatus
	}

const createVisitorTextFilter =
	(filterType: VisitorFilterType, textFilter: string) =>
	(visitor: VisitorSimple | VisitorDisconnected): boolean => {
		const filterBase = createVisitorBaseFilter(filterType)(visitor)
		const filterByText = !!textFilter && visitorContainsText(visitor, textFilter)
		return filterBase && filterByText
	}

export const createVisitorFilter = (filterType: VisitorFilterType, textFilter: string) => {
	return textFilter ? createVisitorTextFilter(filterType, textFilter) : createVisitorBaseFilter(filterType)
}

export const getUpdatedVisitorFilterResult = (
	filterType: VisitorFilterType,
	textFilter: string,
	updatedVisitor: VisitorUpdated,
) => {
	const currentVisitor = pick(updatedVisitor, 'id', 'name', 'email', 'status', 'bannedAt')
	const previousVisitor: typeof currentVisitor = { ...currentVisitor, ...updatedVisitor.previous }
	const isFiltered = createVisitorFilter(filterType, textFilter)
	const isFilteredAfterChanges = isFiltered(currentVisitor)
	const isFilteredBeforeChanges = isFiltered(previousVisitor)
	return { isFilteredAfterChanges, isFilteredBeforeChanges }
}

export const serializeVisitorData = (data: Record<string, string | null>, shouldPutToSentenceCase = true): string => {
	return Object.keys(data).reduce((acc, key) => {
		const value = data[key]
		if (value === '') return acc
		return `${acc}${shouldPutToSentenceCase ? toSentenceCase(key) : key}: ${value}; \n`
	}, '')
}

export const toSentenceCase = (str: string) => {
	let sentenceCase = str.replaceAll(/([\da-z])([A-Z])/g, '$1 $2') // remove CamelCase
	sentenceCase = sentenceCase.replaceAll(/[_-]/g, ' ') // remove underScore and dash
	sentenceCase = sentenceCase.charAt(0).toUpperCase() + sentenceCase.slice(1) // first letter to Upper case
	return sentenceCase
}
