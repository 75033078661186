import { ReactNode } from 'react'
import { Alert as ChakraAlert, AlertDescription, AlertIcon, AlertProps, AlertTitle, Flex } from '@chakra-ui/react'

import { Box, Stack } from 'core'

export type { AlertProps } from '@chakra-ui/react'

type Props = {
	showIcon?: boolean
	title?: string
	description: ReactNode
	extra?: ReactNode
	buttonRight?: ReactNode
} & AlertProps

const Alert = ({
	showIcon = false,
	title,
	description,
	variant = 'left-accent',
	extra,
	buttonRight,
	lineHeight,
	...rest
}: Props) => {
	return (
		<ChakraAlert variant={variant} borderRadius={1} {...rest}>
			{showIcon && <AlertIcon />}
			<Stack direction={{ base: 'column', sm: 'row' }} w="full" alignItems="center" spacing={{ base: '1', sm: '4' }}>
				<Flex flexDirection="column" w="full" alignItems="flex-start">
					{title && <AlertTitle>{title}</AlertTitle>}
					<AlertDescription w="full" lineHeight={lineHeight}>
						{description}
					</AlertDescription>
					{extra}
				</Flex>
				{buttonRight && <Box alignSelf="center">{buttonRight}</Box>}
			</Stack>
		</ChakraAlert>
	)
}

export default Alert
