import { OnboardingStepCode, OnboardingType } from 'shared/models/Onboarding'
import { AccountSegmentCompanySize, AccountSegmentCustomerObjective } from 'shared/models/Segmentation'
import { theme } from 'styles'

import { OnboardingStepsMap } from './types'

export const DEFAULT_STEP = 0

// design
export const DEFAULT_COLOR = '#1233df'

export const COLOR_TEXT_LIGHT = theme.colors.white
export const COLOR_TEXT_DARK = theme.colors.gray[700]
export const DEFAULT_COLOR_TEXT = COLOR_TEXT_LIGHT

export const onboardingStepsLeadgen: OnboardingStepsMap = {
	0: { code: OnboardingStepCode.Objective, title: 'onboard.step.objective' },
	1: { code: OnboardingStepCode.Survey, title: 'onboard.step.survey' },
	2: { code: OnboardingStepCode.Basics, title: 'onboard.step.basics' },
}

const onboardingStepsAi: OnboardingStepsMap = {
	0: { code: OnboardingStepCode.Survey, title: 'onboard.step.survey' },
	1: { code: OnboardingStepCode.Message, title: 'onboard.chatbot.label' },
}

const stepsChatbot: OnboardingStepsMap = {
	...onboardingStepsLeadgen,
	3: { code: OnboardingStepCode.Chatbot, title: 'onboard.step.chatbot' },
}

const onboardingStepsChatbot: OnboardingStepsMap = {
	...stepsChatbot,
	4: { code: OnboardingStepCode.Install, title: 'onboard.step.install' },
}

const onboardingStepsPluginsChatbot: OnboardingStepsMap = {
	...stepsChatbot,
}

const onboardingStepsInstallShopify: OnboardingStepsMap = {
	...stepsChatbot,
	4: { code: OnboardingStepCode.ShopifyInstall, title: 'onboard.step.install' },
}

export const onboardingStepsTypeMap: Record<OnboardingType, OnboardingStepsMap> = {
	[OnboardingType.Chatbot]: onboardingStepsChatbot,
	[OnboardingType.PluginsChatbot]: onboardingStepsPluginsChatbot,
	[OnboardingType.Shopify]: onboardingStepsInstallShopify,
	[OnboardingType.Ai]: onboardingStepsAi,
}

export const companySizesArray = [
	{ name: AccountSegmentCompanySize.S, title: 'companySize.s' },
	{ name: AccountSegmentCompanySize.M, title: 'companySize.m' },
	{ name: AccountSegmentCompanySize.XL, title: 'companySize.xl' },
	{ name: AccountSegmentCompanySize.XXL, title: 'companySize.xxl' },
]

export const customerObjectivesArray = [
	AccountSegmentCustomerObjective.Sales,
	AccountSegmentCustomerObjective.CustomerSupport,
	AccountSegmentCustomerObjective.Leadgen,
]

export const forbiddenUserEmailDomains = [
	'gmail',
	'yahoo',
	'icloud',
	'mail.ru',
	'seznam',
	'centrum',
	'smartsupp',
	'hotmail',
]

export const customerObjectivesEmojis = {
	[AccountSegmentCustomerObjective.Sales]: '💰',
	[AccountSegmentCustomerObjective.CustomerSupport]: '🙏',
	[AccountSegmentCustomerObjective.Leadgen]: '🤖',
}
