import { Dictionary, IdentifiedEntity } from 'types'
import { isString } from 'shared/utils'

import { normalize } from './normalize'

export const sortDictionary = <T extends IdentifiedEntity>(
	dictionary: Dictionary<T>,
	sorter: (a: T, b: T) => number,
) => {
	const sortedList = Object.values(dictionary).sort(sorter)
	const item = sortedList[0]
	if (item && !isString(item.id)) {
		throw new Error(`Dictionary must have property 'id' of type string`)
	}

	return normalize('id', sortedList)
}
