import accept from 'attr-accept'

import { FilesUploadConfig } from 'shared/models/AppConfig'
import { TranslationService as T } from 'shared/services'
import { notification } from 'shared/utils'
import { MIN_FILE_SIZE_IN_BYTES } from 'constants/files'

import { formatFileSize } from './formatFileSize'

export const validateFileSize = (file: File, maxFileSize: FilesUploadConfig['maxFileSize']) => {
	const { size } = file
	if (size < MIN_FILE_SIZE_IN_BYTES) {
		notification.error({
			title: T.translate('chat.fileUpload.error.minSize', { filesize: formatFileSize(MIN_FILE_SIZE_IN_BYTES) }),
		})
		return false
	}

	if (size > maxFileSize) {
		notification.error({
			title: T.translate('chat.fileUpload.error.maxSize', { filesize: formatFileSize(maxFileSize) }),
		})
		return false
	}

	return true
}

export const getFileUploadConfig = (config: FilesUploadConfig) => {
	const extensionsWithDot = config.acceptedFileExtensions.map((extension: string) => `.${extension}`)
	const acceptedFiles = [...config.acceptedFileTypes, ...extensionsWithDot]
	return { acceptFiles: acceptedFiles, maxFileSize: config.maxFileSize }
}

export const validateFileType = (file: File, acceptFiles: string[]) => {
	const shouldAcceptFile = accept({ name: file.name, type: file.type }, acceptFiles)
	if (shouldAcceptFile) return true
	notification.error({
		title: T.translate('chat.fileUpload.error.fileType', { filetype: file.type }),
		testId: 'notification-error-upload-filetype',
	})
	return false
}
