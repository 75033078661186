import moment, * as Moment from 'moment'
import { extendMoment } from 'moment-range'

import {
	AgentsStatisticsSummary,
	AggregateByInterval,
	MetricOptions,
	MetricsAggregate,
	MetricsBucket,
	MetricsBucketSession,
	MetricsRequestBodyParams,
	MetricsResponse,
	MetricsType,
	OverallChartData,
	SeriesItem,
	StatisticsInterval,
} from 'shared/models/Statistics'
import { isString } from 'shared/utils'
import { StatisticsFilter } from 'modules/statisticsFilter'

import { allowedMetricsTypeForTags, ONLINE_ACTIVITY_KEY_SEPARATOR, RatingsSmileMap } from './constants'
import {
	ChartType,
	NewActivityValuesType,
	OnlineActivityBucketSession,
	RatingRequestBody,
	RatingResponse,
	Ratings,
	RatingsChartData,
	RatingsValue,
	Record,
	SummaryChartData,
} from './types'

const HOUR_IN_SEC = 3600
const MIN_IN_SEC = 60
const momentExt = extendMoment(Moment)

export const formatSeconds = (t: number, useHours: boolean): string => {
	const time = Math.round(t)
	const hours = Math.floor(time / HOUR_IN_SEC)
	const minutes = useHours ? Math.floor(time / MIN_IN_SEC) % MIN_IN_SEC : Math.floor(time / MIN_IN_SEC)
	const seconds = time % MIN_IN_SEC

	const formatArray = []

	if (hours > 0 && useHours) formatArray.push(`${hours}h`)
	if (minutes > 0 || useHours) formatArray.push(`${minutes}m`)
	!useHours && formatArray.push(`${seconds}s`)

	return formatArray.join(' ')
}

export const xAxisDateFormatter = (
	date: string | number | Date,
	interval: StatisticsInterval,
	numberOfDates: number,
	maxNumberOfRecords = 30,
): string => {
	const MAX_NUMBER_OF_RECORDS = maxNumberOfRecords
	const MAX_NUMBER_OF_RECORDS_WEEK = 10
	const iteration: number = Math.ceil(numberOfDates / MAX_NUMBER_OF_RECORDS)
	const iterationWeek: number = Math.ceil(numberOfDates / MAX_NUMBER_OF_RECORDS_WEEK)

	if (interval === StatisticsInterval.Day && moment(date, 'dddd, LL').dayOfYear() % iteration === 0) {
		return `${moment(date, 'dddd, LL').format('D')}`
	}
	if (interval === StatisticsInterval.Week && isString(date) && Number.parseInt(date, 10) % iterationWeek === 0) {
		return `${date}`
	}
	if (interval === StatisticsInterval.Month && moment(date, 'MMMM Y').month() % iteration === 0) {
		return `${moment(date, 'MMMM Y').format('MMM')}`
	}
	return ''
}

export const autoFormatSeconds = (t: number) => {
	if (t < HOUR_IN_SEC) {
		return formatSeconds(t, false)
	}
	return formatSeconds(t, true)
}

export const calcAgentPercentageRating = (rating: number): number => {
	const isRatingValid = rating > 0 && rating <= 5
	return isRatingValid ? Math.round(((rating - 1) * 100) / 4) : 0
}

export const getBucketValuesByAgent = (buckets: MetricsBucket[], agentId: string): { value: number; count: number } => {
	const filteredBucket = buckets.filter((bucket) => bucket.key === agentId)
	if (filteredBucket.length > 0) {
		return { value: filteredBucket[0].value, count: filteredBucket[0].count }
	}

	return { value: 0, count: 0 }
}

export const formatMetricsByAgent =
	(metrics: MetricsResponse[]) =>
	(agentSummary: AgentsStatisticsSummary): AgentsStatisticsSummary => {
		let newItem = { ...agentSummary }
		metrics.forEach((metric) => {
			switch (metric.name) {
				case MetricsType.NewConversation: {
					const { value } = getBucketValuesByAgent(metric.buckets, `${agentSummary.agent.id}`)
					newItem = { ...newItem, new_conversation: value }
					break
				}
				case MetricsType.AvgFirstResponseTime: {
					const { value } = getBucketValuesByAgent(metric.buckets, `${agentSummary.agent.id}`)
					newItem = { ...newItem, avg_first_response_time: value }
					break
				}
				case MetricsType.ConversationRatings: {
					const { value, count } = getBucketValuesByAgent(metric.buckets, `${agentSummary.agent.id}`)
					newItem = { ...newItem, conversation_ratings: value, conversation_ratings_count: count }
					break
				}
				case MetricsType.SumTimeToClose: {
					const { value } = getBucketValuesByAgent(metric.buckets, `${agentSummary.agent.id}`)
					newItem = { ...newItem, sum_time_to_close: value }
					break
				}
				case MetricsType.AvgTimeToClose: {
					const { value } = getBucketValuesByAgent(metric.buckets, `${agentSummary.agent.id}`)
					newItem = { ...newItem, avg_time_to_close: value }
					break
				}
				case MetricsType.AvgResponseTime: {
					const { value } = getBucketValuesByAgent(metric.buckets, `${agentSummary.agent.id}`)
					newItem = { ...newItem, avg_response_time: value }
					break
				}
				default:
			}
		})
		return { ...newItem }
	}

export const filterEmptyResults = (agent: AgentsStatisticsSummary): boolean => {
	const metricsAgentTable = new Set([
		'new_conversation',
		'avg_first_response_time',
		'conversation_ratings',
		'conversation_ratings_count',
		'sum_time_to_close',
		'avg_time_to_close',
		'avg_response_time',
	])
	const nonEmptyMetrics = Object.entries(agent).filter(([key, value]) => metricsAgentTable.has(key) && value > 0)

	return nonEmptyMetrics.length > 0
}

const hasGroupToFilter = (group: string): boolean => group !== ''
const hasTagsToFilter = (tags: string[]): boolean => tags.length > 0

export const getConvertedRange = (from: Date, to: Date) => {
	return {
		from: moment(from).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
		to: moment(to).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
	}
}

export const getFilters = (group: string): { groupId: string } | undefined => {
	let filters

	if (hasGroupToFilter(group)) {
		filters = { groupId: group }
	}

	return filters
}

const getQuery = (metricsType: MetricsType, tags: string[]): MetricOptions.Query[] | undefined => {
	let query

	if (hasTagsToFilter(tags) && allowedMetricsTypeForTags.includes(metricsType)) {
		query = [{ field: 'tags', value: tags }]
	}

	return query
}

export const getRequestBodyParams = (
	typesArray: { metricsType: MetricsType; interval?: StatisticsInterval }[],
	{ from, to, group, tags }: StatisticsFilter,
	timezone: string,
	aggs: MetricsAggregate,
	withTrend?: boolean,
): MetricsRequestBodyParams[] => {
	return typesArray.map((item) => {
		const { interval, metricsType } = item
		const bodyParamsOptions = {
			timezone,
			range: from && to ? getConvertedRange(from, to) : undefined,
			aggs: [{ ...aggs, interval, format: interval ? getDateFormatByInterval(interval) : undefined }],
			withTrend,
		}
		return {
			name: metricsType,
			options: {
				...bodyParamsOptions,
				// deprecated - move into query attr
				filters: getFilters(group),
				query: getQuery(metricsType, tags),
			},
		}
	})
}

export const getRatingsRequestBody = (
	{ from, to, group, tags }: StatisticsFilter,
	timezone: string,
	aggs: AggregateByInterval,
): RatingRequestBody => {
	return {
		[Ratings.Bad]: {
			name: MetricsType.ConversationRatings,
			options: {
				timezone,
				filters: {
					ratingValue: [1, 2],
					...getFilters(group),
				},
				query: getQuery(MetricsType.ConversationRatings, tags),
				range: from && to ? getConvertedRange(from, to) : undefined,
				aggs: [aggs],
			},
		},
		[Ratings.Ok]: {
			name: MetricsType.ConversationRatings,
			options: {
				timezone,
				filters: {
					ratingValue: [3],
					...getFilters(group),
				},
				query: getQuery(MetricsType.ConversationRatings, tags),
				range: from && to ? getConvertedRange(from, to) : undefined,
				aggs: [aggs],
			},
		},
		[Ratings.Happy]: {
			name: MetricsType.ConversationRatings,
			options: {
				timezone,
				filters: {
					ratingValue: [4, 5],
					...getFilters(group),
				},
				query: getQuery(MetricsType.ConversationRatings, tags),
				range: from && to ? getConvertedRange(from, to) : undefined,
				aggs: [aggs],
			},
		},
	}
}

export const getSeriesData = (
	records: Record[],
	metricsType: MetricsType,
	defaultValue: number | null,
): OverallChartData[] => {
	const seriesData = records.map((record) => {
		return createSeriesItem(record)
	})

	return [
		{
			id: metricsType,
			data:
				seriesData &&
				seriesData.map((dataItem) => {
					return {
						x: dataItem.name,
						y: dataItem.y || defaultValue,
					}
				}),
		},
	]
}

export const getMaxSeriesValue = (buckets: MetricsBucket[]) => {
	if (!buckets || buckets.length === 0) return 0
	const values = buckets.map((bucket) => bucket.value)
	return Math.max(...values)
}

const createSeriesItem = (record: Record): SeriesItem => {
	return {
		name: record.longName,
		y: record.value || 0,
	}
}

export const addPercentagesToResult = (item: RatingsChartData): RatingsChartData => {
	const total = item.values[Ratings.Happy] + item.values[Ratings.Ok] + item.values[Ratings.Bad]
	if (Number.isNaN(total) || total === 0) return item

	item[Ratings.Happy] = (item.values[Ratings.Happy] / total) * 100
	item[Ratings.Ok] = (item.values[Ratings.Ok] / total) * 100
	item[Ratings.Bad] = (item.values[Ratings.Bad] / total) * 100

	return item
}

export const roundToTheTopTen = (value: number): number => {
	return Math.ceil((value + 1) / 10) * 10
}

export const getRatingsSmileByPercentage = (percentage: number, ratings: RatingResponse): string => {
	const isRatingsEmpty =
		ratings[Ratings.Bad].count === 0 && ratings[Ratings.Ok].count === 0 && ratings[Ratings.Happy].count === 0

	if (!isRatingsEmpty) {
		let imagePath: string = RatingsSmileMap.Default
		if (percentage >= RatingsValue.Positive) {
			imagePath = RatingsSmileMap[Ratings.Happy]
		}
		if (percentage >= RatingsValue.Ok && percentage < RatingsValue.Positive) {
			imagePath = RatingsSmileMap[Ratings.Ok]
		}
		if (percentage < RatingsValue.Ok) {
			imagePath = RatingsSmileMap[Ratings.Bad]
		}

		return imagePath
	}

	return RatingsSmileMap.Default
}

// for key is appended SEPARATOR and startedAt value due to unique value
export const getFormattedKey = ({ startedAt, stoppedAt, type }: MetricsBucketSession): string => {
	return `${type} ${moment(startedAt, 'YYYY_MM_DD:HH_mm_ss').format('HH:mm')} - ${moment(
		stoppedAt,
		'YYYY_MM_DD:HH_mm_ss',
	).format('HH:mm')}${ONLINE_ACTIVITY_KEY_SEPARATOR}${startedAt}`
}

export const getDateFormatByInterval = (interval: StatisticsInterval): string => {
	return interval === StatisticsInterval.Week ? 'gggg_MM_DD_w' : 'YYYY_MM_DD_w'
}

export const transformOnlineActivityBucketSession = (session: MetricsBucketSession): OnlineActivityBucketSession => {
	const duration = session.duration > 0 ? session.duration / HOUR_IN_SEC : 0
	const key = getFormattedKey(session)
	return { [key]: duration }
}

export const transformOnlineActivityBucket = (bucket: MetricsBucket): NewActivityValuesType => {
	let bucketSessions = {}
	bucket.sessions?.map(transformOnlineActivityBucketSession).forEach((item) => {
		bucketSessions = { ...bucketSessions, ...item }
	})

	const formattedDate = moment(bucket.key, 'YYYY_MM_DD_w').format('dddd, LL')
	return { date: formattedDate, ...bucketSessions }
}

export const getChartTypeByMetricsType = (metricType: MetricsType): Exclude<ChartType, ChartType.Tags> => {
	switch (metricType) {
		case MetricsType.NewConversation: {
			return ChartType.NewConversation
		}
		case MetricsType.ClosedConversation: {
			return ChartType.ClosedConversation
		}
		case MetricsType.ServedConversation: {
			return ChartType.ServedConversation
		}
		case MetricsType.ConversationRatings: {
			return ChartType.Rating
		}
		case MetricsType.ContactAcquired: {
			return ChartType.NewContacts
		}
		case MetricsType.ConversationRatingsPercent: {
			return ChartType.RatingPercentage
		}
		case MetricsType.AvgResponseTime:
		case MetricsType.AvgFirstResponseTime: {
			return ChartType.ResponseTime
		}
		case MetricsType.Activity:
		case MetricsType.OnlineTime: {
			return ChartType.OnlineActivity
		}
		default: {
			return ChartType.NewConversation
		}
	}
}

export const formatBucket =
	(buckets: MetricsBucket[], interval: StatisticsInterval) =>
	(date: Moment.Moment): SummaryChartData => {
		const formattedNames = getFormattedNames(interval, date)
		const bucketData = buckets && buckets.find(bucketByDate(date, interval))
		return {
			date: date.toDate(),
			shortName: formattedNames.short,
			longName: formattedNames.long,
			key: formattedNames.long,
			count: 0,
			value: 0,
			...bucketData,
		}
	}

const bucketByDate = (date: Moment.Moment, interval: StatisticsInterval) => (bucket: MetricsBucket) => {
	const parts = bucket.key.split('_')
	let parsedDate = moment()

	switch (interval) {
		case StatisticsInterval.Day: {
			const daysCount = Number.parseInt(parts[2], 10)
			parsedDate = moment(`${parts[0]}-${parts[1]}-01`).add(daysCount - 1, interval)
			break
		}
		case StatisticsInterval.Month: {
			const monthsCount = Number.parseInt(parts[1], 10)
			parsedDate = moment(`${parts[0]}-01-01`).add(monthsCount - 1, interval)
			break
		}
		case StatisticsInterval.Week: {
			const weeksCount = Number.parseInt(parts[3], 10)
			parsedDate = moment(`${parts[0]}-01-01`).add(weeksCount - 1, interval)
			break
		}
		default:
	}

	return date.isSame(parsedDate, interval)
}

export const getFormattedNames = (interval: StatisticsInterval, date: Moment.Moment) => {
	const localDate = moment(date.toISOString())
	if (interval === StatisticsInterval.Month) {
		return {
			short: localDate.format('MMMM'),
			long: localDate.format('MMMM Y'),
		}
	}
	if (interval === StatisticsInterval.Week) {
		return {
			short: localDate.format('wo (Y)'),
			long: localDate.format('wo (Y)'),
		}
	}
	return {
		short: localDate.format('D'),
		long: localDate.format('dddd, LL'),
	}
}

export const getBucketData = (buckets: MetricsBucket[], interval: StatisticsInterval, filter: StatisticsFilter) => {
	const { from, to } = filter
	if (!from || !to) return []
	const filteredRange = momentExt.range(from, to).snapTo(interval)
	const rangeDates = [...filteredRange.by(interval)]
	return rangeDates.map(formatBucket(buckets, interval))
}

export const getTooltipTitleValue = (value: string): string => {
	return value.split(ONLINE_ACTIVITY_KEY_SEPARATOR)[0]
}
