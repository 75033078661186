import { ReactNode } from 'react'

import { AGENTS_PANEL_HEIGHT } from 'shared/styles/constants'

import { Flex } from 'core'

type Props = {
	children?: ReactNode
	agentPanel: boolean
}

const Tabs = ({ children, agentPanel }: Props) => {
	return (
		<Flex flexDirection="column" height="100%" overflow="hidden" pb={agentPanel ? `${AGENTS_PANEL_HEIGHT}px` : 0}>
			{children}
		</Flex>
	)
}

export default Tabs
