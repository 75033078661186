import { batch } from 'react-redux'
import { createAsyncThunk, createSelector, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit'

import { ChannelType, ChatChannel, FeatureUsageName } from 'models'
import { ApiError } from 'shared/types'
import { AppThunkAction, DashboardState, Dictionary } from 'types'
import { AgentEvents } from 'shared/models/Agent'
import { Message, MessageContent, MessageSubType, MessageType } from 'shared/models/Message'
import { TranslationService as T } from 'shared/services'
import { flashMessage, notification } from 'shared/utils'
import { getChatId } from 'selectors/commonSelectors'
import { appSelectors } from 'modules/app'
import { chatDetailSelectors, clearAttachmentsInChat, setDraftMessage } from 'modules/chatDetail'
import { chatsActions, chatsSelectors } from 'modules/chats'
import { chatVisitorTyping, setUnreadChatsCount } from 'modules/chatsSlice'
import { logFeatureUsage } from 'modules/features'
import { userSelectors } from 'modules/user'

import { messagesApi } from './api'
import { MESSAGE_MAX_LENGTH } from './constants'
import { ChatMessagesItemsState, MessageData, SendMessageRequestData, UploadFinishRequestData } from './types'

export type MessagesState = typeof initialState
export type MessagesRootState = Pick<DashboardState, 'messages'>

export const fetchMessages = createAsyncThunk('MESSAGES/FETCH', async (chatId: string) => {
	return messagesApi.fetchMessages(chatId)
})

export const sendMessageThunk = createAsyncThunk('MESSAGES/SEND', async (data: SendMessageRequestData) => {
	return messagesApi.sendMessage(data)
})

const uploadFinishThunk = createAsyncThunk('MESSAGES/UPLOAD-FINISH', async (data: UploadFinishRequestData) => {
	return messagesApi.uploadFinish(data)
})

export const sendMessage =
	(chatId: string, text: string, channel: ChatChannel): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const getAreAttachmentsUploaded = chatDetailSelectors.makeAreAttachmentsUploaded(chatId)
		const getAttachmentTokens = chatDetailSelectors.makeGetAttachmentTokensByChatId(chatId)
		const areAttachmentsUploaded = getAreAttachmentsUploaded(state)
		const tokensArray = getAttachmentTokens(state)

		// all attachments have to be uploaded before sending message
		if (!areAttachmentsUploaded) {
			notification.error({ title: T.translate('chat.fileUpload.error.stillUploading') })
			return
		}

		if (text.length > MESSAGE_MAX_LENGTH) {
			flashMessage.error('chat.message.error.maxLength')
			return
		}
		// empty string is not allowed for request, it requires NULL - when agent wants to send only attachments
		let contentText: string | null = text
		if (text === '') contentText = null

		const messageData: MessageData = {
			type: MessageType.Message,
			content: {
				type: MessageContent.Message.Type.Text,
				text: contentText,
			},
			channel,
			attachments: tokensArray,
		}

		batch(async () => {
			try {
				const result = await dispatch(sendMessageThunk({ chatId, messageData }))
				unwrapResult(result)
				// clear draft text
				dispatch(setDraftMessage({ chatId, text: '' }))
			} catch (error) {
				const errorResponse: ApiError = error as ApiError
				if (errorResponse.message) flashMessage.error(errorResponse.message, { isRawData: true })
				else flashMessage.error('general.error')
			}
		})

		// clear draft attachments
		dispatch(clearAttachmentsInChat({ chatId }))

		if (channel.type === ChannelType.Email) {
			dispatch(logFeatureUsage(FeatureUsageName.SendEmailMessage))
		}
	}

export const onMessageReceived =
	({ chatId, message, unreadCount, isMuted }: AgentEvents.ChatMessageReceived): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const userId = userSelectors.getUserId(state)
		const selectedChatId = chatsSelectors.getChatDetailId(state)
		const isChatDetailVisible = chatDetailSelectors.getIsChatDetailVisible(state)
		const isAppFocused = appSelectors.isAppFocused(state)
		if (!userId) return

		dispatch(setUnreadChatsCount(unreadCount))

		const getChatById = chatsSelectors.makeGetChatById()
		const chat = getChatById(state, { chatId })
		const isNewChat = !chat
		if (isNewChat) {
			const newChat = await dispatch(chatsActions.fetchChat(message.chatId, false))
			if (!newChat) return
		}

		// pass isMuted property for notifications middleware
		// messageReceived has to be dispatch after fetching chat (due to notification middleware)
		dispatch(messageReceived({ chatId, userId, message, isMuted }))

		if (message.type !== MessageType.Help) {
			dispatch(chatsActions.chatUpdated({ chatId, values: { lastMessage: message } }))
		}

		// Don't update unread count if chat is new (already has current unread info)
		const isMessageFromSelectedChat = chatId === selectedChatId
		const canUpdateUnreadCount = !isNewChat && (!isMessageFromSelectedChat || !isChatDetailVisible)
		if (message.subType === MessageSubType.Contact && canUpdateUnreadCount) {
			dispatch(chatsActions.chatIncrementUnreadCount({ chatId }))
		}

		if (isMessageFromSelectedChat && isChatDetailVisible && isAppFocused) {
			dispatch(chatsActions.readChat(chatId))
		}

		if (message.subType === MessageSubType.Contact) {
			dispatch(chatVisitorTyping({ chatId, isTyping: false }))
		}
	}

export const onMessageUpdated =
	({
		chatId,
		message,
	}:
		| AgentEvents.ChatMessageDelivered
		| AgentEvents.ChatMessageDeliveryFailed
		| AgentEvents.ChatMessageSeen): AppThunkAction =>
	(dispatch, getState) => {
		dispatch(messageUpdated({ chatId, message }))

		const state = getState()
		const getChatById = chatsSelectors.makeGetChatById()
		const chat = getChatById(state, { chatId })

		if (!chat || !chat.lastMessage) return

		const lastMessageId = chat.lastMessage?.id
		if (lastMessageId === message.id) {
			dispatch(chatsActions.chatUpdated({ chatId, values: { lastMessage: message } }))
		}
	}

export const onMessageDeleted =
	({ chatId, messageId }: AgentEvents.ChatMessageDeleted): AppThunkAction =>
	(dispatch) => {
		//*
		// INFO: Support for removing messages was implemented for Helbot (Chat Resolve) reasons only.
		// If there will be needed to use for different type of messages (Contact, Agent, ...), it's necessary work with chat.lastMessage, unread messages counter etc !
		// */
		dispatch(messageDeleted({ chatId, messageId }))
	}

export const initialState = {
	chatsMessages: {} as Dictionary<ChatMessagesItemsState>,
	isSendingMessage: false,
}

const messagesSlice = createSlice({
	name: 'messages',
	initialState,
	reducers: {
		messageReceived: (
			state,
			{ payload }: PayloadAction<{ chatId: string; userId?: string; message: Message; isMuted?: boolean }>,
		) => {
			const { chatId, message } = payload

			if (!chatId) return

			if (!state.chatsMessages[chatId]) {
				state.chatsMessages[chatId] = { items: [], isFetching: false }
			}

			// Find message index to prevent adding duplicate
			const messageIndex = state.chatsMessages[chatId].items.findIndex((m: Message) => m.id === message.id)

			if (messageIndex < 0) {
				// Message not found in the store => add it to messages
				state.chatsMessages[chatId].items.push(message)
			} else if (
				state.chatsMessages[chatId].items[messageIndex] &&
				message.content.type === MessageContent.Message.Type.RateForm
			) {
				state.chatsMessages[chatId].items[messageIndex] = message
			}
		},
		messageUpdated: (state, { payload }) => {
			const { chatId, message } = payload

			if (!chatId) return

			if (!state.chatsMessages[chatId]) return

			const messageIndex = state.chatsMessages[chatId].items.findIndex((m: Message) => m.id === message.id)

			if (messageIndex < 0) return
			const updatedMessage = { ...state.chatsMessages[chatId].items[messageIndex] }
			updatedMessage.deliveredAt = message.deliveredAt
			updatedMessage.deliveryTo = message.deliveryTo
			updatedMessage.deliveryStatus = message.deliveryStatus
			updatedMessage.deliveryFailReason = message.deliveryFailReason
			state.chatsMessages[chatId].items[messageIndex] = { ...updatedMessage }
		},
		messageDeleted: (state, { payload }: PayloadAction<{ chatId: string; messageId: string }>) => {
			const { chatId, messageId } = payload

			if (!chatId || !messageId) return

			if (!state.chatsMessages[chatId]) return

			const messages = { ...state.chatsMessages[chatId] }.items
			const updatedMessages = messages.filter((message) => message.id !== messageId)
			state.chatsMessages[chatId].items = [...updatedMessages]
		},
		removeHelpbotResolveMessages: (state, { payload }: PayloadAction<{ chatId: string }>) => {
			const { chatId } = payload

			if (!chatId || !state.chatsMessages[chatId]) return

			const messages = { ...state.chatsMessages[chatId] }.items
			const filteredMessages = messages.filter((message) => message.subType !== MessageSubType.ChatResolve)
			state.chatsMessages[chatId].items = [...filteredMessages]
		},
		setMessages: (state, { payload }: PayloadAction<{ messages: Message[]; chatId: string }>) => {
			const { messages, chatId } = payload
			if (chatId) {
				state.chatsMessages[chatId] = { items: messages, isFetching: false }
			}
		},
	},
	extraReducers: (builder) => {
		// FETCH MESSAGES
		builder.addCase(fetchMessages.fulfilled, (state, { payload, meta }) => {
			const { items } = payload
			const chatId = meta.arg
			if (chatId) {
				state.chatsMessages[chatId] = { items, isFetching: false }
			}
		})
		builder.addCase(fetchMessages.rejected, (state, { meta }) => {
			const chatId = meta.arg
			if (chatId) {
				state.chatsMessages[chatId] = { items: [], isFetching: false }
			}
		})
		builder.addCase(fetchMessages.pending, (state, { meta }) => {
			const chatId = meta.arg
			if (chatId) {
				state.chatsMessages[chatId] = { items: [], isFetching: true }
			}
		})
		// SEND MESSAGE
		builder.addCase(sendMessageThunk.fulfilled, (state, { payload, meta }) => {
			state.isSendingMessage = false
			const {
				arg: { chatId },
			} = meta
			const dashboardMessage = payload

			// Find message index to prevent adding duplicate
			const duplicateIndex = state.chatsMessages[chatId].items.findIndex((m) => m.id === dashboardMessage.id)
			if (duplicateIndex >= 0) return

			// Add sent message to messages
			const chatMessages = state.chatsMessages[chatId].items
			if (!chatMessages) state.chatsMessages[chatId].items = []
			state.chatsMessages[chatId].items.push(dashboardMessage)
		})
		builder.addCase(sendMessageThunk.pending, (state) => {
			state.isSendingMessage = true
		})
		builder.addCase(sendMessageThunk.rejected, (state) => {
			state.isSendingMessage = false
		})
		// UPLOAD FINISH
		builder.addCase(uploadFinishThunk.fulfilled, (state, { payload, meta }) => {
			state.isSendingMessage = false
			const {
				arg: { chatId },
			} = meta
			const dashboardMessage = payload

			// Find message index to prevent adding duplicate
			const duplicateIndex = state.chatsMessages[chatId].items.findIndex((m) => m.id === dashboardMessage.id)
			if (duplicateIndex >= 0) return

			// Add sent message to messages
			const chatMessages = state.chatsMessages[chatId].items
			if (!chatMessages) state.chatsMessages[chatId].items = []
			state.chatsMessages[chatId].items.push(dashboardMessage)
		})
		builder.addCase(uploadFinishThunk.pending, (state) => {
			state.isSendingMessage = true
		})
		builder.addCase(uploadFinishThunk.rejected, (state) => {
			state.isSendingMessage = false
			notification.error({ title: T.translate('chat.fileUpload.error.server') })
		})
	},
})

const getChatsMessages = (state: MessagesRootState) => state.messages.chatsMessages
export const isSendingMessage = (state: MessagesRootState) => state.messages.isSendingMessage
export const makeGetMessagesByChatId = () =>
	createSelector([getChatsMessages, getChatId], (messages, chatId) => {
		if (!chatId) return []

		const chatMessages = messages[chatId]?.items
		if (!chatMessages) return []

		const sortByMessageTime = (m1: Message, m2: Message) => {
			const date1 = new Date(m1.createdAt).getTime()
			const date2 = new Date(m2.createdAt).getTime()
			return date1 - date2
		}

		// Return new sorted array
		return [...chatMessages].sort(sortByMessageTime)
	})

export const makeGetIsFetchingMessagesByChatId = (chatId: string) =>
	createSelector([getChatsMessages], (messages) => {
		if (!chatId) return []

		return !!messages[chatId]?.isFetching
	})

const { reducer, actions } = messagesSlice
export const { messageReceived, messageUpdated, removeHelpbotResolveMessages, messageDeleted, setMessages } = actions
export default reducer

export const messagesSelectors = {
	makeGetMessagesByChatId,
	makeGetIsFetchingMessagesByChatId,
	isSendingMessage,
}
