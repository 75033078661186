import { ReactNode } from 'react'

export enum SystemModalType {
	Confirm = 'CONFIRM_MODAL',
	Delete = 'DELETE_MODAL',
	Disconnected = 'DISCONNECTED_MODAL',
}

export interface SystemModal {
	name: SystemModalType
	data?: SystemModalConfirmData
}

export interface SystemModalConfirmData {
	title: string
	text: string
	extra?: ReactNode
	buttonPrimaryText?: string
	buttonSecondaryText?: string
	onConfirm: () => void
	onCancel?: () => void
	shouldDisplayCloseBtn?: boolean
}
