import { UpsellType } from './constants'

export enum UpsellAction {
	Upgrade = 'upgrade',
	Contact = 'contact',
}

export type UpsellContext =
	| UpsellContextTags
	| UpsellContextChatbotConversations
	| UpsellContextAiConversations
	| UpsellContextGroups
	| UpsellContextChatbot
	| UpsellContextChatbotActions
	| UpsellContextLivechatConversations
	| UpsellContextAiSources

export enum UpsellContextTags {
	Settings = 'settings',
	Conversation = 'conversation',
}

export enum UpsellContextChatbotActions {
	ChatbotBuilder = 'chatbot-builder',
	AutoMessageBuilder = 'auto-message-builder',
}

export enum UpsellContextChatbot {
	List = 'chatbot-list',
	Builder = 'chatbot-builder',
}

export enum UpsellContextGroups {
	GroupsList = 'groups',
	WidgetAuthFormGroupSelect = 'widgetAuthFormGroupSelect',
	GroupChatbotCondition = 'groupChatbotCondition',
}

export enum UpsellContextAiConversations {
	SmartHub = 'smarthub',
	AiList = 'ai-chatbot-list',
	Notification = 'notification',
}

export enum UpsellContextAiSources {
	SourceModal = 'source-modal',
	OnboardingModal = 'onboarding-modal',
}

export enum UpsellContextChatbotConversations {
	SmartHub = 'smarthub',
	Automation = 'chatbot-list',
	Notification = 'notification',
	LimitAlert = 'chatbot-list-alert',
}

export enum UpsellContextLivechatConversations {
	SmartHub = 'smarthub',
	Notification = 'notification',
	ConversationDetail = 'conversation-detail',
}

export type UpsellInfoPanelType =
	| typeof UpsellType.CustomerInfo
	| typeof UpsellType.ContactTagsPanel
	| typeof UpsellType.ContactPropertiesPanel

export type UpsellPopoverType =
	| typeof UpsellType.AccountReview
	| typeof UpsellType.AccountReviewUnlimited
	| typeof UpsellType.Chatbots
	| typeof UpsellType.ChatbotsExtra
	| typeof UpsellType.ChatbotConversations
	| typeof UpsellType.AutoMessages
	| typeof UpsellType.AutoMessagesExtra
	| typeof UpsellType.Groups
	| typeof UpsellType.ExportContacts
	| typeof UpsellType.LivechatConversations
	| typeof UpsellType.AiConversations
	| typeof UpsellType.AiBonusConversationsLimit

export enum UpsellPopoverButtonType {
	UpgradePackage,
	UpgradeLimit,
	UnlimitedAccess,
}

export type AllowedUpsellModalType = Exclude<
	UpsellType,
	| UpsellType.AccountReview
	| UpsellType.AccountReviewUnlimited
	| UpsellType.AiReplyAssistToBasic
	| UpsellType.AiReplyAssistToPro
	| UpsellType.AgentsLimitedAccess
	| UpsellType.BillingProfileFree
	| UpsellType.BillingProfileTrial
	| UpsellType.ExportContacts
	| UpsellType.FacebookConnectedInTrial
	| UpsellType.Facebook
	| UpsellType.FacebookTextarea
	| UpsellType.LivechatConversations
	| UpsellType.Statistics
	| UpsellType.StatisticsBasic
	| UpsellType.Tags
	| UpsellType.ChatbotConversations
	| UpsellType.ContactTagsPanel
	| UpsellType.ContactPropertiesPanel
>
