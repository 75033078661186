import { Circle, SquareProps } from '@chakra-ui/react'

type Props = {
	colorScheme?: string
	size?: string
} & SquareProps

export const BadgeDot = ({ colorScheme = 'blue', size = '10px', ...rest }: Props) => {
	return <Circle size={size} bg={`${colorScheme}.500`} {...rest} />
}
