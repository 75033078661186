import {
	ActionApp,
	ActionBotTransfer,
	ActionChatAddTags,
	ActionContactAddTags,
	ActionContactRequireApproval,
	ActionContactRetrieveProperty,
	ActionHandoverV2,
	ActionMessage,
	ActionType,
	Bot,
	BotsApi,
	BotSimple,
	GroupType as ChatbotGroupType,
	InstalledActionApp,
	Interaction,
	Reply as ChatbotReply,
	Template,
	TemplateSimple,
	Trigger,
	TriggerCondition,
} from '@smartsupp/chatbot-client-api/dist/clients/v1/src'

export {
	BotIdentity,
	Gender as BotIdentityGender,
	BotIdentityUpdate,
	BotsApi,
	BotSimple,
	ActionAppData as ChatbotActionAppData,
	ActionBotTransferData as ChatbotActionBotTransferData,
	ContactProperty as ChatbotActionContactPropertyName,
	ActionContactRequireApprovalData as ChatbotActionContactRequireApprovalData,
	ActionContactRetrieveProperty as ChatbotActionContactRetrieveProperty,
	ActionContactRetrievePropertyData as ChatbotActionContactRetrievePropertyData,
	ActionHandoverV2Data as ChatbotActionHandoverV2Data,
	ActionMessage as ChatbotActionMessage,
	ActionMessageData as ChatbotActionMessageData,
	ActionType as ChatbotActionType,
	BotBehavior as ChatbotAiBehavior,
	BotBehaviorConfidence as ChatbotAiConfidenceValue,
	BotBehaviorEmoji as ChatbotAiEmojiValue,
	SkillHandoverType as ChatbotAiSkillHandoverType,
	SkillProductRecommendationType as ChatbotAiSkillProductRecommendationType,
	Skills as ChatbotAiSkills,
	BotBehaviorTalkativeness as ChatbotAiTalkativenessValue,
	BotBehaviorTone as ChatbotAiToneValue,
	ErrorCode as ChatbotErrorCode,
	GroupType as ChatbotGroupType,
	Reply as ChatbotReply,
	ReplyType as ChatbotReplyType,
	TemplateSuggestion as ChatbotTemplateSuggestion,
	TriggerCondition as ChatbotTriggerCondition,
	ConditionName as ChatbotTriggerConditionName,
	ConditionOp as ChatbotTriggerConditionOp,
	ConditionOpGroup as ChatbotTriggerConditionOpGroup,
	ConditionOpNumber as ChatbotTriggerConditionOpNumber,
	ConditionOpString as ChatbotTriggerConditionOpString,
	ConditionSection as ChatbotTriggerConditionSection,
	TriggerType as ChatbotTriggerType,
	Workflow as ChatbotWorkflow,
	HandoverType,
} from '@smartsupp/chatbot-client-api/dist/clients/v1/src'

export enum ChatbotCategory {
	// Chatbots
	Faq = 'faq',
	Chatbot = 'chatbot',
	ChatbotBusy = 'chatbot_busy',
	ChatbotOffline = 'chatbot_offline',
	ChatbotChristmas = 'chatbot_xmas_guide',
	ChatbotVisitOne = 'chatbot_visit1',
	ChatbotVisitTwo = 'chatbot_returning',
	ChatbotNewProduct = 'chatbot_new_product_announcement',
	ChatbotFirstOrderDiscount = 'chatbot_first_order_discount',
	ChatbotAutoMessage = 'chatbot_automatic_message',
	ChatbotEshop = 'chatbot_eshop_guide',
	ChatbotOnboarding = 'chatbot_onboarding',
	ChatbotCart = 'chatbot_cart_microbot',
	ChatbotDiscountReturning = 'chatbot_discount_returning_visitors',
	ChatbotIncreaseEngagement = 'chatbot_increase_engagement',
	ChatbotLeadCollection = 'chatbot_lead_collection',
	ChatbotNewsletterSubscribe = 'chatbot_newsletter_subscribe',
	ChatbotEmailCollection = 'chatbot_email_collection',
	ChatbotPricelistHelp = 'chatbot_pricelist_help',
	AiChatbotCustom = 'ai_custom',
	ChatbotNewClients = 'chatbot_secure_new_clients',
	ChatbotMoreMeetings = 'chatbot_arrange_more_meetings',

	// Triggers
	// TODO: Stays because of chatbot garage (soon to be moved) table item icons -> remove after migration
	Custom = 'custom',
	VisitOne = 'visit1',
	VisitTwo = 'visit2',
	Offline = 'offline',
	NextPurchase = 'next_purchase',
	FinishOrder = 'finish_order_help',
	SelectGoods = 'select_goods_help',
	SpecialOffer = 'special_offer',
}

export const { ListQueryGroupType } = BotsApi

export type ChatbotType =
	| ChatbotCategory.Faq
	| ChatbotCategory.VisitOne
	| ChatbotCategory.VisitTwo
	| ChatbotCategory.Custom

export type ChatbotTemplateType =
	| ChatbotCategory.Faq
	| ChatbotCategory.Chatbot
	| ChatbotCategory.ChatbotChristmas
	| ChatbotCategory.ChatbotBusy
	| ChatbotCategory.ChatbotOffline
	| ChatbotCategory.ChatbotVisitOne
	| ChatbotCategory.ChatbotVisitTwo
	| ChatbotCategory.ChatbotNewProduct
	| ChatbotCategory.ChatbotFirstOrderDiscount
	| ChatbotCategory.ChatbotAutoMessage
	| ChatbotCategory.VisitOne
	| ChatbotCategory.VisitTwo
	| ChatbotCategory.Custom
	| ChatbotCategory.FinishOrder
	| ChatbotCategory.NextPurchase
	| ChatbotCategory.SelectGoods
	| ChatbotCategory.SpecialOffer
	| ChatbotCategory.ChatbotEshop
	| ChatbotCategory.ChatbotOnboarding
	| ChatbotCategory.ChatbotCart
	| ChatbotCategory.ChatbotDiscountReturning
	| ChatbotCategory.ChatbotIncreaseEngagement
	| ChatbotCategory.ChatbotLeadCollection
	| ChatbotCategory.ChatbotNewsletterSubscribe
	| ChatbotCategory.ChatbotEmailCollection
	| ChatbotCategory.ChatbotPricelistHelp
	| ChatbotCategory.AiChatbotCustom
	| ChatbotCategory.ChatbotNewClients
	| ChatbotCategory.ChatbotMoreMeetings

export type ChatbotPromotedTemplateType =
	| ChatbotCategory.ChatbotCart
	| ChatbotCategory.ChatbotDiscountReturning
	| ChatbotCategory.ChatbotIncreaseEngagement

export type LeadgenPromotedTemplateType =
	| ChatbotCategory.ChatbotLeadCollection
	| ChatbotCategory.ChatbotNewsletterSubscribe
	| ChatbotCategory.ChatbotEmailCollection
	| ChatbotCategory.ChatbotPricelistHelp

export type OtherPromotedTemplateType =
	| ChatbotCategory.Faq
	| ChatbotCategory.ChatbotEshop
	| ChatbotCategory.ChatbotChristmas
	| ChatbotCategory.ChatbotNewClients
	| ChatbotCategory.ChatbotMoreMeetings

export type AutoMessagesPromotedTemplateType =
	| ChatbotCategory.ChatbotVisitOne
	| ChatbotCategory.ChatbotVisitTwo
	| ChatbotCategory.ChatbotBusy
	| ChatbotCategory.ChatbotOffline
	| ChatbotCategory.ChatbotNewProduct
	| ChatbotCategory.ChatbotFirstOrderDiscount

export type TemplateStorePromotedTemplateType =
	| ChatbotPromotedTemplateType
	| LeadgenPromotedTemplateType
	| OtherPromotedTemplateType
	| AutoMessagesPromotedTemplateType

export type ChatbotAction =
	| ActionMessage
	| ActionHandoverV2
	| ActionApp
	| ActionChatAddTags
	| ActionContactRetrieveProperty
	| ActionContactAddTags
	| ActionContactRequireApproval
	| ActionBotTransfer

export type ChatbotActionData =
	| ActionMessage['data']
	| ActionHandoverV2['data']
	| ActionApp['data']
	| ActionChatAddTags['data']
	| ActionContactRetrieveProperty['data']
	| ActionContactRequireApproval['data']
	| ActionContactAddTags['data']
	| ActionBotTransfer['data']

export type ChatbotActionDataWithMessage = ActionMessage['data'] | ActionApp['data']

export interface ChatbotInteraction extends Interaction {
	actions: ChatbotAction[]
}

export interface ChatbotTrigger extends Trigger {
	conditions: TriggerCondition[]
}

export interface Chatbot extends Bot {
	type: ChatbotType
	templateType: ChatbotTemplateType | null
	groupType: ChatbotGroupType | null
	interactions: ChatbotInteraction[]
	trigger: ChatbotTrigger
	constraints?: BotsApi.CreateBodyConstraints
	identityId: string | null
}

export interface ChatbotSimple extends BotSimple {
	type: ChatbotType
	templateType: ChatbotTemplateType | null
	groupType: ChatbotGroupType | null
}

export interface SelectedChatbotSimple extends ChatbotSimple {
	isTypeFaq: boolean
	isGroupTypeChatbot: boolean
	isGroupTypeMessage: boolean
	isGroupTypeAi: boolean
}

export interface SelectedChatbotSimpleWithStatistics extends SelectedChatbotSimple {
	ratio: number
	conversations: number
	triggered: number
}

export interface SelectedChatbotReply extends ChatbotReply {
	isTemporary: boolean
	nodeType?: AllowedActionTypes
	appId?: string
	actionId?: string
	actions?: ChatbotAction[]
}

export type ChatbotNewReplyData = { type: AllowedActionTypes; text?: string } & Pick<
	SelectedChatbotReply,
	'appId' | 'actionId' | 'actions'
>

export interface ChatbotTemplateSimple extends TemplateSimple {
	type: ChatbotTemplateType
	groupType: ChatbotGroupType | null
}

export interface ChatbotTemplate extends Template {
	type: ChatbotTemplateType
	groupType: ChatbotGroupType | null
	interactions: ChatbotInteraction[]
}

export type ChatbotCreateRequest = BotsApi.CreateBody
export type ChatbotUpdateRequest = BotsApi.UpdateBody

export interface ChatbotUpdateParams {
	botId: Chatbot['id']
	changes: ChatbotUpdateRequest
}

export type AllowedActionTypes =
	| typeof ActionType.App
	| typeof ActionType.HandoverV2
	| typeof ActionType.Message
	| typeof ActionType.ContactRetrieveProperty
	| typeof ActionType.BotTransfer

export type AllowedActions =
	| ActionMessage
	| ActionHandoverV2
	| ActionApp
	| ActionContactRetrieveProperty
	| ActionBotTransfer

export enum AdditionalNodeTypes {
	TriggerNode = 'trigger',
}

export type AllowedNodeTypes = AllowedActionTypes | AdditionalNodeTypes.TriggerNode

export interface Icon {
	url: string
	bg: string | undefined
}

export interface ChatbotInstalledActionApp extends InstalledActionApp {
	title: string
	icon: Icon | null
	quickReplyText: string | null
}

export enum ChatbotTemplatesModalsTypes {
	Sales = 'sales',
	Leadgen = 'leadgen',
	All = 'all',
}

export enum ChatbotActionProperties {
	Name = 'name',
	Email = 'email',
	Phone = 'phone',
	Question = 'question',
}

export enum TemplatesTags {
	Leadgen = 'leadgen',
	Sales = 'sales',
	Marketing = 'marketing',
	CustomerCare = 'customerCare',
	Chatbot = 'chatbot',
	AutoMessage = 'autoMessage',
}

export type ChatbotTemplateSimpleExtended = ChatbotTemplateSimple & { tags: TemplatesTags[]; ribbon: boolean }

export enum ListContext {
	Ai,
	AutoMessages,
	Chatbots,
}
