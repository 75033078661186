export {
	ConfigApi as NotificationsApi,
	NotificationsConfig,
	NotificationsConfigPlatformsBrowser,
	NotificationsConfigPlatformsBrowserEvents,
	NotificationsConfigPlatformsEmail,
	NotificationsEventBrowser,
} from '@smartsupp/notifications-client-api'

export type PlatformType = 'browser' | 'email'

export enum NotificationBrowserEventType {
	UnservedChat = 'incoming_chat',
	NewMessage = 'incoming_message',
	NewVisitor = 'incoming_visitor',
}
