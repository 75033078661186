import { RefObject, useEffect } from 'react'

type OutsideClickEvent = MouseEvent | TouchEvent

export const useOnClickOutside = (ref: RefObject<Element>, handler: (e: OutsideClickEvent) => void) => {
	useEffect(() => {
		const listener = (event: OutsideClickEvent) => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref.current || ref.current.contains(event.target as Node)) {
				return
			}
			handler(event)
		}

		document.addEventListener('mousedown', listener)
		document.addEventListener('touchstart', listener)

		return () => {
			document.removeEventListener('mousedown', listener)
			document.removeEventListener('touchstart', listener)
		}
	}, [ref, handler])
}
