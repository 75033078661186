import { DataLayerData } from 'shared/types'
import { apiClient } from 'shared/utils'

import { EventsConfirmData, ExternalEvents } from './types'

const API_RESOURCE = 'external-events'
const API_GTM_RESOURCE = 'gtm'

const fetchAccountEventQueue = () => {
	return apiClient.get<ExternalEvents>(`${API_RESOURCE}`)
}

const confirmAccountEventQueue = (data: EventsConfirmData) => {
	return apiClient.post(`${API_RESOURCE}/confirm`, data)
}

const fetchGTMDataLayerContent = () => {
	return apiClient.get<DataLayerData>(`${API_GTM_RESOURCE}/data-layer/content`)
}

export const dataLayerApi = { fetchAccountEventQueue, confirmAccountEventQueue, fetchGTMDataLayerContent }
