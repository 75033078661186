import { ChatbotTemplateType } from 'models'
import { isNumber } from 'shared/utils'
import MixpanelService from 'services/MixpanelService'
import { AiOnboardingStep } from 'modules/aiChatbots/aiOnboarding'
import { ChatbotPublishSourceType } from 'modules/chatbotDetail'
import { ChatbotWorkflowSection } from 'modules/chatbotWorkflow'
import { AccordionLocation, PanelName } from 'modules/installMethods'
import { UpsellContext, UpsellEventAction, UpsellSubType, UpsellType } from 'modules/upsell'
import { AiPreviewSource } from 'modules/widgetPreview'

type UpsellProperties = {
	context?: UpsellContext
	subType?: UpsellSubType
	usedPercentage?: number
}

// Install code
export const trackInstallButton = () => {
	MixpanelService.track('dash.welcome.install_btn.click')
}

export const trackInstallCodePlugin = (name: PanelName, location: AccordionLocation) => {
	MixpanelService.track(`install_code.click.${name}.${location}`)
}

export const trackSmarthubDemoBannerButtonClick = () => {
	MixpanelService.track('smarthub.demoBanner.button.click')
}

export const trackSmarthubAiBannerButtonClick = () => {
	MixpanelService.track('smarthub.aiBanner.button.click')
}

// Upsell
export const trackUpsellEvent = (type: UpsellType, action: UpsellEventAction, properties?: UpsellProperties) => {
	const data = properties && {
		...(properties.context && { context: properties.context }),
		...(properties.subType && { 'sub-type': properties.subType }),
		...(isNumber(properties.usedPercentage) && { 'used-percentage': `${Math.floor(properties.usedPercentage * 100)}` }),
	}
	MixpanelService.track(`upsell.${action}`, { type, ...data })
}

export const trackAccountReviewClick = () => {
	MixpanelService.track('accountReview.click')
}

export const trackAccountDeleteClick = (action: 'click' | 'confirm') => {
	MixpanelService.track(`account.delete.${action}`)
}

export const trackTurnOffAutoRenewalClick = (step: 'modal' | 'survey' | 'confirm') => {
	MixpanelService.track(`billing.autoRenewal.turnOff.${step}.click`)
}

// Chatbot events
export const trackChatbotTemplateModalCtaClick = (properties: Record<'type', ChatbotTemplateType>) => {
	MixpanelService.track('chatbot.templateModal.cta.click', properties)
}
export const trackChatbotBuilderOpen = (properties: Record<'type', 'autoMessage' | 'chatbot'>) => {
	MixpanelService.track('chatbot.builder.open', properties)
}
export const trackChatbotBuilderPublish = (properties: Record<'source', ChatbotPublishSourceType>) => {
	MixpanelService.track('chatbot.builder.publish', properties)
}
export const trackChatbotBuilderCopyNode = () => {
	MixpanelService.track('chatbot.builder.copyNode')
}

// Billing events
export const trackPayButtonClick = () => {
	MixpanelService.track('billing.payment.pay_btn.click')
}
export const trackThreeDSecureModalOpen = () => {
	MixpanelService.track('billing.three_d_secure_modal.open')
}
export const trackPaymentSuccess = (properties?: Record<string, string>) => {
	MixpanelService.track('billing.payment.success', properties)
}
export const trackPaymentError = (properties?: Record<string, string>) => {
	MixpanelService.track('billing.payment.error', properties)
}
export const trackDraftInvoiceAlertOpen = () => {
	MixpanelService.track('billing.draft_invoice_alert.open')
}

// New subscription funnel
export const trackSubscriptionCreatePackagesPageOpen = () => {
	MixpanelService.track('billing.subscription_activation.packages.open')
}
export const trackSubscriptionCreatePackageConfigurationOpen = () => {
	MixpanelService.track('billing.subscription_activation.configuration.open')
}
export const trackSubscriptionCreatePackageBillingInfoOpen = () => {
	MixpanelService.track('billing.subscription_activation.billingInfo.open')
}
export const trackSubscriptionCreatePaymentOpen = () => {
	MixpanelService.track('billing.subscription_activation.payment.open')
}

// Update subscription funnel
export const trackSubscriptionUpdatePackagesPageOpen = () => {
	MixpanelService.track('billing.subscription_change.packages.open')
}
export const trackSubscriptionUpdatePackageConfigurationOpen = () => {
	MixpanelService.track('billing.subscription_change.configuration.open')
}
export const trackSubscriptionUpdatePaymentOpen = () => {
	MixpanelService.track('billing.subscription_change.payment.open')
}

export const trackStripePromoCodeApplied = (promoCode: string) => {
	MixpanelService.track(`billing.promoCode.applied`, { promoCode })
}

export const trackVatIdValidationFailed = (paymentGateway: 'braintree' | 'stripe') => {
	MixpanelService.track(`billing.vat.validation.failed`, { paymentGateway })
}

// Modals
export const trackTrialExpiringModalDisplay = (step: string) => {
	MixpanelService.track(`modal.trialExpiring.${step}.load`)
}

export const trackDemoFormModalOpen = (type: string) => {
	MixpanelService.track('modal.demoForm.open', { type })
}

// Onboarding
export const trackOnboardingStepSurveyDemoRequestButtonClick = () => {
	MixpanelService.track('onboarding.survey.demoRequest.click')
}

// AI Reply assist
export const trackAiReplyAssistButtonClick = () => {
	MixpanelService.track('ai.replyAssist.button.click')
}

// AI Chatbot
export const trackAiBuilderOpen = () => {
	MixpanelService.track('ai.builder.open')
}

export const trackAiBuilderContinueButtonClick = (section: ChatbotWorkflowSection) => {
	MixpanelService.track('ai.builder.continueButton.click', { section })
}

export const trackAiBuilderPublishButtonClick = (data: {
	section: ChatbotWorkflowSection
	source: ChatbotPublishSourceType
}) => {
	MixpanelService.track('ai.builder.publishButton.click', data)
}

export const trackAiBuilderUnpublishButtonClick = (data: {
	section: ChatbotWorkflowSection
	source: ChatbotPublishSourceType
}) => {
	MixpanelService.track('ai.builder.unpublishButton.click', data)
}

export const trackAiBuilderActivateSourceClick = () => {
	MixpanelService.track('ai.builder.activateSource.click')
}

export const trackAiListPublishButtonClick = () => {
	MixpanelService.track('ai.list.publishButton.click')
}

export const trackAiListUnpublishButtonClick = () => {
	MixpanelService.track('ai.list.unpublishButton.click')
}

export const trackAiPreviewRestart = (source: AiPreviewSource) => {
	MixpanelService.track('ai.preview.restart.click', { source })
}

export const trackAiPreviewMessageSent = (source: AiPreviewSource) => {
	MixpanelService.track('ai.preview.messageSent', { source })
}

export const trackAiOnboardingModalOpen = () => {
	MixpanelService.track('ai.onboarding.modal.open')
}

export const trackAiOnboardingModalStepOpen = (step: AiOnboardingStep) => {
	MixpanelService.track(`ai.onboarding.modal.step.open`, { step })
}

// Integrations
export const trackIntegrationsTabClick = () => {
	MixpanelService.track('integrations.tab.click')
}

export const trackIntegrationsBannerDemoRequestButtonClick = () => {
	MixpanelService.track('integrations.banner.demoRequest.click')
}

export const trackIntegrationsFakeDoorConnectButtonClick = (name: string) => {
	MixpanelService.track(`integrations.fakeDoor.${name}.connectButton.click`)
}
