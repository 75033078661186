import { Flex, FlexProps } from 'core'

import { TableCell } from './TableCell'

type TableHeaderProps = {
	bgColor?: string
} & FlexProps

export const TableHeader = ({ bgColor = 'gray.200', ...rest }: TableHeaderProps) => {
	return <Flex bgColor={bgColor} borderTopLeftRadius="6px" borderTopRightRadius="6px" {...rest} />
}

export const TableHeaderCell = (props: FlexProps) => {
	return (
		<TableCell minH="40px" fontSize={0} color="gray.600" borderBottom="none" textTransform="uppercase" {...props} />
	)
}

export const TableHeaderCellThin = (props: FlexProps) => {
	return <TableHeaderCell minW="100px" {...props} />
}
