import { WidgetAppearanceFormMergedData, WidgetAuthFormMergedData } from 'modules/widget'
import { Translations, WidgetAppearanceTranslations, WidgetSettingsTranslationsType } from 'modules/widgetTranslations'

export const getTranslationsFormSubmitData = (data: Translations, defaultTranslations: Translations): Translations => {
	let customTranslationsObject: Translations = {}
	Object.entries(data).forEach(([key, value]) => {
		if (!defaultTranslations[key]) return
		if (value !== defaultTranslations[key]) {
			customTranslationsObject = { ...customTranslationsObject, [key]: value }
		}
	})
	return customTranslationsObject
}

export const getWidgetAppearanceTranslations = (data: Translations): WidgetAppearanceTranslations => {
	return {
		onlineTitle: data[WidgetSettingsTranslationsType.OnlineTitle],
		onlineStatus: data[WidgetSettingsTranslationsType.OnlineStatus],
		offlineTitle: data[WidgetSettingsTranslationsType.OfflineTitle],
		offlineStatus: data[WidgetSettingsTranslationsType.OfflineStatus],
		textarea: data[WidgetSettingsTranslationsType.Textarea],
		chatButton: data[WidgetSettingsTranslationsType.ChatButton],
	}
}

export const getWidgetAppearanceTranslationsSubmitData = (
	data: WidgetAppearanceFormMergedData,
	customTranslations: Translations,
	defaultTranslations: Translations,
): Translations => {
	const formTranslations = {
		[WidgetSettingsTranslationsType.OnlineTitle]: data.onlineTitle,
		[WidgetSettingsTranslationsType.OnlineStatus]: data.onlineStatus,
		[WidgetSettingsTranslationsType.OfflineTitle]: data.offlineTitle,
		[WidgetSettingsTranslationsType.OfflineStatus]: data.offlineStatus,
		[WidgetSettingsTranslationsType.Textarea]: data.textarea,
		[WidgetSettingsTranslationsType.ChatButton]: data.chatButton,
	}
	const editedTranslations = { ...customTranslations, ...formTranslations }
	return getTranslationsFormSubmitData(editedTranslations, defaultTranslations)
}

export const getWidgetAuthFormTranslationsSubmitData = (
	data: WidgetAuthFormMergedData,
	customTranslations: Translations,
	defaultTranslations: Translations,
): Translations => {
	const formTranslations = {
		[WidgetSettingsTranslationsType.AuthFormGreeting]: data.authFormTitle,
		[WidgetSettingsTranslationsType.AuthFormDescription]: data.authFormDesc,
	}
	const editedTranslations = { ...customTranslations, ...formTranslations }
	return getTranslationsFormSubmitData(editedTranslations, defaultTranslations)
}
