import { Flex, FlexProps } from 'core'

export const TableCell = ({ flex = 1, minW = '150px', overflow = 'hidden', ...rest }: FlexProps) => {
	return (
		<Flex
			alignItems="center"
			minH="56px"
			p={1}
			borderBottom={1}
			borderColor="gray.200"
			textOverflow="ellipsis"
			_first={{ pl: 2 }}
			_last={{ pr: 2 }}
			flex={flex}
			minW={minW}
			overflow={overflow}
			{...rest}
		/>
	)
}

export const TableCellThin = (props: FlexProps) => {
	return <TableCell minW="100px" {...props} />
}
