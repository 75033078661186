export enum UpsellType {
	AccountReview = 'accountReview',
	AccountReviewUnlimited = 'accountReviewUnlimited',
	Addons = 'addons',
	AiConversations = 'ai-conversations-limit',
	AiBonusConversationsLimit = 'ai-bonus-conversations-limit',
	AiReplyAssistToBasic = 'aiReplyAssistToBasic',
	AiReplyAssistToPro = 'aiReplyAssistToPro',
	AiSources = 'ai-sources-limit',
	AgentsAdd = 'fullAgents',
	AgentsAddUltimate = 'agentsAddUltimate',
	AssignAgent = 'assignAgent',
	AgentsLimitedAccess = 'agentsLimitedAccess',
	AutoMessages = 'auto-message',
	AutoMessagesExtra = 'autoMessagesExtra',
	BillingProfileFree = 'billingProfileFree',
	BillingProfileTrial = 'billingProfileTrial',
	BlockVisitor = 'blockVisitor',
	BlockIpAddress = 'blockIpAddress',
	ChatHistoryMonths12 = 'chatHistoryMonths12',
	ChatHistoryMonths36 = 'chatHistoryMonths36',
	ChatHistoryUnlimited = 'chatHistoryUnlimited',
	Chatbots = 'chatbot',
	ChatbotsExtra = 'chatbotsExtra', // Upsell over max limit in Trial/Pro
	ChatbotConversations = 'chatbot-conversations-limit',
	ContactTagsPanel = 'contactTagsPanel',
	ContactPropertiesPanel = 'contactPropertiesPanel',
	ChatHistoryDetailMonths12 = 'chatHistoryDetailMonths12',
	ChatHistoryDetailMonths36 = 'chatHistoryDetailMonths36',
	ChatHistoryDetailUnlimited = 'chatHistoryDetailUnlimited',
	CustomerInfo = 'customerInfo',
	ExportChats = 'exportChats',
	ExportContacts = 'exportContacts',
	Facebook = 'facebook',
	FacebookConnectedInTrial = 'facebookConnectedInTrial',
	FacebookTextarea = 'facebookTextarea',
	GoogleAnalytics = 'googleAnalytics',
	Groups = 'groups',
	LivechatConversations = 'livechat-conversations-limit',
	NotificationsMissedConversation = 'notificationsMissedConversation',
	OfficeHours = 'officeHours',
	PhoneNumber = 'phoneNumber',
	SendChatTranscript = 'sendChatTranscript',
	ShortcutsTeam = 'teamShortcut',
	ShortcutsLimit = 'limitShortcut',
	Tags = 'tags',
	Statistics = 'statistics',
	StatisticsBasic = 'statisticsBasic',
	ShowIP = 'showIpAddresses',
	Triggers = 'triggers',
	VisitorEngagement = 'visitorEngagement',
	VisitorListFilter = 'visitorListFilter',
	WidgetCustomization = 'customChatBox',
	WidgetHide = 'hideChatBox',
	WidgetPreChatForm = 'preChatForm',
	ChatbotActions = 'chatbot-actions',
}

export enum UpsellSubTypeChatbotConversations {
	ToPro = 'pro',
	ToUltimate = 'ultimate',
}

export enum UpsellSubTypeAiConversations {
	ToUltimate = 'ultimate',
}

export enum UpsellSubTypeLivechatConversations {
	LastChat = 'last-chat',
	OverLimit = 'over-limit',
	ToPro = 'pro',
}

export enum UpsellSubTypeChatbots {
	Leadgen = 'leadgen',
}

export enum UpsellSubTypeAiSources {
	CountLimit = 'count-limit',
	SizeLimit = 'size-limit',
}

export type UpsellSubType =
	| UpsellSubTypeChatbotConversations
	| UpsellSubTypeChatbots
	| UpsellSubTypeLivechatConversations
	| UpsellSubTypeAiSources
	| UpsellSubTypeAiConversations

export type UpsellEventAction = 'open' | 'upgrade' | 'contact'
