const getSize = (size: string) => {
	return {
		container: {
			width: size,
			height: size,
			fontSize: `calc(${size} / 2.5)`,
		},
		excessLabel: {
			width: size,
			height: size,
		},
		label: {
			fontSize: `calc(${size} / 2.5)`,
			lineHeight: size,
		},
	}
}

const sizes = {
	md: getSize('40px'),
}

const baseStyleContainer = (props: Record<string, string>) => {
	const { textColor, bg } = props
	return {
		bg: bg || 'transparent',
		color: textColor,
	}
}

const baseStyle = (props: Record<string, string>) => ({
	container: baseStyleContainer(props),
})

export const Avatar = {
	sizes,
	baseStyle,
}
