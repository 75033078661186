import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SmartsuppWindow } from 'shared/types'
import { AppThunkAction, DashboardState } from 'types'
import { logWarning } from 'shared/services'
import { flashMessage } from 'shared/utils'

import { gtmConfig, RESOURCE_NAME, TRIGGER_NAME, VERSION_NAME } from './constants'
import { GTMAccount, GTMContainer, GTMData, GTMError, GTMWorkspace } from './types'
import { makeConfig, makeField } from './utils'

type GTMRootState = Pick<DashboardState, 'gtm'>

declare const window: SmartsuppWindow

const initialState = {
	isPending: false,
	isError: false,
	errorType: '' as GTMError,
	isCreating: false,
	authenticated: false,
	inited: false,
	isInstalled: false,
	loaded: false,
	accounts: [] as GTMAccount[],
	containers: [] as GTMContainer[],
	workspaces: [] as GTMWorkspace[],
	account: null as null | GTMAccount,
	container: null as null | GTMContainer,
	workspace: null as null | GTMWorkspace,
	fetching: {
		accounts: false,
		containers: false,
		workspaces: false,
	},
}

export const loadLibrary = (): AppThunkAction => (dispatch) => {
	const { gapi } = window
	if (!gapi) {
		flashMessage.error('gtm.error.load')
		return
	}
	gapi.load('client:auth2', async () => {
		try {
			await gapi.auth2.init({ client_id: gtmConfig.clientId })
			dispatch(init())
		} catch (error) {
			if (!error) return
			logWarning(`GTM Init-${JSON.stringify(error)}`)
			dispatch(setError({ status: true, type: 'load' }))
		}
	})
}

export const authenticateAndLoadClient = (): AppThunkAction => async (dispatch) => {
	const { gapi } = window
	if (!gapi) {
		flashMessage.error('gtm.error.load')
		return
	}
	dispatch(setError({ status: false }))
	try {
		const authInstance = gapi.auth2.getAuthInstance()
		const isSignedIn = authInstance.isSignedIn.get()
		if (!isSignedIn) {
			await authInstance.signIn({ scope: gtmConfig.scope, prompt: 'select_account' })
		}
		dispatch(authenticate())
	} catch {
		flashMessage.warning('gtm.error.auth')
	}

	try {
		await gapi.client.setApiKey(gtmConfig.apiKey)
		await gapi.client.load(gtmConfig.restUrl)
		dispatch(load())
		dispatch(fetchAccounts())
	} catch {
		dispatch(setError({ status: true, type: 'client' }))
	}
}

export const fetchAccounts = (): AppThunkAction => async (dispatch) => {
	const accounts = await dispatch(fetchData(GTMData.Accounts))
	if (accounts.length === 0) return

	await dispatch(fetchContainers(accounts[0].path))
}

export const fetchContainers =
	(accountPath: string): AppThunkAction =>
	async (dispatch) => {
		const containers = await dispatch(fetchData(GTMData.Containers, accountPath))
		if (containers.length === 0) return

		await dispatch(fetchWorkspaces(containers[0].path))
	}

export const fetchWorkspaces =
	(containerPath: string): AppThunkAction =>
	async (dispatch) => {
		await dispatch(fetchData(GTMData.Workspaces, containerPath))
	}

const fetchData =
	(type: GTMData, parent?: string): AppThunkAction<Promise<GTMWorkspace[] | GTMContainer[] | GTMAccount[]>> =>
	async (dispatch) => {
		const { gapi } = window
		if (!gapi) {
			flashMessage.error('gtm.error.load')
			throw new Error('GTM not loaded')
		}
		const { tagmanager } = gapi.client
		if (!tagmanager) throw new Error('Tag Manager not available')

		const getResponse = () => {
			switch (type) {
				case 'accounts': {
					return tagmanager.accounts
				}
				case 'containers': {
					return tagmanager.accounts.containers
				}
				case 'workspaces': {
					return tagmanager.accounts.containers.workspaces
				}
				default: {
					return null
				}
			}
		}

		dispatch(fetching({ type, value: true }))
		try {
			const response = await getResponse().list(parent ? { parent } : {})
			if (response) {
				const field = makeField(type)
				const resultsByField = response.result[field]
				dispatch(setData({ type, data: response.result[field] }))
				dispatch(setFieldValue({ type, value: resultsByField[0].name }))
				dispatch(fetching({ type, value: false }))
				return resultsByField
			}
			return []
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			dispatch(fetching({ type, value: false }))
			if (error && error.result) {
				dispatch(setError({ status: true, type: error.result.error.message.includes('API') ? 'wrongApiKey' : 'data' }))
			}
			return []
		}
	}

export const setField =
	(type: GTMData, value: string): AppThunkAction =>
	(dispatch) => {
		const { gapi } = window
		if (!gapi) {
			flashMessage.error('gtm.error.load')
			return
		}
		dispatch(setFieldValue({ type, value }))
		if (type === GTMData.Accounts) {
			dispatch(setData({ type: GTMData.Containers, data: [] }))
			dispatch(setData({ type: GTMData.Workspaces, data: [] }))
		}
		if (type === GTMData.Containers) {
			dispatch(setData({ type: GTMData.Workspaces, data: [] }))
		}
	}

export const createTrigger =
	(path: string, callback?: () => void): AppThunkAction =>
	async (dispatch) => {
		const { gapi } = window
		if (!gapi) {
			flashMessage.error('gtm.error.load')
			return
		}
		const { tagmanager } = gapi.client
		if (!tagmanager) return

		try {
			await tagmanager.accounts.containers.workspaces.triggers.create({
				parent: path,
				resource: {
					name: TRIGGER_NAME,
					type: 'pageview',
				},
			})
			if (callback) callback()
		} catch {
			dispatch(setError({ status: true, type: 'trigger' }))
		}
	}

export const getTriggerId = async (path: string) => {
	const { gapi } = window
	if (!gapi) return null

	const { tagmanager } = gapi.client
	if (!tagmanager) return null

	try {
		const triggers = await tagmanager.accounts.containers.workspaces.triggers.list({ parent: path })
		if (triggers.result.trigger) {
			return triggers.result.trigger.find((item: Record<string, string>) => item.name === TRIGGER_NAME).triggerId
		}
		return null
	} catch (error) {
		if (error) console.error(error)
	}

	return null
}

export const createTag =
	(path: string, callback: () => void): AppThunkAction =>
	async (dispatch) => {
		const { gapi } = window
		if (!gapi) {
			flashMessage.error('gtm.error.load')
			return
		}
		const { tagmanager } = gapi.client
		if (!tagmanager) return

		dispatch(creating(true))

		let triggerId = ''
		const tags = await tagmanager.accounts.containers.workspaces.tags.list({ parent: path })
		const {
			result: { tag },
		} = tags
		if (tag) {
			const tagExists = tag.some((item: Record<string, string>) => item.name === RESOURCE_NAME)

			if (tagExists) {
				dispatch(setError({ status: true, type: 'alreadyExists' }))
				return
			}
		}

		try {
			triggerId = await getTriggerId(path)
			if (!triggerId) {
				await dispatch(createTrigger(path))
				triggerId = await getTriggerId(path)
			}
		} catch {
			dispatch(creating(false))
			dispatch(setError({ status: true, type: 'trigger' }))
		}

		try {
			await tagmanager.accounts.containers.workspaces.tags.create(makeConfig(path, triggerId))
			if (callback) callback()
			if (!callback) dispatch(creating(false))
		} catch {
			dispatch(creating(false))
			dispatch(setError({ status: true, type: 'tag' }))
		}
	}

export const publishVersion =
	(containersPath: string, workspacesPath: string, callback?: () => void): AppThunkAction =>
	async (dispatch) => {
		const { gapi } = window
		if (!gapi) {
			flashMessage.error('gtm.error.load')
			return
		}
		const { tagmanager } = gapi.client
		if (!tagmanager) return

		try {
			const version = await tagmanager.accounts.containers.workspaces.create_version({
				path: workspacesPath,
				resource: { name: VERSION_NAME },
			})
			if (version.result.containerVersion) {
				await tagmanager.accounts.containers.versions.publish({ path: version.result.containerVersion.path })
				await tagmanager.accounts.containers.versions.live({ parent: containersPath })
				if (callback) callback()
				dispatch(creating(false))
				dispatch(setInstalled())
			}
		} catch {
			dispatch(creating(false))
			dispatch(setError({ status: true, type: 'publish' }))
		}
	}

const gtmSlice = createSlice({
	name: 'gtm',
	initialState,
	reducers: {
		authenticate: (state) => {
			state.authenticated = true
		},
		init: (state) => {
			state.inited = true
		},
		load: (state) => {
			state.loaded = true
			state.isPending = true
		},
		setData: (
			state,
			{ payload }: PayloadAction<{ type: GTMData; data: GTMAccount[] | GTMContainer[] | GTMWorkspace[] }>,
		) => {
			if (payload.type === GTMData.Accounts) {
				state.accounts = payload.data as GTMAccount[]
			}
			if (payload.type === GTMData.Containers) {
				state.containers = payload.data as GTMContainer[]
			}
			if (payload.type === GTMData.Workspaces) {
				state.workspaces = payload.data as GTMWorkspace[]
			}
			state.isPending = false
		},
		setFieldValue: (state, { payload }: PayloadAction<{ type: GTMData; value: string }>) => {
			const dataIndex = state[payload.type].findIndex((item) => item.name === payload.value)
			if (payload.type === GTMData.Accounts) {
				state.account = state.accounts[dataIndex]
			}
			if (payload.type === GTMData.Containers) {
				state.container = state.containers[dataIndex]
			}
			if (payload.type === GTMData.Workspaces) {
				state.workspace = state.workspaces[dataIndex]
			}
		},
		setError: (state, { payload }: PayloadAction<{ status: boolean; type?: GTMError }>) => {
			const { status, type } = payload
			state.isError = status
			state.isPending = !status
			if (type) state.errorType = type
		},
		reset: (state) => {
			state.isError = false
			state.errorType = ''
			state.isPending = false
			state.inited = false
			state.isCreating = false
			state.authenticated = false
		},
		fetching: (state, { payload }: PayloadAction<{ type: GTMData; value: boolean }>) => {
			state.fetching[payload.type] = payload.value
		},
		creating: (state, { payload }: PayloadAction<boolean>) => {
			state.isCreating = payload
		},
		setInstalled: (state) => {
			state.isInstalled = true
		},
	},
})

const isAuthenticated = (state: GTMRootState) => state.gtm.authenticated
const isCreating = (state: GTMRootState) => state.gtm.isCreating
const isInitialized = (state: GTMRootState) => state.gtm.inited
const isInstalled = (state: GTMRootState) => state.gtm.isInstalled
const isLoaded = (state: GTMRootState) => state.gtm.loaded
const isPending = (state: GTMRootState) => state.gtm.isPending
const getData = (state: GTMRootState, type: GTMData) => state.gtm[type]
const getAccounts = (state: GTMRootState) => state.gtm.accounts
const getContainers = (state: GTMRootState) => state.gtm.containers
const getWorkspaces = (state: GTMRootState) => state.gtm.workspaces
const getSelectedContainer = (state: GTMRootState) => state.gtm.container
const getSelectedWorkspace = (state: GTMRootState) => state.gtm.workspace
const getError = (state: GTMRootState) => state.gtm.isError
const getErrorType = (state: GTMRootState) => state.gtm.errorType
const getFetching = (state: GTMRootState) => state.gtm.fetching

const getCreateTagPath = createSelector([getSelectedWorkspace], (workspace) => {
	if (!workspace) return ''
	return workspace.path
})

const getPublishPath = createSelector([getSelectedContainer], (container) => {
	if (!container) return ''
	return container.path
})

const { reducer, actions } = gtmSlice

export const { authenticate, init, load, setData, setFieldValue, setError, reset, fetching, creating, setInstalled } =
	actions

export const gtmSelectors = {
	isAuthenticated,
	isCreating,
	isInitialized,
	isInstalled,
	isLoaded,
	isPending,
	getData,
	getAccounts,
	getContainers,
	getWorkspaces,
	getSelectedContainer,
	getSelectedWorkspace,
	getError,
	getErrorType,
	getFetching,
	getCreateTagPath,
	getPublishPath,
}

export default reducer
