import { ReactNode } from 'react'

import { List } from 'core'

type Props = {
	children?: ReactNode
}

const TabsList = ({ children }: Props) => {
	return (
		<List
			display="flex"
			flexWrap="wrap"
			styleType="none"
			height="headerHeight"
			mb={0}
			borderBottom={1}
			borderColor="gray.300"
		>
			{children}
		</List>
	)
}

export default TabsList
