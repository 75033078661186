import { createStylesContext, ThemingProps, useMultiStyleConfig } from '@chakra-ui/react'

import { Box, BoxProps } from 'core'

export type CardProps = {
	isRaised?: boolean
} & BoxProps &
	ThemingProps

const [StylesProvider, useStyles] = createStylesContext('Card')

export const Card = ({ size, variant, colorScheme, isRaised, sx, children, ...rest }: CardProps) => {
	const styles = useMultiStyleConfig('Card', { size, variant, colorScheme })

	const containerStyles = {
		...styles.container,
		...(isRaised && {
			boxShadow: 'shadow8',
			borderColor: 'transparent',
		}),
		...sx,
	}

	return (
		<Box __css={containerStyles} variant={variant} {...rest}>
			<StylesProvider value={styles}>{children}</StylesProvider>
		</Box>
	)
}

export type CardContentProps = BoxProps

export const CardContent = (props: CardContentProps) => {
	const styles = useStyles()
	return <Box __css={styles.cardContent} {...props} />
}
