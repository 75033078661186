import { AgentsSetActiveResponse, ApiAgent } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'agents'

const getAgents = () => {
	return apiClient.get<ApiAgent[]>(`${API_RESOURCE}?includeDeleted=true`)
}

const getAgent = (id: number) => {
	return apiClient.get<ApiAgent>(`${API_RESOURCE}/${id}`)
}

const updateAgent = (id: number, changes: Partial<ApiAgent>) => {
	return apiClient.patch<ApiAgent>(`${API_RESOURCE}/${id}`, changes)
}

const setActiveAgent = (id: number, active: boolean) => {
	return apiClient.patch<AgentsSetActiveResponse>(`${API_RESOURCE}/${id}/active`, { active })
}

const deleteAgent = (id: number) => {
	return apiClient.delete(`${API_RESOURCE}/${id}`)
}

export const agentsApi = { getAgents, getAgent, updateAgent, setActiveAgent, deleteAgent }
