import { Howl, Howler } from 'howler'

import { NotificationsConfigPlatformsBrowserEvents, NotificationsEventBrowser } from 'models'
import { ConfigurationService } from 'shared/services'

const isEnvDevelopment = process.env.NODE_ENV === 'development'
const envPublicUrl = process.env.PUBLIC_URL
const VOLUME_VALUE = 0.5

export const SoundService = {
	sounds: new Map<string, Howl>(),

	init(events: NotificationsConfigPlatformsBrowserEvents) {
		Howler.autoSuspend = false
		Howler.volume(VOLUME_VALUE)

		const configData = ConfigurationService.getData()

		const soundPath =
			isEnvDevelopment && envPublicUrl
				? `${envPublicUrl}/assets/sounds/`
				: `${configData.dashboardDistUrl}/assets/sounds/`

		Object.values(events).forEach((sound: NotificationsEventBrowser) => {
			if (sound.soundName) {
				const howl = new Howl({
					html5: true,
					src: [`${soundPath}/${sound.soundName}`],
				})
				this.sounds.set(sound.soundName, howl)
			}
		})
	},

	play(name: string) {
		const sound = this.sounds.get(name)
		if (sound) {
			sound.play()
		}
	},

	initAndPlay(file: string) {
		const configData = ConfigurationService.getData()

		const soundPath =
			isEnvDevelopment && envPublicUrl
				? `${envPublicUrl}/assets/sounds/${file}`
				: `${configData.dashboardDistUrl}/assets/sounds/${file}`

		const howl = new Howl({
			html5: true,
			src: [soundPath],
		})
		this.sounds.set(file, howl)
		this.play(file)
	},
}
