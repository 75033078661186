import { CustomHeader, getHttpHeaders } from 'shared/config/headers'
import { HttpMethod, HttpStatusCode } from 'shared/constants'
import { ConfigurationService } from 'shared/services'

export type ApiClient = typeof apiClient

export class ApiError extends Error {
	constructor(public response: Response) {
		super(response.statusText)
	}
}

const createApiClient = () => {
	const call = async <T>(
		method: HttpMethod,
		endpoint: string,
		options?: RequestInit,
		headers?: Record<string, string>,
	): Promise<T> => {
		const restAccessToken = await ConfigurationService.getRestAccessToken()
		return (
			fetchEndpoint(restAccessToken, method, endpoint, options, headers)
				.then((response) => {
					if (!response.ok) {
						throw new ApiError(response)
					}

					return extractJSON<T>(response)
				})
				// eslint-disable-next-line promise/no-return-wrap
				.catch((error: ApiError) =>
					error.response.json().then((json) => {
						throw json
					}),
				)
		)
	}

	const get = <T>(endpoint: string): Promise<T> => {
		return call<T>(HttpMethod.Get, endpoint, getHttpHeaders())
	}

	const post = <T>(endpoint: string, data?: unknown, headers?: Record<string, string>): Promise<T> => {
		const body = JSON.stringify(data)
		return call<T>(HttpMethod.Post, endpoint, { body }, { ...getHttpHeaders(), ...headers })
	}

	const postForm = <T>(endpoint: string, body?: FormData): Promise<T> => {
		return call<T>(HttpMethod.Post, endpoint, { body })
	}

	const patch = <T>(endpoint: string, data?: unknown): Promise<T> => {
		const body = JSON.stringify(data)
		return call<T>(HttpMethod.Patch, endpoint, { body }, getHttpHeaders())
	}

	const put = <T>(endpoint: string, data: unknown): Promise<T> => {
		const body = JSON.stringify(data)
		return call<T>(HttpMethod.Put, endpoint, { body }, getHttpHeaders())
	}

	const deleteCall = <T>(endpoint: string, data?: unknown): Promise<T> => {
		const options: RequestInit = {}
		if (data) {
			options.body = JSON.stringify(data)
		}
		return call<T>(HttpMethod.Delete, endpoint, options, getHttpHeaders())
	}

	return { get, post, postForm, patch, put, delete: deleteCall }
}

export const apiClient = createApiClient()

const checkApiVersion = (response: Response) => {
	try {
		const configData = ConfigurationService.getData()
		const apiVersion = response.headers.get(CustomHeader.ApiVersion)
		if (
			apiVersion &&
			configData &&
			configData.apiVersion &&
			Number.parseInt(apiVersion, 10) !== configData.apiVersion
		) {
			window.location.reload()
		}
	} catch {
		// Skip error
	}
}

const fetchEndpoint = async (
	accessToken: string,
	method: HttpMethod,
	endpoint: string,
	options?: RequestInit,
	headers?: Record<string, string>,
): Promise<Response> => {
	const { apiUrl } = ConfigurationService.getAppEnvData()
	const requestInit: RequestInit = {
		method,
		headers: {
			...headers,
			Authorization: `Bearer ${accessToken}`,
		},
		...options,
	}

	const response = await fetch(`${apiUrl}/${endpoint}`, requestInit)
	checkApiVersion(response)
	return response
}

const extractJSON = <T>(response: Response): Promise<T> => {
	if (response.status === HttpStatusCode.S204NoContent) {
		return Promise.resolve(null as unknown as T)
	}

	return response.json()
}
