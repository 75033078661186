import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DashboardState } from 'types'
import { AccountSegmentCompanySize, AccountSegmentCustomerObjective } from 'shared/models/Segmentation'
import { ReducedAccountSegment } from 'modules/onboarding'

import { segmentationApi } from './api'

export const DEFAULT_DATA: ReducedAccountSegment = {
	companySize: null,
	customerObjective: null,
}

export const updateAccountSegment = createAsyncThunk('segment/UPDATE', (values: Partial<ReducedAccountSegment>) => {
	return segmentationApi.updateAccountSegment(values)
})

export const fetchAccountSegment = createAsyncThunk('segment/FETCH', () => {
	return segmentationApi.fetchAccountSegment()
})

const initialState = {
	isFetching: false,
	data: DEFAULT_DATA,
}

const accountSegmentSlice = createSlice({
	name: 'accountSegment',
	initialState,
	reducers: {
		setAccountSegmentData: (state, { payload }: PayloadAction<Partial<ReducedAccountSegment>>) => {
			state.data = { ...state.data, ...payload }
		},
		setCustomerObjective: (state, { payload }: PayloadAction<AccountSegmentCustomerObjective | null>) => {
			state.data.customerObjective = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAccountSegment.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchAccountSegment.fulfilled, (state, { payload }) => {
				state.isFetching = false
				state.data = {
					companySize: payload.companySize,
					customerObjective: payload.customerObjective,
				}
			})
			.addCase(fetchAccountSegment.rejected, (state) => {
				state.isFetching = false
			})
	},
})

const { reducer, actions } = accountSegmentSlice
export const { setAccountSegmentData, setCustomerObjective } = actions
export default reducer

const getAccountSegmentData = (state: DashboardState) => state.accountSegment.data
const getAccountCustomerObjective = (state: DashboardState) => state.accountSegment.data.customerObjective
const getAccountCompanySizeData = (state: DashboardState) => state.accountSegment.data.companySize

const getAccountCompanySize = createSelector(
	[getAccountCompanySizeData],
	(companySize): AccountSegmentCompanySize | null => {
		// Map Legacy Company sizes
		switch (companySize) {
			case AccountSegmentCompanySize.XS: {
				return AccountSegmentCompanySize.S
			}
			case AccountSegmentCompanySize.L: {
				return AccountSegmentCompanySize.M
			}
			default: {
				return companySize
			}
		}
	},
)

export const accountSegmentSelectors = {
	getAccountSegmentData,
	getAccountCompanySize,
	getAccountCustomerObjective,
}
