import { createAsyncThunk, createSelector, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit'

import { AppThunkAction, DashboardState } from 'types'
import {
	NotificationsApi,
	NotificationsConfigPlatformsBrowser,
	NotificationsConfigPlatformsEmail,
	PlatformType,
} from 'shared/models/NotificationsSound'
import { flashMessage } from 'shared/utils'
import { SoundService } from 'services'

import { notificationsApi } from './api'
import { NotificationsFormData } from './types'

export const initialState = {
	isFetching: false,
	isUpdating: false,
	browserNotification: null as null | NotificationsConfigPlatformsBrowser,
	emailNotification: null as null | NotificationsConfigPlatformsEmail,
	error: null,
}

export const fetchNotifications = createAsyncThunk('notifications/FETCH', (platforms?: PlatformType[]) => {
	return notificationsApi.fetchNotifications(platforms)
})

export const updateNotificationsThunk = createAsyncThunk(
	'notifications/PATCH',
	(data: NotificationsApi.UpdateBodyPlatforms) => {
		return notificationsApi.updateNotifications(data)
	},
)

export const initSoundNotifications = (): AppThunkAction => async (dispatch) => {
	try {
		const notificationsResponse = await dispatch(fetchNotifications(['browser']))
		if (fetchNotifications.fulfilled.match(notificationsResponse)) {
			const { platforms } = notificationsResponse.payload
			if (platforms.browser) {
				SoundService.init(platforms.browser.events)
			} else {
				throw new Error('Init sound notifications failed - browser notifications not found')
			}
		} else {
			throw new Error('Init sound notifications failed')
		}
	} catch (error) {
		if (error instanceof Error) throw error
	}
}

export const updateBrowserNotifications =
	(data: NotificationsApi.UpdateBodyPlatformsBrowser): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const browserNotification = getBrowserNotifications(state)
		if (!browserNotification) return

		const isBrowserEventUpdated = data.events
		let browserNotificationUpdated

		if (isBrowserEventUpdated) {
			// sound event browser notification was updated
			const browserEventsNotificationsUpdated = { ...browserNotification.events, ...data.events }
			browserNotificationUpdated = {
				...browserNotification,
				events: browserEventsNotificationsUpdated,
			}
		} else {
			// general browser notification was updated
			browserNotificationUpdated = { ...browserNotification, ...data }
		}

		dispatch(setBrowserNotification(browserNotificationUpdated as NotificationsConfigPlatformsBrowser))
		try {
			const response = await dispatch(updateNotificationsThunk({ browser: { ...data } }))
			unwrapResult(response)
		} catch {
			flashMessage.error('general.error')
			dispatch(setBrowserNotification(browserNotification))
		}
	}

export const updateEmailNotifications =
	(data: NotificationsApi.UpdateBodyPlatformsEmailEvents): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const emailNotification = getEmailNotifications(state)
		if (!emailNotification) return

		const emailNotificationUpdated = { ...emailNotification.events, ...data }
		dispatch(setEmailNotification({ events: emailNotificationUpdated } as NotificationsConfigPlatformsEmail))
		try {
			const response = await dispatch(updateNotificationsThunk({ email: { events: { ...data } } }))
			unwrapResult(response)
		} catch {
			flashMessage.error('general.error')
			dispatch(setEmailNotification(emailNotification))
		}
	}

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		setBrowserNotification: (state, { payload }: PayloadAction<NotificationsConfigPlatformsBrowser>) => {
			state.browserNotification = payload
		},
		setEmailNotification: (state, { payload }: PayloadAction<NotificationsConfigPlatformsEmail>) => {
			state.emailNotification = payload
		},
	},
	extraReducers: (builder) => {
		// FETCH
		builder
			.addCase(fetchNotifications.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchNotifications.fulfilled, (state, { payload }) => {
				state.browserNotification = payload.platforms.browser ?? null
				state.emailNotification = payload.platforms.email ?? null
				state.isFetching = false
			})
			.addCase(fetchNotifications.rejected, (state) => {
				state.isFetching = false
			})

		builder.addCase(updateNotificationsThunk.fulfilled, (state, { payload }) => {
			state.browserNotification = payload.platforms.browser ?? null
			state.emailNotification = payload.platforms.email ?? null
		})
	},
})

const { reducer, actions } = notificationsSlice
const { setBrowserNotification, setEmailNotification } = actions
export default reducer

export const getBrowserNotifications = (state: DashboardState) => state.notifications.browserNotification
const getEmailNotifications = (state: DashboardState) => state.notifications.emailNotification
export const isFetching = (state: DashboardState) => state.notifications.isFetching
export const isUpdating = (state: DashboardState) => state.notifications.isUpdating

export const getNotificationsFormData = createSelector(
	[getBrowserNotifications, getEmailNotifications],
	(browserNotification, emailNotification): NotificationsFormData | null => {
		if (!browserNotification || !emailNotification) return null

		const {
			enabled,
			enabledOffline,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			events: { incoming_chat, incoming_message, incoming_visitor },
		} = browserNotification
		const {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			events: { missed_chat, offline_message, contact_acquired_chatbot },
		} = emailNotification

		return {
			enabledSound: enabled,
			enabledSoundOffline: enabledOffline,
			emailMissedChat: missed_chat.enabled,
			emailOfflineMessage: offline_message.enabled,
			emailContactAcquired: contact_acquired_chatbot.enabled,
			eventIncomingChat: incoming_chat.enabled ? incoming_chat.soundName : '',
			eventIncomingMessage: incoming_message.enabled ? incoming_message.soundName : '',
			eventIncomingVisitor: incoming_visitor.enabled ? incoming_visitor.soundName : '',
		}
	},
)

export const notificationsSelectors = {
	getNotificationsFormData,
	getBrowserNotifications,
	isFetching,
	isUpdating,
}
