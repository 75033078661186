import { css } from '@emotion/react'

import { theme } from './theme'

export const document = css({
	'*': {
		padding: 0,
		margin: 0,
		boxSizing: 'border-box',
		borderWidth: 0,
		borderStyle: 'solid',

		'&::before, &::after': {
			boxSizing: 'border-box',
			borderWidth: 0,
			borderStyle: 'solid',
		},

		'&:focus': {
			outline: 'none',
		},
	},

	html: {
		height: '100%',
	},

	body: {
		fontFamily: theme.fonts.body as string,
		color: theme.colors.gray[700],
		height: '100%',
		width: '100% !important',
	},

	a: {
		color: theme.colors.blue[500],
		textDecoration: 'none',
		'&:hover, &:active': {
			color: theme.colors.blue[600],
			textDecoration: 'underline',
		},
	},

	'h1, h2, h3, h4': {
		marginTop: 0,
		marginBottom: 0,
		lineHeight: theme.lineHeights.default,
	},

	ul: {
		marginBottom: 0,
	},

	'#app': {
		height: '100%',
	},

	button: {
		background: 'transparent',
		cursor: 'pointer',
	},

	'img.emoji': {
		height: '1em',
		width: '1em',
		margin: '0 .05em 0 .1em',
		verticalAlign: '-0.1em',
	},

	p: {
		margin: 0,
	},

	// react modal override
	'.react-responsive-modal-root': {
		zIndex: 9999,
	},
})
