import {
	Box,
	Center,
	Step as ChakraStep,
	StepIcon,
	StepIndicator,
	StepNumber,
	StepSeparator,
	StepStatus,
	Text,
} from '@chakra-ui/react'

const STEP_TRANSITION = 'all 200ms'

type StepProps = {
	title: string
	stepIndex: number
	selectedIndex: number
	onClick?: (stepIndex: number) => void
	isAiContext?: boolean
}

export const Step = ({ stepIndex, selectedIndex, title, onClick, isAiContext = false }: StepProps) => {
	const isClickable = stepIndex < selectedIndex
	const isSelected = stepIndex === selectedIndex
	const color = isAiContext ? 'purple.500' : 'blue.500'

	const handleClick = () => {
		if (isClickable) {
			onClick && onClick(stepIndex)
		}
	}
	return (
		<ChakraStep onClick={handleClick}>
			<Center
				gridGap={1}
				_hover={{ cursor: isClickable ? 'pointer' : 'default', color: isClickable ? color : 'auto' }}
				color={isSelected ? 'gray.700' : 'gray.500'}
			>
				<StepIndicator
					transition={STEP_TRANSITION}
					sx={{
						'&[data-status=complete]': {
							border: 1,
							borderColor: color,
							bgColor: 'white',
							color,
						},
						'&[data-status=active]': {
							border: 1,
							borderColor: color,
							bgColor: color,
							color: 'white',
						},
						'&[data-status=incomplete]': {
							border: 1,
							borderColor: 'gray.300',
						},
					}}
				>
					<StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
				</StepIndicator>

				<Box flexShrink="0" display={{ base: 'none', lg: 'block' }}>
					<Text>{title}</Text>
				</Box>
			</Center>
			<StepSeparator style={{ transition: STEP_TRANSITION }} />
		</ChakraStep>
	)
}
