import {
	Contact,
	ContactBanResponse,
	ContactsExportBody,
	ContactsFindBody,
	ContactsListBody,
	ContactsListResponse,
	ContactsUnseenCount,
	ContactUnbanResponse,
} from 'shared/models/Contact'
import { apiClient } from 'shared/utils'

import { TagContactParams, UpdateContactParams } from './types'

const API_RESOURCE = 'v2/contacts'

const fetchContacts = (data: ContactsListBody) => {
	return apiClient.post<ContactsListResponse>(`${API_RESOURCE}/search?type=lead`, data)
}

const exportContacts = (data: ContactsExportBody) => {
	return apiClient.post(`${API_RESOURCE}/export?type=lead`, data)
}

const getContact = (id: Contact['id']) => {
	return apiClient.get<Contact>(`${API_RESOURCE}/${id}`)
}

const deleteContact = (id: Contact['id']) => {
	return apiClient.delete(`${API_RESOURCE}/${id}`)
}

const updateContact = (data: UpdateContactParams) => {
	const { id, changes } = data
	return apiClient.patch<Contact>(`${API_RESOURCE}/${id}`, changes)
}

const banContact = (id: Contact['id']) => {
	return apiClient.patch<ContactBanResponse>(`${API_RESOURCE}/${id}/ban`)
}

const unbanContact = (id: Contact['id']) => {
	return apiClient.patch<ContactUnbanResponse>(`${API_RESOURCE}/${id}/unban`)
}

const addTagContact = ({ id, tagKey }: TagContactParams) => {
	return apiClient.post(`${API_RESOURCE}/${id}/tags`, { tagId: tagKey })
}

const removeTagContact = ({ id, tagKey }: TagContactParams) => {
	return apiClient.delete(`${API_RESOURCE}/${id}/tags/${tagKey}`)
}

const setContactsSeen = (data: string[] | null) => {
	return apiClient.post(`${API_RESOURCE}/seen`, { ids: data })
}

const getUnseenCount = () => {
	return apiClient.get<ContactsUnseenCount>(`${API_RESOURCE}/count/unseen`)
}

const findContact = (data: ContactsFindBody) => {
	return apiClient.post<Contact>(`${API_RESOURCE}/find`, data)
}

export const contactsApi = {
	getContact,
	banContact,
	unbanContact,
	updateContact,
	fetchContacts,
	deleteContact,
	exportContacts,
	addTagContact,
	removeTagContact,
	setContactsSeen,
	getUnseenCount,
	findContact,
}
