/* eslint-disable consistent-return */
import { useEffect, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

export const useInterval = (callback: () => void, delay: number | null | false, immediate?: boolean) => {
	const savedCallback = useRef(noop)

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback
	})

	// Execute callback if immediate is set.
	useEffect(() => {
		if (!immediate) return
		if (delay === null || delay === false) return
		savedCallback.current()
	}, [delay, immediate])

	// Set up the interval.
	useEffect(() => {
		if (delay === null || delay === false) return
		const tick = () => savedCallback.current()
		const id = window.setInterval(tick, delay)
		return () => window.clearInterval(id)
	}, [delay])
}
