export enum AutomationSortName {
	Oldest = 'oldest',
	Newest = 'newest',
	MostTriggered = 'mostTriggered',
	MostConversations = 'mostConversations',
	BestPerformance = 'bestPerformance',
}

export enum AutomationSortType {
	CreatedAt = 'createdAt',
	Triggered = 'triggered',
	Conversations = 'conversations',
	Ratio = 'ratio',
}

export enum AutomationSortDirection {
	Asc = 'asc',
	Desc = 'desc',
}

export interface AutomationSorting {
	type: AutomationSortType
	direction: AutomationSortDirection
	name: AutomationSortName
}
