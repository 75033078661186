import moment from 'moment/moment'

import { CountWithDate, StatisticsInterval } from 'models'
import { AutoMessagesApi, AutoMessagesStats, AutoMessagesStatsSummary } from 'models/AutoMessage'
import { TranslationService as T } from 'shared/services'
import { theme } from 'styles'
import { ChartItemData } from 'modules/chatbotStatistics'
import { getFormattedNames } from 'modules/statistics'

import { DEFAULT_AUTO_MESSAGE_STATISTICS_ARRAY, DEFAULT_AUTO_MESSAGE_SUMMARY_ARRAY } from './constants'
import {
	AutoMessageStatisticsDataType,
	ChartData,
	TransformedAutoMessageStatistics,
	TransformedAutoMessageSummaryStatistics,
	TransformedSingleAutoMessageSummaryStats,
} from './types'

export const transformAutoMessageStatistics = (stats: AutoMessagesStats): TransformedAutoMessageStatistics => {
	if (!stats) return DEFAULT_AUTO_MESSAGE_STATISTICS_ARRAY

	return [
		{ name: AutoMessageStatisticsDataType.Triggered, value: stats.triggeredUnique.value },
		{ name: AutoMessageStatisticsDataType.IncomingConversations, value: stats.reactions.value },
	]
}

export const transformAutoMessageSummaryStatistics = (
	stats: AutoMessagesStatsSummary,
): TransformedAutoMessageSummaryStatistics => {
	if (!stats) return DEFAULT_AUTO_MESSAGE_SUMMARY_ARRAY

	return {
		[AutoMessageStatisticsDataType.Triggered]: {
			value: stats.triggeredUnique.value,
			trend: stats.triggeredUnique.trendPercentage,
		},
		[AutoMessageStatisticsDataType.IncomingConversations]: {
			value: stats.reactions.value,
			trend: stats.reactions.trendPercentage,
		},
	}
}

export const transformSingleAutoMessageSummary = (
	stats: AutoMessagesStatsSummary,
): TransformedSingleAutoMessageSummaryStats[] => {
	return [
		{
			name: AutoMessageStatisticsDataType.Triggered,
			value: stats.triggeredUnique.value,
			trendPercentage: stats.triggeredUnique.trendPercentage,
		},
		{
			name: AutoMessageStatisticsDataType.IncomingConversations,
			value: stats.reactions.value,
			trendPercentage: stats.reactions.trendPercentage,
		},
	]
}

const convertChartDataArray = (data?: CountWithDate[]): ChartItemData[] => {
	if (!data) return []
	return data.map((item) => {
		const momentDate = moment(item.date, 'YYYY-MM-DD')
		const formattedNames = getFormattedNames(StatisticsInterval.Day, momentDate)
		return { x: formattedNames.long, y: item.count }
	})
}

export const getChartData = (data: AutoMessagesApi.StatsSingleAggregatedResponse): ChartData[] => {
	const { triggered, reactions } = data

	return [
		{
			id: AutoMessageStatisticsDataType.Triggered,
			label: T.translate('statistics.autoMessages.triggered'),
			color: theme.colors.blue[500],
			data: convertChartDataArray(triggered),
		},
		{
			id: AutoMessageStatisticsDataType.IncomingConversations,
			label: T.translate('statistics.autoMessages.incomingConversations'),
			color: theme.colors.pink[500],
			data: convertChartDataArray(reactions),
		},
	]
}
