import { useState } from 'react'
import { Image as ChakraImage, ImageProps as ChakraImageProps } from '@chakra-ui/react'

import { ConfigurationService } from 'shared/services'

export type ImageProps = {
	path: string
	fallbackPath?: string
} & ChakraImageProps

export const Image = ({ path, fallbackPath, ...rest }: ImageProps) => {
	const [error, setError] = useState<boolean>(false)
	const configData = ConfigurationService.getData()
	const imgPath = error ? fallbackPath || '' : path
	const isPathAbsolute = imgPath.indexOf('://') > 0 || imgPath.indexOf('//') === 0
	const preparedImgPath = isPathAbsolute ? imgPath : `${configData ? configData.distUrl : ''}/${imgPath}`

	const handleError = () => {
		setError(true)
	}

	return <ChakraImage onError={handleError} src={preparedImgPath} {...rest} />
}
