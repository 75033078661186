import { NotificationsApi, NotificationsConfig, PlatformType } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'notifications/config'

const DEFAULT_PLATFORMS: PlatformType[] = ['browser', 'email']

const fetchNotifications = (platforms: PlatformType[] = DEFAULT_PLATFORMS) => {
	const platformsQuery = platforms.join(',')
	return apiClient.get<NotificationsConfig>(`${API_RESOURCE}?platform=${platformsQuery}`)
}

const updateNotifications = (data: NotificationsApi.UpdateBodyPlatforms) => {
	const platformsQuery = DEFAULT_PLATFORMS.join(',')
	const bodyData = {
		platforms: { ...data },
	}
	return apiClient.patch<NotificationsConfig>(`${API_RESOURCE}?platform=${platformsQuery}`, bodyData)
}

export const notificationsApi = {
	fetchNotifications,
	updateNotifications,
}
