import { createStandaloneToast } from '@chakra-ui/react'

import { NotificationProps as Props } from 'models'
import { theme } from 'styles/theme'

import { NotificationComponent } from 'shared/components/notification'

const { ToastContainer, toast } = createStandaloneToast({ theme })

const showNotification = ({
	id = Math.random() + Date.now(),
	duration = 5000,
	position = 'top-right',
	isClosable = true,
	...rest
}: Props) => {
	toast({
		id,
		position,
		duration,
		render: function renderToastComponent({ onClose }) {
			return <NotificationComponent {...rest} onClose={onClose} isClosable={isClosable} />
		},
	})
}

export const notification = {
	error: (props: Props) => showNotification({ ...props, status: 'error' }),
	warning: (props: Props) => showNotification({ ...props, status: 'warning' }),
	success: (props: Props) => showNotification({ ...props, status: 'success' }),
	info: (props: Props) => showNotification({ ...props, status: 'info' }),
}

export const NotificationContainer = () => <ToastContainer />
