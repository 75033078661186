import { mdiAutoFix, mdiMessageFlash } from '@mdi/js'

import { GroupType } from 'shared/models/Message'

import { iconChatbot } from 'shared/components/icons'

export const getAutomationsIcon = (type: GroupType | null) => {
	switch (type) {
		case GroupType.ai: {
			return mdiAutoFix
		}
		case GroupType.chatbot: {
			return iconChatbot
		}
		case GroupType.message: {
			return mdiMessageFlash
		}
		default: {
			return iconChatbot
		}
	}
}
