import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunkAction, DashboardState } from 'types'
import { BotIdentity, ChatbotWorkflow } from 'shared/models/Chatbot'
import { Preview } from 'shared/models/WidgetPreview'
import { DEFAULT_IDENTITY_NAME } from 'modules/chatbotIdentities'
import {
	ChatbotWorkflowSection,
	chatbotWorkflowSelectors,
	FORM_SOURCES,
	getWorkflowSubmitData,
} from 'modules/chatbotWorkflow'
import { sourcesSelectors } from 'modules/sources'

import { fetchAiAssistant, updateAiPreview } from './api'
import { AbortType, DEFAULT_BOT_GENDER } from './constants'
import { AiAssistantThunk } from './types'
import { getAiPreviewFetchData, processKeys } from './utils'

let aiAssistantThunk: AiAssistantThunk | null

const initialState = {
	isAiPreviewUpdating: false,
	isAiPreviewShown: false,
	isPreviewLoading: false,
	withoutReset: false,
}

const fetchAiAssistantPreview = createAsyncThunk('widgetPreview/FETCH_PREVIEW', (data: Preview.CreateMessageParams) =>
	fetchAiAssistant(data),
)

const slice = createSlice({
	name: 'widgetPreview',
	initialState,
	reducers: {
		setIsAiPreviewShown: (state, { payload }: PayloadAction<boolean>) => {
			state.isAiPreviewShown = payload
		},
		setIsPreviewLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isPreviewLoading = payload
		},
		setWithoutReset: (state, { payload }: PayloadAction<boolean>) => {
			state.withoutReset = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAiAssistantPreview.pending, (state) => {
				state.isAiPreviewUpdating = true
			})
			.addCase(fetchAiAssistantPreview.fulfilled, (state) => {
				state.isAiPreviewUpdating = false
			})
			.addCase(fetchAiAssistantPreview.rejected, (state, action) => {
				if (action.meta.aborted && action.error.message === AbortType.AnotherRequest) return
				state.isAiPreviewUpdating = false
			})
	},
})

const getIsAiPreviewUpdating = (state: DashboardState): boolean => state.widgetPreview.isAiPreviewUpdating
const getIsAiPreviewShown = (state: DashboardState): boolean => state.widgetPreview.isAiPreviewShown
const getIsPreviewLoading = (state: DashboardState): boolean => state.widgetPreview.isPreviewLoading
const getWithoutReset = (state: DashboardState): boolean => state.widgetPreview.withoutReset

const abortAiAssistantThunk = (reason?: AbortType) => {
	if (aiAssistantThunk?.abort) {
		aiAssistantThunk.abort(reason)
		aiAssistantThunk = null
	}
}

const { reducer, actions } = slice
export const { setIsAiPreviewShown, setIsPreviewLoading, setWithoutReset } = actions
export default reducer

export const widgetPreviewSelectors = {
	getIsAiPreviewUpdating,
	getIsAiPreviewShown,
	getIsPreviewLoading,
	getWithoutReset,
}

export const updatePreview =
	(data: ChatbotWorkflow, name: string, botIdentity: BotIdentity | null): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const { getSelectedSection, getSelectedSkillsSection } = chatbotWorkflowSelectors
		if (getSelectedSection(state) === ChatbotWorkflowSection.Preview) {
			abortAiAssistantThunk(AbortType.PreviewSection)
			return
		}
		abortAiAssistantThunk(AbortType.AnotherRequest)
		if (name?.includes(FORM_SOURCES) || !getIsAiPreviewShown(state)) return
		const previewQuestionKeys: string = processKeys(
			data,
			name,
			getSelectedSkillsSection(state),
			getSelectedSection(state),
		)
		const workflow = getWorkflowSubmitData(data, sourcesSelectors.getSources(state))
		const workflowWithIdentity: ChatbotWorkflow = botIdentity
			? {
					...workflow,
					profile: {
						name: botIdentity.name ?? DEFAULT_IDENTITY_NAME,
						gender: botIdentity.gender ?? DEFAULT_BOT_GENDER,
						avatar: botIdentity.avatarUrl ?? '',
					},
			  }
			: workflow

		aiAssistantThunk = dispatch(
			fetchAiAssistantPreview(getAiPreviewFetchData(workflowWithIdentity, previewQuestionKeys)),
		)
		const result = await aiAssistantThunk
		if (fetchAiAssistantPreview.fulfilled.match(result)) {
			updateAiPreview(result.payload, workflowWithIdentity)
		}
	}
