import { createSelector } from '@reduxjs/toolkit'

import { DEFAULT_TIMEZONE } from 'constants/app'
import { AccountRootState } from 'modules/account/slice'

const SMARTSUPP_ACCOUNT_ID = 50

const getSelectedTimezone = (state: AccountRootState) => state.account.timezone
const getTimezones = (state: AccountRootState) => state.account.timezones
const getAccountId = (state: AccountRootState) => state.account.accountId
const getIsAccountBlocked = (state: AccountRootState) => state.account.isBlocked
const getAccountBlockReason = (state: AccountRootState) => state.account.blockReason
const getAccountSourcePlatform = (state: AccountRootState) => state.account.sourcePlatform
const getAnonymizeIp = (state: AccountRootState) => state.account.anonymizeIp
const getAllowedDomains = (state: AccountRootState) => state.account.allowedDomains
const isPendingUpdate = (state: AccountRootState) => state.account.isPendingUpdate
const isDeletingAccount = (state: AccountRootState) => state.account.isDeletingAccount
const getIsGeneralFormDirty = (state: AccountRootState) => state.account.isGeneralFormDirty
const getIsSecurityFormDirty = (state: AccountRootState) => state.account.isSecurityFormDirty
const getIsGoogleAnalyticsFormDirty = (state: AccountRootState) => state.account.isGoogleAnalyticsFormDirty
const getMainDomain = (state: AccountRootState) => state.account.mainDomain
const getInstalledDomain = (state: AccountRootState) => state.account.installedDomain
const getShopifyActivationUrl = (state: AccountRootState) => state.account.shopifyActivationUrl
const getErrors = (state: AccountRootState) => state.account.errors
const getIsChatCodeInstalled = (state: AccountRootState) => state.account.chatCodeInstalled
const getChatCodeInstalledFirstAt = (state: AccountRootState) => state.account.installedFirstAt
const getWidgetPageUrl = (state: AccountRootState) => state.account.widgetPageUrl
const getAccountReviewConfig = (state: AccountRootState) => state.account.accountReviewConfig

const getMainDomainValue = createSelector(getMainDomain, (mainDomain): string => {
	if (!mainDomain) {
		return ''
	}
	return mainDomain
})

const getTimezonesOptions = createSelector(getTimezones, (timezones) => {
	return timezones.map((item) => ({ value: item.name, label: item.text }))
})

const getFormattedDomains = createSelector(getAllowedDomains, (allowedDomains): string => {
	return allowedDomains.join('\n')
})

const getAccountTimezone = createSelector(getSelectedTimezone, (timezone): string => {
	return timezone?.name ?? DEFAULT_TIMEZONE
})

const getIsSmartsuppAccount = createSelector(getAccountId, (accountId): boolean => {
	return accountId === SMARTSUPP_ACCOUNT_ID
})

const getIsAccountBlockedByAdmin = createSelector(
	[getIsAccountBlocked, getAccountBlockReason],
	(isBlocked, blockReason): boolean => {
		return isBlocked && blockReason === 'admin'
	},
)

const getIsAccountBlockedByExpiredPackage = createSelector(
	[getIsAccountBlocked, getAccountBlockReason],
	(isBlocked, blockReason): boolean => {
		return isBlocked && (blockReason === 'expired' || blockReason === 'unpaid')
	},
)

const getIsError = createSelector([getErrors], (errors) => {
	const { isErrorAccount, isErrorTimezones } = errors
	return isErrorAccount || isErrorTimezones
})

export const accountSelectors = {
	getAccountTimezone,
	getTimezones,
	getSelectedTimezone,
	getIsSmartsuppAccount,
	getIsAccountBlocked,
	getIsAccountBlockedByAdmin,
	getIsAccountBlockedByExpiredPackage,
	getAccountSourcePlatform,
	getAnonymizeIp,
	getAllowedDomains,
	getFormattedDomains,
	getIsGeneralFormDirty,
	getIsSecurityFormDirty,
	getIsGoogleAnalyticsFormDirty,
	isPendingUpdate,
	isDeletingAccount,
	getTimezonesOptions,
	getMainDomainValue,
	getInstalledDomain,
	getShopifyActivationUrl,
	getIsError,
	getIsChatCodeInstalled,
	getChatCodeInstalledFirstAt,
	getWidgetPageUrl,
	getAccountId,
	getAccountReviewConfig,
}
