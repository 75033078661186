import { useDispatch } from 'react-redux'
import { mdiChartLine } from '@mdi/js'

import { TranslationService as T } from 'shared/services/TranslationService'
import { routes } from 'configuration/routes'
import { selectStatistic, StatisticSection } from 'modules/statistics'

import { SubmenuLinkContent } from 'components/layout/submenu/SubmenuLinkContent'
import RouterLink from 'components/router/RouterLink'
import { PopoverMenu, PopoverMenuItem, PopoverMenuSection } from 'shared/components/core/Popover'
import { Text } from 'shared/components/core/Text'

const SidebarStatisticsMenu = () => {
	const dispatch = useDispatch()

	const handleClick = (section: StatisticSection) => {
		dispatch(selectStatistic({ section }))
	}

	return (
		<PopoverMenu width="250px">
			<PopoverMenuSection noPadding noBorder>
				<Text fontSize={0} textColor="gray.500" mt={1} mb="0.5" ml={2}>
					{T.translate('stats.conversations')}
				</Text>
				<PopoverMenuItem onClick={() => handleClick(StatisticSection.NewConversations)}>
					<RouterLink to={routes.statistics.path} data-testid={`menu-${StatisticSection.NewConversations}`}>
						<SubmenuLinkContent icon={mdiChartLine}>{T.translate('stats.newConversations')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem onClick={() => handleClick(StatisticSection.ServedConversations)}>
					<RouterLink to={routes.statistics.path} data-testid={`menu-${StatisticSection.ServedConversations}`}>
						<SubmenuLinkContent icon={mdiChartLine}>{T.translate('stats.conversationsRepliedTo')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem onClick={() => handleClick(StatisticSection.ClosedConversations)}>
					<RouterLink to={routes.statistics.path} data-testid={`menu-${StatisticSection.ClosedConversations}`}>
						<SubmenuLinkContent icon={mdiChartLine}>{T.translate('stats.closedConversations')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem onClick={() => handleClick(StatisticSection.Rating)}>
					<RouterLink to={routes.statistics.path} data-testid={`menu-${StatisticSection.Rating}`}>
						<SubmenuLinkContent icon={mdiChartLine}>{T.translate('stats.rating')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem onClick={() => handleClick(StatisticSection.ResponseTime)}>
					<RouterLink to={routes.statistics.path} data-testid={`menu-${StatisticSection.ResponseTime}`}>
						<SubmenuLinkContent icon={mdiChartLine}>{T.translate('stats.responseTime')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>

				<Text fontSize={0} textColor="gray.500" mt={1} mb="0.5" ml={2}>
					{T.translate('stats.newContacts')}
				</Text>
				<PopoverMenuItem onClick={() => handleClick(StatisticSection.NewContacts)}>
					<RouterLink to={routes.statistics.path} data-testid={`menu-${StatisticSection.NewContacts}`}>
						<SubmenuLinkContent icon={mdiChartLine}>{T.translate('stats.newContacts')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>

				<Text fontSize={0} textColor="gray.500" mt={1} mb="0.5" ml={2}>
					{T.translate('stats.modeSwitch.agents')}
				</Text>
				<PopoverMenuItem onClick={() => handleClick(StatisticSection.Agents)}>
					<RouterLink to={routes.statistics.path} data-testid={`menu-${StatisticSection.Agents}`}>
						<SubmenuLinkContent icon={mdiChartLine}>{T.translate('general.summary')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem onClick={() => handleClick(StatisticSection.OnlineTime)}>
					<RouterLink to={routes.statistics.path} data-testid={`menu-${StatisticSection.OnlineTime}`}>
						<SubmenuLinkContent icon={mdiChartLine}>{T.translate('stats.onlineTime')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<>
					<Text fontSize={0} textColor="gray.500" mt={1} mb="0.5" ml={2}>
						{T.translate('tags.title')}
					</Text>
					<PopoverMenuItem onClick={() => handleClick(StatisticSection.TagsUsage)}>
						<RouterLink to={routes.statistics.path} data-testid={`menu-${StatisticSection.TagsUsage}`}>
							<SubmenuLinkContent icon={mdiChartLine}>{T.translate('general.usage')}</SubmenuLinkContent>
						</RouterLink>
					</PopoverMenuItem>
				</>
			</PopoverMenuSection>
		</PopoverMenu>
	)
}

export default SidebarStatisticsMenu
