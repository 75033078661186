import { FieldValues, Path, UseFormSetError } from 'react-hook-form'

import { ApiValidationError } from 'shared/types'

export type FormHelpers = {
	setSubmissionErrors: (errors: ApiValidationError[]) => void
}

export const setSubmissionErrors =
	<T extends FieldValues>(setError: UseFormSetError<T>) =>
	(errors: ApiValidationError[]) => {
		for (const error of errors) {
			setError(error.path as Path<T>, { type: error.code })
		}
	}
