import moment from 'moment'

import { AppLang } from 'shared/types'
import { UserPreference } from 'shared/constants'
import { ConfigurationService } from 'shared/services'

export const shouldShowWebinarBanner = () => {
	/*
	 * 1. Edit "ALLOWED_LANGUAGES"
	 * 2. Edit "WEBINAR_BANNER_DATE"
	 * 3. Change "UserPreference.WebinarBannerHidden" value in 'shared/constants/UserPreference' to reflect correct webinar date */

	const ALLOWED_LANGUAGES: AppLang[] = ['en']
	const WEBINAR_BANNER_DATE = moment('2024-07-04 14:00')

	const { lang } = ConfigurationService.getData()
	const isBeforeWebinarDate = moment().isBefore(WEBINAR_BANNER_DATE)
	const isBannerClicked = window.localStorage.getItem(UserPreference.WebinarBannerHidden) === 'bannerClicked'

	return ALLOWED_LANGUAGES.includes(lang) && isBeforeWebinarDate && !isBannerClicked
}
