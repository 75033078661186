import { Input } from './input'

const sizes = {
	sm: Input.sizes.sm.field,
	md: Input.sizes.md.field,
	lg: Input.sizes.lg.field,
}

export const Textarea = {
	sizes,
}
