import { useSelector } from 'react-redux'

import { TranslationService as T } from 'shared/services'
import { chatbotDetailSelectors, getIsDefaultProperty, getPropertyKeyToName } from 'modules/chatbotDetail'
import { propertiesSelectors } from 'modules/properties'

export const useChatbotInteractionProperty = (interactionId: string) => {
	const interactionProperties = useSelector(chatbotDetailSelectors.makeGetPropertiesByInteractionId(interactionId))
	const allProperties = useSelector(propertiesSelectors.getSortedAndFilteredProperties)
	if (!interactionProperties) return []
	const translatedProps = interactionProperties.map((prop) => {
		return getIsDefaultProperty(prop) ? T.translate(`general.${prop}`) : getPropertyKeyToName(prop, allProperties)
	})
	return translatedProps
}
