export const Progress = {
	parts: ['track', 'filledTrack'],
	baseStyle: {
		track: {
			borderRadius: 'md',
		},
		filledTrack: {
			borderRadius: 'md',
		},
	},
	defaultProps: {
		borderRadius: 'md',
	},
}
