import { AppThunkAction } from 'types'
import { updateDataLayer } from 'shared/services'
import { accountSelectors } from 'modules/account'
import { packageSelectors } from 'modules/package'
import { userSelectors } from 'modules/user'

import { ExternalEvent } from './types'

export const updateDataLayerFirstSignIn = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const user = userSelectors.getActiveUser(state)
	if (!user) return

	const sourcePlatform = accountSelectors.getAccountSourcePlatform(state)
	const isPlatform = sourcePlatform !== null
	const sourcePlatformLabel = isPlatform ? `_plugin=${sourcePlatform}` : ''

	updateDataLayer({
		event: 'ga.event',
		eventCategory: isPlatform ? 'plugin' : 'web',
		eventAction: 'sign-up',
		eventLabel: `role=${user.role}${sourcePlatformLabel}`,
		eventValue: undefined,
	})
}

export const updateDataLayerWidgetUsage = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const user = userSelectors.getActiveUser(state)
	if (!user) return

	const sourcePlatform = accountSelectors.getAccountSourcePlatform(state)
	const isPlatform = sourcePlatform !== null
	const sourcePlatformLabel = isPlatform ? `_plugin=${sourcePlatform}` : ''

	updateDataLayer({
		event: 'ga.event',
		eventCategory: isPlatform ? 'plugin' : 'web',
		eventAction: 'usage',
		eventLabel: `role=${user.role}${sourcePlatformLabel}`,
		eventValue: undefined,
	})
}

export const updateDataLayerNewSubscription =
	(eventData: ExternalEvent.SubscriptionData): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const packageInfo = packageSelectors.getPackageInfo(state)

		if (!packageInfo) return
		const { interval, priceTotal, name, currencyCode } = packageInfo

		const data = {
			event: 'ec.purchase',
			ecommerce: {
				currencyCode: currencyCode ? currencyCode.toUpperCase() : currencyCode,
				purchase: {
					actionField: {
						id: eventData.externalId,
						revenue: priceTotal,
					},
					products: [
						{
							name,
							price: priceTotal,
							variant: interval,
							quantity: 1,
						},
					],
				},
			},
		}

		updateDataLayer(data)
	}

export const updateDataLayerNewSubscriptionGA =
	(eventData: ExternalEvent.SubscriptionData): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const user = userSelectors.getActiveUser(state)
		if (!user) return

		const sourcePlatform = accountSelectors.getAccountSourcePlatform(state)
		const isPlatform = sourcePlatform !== null
		const sourcePlatformLabel = isPlatform ? `_plugin=${sourcePlatform}` : ''

		updateDataLayer({
			event: 'ga.event',
			eventCategory: isPlatform ? 'plugin' : 'web',
			eventAction: 'subscription',
			eventLabel: `type=${eventData.isFirst ? 'activation' : 'reactivation'}_role=${user.role}${sourcePlatformLabel}`,
			eventValue: undefined,
		})
	}

export const updateDataLayerWidgetInstall = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const user = userSelectors.getActiveUser(state)
	if (!user) return

	const sourcePlatform = accountSelectors.getAccountSourcePlatform(state)
	const isPlatform = sourcePlatform !== null
	const sourcePlatformLabel = isPlatform ? `_plugin=${sourcePlatform}` : ''

	updateDataLayer({
		event: 'ga.event',
		eventCategory: isPlatform ? 'plugin' : 'web',
		eventAction: 'install',
		eventLabel: `role=${user.role}${sourcePlatformLabel}`,
		eventValue: undefined,
	})
}
