import { Property } from 'shared/models/Property'

export const getPropertyNameToKey = (name: string) => {
	const transformedKey = name
		.toLowerCase()
		.replaceAll(/\s/g, '_')
		.normalize('NFD')
		.replaceAll(/[\u0300-\u036F]/g, '')
		.replaceAll(/[^\d_a-z]/g, '')

	return transformedKey
}

export const getValidateKeyRegex = (): RegExp => {
	return /^[\d_a-z]+$/
}

export const getPreprocessProperties = (properties: Property[]): string[][] => {
	const nameKeyPropsObj = Object.fromEntries(properties.map((property) => [property.name, property.key]))
	const sortedByName = Object.keys(nameKeyPropsObj)
		.sort((a, b) => a.localeCompare(b))
		.map((name) => [name, nameKeyPropsObj[name]])
	return sortedByName
}
