import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DashboardState } from 'types'

import { SystemModal } from './types'

export type SystemModalRootState = Pick<DashboardState, 'systemModal'>
export type SystemModalState = typeof initialState

export const initialState = {
	systemModals: [] as SystemModal[],
}

const systemModalSlice = createSlice({
	name: 'systemModal',
	initialState,
	reducers: {
		openSystemModal: (state, action: PayloadAction<SystemModal>) => {
			const { name, data } = action.payload
			state.systemModals.push({ name, data })
		},
		closeSystemModal: (state) => {
			state.systemModals.pop()
		},
	},
})

const { actions, reducer } = systemModalSlice
export const { openSystemModal, closeSystemModal } = actions
export default reducer

const getSystemModals = (state: SystemModalRootState) => state.systemModal.systemModals

const getActiveSystemModal = createSelector([getSystemModals], (modals) => modals[modals.length - 1])

export const systemModalSelectors = {
	getActiveSystemModal,
}
