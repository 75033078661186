import { get } from 'lodash'

import { ChatbotWorkflow, Preview } from 'models'
import { SmartsuppWindow } from 'shared/types'
import { ConfigurationService, TranslationService as T } from 'shared/services'
import { theme } from 'styles'
import { trackAiPreviewRestart } from 'utils'
import { DEFAULT_IDENTITY_NAME } from 'modules/chatbotIdentities'
import { ChatbotWorkflowSection, ChatbotWorkflowSkills } from 'modules/chatbotWorkflow'

import {
	baseConnectedData,
	botIdentity,
	DEFAULT_BOT_GENDER,
	DEFAULT_IDENTITY_ID,
	DEFAULT_PATH,
	generalBlockingOptions,
	message,
	previewQuestions,
	sectionToWorkflowMap,
	WIDGET_EVENT_MESSAGE_SENT,
} from './constants'
import { AiPreviewSource, ApiCommand, PreviewType } from './types'

declare let window: SmartsuppWindow

const getBotIdentity = (workflow: ChatbotWorkflow) => {
	botIdentity.id = DEFAULT_IDENTITY_ID
	botIdentity.name = workflow.profile.name || DEFAULT_IDENTITY_NAME
	botIdentity.avatarUrl = workflow.profile.avatar || null
	botIdentity.gender = workflow.profile.gender || DEFAULT_BOT_GENDER
	return botIdentity
}

const getBaseConnectedData = (workflow: ChatbotWorkflow) => {
	const result = { ...baseConnectedData }
	result.account = { ...result.account, botIdentities: [getBotIdentity(workflow)] }
	if (result?.chat) {
		result.chat.readInfo.lastReadAt = new Date().toISOString()
		result.chat.readInfo.lastReadAt = new Date().toISOString()
	}

	return result
}

const getMessage = (text: string) => {
	const date = new Date().toISOString()
	const result = { ...message }
	result.createdAt = date
	result.id = date
	result.content.text = text
	result.attachments = []
	return result
}

export const getMockDataWithNewText = (text: string, workflow: ChatbotWorkflow) => {
	const baseData = getBaseConnectedData(workflow)
	if (baseData?.chat?.messages) {
		baseData.chat = { ...baseData.chat, messages: [getMessage(text)] }
	}
	return { connectData: baseData }
}

const getPreviewQuestion = (name: string) => {
	const questionKey: string = get(previewQuestions, name) || previewQuestions.behavior.default
	return T.translate(questionKey)
}

export const getAiPreviewFetchData = (workflow: ChatbotWorkflow, name: string): Preview.CreateMessageParams => ({
	messages: [
		{
			role: 'user',
			content: getPreviewQuestion(name),
		},
	],
	workflow,
})

export const processKeys = (
	workflow: ChatbotWorkflow,
	name: string,
	selectedSkillsSection: ChatbotWorkflowSkills,
	selectedSection: ChatbotWorkflowSection,
) => {
	if (!name) {
		return `${sectionToWorkflowMap[selectedSection]}.${DEFAULT_PATH}`
	}
	if (name.includes('.type')) {
		return name.replace(/(type)+/, workflow.skills[selectedSkillsSection].type || DEFAULT_PATH)
	}
	if (name.includes('behavior.description')) {
		return `training.default`
	}
	return name
}

export const getPathForSections = (
	workflow: ChatbotWorkflow,
	section: ChatbotWorkflowSection,
	skillsSection: ChatbotWorkflowSkills,
) => {
	const skill = section === ChatbotWorkflowSection.Skills ? skillsSection : null
	const name = `${sectionToWorkflowMap[section]}.${skill ? `${skill}.${workflow.skills[skill].type}` : 'default'}`
	return name
}

const getOptionsBasedUsage = (type: PreviewType, botId?: string, withoutReset = false) => {
	if (type === PreviewType.AiBuilderPreview) {
		return {
			mock: { connectData: baseConnectedData },
			widgetBlockingOptions: {
				...generalBlockingOptions,
				disableTextarea: true,
			},
		}
	}
	if (type === PreviewType.AiWidgetPreview) {
		return {
			widgetBlockingOptions: generalBlockingOptions,
			botId,
			previewWithoutReset: withoutReset,
			isPreviewMode: true,
		}
	}
	return ''
}

export const getPreviewHTML = (type: PreviewType, botId?: string, withoutReset?: boolean) => `
<html>
	<head>
		<meta name="viewport" content="width=device-width, initial-scale=1.0">
		<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
		<meta http-equiv="X-UA-Compatible" content="IE=8, IE=9, IE=10">
		<meta name="robots" content="noindex,nofollow">
	</head>
	<body>
		<!-- Smartsupp Live Chat script -->
		<script>var SMARTSUPP_AUTOCREATE = false;
		</script>
		<script src="${ConfigurationService.getAppEnvData().smartsuppWidgetBaseUrl}/loader.js"></script>
		<script type="text/javascript">
			var _smartsupp = ${JSON.stringify({
				protocol: ConfigurationService.getData()?.connection?.protocol || 'https',
				key: ConfigurationService.getAccountData().key,
				widgetVersion: 3,
				googleAnalyticsEnabled: false,
				colorGradient: true,
				color: theme.colors.violet[500],
				color2: theme.colors.purple[500],
				...getOptionsBasedUsage(type, botId, withoutReset),
			})};
			// Pass API to parent as **demoPreview**
			(window.top || window.parent).demoPreview = (...arg) => {
				${
					type === PreviewType.AiWidgetPreview
						? `if (arg[0] !== '${ApiCommand.ReloadWithMockData}') window.smartsupp.getChat('${type}').exec(...arg);`
						: `window.smartsupp.getChat('${type}').exec(...arg);`
				}
			}

			(async () => {
				const widget = await window.smartsupp.createWidget('${type}', _smartsupp)
				window.smartsupp.getAssetUrl = widget.getAssetUrl.bind(widget)
				await widget.render()
			})().then(() => {
				window.smartsupp.getChat('${type}').exec('on', 'widget_init', () => {
					window.parent.dispatchEvent(new Event('widgetInit'))
				})
				window.smartsupp.getChat('${type}').exec('on', 'message_sent', () => {
					window.parent.dispatchEvent(new Event('${WIDGET_EVENT_MESSAGE_SENT}'))
				})
				window.smartsupp.getChat('${type}').exec('chat:open');
				${
					type === PreviewType.AiWidgetPreview && !withoutReset
						? `window.smartsupp.getChat('${type}').exec('chat:message', '${T.translate(
								'chatbot.workflow.preview.defaultPrompt',
						  )}');`
						: ''
				}
			})
		</script>
	</body>
</html>
`

export const widgetPreviewApi = (name: string, data?: { [key: string]: unknown } | string | boolean) => {
	if (typeof window.demoPreview === 'function') {
		window.demoPreview(name, data)
	}
}

export const widgetPreviewRestart = (source: AiPreviewSource) => {
	widgetPreviewApi(ApiCommand.Reload, true)
	widgetPreviewApi(ApiCommand.Message, T.translate('chatbot.workflow.preview.defaultPrompt'))
	trackAiPreviewRestart(source)
}

export const widgetPreviewSendMessage = (text: string) => {
	widgetPreviewApi(ApiCommand.Send, text)
}
