import { useBreakpointValue } from '@chakra-ui/react'
import { mdiSignalCellular3 } from '@mdi/js'

import { TranslationService as T } from 'shared/services'
import { routes } from 'configuration/routes'

import { Box } from 'shared/components/core/Box'

import SidebarMenuIcon from '../sidebarMenu/SidebarMenuIcon'
import SidebarMenuItem from '../sidebarMenu/SidebarMenuItem'
import SidebarMenuItemPopover from '../sidebarMenu/SidebarMenuItemPopover'

import SidebarStatisticsMenu from './SidebarStatisticsMenu'

const StatisticsIcon = () => {
	return (
		<SidebarMenuItem path={routes.statistics.path} tooltip={T.translate('headerBar.statistics')}>
			<SidebarMenuIcon icon={mdiSignalCellular3} />
		</SidebarMenuItem>
	)
}

const SidebarStatistics = () => {
	const isMobile = useBreakpointValue({ base: true, lg: false })

	return (
		<Box data-testid="sidebar-statistics">
			{isMobile ? (
				<SidebarMenuItemPopover popoverContent={<SidebarStatisticsMenu />}>
					<SidebarMenuIcon icon={mdiSignalCellular3} />
				</SidebarMenuItemPopover>
			) : (
				<StatisticsIcon />
			)}
		</Box>
	)
}

export default SidebarStatistics
