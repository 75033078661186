import { Addon, AddonsApi } from 'models'
import { apiClient } from 'shared/utils'

import { FetchAddonRequestParams } from './types'

const API_RESOURCE = 'addons'

const fetchAddonsList = ({ timezone, lang }: AddonsApi.ListQuery) => {
	return apiClient.get<Addon[]>(`${API_RESOURCE}/list?timezone=${timezone}&lang=${lang}`)
}

const fetchAddonComponents = ({ id, context, timezone, lang, section, state, action }: FetchAddonRequestParams) => {
	return apiClient.post<AddonsApi.RenderResponse>(
		`${API_RESOURCE}/${id}/render/${section}?timezone=${timezone}&lang=${lang}`,
		{ context, state, ...(action && { action }) },
	)
}

export const addonsApi = { fetchAddonsList, fetchAddonComponents }
