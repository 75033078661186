import { WidgetLanguageType } from './types'

export const AUTH_FORM_EMAIL = 'emailControl'
export const AUTH_FORM_NAME = 'nameControl'
export const AUTH_FORM_PHONE = 'numberControl'
export const AUTH_FORM_GROUP = 'groupSelectEnabled'
export const PRIVACY_NOTICE = 'privacyNoticeEnabled'
export const PRIVACY_NOTICE_URL = 'privacyNoticeUrl'
export const PRIVACY_NOTICE_CONSENT = 'privacyNoticeCheckRequired'
export const WIDGET_BUTTON_STYLE = 'buttonStyle'

export const WIDGET_LANG_DEFAULT = 'en'
export const WIDGET_BUTTON_DEFAULT = 'greeting'
export const WIDGET_COLOR_DEFAULT = '#1233df'

// Languages sorted by usage
export const officiallyTranslatedLangs: WidgetLanguageType[] = [
	{ id: WIDGET_LANG_DEFAULT },
	{ id: 'cs' },
	{ id: 'sk' },
	{ id: 'de' },
	{ id: 'es' },
	{ id: 'fr' },
	{ id: 'it' },
	{ id: 'pl' },
	{ id: 'hu' },
	{ id: 'nl' },
]

export const unofficiallyTranslatedLangs: WidgetLanguageType[] = [
	{ id: 'az' },
	{ id: 'br' },
	{ id: 'bs' },
	{ id: 'ca' },
	{ id: 'da' },
	{ id: 'el' },
	{ id: 'fa' },
	{ id: 'fil' },
	{ id: 'hr' },
	{ id: 'lt' },
	{ id: 'lv' },
	{ id: 'no' },
	{ id: 'pt' },
	{ id: 'ro' },
	{ id: 'ru' },
	{ id: 'sl' },
	{ id: 'sv' },
	{ id: 'tr' },
	{ id: 'uk' },
	{ id: 'ar' },
	{ id: 'bg' },
	{ id: 'cn' },
	{ id: 'da' },
	{ id: 'fi' },
	{ id: 'he' },
	{ id: 'hi' },
	{ id: 'is' },
	{ id: 'ja' },
	{ id: 'ka' },
	{ id: 'mk' },
	{ id: 'sr' },
	{ id: 'th' },
	{ id: 'tw' },
]
