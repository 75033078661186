export const addScript = (url: string, callback?: () => void) => {
	if (document.querySelector(`script[src="${url}"]`)) return
	const fragment = document.createDocumentFragment()
	const script = document.createElement('script')

	if (callback) script.addEventListener('load', callback)

	script.setAttribute('defer', 'true')
	script.setAttribute('async', 'true')
	script.setAttribute('type', 'text/javascript')
	script.setAttribute('src', url)

	fragment.append(script)
	document.body.append(fragment)
}

export const addLink = (url: string, rel = 'stylesheet') => {
	if (document.querySelector(`link[href="${url}"]`)) return
	const fragment = document.createDocumentFragment()
	const link = document.createElement('link')

	link.setAttribute('rel', rel)
	link.setAttribute('href', url)

	fragment.append(link)
	document.head.append(fragment)
}

export const addMeta = (name: string, content: string) => {
	if (document.querySelector(`meta[name="${name}"]`)) return
	const fragment = document.createDocumentFragment()
	const meta = document.createElement('meta')

	meta.setAttribute('name', name)
	meta.setAttribute('content', content)

	fragment.append(meta)
	document.head.append(fragment)
}
