import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DemoFormData, DemoFormOptions, SurveyData } from 'models'
import { DashboardState } from 'types'
import { surveysApi } from 'modules/surveys'

export type SurveysRootState = Pick<DashboardState, 'surveys'>

export const initialState = {
	surveyData: null as null | SurveyData,
	isDemoFormModalOpen: false,
	demoFormModalOption: null as null | DemoFormOptions,
}

export const sendSurvey = createAsyncThunk('surveys/POST', (_, { getState }) => {
	const state = getState() as DashboardState
	const surveyData = getSurveyData(state)
	return surveysApi.sendSurvey(surveyData)
})

export const sendDemoForm = createAsyncThunk('demoform/POST', (data: DemoFormData) => {
	return surveysApi.sendDemoForm(data)
})

const surveysSlice = createSlice({
	name: 'surveys',
	initialState,
	reducers: {
		setSurveyData: (state, { payload }: PayloadAction<SurveyData>) => {
			state.surveyData = payload
		},
		openDemoFormModal: (state, { payload }: PayloadAction<DemoFormOptions>) => {
			state.isDemoFormModalOpen = true
			state.demoFormModalOption = payload
		},
		closeDemoFormModal: (state) => {
			state.isDemoFormModalOpen = false
		},
	},
})

const { reducer, actions } = surveysSlice
export const { setSurveyData, openDemoFormModal, closeDemoFormModal } = actions
export default reducer

const getSurveyData = (state: SurveysRootState) => state.surveys.surveyData
const getIsDemoFormModalOpen = (state: SurveysRootState) => state.surveys.isDemoFormModalOpen
const getDemoFormModalOption = (state: SurveysRootState) => state.surveys.demoFormModalOption

export const surveySelectors = {
	getIsDemoFormModalOpen,
	getDemoFormModalOption,
}
