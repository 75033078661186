import { ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'

import { getParams, QueryParams } from 'utils'

type Props = {
	children: ReactNode
	to: LinkProps['to']
	params?: QueryParams
} & LinkProps

const RouterLink = ({ children, to, params, ...rest }: Props) => {
	return (
		<Link to={`${to}${getParams(params)}`} {...rest}>
			{children}
		</Link>
	)
}

export default RouterLink
