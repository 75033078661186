import { AnyAction } from 'redux'

import Logger from 'services/Logger'

import { Dispatcher } from './Dispatcher'

const now = () => Date.now()

export default class ActionsThrottleDispatcher extends Dispatcher {
	lastDispatchedTime: number

	logger: Logger

	returnActionType: string

	timeoutCall?: number

	timeoutInMs: number

	constructor(timeoutInMs: number, returnActionType: string, logger: Logger) {
		super()
		this.timeoutInMs = timeoutInMs
		this.lastDispatchedTime = now() - timeoutInMs
		this.returnActionType = returnActionType
		this.logger = logger
	}

	dispatchAction(next: (action: AnyAction) => void, action: AnyAction) {
		if (this.timeoutCall) window.clearTimeout(this.timeoutCall)

		if (now() - this.lastDispatchedTime > this.timeoutInMs) {
			this.logger.log(`${this.returnActionType}`)
			next(action)
			this.lastDispatchedTime = now()
		} else {
			// eslint-disable-next-line unicorn/prefer-prototype-methods
			this.timeoutCall = window.setTimeout(this.dispatchAction.bind(this, next, action), this.timeoutInMs)
		}
	}
}
