import { action, payload } from 'ts-action'

type ApiError = {
	message: string
}

type Payload<M, E = ApiError> = {
	isPending: boolean
	error?: E
	meta?: M
}

type PayloadWithResponse<R, M = void, E = ApiError> = Payload<M, E> & {
	response: R
}

type RequestState<R = void, M = void, E = ApiError> = R extends void
	? Readonly<Payload<M, E>>
	: Readonly<PayloadWithResponse<R, M, E>>

export const asyncAction = <R = void, M = void>(actionType: string) => {
	return Object.freeze({
		request: action(`${actionType}_REQUEST`, payload<RequestState>()),
		success: action(`${actionType}_SUCCESS`, payload<RequestState<R, M>>()),
		failure: action(`${actionType}_FAILURE`, payload<RequestState>()),
	})
}
