import { User, UserPasswords } from 'models/User'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'user'

const getUser = () => {
	return apiClient.get<User>(`${API_RESOURCE}`)
}

const updateUser = (changes: Partial<User>) => {
	return apiClient.patch<User>(`${API_RESOURCE}`, changes)
}

const updateUserPassword = (changes: UserPasswords) => {
	return apiClient.patch<User>(`${API_RESOURCE}`, changes)
}

export const userApi = { getUser, updateUser, updateUserPassword }
