import { useSelector } from 'react-redux'

import { propertiesSelectors } from 'modules/properties'

import { chatbotDetailSelectors } from './slice'

export const useChatbotDeletedProperty = () => {
	const chatbotProperties = useSelector(chatbotDetailSelectors.getChatbotProperties)
	const activePropertiesKeys = useSelector(propertiesSelectors.getPropertiesKeys)
	if (!chatbotProperties) return false

	return chatbotProperties.some((property) => !activePropertiesKeys.has(property))
}
