import moment, * as Moment from 'moment'
import { extendMoment } from 'moment-range'

import { Dictionary } from 'types'
import { MONTH_IN_DAYS, YEAR_IN_DAYS } from 'shared/models/Date'
import {
	MetricsAggregate,
	MetricsBucket,
	MetricsRequestBodyParams,
	MetricsType,
	StatisticsInterval,
} from 'shared/models/Statistics'
import { Tag } from 'shared/models/Tag'
import { getConvertedRange, getFilters, getFormattedNames } from 'modules/statistics'
import { StatisticsFilter } from 'modules/statisticsFilter'
import { DEFAULT_TAG_COLORS } from 'modules/tags'

import {
	FormattedTagStatisticsBuckets,
	TagsChartAggregateFilterState,
	TagsChartData,
	TagsChartItemData,
	TagsWithStatistics,
	TagWithMetrics,
} from './types'

const momentExt = extendMoment(Moment)

export const getRequestBodyParamsTags = (
	metric: { metricsType: MetricsType; interval?: StatisticsInterval },
	{ from, to, group }: StatisticsFilter,
	timezone: string,
	aggs: MetricsAggregate,
): MetricsRequestBodyParams => {
	const { interval, metricsType } = metric
	return {
		name: metricsType,
		options: {
			timezone,
			range: from && to ? getConvertedRange(from, to) : undefined,
			aggs: [
				{ ...aggs, interval, format: 'YYYY_MM_DD' },
				{ type: 'terms', field: 'tags' },
			],
			filters: getFilters(group),
			query: [
				{ field: 'tags', type: 'exists' },
				{ field: 'status', value: 'closed' },
			],
		},
	}
}

export const getTagsStatisticsSummaryData = (buckets: MetricsBucket[]): TagsWithStatistics | null => {
	if (!buckets) return null

	const convertedBuckets = buckets.map((bucket) => bucket.key.split('__')[1])
	const tagsKeys = new Set(convertedBuckets)
	let stats = {}

	tagsKeys.forEach((tag) => {
		let tagCount = 0
		buckets.forEach((bucket) => {
			if (bucket.key.split('__')[1] === tag) {
				tagCount += bucket.count
				stats = { ...stats, [tag]: tagCount }
			}
		})
	})
	return stats
}

export const mergeTagsWithMetrics = (
	statistics: null | TagsWithStatistics,
	count: number,
	tags: Tag[],
): TagWithMetrics[] => {
	return tags.map((tag) => {
		const percentage = statistics && statistics[tag.key] ? Math.round((statistics[tag.key] / count) * 100) : 0
		const statCount = statistics && statistics[tag.key] ? statistics[tag.key] : 0
		return { ...tag, count: statCount, percentage }
	})
}

export const formatBucketData = (buckets: MetricsBucket[], tagKeys: Set<string>) => {
	const formattedBuckets = buckets.map((bucket) => {
		const splitBucketKey = bucket.key.split('__')
		const tagKey = splitBucketKey[1]
		const date = splitBucketKey[0]

		return {
			key: tagKey,
			date,
			value: bucket.value,
		}
	})
	return formattedBuckets.filter((bucket) => tagKeys.has(bucket.key))
}

const getTagColorByKey = (tags: Dictionary<Tag>, tagKey: string): string => {
	return tags[tagKey].color ?? DEFAULT_TAG_COLORS.text
}

export const getTagsChartData = (
	formattedBuckets: FormattedTagStatisticsBuckets,
	filter: StatisticsFilter,
	interval: StatisticsInterval,
	tags: Dictionary<Tag>,
): TagsChartData[] => {
	const tagKeysArray = formattedBuckets.map((bucket) => bucket.key)
	const tagKeys = new Set(tagKeysArray)
	let tagsChartData: TagsChartData[] = []

	tagKeys.forEach((key) => {
		const bucketsWithKey = formattedBuckets.filter((bucket) => bucket.key === key)
		let keyWithStats: TagsChartItemData[] = []

		bucketsWithKey.forEach((bucket) => {
			keyWithStats = [...keyWithStats, { x: bucket.date, y: bucket.value }]
		})
		tagsChartData = [
			...tagsChartData,
			{ id: key, color: getTagColorByKey(tags, key), data: formatKeyDates(keyWithStats, filter, interval) },
		]
	})
	return tagsChartData
}

const formatKeyDates = (
	stats: TagsChartItemData[],
	filter: StatisticsFilter,
	interval: StatisticsInterval,
): TagsChartItemData[] => {
	const { from, to } = filter

	if (!from || !to) return []

	const datesArray = stats.map((stat) => stat.x)
	const uniqueDates = new Set(datesArray)
	const filteredRange = momentExt.range(from, to).snapTo(interval)
	const rangeDates = [...filteredRange.by(interval)]

	const dates = rangeDates.map((date) => date.format('YYYY_MM_DD'))

	const daysWithoutStats = dates.filter((date) => !uniqueDates.has(date))

	let formattedDates: TagsChartItemData[] = []

	daysWithoutStats.forEach((day) => {
		formattedDates = [...formattedDates, { x: day, y: 0 }]
	})

	uniqueDates.forEach((date) => {
		const statsWithDate = stats.filter((stat) => stat.x === date)
		let count = 0
		statsWithDate.forEach((stat) => {
			count += stat.y
		})
		formattedDates = [...formattedDates, { x: date, y: count }]
	})
	const sortedFormattedDates = formattedDates.sort((a, b) => {
		return a.x.localeCompare(b.x)
	})

	return sortedFormattedDates.map((date) => {
		const momentDate = moment(date.x, 'YYYY-MM-DD')
		const formattedNames = getFormattedNames(interval, momentDate)
		return { x: formattedNames.long, y: date.y }
	})
}

export const getAggregateInterval = (filter: StatisticsFilter, interval: StatisticsInterval): StatisticsInterval => {
	const from = moment(filter.from)
	const to = moment(filter.to)

	const diffInDays = to.diff(from, 'days')

	if (diffInDays > MONTH_IN_DAYS && diffInDays <= YEAR_IN_DAYS && interval === StatisticsInterval.Day) {
		return StatisticsInterval.Week
	}
	if (diffInDays > YEAR_IN_DAYS && interval !== StatisticsInterval.Month) {
		return StatisticsInterval.Month
	}
	return interval
}

export const getAggregateFilterState = (filter: StatisticsFilter): TagsChartAggregateFilterState => {
	const from = moment(filter.from)
	const to = moment(filter.to)

	const diffInDays = to.diff(from, 'days')

	return {
		isDayDisabled: diffInDays > MONTH_IN_DAYS,
		isWeekDisabled: diffInDays > YEAR_IN_DAYS,
	}
}
