import { useBreakpointValue } from '@chakra-ui/react'
import { mdiAutoFix } from '@mdi/js'

import { TranslationService as T } from 'shared/services'
import { routes } from 'configuration/routes'

import { Box } from 'shared/components/core/Box'

import SidebarMenuIcon from '../sidebarMenu/SidebarMenuIcon'
import SidebarMenuItem from '../sidebarMenu/SidebarMenuItem'
import SidebarMenuItemPopover from '../sidebarMenu/SidebarMenuItemPopover'

import SidebarAiAutomationsMenu from './SidebarAiAutomationsMenu'

const SidebarAiAutomationsMenuItem = () => {
	return (
		<SidebarMenuItem path={routes.aiAutomations.path} exactPath={false} isNew tooltip={T.translate('general.miraAi')}>
			<SidebarMenuIcon icon={mdiAutoFix} />
		</SidebarMenuItem>
	)
}

const SidebarAiAutomations = () => {
	const isMobile = useBreakpointValue({ base: true, lg: false })
	return (
		<Box data-testid="sidebar-ai-automations">
			{isMobile ? (
				<SidebarMenuItemPopover popoverContent={<SidebarAiAutomationsMenu />}>
					<SidebarMenuIcon icon={mdiAutoFix} />
				</SidebarMenuItemPopover>
			) : (
				<SidebarAiAutomationsMenuItem />
			)}
		</Box>
	)
}

export default SidebarAiAutomations
