import UAParser from 'ua-parser-js'

type BrowserCode = 'chrome' | 'firefox' | 'opera' | 'safari' | 'explorer' | 'edge' | 'others' | null
type PlatformCode = 'windows' | 'mac' | 'linux' | 'android' | 'others' | null

type ParsedUserAgentBrowser = UAParser.IBrowser & {
	code: BrowserCode
	visibleName: string
}

type ParsedUserAgentOs = UAParser.IOS & {
	code: PlatformCode
	visibleName: string
}

export type ParsedUserAgent = UAParser.IResult & {
	browser: ParsedUserAgentBrowser
	os: ParsedUserAgentOs
}

const getBrowserCode = (browser: UAParser.IBrowser): BrowserCode => {
	if (!browser || !browser.name) return null

	const browserName = browser.name.toLowerCase()
	if (browserName.includes('chrome')) return 'chrome'
	if (browserName.includes('firefox')) return 'firefox'
	if (browserName.includes('opera')) return 'opera'
	if (browserName.includes('safari')) return 'safari'
	if (browserName.includes('explorer')) return 'explorer'
	if (browserName.includes('edge')) return 'edge'
	return 'others'
}

const getPlatformCode = (platform: UAParser.IOS): PlatformCode => {
	if (!platform || !platform.name) return null

	const platformName = platform.name.toLowerCase()
	if (platformName.includes('windows')) return 'windows'
	if (platformName.includes('mac')) return 'mac'
	if (platformName.includes('linux')) return 'linux'
	if (platformName.includes('android') || platformName.includes('blackberry')) return 'android'
	return 'others'
}

const getBrowserName = (browser: UAParser.IBrowser): string => {
	if (!browser || !browser.name) {
		return ''
	}
	if (!browser.version) {
		return browser.name
	}
	return `${browser.name} ${browser.version}`
}

const getPlatformName = (platform: UAParser.IOS): string => {
	if (!platform || !platform.name) {
		return ''
	}
	if (!platform.version) {
		return platform.name
	}
	return `${platform.name} ${platform.version}`
}

export const parseUserAgent = (userAgent?: string | null): ParsedUserAgent => {
	// eslint-disable-next-line unicorn/prefer-default-parameters
	const UAString = userAgent ?? ''
	const parser = new UAParser()
	parser.setUA(UAString)
	const parsedResult = parser.getResult()
	const parsedBrowser = parser.getBrowser()
	const parsedOS = parser.getOS()

	return {
		...parsedResult,
		browser: {
			...parsedBrowser,
			code: getBrowserCode(parsedBrowser),
			visibleName: getBrowserName(parsedBrowser),
		},
		os: {
			...parsedOS,
			code: getPlatformCode(parsedOS),
			visibleName: getPlatformName(parsedOS),
		},
	}
}
