export const space = {
	'0': 0,
	'0.5': '0.25rem',
	'1': '0.5rem',
	'1.5': '0.75rem',
	'2': '1rem',
	'3': '1.5rem',
	'4': '2rem',
	'5': '2.5rem',
	'6': '3rem',
	'7': '4rem',
	'8': '4.5rem',
	'9': '5rem',
	'10': '6rem',
}
