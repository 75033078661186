import { SmartsuppWindow } from 'shared/types'
import { PackageName } from 'shared/models/Package'
import { ConfigurationService } from 'shared/services'
import { WidgetVariablesData } from 'modules/app'

declare let window: SmartsuppWindow

export interface SmartsuppWidgetApi {
	version: string
	exec: (name: string, ...args: unknown[]) => void
}

const supportedLangs = new Set(['cs', 'en', 'fr', 'it', 'pl'])

// https://en.wikipedia.org/wiki/ISO_3166-1
type AllowedCountriesType =
	| 'cz'
	| 'it'
	| 'de'
	| 'pl'
	| 'fr'
	| 'es'
	| 'hu'
	| 'nl'
	| 'sk'
	| 'ch'
	| 'be'
	| 'at'
	| 'gb'
	| 'us'
	| 'ie'
	| 'no'
	| 'se'
	| 'fi'
	| 'ee'
	| 'lv'
	| 'lt'
	| 'ro'
	| 'ca'
	| 'au'
	| 'nz'
	| 'pt'

const trialCountries: Set<AllowedCountriesType> = new Set([
	'cz',
	'it',
	'de',
	'pl',
	'fr',
	'es',
	'hu',
	'nl',
	'sk',
	'ch',
	'be',
	'at',
	'gb',
	'us',
	'ie',
	'no',
	'se',
	'fi',
	'ee',
	'lv',
	'lt',
	'ro',
	'ca',
	'au',
	'nz',
	'pt',
])

const mapCountryToBillingGroup: Record<string, string> = {
	cz: 'cs',
	it: 'it',
	de: 'de',
	pl: 'pl',
	fr: 'fr',
	es: 'es',
	hu: 'hu',
	nl: 'nl',
	sk: 'sk',
	ch: 'de',
	be: 'fr',
	at: 'de',
}

const mapCountryToTrialGroup: Record<string, string> = {
	cz: 'cs',
	sk: 'cs',
	de: 'de',
	at: 'de',
	fr: 'fr',
	pl: 'pl',
	es: 'es',
	it: 'it',
}

const mapCountryToDefaultGroup: Record<string, string> = {
	cz: 'cs',
	pl: 'pl',
	de: 'de',
	es: 'es',
	fr: 'fr',
	nl: 'nl',
	pt: 'pt',
	br: 'pt',
	sk: 'sk',
	it: 'it',
	hu: 'hu',
}

const getWidgetLang = () => {
	const { lang } = ConfigurationService.getData()
	return supportedLangs.has(lang) ? lang : 'en'
}

const getCountryCode = (): string | null => {
	// for development reasons
	const isEnvDevelopment = process.env.NODE_ENV === 'development'
	if (isEnvDevelopment) return 'cz'

	const configData = ConfigurationService.getData()
	return configData?.countryCode ?? null
}

const isCountryCodeAllowed = (countryCode: string | null) => {
	if (!countryCode) return false
	return trialCountries.has(countryCode as AllowedCountriesType)
}

const shouldUseTrialGroup = (packageName: PackageName) => packageName === PackageName.Trial

const getTrialGroup = () => {
	const { smartsuppWidgetGroupsTrial } = ConfigurationService.getAppEnvData()
	const countryCode = getCountryCode()
	if (!countryCode) return smartsuppWidgetGroupsTrial.en ?? null

	const groupCode = mapCountryToTrialGroup[countryCode]

	return smartsuppWidgetGroupsTrial[groupCode] ?? smartsuppWidgetGroupsTrial.en ?? null
}

const getBillingGroup = () => {
	const { smartsuppWidgetGroupsBilling } = ConfigurationService.getAppEnvData()
	const countryCode = getCountryCode()
	if (!countryCode) return smartsuppWidgetGroupsBilling.en ?? null

	const groupCode = mapCountryToBillingGroup[countryCode]

	return smartsuppWidgetGroupsBilling[groupCode] ?? smartsuppWidgetGroupsBilling.en ?? null
}

const CHAT_WIDGET = 'chat-widget'

const getProtocol = (): string => {
	return window.location.protocol.slice(0, -1)
}

const getCookieDomain = (): string => {
	return window.location.hostname.replace('app', '')
}

const getWidgetKeyQueryParam = () => {
	const queryParams = new URLSearchParams(window.location.search)
	return queryParams.get('widgetKey')
}

const getWidgetKey = (): string => {
	const { smartsuppWidgetKey } = ConfigurationService.getAppEnvData()
	const { stagingMode } = ConfigurationService.getData()
	const queryParam = getWidgetKeyQueryParam()

	// override for testing reasons
	if (stagingMode && queryParam) return queryParam

	return smartsuppWidgetKey
}

const getDefaultGroup = (): string => {
	const { smartsuppWidgetGroups } = ConfigurationService.getAppEnvData()
	const { lang } = ConfigurationService.getData()
	const countryCode = getCountryCode()

	const countryCodeLang =
		countryCode && mapCountryToDefaultGroup[countryCode] ? mapCountryToDefaultGroup[countryCode] : lang

	return smartsuppWidgetGroups[countryCodeLang] ?? smartsuppWidgetGroups.en ?? null
}

export const renderChatWidget = async (data: WidgetVariablesData) => {
	if (!window.smartsupp) return

	const { packageName, userId, userFullname, userRole, userEmail, accountId, sourcePlatform } = data

	const countryCode = getCountryCode()
	const shouldHideWidget =
		(packageName === PackageName.Trial && !isCountryCodeAllowed(countryCode)) || packageName === PackageName.Free

	const widget = await window.smartsupp.createWidget(CHAT_WIDGET, {
		key: getWidgetKey(),
		protocol: getProtocol(),
		cookieDomain: getCookieDomain(),
		cookiePath: '/app',
		cookiePrefix: 'app.',
		requireLogin: false,
		hideOfflineChat: false,
		hideWidget: shouldHideWidget,
		onStartup: (widgetApi: SmartsuppWidgetApi) => {
			widgetApi.exec('language', getWidgetLang())
			widgetApi.exec('group', shouldUseTrialGroup(packageName) ? getTrialGroup() : getDefaultGroup())
			widgetApi.exec('name', `${userFullname} (${accountId})`)
			widgetApi.exec('email', userEmail)
			widgetApi.exec('variables', {
				account: accountId,
				package: packageName,
				userId,
				userEmail,
				userName: userFullname,
				userRole,
				sourcePlatform,
			})
		},
	})
	widget.render()
}

export const openChatWidget = () => {
	if (window.smartsupp) {
		window.smartsupp.getChat(CHAT_WIDGET)?.exec('chat:open')
	}
}

export const hideChatWidget = () => {
	if (window.smartsupp) {
		window.smartsupp.getChat(CHAT_WIDGET)?.exec('chat:hide')
	}
}

export const showChatWidgetBilling = () => {
	const smartsuppWidget = window?.smartsupp?.getChat(CHAT_WIDGET)
	if (!smartsuppWidget) return

	const countryCode = getCountryCode()
	if (!countryCode || !isCountryCodeAllowed(countryCode)) return

	smartsuppWidget.exec('chat:show')

	// set priority billing group in billing
	const group = getBillingGroup()
	if (group) {
		smartsuppWidget.exec('group', group)
	}
}

export const hideChatWidgetBilling = (packageName: PackageName) => {
	const smartsuppWidget = window?.smartsupp?.getChat(CHAT_WIDGET)
	if (!smartsuppWidget) return

	// set default group, when user goes from billing
	smartsuppWidget.exec('group', shouldUseTrialGroup(packageName) ? getTrialGroup() : getDefaultGroup())

	// do not hide widget for Trial & Paid accounts
	if (packageName !== PackageName.Free) return

	smartsuppWidget.exec('chat:hide')
}
