import { ConfigurationService } from 'shared/services'

const absoluteUrlRegex = /^(https?:)?\/\/.+/
const dataUrlRegex = /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[\s\w!$%&'()*+,./:;=?@~-]*\s*$/i

export const DEFAULT_AVATAR = 'user.svg'
export const DEFAULT_IMAGE_SIZE = 40

export const getAvatarUrl = (avatar?: string | null, imageSize = DEFAULT_IMAGE_SIZE): string => {
	const configData = ConfigurationService.getData()
	if (!configData) return ''

	if (!avatar) {
		return `${configData.distUrl}/assets/icons/svg/${DEFAULT_AVATAR}`
	}
	if (dataUrlRegex.test(avatar)) {
		return avatar
	}
	if (absoluteUrlRegex.test(avatar)) {
		const params = imageSize ? `?size=${imageSize}` : ''
		return `${avatar}${params}`
	}
	if (configData.filesBaseUrl) {
		return `${configData.filesBaseUrl}/agents${avatar}`
	}
	return `${configData.distUrl}/assets/icons/svg/${DEFAULT_AVATAR}`
}
