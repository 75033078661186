import { Store, Unsubscribe } from 'redux'

export type StoreObserverSelector<State> = (state: State) => unknown
export type StoreObserverFn = (currentValue: unknown, previousValue: unknown) => void
export type StoreObserverCompareFn = (a: unknown, b: unknown) => boolean

interface StoreObserverReturnType<State> {
	on: (selector: StoreObserverSelector<State>, fn: StoreObserverFn, compare?: StoreObserverCompareFn) => Unsubscribe
}

const defaultCompare: StoreObserverCompareFn = (a, b) => a === b

export const createStoreObserver = <State>(store: Store): StoreObserverReturnType<State> => {
	return {
		on: (selector, fn, compare) => {
			const compareFn = compare ?? defaultCompare
			let currentValue: unknown

			const storeListener = () => {
				const previousValue = currentValue
				currentValue = selector(store.getState())
				if (!compareFn(previousValue, currentValue)) {
					fn(currentValue, previousValue)
				}
			}

			return store.subscribe(storeListener)
		},
	}
}
