import { OnboardingResponse, OnboardingStepCode } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'onboarding'

const finishStep = (code: OnboardingStepCode, finished: boolean) => {
	return apiClient.post<OnboardingResponse>(`${API_RESOURCE}/step`, { code, finished })
}

const fetchOnboardingData = () => {
	return apiClient.get<OnboardingResponse>(`${API_RESOURCE}`)
}

export const onboardingApi = { finishStep, fetchOnboardingData }
