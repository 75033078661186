import { ReactNode } from 'react'

import { Flex } from 'core'

type Props = {
	children: ReactNode
}

export const StatHeader = ({ children }: Props) => {
	return (
		<Flex w="full" justifyContent="space-between">
			{children}
		</Flex>
	)
}
