import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { ConversationsApi, ConversationsStatsSummary, DataValidityObject } from 'models/ConversationsStatistics'
import { AppThunkAction, DashboardState } from 'types'
import { smartHubSelectors } from 'modules/smartHub'

import { conversationStatisticsApi } from './api'
import { transformChatbotConversationsSummaryStats } from './utils'

export type ConversationsStatisticsRootState = Pick<DashboardState, 'conversationsStatistics'>
export type ConversationsStatisticsState = typeof initialState

export const initialState = {
	summary: null as null | ConversationsStatsSummary,
	lastUpdated: null as null | string,
	validFrom: null as null | DataValidityObject,
	isFetching: false,
}

export const fetchConversationsStatisticsThunk = createAsyncThunk(
	'chatbotStatistics/FETCH_CONVERSATIONS',
	(data: ConversationsApi.StatsQuery) => {
		return conversationStatisticsApi.getConversationsStatistics(data)
	},
)

export const fetchConversationsStatisticsSmartHub = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()

	const range = smartHubSelectors.getChatbotStatisticsRange(state)

	dispatch(fetchConversationsStatisticsThunk({ range }))
}

const conversationsStatisticsSlice = createSlice({
	name: 'conversationsStatistics',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchConversationsStatisticsThunk.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchConversationsStatisticsThunk.fulfilled, (state, { payload }) => {
				state.isFetching = false
				const { summary, lastUpdate, validFrom } = payload
				if (summary) {
					state.summary = summary
				}
				if (lastUpdate) {
					state.lastUpdated = lastUpdate
				}
				if (validFrom) {
					state.validFrom = validFrom
				}
			})
			.addCase(fetchConversationsStatisticsThunk.rejected, (state) => {
				state.isFetching = false
			})
	},
})

const getSummary = (state: ConversationsStatisticsRootState) => state.conversationsStatistics.summary
const getLastUpdated = (state: ConversationsStatisticsRootState) => state.conversationsStatistics.lastUpdated
const getValidFrom = (state: ConversationsStatisticsRootState) => state.conversationsStatistics.validFrom
const getIsFetching = (state: ConversationsStatisticsRootState) => state.conversationsStatistics.isFetching

const getConversationStatistics = createSelector([getSummary], (summary): ConversationsStatsSummary => {
	return transformChatbotConversationsSummaryStats(summary)
})

const { reducer } = conversationsStatisticsSlice
export default reducer

export const conversationsStatisticsSelectors = {
	getConversationStatistics,
	getSummary,
	getLastUpdated,
	getIsFetching,
	getValidFrom,
}
