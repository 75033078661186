import { ApnsSdkData, DeleteReponse, FirebaseSdkData, Platform } from 'shared/models/MobileSdk'
import { apiClient } from 'shared/utils'

const API_IOS_RESOURCE = 'pushky/sdk/ios/config'
const API_ANDROID_RESOURCE = 'pushky/sdk/android/config'

const getAndroidConfig = () => {
	return apiClient.get<FirebaseSdkData>(`${API_ANDROID_RESOURCE}`)
}

const fetchAndroidConfig = (data: FormData) => {
	return apiClient.postForm<FirebaseSdkData>(`${API_ANDROID_RESOURCE}`, data)
}

const getIosConfig = () => {
	return apiClient.get<ApnsSdkData>(`${API_IOS_RESOURCE}`)
}

const fetchIosConfig = (data: FormData) => {
	return apiClient.postForm<ApnsSdkData>(`${API_IOS_RESOURCE}`, data)
}

const deleteConfig = (platform: Platform) => {
	return apiClient.delete<DeleteReponse>(`${platform === Platform.ios ? API_IOS_RESOURCE : API_ANDROID_RESOURCE}`)
}

export const mobileSdkApi = { getAndroidConfig, getIosConfig, fetchAndroidConfig, fetchIosConfig, deleteConfig }
