import { ReactNode } from 'react'

import { Box } from 'core'

type Props = {
	children: ReactNode
	width?: string
}

const DEFAULT_WIDTH = '200px'

const PopoverMenu = ({ children, width = DEFAULT_WIDTH }: Props) => {
	return (
		<Box width={width} margin="0 -9px">
			{children}
		</Box>
	)
}

export default PopoverMenu
