export const DEFAULT_CHATBOT_ACTIVE_COUNT = 0
export const MAX_CHATBOT_NAME_LENGTH = 64
export const MAX_CHARACTERS_QUESTION = 35
export const MAX_CHARACTER_RETRIEVE_PROPERTY_QUESTION = 500
export const MAX_CHARACTERS_ANSWER = 500
export const MAX_CHATBOT_REPLIES = 5
export const MAX_CHATBOT_LEVEL = 10
export const MAX_GDPR_CHARACTERS_TEXT = 500

export const CHATBOT_TEMPLATE_PREFIX = 'chatbot'

export const CHATBOT_LIST_PUBLISH_COLUMN_W = '150px'
export const CHATBOT_LIST_STAT_FIRST_COLUMN_W = '125px'
export const CHATBOT_LIST_STAT_COLUMN_W = { base: '125px', lg: '150px', xl: '190px' }

export const chatbotTestingAttributes = {
	chatbotList: {
		chatbotCard: 'chatbot-card',
		chatbotAddCard: 'chatbot-add-new-bot',
		chatbotCardSwitch: 'chatbot-card-switch',
		chatbotCardSwitchText: 'chatbot-card-switch-text',
		chatbotCardDropdown: {
			dropdown: 'chatbot-card-dropdown',
			remove: 'chatbot-card-dropdown-remove',
			edit: 'chatbot-card-dropdown-edit',
			duplicate: 'chatbot-card-dropdown-duplicate',
		},
		chatbotStatisticsButton: 'chatbot-list-statistics-button',
		chatbotGarageNewBot: 'chatbot-garage-new-bot',
		chatbotGarageBuildFromScratch: 'chatbot-garage-build-from-scratch',
		automationsCounter: 'automations-allowed-items-counter',
		chatbotConversationsCounter: 'chatbot-conversations-counter',
	},
	chatbotListDrawer: {
		chatbotAddDrawerClose: 'chatbot-add-drawer-close',
		chatbotAddDrawerNewBot: 'chatbot-add-drawer-new-bot',
	},
	chatbotBuilder: {
		title: 'chatbot-builder-title',
		publish: 'chatbot-builder-publish',
		preview: 'chatbot-builder-preview',
		dropdown: {
			togglerAttr: 'chatbot-builder-dropdown-toggler',
			remove: 'chatbot-builder-dropdown-remove',
		},
		activateSwitch: 'chatbot-builder-activate-switch',
		activateSwitchText: 'chatbot-builder-activate-switch-text',
		triggerNode: 'chatbot-builder-trigger-node-card',
		messageNode: 'chatbot-builder-chat-message-card',
		nodeTitle: 'chatbot-builder-node-title',
		nodeContent: 'chatbot-builder-node-content',
		nodeMenu: {
			button: 'chatbot-builder-node-menu-button',
			moveNode: 'chatbot-builder-node-menu-moveNode',
			deleteNode: 'chatbot-builder-node-menu-deleteNode',
			copyNode: 'chatbot-builder-node-menu-copyNode',
		},
		editButton: 'chatbot-builder-edit-node-button',
		form: {
			messageField: 'chatbot-builder-form-message-text-area',
			messageFieldCounter: 'chatbot-builder-form-message-counter',
			closeForm: 'chatbot-builder-form-close-drawer',
			replyPreview: 'chatbot-builder-form-reply-preview',
			replyInput: 'chatbot-builder-form-reply-input',
			replyInputCounter: 'chatbot-builder-form-reply-counter',
			replyDelete: 'chatbot-builder-form-reply-delete',
			replyCopy: 'chatbot-builder-form-reply-copy',
			replyMove: 'chatbot-builder-form-reply-move',
			addQuickReply: 'chatbot-builder-form-add-quick-reply',
			selectGroup: 'chatbot-builder-form-select-group',
			createGroup: 'chatbot-builder-form-create-group',
			createChatbot: 'chatbot-builder-form-create-chatbot',
			openSelectedChatbot: 'chatbot-builder-form-open-selected-chatbot',
			submit: 'chatbot-builder-form-submit',
			nodeDelete: 'chatbot-builder-form-node-delete',
			triggerSave: 'chatbot-builder-trigger-form-save',
			url: 'chatbot-builder-trigger-url',
			title: 'chatbot-builder-trigger-title',
			select: 'chatbot-builder-trigger-select',
			selectAny: 'chatbot-builder-trigger-select-any',
			selectIs: 'chatbot-builder-trigger-select-is',
			selectIsNot: 'chatbot-builder-trigger-select-isNot',
			selectContains: 'chatbot-builder-trigger-select-contains',
			selectNotContains: 'chatbot-builder-trigger-select-notContains',
			textInput: 'chatbot-builder-trigger-select-textInput',
			errorMessage: 'chatbot-builder-trigger-error-message',
			drawerTitle: 'chatbot-builder-drawer-title-input',
			leadgenUpsell: 'chatbot-builder-leadgen-upsell-button',
			retrieveProperty: {
				addFieldMenu: 'chatbot-builder-retrieve-property-add-field-menu',
				addFieldMenuItem: 'chatbot-builder-retrieve-property-add-field-menu-item',
				field: 'chatbot-builder-retrieve-property-field',
				fieldButton: 'chatbot-builder-retrieve-property-field-button',
				fieldDelete: 'chatbot-builder-retrieve-property-field-delete',
				fieldMove: 'chatbot-builder-retrieve-property-field-move',
				fieldQuestion: 'chatbot-builder-retrieve-property-field-question',
				fieldTitle: 'chatbot-builder-retrieve-property-field-title',
				createPropertyButton: 'properties-create-new',
				addPropertySelect: 'chatbot-builder-add-property-select',
				property: 'chatbot-builder-property',
			},
			actions: {
				chatAddTagButton: 'chatbot-builder-action-tag-open',
				contactAddTagButton: 'chatbot-builder-action-contact-tag-open',
				menuItemChatAddTag: 'chatbot-builder-add-action-chat-add-tags',
				menuItemContactAddTag: 'chatbot-builder-add-action-contact-add-tags',
				addActionMenu: 'chatbot-builder-add-action',
				addActionMenuList: 'chatbot-builder-add-action-list',
				createTagButton: 'tags-create-new',
			},
			gdprAction: {
				switchBtn: 'chatbot-builder-gdprAction-switch',
				input: 'chatbot-builder-gdprAction-input',
				inputError: 'chatbot-builder-gdprAction-error',
				inputMessage: 'chatbot-builder-gdprAction-inputMessage',
				inputMessageError: 'chatbot-builder-gdprAction-inputMessage-error',
			},
		},
		drawer: {
			header: 'chatbot-builder-drawer-header',
			tag: 'chatbot-builder-drawer-tag',
		},
		moveNodeModal: {
			select: 'chatbot-builder-move-node-select',
			modalContent: 'chatbot-builder-move-node-modal',
			confirm: 'chatbot-builder-move-node-confirm',
			cancel: 'chatbot-builder-move-node-cancel',
		},
		copyNodeModal: {
			modalContent: 'chatbot-builder-copy-node-modal',
			confirm: 'chatbot-builder-copy-node-confirm',
			cancel: 'chatbot-builder-copy-node-cancel',
			select: 'chatbot-builder-copy-node-select',
		},
		popover: {
			closePublishPopover: 'chatbot-builder-publish-popover-close',
			content: 'chatbot-builder-publish-popover-content',
		},
	},
}
