import {
	Chatbot,
	ChatbotActionMessageData,
	ChatbotActionType,
	ChatbotInteraction,
	ChatbotReply,
	ChatbotReplyType,
} from 'models'
import { createChatbotInteraction, findInteractionActionByType, isInteractionById } from 'modules/chatbotDetail'

import { ChatbotForWizard, ChatbotQuestionNode, ChatbotQuestionNodeInteraction } from './types'

export const mapFirstInteractionToQuestionNodes = (
	interactions: ChatbotInteraction[],
): ChatbotQuestionNodeInteraction[] => {
	const messageAction = findInteractionActionByType(interactions[0], ChatbotActionType.Message)
	if (!messageAction) return []

	const messageActionData = messageAction.data as ChatbotActionMessageData
	return messageActionData.replies
		.map((reply: ChatbotReply) => {
			const interactionsIndex = interactions.findIndex(isInteractionById(reply.nextInteractionId ?? ''))

			const actionWithText = findInteractionActionByType(interactions[interactionsIndex], [ChatbotActionType.Message])
			const data = actionWithText?.data as ChatbotActionMessageData

			return {
				id: reply.nextInteractionId ?? '',
				question: reply.text,
				answer: data ? data.text : '',
			}
		})
		.filter((item) => item.answer !== '')
}

export const mapChatbotToQuestionNode = (chatbot: ChatbotForWizard): ChatbotQuestionNode => {
	const actionWithText = findInteractionActionByType(chatbot.interactions[0], [ChatbotActionType.Message])
	const data = actionWithText?.data as ChatbotActionMessageData

	return {
		id: chatbot.id,
		isActive: chatbot.isActive,
		textMessage: data.text ?? '',
		interactions: mapFirstInteractionToQuestionNodes(chatbot.interactions),
	}
}

export const mapQuestionNodesToInteractions = ({
	interactions,
	questionNodes,
	welcomeText,
}: {
	interactions: Chatbot['interactions']
	questionNodes: ChatbotQuestionNodeInteraction[]
	welcomeText: string
}): ChatbotInteraction[] => {
	const interactionIds: string[] = []
	const replies = questionNodes.map((node) => {
		const interactionId = node.id
		interactionIds.push(interactionId)
		return { nextInteractionId: interactionId, text: node.question, type: ChatbotReplyType.Text }
	})

	const firstInteraction = createChatbotInteraction({
		id: interactions[0].id,
		text: welcomeText,
		replies,
	})

	const newInteractions = questionNodes.map((node, index) => {
		return createChatbotInteraction({ id: interactionIds[index], replies: [], text: node.answer })
	})
	return [firstInteraction, ...newInteractions]
}

// Testing utils
export const getNodeText = (chatbot: Chatbot) => {
	const messageAction = findInteractionActionByType(chatbot.interactions[0], ChatbotActionType.Message)
	if (!messageAction) return ''
	const data = messageAction.data as ChatbotActionMessageData
	return data.text
}
