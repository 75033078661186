import {
	BillingInfo,
	BillingInfoUpdateRequest,
	StripeCustomer,
	StripeCustomerPortal,
	StripeCustomerPortalRequestData,
	ValidationVatNumberResponse,
} from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'billing'
const API_RESOURCE_VAT = 'vat'
const API_STRIPE_RESOURCE_CUSTOMER = 'billing/customer'

const fetchBillingInfo = () => {
	return apiClient.get<BillingInfo>(`${API_RESOURCE}`)
}

const fetchStripeCustomer = () => {
	return apiClient.get<StripeCustomer>(`${API_STRIPE_RESOURCE_CUSTOMER}`)
}

const fetchStripeCustomerPortalLink = (data: StripeCustomerPortalRequestData) => {
	return apiClient.post<StripeCustomerPortal>(`${API_STRIPE_RESOURCE_CUSTOMER}/portal`, data)
}

const updateStripeCustomer = (data: Omit<StripeCustomer, 'currency'>) => {
	return apiClient.post<StripeCustomer>(`${API_STRIPE_RESOURCE_CUSTOMER}`, data)
}

const updateBillingInfo = (data: BillingInfoUpdateRequest) => {
	return apiClient.post<BillingInfo>(`${API_RESOURCE}`, data)
}

const validateVatNumber = (vatNumber: string) => {
	return apiClient.get<ValidationVatNumberResponse>(`${API_RESOURCE_VAT}/validate?vatNumber=${vatNumber}`)
}

export const billingInfoApi = {
	fetchBillingInfo,
	updateBillingInfo,
	validateVatNumber,
	fetchStripeCustomer,
	updateStripeCustomer,
	fetchStripeCustomerPortalLink,
}
