import { HTMLProps, ReactNode } from 'react'

import { Box, Text, TooltipProps } from 'core'

import IconHelpTooltip from '../../IconHelpTooltip'

type Props = {
	children?: ReactNode
	hint?: ReactNode
	hintPlacement?: TooltipProps['placement']
	required?: boolean
} & Pick<HTMLProps<HTMLLabelElement>, 'htmlFor'>

const Label = ({ children, hint, hintPlacement = 'top-end', required, ...rest }: Props) => {
	return (
		<Box as="label" display="inline-flex" alignItems="center" mb={0.5} {...rest}>
			<Text
				as="span"
				fontWeight="bold"
				{...(required && {
					_after: {
						// Nonbreaking space for cases when label occupies two lines and "*" marker may end up on the second line alone
						content: '" *"',
						color: 'red.500',
					},
				})}
			>
				{children}
			</Text>
			{hint && <IconHelpTooltip content={hint} placement={hintPlacement} />}
		</Box>
	)
}

export default Label
