import { ReactNode } from 'react'

import { Flex, FlexProps } from 'core'

type Props = {
	children: ReactNode
	onClick?: () => void
} & FlexProps

const PopoverMenuItem = ({ children, onClick, ...rest }: Props) => {
	return (
		<Flex
			p={0}
			_hover={{
				bg: 'blue.100',
			}}
			sx={{
				a: {
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					color: 'gray.700',
					paddingLeft: 1.5,
					paddingRight: 3,
					whiteSpace: 'normal',
					':hover': {
						color: 'blue.600',
						textDecoration: 'none',
					},
				},
			}}
			onClick={onClick}
			{...rest}
		>
			{children}
		</Flex>
	)
}

export default PopoverMenuItem
