export enum AutoMessageStatisticsDataType {
	Triggered = 'triggered',
	IncomingConversations = 'incomingConversations',
}

interface TransformedAutoMessageStat {
	name: AutoMessageStatisticsDataType
	value: number
}

export type TransformedAutoMessageStatistics = TransformedAutoMessageStat[]

interface TransformedAutoMessageSummaryStat {
	value: number
	trend: number
}

export type TransformedAutoMessageSummaryStatistics = Record<
	AutoMessageStatisticsDataType,
	TransformedAutoMessageSummaryStat
>

export interface TransformedSingleAutoMessageSummaryStats {
	name: AutoMessageStatisticsDataType
	value: number
	trendPercentage: number
}

export const DEFAULT_AUTO_MESSAGE_STATISTICS_ITEMS = [
	AutoMessageStatisticsDataType.Triggered,
	AutoMessageStatisticsDataType.IncomingConversations,
]

export type ChartItemData = {
	x: string
	y: number
}

export type ChartData = {
	id: AutoMessageStatisticsDataType
	label: string
	color: string
	data: ChartItemData[]
}
