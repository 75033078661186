import { AppLang } from 'shared/types'

export enum AccordionLocation {
	Onboarding = 'onboarding',
	Dashboard = 'dashboard',
	Settings = 'settings',
}

export enum PanelName {
	Code = 'code',
	Gtm = 'gtm',
	Epages = 'epages',
	PrestaShop = 'prestashop',
	Shoper = 'shoper',
	Shopify = 'shopify',
	Shoprenter = 'shoprenter',
	Shoptet = 'shoptet',
	Shopware = 'shopware',
	WordPress = 'wordpress',
	OtherPlugins = 'plugins',
	ChatKey = 'key',
}

export enum OtherPlugins {
	OpenCart = 'opencart',
	Webnode = 'webnode',
	Jimdo = 'jimdo',
	Magento = 'magento',
	Drupal = 'drupal',
}

export type Plugin = {
	name: PanelName
	title: string
	link: string
	steps: string[]
	icon: string
}

export type VideoIdPerLang = Record<AppLang, string | null>
