import { ChannelType } from 'models'
import {
	CreateSystemMessageParams,
	DashboardContentEventType,
	MessageContent,
	MessageEventSystem,
	MessageSubType,
	MessageType,
} from 'shared/models/Message'

const getContent = (params: CreateSystemMessageParams) => {
	switch (params.type) {
		case DashboardContentEventType.VisitorChangeUrl: {
			return {
				type: DashboardContentEventType.VisitorChangeUrl,
				data: { url: params.data.pageUrl, title: params.data.pageTitle },
			}
		}

		case DashboardContentEventType.VisitorDisconnect: {
			return {
				type: DashboardContentEventType.VisitorDisconnect,
				data: {},
			}
		}

		case DashboardContentEventType.VisitorConnect: {
			return {
				type: DashboardContentEventType.VisitorConnect,
				data: {},
			}
		}

		default: {
			return {
				type: DashboardContentEventType.Unknown,
				data: {},
			}
		}
	}
}

export const createSystemMessage = (params: CreateSystemMessageParams): MessageEventSystem => {
	const channel = { type: ChannelType.Default, id: null }

	return {
		agentId: null,
		attachments: [],
		channel,
		chatId: params.chatId,
		deliveredAt: null,
		deliveryFailReason: null,
		deliveryStatus: null,
		deliveryTo: null,
		groupId: null,
		responseTime: null,
		tags: null,
		trigger: null,
		id: `${Date.now()}`,
		type: MessageType.Event,
		subType: MessageSubType.System,
		visitorId: `${params.id}`,
		content: getContent(params) as unknown as MessageContent.Event,
		createdAt: new Date().toISOString(),
	}
}
