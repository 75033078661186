import { PackageFeature, PackageInfoFeatures } from 'models'

/**
 * Checks whether feature is enabled for a user.
 * @param features
 * @param feature
 */
export const isFeatureEnabled = (features: PackageInfoFeatures, feature: PackageFeature): boolean => {
	return features && !!features[feature]
}
