export const DEFAULT_STATISTICS_VALUE = 0

export const smartHubTestingAttributes = {
	statistics: {
		statistics: 'smarthub-statistics',
		statisticsCard: 'smarthub-statistics-card',
		statisticsContent: 'smarthub-statistics-content',
		statisticsItem: 'smarthub-statistics-item',
		filter: {
			button: 'smarthub-statistics-dropdown-toggler',
			filterItem: 'smarthub-statistics-filter',
		},
	},
	accountReview: {
		button: 'smarthub-account-review-button',
	},
	academy: {
		carousel: 'smarthub-academy-carousel',
	},
	basicProgress: {
		wrapper: 'smarthub-progress',
		header: 'smarthub-progress-header',
		content: 'smarthub-progress-content',
		skeleton: 'smarthub-progress-skeleton',
		headerSkeleton: 'smarthub-progress-header-skeleton',
	},
	supportProgress: {
		wrapper: 'smarthub-support-progress',
		header: 'smarthub-support-progress-header',
		content: 'smarthub-support-progress-content',
		skeleton: 'smarthub-support-progress-skeleton',
		headerSkeleton: 'smarthub-support-progress-header-skeleton',
	},
	salesProgress: {
		wrapper: 'smarthub-sales-progress',
		header: 'smarthub-sales-progress-header',
		content: 'smarthub-sales-progress-content',
		skeleton: 'smarthub-sales-progress-skeleton',
		headerSkeleton: 'smarthub-sales-progress-header-skeleton',
	},
	welcome: {
		agentWelcome: 'smarthub-agent-welcome',
	},
	installBanner: {
		installButton: 'smarthub-install-button',
	},
	demoBanner: {
		demoButton: 'smarthub-demo-button',
		hideDemoButton: 'smarthub-hide-demo-button',
	},
	webinarBanner: {
		webinarButton: 'smarthub-webinar-button',
		hideWebinarButton: 'smarthub-hide-webinar-button',
	},
	aiBanner: {
		aiBanner: 'smarthub-ai-banner',
		createAiButton: 'smarthub-ai-banner-create-button',
		hideAiButton: 'smarthub-ai-banner-hide-button',
	},
}
