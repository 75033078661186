import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useScript } from 'shared/hooks/useScript'
import { ConfigurationService } from 'shared/services/ConfigurationService'
import { renderChatWidget } from 'utils/renderChatWidget'
import { appSelectors, setIsWidgetRendered } from 'modules/app'

export const ChatWidget = () => {
	const dispatch = useDispatch()
	const data = useSelector(appSelectors.getWidgetVariablesData)
	const isRendered = useSelector(appSelectors.isWidgetRendered)

	const { smartsuppWidgetBaseUrl } = ConfigurationService.getAppEnvData()

	const status = useScript(`${smartsuppWidgetBaseUrl}/loader.js`)

	useEffect(() => {
		if (status !== 'ready' || isRendered) return
		data && renderChatWidget(data)
		dispatch(setIsWidgetRendered(true))
	}, [status, data, isRendered, dispatch])

	return null
}
