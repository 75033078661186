import {
	createAsyncThunk,
	createEntityAdapter,
	createSelector,
	createSlice,
	PayloadAction,
	SerializedError,
} from '@reduxjs/toolkit'
import WebsocketAgentClient from '@smartsupp/websocket-client-agent'

import { Chat, Group, GroupedAgents, UpdateAgentParams, UserRole } from 'models'
import { ApiError } from 'shared/types'
import { AppThunkAction, DashboardState, Dictionary } from 'types'
import {
	Agent,
	AgentEvents,
	AgentStatus,
	ApiAgent,
	AppAgent,
	AppAgentStatus,
	AppAgentWithGroups,
} from 'shared/models/Agent'
import { Visitor } from 'shared/models/Visitor'
import { normalize, setFavicon } from 'utils'
import { DEFAULT_GROUP, groupsSelectors, sortGroupByName } from 'modules/groups'
import { packageSelectors } from 'modules/package'
import { userSelectors } from 'modules/user'

import { updateAgentFromWebsocket } from './actions'
import { agentsApi } from './api'
import { transformToAppAgent } from './utils'

export type AgentsRootState = Pick<DashboardState, 'agents'>
export type AgentsState = typeof initialState

const agentsAdapter = createEntityAdapter<ApiAgent>()

export const initialState = agentsAdapter.getInitialState({
	agentStatuses: {} as Dictionary<AppAgentStatus>,
	isFetchingList: false,
	isFetchingDetail: false,
	isOpenAgentsList: false,
	isPendingUpdate: false,
	isPendingDelete: false,
	isInviteModalOpen: false,
	error: null as null | SerializedError,
	filterValue: '',
})

export const fetchAgents = createAsyncThunk('agents/FETCH', async () => {
	return agentsApi.getAgents()
})

export const fetchAgent = createAsyncThunk('agents/FETCH_SINGLE', async (agentId: number) => {
	return agentsApi.getAgent(agentId)
})

export const updateAgent = createAsyncThunk<ApiAgent, UpdateAgentParams, { rejectValue: ApiError }>(
	'agents/UPDATE',
	async ({ agentId, changes }, { rejectWithValue }) => {
		try {
			return await agentsApi.updateAgent(agentId, changes)
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

export const setActiveAgent = createAsyncThunk(
	'agents/SET_ACTIVE',
	async (args: { agent: ApiAgent; active: boolean }) => {
		const { agent, active } = args
		return agentsApi.setActiveAgent(agent.id, active)
	},
)

export const deleteAgent = createAsyncThunk('agents/DELETE', async (agentId: number) => {
	return agentsApi.deleteAgent(agentId)
})

export const updateAgentByUser =
	(changes: Partial<ApiAgent>): AppThunkAction =>
	(dispatch, getState) => {
		const userId = userSelectors.getUserId(getState())
		if (userId) {
			dispatch(actions.updateAgentByUser({ userId, changes }))
		}
	}

/**
 * Websocket events
 */
export const onAgentConnected =
	(data: AgentEvents.AgentConnected): AppThunkAction =>
	(dispatch) => {
		dispatch(actions.connectAgent(data))
	}

export const onAgentDisconnected =
	(data: AgentEvents.AgentDisconnected): AppThunkAction =>
	(dispatch) => {
		dispatch(actions.disconnectAgent(data))
	}

export const onAgentUpdated =
	(data: AgentEvents.AgentUpdated): AppThunkAction =>
	(dispatch, getState) => {
		const userId = userSelectors.getUserId(getState())
		dispatch(updateAgentFromWebsocket(data))

		// Change favicon if current user's status was updated
		if (data.id === userId && data.changes.status) {
			setFavicon(data.changes.status)
		}
	}

const agentsSlice = createSlice({
	name: 'agents',
	initialState,
	reducers: {
		connectAgent: (state, { payload }: PayloadAction<AgentEvents.AgentConnected>) => {
			const id = Number(payload.id)
			const { connectedAt, status } = payload
			state.agentStatuses[id] = { id, connectedAt, status }
		},
		disconnectAgent: (state, { payload }: PayloadAction<AgentEvents.AgentDisconnected>) => {
			const id = Number(payload.id)
			state.agentStatuses[id] = { id, connectedAt: null, status: AgentStatus.Offline }
		},
		updateAgentByUser: (state, { payload }: PayloadAction<{ userId: string; changes: Partial<ApiAgent> }>) => {
			agentsAdapter.updateOne(state, { id: Number(payload.userId), changes: payload.changes })
		},
		inviteModalOpen(state) {
			state.isInviteModalOpen = true
		},
		inviteModalClose(state) {
			state.isInviteModalOpen = false
		},
		setFilterValue(state, { payload }: PayloadAction<string>) {
			state.filterValue = payload
		},
		resetFilterValue(state) {
			state.filterValue = ''
		},
		initializeAgents: (state, { payload }: PayloadAction<WebsocketAgentClient.ConnectedData>) => {
			const connectedAgents: Agent[] = payload.account.agents
			const agentStatuses = connectedAgents.map((a) => ({
				id: Number(a.id),
				connectedAt: a.connectedAt,
				status: a.status,
			}))
			state.agentStatuses = normalize('id', agentStatuses)
		},
	},
	extraReducers: (builder) => {
		// Fetch agents
		builder
			.addCase(fetchAgents.pending, (state) => {
				state.isFetchingList = true
			})
			.addCase(fetchAgents.fulfilled, (state, { payload }) => {
				state.isFetchingList = false
				agentsAdapter.setAll(state, payload.map(transformToAppAgent))
			})
			.addCase(fetchAgents.rejected, (state) => {
				state.isFetchingList = false
			})

		// Fetch single agent
		builder
			.addCase(fetchAgent.pending, (state) => {
				state.isFetchingDetail = true
			})
			.addCase(fetchAgent.fulfilled, (state, { payload }) => {
				state.isFetchingDetail = false
				agentsAdapter.upsertOne(state, payload)
			})
			.addCase(fetchAgent.rejected, (state) => {
				state.isFetchingDetail = false
			})

		// Update agent
		builder
			.addCase(updateAgent.pending, (state) => {
				state.isPendingUpdate = true
			})
			.addCase(updateAgent.fulfilled, (state, { payload }) => {
				state.isPendingUpdate = false
				agentsAdapter.updateOne(state, { id: payload.id, changes: payload })
			})
			.addCase(updateAgent.rejected, (state) => {
				state.isPendingUpdate = false
			})

		// Set active agent
		builder
			.addCase(setActiveAgent.pending, (state, { meta }) => {
				const { agent, active } = meta.arg
				agentsAdapter.updateOne(state, { id: agent.id, changes: { active } })
			})
			.addCase(setActiveAgent.rejected, (state, { meta }) => {
				const { agent } = meta.arg
				agentsAdapter.updateOne(state, { id: agent.id, changes: { active: agent.active } })
			})

		// Delete agent
		builder
			.addCase(deleteAgent.pending, (state) => {
				state.isPendingDelete = true
			})
			.addCase(deleteAgent.fulfilled, (state, { meta }) => {
				state.isPendingDelete = false
				agentsAdapter.removeOne(state, meta.arg)
			})
			.addCase(deleteAgent.rejected, (state) => {
				state.isPendingDelete = false
			})

		// Update agent from websocket
		builder.addCase(updateAgentFromWebsocket, (state, { payload }) => {
			const id = Number(payload.id)
			const { changes }: { changes: Partial<Pick<Agent, 'connectedAt' | 'status'>> } = payload
			if ('status' in changes || 'connectAt' in changes) {
				state.agentStatuses[id] = { ...state.agentStatuses[id], ...changes }
			}
		})
	},
})

const { reducer, actions } = agentsSlice
export const { inviteModalOpen, inviteModalClose, setFilterValue, resetFilterValue, initializeAgents } = actions
export default reducer

const entitySelectors = agentsAdapter.getSelectors<AgentsRootState>((state) => state.agents)
const getIsFetchingList = (state: AgentsRootState) => state.agents.isFetchingList
const getIsFetchingDetail = (state: AgentsRootState) => state.agents.isFetchingDetail
const getIsPendingUpdate = (state: AgentsRootState) => state.agents.isPendingUpdate
const getAgentStatuses = (state: AgentsRootState) => state.agents.agentStatuses
const getAgentsError = (state: AgentsRootState) => state.agents.error
const getIsInviteModalOpen = (state: AgentsRootState) => state.agents.isInviteModalOpen
const getAgents = (state: AgentsRootState) => entitySelectors.selectAll(state)
const getAgentIds = (state: AgentsRootState) => entitySelectors.selectIds(state)
const getAgentById = (state: AgentsRootState, id: number) => entitySelectors.selectById(state, id)
const getAgentId = (state: AgentsRootState, agentId: number | null) => agentId
const getChat = (state: DashboardState, chat: Chat | null) => chat
const getFilterValue = (state: AgentsRootState) => state.agents.filterValue

const getAppAgentEntities = createSelector([getAgents, getAgentStatuses], (agents, statuses): Dictionary<AppAgent> => {
	const appAgents: Dictionary<AppAgent> = {}
	agents.forEach((agent) => {
		const agentStatus = statuses[agent.id]
		appAgents[agent.id] = {
			...agent,
			status: agentStatus?.status ?? null,
			connectedAt: agentStatus?.connectedAt ?? null,
		}
	})
	return appAgents
})

const getAppAgents = createSelector([getAppAgentEntities], (agents): AppAgent[] => {
	// except Removed agents
	return Object.values(agents).filter((a) => a.deletedAt === null)
})

const getAppAgentsIncludeRemoved = createSelector([getAppAgentEntities], (agents): AppAgent[] => {
	return Object.values(agents)
})

const getAppAgentsWithGroups = createSelector(
	[getAppAgents, groupsSelectors.getGroups],
	(agents, groups): AppAgentWithGroups[] => {
		return agents.map((agent) => {
			const agentGroups: Group[] = groups.filter((g) => g.agents.includes(agent.id))
			return { ...agent, groups: agentGroups }
		})
	},
)

const getAppAgentsSorted = createSelector([getAppAgents], (agents): AppAgent[] => {
	return agents.sort((a, b) => a.fullname.localeCompare(b.fullname))
})

const getActiveAppAgents = createSelector([getAppAgents], (agents): AppAgent[] => {
	return agents.filter((a) => a.active)
})

const getAssignableAppAgents = createSelector([getAppAgentsWithGroups], (agents): AppAgentWithGroups[] => {
	return agents.filter((a) => a.active)
})

const getActiveAgentsCount = createSelector([getActiveAppAgents], (agents): number => {
	return agents.length
})

const getMaxActiveAgentsCount = createSelector([packageSelectors.getPackageInfo], (packageInfo): number | null => {
	if (!packageInfo) return 0
	return packageInfo.agents
})

const getFilteredAgents = createSelector(
	[getAssignableAppAgents, getFilterValue],
	(agents, filter): AppAgentWithGroups[] => {
		return agents.filter((agent) => agent.fullname.toLowerCase().includes(filter))
	},
)

const getOnlineAgentsExceptUser = createSelector(
	[getAppAgents, userSelectors.getUserId],
	(agents, userId): AppAgent[] | null => {
		if (!agents || !userId) return null
		return agents.filter((a) => a.status === AgentStatus.Online && a.id !== Number.parseInt(userId, 10))
	},
)

const getAgentsCountExceptUser = createSelector([getAppAgents, userSelectors.getUserId], (agents, userId): number => {
	if (!agents || !userId) return 0
	return agents.filter((a) => a.id !== Number.parseInt(userId, 10)).length
})

const canActivateAgent = createSelector(
	[getActiveAgentsCount, packageSelectors.getPackageInfo],
	(activeAgentsCount, packageInfo) => {
		if (!packageInfo) return false
		if (packageInfo.agents === null) return true // unlimited agents
		return activeAgentsCount < packageInfo.agents
	},
)

const makeGetAgentById = () => {
	return createSelector([getAppAgentEntities, getAgentId], (agents, agentId) => {
		if (!agentId) return null
		return agents[agentId]
	})
}

/**
 * @param includeUser - false, if we can agents in chat except logged user
 */
const makeGetCurrentAgentsInChat = (includeUser = true) => {
	return createSelector([getAppAgentsIncludeRemoved, userSelectors.getUserId, getChat], (agents, userId, chat) => {
		if (!userId || !chat) return null
		return agents.filter((a) => {
			const agentId = `${a.id}`
			if (includeUser) {
				return chat.assignedIds.includes(agentId)
			}
			return userId !== agentId && chat.assignedIds.includes(agentId)
		})
	})
}

const makeGetHistoryAgentsInChat = () => {
	return createSelector([getAppAgentsIncludeRemoved, userSelectors.getUserId, getChat], (agents, userId, chat) => {
		if (!userId || !chat) return null
		return agents.filter((a) => chat.agentIds.includes(`${a.id}`))
	})
}

const getAgentByUser = createSelector(
	[getAppAgentEntities, userSelectors.getUserId, groupsSelectors.getGroups],
	(agents, userId, groups) => {
		if (!userId) return null

		const agent = agents[userId]
		if (!agent) return null

		const agentGroups: Group[] = groups.filter((g) => g.agents.includes(Number(userId)))
		return { ...agent, groups: agentGroups } as AppAgentWithGroups
	},
)

const getAgentsServingVisitor = (servedBy: Visitor['servedBy']) => {
	return createSelector([getAppAgentEntities], (agents): AppAgent[] => {
		const visitorAgents: AppAgent[] = []

		servedBy.forEach((id) => {
			const agent = agents[id]
			if (agent) visitorAgents.push(agent)
		})

		return visitorAgents
	})
}

const getGroupedAllAgents = createSelector([getAppAgentsWithGroups], (agents): GroupedAgents[] => {
	const defaultGroupAgents = agents.filter((a) => a.groups.length === 0)

	const groupedAgents = new Map<string, GroupedAgents>([
		[DEFAULT_GROUP, { groupName: DEFAULT_GROUP, agents: defaultGroupAgents }],
	])

	agents.forEach((a) => {
		const agentGroups = a.groups
		agentGroups.forEach((group) => {
			if (!groupedAgents.has(group.key)) {
				groupedAgents.set(group.key, { groupName: group.name, agents: [] })
			}
			const resultItem = groupedAgents.get(group.key)
			if (resultItem) resultItem.agents.push(a)
		})
	})

	return [...groupedAgents.values()].sort(sortGroupByName)
})

const getAccountOwner = createSelector([getAppAgents, userSelectors.getUserId], (agents): AppAgent | null => {
	return agents.find((a) => a.role === UserRole.Owner) ?? null
})

export const agentsSelectors = {
	getIsFetchingList,
	getIsFetchingDetail,
	getIsPendingUpdate,
	getIsInviteModalOpen,
	getAgentsError,
	getAgents,
	getAgentIds,
	getAgentById,
	getAccountOwner,
	getAgentByUser,
	getActiveAppAgents,
	getActiveAgentsCount,
	getAppAgents,
	getAppAgentsIncludeRemoved,
	getAppAgentsSorted,
	getMaxActiveAgentsCount,
	getGroupedAllAgents,
	getAssignableAppAgents,
	getAgentsServingVisitor,
	getOnlineAgentsExceptUser,
	getAgentsCountExceptUser,
	canActivateAgent,
	makeGetCurrentAgentsInChat,
	makeGetHistoryAgentsInChat,
	makeGetAgentById,
	getFilteredAgents,
	getFilterValue,
}
