import { ChakraTheme, extendTheme, useTheme } from '@chakra-ui/react'

import { Accordion } from './components/accordion'
import { Alert } from './components/alert'
import { Avatar } from './components/avatar'
import { Badge } from './components/badge'
import { Button } from './components/button'
import { Card } from './components/card'
import { Checkbox } from './components/checkbox'
import { CloseButton } from './components/closeButton'
import { Divider } from './components/divider'
import { Drawer } from './components/drawer'
import { Input } from './components/input'
import { Menu } from './components/menu'
import { Modal } from './components/modal'
import { Popover } from './components/popover'
import { Progress } from './components/progress'
import { Select } from './components/select'
import { Switch } from './components/switch'
import { Tag } from './components/tag'
import { Text } from './components/text'
import { Textarea } from './components/textarea'
import { borders } from './foundations/borders'
import { breakpoints, mediaQueries } from './foundations/breakpoints'
import { colors } from './foundations/colors'
import { filters } from './foundations/filters'
import { radii } from './foundations/radii'
import { shadows } from './foundations/shadows'
import { sizes } from './foundations/sizes'
import { space } from './foundations/space'
import { fontSizes, lineHeights } from './foundations/typography'
import { zIndices } from './foundations/zIndices'
import { styles } from './styles'

const components = {
	Accordion,
	Alert,
	Avatar,
	Badge,
	Button,
	Card,
	CloseButton,
	Checkbox,
	Divider,
	Drawer,
	Input,
	Menu,
	Modal,
	Progress,
	Select,
	Switch,
	Tag,
	Text,
	Textarea,
	Popover,
}

const overrides = {
	styles,
	borders,
	breakpoints,
	colors,
	filters,
	fontSizes,
	lineHeights,
	mediaQueries,
	radii,
	shadows,
	sizes,
	space,
	zIndices,
	components,
}

export type Theme = ChakraTheme & typeof overrides

export const theme = extendTheme(overrides)

export const useAppTheme = () => {
	return useTheme<Theme>()
}
