import { forbiddenUserEmailDomains } from './constants'
import { OnboardingLandingPage } from './types'

export const isAllowedUserEmailDomain = (domain: string): boolean => {
	return !forbiddenUserEmailDomains.some((forbiddenDomain) => domain.includes(forbiddenDomain))
}

export const isAllowedLandingPage = (landingPage: string): landingPage is OnboardingLandingPage => {
	const onboardingLandingPages = Object.values(OnboardingLandingPage).map((value) => value.toString())
	return onboardingLandingPages.includes(landingPage)
}
