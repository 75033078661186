import { PayloadAction, SerializedError } from '@reduxjs/toolkit'

import {
	ChatbotAiSkillHandoverType,
	ChatbotAiSkillProductRecommendationType,
	ChatbotWorkflow,
} from 'shared/models/Chatbot'
import { Preview } from 'shared/models/WidgetPreview'

export enum ApiCommand {
	ReloadWithMockData = 'reloadWithMockData',
	Reload = 'reload',
	Message = 'chat:message',
	Send = 'chat:send',
}

export enum PreviewType {
	AiBuilderPreview = 'aiBuilderPreview',
	AiWidgetPreview = 'aiWidgetPreview',
}

export enum AiPreviewSource {
	Builder = 'builder',
	Onboarding = 'onboarding',
}

export type AiPreviewState = 'ready' | 'pending' | 'failed' | 'limitReached'

interface DefaultPreviewQuestion {
	default: string
}

export type PreviewQuestions = {
	behavior: Record<keyof Omit<ChatbotWorkflow['behavior'], 'description'>, string> & DefaultPreviewQuestion
	skills: {
		productRecommendation: Record<ChatbotAiSkillProductRecommendationType, string> & DefaultPreviewQuestion
		handover: Record<ChatbotAiSkillHandoverType, string> & DefaultPreviewQuestion
	} & DefaultPreviewQuestion
}

export type AiAssistantThunk = Promise<
	| PayloadAction<
			Preview.Message,
			string,
			{ arg: Preview.CreateMessageParams; requestId: string; requestStatus: 'fulfilled' }
	  >
	| PayloadAction<
			unknown,
			string,
			{
				arg: Preview.CreateMessageParams
				requestId: string
				rejectedWithValue: boolean
				requestStatus: 'rejected'
				aborted: boolean
				condition: boolean
			},
			SerializedError
	  >
> & { abort(reason?: string | undefined): void; requestId: string; arg: Preview.CreateMessageParams }
