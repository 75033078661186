import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DashboardState } from 'types'
import {
	PackageBalance,
	PackageInfo,
	PackageInterval,
	PackageLogEventRequest,
	PackageNotifications,
	SelectedPackageInfo,
} from 'shared/models/Package'
import { PaymentGateway } from 'shared/models/Payment'
import { CheckoutAllowedIntervals } from 'modules/checkout'
import { getAllowedCheckoutIntervalsFromGateways } from 'modules/checkout/utils'

import { packageApi } from './api'
import * as utils from './utils'

export type PackageRootState = Pick<DashboardState, 'package'>

export const fetchPackage = createAsyncThunk('package/FETCH', () => {
	return packageApi.fetchPackage()
})

export const fetchPackageBalance = createAsyncThunk('package/FETCH_BALANCE', async () => {
	return packageApi.fetchPackageBalance()
})

export const logPackageEvent = createAsyncThunk('package/LOG_EVENT', (data: PackageLogEventRequest) => {
	return packageApi.logPackageEvent(data)
})

const initialState = {
	packageInfo: null as null | PackageInfo,
	packageBalance: null as null | PackageBalance,
	isFetchInfoPending: false,
	isFetchBalancePending: false,
}

const packageSlice = createSlice({
	name: 'package',
	initialState,
	reducers: {
		onPackageChanged: (state, { payload }: PayloadAction<PackageInfo>) => {
			state.packageInfo = payload
		},
	},
	extraReducers: (builder) => {
		// Fetch package
		builder
			.addCase(fetchPackage.pending, (state) => {
				state.isFetchInfoPending = true
			})
			.addCase(fetchPackage.fulfilled, (state, { payload }) => {
				state.isFetchInfoPending = false
				state.packageInfo = payload
			})
			.addCase(fetchPackage.rejected, (state) => {
				state.isFetchInfoPending = false
			})

		// Fetch package Balance
		builder
			.addCase(fetchPackageBalance.pending, (state) => {
				state.isFetchBalancePending = true
			})
			.addCase(fetchPackageBalance.fulfilled, (state, { payload }) => {
				state.isFetchBalancePending = false
				state.packageBalance = payload
			})
			.addCase(fetchPackageBalance.rejected, (state) => {
				state.isFetchBalancePending = false
			})
	},
})

const { actions, reducer } = packageSlice
export const { onPackageChanged } = actions
export default reducer

const getPackageInfoData = (state: PackageRootState) => state.package.packageInfo
const getPackageBalance = (state: PackageRootState) => state.package.packageBalance
const getIsFetchInfoPending = (state: PackageRootState) => state.package.isFetchInfoPending

export const getPackageInfo = createSelector([getPackageInfoData], (packageInfo): SelectedPackageInfo | null => {
	if (!packageInfo) return null

	const notifications: PackageNotifications = {
		isDowngradedToFree: utils.isDowngradedToFree(packageInfo),
		isTrialExpiring: utils.isTrialExpiring(packageInfo),
		isPackageExpiring: utils.isPackageExpiring(packageInfo),
		isPackageExpired: utils.isPackageExpired(packageInfo),
		isPackagePastDue: utils.isPackagePastDue(packageInfo),
		hasUnpaidFirstInvoice: utils.hasUnpaidFirstInvoice(packageInfo),
		hasUnpaidGeneratedInvoice: utils.hasUnpaidGeneratedInvoice(packageInfo),
	}

	return {
		...packageInfo,
		formattedName: utils.formatPackageName(packageInfo.name),
		isPaidPackage: utils.isPaidPackage(packageInfo.name),
		isTrial: utils.isTrialPackage(packageInfo.name),
		isFree: utils.isFreePackage(packageInfo.name),
		isMini: utils.isMiniPackage(packageInfo.name),
		isBasic: utils.isBasicPackage(packageInfo.name),
		isPro: utils.isProPackage(packageInfo.name),
		isExpired: utils.isPackageExpired(packageInfo),
		isRemainingDaysCountLow: utils.isRemainingDaysCountLow(packageInfo),
		isPastDue: utils.isPackagePastDue(packageInfo),
		pendingInvoice: utils.getPendingInvoice(packageInfo),
		pendingOrder: utils.getPendingOrder(packageInfo),
		hasBraintreeGateway: packageInfo.paymentGateway === PaymentGateway.Braintree,
		hasTransferGateway: packageInfo.paymentGateway === PaymentGateway.Transfer,
		hasShopifyGateway: packageInfo.paymentGateway === PaymentGateway.Shopify,
		hasAppleGateway: packageInfo.paymentGateway === PaymentGateway.Apple,
		hasStripeGateway: packageInfo.paymentGateway === PaymentGateway.Stripe,
		isShopifySubscriptionUpdatable: utils.isShopifySubscriptionUpdatable(packageInfo),
		shopifyConfirmationUrl: utils.getShopifyConfirmationUrl(packageInfo),
		chatHistory: utils.getChatHistory(packageInfo.name),
		notifications,
	}
})

export const hasAutoRenewalTurnedOff = createSelector([getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	const { autoRenewal, isDemo, isExpired } = packageInfo
	return !isDemo && !isExpired && !autoRenewal
})

const getAllowedPaymentGateways = createSelector([getPackageInfo], (packageInfo) => {
	if (!packageInfo) return {}

	return packageInfo.allowedPaymentGateways
})

const getIsShopifyAccount = createSelector([getAllowedPaymentGateways], (allowedGateways) => {
	return !!allowedGateways.shopify
})

const getIsAllowedStripePaymentGateway = createSelector([getAllowedPaymentGateways], (allowedGateways) => {
	return !!allowedGateways.stripe
})

const getAllowedPaymentGatewayName = createSelector(
	[getIsAllowedStripePaymentGateway],
	(isStripe): 'stripe' | 'braintree' => {
		if (isStripe) return 'stripe'
		return 'braintree'
	},
)

const getAllowedCheckoutIntervals = createSelector(
	[getAllowedPaymentGateways],
	(allowedGateways): CheckoutAllowedIntervals => {
		return getAllowedCheckoutIntervalsFromGateways(allowedGateways)
	},
)

const getShowIntervalConfiguration = createSelector(
	[getAllowedCheckoutIntervals, getPackageInfo],
	(allowedIntervals, packageInfo): boolean => {
		if (!packageInfo) return false
		// Don't show interval config if current package has yearly subscription interval
		const isYearlyInterval = packageInfo.interval === PackageInterval.Year
		return !isYearlyInterval && Object.values(allowedIntervals).every(Boolean)
	},
)

const getIsUltimatePackage = createSelector([getPackageInfo], (packageInfo): boolean => {
	if (!packageInfo) return false

	return packageInfo.isUltimate
})

const getShouldShowTurnOffRenewal = createSelector([getPackageInfo], (packageInfo) => {
	if (!packageInfo) return null

	return packageInfo && packageInfo.autoRenewal && !packageInfo.isDemo
})

const getIsAllowedDifferentPaymentGateway = createSelector([getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false

	const { allowedPaymentGateways, paymentGateway } = packageInfo

	if (!paymentGateway) return true

	return !Object.keys(allowedPaymentGateways).includes(paymentGateway)
})

const getIsAiLimitsUpgradeable = createSelector([getPackageInfo], (packageInfo) => {
	if (!packageInfo) return null

	const { isFree, isTrial } = packageInfo
	return isFree || isTrial
})

export const packageSelectors = {
	getIsFetchInfoPending,
	getPackageInfo,
	getPackageBalance,
	hasAutoRenewalTurnedOff,
	getIsShopifyAccount,
	getShowIntervalConfiguration,
	getAllowedCheckoutIntervals,
	getIsAllowedStripePaymentGateway,
	getIsUltimatePackage,
	getAllowedPaymentGatewayName,
	getShouldShowTurnOffRenewal,
	getIsAllowedDifferentPaymentGateway,
	getIsAiLimitsUpgradeable,
}
