import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { ChatbotSimple, ListQueryGroupType, SelectedChatbotSimple } from 'models'
import { AppThunkAction, DashboardState } from 'types'
import { automationsApi, makeSortAutomations, transformSimpleChatbot } from 'modules/automations'
import { autoMessagesSelectors, getSimpleAutoMessagesWithStatistics, toggleEditDrawer } from 'modules/autoMessages'
import { autoMessageStatisticsSelectors } from 'modules/autoMessageStatistics'
import { deleteChatbot, fetchChatbot, postChatbot, updateChatbot } from 'modules/chatbotDetail/actions'
import { sortAutomations } from 'modules/chatsFilter'
import { packageSelectors } from 'modules/package'

type TriggersRootState = Pick<DashboardState, 'triggers'>

const triggersAdapter = createEntityAdapter<ChatbotSimple>()

const initialState = triggersAdapter.getInitialState({
	lastFetchedTime: null as null | number,
	isFetchingTriggers: false,
})

export const fetchTriggersThunk = createAsyncThunk(
	'autoMessages/FETCH_TRIGGERS',
	() => {
		return automationsApi.getBotsList({ groupType: ListQueryGroupType.MessageLegacy })
	},
	{
		condition: (_, { getState }) => {
			const state = getState() as DashboardState
			const lastFetchedTime = getTriggersLastFetchedTime(state)
			const currentTime = Date.now()
			return !lastFetchedTime || currentTime - lastFetchedTime > 10000
		},
	},
)

export const selectTrigger =
	(chatbot: SelectedChatbotSimple): AppThunkAction =>
	(dispatch) => {
		dispatch(fetchChatbot(chatbot.id))
		dispatch(toggleEditDrawer(true))
	}

const triggersSlice = createSlice({
	name: 'legacyTriggers',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchTriggersThunk.pending, (state) => {
				state.isFetchingTriggers = true
			})
			.addCase(fetchTriggersThunk.fulfilled, (state, { payload }) => {
				state.isFetchingTriggers = false
				triggersAdapter.setAll(state, payload)
				state.lastFetchedTime = Date.now()
			})
			.addCase(fetchTriggersThunk.rejected, (state) => {
				state.isFetchingTriggers = false
			})
		builder.addCase(postChatbot.fulfilled, (state, { payload }) => {
			if (!payload.groupType) {
				triggersAdapter.addOne(state, payload)
			}
		})
		builder.addCase(updateChatbot.fulfilled, (state, { payload }) => {
			triggersAdapter.updateOne(state, { id: payload.id, changes: payload })
		})
		builder.addCase(deleteChatbot.fulfilled, (state, { meta }) => {
			triggersAdapter.removeOne(state, meta.arg)
		})
	},
})

const { reducer } = triggersSlice
export default reducer

const entitySelectors = triggersAdapter.getSelectors<TriggersRootState>((state) => state.triggers)
const getTriggersList = (state: TriggersRootState) => entitySelectors.selectAll(state)
const getIsFetchingTriggers = (state: TriggersRootState) => state.triggers.isFetchingTriggers
const getTriggersLastFetchedTime = (state: TriggersRootState) => state.triggers.lastFetchedTime

const getTriggers = createSelector([getTriggersList], (triggers): SelectedChatbotSimple[] => {
	return triggers.map(transformSimpleChatbot)
})

const getActiveTriggersCount = createSelector([getTriggers], (triggers): number => {
	return triggers.filter((trigger) => trigger.isActive).length
})

const getMaxActiveTriggersCount = createSelector([packageSelectors.getPackageInfo], (packageInfo): number | null => {
	if (!packageInfo) return 0
	return packageInfo.maxActiveTriggersCount
})

const canActivateTrigger = createSelector(
	[getActiveTriggersCount, getMaxActiveTriggersCount],
	(activeTriggersCount, maxActiveTriggersCount) => {
		if (maxActiveTriggersCount === null) return true // Unlimited triggers
		return activeTriggersCount < maxActiveTriggersCount
	},
)

const getTriggersWithStatistics = createSelector(
	[getTriggers, autoMessageStatisticsSelectors.getMessages],
	(triggers, stats) => {
		return getSimpleAutoMessagesWithStatistics(triggers, stats)
	},
)

const getSortedTriggers = createSelector(
	[getTriggersWithStatistics, autoMessagesSelectors.getAutoMessagesSorting],
	(triggers, sorting) => {
		return Object.values(triggers).sort(makeSortAutomations(sorting))
	},
)

const isTriggerCreated = createSelector([getTriggers], (triggers): boolean => {
	return triggers.length > 0
})

const getTriggerIds = createSelector([getTriggers], (triggers) => {
	return triggers.map((trigger) => trigger.id)
})

const getTriggersSortedAlphabetically = createSelector([getTriggers], (triggers) => {
	return sortAutomations(triggers)
})

export const triggersSelectors = {
	getTriggers,
	getIsFetchingTriggers,
	getActiveTriggersCount,
	getMaxActiveTriggersCount,
	canActivateTrigger,
	getSortedTriggers,
	isTriggerCreated,
	getTriggerIds,
	getTriggersSortedAlphabetically,
}
