import { Text, TextProps, Tooltip } from 'core'

type Props = {
	children: string
	hasTooltip?: boolean
} & TextProps

export const StatName = ({ children, hasTooltip = false, ...rest }: Props) => {
	return (
		<Tooltip content={children} disabled={!hasTooltip}>
			<Text isTruncated fontSize="lg" fontWeight="600" {...rest}>
				{children}
			</Text>
		</Tooltip>
	)
}
