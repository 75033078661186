import { ComponentProps } from 'react'
import { FormattedNumber } from 'react-intl'

type Props = ComponentProps<typeof FormattedNumber>

const IntlNumber = (props: Props) => {
	return <FormattedNumber {...props} />
}

export default IntlNumber
