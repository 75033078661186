import { useEffect, useMemo } from 'react'
import debounce from 'lodash/debounce'

export const useDebounce = <T extends (...args: never[]) => void>(fn: T, waitTime: number) => {
	const debounceFn = useMemo(() => {
		return debounce(fn, waitTime)
	}, [fn, waitTime])

	useEffect(() => {
		return () => {
			debounceFn.cancel()
		}
	}, [debounceFn])

	return debounceFn
}
