import { BotIdentity, BotIdentityGender } from 'shared/models/Chatbot'

export const MAX_NAME_LENGTH = 64
export const DEFAULT_IDENTITY_NAME = 'Mira AI'
export const DEFAULT_IDENTITY_GENDER = 'female'

export const IDENTITY_FORM_ID = 'chatbot-identity'
export const IDENTITY_NAME = 'name'
export const IDENTITY_AVATAR = 'avatarImg'
export const IDENTITY_GENDER = 'gender'

export const DEFAULT_IDENTITY: BotIdentity = {
	id: '',
	name: '',
	gender: DEFAULT_IDENTITY_GENDER,
	avatarUrl: null,
	createdAt: null,
}

export const gendersMap: Record<BotIdentityGender, { icon: string; value: BotIdentityGender }> = {
	[BotIdentityGender.Female]: {
		icon: '👩🏻',
		value: BotIdentityGender.Female,
	},
	[BotIdentityGender.Male]: {
		icon: '👨🏻',
		value: BotIdentityGender.Male,
	},
}
