import { MenuItem as Item, MenuItemProps } from '@chakra-ui/react'

type Props = {
	variant?: 'danger'
} & MenuItemProps

export const MenuItem = ({ variant, ...rest }: Props) => {
	let variantProps
	if (variant === 'danger') {
		variantProps = {
			color: 'red.500',
			_hover: { color: 'red.600', bg: 'red.100' },
		}
	}

	return <Item {...(variantProps && variantProps)} {...rest} />
}
