import { AnyAction, Middleware } from 'redux'

import { DashboardState } from 'types'
import { Dispatcher } from 'dispatchers/Dispatcher'

export const dispatcherMiddleware =
	(dispatchers: Map<string, Dispatcher>): Middleware<Record<string, unknown>, DashboardState> =>
	({ getState }) =>
	(next) =>
	(action: AnyAction) => {
		if (action && dispatchers.has(action.type as string)) {
			const dispatcher = dispatchers.get(action.type as string)
			dispatcher && dispatcher.dispatchAction(next, action)

			// Do not dispatch action directly, dispatcher will handle it, send current state
			return getState()
		}

		return next(action)
	}
