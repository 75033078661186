import { ComponentProps } from 'react'
import { FormattedNumber } from 'react-intl'

import { CurrencyCode } from 'models'

import IntlCurrencyProvider from 'components/intl/IntlCurrencyProvider'

type Props = {
	currencyCode: CurrencyCode
	locale?: string
} & ComponentProps<typeof FormattedNumber>

const IntlCurrency = ({ currencyCode, locale, value, ...props }: Props) => {
	return (
		<IntlCurrencyProvider locale={locale}>
			{/* eslint-disable-next-line react/style-prop-object */}
			<FormattedNumber {...props} style="currency" value={value} currency={currencyCode} />
		</IntlCurrencyProvider>
	)
}

export default IntlCurrency
