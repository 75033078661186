import { SmartsuppWindow } from 'shared/types'

declare const window: SmartsuppWindow

export const GA = (action: string, category: string, label: string) => {
	if (!window.dataLayer) return

	window.dataLayer.push({
		event: 'ga.event',
		eventAction: action,
		eventCategory: category,
		eventLabel: label,
	})
}
