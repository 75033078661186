import { ShortcutsApi } from '@smartsupp/core-client-api/dist/types'

import { Shortcut, ShortcutCreateRequest } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'shortcuts'

const fetchShortcuts = () => {
	return apiClient.get<Shortcut[]>(`${API_RESOURCE}`)
}

const createShortcut = (data: ShortcutCreateRequest) => {
	return apiClient.post<Shortcut>(`${API_RESOURCE}`, data)
}

const deleteShortcut = (id: number) => {
	return apiClient.delete(`${API_RESOURCE}/${id}`)
}

const editShortcut = (id: number, changes: ShortcutsApi.UpdateBody) => {
	return apiClient.patch<Shortcut>(`${API_RESOURCE}/${id}`, changes)
}

const logUsageShortcutId = (id: Shortcut['id']) => {
	return apiClient.post(`${API_RESOURCE}/${id}/used`)
}

export const shortcutsApi = { fetchShortcuts, createShortcut, deleteShortcut, editShortcut, logUsageShortcutId }
