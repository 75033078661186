const variants = {
	filled: {
		field: {
			bg: 'gray.200',
		},
	},
}

const size = {
	sm: {
		fontSize: 0,
		py: '4px',
		px: 1,
	},
	md: {
		fontSize: 1,
		py: '6px',
		px: 1.5,
		borderRadius: 1,
	},
	lg: {
		fontSize: 2,
		py: 1,
		px: 2,
		borderRadius: 1,
	},
}

const sizes = {
	sm: {
		field: size.sm,
		addon: size.sm,
	},
	md: {
		field: size.md,
		addon: size.md,
	},
	lg: {
		field: size.lg,
		addon: size.lg,
	},
}

export const Input = {
	sizes,
	variants,
}
