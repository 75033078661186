import { AppLang } from 'shared/types'
import { ConfigurationService } from 'shared/services'

const webUrl = 'https://www.smartsupp.com'
const docsUrl = 'https://docs.smartsupp.com'
export const widgetUrl = 'https://widget-page.smartsupp.com'

const getHelpUrl = () => {
	const configData = ConfigurationService.getData()
	return configData && configData.lang === 'en' ? `${webUrl}/help/` : `${webUrl}/${configData.lang}/help/`
}

const getBlogUrl = (lang?: AppLang) => {
	const configData = ConfigurationService.getData()
	const selectedLang = lang ?? configData.lang
	return selectedLang === 'en' ? `${webUrl}/blog/` : `${webUrl}/${selectedLang}/blog/`
}

const getAcademyUrl = (lang?: AppLang) => {
	const configData = ConfigurationService.getData()
	const selectedLang = lang ?? configData.lang

	if (selectedLang === 'cs') return `${webUrl}/${selectedLang}/kurzy/`
	return `${webUrl}/courses/`
}

export const getPricingUrl = (param = '') => {
	const configData = ConfigurationService.getData()
	return configData && configData.lang === 'en'
		? `${webUrl}/pricing${param}#features`
		: `${webUrl}/${configData.lang}/pricing${param}#features`
}

export const getRequestDemoUrl = () => {
	const configData = ConfigurationService.getData()
	return configData && configData.lang === 'en'
		? `${webUrl}/request-demo/`
		: `${webUrl}/${configData.lang}/request-demo/`
}

export const getContactSalesFormUrl = () => {
	const { lang } = ConfigurationService.getData()

	return lang === 'cs'
		? 'https://share-eu1.hsforms.com/17j2khFE6QXqZI3tkXUzc-Afwi56'
		: 'https://share-eu1.hsforms.com/1vOR6yqIbTJSAbGf0TNCr7gfwi56'
}

export const getWidgetKey = () => {
	const configData = ConfigurationService.getData()
	const chatKey = configData.account.key

	return chatKey
}

export const getBlogLink = (url: string, lang?: AppLang) => `${getBlogUrl(lang)}${url}`
export const getAcademyLink = (url: string, lang?: AppLang) => `${getAcademyUrl(lang)}${url}`
export const getHelpLink = (url: string) => `${getHelpUrl()}${url}`
export const getWidgetDocsLink = (url: string) => `${docsUrl}/chat-box/${url}`
export const getMobileSdkDocsLink = () => `${docsUrl}/mobile-sdk`
export const getChatbotPreviewLink = (id: string, widgetPageUrl: string) =>
	`${widgetPageUrl}?ss-bot-run=${id}&reset=1&isPreviewMode=1`
export const getWidgetPreviewLink = () => `/widget-preview/${getWidgetKey()}`
