import { ConfigurationService } from 'shared/services'

export function generateChatCode() {
	const { account, chatUrl, debugMode } = ConfigurationService.getData()

	return (
		`${
			'<!-- Smartsupp Live Chat script -->\n' +
			'<script type="text/javascript">\n' +
			'var _smartsupp = _smartsupp || {};\n' +
			"_smartsupp.key = '"
		}${account.key}';\n${debugMode ? `_smartsupp.baseUrl = "${chatUrl}";\n` : ''}${
			debugMode ? '_smartsupp.protocol = "http";\n' : ''
		}window.smartsupp||(function(d) {\n` +
		`  var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];\n` +
		`  s=d.getElementsByTagName('script')[0];c=d.createElement('script');\n` +
		`  c.type='text/javascript';c.charset='utf-8';c.async=true;\n` +
		`  c.src='${chatUrl}/loader.js?';s.parentNode.insertBefore(c,s);\n` +
		`})(document);\n` +
		`</script>\n` +
		`<noscript> Powered by <a href=“https://www.smartsupp.com” target=“_blank”>Smartsupp</a></noscript>\n`
	)
}
