import { rgba } from 'polished'

import { colors } from './colors'

export const shadows = {
	shadow1: `0 2px 4px ${rgba(colors.black, 0.15)}`,
	shadow2: `0 12px 15px 0 rgba(33, 50, 138, 0.16)`,
	shadow3: `0 2px 20px 0 ${rgba(colors.black, 0.15)}`,
	shadow4: `0 1px 1px ${colors.widgetDesign.gray[500]}`,
	shadow5: `0 1px 1px ${colors.widgetDesign.blue[500]}`,
	shadow6: `0 1px 1px ${colors.widgetDesign.red[500]}`,
	shadow7: `0 1px 3px ${rgba(colors.gray[800], 0.12)}`,
	shadow8: `0 1px 3px ${rgba(colors.coal[900], 0.14)}`,
	shadow9: `0 3px 6px ${rgba(colors.black, 0.16)}`,
	shadow10: `0 3px 5px -3px ${rgba(colors.black, 0.1)} inset`,
	shadow11: `0 0 1px 1px rgba(6, 19, 43, 0.04)`,
	shadow12: `0 0 0 2px ${colors.coal[900]}`,
	shadowWhite: `0 0 0 2px ${colors.white}`,
	shadowPrimary: `0 0 0 1px ${colors.blue[500]}`,
	shadowError: `0 0 0 1px ${colors.red[600]}`,
}
