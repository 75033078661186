import { createSelector } from '@reduxjs/toolkit'

import { FilesUploadConfig } from 'models/AppConfig'
import { DashboardState } from 'types'
import { accountSelectors } from 'modules/account'
import { WidgetVariablesData } from 'modules/app'
import { packageSelectors } from 'modules/package'
import { userSelectors } from 'modules/user'

import { AppRootState } from './slice'

const isAppInitPending = (state: DashboardState) => state.app.isAppInitPending
const getAppInitError = (state: DashboardState) => state.app.appInitError
const isAppVisible = (state: DashboardState) => state.app.visible
const isAppFocused = (state: DashboardState) => state.app.focused
const getLocale = (state: DashboardState) => state.app.locale
const isWidgetRendered = (state: DashboardState) => state.app.isWidgetRendered

const getUploadConfig = (state: AppRootState) => state.app.fileUploadConfig

const getFileUploadConfig = createSelector([getUploadConfig], (config): FilesUploadConfig => {
	if (!config) {
		return {
			acceptedFileTypes: [],
			acceptedFileExtensions: [],
			maxFileSize: 0,
		}
	}
	return config
})

const getWidgetVariablesData = createSelector(
	[
		userSelectors.getActiveUser,
		accountSelectors.getAccountId,
		accountSelectors.getAccountSourcePlatform,
		packageSelectors.getPackageInfo,
	],
	(user, accountId, sourcePlatform, packageInfo): WidgetVariablesData | null => {
		if (!user || !packageInfo) return null

		return {
			userId: user.id,
			userFullname: user.fullname,
			userEmail: user.email,
			userRole: user.role,
			accountId,
			sourcePlatform: sourcePlatform ?? '-',
			packageName: packageInfo.name,
		}
	},
)

export const appSelectors = {
	isAppInitPending,
	getAppInitError,
	isAppVisible,
	isAppFocused,
	getFileUploadConfig,
	getWidgetVariablesData,
	getLocale,
	isWidgetRendered,
}
