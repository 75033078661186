import { DemoFormData, SurveyData } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE_SURVEY = 'survey/answer'
const API_RESOURCE_DEMOFORM = 'sales-tp'

const sendSurvey = (data: SurveyData): Promise<void> => {
	return apiClient.post<void>(API_RESOURCE_SURVEY, data)
}

const sendDemoForm = (data: DemoFormData) => {
	return apiClient.post<DemoFormData>(API_RESOURCE_DEMOFORM, data)
}

export const surveysApi = { sendSurvey, sendDemoForm }
