const baseStyle = {
	borderRadius: 1,
}

const variantResolve = () => {
	const resolveStateColors = { bg: 'green.200', color: `green.600` }

	return {
		bg: 'gray.100',
		color: `green.600`,
		_hover: resolveStateColors,
		_focus: resolveStateColors,
		_active: resolveStateColors,
	}
}

const variants = {
	resolve: variantResolve,
}

const sizes = {
	xs: {
		px: 1,
	},
	sm: {
		px: '12px',
	},
	md: {
		px: 2,
	},
	lg: {
		px: 3,
	},
}

const defaultProps = {
	colorScheme: 'brand',
}

export const Button = {
	baseStyle,
	variants,
	sizes,
	defaultProps,
}
