import { useLayoutEffect, useState } from 'react'

export interface UseScheduleProps {
	duration?: number
	delayMS?: number
	loop?: boolean
}

export interface UseScheduleReturnValue {
	elapsed: number
	delayMS: number
	delayComplete: boolean
}

const DEFAULT_DURATION = 1250
const DEFAULT_DELAY = 750

export const useSchedule = ({
	duration = DEFAULT_DURATION,
	delayMS = DEFAULT_DELAY,
	loop = true,
}: UseScheduleProps = {}): UseScheduleReturnValue => {
	const [elapsed, setTime] = useState(0)
	const [delayComplete, setDelayComplete] = useState(false)

	useLayoutEffect(() => {
		let raf: number
		let start: number
		let loopTimeout: ReturnType<typeof setTimeout>

		const tick = () => {
			raf = window.requestAnimationFrame(performAnimationFrame)
		}

		const performAnimationFrame = () => {
			setTime(Date.now() - start)
			tick()
		}

		const onStart = () => {
			loopTimeout = setTimeout(() => {
				window.cancelAnimationFrame(raf)
				setTime(Date.now() - start)
				if (loop) onStart()
			}, duration)

			// Start the loop
			start = Date.now()
			setDelayComplete(true)
			tick()
		}

		const renderingDelayTimeout = setTimeout(onStart, delayMS)

		return () => {
			clearTimeout(renderingDelayTimeout)
			clearTimeout(loopTimeout)
			window.cancelAnimationFrame(raf)
		}
	}, [duration, delayMS, loop])

	return {
		elapsed: Math.min(1, elapsed / duration),
		delayMS,
		delayComplete,
	}
}
