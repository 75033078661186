import { AGENTS_PANEL_HEIGHT, CHAT_LIST_ITEM_HEIGHT, HEADER_HEIGHT, SIDEBAR_WIDTH } from '../../constants'

export const sizes = {
	agentsPanelHeight: `${AGENTS_PANEL_HEIGHT}px`,
	conversationsCountExportHeight: '48px',
	chatFilterHeight: '48px',
	chatListItemHeight: `${CHAT_LIST_ITEM_HEIGHT}px`,
	headerHeight: `${HEADER_HEIGHT}px`,
	sidebarWidth: `${SIDEBAR_WIDTH}px`,
}
