export const validationErrorsMap: Record<string, string> = {
	default: 'invalid',
	format_email: 'invalid_email', // returned as API response
	email: 'invalid_email', // used by yup resolver
	invalid_email: 'invalid_email', // legacy API response
	email_exists: 'email_exists',
	already_invited: 'already_invited',
	too_many_agents: 'too_many_agents',
	required: 'missing_field',
	oneOf: 'password_match',
	max: 'invalid_length', // used by yup resolver
	max_length: 'invalid_length', // returned as API response
	invalid_length_max: 'invalid_length', // legacy API response
	shortcut_exists: 'shortcut_exists',
	tag_exists: 'tag_exists',
	duplicate_key: 'property_duplicate_key',
	url: 'invalid_url',
	validation: 'invalid_value',
	url_host_mismatch: 'url_host_mismatch',
	title_not_unique: 'title_not_unique',
	custom_response_not_unique: 'custom_response_not_unique',
}
