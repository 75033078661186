import { ElementType } from 'react'
import { Box, ChakraProps, ComponentWithAs, forwardRef } from '@chakra-ui/react'
import { isValidMotionProp, motion, MotionProps } from 'framer-motion'

export type MotionBoxProps = Omit<ChakraProps, keyof MotionProps> &
	MotionProps & {
		as?: ElementType
	}

export const MotionBox = motion(
	forwardRef<MotionBoxProps, 'div'>((props, ref) => {
		const chakraProps = Object.fromEntries(
			// Don't pass Framer props to DOM element
			Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
		)
		return <Box ref={ref} {...chakraProps} />
	}),
) as ComponentWithAs<'div', MotionBoxProps>
