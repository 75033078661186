import { Dictionary } from 'types'
import { SelectedChatbotSimple, SelectedChatbotSimpleWithStatistics } from 'shared/models/Chatbot'
import { AiChatbotStats } from 'shared/models/ChatbotStatistics'

export const getSimpleAiChatbotsWithStatistics = (
	chatbots: SelectedChatbotSimple[],
	stats: Dictionary<AiChatbotStats> | null,
): Dictionary<SelectedChatbotSimpleWithStatistics> => {
	const chatbotsWithStatistics: Dictionary<SelectedChatbotSimpleWithStatistics> = {}

	chatbots.forEach((chatbot) => {
		const triggered = stats && stats[chatbot.id] ? stats[chatbot.id].triggered.value : 0
		const conversations = stats && stats[chatbot.id] ? stats[chatbot.id].conversations.value : 0
		chatbotsWithStatistics[chatbot.id] = {
			...chatbot,
			triggered,
			conversations,
			ratio: triggered ? (conversations / triggered) * 100 : -1,
		}
	})
	return chatbotsWithStatistics
}
