const MILLISECONDS_IN_MINUTE = 60000
const MILLISECONDS_IN_DAY = 86400000

export const startOfDay = (date: Date): Date => {
	const newDate = new Date(date.getTime())
	newDate.setHours(0, 0, 0, 0)
	return newDate
}

export const differenceInMilliseconds = (date1: Date, date2: Date): number => {
	return date1.getTime() - new Date(date2).getTime()
}

export const differenceInMinutes = (date1: Date, date2: Date): number => {
	const diff = differenceInMilliseconds(date1, date2) / MILLISECONDS_IN_MINUTE
	return diff > 0 ? Math.floor(diff) : Math.ceil(diff)
}

export const differenceInDays = (date1: Date, date2: Date): number => {
	const startOfDayFirst = startOfDay(date1)
	const startOfDaySecond = startOfDay(date2)
	const diff = differenceInMilliseconds(startOfDayFirst, startOfDaySecond) / MILLISECONDS_IN_DAY
	return Math.round(diff)
}
