import { SmartsuppWindow } from 'shared/types'
import { logException } from 'shared/services'

import { ApiCommand } from './types'

declare let window: SmartsuppWindow

export const updateLivePreview = (name: ApiCommand, param: string | { [key: string]: unknown }, ...args: unknown[]) => {
	if (typeof window.livePreview === 'function') {
		try {
			window.livePreview(name, param, ...args)
		} catch (error) {
			if (error instanceof Error) {
				logException(error)
			}
		}
	}
}
