import { DemoFormOptions, SurveyData, SurveyKey, SurveyValidationType } from 'models'
import { TranslationService as T } from 'shared/services'
import { AllowedConfigurationItems, ConfigurationItemType } from 'modules/checkout'
import { MAX_TEXTAREA_LENGTH, SurveyOption, SurveySelected, SurveySubOption } from 'modules/surveys'

export const getRandomlySortedOptionArray = (
	options: SurveySubOption[] | SurveyOption[],
): SurveySubOption[] | SurveyOption[] => {
	const lastItem = options.pop()
	if (!lastItem) return options
	const randomizedArray = options.sort(() => Math.random() - 0.5)

	return [...randomizedArray, lastItem]
}

export const makeSurveyData = (
	survey: SurveyKey,
	surveyTitle: string,
	surveySelected: SurveySelected,
	options: SurveyOption[],
): SurveyData => {
	if (surveySelected.selected.length === 0) return null

	let data: SurveyData = {
		name: { key: survey, text: T.translateDefault(surveyTitle) },
		mainChoice: {},
	}
	const selectedIndex = options.findIndex((item: SurveyOption) => item.value === surveySelected.selected)
	const hasComment = surveySelected.comment && surveySelected.comment.length > 0
	const hasRating = surveySelected.rating !== undefined && surveySelected.rating > -1

	if (selectedIndex > -1) {
		data = {
			...data,
			mainChoice: { key: surveySelected.selected, text: T.translateDefault(options[selectedIndex].label) },
		}

		const { subOptions } = options[selectedIndex]

		if (subOptions) {
			const subSelectedIndex = subOptions.findIndex((item: SurveyOption) => item.value === surveySelected.subSelected)

			if (subSelectedIndex > -1) {
				data = {
					...data,
					subChoice: {
						key: surveySelected.subSelected,
						text: subOptions && T.translateDefault(subOptions[subSelectedIndex].label),
					},
				}
			}
		}

		if (hasComment) {
			data = {
				...data,
				comment: surveySelected.comment,
			}
		}

		if (hasRating) {
			data = {
				...data,
				rating: surveySelected.rating,
			}
		}
	}

	return data
}

export const isSurveyValid = (surveySelected: SurveySelected, options: SurveyOption[]): SurveyValidationType => {
	const { selected, subSelected, comment, rating } = surveySelected
	const isCommentFilled = comment.length > 0
	let validationState = SurveyValidationType.MissingOption

	options.forEach((option) => {
		const { subOptions, value, textarea } = option
		const isSelected = value === selected

		if (isSelected) {
			validationState = SurveyValidationType.Valid
		}

		if (isSelected && subOptions) {
			const isSubSelected = subSelected.length > 0

			if (!isSubSelected) {
				validationState = SurveyValidationType.MissingOption
			}

			if (isSubSelected) {
				const subIndex = subOptions.findIndex((subopt: SurveySubOption) => subopt.value === subSelected)
				const subOption = subOptions[subIndex]
				const hasSubOptionTextarea = !subOption.disableTextarea
				const hasSubOptionRating = subOption.rating
				const hasMandatoryText = subOption.mandatoryText

				if (hasSubOptionTextarea) {
					if (!isCommentFilled && hasMandatoryText) {
						validationState = SurveyValidationType.MissingText
					} else if (comment.length > MAX_TEXTAREA_LENGTH) {
						validationState = SurveyValidationType.TextTooLong
					}
				}
				if (hasSubOptionRating && rating === -1) {
					validationState = SurveyValidationType.MissingRating
				}
			}
		}

		if (isSelected && textarea) {
			if (!isCommentFilled) {
				validationState = SurveyValidationType.MissingText
			} else if (comment.length > MAX_TEXTAREA_LENGTH) {
				validationState = SurveyValidationType.TextTooLong
			}
		}
	})

	return validationState
}

export const getDemoFormOptionByCheckoutConfigurationItemType = (type: AllowedConfigurationItems): DemoFormOptions => {
	switch (type) {
		case ConfigurationItemType.Agent: {
			return DemoFormOptions.BillingAgents
		}
		case ConfigurationItemType.Chatbot: {
			return DemoFormOptions.BillingChatbots
		}
		case ConfigurationItemType.LivechatConversation: {
			return DemoFormOptions.BillingLivechatConversations
		}
		case ConfigurationItemType.AiConversation: {
			return DemoFormOptions.BillingAiConversations
		}
		default: {
			return DemoFormOptions.BillingAgents
		}
	}
}
