type Props = {
	label?: string
	symbol: string
}

export const Emoji = ({ label, symbol }: Props) => (
	<span role="img" aria-label={label} aria-hidden={!label}>
		{symbol}
	</span>
)
