import { WidgetConfig } from 'models'
import { TranslationService as T } from 'shared/services'
import {
	GroupedWidgetLanguageOption,
	officiallyTranslatedLangs,
	unofficiallyTranslatedLangs,
	WidgetAppearanceFormData,
	WidgetAppearanceFormMergedData,
	WidgetAuthFormData,
	WidgetAuthFormMergedData,
	WidgetLanguageOption,
	WidgetLanguageType,
	WidgetOptionsData,
} from 'modules/widget'

// Languages
const getOption = (language: string): WidgetLanguageOption => {
	return { label: `${language.toUpperCase()} - ${T.translate(`langs.${language}`)}`, value: language }
}

const getLanguageSelectOptions = (languages: WidgetLanguageType[]): WidgetLanguageOption[] => {
	return languages.map((lang) => {
		return getOption(lang.id)
	})
}

export const getGroupedLanguageSelectOptions = (): GroupedWidgetLanguageOption[] => {
	return [
		{
			label: T.translate('chatbox.options.language.officiallyTranslated'),
			options: getLanguageSelectOptions(officiallyTranslatedLangs),
		},
		{
			label: T.translate('chatbox.options.language.unofficiallyTranslated'),
			options: getLanguageSelectOptions(unofficiallyTranslatedLangs),
		},
	]
}

export const findDefaultLanguageOption = (options: GroupedWidgetLanguageOption[], language: string) => {
	const defaultLanguage = getOption(language)
	let mergedOptions: WidgetLanguageOption[] = []
	options.forEach((option) => {
		mergedOptions = [...mergedOptions, ...option.options]
	})
	return mergedOptions.find((option) => option.value === defaultLanguage.value)
}

// Widget Options
export const getWidgetOptionsData = (data: WidgetConfig): WidgetOptionsData => {
	return {
		lang: data.lang,
		ratingEnabled: data.ratingEnabled,
		hideWidget: data.hideWidget,
		hideOfflineChat: data.hideOfflineChat,
		consentModeEnabled: data.consentModeEnabled,
		mobilePopupsEnabled: data.mobilePopupsEnabled,
	}
}

// Widget Authorization Form
export const getWidgetAuthFormData = (data: WidgetConfig | WidgetAuthFormMergedData): WidgetAuthFormData => {
	return {
		requireLogin: data.requireLogin,
		emailControl: data.emailControl,
		nameControl: data.nameControl,
		numberControl: data.numberControl,
		privacyNoticeCheckRequired: data.privacyNoticeCheckRequired,
		privacyNoticeUrl: data.privacyNoticeUrl,
		privacyNoticeEnabled: data.privacyNoticeEnabled,
		groupSelectEnabled: data.groupSelectEnabled,
	}
}

// Widget Appearance
export const getWidgetAppearanceFormData = (
	data: WidgetConfig | WidgetAppearanceFormMergedData,
): WidgetAppearanceFormData => {
	return {
		color: data.color,
		color2: data.color2,
		colorGradient: data.colorGradient,
		orientation: data.orientation,
		buttonStyle: data.buttonStyle,
	}
}
