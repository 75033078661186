import { useSelector } from 'react-redux'

import { agentsSelectors } from 'modules/agents'
import { userSelectors } from 'modules/user'

import { Text } from 'core'

const SidebarAgentMenuName = () => {
	const agent = useSelector(agentsSelectors.getAgentByUser)
	const isRoot = useSelector(userSelectors.isUserRoot)
	if (!agent) return null

	return (
		<Text as="h4" maxWidth="100%" fontSize={2} fontWeight="bold" textAlign="center" isTruncated>
			{isRoot && (
				<Text textColor="gray" fontWeight="light" mb={1}>
					[ROOT]
				</Text>
			)}
			{agent.fullname}
		</Text>
	)
}

export default SidebarAgentMenuName
