import { InteractionsSort, RangeType } from './Analytics'
import { Chatbot } from './Chatbot'

export {
	AiChatbotApi,
	AiChatbotStats,
	AiChatbotStatsSummary,
	ChatbotApi,
	ChatbotStats,
	ChatbotStatsSummary,
	CountWithDate,
	DataValidityObject,
	InteractionsSort,
	InteractionStats,
} from '@smartsupp/analytics-api-client-api'

export enum ChatbotStatisticsDataType {
	Triggered = 'triggered',
	ChatbotConversations = 'chatbotConversations',
	Resolved = 'resolved',
	Escalated = 'escalated',
}

export type GetChatbotStatsParams = {
	botId: Chatbot['id']
	range: RangeType
}

export type GetChatbotInteractionsStatsParams = {
	sort: InteractionsSort
} & GetChatbotStatsParams
