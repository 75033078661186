import { ProductNews, ProductNewsApi } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'product-news'

const getProductNews = (lang: string) => {
	return apiClient.get<ProductNews[]>(`${API_RESOURCE}?lang=${lang}`)
}

const getProductNewsCount = () => {
	return apiClient.get<ProductNewsApi.UnreadCountResponse>(`${API_RESOURCE}/unread-count`)
}

const markProductNewsAsRead = () => {
	return apiClient.post(`${API_RESOURCE}/confirm`, {})
}

export const productNewsApi = { getProductNews, getProductNewsCount, markProductNewsAsRead }
