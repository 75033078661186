import { CreateConsentRequest, CreateConsentResponse, FetchConsentsResponse } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'consents'

const fetchUserConsents = () => {
	return apiClient.get<FetchConsentsResponse>(`${API_RESOURCE}/user`)
}

const createUserConsent = (data: CreateConsentRequest) => {
	return apiClient.post<CreateConsentResponse>(`${API_RESOURCE}/user`, data)
}

const createAccountConsent = (data: CreateConsentRequest) => {
	return apiClient.post<CreateConsentResponse>(`${API_RESOURCE}/account`, data)
}

export const consentsApi = {
	fetchUserConsents,
	createUserConsent,
	createAccountConsent,
}
