import { ChatbotStatisticsDataType } from 'models'
import { TransformedChatbotStatistic } from 'modules/chatbotStatistics'

export const INTERACTION_TABLE_ROW_ID = 'interaction-table-row'
export const INTERACTION_BUILDER_NODE_ID = 'chatbot-interaction-node'

export const INTERACTION_TABLE_COLUMN_MIN_WIDTH = '80px'
export const DEFAULT_STATISTICS_VALUE = 0
const DEFAULT_PERCENTAGE_VALUE = '0%'

export const DEFAULT_STATISTICS_ARRAY: TransformedChatbotStatistic[] = [
	{
		name: ChatbotStatisticsDataType.Triggered,
		value: DEFAULT_STATISTICS_VALUE,
		trendPercentage: DEFAULT_STATISTICS_VALUE,
	},
	{
		name: ChatbotStatisticsDataType.ChatbotConversations,
		value: DEFAULT_STATISTICS_VALUE,
		trendPercentage: DEFAULT_STATISTICS_VALUE,
	},
	{
		name: ChatbotStatisticsDataType.Resolved,
		value: DEFAULT_STATISTICS_VALUE,
		trendPercentage: DEFAULT_STATISTICS_VALUE,
		percentage: DEFAULT_PERCENTAGE_VALUE,
	},
	{
		name: ChatbotStatisticsDataType.Escalated,
		value: DEFAULT_STATISTICS_VALUE,
		trendPercentage: DEFAULT_STATISTICS_VALUE,
		percentage: DEFAULT_PERCENTAGE_VALUE,
	},
]

export const DEFAULT_CHATBOT_STATISTICS_ITEMS = [
	ChatbotStatisticsDataType.Triggered,
	ChatbotStatisticsDataType.ChatbotConversations,
	ChatbotStatisticsDataType.Escalated,
]

export const testingAttributes = {
	interactionTable: {
		table: 'chatbot-stats-interaction-table',
		tableRow: 'chatbot-stats-interaction-table-row',
		titleButton: 'chatbot-stats-interaction-table-title-button',
	},
	drawer: {
		drawer: 'chatbot-statistics-drawer',
		closeButton: 'chatbot-statistics-drawer-close',
	},
	summary: {
		summary: 'chatbot-statistics-summary',
		summaryButton: 'chatbot-statistics-summary-button',
	},
	chart: {
		lineChart: 'chatbot-statistics-line-chart',
		emptyNoData: 'chatbot-statistics-chart-empty-no-data',
		emptyNoDataSelected: 'chatbot-statistics-chart-empty-no-data-selected',
		legend: 'chatbot-statistics-chart-legend',
	},
}
