export const ERROR_ALREADY_INVITED = 'already_invited'
export const ERROR_EMAIL_EXISTS = 'email_exists'
export const ERROR_INVALID_RECAPTCHA = 'invalid_recaptcha'
export const ERROR_INVITE_ERRORS = 'invite_errors'
export const ERROR_TOO_MANY_AGENTS = 'too_many_agents'
export const ERROR_TOO_MANY_EXPORTS = 'too_many_exports'
export const ERROR_TOO_MANY_REQUESTS = 'too_many_requests'
export const ERROR_NOT_ALLOWED = 'not_allowed'
export const ERROR_NOT_FOUND = 'not_found'
export const ERROR_PROMO_CODE_INVALID = 'promo_code_invalid'
