import { createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit'
import WebsocketAgentClient, { SocketError } from '@smartsupp/websocket-client-agent'
import moment from 'moment'

import { AppLang } from 'shared/types'
import { AppThunkAction, DashboardState } from 'types'
import { FilesUploadConfig } from 'shared/models/AppConfig'
import { ConfigurationService, logException, TranslationService as T } from 'shared/services'
import MixpanelService, { MixpanelInfoData } from 'services/MixpanelService'
import { accountSelectors } from 'modules/account'
import { initializeAgents } from 'modules/agents'
import { packageSelectors } from 'modules/package'
import { hideNotification, showNotification } from 'modules/systemNotification'
import { userSelectors } from 'modules/user'

import { fetchFileUploadConfig, fetchTranslations } from './actions'
import { DEFAULT_LOCALE } from './constants'

export type AppRootState = Pick<DashboardState, 'app'>

const initialState = {
	fileUploadConfig: null as null | FilesUploadConfig,
	appInitError: null as null | boolean,
	isAppInitPending: true,
	visible: document.visibilityState === 'visible',
	focused: true,
	locale: DEFAULT_LOCALE as AppLang,
	isWidgetRendered: false,
}

export const initMixpanel = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const user = userSelectors.getActiveUser(state)
	const accountId = accountSelectors.getAccountId(state)
	const packageInfo = packageSelectors.getPackageInfo(state)

	const mixpanelData: MixpanelInfoData = {
		name: user?.fullname ?? null,
		$email: user?.email ?? null,
		role: user?.role ?? null,
		accountId,
		package: packageInfo?.name ?? null,
		interval: packageInfo?.interval ?? null,
		isDemo: packageInfo?.isDemo ?? null,
		nextBillingDate: packageInfo?.nextBillingDate ?? null,
		mrrCzk: packageInfo?.mrrCZK ?? 0,
	}
	MixpanelService.setInfo(mixpanelData)
}

export const initAppSuccess = (): AppThunkAction => (dispatch) => {
	dispatch(initApp())
	dispatch(initMixpanel())
}

export const loadTranslations = (): AppThunkAction => async (dispatch) => {
	const configData = ConfigurationService.getData()
	let locale: AppLang = DEFAULT_LOCALE

	try {
		const actionResult = await dispatch(fetchTranslations(DEFAULT_LOCALE))
		const data = unwrapResult(actionResult)
		T.setDefaultLanguageData(data)

		// Fetch additional translations
		if (configData.lang !== DEFAULT_LOCALE) {
			locale = configData.lang
			const actionResultLang = await dispatch(fetchTranslations(locale))
			const dataLang = unwrapResult(actionResultLang)
			T.setData(dataLang)
		}

		dispatch(setLocale(locale))

		// Set moment locale
		moment.locale(locale)
	} catch {
		throw new Error(`Fetch translations '${locale}' failed.`)
	}
}

export const onInitialized =
	(data: WebsocketAgentClient.ConnectedData): AppThunkAction =>
	(dispatch) => {
		dispatch(hideNotification())
		dispatch(initializeAgents(data))
	}

export const onError =
	(error: Error | SocketError): AppThunkAction =>
	(dispatch) => {
		logException(error)
		dispatch(
			showNotification({
				type: 'error',
				title: 'general.error',
			}),
		)
	}

const slice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		initApp: (state) => {
			state.isAppInitPending = false
			state.appInitError = null
		},
		initAppFailure: (state) => {
			state.isAppInitPending = false
			state.appInitError = true
		},
		documentVisibilityChanged: (state, { payload }: PayloadAction<DocumentVisibilityState>) => {
			state.visible = payload === 'visible'
		},
		documentFocusChanged: (state, { payload }: PayloadAction<boolean>) => {
			state.focused = payload
		},
		setLocale: (state, { payload }: PayloadAction<AppLang>) => {
			state.locale = payload
		},
		setIsWidgetRendered: (state, { payload }: PayloadAction<boolean>) => {
			state.isWidgetRendered = payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchFileUploadConfig.fulfilled, (state, { payload }) => {
			state.fileUploadConfig = payload
		})
	},
})

const { reducer, actions } = slice
export const {
	initApp,
	initAppFailure,
	documentVisibilityChanged,
	documentFocusChanged,
	setLocale,
	setIsWidgetRendered,
} = actions
export default reducer
