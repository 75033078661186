import { AlertStatus } from '@chakra-ui/react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunkAction, DashboardState } from 'types'
import { UserPreference } from 'shared/constants'
import { loadUserPreference, storeUserPreference } from 'shared/services'
import { isNumber } from 'shared/utils'
import { trackUpsellEvent } from 'utils'
import { getNewLocalStorageData } from 'modules/systemNotification/utils'
import { UpsellContext, UpsellEventAction, UpsellType } from 'modules/upsell'
import { checkUsageLimits, usageSelectors } from 'modules/usage'

import { LocalStorageItem, ProductNotificationType } from './types'

export type NotificationRootState = Pick<DashboardState, 'systemNotification'>

const initialState = {
	title: null as null | string,
	type: null as null | AlertStatus,
	productNotificationType: null as null | ProductNotificationType,
	aiYearlyNotificationShowed: false,
}

export const updateNotificationPreferences =
	(type: ProductNotificationType, limit: number | null): AppThunkAction =>
	(dispatch) => {
		const localStorageData = loadUserPreference(UserPreference.ProductNotifications, true)
		const data: LocalStorageItem[] = Array.isArray(localStorageData) ? localStorageData : []
		const newLimit = isNumber(limit) ? limit : undefined
		const newData = getNewLocalStorageData(type, data, newLimit)
		storeUserPreference(UserPreference.ProductNotifications, newData, true)
		dispatch(hideProductNotification())
		dispatch(checkUsageLimits())
	}

export const showProductNotification =
	(type: ProductNotificationType): AppThunkAction =>
	(dispatch, getState) => {
		const currentType = getProductNotificationType(getState())
		if (currentType !== type) {
			dispatch(hideProductNotification())
		}

		dispatch(setProductNotification(type))
	}

export const trackProductNotification =
	(upsellType: UpsellType, upsellContext: UpsellContext, action: UpsellEventAction): AppThunkAction =>
	(_, getState) => {
		const { count, limit } = usageSelectors.getMonthlyServedLimits(getState())
		trackUpsellEvent(upsellType, action, {
			context: upsellContext,
			usedPercentage: limit ? count / limit : undefined,
		})
	}

const notificationSlice = createSlice({
	name: 'systemNotification',
	initialState,
	reducers: {
		showNotification: (state, { payload }: PayloadAction<{ type: AlertStatus; title: string }>) => {
			const { type, title } = payload
			state.title = title
			state.type = type
		},
		hideNotification: (state) => {
			state.title = null
			state.type = null
		},
		setProductNotification: (state, { payload }: PayloadAction<ProductNotificationType>) => {
			state.productNotificationType = payload
		},
		hideProductNotification: (state) => {
			state.productNotificationType = null
		},
		setShowAiYearlyNotification: (state, { payload }: PayloadAction<boolean>) => {
			state.aiYearlyNotificationShowed = payload
		},
	},
})

const { actions, reducer } = notificationSlice
export const {
	showNotification,
	hideNotification,
	setProductNotification,
	hideProductNotification,
	setShowAiYearlyNotification,
} = actions
export default reducer

const getNotification = (state: NotificationRootState) => ({
	title: state.systemNotification.title,
	type: state.systemNotification.type,
})

const getProductNotificationType = (state: NotificationRootState) => state.systemNotification.productNotificationType
const getAiYearlyNotificationShowed = (state: NotificationRootState) =>
	state.systemNotification.aiYearlyNotificationShowed

export const notificationSelectors = {
	getNotification,
	getProductNotificationType,
	getAiYearlyNotificationShowed,
}
