import { PackageBalance, PackageInfo, PackageLogEventRequest } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'package'

const fetchPackage = () => {
	return apiClient.get<PackageInfo>(`${API_RESOURCE}`)
}

const fetchPackageBalance = () => {
	return apiClient.get<PackageBalance>(`${API_RESOURCE}/balance`)
}

const logPackageEvent = (data: PackageLogEventRequest) => {
	return apiClient.post(`${API_RESOURCE}/log`, data)
}

export const packageApi = {
	fetchPackage,
	fetchPackageBalance,
	logPackageEvent,
}
