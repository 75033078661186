import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { UserRole } from 'shared/models/User'
import { routes } from 'configuration/routes'
import { packageSelectors } from 'modules/package'
import { userSelectors } from 'modules/user'

type OwnProps = {
	children: ReactNode
	onAuth: (userRole: UserRole) => boolean
}

type Props = OwnProps & RouteProps

const REDIRECT_PATH = '/'

const AuthorizedBillingRoute = ({ children, onAuth, ...rest }: Props) => {
	const user = useSelector(userSelectors.getActiveUser)
	const isUltimate = useSelector(packageSelectors.getIsUltimatePackage)
	const isAuthorized = user && user.role ? onAuth(user.role) : false

	const isAllowed = isAuthorized && !isUltimate
	const redirectPath = isUltimate ? routes.ultimate.path : REDIRECT_PATH

	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAllowed ? children : <Redirect to={{ pathname: redirectPath ?? REDIRECT_PATH, state: { from: location } }} />
			}
		/>
	)
}

export default AuthorizedBillingRoute
