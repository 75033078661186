export enum Key {
	ArrowUp = 'ArrowUp',
	Up = 'Up', // IE/Edge specific value
	ArrowDown = 'ArrowDown',
	Down = 'Down', // IE/Edge specific value
	Enter = 'Enter',
	Escape = 'Escape',
	Esc = 'Esc', // IE/Edge specific value
	Period = '.',
	Slash = '/',
}
