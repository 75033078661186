import { css } from '@emotion/react'

const LogoEyes = () => {
	return (
		<svg css={logoEyesCss} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.387 113.386">
			<g>
				<path
					fill="#4088E6"
					d="M35.563,31.458h1.484c5.234,0,10.029,2.221,14.388,6.664c3.354,3.679,5.231,8.472,5.262,13.45v0.341
	c0,6.848-3.039,12.478-9.124,16.893c-3.297,2.188-7.162,3.354-11.12,3.352h-0.338c-3.25-0.04-6.448-0.841-9.338-2.333v22.534
	l-0.129,0.17h-0.04H16.168l-0.169-0.127v-0.043V51.023c0-5.095,2.176-9.862,6.534-14.304
	C26.776,33.211,31.121,31.458,35.563,31.458 M36.2,42.322h-0.383c-3.142,0-5.787,1.669-7.937,5.008
	c-0.678,1.405-1.055,2.938-1.104,4.498c0,3.706,1.838,6.579,5.519,8.616c1.204,0.537,2.504,0.826,3.819,0.85h0.424
	c3.508,0,6.27-1.727,8.276-5.18c0.665-1.27,1.012-2.682,1.016-4.115v-0.427c0-3.45-1.723-6.222-5.176-8.317
	c-1.18-0.565-2.467-0.884-3.779-0.933h-0.423l-0.083-0.063v-0.022L36.2,42.322z"
				/>
				<path
					fill="#4088E6"
					d="M80.254,31.458h1.484c5.234,0,10.029,2.221,14.388,6.664c3.354,3.679,5.229,8.472,5.262,13.45v0.341
			c0,6.848-3.043,12.478-9.124,16.893c-3.297,2.188-7.162,3.354-11.12,3.352h-0.338c-3.254-0.04-6.448-0.841-9.338-2.333v22.534
			l-0.126,0.17h-0.043H60.859l-0.172-0.127v-0.043V51.023c0-5.095,2.179-9.862,6.537-14.304
			C71.468,33.211,75.81,31.458,80.254,31.458 M80.889,42.322h-0.384c-3.138,0-5.787,1.669-7.933,5.008
			c-0.682,1.405-1.055,2.938-1.104,4.498c0,3.706,1.838,6.579,5.519,8.616c1.2,0.537,2.5,0.826,3.819,0.85h0.424
			c3.508,0,6.266-1.727,8.276-5.18c0.662-1.27,1.012-2.682,1.016-4.115v-0.427c0-3.45-1.723-6.222-5.176-8.317
			c-1.18-0.565-2.467-0.884-3.779-0.933h-0.423l-0.083-0.063v-0.022L80.889,42.322z"
				/>
				<path
					fill="#FFFFFF"
					d="M36.369,37.758c7.394,0,13.39,5.995,13.39,13.389c0,7.396-5.996,13.391-13.39,13.391
			s-13.39-5.995-13.39-13.391C22.979,43.753,28.976,37.758,36.369,37.758"
				/>
				<path
					fill="#2C3C45"
					d="M35.973,42.417c-0.92,0-1.829,0.193-2.666,0.567c1.634,0.32,2.699,1.904,2.378,3.538
			c-0.317,1.635-1.901,2.701-3.538,2.381c-1.135-0.222-2.044-1.076-2.338-2.195c-1.26,3.405,0.479,7.188,3.886,8.448
			c3.405,1.258,7.186-0.479,8.445-3.886c1.26-3.404-0.479-7.187-3.882-8.446C37.526,42.554,36.753,42.417,35.973,42.417"
				/>
				<path
					fill="#FFFFFF"
					d="M80.657,37.758c7.397,0,13.389,5.995,13.389,13.389c0,7.396-5.991,13.391-13.389,13.391
			c-7.395,0-13.39-5.995-13.39-13.391C67.268,43.753,73.263,37.758,80.657,37.758"
				/>
				<path
					fill="#2C3C45"
					d="M80.261,42.417c-0.92,0-1.826,0.193-2.666,0.567c1.634,0.32,2.698,1.904,2.381,3.538
			c-0.32,1.635-1.904,2.701-3.538,2.381c-1.138-0.222-2.043-1.076-2.338-2.195c-1.26,3.405,0.479,7.188,3.882,8.448
			c3.406,1.258,7.187-0.479,8.449-3.886c1.26-3.404-0.479-7.187-3.886-8.446C81.814,42.554,81.041,42.417,80.261,42.417"
				/>
			</g>
		</svg>
	)
}

export const logoEyesCss = css({
	width: '40px',
	height: '40px',
	margin: 'auto',
})

export default LogoEyes
