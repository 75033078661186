export type BottleneckOptions<P extends unknown[]> = {
	keyFn?: (...args: P) => string
}

export function bottleneck<T, P extends unknown[]>(
	neck: (...args: P) => Promise<T>,
	options: BottleneckOptions<P> = {},
): (...args: P) => Promise<T> {
	const bottles = new Map<string, Promise<T>>()

	return (...args: P) => {
		const key = options.keyFn ? options.keyFn(...args) : JSON.stringify(args)

		let bottle = bottles.get(key)
		if (!bottle) {
			bottle = neck(...args).finally(() => {
				bottles.delete(key)
			})

			bottles.set(key, bottle)
		}

		return bottle
	}
}
