import { AnyAction, Middleware } from '@reduxjs/toolkit'
import { ThunkDispatch } from 'redux-thunk'
import { isType } from 'ts-action'

import { NotificationBrowserEventType, SelectedChat } from 'models'
import { DashboardState } from 'types'
import { AgentStatus } from 'shared/models/Agent'
import { MessageSubType } from 'shared/models/Message'
import { TranslationService as T } from 'shared/services'
import { SoundService } from 'services'
import { createNotification } from 'utils'
import { agentsSelectors } from 'modules/agents'
import { appSelectors } from 'modules/app'
import { selectChat } from 'modules/chatDetail'
import { chatsActions, chatsSelectors } from 'modules/chats'
import { groupsSelectors } from 'modules/groups'
import { DEFAULT_GROUP } from 'modules/groups/constants'
import { messageReceived } from 'modules/messages'
import { notificationsSelectors } from 'modules/notifications'
import { userSelectors } from 'modules/user'
import { visitorConnected } from 'modules/visitors'

type NotificationOptions = {
	title: string
	onClick: () => void
	id: string
	body?: string | null
}

const getNotificationTitle = (chat: SelectedChat, state: DashboardState) => {
	const { displayedName, groupId } = chat
	if (groupId) {
		const groups = groupsSelectors.getGroupEntities(state)
		const group = groups[groupId]
		if (group) {
			return `${displayedName} (${group.name})`
		}
	}
	return displayedName
}

const sendBrowserNotification = ({ id, body, title, onClick }: NotificationOptions) => {
	createNotification({
		tag: id,
		title,
		// body: body ?? undefined, // todo: resolve undefined
		...(body && { body }),
		onClick,
	})
}

const playNotificationSound = (isAgentOffline: boolean, type: NotificationBrowserEventType, state: DashboardState) => {
	const browserNotifications = notificationsSelectors.getBrowserNotifications(state)
	if (!browserNotifications) return

	const { enabled, enabledOffline, events } = browserNotifications

	if (!enabled) return
	if (isAgentOffline && !enabledOffline) return

	const sound = events[type]
	if (!sound || !sound.enabled || sound.soundName === '') return

	SoundService.play(sound.soundName)
}

const SoundAndBrowserNotificationsMiddleware: Middleware<
	Record<string, unknown>,
	DashboardState,
	ThunkDispatch<DashboardState, unknown, any>
> =
	({ getState, dispatch }) =>
	(next) =>
	(action: AnyAction) => {
		if (
			action &&
			(action.type === messageReceived.type ||
				action.type === chatsActions.chatAgentAssigned.type ||
				action.type === chatsActions.chatOpened.type ||
				action.type === visitorConnected.type)
		) {
			const state = getState()
			const userId = userSelectors.getUserId(state)
			if (!userId) return next(action)

			const agent = agentsSelectors.getAgentByUser(state)
			if (!agent) return next(action)

			const isAgentOffline = agent.status === AgentStatus.Offline

			// Agent's groups contain Chat groupId OR Chat groupId is null OR agent is without group and Chat has some group
			const isForAgentGroup = (groupId: string | null): boolean => {
				if (!agent) return false
				const isAgentWithoutGroupAndChatHasGroup = agent.groups.length === 0 && !!groupId
				return (
					agent.groups.some((group) => group.key === groupId) ||
					!groupId ||
					groupId === DEFAULT_GROUP ||
					isAgentWithoutGroupAndChatHasGroup
				)
			}

			const notificationClickHandler = (chatId: string) => {
				dispatch(selectChat(chatId))
			}

			// Handle message received
			if (isType(action, messageReceived)) {
				const { message, chatId, isMuted } = action.payload

				if (isMuted) {
					return next(action)
				}
				// Find chat

				const getChatById = chatsSelectors.makeGetChatById()
				const chat = getChatById(state, { chatId })
				if (!chat) return next(action)
				const isUnserved = chat.assignedIds.length === 0

				const isAgentAssigned = chat.assignedIds.includes(userId)
				const msgFromContact = message.subType === MessageSubType.Contact
				if (msgFromContact) {
					const title = getNotificationTitle(chat, state)

					// Unserved chat
					if (isUnserved && isForAgentGroup(chat.groupId)) {
						sendBrowserNotification({
							id: message.id,
							body: message.content?.text || null,
							title,
							onClick: () => notificationClickHandler(chat.id),
						})
						playNotificationSound(isAgentOffline, NotificationBrowserEventType.NewMessage, state)
						return next(action)
					}

					// Served chat: only if chat is served by current user
					if (isAgentAssigned) {
						playNotificationSound(isAgentOffline, NotificationBrowserEventType.NewMessage, state)

						// Document is not visible (browser is minified or user is on other tab) OR window is not focused
						const isAppVisible = appSelectors.isAppVisible(state)
						const isAppFocused = appSelectors.isAppFocused(state)
						if (!isAppVisible || !isAppFocused) {
							sendBrowserNotification({
								id: message.id,
								body: message.content?.text || null,
								title,
								onClick: () => notificationClickHandler(chat.id),
							})
						}

						return next(action)
					}
				} else {
					return next(action)
				}
			} else if (isType(action, chatsActions.chatAgentAssigned)) {
				const { assignedId, chatId } = action.payload

				if (assignedId !== userId) {
					return next(action)
				}

				sendBrowserNotification({
					id: chatId,
					title: T.translate('chat.assigned.notification.title'),
					onClick: () => notificationClickHandler(chatId),
				})
				playNotificationSound(isAgentOffline, NotificationBrowserEventType.UnservedChat, state)
			} else if (isType(action, chatsActions.chatOpened)) {
				const { isMuted, chatId } = action.payload

				if (isMuted) {
					return next(action)
				}

				// Find chat
				const getChatById = chatsSelectors.makeGetChatById()
				const chat = getChatById(state, { chatId })
				if (!chat) return next(action)

				if (isForAgentGroup(chat.groupId)) {
					const title = getNotificationTitle(chat, state)
					sendBrowserNotification({
						id: chat.id,
						title,
						onClick: () => notificationClickHandler(chatId),
					})
					playNotificationSound(isAgentOffline, NotificationBrowserEventType.UnservedChat, state)
				}
			} else if (isType(action, visitorConnected)) {
				playNotificationSound(isAgentOffline, NotificationBrowserEventType.NewVisitor, state)
			}
		}

		return next(action)
	}

export default SoundAndBrowserNotificationsMiddleware
