import {
	createAsyncThunk,
	createEntityAdapter,
	createSelector,
	createSlice,
	EntityId,
	PayloadAction,
} from '@reduxjs/toolkit'

import {
	ChatbotCategory,
	ChatbotGroupType,
	ChatbotPromotedTemplateType,
	ChatbotTemplate,
	ChatbotTemplateSimple,
	ChatbotTemplateSimpleExtended,
	ChatbotTemplatesModalsTypes,
	LeadgenPromotedTemplateType,
	TemplateStorePromotedTemplateType,
} from 'models'
import { AppLang } from 'shared/types'
import { DashboardState } from 'types'
import { ConfigurationService } from 'shared/services'

import { chatbotTemplatesApi } from './api'
import { templatesTagsMap } from './constants'
import { getShouldAttachRibbon, isLeadgenPromotedTemplate, isSalesPromotedTemplate } from './utils'

export type ChatbotTemplatesRootState = Pick<DashboardState, 'chatbotTemplates'>
export type ChatbotTemplatesState = typeof initialState

type ModalSettings = {
	name: ChatbotTemplatesModalsTypes
	isOpen: boolean
	selectedTemplate: TemplateStorePromotedTemplateType
}

const chatbotTemplatesAdapter = createEntityAdapter<ChatbotTemplateSimple>()

export const initialState = chatbotTemplatesAdapter.getInitialState({
	isFetchingTemplate: false,
	isFetchingTemplates: false,
	isFetchingChatbotTemplate: false,
	selectedTemplate: null as null | ChatbotTemplate,
	chatbotTemplates: [] as ChatbotTemplateSimple[],
	isTemplateListDrawerOpen: false,
	modals: {
		sales: {
			isOpen: false,
			selectedTemplate: ChatbotCategory.ChatbotIncreaseEngagement as ChatbotPromotedTemplateType,
		},
		leadgen: {
			isOpen: false,
			selectedTemplate: ChatbotCategory.ChatbotLeadCollection as LeadgenPromotedTemplateType,
		},
		all: {
			isOpen: false,
			selectedTemplate: ChatbotCategory.ChatbotLeadCollection as TemplateStorePromotedTemplateType,
		},
	},
	isTemplateSuggestionModalOpen: false,
})

export const fetchChatbotTemplates = createAsyncThunk('chatbotTemplates/GET', () => {
	const { lang } = ConfigurationService.getData()
	return chatbotTemplatesApi.getChatbotTemplates(lang)
})

export const fetchChatbotOnboardingTemplate = createAsyncThunk(
	'chatbotTemplatesOnboarding/GET',
	(widgetLang: AppLang) => {
		return chatbotTemplatesApi.getChatbotOnboardingTemplate(widgetLang)
	},
)

export const fetchChatbotTemplate = createAsyncThunk('chatbotTemplate/GET', async (id: EntityId) => {
	return chatbotTemplatesApi.getChatbotTemplate(id)
})

export const sendTemplateSuggestion = createAsyncThunk('chatbotTemplateSuggestion/POST', async (message: string) => {
	return chatbotTemplatesApi.sendTemplateSuggestion(message)
})

const chatbotTemplatesSlice = createSlice({
	name: 'chatbotTemplates',
	initialState,
	reducers: {
		openTemplateListDrawer: (state) => {
			state.isTemplateListDrawerOpen = true
		},
		closeTemplateListDrawer: (state) => {
			state.isTemplateListDrawerOpen = false
		},
		openTemplatesModal: (state, { payload }: PayloadAction<ChatbotTemplatesModalsTypes>) => {
			state.modals[payload].isOpen = true
		},
		closeTemplatesModal: (state, { payload }: PayloadAction<ChatbotTemplatesModalsTypes>) => {
			state.modals[payload].isOpen = false
		},
		setModalsSelectedTemplate: (
			state,
			{ payload }: PayloadAction<Pick<ModalSettings, 'name' | 'selectedTemplate'>>,
		) => {
			state.modals[payload.name].selectedTemplate = payload.selectedTemplate
		},
		openTemplateSuggestionModal: (state) => {
			state.isTemplateSuggestionModalOpen = true
		},
		closeTemplateSuggestionModal: (state) => {
			state.isTemplateSuggestionModalOpen = false
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchChatbotTemplate.pending, (state) => {
				state.isFetchingTemplate = true
			})
			.addCase(fetchChatbotTemplate.fulfilled, (state, { payload }) => {
				state.isFetchingTemplate = false
				state.selectedTemplate = payload
			})
			.addCase(fetchChatbotTemplate.rejected, (state) => {
				state.isFetchingTemplate = false
			})

		builder
			.addCase(fetchChatbotTemplates.pending, (state) => {
				state.isFetchingTemplates = true
			})
			.addCase(fetchChatbotTemplates.fulfilled, (state, { payload }) => {
				chatbotTemplatesAdapter.setAll(state, payload)
				state.isFetchingTemplates = false
			})
			.addCase(fetchChatbotTemplates.rejected, (state) => {
				state.isFetchingTemplates = false
			})

		builder
			.addCase(fetchChatbotOnboardingTemplate.pending, (state) => {
				state.isFetchingTemplate = true
			})
			.addCase(fetchChatbotOnboardingTemplate.fulfilled, (state, { payload }) => {
				chatbotTemplatesAdapter.addOne(state, payload)
				state.selectedTemplate = payload
				state.isFetchingTemplate = false
			})
			.addCase(fetchChatbotOnboardingTemplate.rejected, (state) => {
				state.isFetchingTemplate = false
			})
	},
})

const entitySelectors = chatbotTemplatesAdapter.getSelectors<ChatbotTemplatesRootState>(
	(state) => state.chatbotTemplates,
)
const getAllTemplates = (state: ChatbotTemplatesRootState) => entitySelectors.selectAll(state)
const getChatbotTemplateEntities = (state: ChatbotTemplatesRootState) => entitySelectors.selectEntities(state)
const getChatbotTemplateIds = (state: ChatbotTemplatesRootState) => entitySelectors.selectIds(state)
const getIsFetchingTemplate = (state: ChatbotTemplatesRootState) => state.chatbotTemplates.isFetchingTemplate
const getIsFetchingTemplates = (state: ChatbotTemplatesRootState) => state.chatbotTemplates.isFetchingTemplates
const getSelectedTemplate = (state: ChatbotTemplatesRootState) => state.chatbotTemplates.selectedTemplate
const getIsTemplateListDrawerOpen = (state: ChatbotTemplatesRootState) =>
	state.chatbotTemplates.isTemplateListDrawerOpen
const getModalsData = (state: ChatbotTemplatesRootState) => state.chatbotTemplates.modals
const getIsTemplateSuggestionModalOpen = (state: ChatbotTemplatesRootState) =>
	state.chatbotTemplates.isTemplateSuggestionModalOpen

const getChatbotTemplateId = (
	state: ChatbotTemplatesRootState,
	{ chatbotTemplateId }: { chatbotTemplateId: EntityId },
) => chatbotTemplateId

const getOnboardingChatbotTemplate = createSelector([getAllTemplates], (templates): ChatbotTemplateSimple | null => {
	const filteredTemplates = templates.filter((template) => template.type === ChatbotCategory.ChatbotOnboarding)
	if (filteredTemplates.length > 0) {
		return filteredTemplates[0]
	}
	return null
})

const getMessagesTemplates = createSelector([getAllTemplates], (templates): ChatbotTemplateSimple[] => {
	return templates
		.filter((template) => template.groupType === ChatbotGroupType.Message)
		.filter((t) => t.type !== ChatbotCategory.ChatbotAutoMessage)
})

const getChatbotTemplates = createSelector([getAllTemplates], (templates): ChatbotTemplateSimple[] => {
	const filteredTemplates = templates
		.filter(
			(template) => template.groupType === ChatbotGroupType.Chatbot || template.groupType === ChatbotGroupType.Message,
		)
		.filter(
			(t) =>
				t.type !== ChatbotCategory.Chatbot &&
				t.type !== ChatbotCategory.ChatbotAutoMessage &&
				t.type !== ChatbotCategory.ChatbotOnboarding,
		)

	return filteredTemplates
})

const getCustomTemplatesChatbot = createSelector([getAllTemplates], (templates): ChatbotTemplateSimple[] => {
	return templates.filter((template) => template.type === ChatbotCategory.Chatbot)
})

const getCustomTemplatesAutoMessage = createSelector([getAllTemplates], (templates): ChatbotTemplateSimple[] => {
	return templates.filter((template) => template.type === ChatbotCategory.ChatbotAutoMessage)
})

const getTemplateAiChatbot = createSelector([getAllTemplates], (templates): ChatbotTemplateSimple | null => {
	return templates.find((template) => template.type === ChatbotCategory.AiChatbotCustom) ?? null
})

const getLeadgenModalTemplates = createSelector([getAllTemplates], (templates): ChatbotTemplateSimple[] => {
	return templates.filter((template) => isLeadgenPromotedTemplate(template.type))
})

const getSalesModalTemplates = createSelector([getAllTemplates], (templates): ChatbotTemplateSimple[] => {
	return templates.filter((template) => isSalesPromotedTemplate(template.type))
})

const makeGetChatbotTemplateById = () =>
	createSelector(
		[getChatbotTemplateEntities, getChatbotTemplateId],
		(templates, templateId: EntityId): ChatbotTemplateSimple | null => {
			return templates[templateId] ?? null
		},
	)

const getSelectedTemplateId = (name: ChatbotTemplatesModalsTypes) => {
	return createSelector([getAllTemplates, getModalsData], (templates, modals): string | null => {
		const { selectedTemplate } = modals[name]
		return templates.find((template) => template.type === selectedTemplate)?.id ?? null
	})
}

const getTemplatesModalData = (name: ChatbotTemplatesModalsTypes) => {
	return createSelector([getModalsData], (modals) => {
		return modals[name]
	})
}

const getLeadCollectionTemplateId = createSelector([getAllTemplates], (templates): string | null => {
	const leadCollectionTemplate = templates.find((template) => template.type === ChatbotCategory.ChatbotLeadCollection)
	return leadCollectionTemplate ? leadCollectionTemplate.id : null
})

const getBasicChatbotId = createSelector([getAllTemplates], (templates): string | null => {
	const chatbotBasicTemplate = templates.find((template) => template.type === ChatbotCategory.Chatbot)
	return chatbotBasicTemplate ? chatbotBasicTemplate.id : null
})

const getModifiedTemplates = createSelector([getChatbotTemplates], (faqTemplates): ChatbotTemplateSimpleExtended[] => {
	return faqTemplates.map((template) => {
		return {
			...template,
			tags: templatesTagsMap[template.type as TemplateStorePromotedTemplateType],
			ribbon: getShouldAttachRibbon(template.type as TemplateStorePromotedTemplateType),
		}
	})
})

const getBasicChatbotAndAutoMessageTemplates = createSelector(
	[getCustomTemplatesChatbot, getCustomTemplatesAutoMessage],
	(chatbot, autoMessage): ChatbotTemplateSimple[] => {
		return [...chatbot, ...autoMessage]
	},
)

const getCustomTemplatesAutoMessageId = createSelector(
	[getCustomTemplatesAutoMessage],
	(autoMessage): string | null => {
		return autoMessage.find((t) => t.type === ChatbotCategory.ChatbotAutoMessage)?.id ?? null
	},
)

const getCustomTemplatesChatbotId = createSelector([getCustomTemplatesChatbot], (chatbot): string | null => {
	return chatbot.find((t) => t.type === ChatbotCategory.Chatbot)?.id ?? null
})

const { reducer, actions } = chatbotTemplatesSlice
export const {
	openTemplateListDrawer,
	closeTemplateListDrawer,
	openTemplatesModal,
	closeTemplatesModal,
	setModalsSelectedTemplate,
	openTemplateSuggestionModal,
	closeTemplateSuggestionModal,
} = actions

export const chatbotTemplatesSelectors = {
	getIsFetchingTemplate,
	getIsFetchingTemplates,
	getChatbotTemplates,
	getAllTemplates,
	getSelectedTemplate,
	getIsTemplateListDrawerOpen,
	getOnboardingChatbotTemplate,
	getChatbotTemplateIds,
	getCustomTemplatesChatbot,
	getCustomTemplatesAutoMessage,
	getTemplateAiChatbot,
	makeGetChatbotTemplateById,
	getMessagesTemplates,
	getLeadgenModalTemplates,
	getSelectedTemplateId,
	getTemplatesModalData,
	getSalesModalTemplates,
	getLeadCollectionTemplateId,
	getBasicChatbotId,
	getModifiedTemplates,
	getIsTemplateSuggestionModalOpen,
	getBasicChatbotAndAutoMessageTemplates,
	getCustomTemplatesAutoMessageId,
	getCustomTemplatesChatbotId,
}

export default reducer
