export const mobilePlatformName = {
	Android: /android/i.test(window.navigator.userAgent),
	iOS: /iphone|ipad|ipod/i.test(window.navigator.userAgent),
	iPad: /ipad/i.test(window.navigator.userAgent),
}

export const isMobilePlatform = () => {
	return mobilePlatformName.iOS || mobilePlatformName.Android
}

export const isMobileIOS = () => {
	return mobilePlatformName.iOS && !mobilePlatformName.iPad
}
