import { OfficeHoursDay } from '@smartsupp/core-client-api'
import moment from 'moment'

import { OfficeHours, OfficeHoursFormData, OfficeHoursInterval, OfficeHoursTimesFormData } from 'models'
import { DEFAULT_INTERVAL, OfficeHoursValidationOutput, ValidationErrorCodes } from 'modules/officeHours'

export const convertIntervalsToString = (intervals: OfficeHoursInterval[]) => {
	return intervals.map((interval) => ({
		from: moment().startOf('day').add(interval.from, 'minutes').format('HH:mm'),
		to: moment().startOf('day').add(interval.to, 'minutes').format('HH:mm'),
	}))
}

export const getConvertedIntervals = (intervals?: OfficeHoursInterval[]) => {
	if (!intervals) return []
	if (intervals.length > 0) {
		return convertIntervalsToString(intervals)
	}
	return [DEFAULT_INTERVAL]
}

export const getDefaultOfficeHoursDayEnabled = (data?: OfficeHoursDay): boolean => {
	return !!(data?.enabled && data.times.length > 0)
}

export const getConvertedOfficeHoursDay = (data?: OfficeHoursDay) => {
	return { enabled: getDefaultOfficeHoursDayEnabled(data), times: getConvertedIntervals(data?.times) }
}

export const convertIntervalsToMinutes = (intervals: OfficeHoursTimesFormData[]): OfficeHoursInterval[] => {
	return intervals.map((interval) => ({
		from: moment.duration(interval.from).asMinutes(),
		to: moment.duration(interval.to).asMinutes(),
	}))
}

export const getOfficeHoursIntervalValidationRegex = (): RegExp => {
	return /^(([01]\d)|(2[0-3])):([0-5]\d)$/
}

const ERROR_FROM_GREATER = 'error.fromGreater'
const ERROR_INTERSECTION = 'error.intersection'
const ERROR_INVALID_VALUE = 'error.invalidValue'

const addValidationError = (errors: ValidationErrorCodes, index: number, code: string): ValidationErrorCodes => {
	const validationErrors: ValidationErrorCodes = errors
	if (!validationErrors[`index.${index}`]) {
		validationErrors[`index.${index}`] = []
	}
	if (!validationErrors[`index.${index}`].some((e) => e.code === code)) {
		validationErrors[`index.${index}`].push({ code })
	}
	return validationErrors
}

export const validateOfficeHours = (intervals: OfficeHoursTimesFormData[]): OfficeHoursValidationOutput => {
	let validationErrors: ValidationErrorCodes = {}
	const usedIntervals: OfficeHoursTimesFormData[] = []
	const validationRegex: RegExp = getOfficeHoursIntervalValidationRegex()

	intervals.forEach((interval, index) => {
		if (interval.from >= interval.to) {
			validationErrors = addValidationError(validationErrors, index, ERROR_FROM_GREATER)
		}

		if (!validationRegex.exec(interval.to) || !validationRegex.exec(interval.from)) {
			validationErrors = addValidationError(validationErrors, index, ERROR_INVALID_VALUE)
		}

		usedIntervals.forEach((usedInterval) => {
			if (interval.to >= usedInterval.from && usedInterval.to >= interval.from) {
				validationErrors = addValidationError(validationErrors, index, ERROR_INTERSECTION)
			}
		})
		usedIntervals.push(interval)
	})

	return {
		count: Object.keys(validationErrors).length,
		errors: validationErrors,
	}
}

export const transformOfficeHoursFormData = (formData: OfficeHoursFormData): OfficeHours => {
	return {
		isActive: formData.isActive,
		config: {
			sunday: {
				enabled: formData.isActiveSunday,
				times: convertIntervalsToMinutes(formData.officeHoursTimesSunday),
			},
			monday: {
				enabled: formData.isActiveMonday,
				times: convertIntervalsToMinutes(formData.officeHoursTimesMonday),
			},
			tuesday: {
				enabled: formData.isActiveTuesday,
				times: convertIntervalsToMinutes(formData.officeHoursTimesTuesday),
			},
			wednesday: {
				enabled: formData.isActiveWednesday,
				times: convertIntervalsToMinutes(formData.officeHoursTimesWednesday),
			},
			thursday: {
				enabled: formData.isActiveThursday,
				times: convertIntervalsToMinutes(formData.officeHoursTimesThursday),
			},
			friday: {
				enabled: formData.isActiveFriday,
				times: convertIntervalsToMinutes(formData.officeHoursTimesFriday),
			},
			saturday: {
				enabled: formData.isActiveSaturday,
				times: convertIntervalsToMinutes(formData.officeHoursTimesSaturday),
			},
		},
	}
}
