import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { CurrencyCode } from 'models'
import { isNumber } from 'shared/utils'
import {
	CalculationTrigger,
	checkoutSelectors,
	DEFAULT_UNDEFINED_CURRENCY,
	SubscriptionCalculationHook,
} from 'modules/checkout'
import { packageSelectors } from 'modules/package'
import { stripeSelectors, stripeSharedSelectors } from 'modules/stripe'
import { subscriptionSelectors } from 'modules/subscription'

const useCalculation = (): SubscriptionCalculationHook => {
	const isCalculatePending = useSelector(subscriptionSelectors.getIsCalculatePending)
	const calculation = useSelector(checkoutSelectors.getCheckoutCalculation)
	const monthlyBasePrice = useSelector(checkoutSelectors.getCheckoutCalculationMonthlyBasePrice)
	const discounts = useSelector(checkoutSelectors.getCheckoutCalculationDiscounts)
	const calculationTrigger = useSelector(checkoutSelectors.getCalculationTrigger)
	const isCalculationValid = useSelector(checkoutSelectors.getIsCheckoutCalculationValid)

	const chatbotConversationsBatchSize = useSelector(checkoutSelectors.getCheckoutChatbotConversationsBatchSize)
	const livechatConversationsBatchSize = useSelector(checkoutSelectors.getCheckoutLivechatConversationsBatchSize)
	const aiConversationsBatchSize = useSelector(checkoutSelectors.getCheckoutAiConversationsBatchSize)

	const hasDiscounts = discounts.length > 0

	const hasPaymentAmount = !!calculation && calculation.priceFinalTaxed > 0
	const isPriceDifferent = !!(calculation && calculation.priceFinalTaxed !== calculation.priceTotalTaxed)
	const hasOverpayment = !!(calculation && calculation.priceFinalTaxed < 0)

	// V1 & V2 has different price for extra agent than V3, V5, ...
	const hasDifferentPriceForExtraAgent = !!(
		calculation && calculation.extraAgentPriceTotalUntaxed !== calculation.basePriceTotalUntaxed
	)

	const nextBillingDate = calculation ? moment(calculation.nextBillingDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
	const nextBillingAmount = calculation?.nextBillingAmount

	const isPriceLoaded = !isCalculatePending && isCalculationValid

	const allowCalculationLoading = useCallback(
		(triggers: CalculationTrigger[]) => triggers.includes(calculationTrigger),
		[calculationTrigger],
	)

	// Agents specific
	const isAgentsLoading =
		calculation === null ||
		(allowCalculationLoading([CalculationTrigger.SetAgents, CalculationTrigger.Default]) && !isPriceLoaded)
	const sumExtraAgentsPrice = calculation ? calculation.extraAgentsCount * calculation.extraAgentPriceTotalUntaxed : 0

	// Chatbots specific
	const isChatbotsLoading =
		calculation === null ||
		(allowCalculationLoading([CalculationTrigger.SetChatbot, CalculationTrigger.Default]) && !isPriceLoaded)
	const sumExtraChatbotsPrice = calculation
		? calculation.chatbotConversationsBatchesExtra * calculation.chatbotConversationsBatchPriceTotalUntaxed
		: 0
	const extraChatbotConversationsCount =
		calculation?.chatbotConversationsBatchesExtra && isNumber(chatbotConversationsBatchSize)
			? calculation.chatbotConversationsBatchesExtra * chatbotConversationsBatchSize
			: 0

	// Livechat conversations specific
	const isLivechatConversationsLoading =
		calculation === null ||
		(allowCalculationLoading([CalculationTrigger.SetLivechatConversation, CalculationTrigger.Default]) &&
			!isPriceLoaded)
	const sumExtraLivechatConversationsPrice = calculation
		? calculation.livechatConversationsBatchesExtra * calculation.livechatConversationsBatchPriceTotalUntaxed
		: 0
	const extraLivechatConversationsCount =
		calculation?.livechatConversationsBatchesExtra && isNumber(livechatConversationsBatchSize)
			? calculation.livechatConversationsBatchesExtra * livechatConversationsBatchSize
			: 0

	// AI conversations specific
	const isAiConversationsLoading =
		calculation === null ||
		(allowCalculationLoading([CalculationTrigger.SetAiConversation, CalculationTrigger.Default]) && !isPriceLoaded)
	const sumExtraAiConversationsPrice = calculation
		? calculation.aiConversationsBatchesExtra * calculation.aiConversationsBatchPriceTotalUntaxed
		: 0
	const extraAiConversationsCount =
		calculation?.aiConversationsBatchesExtra && isNumber(aiConversationsBatchSize)
			? calculation.aiConversationsBatchesExtra * aiConversationsBatchSize
			: 0

	const flashDealBasePriceUntaxed =
		calculation && calculation.flashDealBasePriceUntaxed && calculation.flashDealBasePriceUntaxed > 0
			? calculation.flashDealBasePriceUntaxed
			: 0

	return {
		isCalculationLoaded: !!calculation && isCalculationValid,
		currencyCode: calculation?.currencyCode ?? DEFAULT_UNDEFINED_CURRENCY,
		packagePrice: monthlyBasePrice ?? 0,
		extraAgentsCount: calculation?.extraAgentsCount ?? 0,
		extraChatbotConversationsCount,
		extraLivechatConversationsCount,
		extraAiConversationsCount,
		extraAgentPriceTotalUntaxed: calculation?.extraAgentPriceTotalUntaxed ?? 0,
		chatbotConversationsBatchPriceTotalUntaxed: calculation?.chatbotConversationsBatchPriceTotalUntaxed ?? 0,
		livechatConversationsBatchPriceTotalUntaxed: calculation?.livechatConversationsBatchPriceTotalUntaxed ?? 0,
		aiConversationsBatchPriceTotalUntaxed: calculation?.aiConversationsBatchPriceTotalUntaxed ?? 0,
		vatRate: calculation?.vatRate ?? 0,
		vatValue: calculation?.vatValue ?? 0,
		priceFinalUntaxed: calculation?.priceFinalUntaxed ?? 0,
		priceFinalTaxed: calculation?.priceFinalTaxed ?? 0,
		priceBeforeDiscount: 0,
		flashDealBasePriceUntaxed: flashDealBasePriceUntaxed || null,
		flashDealExtraAgentPriceUntaxed: calculation?.flashDealExtraAgentPriceUntaxed || null,
		isCalculatePending,
		hasPaymentAmount,
		isPriceDifferent,
		hasDifferentPriceForExtraAgent,
		discounts,
		hasDiscounts,
		hasOverpayment,
		nextBillingDate,
		nextBillingAmount,
		isAgentsLoading,
		sumExtraAgentsPrice,
		isChatbotsLoading,
		sumExtraChatbotsPrice,
		isLivechatConversationsLoading,
		sumExtraLivechatConversationsPrice,
		isAiConversationsLoading,
		sumExtraAiConversationsPrice,
	}
}

const useStripeCalculation = (): SubscriptionCalculationHook => {
	const calculationData = useSelector(stripeSelectors.getCalculationSubscriptionData)
	const hasUnpaidInvoice = useSelector(stripeSharedSelectors.getHasUnpaidInvoice)
	const unpaidInvoiceData = useSelector(stripeSelectors.getUnpaidInvoiceSubscriptionData)

	const data = hasUnpaidInvoice && unpaidInvoiceData ? unpaidInvoiceData : calculationData

	const {
		calculationTotal,
		packageBasePrice,
		extraAgents,
		extraChatbots,
		extraLivechatConversations,
		extraAiConversations,
		isCalculatePending,
		discounts,
		calculationTrigger,
		taxPercent,
		isCalculationValid,
		livechatConversationsBatchSize,
		aiConversationsBatchSize,
		priceBeforeDiscount,
		agentsTotalPrice,
		chatbotConversationsTotalPrice,
		livechatConversationsTotalPrice,
		aiConversationsTotalPrice,
	} = data

	const nextBillingDate = calculationTotal
		? moment(calculationData.calculationTotal?.nextBillingDate).format('YYYY-MM-DD')
		: null
	const nextBillingAmount = calculationData.calculationTotal?.nextBillingAmount

	const hasPaymentAmount = !!calculationTotal && calculationTotal.total > 0
	const hasOverpayment = !!calculationTotal && calculationTotal.total < 0

	const isPriceDifferent = !!calculationTotal && calculationTotal.total !== nextBillingAmount

	const hasDifferentPriceForExtraAgent = false // for legacy pricings (not used in Stripe)
	const hasDiscounts = discounts.length > 0

	const isPriceLoaded = !isCalculatePending && isCalculationValid

	const allowCalculationLoading = useCallback(
		(triggers: CalculationTrigger[]) => triggers.includes(calculationTrigger),
		[calculationTrigger],
	)

	// Agents specific
	const isAgentsLoading =
		calculationTotal === null ||
		(allowCalculationLoading([CalculationTrigger.SetAgents, CalculationTrigger.Default]) && !isPriceLoaded)

	// Chatbots specific
	const isChatbotsLoading =
		calculationTotal === null ||
		(allowCalculationLoading([CalculationTrigger.SetChatbot, CalculationTrigger.Default]) && !isPriceLoaded)

	// Livechat conversations specific
	const isLivechatConversationsLoading =
		calculationTotal === null ||
		(allowCalculationLoading([CalculationTrigger.SetLivechatConversation, CalculationTrigger.Default]) &&
			!isPriceLoaded)

	// AI conversations specific
	const isAiConversationsLoading =
		calculationTotal === null ||
		(allowCalculationLoading([CalculationTrigger.SetAiConversation, CalculationTrigger.Default]) && !isPriceLoaded)

	return {
		isCalculationLoaded: !!calculationTotal && isCalculationValid,
		currencyCode: (calculationTotal?.currency as CurrencyCode) ?? DEFAULT_UNDEFINED_CURRENCY,
		packagePrice: packageBasePrice ?? 0,
		extraAgentsCount: extraAgents?.quantity ?? 0,
		extraChatbotConversationsCount: extraChatbots?.quantity ?? 0,
		extraLivechatConversationsCount: extraLivechatConversations?.quantity ?? 0,
		extraAiConversationsCount: extraAiConversations?.quantity ?? 0,
		extraAgentPriceTotalUntaxed: extraAgents?.unitPrice ?? 0,
		chatbotConversationsBatchPriceTotalUntaxed: extraChatbots?.unitPrice ?? 0,
		livechatConversationsBatchPriceTotalUntaxed:
			extraLivechatConversations && livechatConversationsBatchSize
				? extraLivechatConversations.unitPrice * livechatConversationsBatchSize
				: 0,
		aiConversationsBatchPriceTotalUntaxed:
			extraAiConversations && aiConversationsBatchSize ? extraAiConversations.unitPrice * aiConversationsBatchSize : 0,
		vatRate: taxPercent ?? 0,
		vatValue: calculationTotal?.totalTaxAmounts ?? 0,
		priceFinalUntaxed: calculationTotal?.totalExcludingTax ?? 0,
		priceFinalTaxed: calculationTotal?.total ?? 0,
		priceBeforeDiscount,
		amountDue: calculationTotal?.amountDue ?? 0,
		balanceApplied: calculationTotal?.balanceApplied ?? 0,
		flashDealBasePriceUntaxed: null,
		flashDealExtraAgentPriceUntaxed: null,
		isCalculatePending,
		hasPaymentAmount,
		hasOverpayment,
		isPriceDifferent,
		hasDifferentPriceForExtraAgent,
		discounts,
		hasDiscounts,
		nextBillingDate,
		nextBillingAmount,
		isAgentsLoading,
		sumExtraAgentsPrice: agentsTotalPrice,
		isChatbotsLoading,
		sumExtraChatbotsPrice: chatbotConversationsTotalPrice,
		isLivechatConversationsLoading,
		sumExtraLivechatConversationsPrice: livechatConversationsTotalPrice,
		isAiConversationsLoading,
		sumExtraAiConversationsPrice: aiConversationsTotalPrice,
	}
}

export const useSubscriptionCalculation = () => {
	const isAllowedStripePaymentGateway = useSelector(packageSelectors.getIsAllowedStripePaymentGateway)

	const calculation = useCalculation()
	const stripeCalculation = useStripeCalculation()

	return isAllowedStripePaymentGateway ? stripeCalculation : calculation
}
