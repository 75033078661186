import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiError } from 'shared/types'
import { AppThunkAction, DashboardState } from 'types'
import { Agent } from 'shared/models/Agent'
import { Group, GroupCreateRequest, GroupEditParams } from 'shared/models/Group'
import { Visitor } from 'shared/models/Visitor'
import { flashMessage } from 'shared/utils'
import { createGroupCreateLink } from 'configuration/routes'
import { navigateTo, normalize } from 'utils'
import { agentsActions } from 'modules/agents'

import { groupsApi } from './api'

export type GroupsRootState = Pick<DashboardState, 'groups'>
export type GroupsState = typeof initialState

const groupsAdapter = createEntityAdapter<Group>()

export const initialState = groupsAdapter.getInitialState({
	isFetching: false,
	isCreating: false,
	isDeleting: false,
	createGroupModal: { isOpen: false, group: null as null | Group },
})

export const fetchGroups = createAsyncThunk('groups/FETCH', () => {
	return groupsApi.getGroups()
})

export const createGroup = createAsyncThunk('groups/CREATE', async (data: GroupCreateRequest, { rejectWithValue }) => {
	try {
		return await groupsApi.createGroup(data)
	} catch (error) {
		return rejectWithValue(error)
	}
})

export const editGroup = createAsyncThunk<Group, GroupEditParams, { rejectValue: ApiError }>(
	'group/EDIT',
	async ({ id, changes }, { rejectWithValue }) => {
		try {
			return await groupsApi.editGroup(id, changes)
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

export const deleteGroup = createAsyncThunk('groups/DELETE', (id: number) => {
	return groupsApi.deleteGroup(id)
})

export const addGroup = (): AppThunkAction => async (dispatch) => {
	const resultAction = await dispatch(createGroup({ name: '', agents: [] }))
	if (createGroup.fulfilled.match(resultAction)) {
		const groupId = resultAction.payload.id
		navigateTo(createGroupCreateLink(groupId))
	} else flashMessage.error('general.error')
}

export const addGroupAndOpenModal = (): AppThunkAction => async (dispatch) => {
	const resultAction = await dispatch(createGroup({ name: '', agents: [] }))
	if (createGroup.fulfilled.match(resultAction)) {
		dispatch(openCreateGroupModal(resultAction.payload))
	} else flashMessage.error('general.error')
}

const groupsSlice = createSlice({
	name: 'groups',
	initialState,
	reducers: {
		openCreateGroupModal: (state, { payload }: PayloadAction<Group>) => {
			state.createGroupModal = { isOpen: true, group: payload }
		},
		closeCreateGroupModal: (state) => {
			state.createGroupModal = { isOpen: false, group: null }
		},
	},
	extraReducers: (builder) => {
		// Fetch groups
		builder
			.addCase(fetchGroups.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchGroups.fulfilled, (state, { payload }) => {
				state.isFetching = false
				groupsAdapter.setAll(state, payload)
			})
			.addCase(fetchGroups.rejected, (state) => {
				state.isFetching = false
			})

		// Create group
		builder
			.addCase(createGroup.pending, (state) => {
				state.isCreating = true
			})
			.addCase(createGroup.fulfilled, (state, { payload }) => {
				state.isCreating = false
				groupsAdapter.addOne(state, payload)
			})
			.addCase(createGroup.rejected, (state) => {
				state.isCreating = false
			})

		// Edit Group
		builder
			.addCase(editGroup.pending, (state) => {
				state.isCreating = true
			})
			.addCase(editGroup.fulfilled, (state, { payload }) => {
				state.isCreating = false
				groupsAdapter.updateOne(state, { id: payload.id, changes: payload })
			})
			.addCase(editGroup.rejected, (state) => {
				state.isCreating = false
			})

		// Delete Group
		builder
			.addCase(deleteGroup.pending, (state) => {
				state.isDeleting = true
			})
			.addCase(deleteGroup.fulfilled, (state, { meta }) => {
				groupsAdapter.removeOne(state, meta.arg)
				state.isDeleting = false
			})
			.addCase(deleteGroup.rejected, (state) => {
				state.isDeleting = false
			})

		// Handle agent group update
		builder.addCase(agentsActions.updateAgentFromWebsocket, (state, { payload }) => {
			const id = Number(payload.id)
			const { changes }: { changes: Partial<Pick<Agent, 'groups'>> } = payload
			if ('groups' in changes) {
				Object.values(state.entities).forEach((group) => {
					if (!group || !changes.groups) return

					const shouldAgentBeInGroup = changes.groups.includes(group.key)
					const isAgentInGroup = group.agents.includes(id)

					// Add agent to group
					if (shouldAgentBeInGroup && !isAgentInGroup) {
						group.agents.push(id)
					}

					// Remove agent from group
					if (!shouldAgentBeInGroup && isAgentInGroup) {
						group.agents = group.agents.filter((agentId) => agentId !== id)
					}
				})
			}
		})
	},
})

const { reducer, actions } = groupsSlice
export const { closeCreateGroupModal, openCreateGroupModal } = actions
export default reducer

const entitySelectors = groupsAdapter.getSelectors<GroupsRootState>((state) => state.groups)
const getIsFetching = (state: GroupsRootState) => state.groups.isFetching
const getIsCreating = (state: GroupsRootState) => state.groups.isCreating
const getIsDeleting = (state: GroupsRootState) => state.groups.isDeleting
const getGroupEntities = (state: GroupsRootState) => entitySelectors.selectEntities(state)
const getGroups = (state: GroupsRootState) => entitySelectors.selectAll(state)
const getGroupKey = (state: DashboardState, groupKey: Visitor['group']) => groupKey
const getGroupId = (state: DashboardState, groupId: number | null) => groupId
const getCreateGroupModalData = (state: GroupsRootState) => state.groups.createGroupModal

const makeGetGroupById = () => {
	return createSelector([getGroupEntities, getGroupId], (groups, groupId) => {
		if (!groups || !groupId) return null
		return groups[groupId] ?? null
	})
}

const makeGetIsGroupValidById = (groupId: string | null) => {
	return createSelector([getGroups], (groups): boolean => {
		if (!groups || !groupId) return false

		return groups.some((g) => g.key === groupId)
	})
}

const makeGetGroupByKey = () => {
	return createSelector([getGroups, getGroupKey], (groups, groupKey): Group | null => {
		if (!groups || !groupKey) return null
		const groupsNormalized = normalize('key', groups)
		return groupsNormalized[groupKey] ?? null
	})
}

export const groupsSelectors = {
	getIsFetching,
	getIsCreating,
	getIsDeleting,
	getGroupEntities,
	getGroups,
	makeGetGroupByKey,
	makeGetGroupById,
	makeGetIsGroupValidById,
	getCreateGroupModalData,
}
