import { TranslationService as T } from 'shared/services'

import { CButton, Center, Empty } from 'core'

type Props = {
	error?: Error
}

export const ErrorFallback = ({ error }: Props) => {
	return (
		<Center h="full" w="full">
			<Empty
				icon="dev-error.png"
				iconSize="sm"
				title="dev.empty.error"
				description={error?.message ?? ''}
				extra={<CButton onClick={() => window.location.reload()}>{T.translate('general.buttons.reload')}</CButton>}
			/>
		</Center>
	)
}
