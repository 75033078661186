import { Chatbot } from 'models'
import { BotIdentity, BotIdentityUpdate } from 'shared/models/Chatbot'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'chatbot/identities'

const listIdentities = () => {
	return apiClient.get<BotIdentity[]>(`${API_RESOURCE}/list`)
}

const createIdentity = (botId: Chatbot['id'], data: BotIdentityUpdate) => {
	return apiClient.post<BotIdentity>(`${API_RESOURCE}/${botId}`, data)
}

const editIdentity = (id: BotIdentity['id'], changes?: BotIdentityUpdate) => {
	return apiClient.patch<BotIdentity>(`${API_RESOURCE}/${id}`, changes)
}

const deleteIdentity = (id: BotIdentity['id']) => {
	return apiClient.delete(`${API_RESOURCE}/${id}`)
}

export const chatbotIdentityApi = { listIdentities, createIdentity, editIdentity, deleteIdentity }
