import { ComponentProps } from 'react'
import { FormattedRelativeTime } from 'react-intl'

type Props = ComponentProps<typeof FormattedRelativeTime>

const IntlRelativeTime = (props: Props) => {
	return <FormattedRelativeTime {...props} />
}

export default IntlRelativeTime
