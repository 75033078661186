import { useSelector } from 'react-redux'
import { mdiHelpCircle } from '@mdi/js'
import { packageSelectors } from 'dashboard/src/modules/package'

import { TranslationService as T } from 'shared/services/TranslationService'

import { Box } from 'shared/components/core/Box'
import { Flex } from 'shared/components/core/Flex'
import { Popover, PopoverMenu } from 'shared/components/core/Popover'
import { Tooltip } from 'shared/components/core/Tooltip'

import SidebarMenuIcon from '../sidebarMenu/SidebarMenuIcon'

import NeedHelpMenu from './NeedHelpMenu'

const SidebarNeedHelp = () => {
	const packageInfo = useSelector(packageSelectors.getPackageInfo)
	const isFreePackage = packageInfo ? packageInfo.isFree : false

	return (
		<Popover
			content={
				<PopoverMenu width="300px">
					<NeedHelpMenu isFreePackage={isFreePackage} />
				</PopoverMenu>
			}
			placement="right-end"
			offset={[0, 8]}
		>
			<Box px="10px">
				<Box as="button" data-testid="sidebar-help" w="full">
					<Tooltip content={T.translate('needHelp.panel')} placement="right" closeOnClick offset={[0, 20]}>
						<Flex
							transition="background-color 200ms"
							_hover={{ bgColor: 'coal.800' }}
							borderRadius={2}
							alignItems="center"
							justifyContent="center"
							p="14px"
						>
							<SidebarMenuIcon icon={mdiHelpCircle} />
						</Flex>
					</Tooltip>
				</Box>
			</Box>
		</Popover>
	)
}

export default SidebarNeedHelp
