import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunkAction, DashboardState, Dictionary } from 'types'
import { RangeType } from 'shared/models/Analytics'
import {
	AutoMessagesApi,
	AutoMessagesStats,
	AutoMessagesStatsSummary,
	GetAutoMessageStatsParams,
} from 'shared/models/AutoMessage'
import { normalize } from 'utils'

import { autoMessageStatisticsApi } from './api'
import { DEFAULT_AUTO_MESSAGE_SUMMARY_ARRAY } from './constants'
import {
	AutoMessageStatisticsDataType,
	ChartData,
	DEFAULT_AUTO_MESSAGE_STATISTICS_ITEMS,
	TransformedAutoMessageStatistics,
	TransformedAutoMessageSummaryStatistics,
	TransformedSingleAutoMessageSummaryStats,
} from './types'
import {
	getChartData,
	transformAutoMessageStatistics,
	transformAutoMessageSummaryStatistics,
	transformSingleAutoMessageSummary,
} from './utils'

export type AutoMessageStatisticsRootState = Pick<DashboardState, 'autoMessageStatistics'>
export type AutoMessageStatisticsState = typeof initialState

export const initialState = {
	summary: null as null | AutoMessagesStatsSummary,
	messages: null as null | Dictionary<AutoMessagesStats>,
	lastUpdate: null as null | string,
	isFetching: false,
	isDrawerOpen: true,
	selectedAutoMessage: {
		summary: null as null | AutoMessagesStatsSummary,
		lastUpdate: null as null | string,
		isFetchingStats: false,
	},
	aggregatedStats: {
		statistics: null as null | AutoMessagesApi.StatsSingleAggregatedResponse,
		isFetching: false,
		isError: false,
	},
	selectedItems: DEFAULT_AUTO_MESSAGE_STATISTICS_ITEMS,
}

export const fetchAutoMessageStatisticsThunk = createAsyncThunk(
	'autoMessageStatistics/FETCH',
	(data: AutoMessagesApi.StatsQuery) => {
		return autoMessageStatisticsApi.getStatistics(data)
	},
)

export const fetchSingleAutoMessageStatisticsThunk = createAsyncThunk(
	'autoMessageStatistics/FETCH_SINGLE',
	({ autoMessageId, range }: GetAutoMessageStatsParams) => {
		return autoMessageStatisticsApi.getAutoMessageStatistics(autoMessageId, { range })
	},
)

export const fetchAutoMessageAggregatedStatisticsThunk = createAsyncThunk(
	'chatbotStatistics/FETCH_AGGREGATED',
	({ autoMessageId, range }: GetAutoMessageStatsParams) => {
		return autoMessageStatisticsApi.getAggregatedAutoMessageStatistics(autoMessageId, { range })
	},
)

export const fetchAutoMessagesStatistics = (): AppThunkAction => (dispatch) => {
	dispatch(
		fetchAutoMessageStatisticsThunk({
			fields: [
				AutoMessagesApi.StatsQueryFields.Messages,
				AutoMessagesApi.StatsQueryFields.Summary,
				AutoMessagesApi.StatsQueryFields.LastUpdate,
			],
			range: RangeType.Last30days,
		}),
	)
}

export const fetchSelectedAutoMessageStatistics =
	(autoMessageId: string): AppThunkAction =>
	(dispatch) => {
		dispatch(
			fetchSingleAutoMessageStatisticsThunk({
				autoMessageId,
				range: RangeType.Last30days,
			}),
		)
	}

export const fetchSelectedAutoMessageAggregatedStatistics =
	(autoMessageId: string): AppThunkAction =>
	(dispatch) => {
		dispatch(
			fetchAutoMessageAggregatedStatisticsThunk({
				autoMessageId,
				range: RangeType.Last30days,
			}),
		)
	}

export const selectStatisticsItem =
	(chatbotStatName: AutoMessageStatisticsDataType): AppThunkAction =>
	(dispatch, getState) => {
		const selectedItems = getSelectedItems(getState())
		const isItemSelected = selectedItems.includes(chatbotStatName)

		const newItemArray = isItemSelected
			? selectedItems.filter((selectedItem) => selectedItem !== chatbotStatName)
			: [...selectedItems, chatbotStatName]

		dispatch(setSelectedStatisticsItems(newItemArray))
	}

const autoMessageStatisticsSlice = createSlice({
	name: 'autoMessageStatisticsSlice',
	initialState,
	reducers: {
		setIsDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
			state.isDrawerOpen = payload
		},
		setSelectedStatisticsItems: (state, { payload }: PayloadAction<AutoMessageStatisticsDataType[]>) => {
			state.selectedItems = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAutoMessageStatisticsThunk.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchAutoMessageStatisticsThunk.fulfilled, (state, { payload }) => {
				state.isFetching = false
				const { summary, messages, lastUpdate } = payload
				if (summary) {
					state.summary = summary
				}
				if (messages) {
					state.messages = normalize('id', messages)
				}
				if (lastUpdate) {
					state.lastUpdate = lastUpdate
				}
			})
			.addCase(fetchAutoMessageStatisticsThunk.rejected, (state) => {
				state.isFetching = false
			})
		builder
			.addCase(fetchSingleAutoMessageStatisticsThunk.pending, (state) => {
				state.selectedAutoMessage.isFetchingStats = true
			})
			.addCase(fetchSingleAutoMessageStatisticsThunk.fulfilled, (state, { payload }) => {
				state.selectedAutoMessage.isFetchingStats = false
				const { summary, lastUpdate } = payload
				if (summary) {
					state.selectedAutoMessage.summary = summary
				}
				if (lastUpdate) {
					state.selectedAutoMessage.lastUpdate = lastUpdate
				}
			})
			.addCase(fetchSingleAutoMessageStatisticsThunk.rejected, (state) => {
				state.selectedAutoMessage.isFetchingStats = false
			})
		builder
			.addCase(fetchAutoMessageAggregatedStatisticsThunk.pending, (state) => {
				state.aggregatedStats.isFetching = true
				state.aggregatedStats.isError = false
			})
			.addCase(fetchAutoMessageAggregatedStatisticsThunk.fulfilled, (state, { payload }) => {
				state.aggregatedStats.isFetching = false
				state.aggregatedStats.isError = false
				state.aggregatedStats.statistics = payload
			})
			.addCase(fetchAutoMessageAggregatedStatisticsThunk.rejected, (state) => {
				state.aggregatedStats.isFetching = false
				state.aggregatedStats.isError = true
			})
	},
})

const getSummary = (state: AutoMessageStatisticsRootState) => state.autoMessageStatistics.summary
const getLastUpdate = (state: AutoMessageStatisticsRootState) => state.autoMessageStatistics.lastUpdate
const getMessages = (state: AutoMessageStatisticsRootState) => state.autoMessageStatistics.messages
const getIsFetching = (state: AutoMessageStatisticsRootState) => state.autoMessageStatistics.isFetching
const getIsDrawerOpened = (state: AutoMessageStatisticsRootState) => state.autoMessageStatistics.isDrawerOpen
const getSelectedItems = (state: AutoMessageStatisticsRootState) => state.autoMessageStatistics.selectedItems

const getSelectedAutoMessageStatistics = (state: AutoMessageStatisticsRootState) =>
	state.autoMessageStatistics.selectedAutoMessage.summary
const getSelectedAutoMessageLastUpdated = (state: AutoMessageStatisticsRootState) =>
	state.autoMessageStatistics.selectedAutoMessage.lastUpdate
const getSelectedAutoMessageIsFetching = (state: AutoMessageStatisticsRootState) =>
	state.autoMessageStatistics.selectedAutoMessage.isFetchingStats
const getAggregatedStatistics = (state: AutoMessageStatisticsRootState) =>
	state.autoMessageStatistics.aggregatedStats.statistics
const getIsFetchingAggregatedStatistics = (state: AutoMessageStatisticsRootState) =>
	state.autoMessageStatistics.aggregatedStats.isFetching
const getIsErrorAggregatedStatistics = (state: AutoMessageStatisticsRootState) =>
	state.autoMessageStatistics.aggregatedStats.isError

const makeGetAutoMessageStatisticsStringsById = (id: string) => {
	return createSelector([getMessages], (messages): TransformedAutoMessageStatistics | null => {
		if (!messages) return null

		return transformAutoMessageStatistics(messages[id])
	})
}

const getAutoMessageSummaryStatistics = createSelector(
	[getSummary],
	(summary): TransformedAutoMessageSummaryStatistics => {
		if (!summary) return DEFAULT_AUTO_MESSAGE_SUMMARY_ARRAY

		return transformAutoMessageSummaryStatistics(summary)
	},
)

const getSelectedAutoMessageStatisticsStrings = createSelector(
	[getSelectedAutoMessageStatistics],
	(stats): TransformedSingleAutoMessageSummaryStats[] | null => {
		if (!stats) return null
		return transformSingleAutoMessageSummary(stats)
	},
)

const getSelectedAutoMessageChartData = createSelector(
	[getAggregatedStatistics, getSelectedItems],
	(statistics, selectedItems): ChartData[] => {
		if (!statistics) return []

		const chartData = getChartData(statistics)
		return chartData.filter((dataItem) => selectedItems.includes(dataItem.id))
	},
)

const getSelectedAutoMessageChartMaxValue = createSelector([getSelectedAutoMessageChartData], (chartData): number => {
	const values = chartData.map((dataItem) => {
		const dataY = dataItem.data.map((data) => data.y)
		return Math.max(...dataY)
	})
	return Math.max(...values)
})

const hasSelectedItems = createSelector([getSelectedItems], (selectedItems): boolean => {
	return selectedItems.length > 0
})

const hasAutoMessageDataForSelectedItems = createSelector(
	[getSelectedAutoMessageChartMaxValue],
	(maxValue): boolean => {
		return maxValue > 0
	},
)

const { reducer, actions } = autoMessageStatisticsSlice
export const { setIsDrawerOpen, setSelectedStatisticsItems } = actions
export default reducer

export const autoMessageStatisticsSelectors = {
	getMessages,
	getLastUpdate,
	getSummary,
	getIsFetching,
	getIsDrawerOpened,
	getSelectedItems,
	getSelectedAutoMessageStatistics,
	getSelectedAutoMessageLastUpdated,
	getSelectedAutoMessageIsFetching,
	getAggregatedStatistics,
	getIsFetchingAggregatedStatistics,
	getIsErrorAggregatedStatistics,
	makeGetAutoMessageStatisticsStringsById,
	getAutoMessageSummaryStatistics,
	getSelectedAutoMessageStatisticsStrings,
	getSelectedAutoMessageChartData,
	getSelectedAutoMessageChartMaxValue,
	hasSelectedItems,
	hasAutoMessageDataForSelectedItems,
}
