import { createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit'

import { AppThunkAction, DashboardState } from 'types'
import { CurrencyCode } from 'shared/models/Currency'
import { PackageInterval, PackageLogAction, PackageLogLevel } from 'shared/models/Package'
import { PaymentGateway } from 'shared/models/Payment'
import { flashMessage } from 'shared/utils'
import { checkoutSelectors, logCheckoutEvent, resetCheckoutPaymentStatus, setCheckoutInterval } from 'modules/checkout'
import { fetchInvoicePayment, fetchInvoices, invoicesSelectors } from 'modules/invoices'
import { calculateSubscriptionData, createSubscription } from 'modules/subscription'

import { WireTransferModalType } from './types'

export type WireTransferRootState = Pick<DashboardState, 'wireTransfer'>

export const initialState = {
	isWireTransferModalOpen: false,
	wireTransferModalType: null as null | WireTransferModalType,
	yearlyPriceFinalTaxed: null as null | number,
	currencyCode: null as null | CurrencyCode,
	isYearlyCalculationLoading: false,
}

export const openWireTransfer = (): AppThunkAction => async (dispatch, getState) => {
	const state = getState()
	const interval = checkoutSelectors.getCheckoutInterval(state)
	const calculateData = checkoutSelectors.getSubscriptionCalculateData(state)

	if (interval === PackageInterval.Month) {
		const resultAction = await dispatch(calculateSubscriptionData({ ...calculateData, interval: PackageInterval.Year }))
		if (calculateSubscriptionData.fulfilled.match(resultAction)) {
			dispatch(resetCheckoutPaymentStatus())
			dispatch(openWireTransferModal())
		}
	} else {
		dispatch(resetCheckoutPaymentStatus())
		dispatch(openWireTransferModal())
	}
}

export const processWireTransfer = (): AppThunkAction => async (dispatch, getState) => {
	const interval = checkoutSelectors.getCheckoutInterval(getState())

	if (interval === PackageInterval.Month) dispatch(setCheckoutInterval(PackageInterval.Year))

	try {
		const actionResult = await dispatch(createSubscription({ paymentGateway: PaymentGateway.Transfer }))
		unwrapResult(actionResult)

		const invoicesResult = await dispatch(fetchInvoices())
		unwrapResult(invoicesResult)
		const lastPendingInvoice = invoicesSelectors.getLastPendingInvoice(getState())

		if (lastPendingInvoice) {
			dispatch(fetchInvoicePayment(lastPendingInvoice.id))
		}
		dispatch(setWireTransferModalType(WireTransferModalType.Payment))
	} catch (error) {
		dispatch(
			logCheckoutEvent({
				action: PackageLogAction.WireTransferPaymentError,
				message: 'Wire transfer process error',
				data: { error },
				level: PackageLogLevel.Warn,
			}),
		)
		flashMessage.error('general.error')
	}
}

const wireTransferSlice = createSlice({
	name: 'wireTransfer',
	initialState,
	reducers: {
		openWireTransferModal: (state) => {
			state.isWireTransferModalOpen = true
			state.wireTransferModalType = WireTransferModalType.Confirm
		},
		closeWireTransferModal: (state) => {
			state.isWireTransferModalOpen = false
		},
		setWireTransferModalType: (state, { payload }: PayloadAction<WireTransferModalType>) => {
			state.wireTransferModalType = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(calculateSubscriptionData.fulfilled, (state, { payload }) => {
				const { priceFinalTaxed, currencyCode } = payload.result
				state.yearlyPriceFinalTaxed = priceFinalTaxed
				state.currencyCode = currencyCode
				state.isYearlyCalculationLoading = false
			})
			.addCase(calculateSubscriptionData.rejected, (state) => {
				state.isYearlyCalculationLoading = false
			})
			.addCase(calculateSubscriptionData.pending, (state) => {
				state.isYearlyCalculationLoading = true
			})
	},
})

const { actions, reducer } = wireTransferSlice
export const { closeWireTransferModal, openWireTransferModal, setWireTransferModalType } = actions
export default reducer

const getIsWireTransferModalOpen = (state: WireTransferRootState) => state.wireTransfer.isWireTransferModalOpen
const getWireTransferModalType = (state: WireTransferRootState) => state.wireTransfer.wireTransferModalType
const getCurrencyCode = (state: WireTransferRootState) => state.wireTransfer.currencyCode
const getYearlyPriceFinalTaxed = (state: WireTransferRootState) => state.wireTransfer.yearlyPriceFinalTaxed
const getIsWireTransferProcessing = (state: WireTransferRootState) => state.wireTransfer.isYearlyCalculationLoading

export const wireTransferSelectors = {
	getIsWireTransferModalOpen,
	getWireTransferModalType,
	getYearlyPriceFinalTaxed,
	getCurrencyCode,
	getIsWireTransferProcessing,
}
