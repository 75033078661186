import { Message, MessageContent } from '@smartsupp/smartsupp-message'

import { Visitor } from './Visitor'

export enum DashboardContentEventType {
	VisitorConnect = 'VisitorConnect',
	VisitorDisconnect = 'VisitorDisconnect',
	VisitorChangeUrl = 'visitorChangeUrl',
	Unknown = 'unknown',
}

export type MessageContentTypes = MessageContent.Event.Type & DashboardContentEventType

interface SystemMessage {
	type: DashboardContentEventType
	data?: unknown
	id: number | string
	chatId: string
}

interface SystemMessageVisitorConnect extends SystemMessage {
	type: DashboardContentEventType.VisitorConnect
}

interface SystemMessageVisitorDisconnect extends SystemMessage {
	type: DashboardContentEventType.VisitorDisconnect
}

interface SystemMessageVisitorChangeUrl extends SystemMessage {
	type: DashboardContentEventType.VisitorChangeUrl
	data: Partial<Pick<Visitor, 'pageUrl' | 'pageTitle'>>
}

export type CreateSystemMessageParams =
	| SystemMessageVisitorConnect
	| SystemMessageVisitorDisconnect
	| SystemMessageVisitorChangeUrl

export type MessageAgent = Message.Message.Agent
export type MessageEventSystem = Message.Event.System
export {
	GroupType,
	Message,
	MessageContent,
	MessageContentType,
	MessageSubType,
	MessageType,
} from '@smartsupp/smartsupp-message'
export { MessageDeliveryFailReason, MessageDeliveryStatus } from '@smartsupp/websocket-client-types'
