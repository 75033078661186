import { validateUrl } from 'shared/utils'

const domainWhitelistRegex = /,\s|;\s|,|;|\s/g

const matcherRegex = /(?!([\w-])\.)[\w-]*(?:[\w-]+\.)+[\w-]+/i

export const validateDomains = (urls: string[]) => urls.every(validateUrl)

export const convertDomainsToArray = (domains: string) =>
	domains.split(domainWhitelistRegex).filter((value) => value !== '')

export const formatDomains = (domains: string[]) => {
	return domains.map((domain: string) => {
		const hostname = matcherRegex.exec(domain)
		if (hostname) return hostname[0].toLowerCase()
		return domain.toLowerCase()
	})
}
