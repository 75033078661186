import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { AiAssistAction, AiAssistTransformRequest } from 'models'
import { DashboardState } from 'types'

import { aiAssistApi } from './api'

type AiAssistRootState = Pick<DashboardState, 'aiAssist'>

export const fetchAiAssistActions = createAsyncThunk('aiAssistActions/FETCH', () => {
	return aiAssistApi.fetchAiAssistActions()
})

export const transformText = createAsyncThunk('aiAssistActions/TRANSFORM', (body: AiAssistTransformRequest) => {
	return aiAssistApi.transformText(body)
})

const initialState = {
	actions: null as null | AiAssistAction[],
	isLoadingTransform: false,
}

const aiAssistSlice = createSlice({
	name: 'aiAssist',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchAiAssistActions.fulfilled, (state, { payload }) => {
			state.actions = payload
		})
		builder.addCase(transformText.fulfilled, (state) => {
			state.isLoadingTransform = false
		})
		builder.addCase(transformText.pending, (state) => {
			state.isLoadingTransform = true
		})
		builder.addCase(transformText.rejected, (state) => {
			state.isLoadingTransform = false
		})
	},
})

const getAiAssistActions = (state: AiAssistRootState) => state.aiAssist.actions
const getIsLoadingTransform = (state: AiAssistRootState) => state.aiAssist.isLoadingTransform

const { reducer } = aiAssistSlice
export default reducer

export const aiAssistSelectors = {
	getAiAssistActions,
	getIsLoadingTransform,
}
