// remove legacy sharp prefix which was used on V1
export const removeSharpPrefix = (value: string): string => value.replaceAll(/#/gim, '')

export const addTag = (tags: string[], tagKey: string): string[] => {
	return [...tags, tagKey]
}

export const removeTag = (tags: string[], tagKey: string): string[] => {
	return tags.filter((tag) => tag !== tagKey)
}
