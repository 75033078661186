/* eslint-disable unicorn/no-useless-undefined */
import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react'

type State = ReturnType<typeof getInitialState>
type Props = {
	children: ReactNode
	siteKey: string | null
}

type Action = {
	type: 'ready'
}

const RecaptchaStateContext = createContext<State | undefined>(undefined)
const RecaptchaDispatchContext = createContext<Dispatch<Action> | undefined>(undefined)

const getInitialState = (siteKey: string | null) => ({
	isReady: false,
	siteKey,
})

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'ready': {
			return { ...state, isReady: true }
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

export const RecaptchaProvider = ({ children, siteKey }: Props) => {
	const [state, dispatch] = useReducer(reducer, getInitialState(siteKey))
	return (
		<RecaptchaStateContext.Provider value={state}>
			<RecaptchaDispatchContext.Provider value={dispatch}>{children}</RecaptchaDispatchContext.Provider>
		</RecaptchaStateContext.Provider>
	)
}

export const useRecaptchaState = () => {
	const context = useContext(RecaptchaStateContext)
	if (context === undefined) {
		throw new Error('useRecaptchaState must be used within a RecaptchaProvider')
	}
	return context
}

export const useRecaptchaDispatch = () => {
	const context = useContext(RecaptchaDispatchContext)
	if (context === undefined) {
		throw new Error('useRecaptchaDispatch must be used within a RecaptchaProvider')
	}
	return context
}
