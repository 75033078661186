import { ComponentProps } from 'react'
import { FormattedTime } from 'react-intl'

type Props = ComponentProps<typeof FormattedTime>

const IntlTime = ({ value, ...rest }: Props) => {
	return <FormattedTime {...rest} value={value} />
}

export default IntlTime
