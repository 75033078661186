import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppLang } from 'shared/types'

import { appApi } from './api'

export const fetchFileUploadConfig = createAsyncThunk('app/FETCH_FILE_UPLOAD_CONFIG', () => {
	return appApi.fetchFileUploadConfig()
})

export const fetchInitData = createAsyncThunk('app/FETCH_INIT_DATA', () => {
	return appApi.fetchInitData()
})

export const fetchTranslations = createAsyncThunk('app/FETCH_TRANSLATIONS', (locale: AppLang) => {
	return appApi.fetchTranslations(locale)
})
