import { AppConfigData, AppLang } from 'shared/types'
import { FilesUploadConfig } from 'shared/models/AppConfig'
import { ConfigurationService, TranslationsMap } from 'shared/services'
import { apiClient } from 'shared/utils'

const API_FILES_RESOURCE = 'files'
declare const __HASH__: string

const fetchFileUploadConfig = () => {
	return apiClient.get<FilesUploadConfig>(`${API_FILES_RESOURCE}/upload/config`)
}

const fetchInitData = () => {
	return apiClient.get<AppConfigData>(`app/init`)
}

const fetchTranslations = async (locale: AppLang): Promise<TranslationsMap> => {
	const configData = ConfigurationService.getData()
	const response = await fetch(`${configData.distUrl}/translates/${locale}.json?v=${__HASH__}`)
	return response.json()
}

export const appApi = { fetchFileUploadConfig, fetchInitData, fetchTranslations }
