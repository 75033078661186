import { BraintreeThreeDSecureParams, PaymentDataCreditCard, PaymentDataPaypal, PaymentMethod } from 'models'
import { sanitizeBraintreeParamString } from 'modules/checkout/utils'
import { PaymentDetails } from 'modules/paymentMethod'

export const isPaymentDataCreditCard = (data: PaymentMethod['data']): data is PaymentDataCreditCard => {
	return !!data && data.type === 'credit_card'
}

export const isPaymentDataPaypal = (data: PaymentMethod['data']): data is PaymentDataPaypal => {
	return !!data && data.type === 'paypal'
}

export const getBraintreePaymentDetails = (
	template: BraintreeThreeDSecureParams | null,
	paymentDetails: PaymentDetails | null,
): BraintreeThreeDSecureParams | null => {
	if (!template || !paymentDetails) return null

	const { paymentCity, paymentStreet, paymentPostalCode, paymentCountryCode } = paymentDetails

	const billingAddress = {
		locality: sanitizeBraintreeParamString(paymentCity),
		streetAddress: sanitizeBraintreeParamString(paymentStreet),
		postalCode: paymentPostalCode,
		countryCodeAlpha2: paymentCountryCode,
	}

	const params = { ...template }
	params.billingAddress = billingAddress

	return params
}
