export enum Color {
	Gray = 'gray',
}

export const colorSelectPreset: string[] = [
	'#d6195a',
	'#c52407',
	'#ff8730',
	'#37c706',
	'#34af80',
	'#2e7afa',
	'#1233df',
	'#7138e4',
	'#b743bf',
	'#15152c',
]

export const presetInColorPicker: string[] = [
	'#bb3216',
	'#8b4950',
	'#5e4722',
	'#3e998b',
	'#49ac51',
	'#549c49',
	'#315b6b',
	'#2b1ba7',
	'#ab49ac',
	'#dcdce6',
	'#e6e9ec',
]
