import { Country, CountryCode } from 'shared/models/Country'

export const hasCountryVat = (countries: Country[], code: Country['code']) => {
	const country = countries.find((c) => c.code === code)
	return !!country?.hasVat
}

export const getVatCountryCode = (countryCode: Country['code']): string => {
	if (countryCode === CountryCode.Greece) return 'EL'
	return countryCode.toUpperCase()
}
