import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { EntityId } from '@reduxjs/toolkit'

import { ChatbotTemplateSimple } from 'models'
import { DashboardState } from 'types'

import { chatbotTemplatesSelectors } from './slice'

export const useChatbotTemplate = (chatbotTemplateId: EntityId): ChatbotTemplateSimple | null => {
	const getChatbotTemplateById = useMemo(() => chatbotTemplatesSelectors.makeGetChatbotTemplateById(), [])
	return useSelector((state: DashboardState) => getChatbotTemplateById(state, { chatbotTemplateId }))
}
