import { GlobalStyleProps, mode } from '@chakra-ui/theme-tools'

const globalStyles = (props: GlobalStyleProps) => ({
	// Disable Chakra's default font smoothing
	html: {
		WebkitFontSmoothing: 'auto',
		MozOsxFontSmoothing: 'auto',
	},

	body: {
		fontSize: '14px',
		color: mode('gray.700', 'whiteAlpha.900')(props),
		bg: mode('white', 'gray.800')(props),
		overflow: 'hidden',
	},

	'#app': {
		height: '100vh',
	},

	a: {
		cursor: 'pointer',
		color: 'blue.500',
		textDecoration: 'none',
		'&:hover, &:active': {
			color: 'blue.600',
			textDecoration: 'underline',
		},
	},

	// React responsive modal override
	'.react-responsive-modal-root': {
		zIndex: 9999,
	},

	// Google Recaptcha
	// - Should NOT be visible in production
	// - Does NOT render on local
	'.grecaptcha-badge': {
		visibility: 'hidden',
	},

	// Emoji style
	'img.emoji': {
		display: 'inline',
		height: '1em',
		width: '1em',
		margin: '0 .05em 0 .1em',
		verticalAlign: '-0.1em',
	},

	// Box shadow for popover should be visible in all cases
	// there is a [data-js-focus-visible] :focus:not([data-focus-visible-added]) selector that sets it to 0
	'.chakra-popover__content': {
		boxShadow: 'var(--chakra-shadows-xl) !important',
	},
})

export const styles = { global: globalStyles }
