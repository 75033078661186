const green = {
	50: '#f6fdf0', // same as '100' for Chakra
	100: '#f6fdf0',
	200: '#defccc',
	300: '#bef79b',
	400: '#77d93e',
	500: '#58bf13',
	600: '#41a103',
	700: '#306011',
	800: '#1b3609',
	900: '#101f04',
}

const blue = {
	50: '#f7fbfd', // same as '100' for Chakra
	100: '#f7fbfd',
	200: '#ecf4fd',
	300: '#c2dfff',
	400: '#68b3ff',
	500: '#408fec', // (brand color - alias: 'primary')
	600: '#1774e2',
	700: '#0e51a1',
	800: '#092f5b',
	900: '#05172d',
}

const gray = {
	50: '#f5f5f6', // same as '100' for Chakra
	100: '#f5f5f6',
	200: '#edeeef',
	300: '#dadde0',
	400: '#c8ccd0',
	500: '#a1a8ac',
	600: '#7e8991',
	700: '#4b5258',
	800: '#32373b',
	900: '#191b1d',
}

const yellow = {
	50: '#fff9e0', // same as '100' for Chakra
	100: '#fff9e0',
	200: '#ffefc7',
	300: '#fadb87',
	400: '#f5bd36',
	500: '#cc9700',
	600: '#b07500',
	700: '#825000',
	800: '#523600',
	900: '#2e1e00',
}

const red = {
	50: '#fdf5f5', // same as '100' for Chakra
	100: '#fdf5f5',
	200: '#ffe5e3',
	300: '#ffccc7',
	400: '#ff8b85',
	500: '#ff5f5f',
	600: '#ff3029',
	700: '#d91714',
	800: '#960200',
	900: '#460000',
}

const pink = {
	50: '#fff2f9', // same as '100' for Chakra
	100: '#fff2f9',
	200: '#ffe6f4',
	300: '#ffc9e8',
	400: '#ffa3d9',
	500: '#f777c0',
	600: '#ed53ab',
	700: '#c72687',
	800: '#851157',
	900: '#590437',
}

const purple = {
	50: '#f8f6fe',
	100: '#f8f6fe',
	200: '#f1edfd',
	300: '#c2b5f5',
	400: '#8b71ec',
	500: '#6e4ee7',
	600: '#583eb9',
	700: '#422f8b',
	800: '#2c1f5c',
	900: '#16102e',
}

const violet = {
	50: '#f6e8fc',
	100: '#f6e8fc',
	200: '#edd1f9',
	300: '#c876ee',
	400: '#b549e8',
	500: '#a31be2',
	600: '#8216b5',
	700: '#621088',
	800: '#410b5a',
	900: '#21052d',
}

const coal = {
	100: '#f5f6ff',
	500: '#9da1c2',
	800: '#3d3c57',
	900: '#302f4a',
}

const lightOrange = {
	100: '#fffAf0',
	500: '#f6ad55',
}

export const colors = {
	black: '#000000',
	white: '#ffffff',

	primary: blue['500'],

	green,
	blue,
	gray,
	yellow,
	red,
	pink,
	purple,
	violet,
	coal,
	lightOrange,

	success: green,
	info: blue,
	brand: blue,
	default: gray,
	warning: yellow,
	danger: red,
	error: red,

	chatListItem: {
		warning: '#FFF6A5',
	},

	avatars: {
		bgGray: '#f5f5f6',
		colorGray: '#7e8991',
		bg1: '#cfebd7',
		color1: '#408b5b',
		bg2: '#b8f3f4',
		color2: '#5099b2',
		bg3: '#e2d6bc',
		color3: '#9b6d40',
		bg4: '#c2f2ec',
		color4: '#3aaa9f',
		bg5: '#ead3fd',
		color5: '#8961af',
		bg6: '#c1c1f7',
		color6: '#5f5f9e',
		bg7: '#fdd0e9',
		color7: '#b8488f',
		bg8: '#fee0ca',
		color8: '#c78435',
		bg9: '#c1c1f7',
		color9: '#5f5f9e',
	},

	widgetDesign: {
		gray: {
			200: '#f2f3f8',
			300: '#e2e2e2',
			400: '#b4b5c5',
			500: '#717284',
		},
		blue: {
			500: '#2382e7',
			900: '#06132b',
		},
		green: {
			500: '#58c463',
		},
		red: {
			500: '#ff1c51',
		},
	},
}
