import { batch } from 'react-redux'
import { createAsyncThunk, createSelector, createSlice, isFulfilled, PayloadAction } from '@reduxjs/toolkit'

import { AccountSegmentCustomerObjective, ChatbotTemplatesModalsTypes } from 'models'
import { AppLang } from 'shared/types'
import { AppThunkAction, DashboardState } from 'types'
import { OnboardingApi, OnboardingStepCode, OnboardingType } from 'shared/models/Onboarding'
import { AccountSegmentCompanySize } from 'shared/models/Segmentation'
import { ConfigurationService } from 'shared/services'
import { detectLight, flashMessage } from 'shared/utils'
import { routes } from 'configuration/routes'
import { dashboardLanguages } from 'constants/app'
import { navigateTo } from 'utils'
import { accountSegmentSelectors, fetchAccountSegment, updateAccountSegment } from 'modules/accountSegment'
import { updateAgentByUser } from 'modules/agents'
import { initChatbotsFromOnboarding } from 'modules/chatbots'
import { openTemplatesModal } from 'modules/chatbotTemplates'
import { chatbotWizardSelectors, setChatbotLang } from 'modules/chatbotWizard'
import { updateDataLayerSignUpComplete } from 'modules/dataLayer'
import { updateUser, userSelectors } from 'modules/user'
import { fetchWidget, updateWidget, WIDGET_LANG_DEFAULT, widgetSelectors } from 'modules/widget'

import { onboardingApi } from './api'
import {
	COLOR_TEXT_DARK,
	COLOR_TEXT_LIGHT,
	DEFAULT_COLOR,
	DEFAULT_COLOR_TEXT,
	DEFAULT_STEP,
	onboardingStepsTypeMap,
} from './constants'
import { InitData, OnboardingLandingPage } from './types'
import { isAllowedUserEmailDomain } from './utils'

const setStepDoneThunk = createAsyncThunk(
	'onboarding/FINISH_STEP',
	({ code, finished = false }: OnboardingApi.CreateStepBody) => {
		if (finished) updateDataLayerSignUpComplete()
		return onboardingApi.finishStep(code, finished)
	},
)

export const fetchOnboardingData = createAsyncThunk('onboarding/GET', () => {
	return onboardingApi.fetchOnboardingData()
})

export const fetchOnboardInitData = (): AppThunkAction => async (dispatch, getState) => {
	await dispatch(fetchWidget())
	await dispatch(fetchAccountSegment())

	const state = getState()

	const widgetColor = widgetSelectors.getWidgetColor(state)
	const widgetLang = widgetSelectors.getWidgetLang(state)

	try {
		const user = userSelectors.getActiveUser(state)
		if (!user) throw new Error("User doesn't exist")

		const { avatar, fullname } = user

		const initData = {
			avatar,
			color: widgetColor ?? DEFAULT_COLOR,
			lang: widgetLang ?? WIDGET_LANG_DEFAULT,
			name: fullname,
		}

		dispatch(setInitData({ data: initData }))
	} catch {
		flashMessage.error('general.error')
	}
}

export const goToNextStep = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const isExistNextStep = getIsExistNextStep(state)
	const currentStep = getStep(state)
	if (isExistNextStep) {
		dispatch(setStep({ step: currentStep + 1 }))
	}
}

export const finishStep =
	(stepCode: OnboardingStepCode): AppThunkAction =>
	async (dispatch, getState) => {
		try {
			const state = getState()
			const isCustomerObjectiveLeadgen = getIsCustomerObjectiveLeadgen(state)

			switch (stepCode) {
				case OnboardingStepCode.Survey: {
					const companySize = accountSegmentSelectors.getAccountCompanySize(state)
					dispatch(
						updateAccountSegment(companySize ? { companySize } : { companySize: AccountSegmentCompanySize.Private }),
					)
					break
				}
				case OnboardingStepCode.Basics: {
					const lang = getLang(state)
					const name = getName(state)
					const avatar = getAvatar(state)

					const userChanges = avatar.isNewUpload ? { fullname: name, avatar: avatar.image } : { fullname: name }

					batch(() => {
						dispatch(updateUser(userChanges))
						dispatch(updateAgentByUser(userChanges))
						dispatch(updateWidget({ lang }))
						dispatch(updateChatbotLang(lang))
					})

					if (isCustomerObjectiveLeadgen) {
						dispatch(setStepDoneThunk({ code: stepCode, finished: true }))
						navigateTo(routes.chatbots.path)
						dispatch(openTemplatesModal(ChatbotTemplatesModalsTypes.Leadgen))
						return
					}

					break
				}
				default:
			}
			batch(() => {
				dispatch(setStepDone(stepCode))
				dispatch(goToNextStep())
			})
		} catch {
			flashMessage.error('general.error')
		}
	}

export const setStepDone =
	(code: OnboardingStepCode): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const isExistNextStep = getIsExistNextStep(state)
		const isLastStep = !isExistNextStep
		const landingPage = getOnboardingLandingPage(state)

		if (isLastStep) {
			if (landingPage === OnboardingLandingPage.Chatbot) {
				navigateTo(routes.automations.path)
				dispatch(openTemplatesModal(ChatbotTemplatesModalsTypes.Leadgen))
			} else navigateTo(routes.smartHub.path)
		}
		dispatch(setStepDoneThunk({ code, finished: isLastStep }))
	}

export const skipStep =
	(stepCode: OnboardingStepCode): AppThunkAction =>
	(dispatch) => {
		batch(() => {
			dispatch(setStepDone(stepCode))
			dispatch(goToNextStep())
		})
	}

export const updateChatbotLang =
	(lang: string): AppThunkAction =>
	(dispatch, getState) => {
		const updatedLang = lang as AppLang
		const prevChatbotTemplateLang = chatbotWizardSelectors.getChatbotLang(getState())
		const config = ConfigurationService.getData()
		const chatbotTemplateLang = dashboardLanguages.includes(updatedLang) ? updatedLang : config.lang

		if (prevChatbotTemplateLang === chatbotTemplateLang) return

		batch(() => {
			dispatch(setChatbotLang({ lang: chatbotTemplateLang }))
			dispatch(initChatbotsFromOnboarding(chatbotTemplateLang))
		})
	}

const initialState = {
	finished: true,
	stepsDone: [] as OnboardingStepCode[],
	avatar: {
		isNewUpload: false,
		image: null as null | string,
	},
	color: DEFAULT_COLOR,
	colorText: DEFAULT_COLOR_TEXT,
	isInitialized: false,
	lang: WIDGET_LANG_DEFAULT,
	step: DEFAULT_STEP,
	type: null as null | OnboardingType,
	name: '',
	landingPage: OnboardingLandingPage.Home,
	isUpdatingStepsDone: false,
}

const onboardingSlice = createSlice({
	name: 'onboarding',
	initialState,
	reducers: {
		setStep: (state, { payload }: PayloadAction<{ step: number }>) => {
			const { step } = payload
			state.step = step
		},
		setName: (state, { payload }: PayloadAction<{ name: string }>) => {
			const { name } = payload
			state.name = name
		},
		setLang: (state, { payload }: PayloadAction<{ lang: string }>) => {
			const { lang } = payload
			state.lang = lang
		},
		setAvatar: (state, { payload }: PayloadAction<{ avatar: string }>) => {
			const { avatar } = payload
			state.avatar = { ...state.avatar, image: avatar, isNewUpload: true }
		},
		setLandingPage: (state, { payload }: PayloadAction<OnboardingLandingPage>) => {
			state.landingPage = payload
		},
		setInitData: (state, { payload }: PayloadAction<{ data: InitData }>) => {
			const { color, avatar, lang, name } = payload.data
			state.isInitialized = true
			state.colorText = detectLight(color) ? COLOR_TEXT_DARK : COLOR_TEXT_LIGHT
			state.color = color
			state.name = name
			state.lang = lang
			state.avatar = { ...state.avatar, image: avatar, isNewUpload: false }
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(setStepDoneThunk.pending, (state) => {
				state.isUpdatingStepsDone = true
			})
			.addCase(setStepDoneThunk.fulfilled, (state) => {
				state.isUpdatingStepsDone = false
			})
			.addCase(setStepDoneThunk.rejected, (state) => {
				state.isUpdatingStepsDone = false
			})
		builder.addMatcher(isFulfilled(fetchOnboardingData, setStepDoneThunk), (state, { payload }) => {
			const { type, stepsDone, finished } = payload
			// fallback onboarding type
			state.type = Object.values(OnboardingType).includes(type) ? type : OnboardingType.Chatbot

			state.finished = finished
			state.stepsDone = stepsDone
		})
	},
})

const { reducer, actions } = onboardingSlice
export const { setStep, setName, setLang, setAvatar, setLandingPage, setInitData } = actions
export default reducer

const getSelectedColor = (state: DashboardState) => state.onboarding.color
const getType = (state: DashboardState) => state.onboarding.type
const getColorText = (state: DashboardState) => state.onboarding.colorText
const getAvatar = (state: DashboardState) => state.onboarding.avatar
const getStep = (state: DashboardState) => state.onboarding.step
const getIsFinished = (state: DashboardState) => state.onboarding.finished
const getName = (state: DashboardState) => state.onboarding.name
const isInitialized = (state: DashboardState) => state.onboarding.isInitialized
const getLang = (state: DashboardState) => state.onboarding.lang
const getStepsDone = (state: DashboardState) => state.onboarding.stepsDone
const getOnboardingLandingPage = (state: DashboardState) => state.onboarding.landingPage
const getIsUpdatingStepsDone = (state: DashboardState) => state.onboarding.isUpdatingStepsDone

const getOnboardingType = createSelector([getType], (type) => {
	if (!type) return OnboardingType.Chatbot
	return type
})

const getIsExistNextStep = createSelector([getStep, getOnboardingType], (currentStep, type) => {
	const stepsMap = onboardingStepsTypeMap[type]
	return !!stepsMap[currentStep + 1]
})

const getUserEmailDomain = createSelector([userSelectors.getActiveUser], (user): string => {
	if (!user) return ''
	const emailDomain = user.email.split('@')[1] ?? ''
	return isAllowedUserEmailDomain(emailDomain) ? emailDomain : ''
})

export const makeGetIsStepDone = (stepCode: OnboardingStepCode) =>
	createSelector([getStepsDone], (stepsDone): boolean => {
		return stepsDone.includes(stepCode)
	})

const getIsCustomerObjectiveLeadgen = createSelector(
	[accountSegmentSelectors.getAccountCustomerObjective],
	(customerObjective): boolean => {
		return customerObjective === AccountSegmentCustomerObjective.Leadgen
	},
)

const getIsAiOnboarding = createSelector([getOnboardingType], (type) => {
	return type === OnboardingType.Ai
})

export const onboardingSelectors = {
	getSelectedColor,
	getColorText,
	getAvatar,
	getStep,
	getName,
	isInitialized,
	getLang,
	getIsFinished,
	getOnboardingType,
	getUserEmailDomain,
	makeGetIsStepDone,
	getIsUpdatingStepsDone,
	getIsCustomerObjectiveLeadgen,
	getIsAiOnboarding,
}
