import { ExtendedSourceType, SourceStatus } from 'shared/models/Source'

import { SourceFormType } from './types'

export const getSelectedSourceFormType = (type: ExtendedSourceType): SourceFormType => {
	switch (type) {
		case ExtendedSourceType.WEB:
		case ExtendedSourceType.WebsiteCrawl: {
			return SourceFormType.Web
		}
		default: {
			return SourceFormType.Xml
		}
	}
}

export const getIsProcessingSource = (status: SourceStatus) => {
	return [SourceStatus.Creating, SourceStatus.Updating].includes(status)
}
