import { Country } from 'models/Country'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'countries'

const fetchCountries = () => {
	return apiClient.get<Country[]>(`${API_RESOURCE}`)
}

export const countriesApi = {
	fetchCountries,
}
