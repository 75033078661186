import { MouseEvent } from 'react'
import { useClipboard } from '@chakra-ui/react'
import { mdiContentCopy } from '@mdi/js'

import { useFlashMessage } from 'shared/hooks'
import { TranslationService as T } from 'shared/services'

import { CButton, Icon, Stack, StackDivider, Text, Tooltip, TooltipProps } from 'core'

type OwnProps = {
	text: string
}

type Props = TooltipProps & OwnProps
type TextAndCopyButtonProps = OwnProps

const SHORT_TEXT = 15

const TextAndCopyButton = ({ text }: TextAndCopyButtonProps) => {
	const flashMessage = useFlashMessage()
	const { onCopy } = useClipboard(text)
	const isTextShort = text.length < SHORT_TEXT

	const handleClickCopy = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		onCopy()
		flashMessage.success('general.copy.copiedToClipboard', { testId: 'flashMessage-success-copy-customerInfoValue' })
	}

	return (
		<Stack divider={<StackDivider />} spacing={isTextShort ? 1 : 0.5} direction={isTextShort ? 'row' : 'column'}>
			<Text>{text}</Text>
			<CButton
				onClick={handleClickCopy}
				variant="link"
				leftIcon={<Icon path={mdiContentCopy} />}
				color="white"
				fontWeight="normal"
				fontSize={0}
				_active={{ color: 'white' }}
				data-testid="copy-customer-info-value"
			>
				<Text mb="1px">{T.translate('general.copy')}</Text>
			</CButton>
		</Stack>
	)
}

export const TooltipCopy = ({ children, text, ...rest }: Props) => {
	return (
		<Tooltip {...rest} content={<TextAndCopyButton text={text} />} interactive>
			<div>{children}</div>
		</Tooltip>
	)
}
