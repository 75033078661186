import { mdiPlus } from '@mdi/js'

import { TranslationService as T } from 'shared/services'

import { Center, Flex, FlexProps, Icon, TableCellThin, TableRow, Text } from 'core'

type Props = {
	handleAddNew: () => void
	text?: string
	testId: string
	colorScheme?: string
} & FlexProps

export const TableAddItemRow = ({ handleAddNew, testId, text, colorScheme = 'blue', ...rest }: Props) => {
	return (
		<TableRow onClick={handleAddNew} cursor="pointer" data-testid={testId}>
			<TableCellThin {...rest}>
				<Flex h="full" alignSelf="flex-start" alignItems="center" w="full" color={`${colorScheme}.500`}>
					<Center boxSize={8}>
						<Icon boxSize={5} path={mdiPlus} />
					</Center>
					<Text ml={2}>{T.translate(text || 'general.buttons.addNew')}</Text>
				</Flex>
			</TableCellThin>
		</TableRow>
	)
}
