import { ChatbotWorkflow, Preview } from 'models'
import { apiClient } from 'shared/utils'

import { ApiCommand } from './types'
import { getMockDataWithNewText, widgetPreviewApi } from './utils'

const API_RESOURCE = 'automation/ai-engine/preview/message'

export const updateAiPreview = (aiReply: Preview.Message, workflow: ChatbotWorkflow) => {
	widgetPreviewApi(ApiCommand.ReloadWithMockData, getMockDataWithNewText(aiReply.content, workflow))
}

export const fetchAiAssistant = (data: Preview.CreateMessageParams) => {
	return apiClient.post<Preview.Message>(`${API_RESOURCE}`, data)
}
