import { Source } from 'shared/models/Source'

export enum SourcesModalType {
	Edit = 'edit',
	Create = 'create',
}

export enum SourcesModalSection {
	Type = 'sourceType',
	Details = 'sourceDetails',
}

export enum SourceFormType {
	Xml = 'xml',
	Web = 'web',
}

export type SourceFormData = Pick<Source, 'title'> & {
	id: Source['id'] | null
	parser: {
		url: Source['parser']['url']
		type: Source['parser']['type'] | null
		currency?: Source['parser']['currency']
	}
	formType: SourceFormType
}

export enum SourceFormError {
	TITLE_NOT_UNIQUE = 'title_not_unique',
	URL_HOST_MISMATCH = 'url_host_mismatch',
}
