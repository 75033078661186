import { batch } from 'react-redux'
import { createAsyncThunk, createSelector, createSlice, isFulfilled, isRejected, PayloadAction } from '@reduxjs/toolkit'
import equal from 'fast-deep-equal'

import { AddonsApi, ChatbotActionAppData } from 'models'
import { AppThunkAction, DashboardState, Dictionary } from 'types'
import {
	Addon,
	AddonActionResult,
	AddonSection,
	ContextAppSettings,
	ContextChatbotAction,
	ContextChatInfoPanel,
	ContextChatTextarea,
	ContextVisitorInfoPanel,
	EventType,
	GeneralContext,
} from 'shared/models/Addon'
import { ConfigurationService } from 'shared/services'
import { flashMessage } from 'shared/utils'
import { normalize } from 'utils/normalize'
import { accountSelectors } from 'modules/account'
import { chatDetailSelectors } from 'modules/chatDetail'
import { chatsSelectors } from 'modules/chats'
import { contactsSelectors } from 'modules/contacts'
import { visitorsSelectors } from 'modules/visitors'

import { addonsApi } from './api'
import { AddonDashboard, AddonEvents, FetchAddonParams, FetchAddonRequestParams } from './types'
import { getCommonProps, getEventDataValues } from './utils'

export type AddonsRootState = Pick<DashboardState, 'addons'>

const DEFAULT_CHAT_DETAIL_CONTEXT: ContextChatInfoPanel = { type: AddonSection.ChatInfoPanel, chat: { id: '' } }
const DEFAULT_VISITOR_CONTEXT: ContextVisitorInfoPanel = { type: AddonSection.VisitorInfoPanel, visitor: { id: '' } }
const DEFAULT_CHATBOT_ACTION_CONTEXT: ContextChatbotAction = { type: AddonSection.ChatbotAction, id: '', data: {} }
const DEFAULT_APP_SETTINGS_CONTEXT: ContextAppSettings = { type: AddonSection.AppSettings }
const DEFAULT_CHAT_TEXTAREA_CONTEXT: ContextChatTextarea = { type: AddonSection.ChatTextarea, text: '' }

const initialState = {
	list: null as null | Dictionary<AddonDashboard>,
	contextChatInfoPanel: DEFAULT_CHAT_DETAIL_CONTEXT,
	contextVisitorInfoPanel: DEFAULT_VISITOR_CONTEXT,
	contextChatbotAction: DEFAULT_CHATBOT_ACTION_CONTEXT,
	contextAppSettings: DEFAULT_APP_SETTINGS_CONTEXT,
	contextChatTextarea: DEFAULT_CHAT_TEXTAREA_CONTEXT,
	isAddonsInitialized: false,
	isActionFetching: false,
	isSectionChatTextareaFetching: false, // for AI Chat Area Addon
	isListFetching: false,
	isAddonInitPending: false,
	isError: false,
}

export const initAddons =
	(section: AddonSection, context: GeneralContext): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const getList = makeGetAddonsInSection(section)
		const list = getList(state)

		if (list.length > 0 && context) {
			list.forEach((addon) => {
				dispatch(fetchAddonComponents({ id: addon.id, section, context }))
			})
		}

		dispatch(setIsAddonsInitialized(true))
	}

export const updateAddons =
	(context: GeneralContext, section: AddonSection): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const getList = makeGetAddonsInSection(section)
		const list = getList(state)

		if (list.length > 0 && context) {
			list.forEach((addon) => {
				const getIsUpdateAddonAllowed = addonsSelectors.makeGetIsUpdateAddonAllowed(addon.id, context)
				if (getIsUpdateAddonAllowed(state) && addon.section) {
					dispatch(fetchAddonComponents({ id: addon.id, context, section: addon.section, isUpdate: true }))
				}
			})
		}
	}

export const initAddonsContextChatDetail = (): AppThunkAction => async (dispatch, getState) => {
	const state = getState()
	const chat = chatsSelectors.getSelectedChat(state)

	const getVisitorById = visitorsSelectors.makeGetVisitorById()
	const visitor = getVisitorById(state, { visitorId: chat?.visitorId })
	const isVisitorConnected = !!visitor?.connectedAt

	const contact = contactsSelectors.getSelectedContact(getState())

	const variables = isVisitorConnected ? visitor?.variables : chat?.variables

	if (!chat || !contact) return

	const context = {
		type: AddonSection.ChatInfoPanel,
		contact: { id: contact.id, name: contact.name, email: contact.email },
		chat: { id: chat.id },
		...(variables && { variables }),
		...(visitor && { visitor: { id: visitor.id } }),
	}

	batch(() => {
		dispatch(setChatDetailContext(context))
		dispatch(initAddons(AddonSection.ChatInfoPanel, context))
	})
}

export const initAddonsContextChatTextarea = (): AppThunkAction => async (dispatch, getState) => {
	const state = getState()

	const chatId = chatsSelectors.getChatDetailId(state)
	const getDraftMessageById = chatDetailSelectors.makeGetDraftMessageById()
	const message = getDraftMessageById(state, { chatId: chatId ?? undefined })

	const context = { type: AddonSection.ChatTextarea, text: message }
	batch(() => {
		dispatch(setChatTextareaContext(context))
		dispatch(initAddons(AddonSection.ChatTextarea, context))
	})
}

export const initAddonsContextVisitors =
	(context: ContextVisitorInfoPanel): AppThunkAction =>
	async (dispatch) => {
		batch(() => {
			dispatch(setVisitorContext(context))
			dispatch(initAddons(AddonSection.VisitorInfoPanel, context))
		})
	}

export const initAddonsContextChatbotAction =
	(appData: ChatbotActionAppData): AppThunkAction =>
	(dispatch) => {
		const { appId, actionId, options } = appData
		const addonId = `${appId}:${actionId}`

		const data = options || {}

		const context = { type: AddonSection.ChatbotAction, id: addonId, data }

		batch(() => {
			dispatch(setChatbotActionContext(context))
			dispatch(fetchAddonComponents({ id: addonId, section: AddonSection.ChatbotAction, context }))
			dispatch(setIsAddonsInitialized(true))
		})
	}
export const initAddonsSettings =
	(addonId: string | null): AppThunkAction =>
	(dispatch) => {
		if (addonId) {
			dispatch(
				fetchAddonComponents({
					id: addonId,
					section: AddonSection.AppSettings,
					context: { type: AddonSection.AppSettings },
				}),
			)
			dispatch(setIsAddonsInitialized(true))
		}
	}

export const updateContext =
	(changes: Partial<GeneralContext>): AppThunkAction =>
	async (dispatch) => {
		const section = changes.type
		if (!section) return
		switch (section) {
			case AddonSection.ChatInfoPanel: {
				dispatch(updateChatInfoPanelContext(changes))
				break
			}
			case AddonSection.VisitorInfoPanel: {
				dispatch(updateVisitorInfoPanelContext(changes))
				break
			}
		}
	}

export const updateChatInfoPanelContext =
	(changes: Partial<ContextChatInfoPanel>): AppThunkAction =>
	(dispatch, getState) => {
		const prevContext = getChatInfoPanelContext(getState())
		const newContext: ContextChatInfoPanel = { ...prevContext, ...changes }

		if (!equal(prevContext, newContext)) {
			batch(() => {
				dispatch(setChatDetailContext(newContext))
				dispatch(updateAddons(newContext, AddonSection.ChatInfoPanel))
			})
		}
	}

export const updateVisitorInfoPanelContext =
	(changes: Partial<ContextVisitorInfoPanel>): AppThunkAction =>
	(dispatch, getState) => {
		const prevContext = getVisitorInfoPanelContext(getState())
		const newContext: ContextVisitorInfoPanel = { ...prevContext, ...changes }

		if (!equal(prevContext, newContext)) {
			batch(() => {
				dispatch(setVisitorContext(newContext))
				dispatch(updateAddons(newContext, AddonSection.VisitorInfoPanel))
			})
		}
	}

export const updateChatbotActionContext =
	(changes: Partial<ContextChatbotAction>): AppThunkAction =>
	(dispatch, getState) => {
		const prevContext = getChatbotActionContext(getState())
		const newContext = { ...prevContext, ...changes }

		if (!equal(prevContext, newContext)) {
			dispatch(setChatbotActionContext(newContext))
		}
	}

export const fetchAddonComponentsAction =
	(addonId: string, action: AddonActionResult, section: AddonSection, isUpdate?: boolean): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const getContext = makeGetContextBySection(section)
		const context = getContext(state)

		if (!context) return
		dispatch(fetchAddonComponents({ id: addonId, context, action, section, isUpdate }))

		if (section === AddonSection.ChatTextarea) {
			dispatch(setIsSectionChatTextareaFetching(true))
		}
	}

export const fetchAddonComponents =
	({ id, section, context, action, isUpdate = false }: FetchAddonParams): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const { lang } = ConfigurationService.getData()
		const timezone = accountSelectors.getAccountTimezone(state)

		const getAddonState = makeGetStateByAddonId(id)
		const addonState = getAddonState(state)

		const data: FetchAddonRequestParams = { id, context, lang, timezone, section, state: addonState, action }
		if (isUpdate) {
			dispatch(updateAddonComponentsThunk(data))
		} else {
			dispatch(fetchAddonComponentsThunk(data))
		}
	}

export const fetchAddonsList = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const { lang } = ConfigurationService.getData()
	const timezone = accountSelectors.getAccountTimezone(state)

	return dispatch(fetchAddonsListThunk({ timezone, lang }))
}

export const fetchAddonsListThunk = createAsyncThunk('addons/FETCH', (data: AddonsApi.ListQuery) => {
	return addonsApi.fetchAddonsList(data)
})

const handleFetchAddonRequest = async (data: FetchAddonRequestParams) => {
	const response = await addonsApi.fetchAddonComponents(data)
	if (response.redirect) {
		window.location.href = response.redirect
	}
	return response
}

export const fetchAddonComponentsThunk = createAsyncThunk(
	'addons/FETCH_COMPONENTS',
	async (data: FetchAddonRequestParams, { rejectWithValue }) => {
		try {
			return await handleFetchAddonRequest(data)
		} catch (error) {
			return rejectWithValue(error)
		}
	},
)

export const updateAddonComponentsThunk = createAsyncThunk(
	'addons/UPDATE_COMPONENTS',
	async (data: FetchAddonRequestParams, { rejectWithValue }) => {
		try {
			return await handleFetchAddonRequest(data)
		} catch (error) {
			return rejectWithValue(error)
		}
	},
)

const slice = createSlice({
	name: 'addons',
	initialState,
	reducers: {
		setChatDetailContext: (state, { payload }: PayloadAction<ContextChatInfoPanel>) => {
			state.contextChatInfoPanel = { ...state.contextChatInfoPanel, ...payload }
		},
		setVisitorContext: (state, { payload }: PayloadAction<ContextVisitorInfoPanel>) => {
			state.contextVisitorInfoPanel = { ...state.contextVisitorInfoPanel, ...payload }
		},
		setChatbotActionContext: (state, { payload }: PayloadAction<ContextChatbotAction>) => {
			state.contextChatbotAction = { ...state.contextChatbotAction, ...payload }
		},
		setChatTextareaContext: (state, { payload }: PayloadAction<ContextChatTextarea>) => {
			state.contextChatTextarea = { ...state.contextChatTextarea, ...payload }
		},
		setIsAddonsInitialized: (state, { payload }: PayloadAction<boolean>) => {
			state.isAddonsInitialized = payload
		},
		setIsSectionChatTextareaFetching: (state, { payload }: PayloadAction<boolean>) => {
			state.isSectionChatTextareaFetching = payload
		},
		resetAddonEvents: (state, { payload }: PayloadAction<Addon['id']>) => {
			if (state.list && state.list[payload]) {
				state.list[payload].events = []
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAddonsListThunk.pending, (state) => {
				state.isListFetching = true
			})
			.addCase(fetchAddonsListThunk.fulfilled, (state, { payload }) => {
				state.list = normalize('id', payload)
				state.isListFetching = false
			})
			.addCase(fetchAddonsListThunk.rejected, (state) => {
				state.isListFetching = false
			})

		builder
			.addCase(fetchAddonComponentsThunk.pending, (state, { meta }) => {
				const addonId = meta.arg.id
				if (state.list && state.list[addonId]) {
					state.list[addonId].isFetching = true
				}
			})
			.addCase(fetchAddonComponentsThunk.fulfilled, (state, { meta }) => {
				const { section, id } = meta.arg
				if (state.list && state.list[id]) {
					state.list[id].isFetching = false
				}

				if (section === AddonSection.ChatTextarea) {
					state.isSectionChatTextareaFetching = false
				}
			})
			.addCase(fetchAddonComponentsThunk.rejected, (state, { meta }) => {
				const { section, id } = meta.arg
				if (state.list && state.list[id]) {
					state.list[id].isFetching = false
				}

				if (section === AddonSection.ChatTextarea) {
					state.isSectionChatTextareaFetching = false
					flashMessage.error('general.error')
				}
			})

		builder.addMatcher(
			isFulfilled(fetchAddonComponentsThunk, updateAddonComponentsThunk),
			(state, { meta, payload }) => {
				const addonId = meta.arg.id
				if (state.list && state.list[addonId]) {
					if (payload.components) {
						state.list[addonId].components = payload.components
						state.list[addonId].context = payload.context
						state.list[addonId].section = meta.arg.section
						state.list[addonId].state = payload.state ?? {}
						state.list[addonId].isError = false
						state.list[addonId].errors = payload.errors ?? []
					} else {
						state.list[addonId].isError = true
					}
					state.list[addonId].events = payload.events ?? []
				}
			},
		)

		builder.addMatcher(isRejected(fetchAddonComponentsThunk, updateAddonComponentsThunk), (state, { meta }) => {
			const addonId = meta.arg.id
			if (state.list && state.list[addonId]) {
				state.list[addonId].isError = true
			}
		})
	},
})

const { reducer, actions } = slice
export default reducer
export const {
	setIsAddonsInitialized,
	setChatDetailContext,
	setVisitorContext,
	setChatbotActionContext,
	resetAddonEvents,
	setChatTextareaContext,
	setIsSectionChatTextareaFetching,
} = actions

const getAddonsList = (state: AddonsRootState) => state.addons.list
const getIsListFetching = (state: AddonsRootState) => state.addons.isListFetching
const getIsAddonsInitialized = (state: AddonsRootState) => state.addons.isAddonsInitialized
const getChatInfoPanelContext = (state: AddonsRootState) => state.addons.contextChatInfoPanel
const getVisitorInfoPanelContext = (state: AddonsRootState) => state.addons.contextVisitorInfoPanel
const getChatbotActionContext = (state: AddonsRootState) => state.addons.contextChatbotAction
const getChatTextareaContext = (state: AddonsRootState) => state.addons.contextChatTextarea
const getAppSettingsContext = (state: AddonsRootState) => state.addons.contextAppSettings
const getChatTextareaSectionIsFetching = (state: AddonsRootState) => state.addons.isSectionChatTextareaFetching

const makeGetIsUpdateAddonAllowed = (addonId: string, sectionContext: GeneralContext) => {
	return createSelector([getAddonsList], (list): boolean => {
		if (!list || !list[addonId]) return false

		const { context } = list[addonId]

		// pick releated data from sectionContext (intersection with responseContext)
		const intersectResult = getCommonProps(sectionContext, context)
		// get all changes (updade, add, delete) between responseContext and related sectionContext
		return !equal(context, intersectResult)
	})
}

const makeGetAddonById = (addonId?: string | null) => {
	return createSelector([getAddonsList], (list): AddonDashboard | null => {
		if (!addonId || !list || !list[addonId]) return null
		return list[addonId]
	})
}

const makeGetIsFetchingByAddonId = (addonId: string) => {
	return createSelector([getAddonsList], (list): boolean => {
		if (!list || !list[addonId]) return false

		return !!list[addonId].isFetching
	})
}

const makeGetContextBySection = (section: AddonSection) => {
	return createSelector(
		[
			getChatInfoPanelContext,
			getVisitorInfoPanelContext,
			getChatbotActionContext,
			getChatTextareaContext,
			getAppSettingsContext,
		],
		(
			chatContext,
			visitorContext,
			chatbotActionContext,
			chatTextareaContext,
			appSettingsContext,
		): GeneralContext | null => {
			switch (section) {
				case AddonSection.ChatInfoPanel: {
					return chatContext
				}
				case AddonSection.VisitorInfoPanel: {
					return visitorContext
				}
				case AddonSection.ChatbotAction: {
					return chatbotActionContext
				}
				case AddonSection.AppSettings: {
					return appSettingsContext
				}
				case AddonSection.ChatTextarea: {
					return chatTextareaContext
				}
				default: {
					return null
				}
			}
		},
	)
}

const makeGetSectionByAddonId = (addonId?: string) => {
	return createSelector([getAddonsList], (list): AddonSection | null => {
		if (!list || !addonId) return null

		return list[addonId]?.section ?? null
	})
}

const makeGetIsErrorByAddonId = (addonId: string) => {
	return createSelector([getAddonsList], (list): boolean => {
		if (!list || !list[addonId].errors) return false

		if (list[addonId]?.errors?.length !== 0) return true

		return !!list[addonId].isError
	})
}

const makeGetEventsByAddonId = (addonId: string) => {
	return createSelector([getAddonsList], (list): AddonEvents => {
		if (!list || !list[addonId].events)
			return { hasCancel: false, hasSuccess: false, eventData: null, isChatTextareaEvent: false }
		const { events } = list[addonId]

		const hasCancel = events ? events.some((event) => event.name === EventType.FormCancel) : false
		const hasSuccess = events ? events.some((event) => event.name === EventType.FormSuccess) : false
		const isChatTextareaEvent = events ? events.some((event) => event.name === EventType.ChatTextareaResponse) : false
		const eventData = events && events.length > 0 ? getEventDataValues(events[0]) : null

		return {
			hasCancel,
			hasSuccess,
			isChatTextareaEvent,
			eventData,
		}
	})
}

const makeGetStateByAddonId = (addonId: string) => {
	return createSelector([getAddonsList], (list): AddonDashboard['state'] => {
		if (!list) return {}

		return list[addonId]?.state ?? {}
	})
}

const makeGetAddonsInSection = (section: AddonSection) => {
	return createSelector([getAddonsList], (list): AddonDashboard[] | [] => {
		if (!list) return []

		const addons: AddonDashboard[] = []

		Object.values(list).forEach((addon) => {
			if (addon.sections.includes(section)) {
				addons.push(addon)
			}
		})

		return addons
	})
}

const makeGetHasIntegrationSettings = (integrationId: string) => {
	return createSelector([getAddonsList], (list) => {
		if (!list) return false
		const hasIntegrationSettings = Object.values(list).some(
			(addon) => addon.sections.includes(AddonSection.AppSettings) && addon.id.includes(integrationId),
		)
		return hasIntegrationSettings
	})
}

const makeGetAddonIdByIntegrationId = (integrationId: string) => {
	return createSelector([getAddonsList], (list) => {
		if (!list) return null
		const addonByIntegrationIdAndSection = Object.values(list).find(
			(addon) => addon.sections.includes(AddonSection.AppSettings) && addon.id.includes(integrationId),
		)
		return addonByIntegrationIdAndSection ? addonByIntegrationIdAndSection.id : null
	})
}

export const addonsSelectors = {
	getIsListFetching,
	getIsAddonsInitialized,
	makeGetIsUpdateAddonAllowed,
	makeGetAddonById,
	makeGetEventsByAddonId,
	makeGetIsFetchingByAddonId,
	makeGetIsErrorByAddonId,
	makeGetSectionByAddonId,
	makeGetStateByAddonId,
	makeGetContextBySection,
	makeGetAddonsInSection,
	makeGetHasIntegrationSettings,
	makeGetAddonIdByIntegrationId,
	getChatTextareaSectionIsFetching,
}
