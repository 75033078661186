import { Restriction } from 'models'
import { apiClient } from 'shared/utils'
import { CreateRestrictionRequest } from 'modules/restrictions'

const API_RESOURCE_RESTRICTIONS = 'restrictions'

const fetchRestrictions = () => {
	return apiClient.get<Restriction[]>(`${API_RESOURCE_RESTRICTIONS}`)
}

const deleteRestriction = (id: number) => {
	return apiClient.delete(`${API_RESOURCE_RESTRICTIONS}/${id}`)
}

const createRestriction = (data: CreateRestrictionRequest) => {
	return apiClient.post<Restriction>(`${API_RESOURCE_RESTRICTIONS}`, data)
}

export const restrictionsApi = { fetchRestrictions, deleteRestriction, createRestriction }
