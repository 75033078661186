import { Input } from './input'

const { sizes, variants } = Input

const baseStyle = {
	field: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
}

export const Select = {
	sizes,
	variants,
	baseStyle,
}
