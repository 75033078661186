import { useSelector } from 'react-redux'
import { LinkBox, LinkOverlay } from '@chakra-ui/react'
import { mdiFacebookMessenger, mdiFormSelect, mdiHelpCircleOutline, mdiRobotOutline } from '@mdi/js'

import { SmartsuppWindow } from 'shared/types'
import { TranslationService as T } from 'shared/services/TranslationService'
import { getHelpLink } from 'configuration/links'
import { openChatWidget } from 'utils/renderChatWidget'
import { appSelectors } from 'modules/app'

import { CButton } from 'shared/components/core/CButton'
import { Flex } from 'shared/components/core/Flex'
import { Icon } from 'shared/components/core/Icon'
import { Heading } from 'shared/components/core/Layout'
import { Text } from 'shared/components/core/Text'

declare let window: SmartsuppWindow

enum LinkUrl {
	Home = '',
	FacebookIntegration = 'facebook-integration',
	FaqChatbot = 'faq-chatbot',
	AuthorizationForm = 'authorization-form',
}

interface NeedHelpDataItem {
	icon: string
	linkUrl: LinkUrl
	linkText: string
	description: string
	id: string
}

type Props = {
	isFreePackage: boolean
	onClick?: () => void
}

const needHelpData: NeedHelpDataItem[] = [
	{
		icon: mdiHelpCircleOutline,
		linkUrl: LinkUrl.Home,
		linkText: 'helpCenter.linkText',
		description: 'helpCenter.description',
		id: 'smartsupp-chat-code',
	},
	{
		icon: mdiFacebookMessenger,
		linkUrl: LinkUrl.FacebookIntegration,
		linkText: 'facebookIntegration.linkText',
		description: 'facebookIntegration.description',
		id: 'facebook-integration',
	},
	{
		icon: mdiRobotOutline,
		linkUrl: LinkUrl.FaqChatbot,
		linkText: 'chatbot.linkText',
		description: 'chatbot.description',
		id: 'faq-chatbot',
	},
	{
		icon: mdiFormSelect,
		linkUrl: LinkUrl.AuthorizationForm,
		linkText: 'authForm.linkText',
		description: 'authForm.description',
		id: 'authorization-form',
	},
]

const NeedHelpMenu = ({ isFreePackage, onClick }: Props) => {
	const shouldRenderChatWidget = useSelector(appSelectors.isWidgetRendered)
	const handleChatOnClick = () => {
		openChatWidget()
		onClick && onClick()
	}

	return (
		<>
			<Flex alignItems="center" justifyContent="space-between">
				<Heading size="md" my={2} mx={2}>
					{T.translate('needHelp.title')}
				</Heading>
			</Flex>
			{!isFreePackage && (
				<Text fontSize={0} mx={2} mb={1} textColor="gray.500">
					{T.translate('needHelp.subtitle')}
				</Text>
			)}
			{needHelpData.map((item) => {
				return (
					<LinkBox
						as="article"
						id={item.id}
						data-testid={item.id}
						key={item.id}
						onClick={onClick}
						borderBottom={1}
						borderColor="gray.200"
						w="100%"
						pt={1}
						pb={2}
						px={2}
						sx={{
							a: {
								color: 'gray.700',
							},
							':last-child': {
								borderBottom: 'none',
							},
						}}
						_hover={{
							backgroundColor: 'blue.200',
							a: {
								color: 'blue.500',
							},
						}}
					>
						<Flex flexDirection="row" alignItems="center" my={1}>
							<Icon path={item.icon} fontSize={3} textColor="blue.500" mr={1} />
							<Heading as="h4" size="sm">
								<LinkOverlay
									href={getHelpLink(item.linkUrl)}
									isExternal
									_hover={{
										textDecoration: 'none',
									}}
								>
									{T.translate(`needHelp.item.${item.linkText}`)}
								</LinkOverlay>
							</Heading>
						</Flex>
						<Text>{T.translate(`needHelp.item.${item.description}`)}</Text>
					</LinkBox>
				)
			})}
			{shouldRenderChatWidget && !isFreePackage && (
				<Flex m={2}>
					<CButton onClick={handleChatOnClick} width="100%" data-testid="chat-with-us">
						{T.translate('needHelp.item.chatBox.linkText')}
					</CButton>
				</Flex>
			)}
		</>
	)
}

export default NeedHelpMenu
