const variantOnlineStatus = {
	track: {
		bg: 'red.500',
		_checked: { bg: 'green.500' },
	},
}

const variants = {
	'online-status': variantOnlineStatus,
}

const defaultProps = {
	colorScheme: 'blue',
}

export const Switch = {
	variants,
	defaultProps,
}
