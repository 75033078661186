import { FieldErrors } from 'react-hook-form'
import { nanoid } from 'nanoid'

import {
	AllowedActions,
	Chatbot,
	ChatbotAction,
	ChatbotActionContactPropertyName,
	ChatbotActionContactRetrieveProperty,
	ChatbotActionData,
	ChatbotActionMessageData,
	ChatbotActionProperties,
	ChatbotActionType,
	ChatbotGroupType,
	ChatbotInteraction,
	ChatbotReply,
	ChatbotSimple,
	HandoverType,
	Property,
	SelectedChatbotReply,
} from 'models'
import { TranslationService as T } from 'shared/services'
import { routes } from 'configuration/routes'
import { navigateTo, normalize } from 'utils'
import { MAX_CHATBOT_LEVEL, MAX_CHATBOT_NAME_LENGTH, MAX_CHATBOT_REPLIES } from 'modules/chatbots'

import {
	CHATBOT_TITLE_ID_LENGTH,
	chatbotRetrievePropertyTypeMap,
	GENERATED_INTERACTION_ID_LENGTH,
	GENERATED_INTERACTION_ID_PREFIX,
} from './constants'
import {
	ChatbotAdditionalAction,
	ChatbotAdditionalActionTypes,
	ChatbotBuilderBotSelectOption,
	ChatbotBuilderInteractionFormData,
	ChatbotBuilderMovableOrCopiableNode,
	ChatbotBuilderNode,
	ChatbotInteractionData,
} from './types'

const generatedInteractionIdRegex = new RegExp(
	`^${GENERATED_INTERACTION_ID_PREFIX}.{${GENERATED_INTERACTION_ID_LENGTH}}$`,
)

export const generateInteractionId = (): string => {
	return `${GENERATED_INTERACTION_ID_PREFIX}${nanoid(GENERATED_INTERACTION_ID_LENGTH)}`
}

export const isInteractionIdGenerated = (interactionId: string): boolean => {
	return generatedInteractionIdRegex.test(interactionId)
}

export const isActionWithMessageText = (actionType: ChatbotActionType) => {
	// Used to determine if it's allowed to save the message text for this action
	const allowedTypes: Set<ChatbotActionType> = new Set([ChatbotActionType.Message, ChatbotActionType.App])
	return allowedTypes.has(actionType)
}

export const isAllowedActionType = (actionType: ChatbotActionType) => {
	// Used to determine if the action type is allowed to be displayed in the chatbot builder as builder a node
	const allowedTypes: Set<ChatbotActionType> = new Set([
		ChatbotActionType.Message,
		ChatbotActionType.HandoverV2,
		ChatbotActionType.App,
		ChatbotActionType.ContactRetrieveProperty,
		ChatbotActionType.BotTransfer,
	])
	return allowedTypes.has(actionType)
}

export const isAddableChatbotAction = (actionType: ChatbotActionType) => {
	// Used to determine if the action type is allowed to be added in the interaction actions panel
	const allowedTypes: Set<ChatbotActionType> = new Set([
		ChatbotActionType.ChatAddTags,
		ChatbotActionType.ContactAddTags,
	])
	return allowedTypes.has(actionType)
}

export const findInteractionActionByType = (
	interaction: ChatbotInteraction | null,
	actionType: ChatbotActionType | ChatbotActionType[],
): ChatbotAction | null => {
	const actionTypesArray = Array.isArray(actionType) ? actionType : [actionType]
	const actionTypes = new Set(actionTypesArray)

	const foundAction = interaction?.actions.find((a) => actionTypes.has(a.type))
	if (!foundAction) return null

	return foundAction
}

export const findMessageActionIndex = (actions: ChatbotAction[]) => {
	return actions.findIndex((a) => a.type === ChatbotActionType.Message)
}

export const findHandoverActionIndex = (actions: ChatbotAction[]) => {
	return actions.findIndex((a) => a.type === ChatbotActionType.HandoverV2)
}

export const findAppActionIndex = (actions: ChatbotAction[]) => {
	return actions.findIndex((a) => a.type === ChatbotActionType.App)
}

export const findBotTransferActionIndex = (actions: ChatbotAction[]) => {
	return actions.findIndex((a) => a.type === ChatbotActionType.BotTransfer)
}

export const generateChatbotTitle = (title: string): string => {
	const maxTitleLength = MAX_CHATBOT_NAME_LENGTH - CHATBOT_TITLE_ID_LENGTH - 1
	let outputTitle = title
	if (outputTitle.length > maxTitleLength) {
		outputTitle = outputTitle.slice(0, Math.max(0, maxTitleLength))
	}
	return `${outputTitle} ${nanoid(CHATBOT_TITLE_ID_LENGTH)}`
}

export const generateAiWelcomeMessageTitle = (): string => {
	return `Mira AI ${T.translate('onboard.chatbot.label').toLocaleLowerCase()} ${nanoid(CHATBOT_TITLE_ID_LENGTH)}`
}

export const transformReply = (reply: ChatbotReply): SelectedChatbotReply => ({ ...reply, isTemporary: false })

export const hasReplyError = (errors: FieldErrors<ChatbotBuilderInteractionFormData>, fieldIndex: number): boolean => {
	return !!errors?.replies?.[fieldIndex]?.text?.message
}

export const shouldAllowAddReply = (fieldsLength: number, level: number, type: ChatbotActionType | null): boolean => {
	return (
		fieldsLength < MAX_CHATBOT_REPLIES &&
		level < MAX_CHATBOT_LEVEL &&
		(type === ChatbotActionType.Message || type === ChatbotActionType.ContactRetrieveProperty)
	)
}

export const shouldDisplayDeleteButton = (
	interactionsIndex: number | null,
	data: ChatbotActionData | null,
): boolean => {
	if (!data) return false
	return !!interactionsIndex
}

export const isInteractionById =
	(interactionId: string | null) =>
	(interaction: ChatbotInteraction): boolean => {
		return interaction.id === interactionId
	}

export const isReplyByNextInteractionId =
	(interactionId: string) =>
	(reply: ChatbotReply): boolean => {
		return reply.nextInteractionId === interactionId
	}

const getDefaultContactRetrievePropertyActions = (): ChatbotActionContactRetrieveProperty[] => {
	const defaultProperties: ChatbotActionContactPropertyName[] = [
		ChatbotActionContactPropertyName.Name,
		ChatbotActionContactPropertyName.Email,
		ChatbotActionContactPropertyName.Phone,
	]

	return defaultProperties.map((property) => ({
		type: ChatbotActionType.ContactRetrieveProperty,
		data: {
			property,
			text: T.translate(chatbotRetrievePropertyTypeMap[property].question),
		},
	}))
}

export const createInteractionObject = ({
	id,
	type,
	appId,
	actionId,
	actions,
}: ChatbotInteractionData): ChatbotInteraction => {
	const hasActions = actions && actions.length > 0

	if (type === ChatbotActionType.HandoverV2) {
		return {
			id: id ?? '',
			actions: hasActions
				? actions
				: [
						{
							type: ChatbotActionType.HandoverV2,
							data: {
								online: {
									type: HandoverType.Inbox,
									message: T.translate('chatbotBuilder.nodes.handover.online.inbox.defaultText'),
								},
								offline: {
									type: HandoverType.Inbox,
									message: T.translate('chatbotBuilder.nodes.handover.offline.inbox.defaultText'),
								},
							},
						},
				  ],
		}
	}

	if (type === ChatbotActionType.BotTransfer) {
		return {
			id: id ?? '',
			actions: hasActions
				? actions
				: [
						{
							type: ChatbotActionType.BotTransfer,
							data: {
								botId: '',
							},
						},
				  ],
		}
	}

	if (type === ChatbotActionType.App) {
		return {
			id: id ?? '',
			actions: hasActions
				? actions
				: [
						{
							type: ChatbotActionType.App,
							data: {
								appId: appId ?? '',
								actionId: actionId ?? '',
							},
						},
				  ],
		}
	}

	if (type === ChatbotActionType.ContactRetrieveProperty) {
		return {
			id: id ?? '',
			actions: hasActions
				? actions
				: [
						...getDefaultContactRetrievePropertyActions(),
						{
							type: ChatbotActionType.Message,
							data: {
								text: T.translate('chatbotBuilder.nodes.retrieveProperty.endReply'),
								replies: [],
							},
						},
				  ],
		}
	}

	return {
		id: id ?? '',
		actions: hasActions
			? actions
			: [
					{
						type: ChatbotActionType.Message,
						data: {
							text: T.translate('chatbotBuilder.nodes.chatMessage.defaultText'),
							replies: [],
						},
					},
			  ],
	}
}

export const transformChatbotToBuilderNode = (chatbot: Chatbot): ChatbotBuilderNode => {
	const interactionsMap = normalize('id', chatbot.interactions)
	const entrypoint = chatbot.interactions[0]

	const createBuilderNode = (interaction: ChatbotInteraction, title?: string) => {
		// Finds the first Action with the allowed action type, used to correctly display node icon and node content
		const filteredInteractionAction = findInteractionActionByType(interaction, [
			ChatbotActionType.Message,
			ChatbotActionType.HandoverV2,
			ChatbotActionType.App,
			ChatbotActionType.ContactRetrieveProperty,
			ChatbotActionType.BotTransfer,
		])

		const firstAllowedAction = filteredInteractionAction as AllowedActions

		const { type, data } = firstAllowedAction

		const node: ChatbotBuilderNode = {
			id: interaction.id ?? '',
			content: 'text' in data ? data.text : 'online' in data ? data.online.message : '',
			title,
			nodes: [],
			type,
		}

		// Finds the first Message Action, used to display child nodes in the builder
		const messageAction = findInteractionActionByType(interaction, [ChatbotActionType.Message])
		const messageActionData = messageAction ? (messageAction.data as ChatbotActionMessageData) : null

		if (messageActionData && 'replies' in messageActionData) {
			messageActionData.replies.forEach((r) => {
				const replyInteraction = interactionsMap[r.nextInteractionId ?? '']
				node.nodes.push(createBuilderNode(replyInteraction, r.text))
			})
		}
		return node
	}

	return createBuilderNode(entrypoint)
}

export const findBuilderNodeByInteractionId = (
	builderNode: ChatbotBuilderNode,
	interactionId: string,
): ChatbotBuilderNode | null => {
	let result = null
	const processBuilderNode = (node: ChatbotBuilderNode) => {
		if (node.id === interactionId) {
			result = node
			return
		}
		node.nodes.forEach((n) => {
			processBuilderNode(n)
		})
	}

	processBuilderNode(builderNode)
	return result
}

export const getBuilderNodeMaxLevel = (builderNode: ChatbotBuilderNode): number => {
	let maxLevel = 1
	const processBuilderNode = (node: ChatbotBuilderNode, level: number) => {
		if (level > maxLevel) {
			maxLevel = level
		}
		node.nodes.forEach((n) => {
			processBuilderNode(n, level + 1)
		})
	}

	processBuilderNode(builderNode, 1)
	return maxLevel
}

export const transformChatbotBuilderToCopiableNode = (
	builderNode: ChatbotBuilderNode,
	interactionIdToCopy: string,
): ChatbotBuilderMovableOrCopiableNode => {
	const copyBuilderNode = findBuilderNodeByInteractionId(builderNode, interactionIdToCopy)
	if (!copyBuilderNode) throw new Error("Node to copy wasn't found")

	const copyNodeMaxLevel = getBuilderNodeMaxLevel(copyBuilderNode)

	const createBuilderCopiableNode = (node: ChatbotBuilderNode, level: number) => {
		const hasNodeMaxChildren = node.nodes.length >= MAX_CHATBOT_REPLIES
		const isCombinedMaxLevelExceeded = copyNodeMaxLevel + level > MAX_CHATBOT_LEVEL
		const isAllowedType =
			node.type === ChatbotActionType.Message || node.type === ChatbotActionType.ContactRetrieveProperty
		const isDisabled = hasNodeMaxChildren || isCombinedMaxLevelExceeded || !isAllowedType

		const copiableNode: ChatbotBuilderMovableOrCopiableNode = {
			...node,
			isDisabled,
			nodes: [],
		}
		node.nodes.forEach((n) => {
			copiableNode.nodes.push(createBuilderCopiableNode(n, level + 1))
		})
		return copiableNode
	}

	return createBuilderCopiableNode(builderNode, 0)
}

export const transformChatbotBuilderNodeToMovableNode = (
	builderNode: ChatbotBuilderNode,
	interactionIdToMove: string,
): ChatbotBuilderMovableOrCopiableNode => {
	const movedBuilderNode = findBuilderNodeByInteractionId(builderNode, interactionIdToMove)
	if (!movedBuilderNode) throw new Error("Node to move wasn't found")

	const moveNodeMaxLevel = getBuilderNodeMaxLevel(movedBuilderNode)

	const createBuilderMovableNode = (node: ChatbotBuilderNode, level: number, isParentDisabled: boolean) => {
		const hasNodeMaxChildren = node.nodes.length >= MAX_CHATBOT_REPLIES
		const isCombinedMaxLevelExceeded = moveNodeMaxLevel + level > MAX_CHATBOT_LEVEL
		const isMovedNode = node.id === interactionIdToMove
		const isAllowedType =
			node.type === ChatbotActionType.Message || node.type === ChatbotActionType.ContactRetrieveProperty
		const shouldNodeBeDisabled = isMovedNode || hasNodeMaxChildren || isCombinedMaxLevelExceeded || !isAllowedType
		const isDisabled = isParentDisabled ? true : shouldNodeBeDisabled
		const movableNode: ChatbotBuilderMovableOrCopiableNode = { ...node, isDisabled, nodes: [] }
		node.nodes.forEach((n) => {
			const shouldChildrenBeDisabled = isParentDisabled || isMovedNode
			movableNode.nodes.push(createBuilderMovableNode(n, level + 1, shouldChildrenBeDisabled))
		})
		return movableNode
	}

	return createBuilderMovableNode(builderNode, 0, false)
}

export const deleteChatbotInteractions = (chatbot: Chatbot, interactionId: string | null): ChatbotInteraction[] => {
	if (!interactionId) return []
	const interactionsMap = normalize('id', chatbot.interactions)
	const entrypoint = interactionsMap[interactionId]

	const deleteNode = (interaction: ChatbotInteraction) => {
		// Delete all interactions which are used as replies in the deleted node
		const messageNodeIndex = findMessageActionIndex(interaction.actions)
		const messageAction = interaction.actions[messageNodeIndex]

		if (messageAction && messageAction.type === ChatbotActionType.Message) {
			messageAction.data.replies.forEach((r) => {
				const replyInteraction = interactionsMap[r.nextInteractionId ?? '']
				deleteNode(replyInteraction)
			})
		}
		interaction?.id && delete interactionsMap[interaction.id]
	}

	// Entrypoint can be undefined if the interaction to be deleted is temporary
	entrypoint && deleteNode(entrypoint)

	Object.values(interactionsMap).forEach((i) => {
		// Delete all replies which have same nextInteractionId as the deleted interaction
		const messageNodeIndex = findMessageActionIndex(i.actions)
		const messageAction = i.actions[messageNodeIndex]

		if (messageAction && messageAction.type === ChatbotActionType.Message) {
			if (!messageAction.data.replies) return
			const replyIndex = messageAction.data.replies.findIndex(isReplyByNextInteractionId(interactionId))
			if (replyIndex >= 0) {
				messageAction.data.replies.splice(replyIndex, 1)
			}
		}
	})

	return Object.values(interactionsMap)
}

export const moveChatbotInteraction = (
	chatbot: Chatbot,
	interactionId: string | null,
	newParentInteractionId: string,
): ChatbotInteraction[] => {
	if (!interactionId) return []
	let movedReply: ChatbotReply | null = null
	const interactionsMap = normalize('id', chatbot.interactions)

	// Remove interaction from current parent
	Object.values(interactionsMap).forEach((i) => {
		const messageNodeIndex = findMessageActionIndex(i.actions)
		const messageAction = i.actions[messageNodeIndex]

		if (messageAction && messageAction.type === ChatbotActionType.Message) {
			const replyIndex = messageAction.data.replies.findIndex(isReplyByNextInteractionId(interactionId))
			if (replyIndex > -1) {
				movedReply = messageAction.data.replies[replyIndex]
				messageAction.data.replies.splice(replyIndex, 1)
			}
		}
	})

	if (!movedReply) throw new Error('Trying to move non-existent reply')

	// Add interaction to new parent
	const newParentInteraction = interactionsMap[newParentInteractionId]
	const messageNodeIndex = findMessageActionIndex(newParentInteraction.actions)
	const newInteractionMessageAction = newParentInteraction.actions[messageNodeIndex]

	if (newInteractionMessageAction && newInteractionMessageAction.type === ChatbotActionType.Message) {
		newInteractionMessageAction.data.replies.push(movedReply)
	}
	return Object.values(interactionsMap)
}

export const createChatbotInteraction = ({
	id = '',
	text,
	replies = [],
}: {
	id?: string | null
	text: string
	replies?: ChatbotReply[]
}): ChatbotInteraction => ({
	...(id && { id }),
	actions: [{ type: ChatbotActionType.Message, data: { text, replies } }],
})

export const getRemovedReplies = (repliesBefore: ChatbotReply[], repliesAfter: ChatbotReply[]) => {
	return repliesBefore.filter(
		(replyBefore) => !repliesAfter.some(isReplyByNextInteractionId(replyBefore.nextInteractionId ?? '')),
	)
}

export const findReplyByNextInteractionId = (replies: ChatbotReply[], id: string): ChatbotReply | undefined => {
	return replies?.find((reply) => reply.nextInteractionId === id)
}

export const getInteractionParent = (interactions: ChatbotInteraction[], interactionId: string): string | null => {
	// Find interaction that includes message action, that has a reply with specified interaction ID
	const interaction = interactions.find((i) => {
		const messageAction = i.actions.find((a) => a.type === ChatbotActionType.Message)
		if (messageAction) {
			const { data } = messageAction
			if ('replies' in data) {
				return data.replies.some((r) => r.nextInteractionId === interactionId)
			}
		}
		return false
	})
	if (interaction && interaction.id) return interaction.id
	return null
}

export const getInteractionLevel = (interactions: ChatbotInteraction[], interactionId: string) => {
	const getLevel = (id: string, level: number): number => {
		const interactionParent = getInteractionParent(interactions, id)
		if (interactionParent) return getLevel(interactionParent, level + 1)
		return level
	}
	return getLevel(interactionId, 0)
}

export const getAddableActionsData = (interaction: ChatbotInteraction | null): ChatbotAdditionalAction[] => {
	const actions = interaction?.actions.filter((action) => isAddableChatbotAction(action.type))
	if (!actions) return []

	return actions.map((action) => {
		const { data } = action
		const tagIds = 'tagIds' in data ? (data.tagIds as string[]) : []
		const name = action.type as ChatbotAdditionalActionTypes
		return { name, data: tagIds }
	})
}

export const getAvailablePropertiesInForm = (allProperties: Property[], propertiesInForm: string[]): Property[] => {
	return allProperties.filter((property) => !propertiesInForm.includes(property.key))
}

export const getPropertyKeyToName = (key: string | null, properties: Property[]) => {
	if (!properties) return null
	return (properties.find((prop) => prop.key === key)?.name as string) ?? null
}

export const getIsDefaultProperty = (property: string | null) => {
	switch (property) {
		case ChatbotActionProperties.Question:
		case ChatbotActionProperties.Email:
		case ChatbotActionProperties.Phone:
		case ChatbotActionProperties.Name: {
			return true
		}
		default: {
			return false
		}
	}
}

const getChatbotSelectOptionLabel = (botTitle: string, isSelected: boolean) => {
	return isSelected ? `${botTitle} (${T.translate('chatbot.botTransfer.currentBot.label')})` : botTitle
}

export const getChatbotSelectOptions = (
	chatbots: ChatbotSimple[],
	selectedBot: string,
): { label: string; options: ChatbotBuilderBotSelectOption[] }[] => {
	const activeBots = chatbots.filter((bot) => bot.isActive)
	const inactiveBots = chatbots.filter((bot) => !bot.isActive)

	const categories = [
		{ label: T.translate('chatbot.garage.active'), options: activeBots },
		{ label: T.translate('chatbot.garage.inactive'), options: inactiveBots },
	]

	return categories.map((category) => ({
		label: category.label,
		options: category.options.map((bot) => ({
			label: getChatbotSelectOptionLabel(bot.title, bot.id === selectedBot),
			value: bot.id,
			isSelectedBot: bot.id === selectedBot,
		})),
	}))
}

export const getChatbotSelectDefaultValue = (
	chatbots: ChatbotSimple[],
	botId: string,
	selectedBot: string,
): ChatbotBuilderBotSelectOption => {
	const selectedChatbot = chatbots.find((bot) => bot.id === botId)
	return {
		label: getChatbotSelectOptionLabel(selectedChatbot?.title ?? '', selectedBot === botId),
		value: selectedChatbot?.id ?? '',
		isSelectedBot: selectedBot === botId,
	}
}
// Copy node interaction utils

export const getCopiableInteractionIds = (chatbot: Chatbot, interaction: ChatbotInteraction): Set<string> => {
	// Returns all the children interaction IDs, including the parent interaction ID
	const ids: Set<string> = new Set([])

	if (interaction.id) ids.add(interaction.id)
	const messageActionIndex = findMessageActionIndex(interaction.actions)
	const messageAction = interaction.actions[messageActionIndex]
	if (messageAction && messageAction.type === ChatbotActionType.Message) {
		messageAction.data.replies.forEach((r) => {
			const replyInteraction = chatbot.interactions.find((i) => i.id === r.nextInteractionId)
			if (replyInteraction) {
				if (replyInteraction.id) ids.add(replyInteraction.id)
				getCopiableInteractionIds(chatbot, replyInteraction).forEach((id) => ids.add(id))
			}
		})
	}
	return ids
}

const getCopyInteractionData = (
	chatbot: Chatbot,
	interactionId: string,
): { newInteractions: ChatbotInteraction[]; copiedInteractionTempId: string } | null => {
	if (!chatbot) return null

	const finalInteractions: ChatbotInteraction[] = []

	const originalInteraction = chatbot.interactions.find((i) => i.id === interactionId)
	if (!originalInteraction) return null

	const interactionIds = getCopiableInteractionIds(chatbot, originalInteraction)

	// Assign new temp IDs to the copied interactions
	// Filter only interactions that are about to be copied
	const newInteractions = chatbot.interactions
		.filter((i) => i.id && interactionIds.has(i.id))
		.map((i) => {
			return { ...i, newId: generateInteractionId() }
		})

	// Gets the temp ID of the copied interaction (used to create reply in the parent interaction)
	const copiedInteractionIndex = newInteractions.findIndex((i) => i.id === interactionId)
	const copiedInteractionTempId = newInteractions[copiedInteractionIndex].newId

	// Creates an array of new temp interactions (assigned tempt ID and transformed actions)
	newInteractions.forEach((i) => {
		const interactionActions = i.actions
		const newInteractionActions = interactionActions.map((a) => {
			if (a.type === ChatbotActionType.Message) {
				const messageActionData = a.data
				const { replies } = messageActionData
				const newReplies = replies.map((r) => {
					const newIdIndex = newInteractions.findIndex((int) => int.id === r.nextInteractionId)
					const newNextInteractionId = newInteractions[newIdIndex].newId
					return { ...r, id: undefined, nextInteractionId: newNextInteractionId }
				})
				return { ...a, id: undefined, data: { ...a.data, replies: newReplies } }
			}
			// IDs for new actions need to be undefined, BE will assign and returns new IDs in this case
			return { ...a, id: undefined }
		})
		finalInteractions.push({ id: i.newId, actions: newInteractionActions })
	})

	return { newInteractions: finalInteractions, copiedInteractionTempId }
}

const getReplyTextByNextInteractionId = (interactions: ChatbotInteraction[], nextInteractionId: string) => {
	// Find interaction that includes message action, that has a reply with specified interaction ID, providing the text for builder node
	const allReplies: ChatbotReply[] = []
	interactions.forEach((i) => {
		const messageActionIndex = findMessageActionIndex(i.actions)
		const messageAction = i.actions[messageActionIndex]
		if (messageAction && messageAction.type === ChatbotActionType.Message) {
			allReplies.push(...messageAction.data.replies)
		}
	})
	return (
		allReplies.find((r) => r.nextInteractionId === nextInteractionId)?.text ??
		T.translate('chatbotBuilder.nodes.welcomeMessage')
	)
}

export const copyChatbotInteraction = (
	chatbot: Chatbot,
	interactionId: string,
	parentInteractionId: string,
): ChatbotInteraction[] => {
	const newInteractionData = getCopyInteractionData(chatbot, interactionId)
	const interactionsMap = normalize('id', chatbot.interactions)

	if (!newInteractionData) return Object.values(interactionsMap)
	const { newInteractions, copiedInteractionTempId } = newInteractionData

	// Add interaction as a reply to the parent
	const parentInteraction = interactionsMap[parentInteractionId]
	const messageNodeIndex = findMessageActionIndex(parentInteraction.actions)
	const newInteractionMessageAction = parentInteraction.actions[messageNodeIndex]

	if (
		newInteractionMessageAction &&
		newInteractionMessageAction.type === ChatbotActionType.Message &&
		copiedInteractionTempId
	) {
		newInteractionMessageAction.data.replies.push({
			nextInteractionId: copiedInteractionTempId,
			type: 'text',
			text: getReplyTextByNextInteractionId(chatbot.interactions, interactionId),
		})
	}
	// Returns all interactions, including the new ones
	return [...Object.values(interactionsMap), ...newInteractions]
}

export const goBack = (chatbotGroupType: ChatbotGroupType | null) => {
	if (chatbotGroupType === ChatbotGroupType.Message) {
		navigateTo(routes.autoMessages.path)
	} else if (chatbotGroupType === ChatbotGroupType.Ai) {
		navigateTo(routes.aiChatbots.path)
	} else navigateTo(routes.chatbots.path)
}
