import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit'

import {
	PaymentGateway,
	SubscriptionCalculateRequest,
	SubscriptionCalculateResponse,
	SubscriptionCreateData,
	SubscriptionCreateRequest,
	SubscriptionResponse,
	SubscriptionResumeRequest,
	SubscriptionUpdateRequest,
} from 'models'
import { ApiError } from 'shared/types'
import { AppThunkAction, DashboardState } from 'types'
import { flashMessage } from 'shared/utils'
import { checkoutSelectors } from 'modules/checkout'
import { fetchPackage } from 'modules/package'
import { fetchPaymentMethod } from 'modules/paymentMethod'

import { subscriptionApi } from './api'

export type SubscriptionRootState = Pick<DashboardState, 'subscription'>

export const initialState = {
	isCreatePending: false,
	isUpdatePending: false,
	isPausePending: false,
	isResumePending: false,
	isCancelPending: false,
	isCancelDowngradePending: false,
	isCalculatePending: false,
	source: null as null | string,
}

// Create subscription - Credit card 3DS
export const createSubscriptionThreeDSecure = createAsyncThunk(
	'subscription/CREATE_CREDIT_CARD',
	(nonce3DS: string, { getState }) => {
		const state = getState() as DashboardState
		const packageConfiguration = checkoutSelectors.getCheckoutPackageConfiguration(state)
		const subscriptionSource = getSource(state)
		const data: SubscriptionCreateRequest = {
			paymentGateway: PaymentGateway.Braintree,
			nonce_3ds: nonce3DS,
			...packageConfiguration,
			...(subscriptionSource && { subscriptionSource }),
		}
		return subscriptionApi.createSubscription<SubscriptionResponse>(data)
	},
)

// Create subsription - PayPal + Credit card no3DS + Transfer
export const createSubscription = createAsyncThunk(
	'subscription/CREATE',
	({ nonce, paymentGateway }: SubscriptionCreateData, { getState }) => {
		const state = getState() as DashboardState
		const packageConfiguration = checkoutSelectors.getCheckoutPackageConfiguration(state)
		const subscriptionSource = getSource(state)
		const data: SubscriptionCreateRequest = {
			paymentGateway,
			...(nonce && { payment_method_nonce: nonce }),
			...packageConfiguration,
			...(subscriptionSource && { subscriptionSource }),
		}
		return subscriptionApi.createSubscription<SubscriptionResponse>(data)
	},
)

export const createSubscriptionFromTransfer = createAsyncThunk(
	'subscription/CREATE_FROM_TRANSFER',
	(data: SubscriptionCreateRequest) => {
		return subscriptionApi.createSubscription<SubscriptionResponse>(data)
	},
)

export const createShopifySubscription = createAsyncThunk('subscription/CREATE_SHOPIFY', (_, { getState }) => {
	const state = getState() as DashboardState
	const packageConfiguration = checkoutSelectors.getCheckoutPackageConfiguration(state)
	const subscriptionSource = getSource(state)
	const data: SubscriptionCreateRequest = {
		paymentGateway: PaymentGateway.Shopify,
		...packageConfiguration,
		...(subscriptionSource && { subscriptionSource }),
	}
	return subscriptionApi.createSubscription<SubscriptionResponse>(data)
})

// Update subscription - Credit card 3DS
export const updateSubscriptionThreeDSecure = createAsyncThunk(
	'subscription/UPDATE_CREDIT_CARD_3DS',
	(nonce3DS: string, { getState }) => {
		const state = getState() as DashboardState
		const packageConfiguration = checkoutSelectors.getCheckoutPackageConfiguration(state)
		const subscriptionSource = getSource(state)
		const data: SubscriptionUpdateRequest = {
			nonce_3ds: nonce3DS,
			...packageConfiguration,
			...(subscriptionSource && { subscriptionSource }),
		}
		return subscriptionApi.updateSubscription<SubscriptionResponse>(data)
	},
)

// Update subsription - PayPal + Credit card no3DS
export const updateSubscription = createAsyncThunk('subscription/UPDATE', (nonce: string | undefined, { getState }) => {
	const state = getState() as DashboardState
	const packageConfiguration = checkoutSelectors.getCheckoutPackageConfiguration(state)
	const subscriptionSource = getSource(state)
	const data: SubscriptionUpdateRequest = {
		payment_method_nonce: nonce,
		...packageConfiguration,
		...(subscriptionSource && { subscriptionSource }),
	}
	return subscriptionApi.updateSubscription<SubscriptionResponse>(data)
})

export const updateShopifySubscription = createAsyncThunk('subscription/UPDATE_SHOPIFY', (_, { getState }) => {
	const packageConfiguration = checkoutSelectors.getCheckoutPackageConfiguration(getState() as DashboardState)
	const data: SubscriptionUpdateRequest = { ...packageConfiguration }
	return subscriptionApi.updateSubscription<SubscriptionResponse>(data)
})

export const pauseSubscription = createAsyncThunk('subscription/PAUSE', () => {
	return subscriptionApi.pauseSubscription()
})

export const resumeSubscription = createAsyncThunk('subscription/RESUME', (data: SubscriptionResumeRequest = {}) => {
	return subscriptionApi.resumeSubscription(data)
})

export const cancelSubscription = createAsyncThunk('subscription/CANCEL', () => {
	return subscriptionApi.cancelSubscription()
})

export const calculateSubscription = createAsyncThunk<
	SubscriptionCalculateResponse,
	SubscriptionCalculateRequest,
	{ rejectValue: ApiError }
>('subscription/CALCULATE', async (data, { rejectWithValue }) => {
	try {
		return await subscriptionApi.calculateSubscription(data)
	} catch (error) {
		return rejectWithValue(error as ApiError)
	}
})

// only for fetching data (without store into state management)
export const calculateSubscriptionData = createAsyncThunk(
	'subscription/CALCULATE_DATA',
	(data: SubscriptionCalculateRequest) => {
		return subscriptionApi.calculateSubscription(data)
	},
)

export const onPauseSubscription = (): AppThunkAction => async (dispatch) => {
	const resultAction = await dispatch(pauseSubscription())
	if (pauseSubscription.fulfilled.match(resultAction)) {
		dispatch(fetchPackage())
	} else {
		flashMessage.error('general.error')
	}
}

export const onResumeSubscription =
	(data: SubscriptionResumeRequest = {}): AppThunkAction =>
	async (dispatch) => {
		const resultAction = await dispatch(resumeSubscription(data))
		if (resumeSubscription.fulfilled.match(resultAction)) {
			if (data.nonce_3ds) dispatch(fetchPaymentMethod())
			dispatch(fetchPackage())
		} else {
			flashMessage.error('general.error')
		}
	}

const slice = createSlice({
	name: 'subscription',
	initialState,
	reducers: {
		setSource: (state, { payload }: PayloadAction<string>) => {
			state.source = payload
		},
	},
	extraReducers: (builder) => {
		// Pause
		builder
			.addCase(pauseSubscription.pending, (state) => {
				state.isPausePending = true
			})
			.addCase(pauseSubscription.fulfilled, (state) => {
				state.isPausePending = false
			})
			.addCase(pauseSubscription.rejected, (state) => {
				state.isPausePending = false
			})

		// Resume
		builder
			.addCase(resumeSubscription.pending, (state) => {
				state.isResumePending = true
			})
			.addCase(resumeSubscription.fulfilled, (state) => {
				state.isResumePending = false
			})
			.addCase(resumeSubscription.rejected, (state) => {
				state.isResumePending = false
			})

		// Cancel
		builder
			.addCase(cancelSubscription.pending, (state) => {
				state.isCancelPending = true
			})
			.addCase(cancelSubscription.fulfilled, (state) => {
				state.isCancelPending = false
			})
			.addCase(cancelSubscription.rejected, (state) => {
				state.isCancelPending = false
			})

		// Calculate
		builder
			.addCase(calculateSubscription.pending, (state) => {
				state.isCalculatePending = true
			})
			.addCase(calculateSubscription.fulfilled, (state) => {
				state.isCalculatePending = false
			})
			.addCase(calculateSubscription.rejected, (state) => {
				state.isCalculatePending = false
			})

		// Create matchers
		builder
			.addMatcher(
				isPending(
					createSubscription,
					createSubscriptionThreeDSecure,
					createSubscriptionFromTransfer,
					createShopifySubscription,
				),
				(state) => {
					state.isCreatePending = true
				},
			)
			.addMatcher(
				isFulfilled(
					createSubscription,
					createSubscriptionFromTransfer,
					createSubscriptionThreeDSecure,
					createShopifySubscription,
				),
				(state) => {
					state.isCreatePending = false
				},
			)
			.addMatcher(
				isRejected(
					createSubscription,
					createSubscriptionThreeDSecure,
					createSubscriptionFromTransfer,
					createShopifySubscription,
				),
				(state) => {
					state.isCreatePending = false
				},
			)

		// Update matchers
		builder
			.addMatcher(isPending(updateSubscriptionThreeDSecure, updateSubscription, updateShopifySubscription), (state) => {
				state.isUpdatePending = true
			})
			.addMatcher(
				isFulfilled(updateSubscriptionThreeDSecure, updateSubscription, updateShopifySubscription),
				(state) => {
					state.isUpdatePending = false
				},
			)
			.addMatcher(
				isRejected(updateSubscriptionThreeDSecure, updateSubscription, updateShopifySubscription),
				(state) => {
					state.isUpdatePending = false
				},
			)
	},
})

const { reducer, actions } = slice
export const { setSource } = actions
export default reducer

const getIsCreatePending = (state: SubscriptionRootState) => state.subscription.isCreatePending
const getIsUpdatePending = (state: SubscriptionRootState) => state.subscription.isUpdatePending
const getIsPausePending = (state: SubscriptionRootState) => state.subscription.isPausePending
const getIsResumePending = (state: SubscriptionRootState) => state.subscription.isResumePending
const getIsCancelPending = (state: SubscriptionRootState) => state.subscription.isCancelPending
const getIsCalculatePending = (state: SubscriptionRootState) => state.subscription.isCalculatePending
const getIsCancelDowngradePending = (state: SubscriptionRootState) => state.subscription.isCancelDowngradePending
const getSource = (state: SubscriptionRootState) => state.subscription.source

export const subscriptionSelectors = {
	getIsCreatePending,
	getIsUpdatePending,
	getIsPausePending,
	getIsResumePending,
	getIsCancelPending,
	getIsCalculatePending,
	getIsCancelDowngradePending,
	getSource,
}
