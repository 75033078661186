import { matchPath, useLocation } from 'react-router-dom'

import { routes } from 'configuration/routes'

type ChatbotColorScheme = 'blue' | 'purple'

export const useAutomationColorScheme = (): ChatbotColorScheme => {
	const location = useLocation()
	const path = location.pathname

	const isAiChatbot = !!matchPath(path, {
		path: routes.aiAutomations.path,
	})

	return isAiChatbot ? 'purple' : 'blue'
}
