import { ReactNode } from 'react'

import { ListItem } from 'core'

type Props = {
	active: string
	children?: ReactNode
	onClick: (tabKey: string) => void
	tabKey: string
}

const Tab = ({ active, children, onClick, tabKey, ...rest }: Props) => {
	const isActive = active === tabKey

	const handleTabClick = () => {
		onClick(tabKey)
	}

	return (
		<ListItem
			onClick={handleTabClick}
			{...rest}
			display="flex"
			flex={1}
			alignItems="center"
			justifyContent="center"
			position="relative"
			fontSize={16}
			fontWeight={isActive ? 500 : 400}
			color={isActive ? 'gray.700' : 'gray.600'}
			py={0.5}
			px={1}
			cursor="pointer"
			_after={{
				transition: 'background-color 0.2s ease',
				content: '""',
				position: 'absolute',
				bottom: '-1px',
				left: '0px',
				right: '0px',
				margin: '0 auto',
				backgroundColor: isActive ? 'blue.500' : 'transparent',
				height: '3px',
				width: '80%',
			}}
			_hover={{
				'::after': {
					backgroundColor: 'blue.500',
				},
			}}
		>
			{children}
		</ListItem>
	)
}

export default Tab
