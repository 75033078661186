import { createBrowserHistory } from 'history'

import { ConfigurationService } from 'shared/services'

export type QueryParams = { modal?: string; from?: string; lang?: string }

export const getParams = (params?: QueryParams) => {
	const queryParams = new URLSearchParams()

	if (params) {
		Object.entries(params).forEach(([key, val]) => {
			queryParams.append(key, val)
		})
	}

	const queryParamsString = queryParams.toString()

	return queryParamsString.length > 0 ? `?${queryParams.toString()}` : ''
}

export const history = createBrowserHistory({ basename: ConfigurationService.getAppEnvData()?.baseName ?? '' })

export const navigateTo = (path: string, params?: QueryParams) => history.push(`${path}${getParams(params)}`)

export const redirectTo = (path: string, params?: QueryParams) => history.replace(`${path}${getParams(params)}`)

export const navigateBack = () => history.goBack()
