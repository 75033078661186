import { forwardRef, ReactNode, Ref } from 'react'

import { Box, BoxProps } from 'core'

type Props = {
	children: ReactNode
} & Omit<BoxProps, 'ref'>

type PropsFC = Props & { ref?: Ref<HTMLDivElement> }

export const FormControl = forwardRef<HTMLDivElement | null, PropsFC>(({ children, ...rest }, ref) => {
	return (
		<Box ref={ref} mb={3} {...rest}>
			{children}
		</Box>
	)
})

FormControl.displayName = 'FormControl'
