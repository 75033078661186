import { useDispatch, useSelector } from 'react-redux'
import {
	mdiAccountOutline,
	mdiBellOutline,
	mdiBrushOutline,
	mdiCashMultiple,
	mdiLightningBoltOutline,
	mdiLogout,
	mdiMessageArrowRightOutline,
} from '@mdi/js'

import { ConfigurationService, TranslationService as T } from 'shared/services'
import { routes } from 'configuration/routes'
import { logout } from 'utils'
import { accountSelectors } from 'modules/account'
import { affiliateSelectors } from 'modules/affiliate'
import { featuresSelectors, openFeaturePreviewModal } from 'modules/features'
import { userSelectors } from 'modules/user'

import { Link, PopoverMenu, PopoverMenuItem, PopoverMenuSection } from 'core'
import { SubmenuLinkContent } from 'components/layout/submenu/SubmenuLinkContent'
import RouterLink from 'components/router/RouterLink'

import AgentGroups from './SidebarAgentMenuGroups'
import AgentName from './SidebarAgentMenuName'
import PackageInfo from './SidebarAgentMenuPackageInfo'
import AgentStatus from './SidebarAgentMenuStatus'

const SidebarAgentMenu = () => {
	const dispatch = useDispatch()
	const user = useSelector(userSelectors.getActiveUser)
	const isRoot = useSelector(userSelectors.isUserRoot)
	const publicFeatureFlags = useSelector(featuresSelectors.getPublicFeatureFlags)
	const isAffilActive = useSelector(affiliateSelectors.getIsActive)
	const widgetPageUrl = useSelector(accountSelectors.getWidgetPageUrl)

	if (!user) return null

	const { stagingMode } = ConfigurationService.getData()
	const isEnvDevelopment = process.env.NODE_ENV === 'development'
	const shouldRenderStyleGuide = stagingMode || isEnvDevelopment
	const shouldRenderBetaFeatures = publicFeatureFlags.length > 0 && user.isOwner

	const handleLogout = (e: React.MouseEvent) => {
		e.preventDefault()
		logout()
	}

	const handleFeaturePreviewClick = (e: React.MouseEvent) => {
		e.preventDefault()
		dispatch(openFeaturePreviewModal())
	}

	return (
		<PopoverMenu>
			<PopoverMenuSection center>
				<AgentName />
				<AgentGroups />
			</PopoverMenuSection>
			{!isRoot && (
				<PopoverMenuSection noPadding>
					<AgentStatus />
				</PopoverMenuSection>
			)}
			<PopoverMenuSection noPadding>
				<PopoverMenuItem>
					<RouterLink to={routes.profile.path} data-testid="profile">
						<SubmenuLinkContent pl={0} icon={mdiAccountOutline}>
							{T.translate('sideBar.personal.profile')}
						</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem>
					<RouterLink to={routes.shortcuts.path} data-testid="shortcuts">
						<SubmenuLinkContent pl={0} icon={mdiLightningBoltOutline}>
							{T.translate('sideBar.personal.shortcuts')}
						</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem>
					<RouterLink to={routes.notifications.path} data-testid="notifications">
						<SubmenuLinkContent pl={0} icon={mdiBellOutline}>
							{T.translate('sideBar.personal.notifications')}
						</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
			</PopoverMenuSection>
			{user.isAdmin && (
				<PopoverMenuSection noPadding>
					<PackageInfo />
				</PopoverMenuSection>
			)}
			{shouldRenderBetaFeatures && (
				<PopoverMenuSection noPadding>
					<PopoverMenuItem>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<Link href="#" onClick={handleFeaturePreviewClick}>
							{T.translate('beta.features.title')}
						</Link>
					</PopoverMenuItem>
				</PopoverMenuSection>
			)}
			<PopoverMenuSection noPadding>
				{widgetPageUrl && (
					<PopoverMenuItem>
						<Link href={widgetPageUrl} external data-testid="widget-page">
							<SubmenuLinkContent pl={0} icon={mdiMessageArrowRightOutline}>
								{T.translate('chatbox.chatCode.chatPage.open')}
							</SubmenuLinkContent>
						</Link>
					</PopoverMenuItem>
				)}
				{shouldRenderStyleGuide && (
					<PopoverMenuItem>
						<RouterLink to={routes.styleGuide.path} target="_blank">
							<SubmenuLinkContent pl={0} icon={mdiBrushOutline}>
								Style guide
							</SubmenuLinkContent>
						</RouterLink>
					</PopoverMenuItem>
				)}
			</PopoverMenuSection>
			<PopoverMenuSection noBorder noPadding>
				{user.isAdmin && isAffilActive && (
					<PopoverMenuItem>
						<RouterLink to={routes.affiliateOverview.path} data-testid="affiliate-link">
							<SubmenuLinkContent pl={0} icon={mdiCashMultiple}>
								{T.translate('headerBar.affiliate')}
							</SubmenuLinkContent>
						</RouterLink>
					</PopoverMenuItem>
				)}
				<PopoverMenuItem>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<Link href="#" onClick={handleLogout} data-testid="logout">
						<SubmenuLinkContent pl={0} icon={mdiLogout}>
							{T.translate('headerBar.logout')}
						</SubmenuLinkContent>
					</Link>
				</PopoverMenuItem>
			</PopoverMenuSection>
		</PopoverMenu>
	)
}

export default SidebarAgentMenu
