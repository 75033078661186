import { ThemingProps } from '@chakra-ui/react'

import { Badge } from './badge'

export const Tag = {
	variants: {
		subtle: (props: ThemingProps) => ({
			container: Badge.variants.subtle(props),
		}),
	},
	sizes: {
		sm: {
			container: {
				borderRadius: 4,
				fontSize: 0,
				px: 1,
			},
		},
		md: {
			container: {
				borderRadius: 4,
				fontSize: 0,
				px: 1,
			},
		},
		lg: {
			container: {
				borderRadius: 4,
				fontSize: 1,
				px: 2,
			},
		},
	},
}
