import { ReactNode } from 'react'

import { SkeletonCircle } from 'core'
import IconHelpTooltip from 'shared/components/IconHelpTooltip'

type Props = {
	children: ReactNode
	isLoading?: boolean
	color?: string
}

export const StatTooltip = ({ children, isLoading, color = 'gray.500' }: Props) => {
	return (
		<>
			{isLoading ? (
				<SkeletonCircle size="18px" alignSelf="center" />
			) : (
				<IconHelpTooltip content={children} fontSize="18px" alignSelf="center" color={color} />
			)}
		</>
	)
}
