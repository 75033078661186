import { FeatureUsageInfo, FeatureUsageName } from 'models'

export const featureUsageNamesAccount = [
	FeatureUsageName.OfficeHoursEnabled,
	FeatureUsageName.ContactFormEnabled,
	FeatureUsageName.AutoMessageCreated,
	FeatureUsageName.ActivateChatbot,
]
export const featureUsageNamesUser: FeatureUsageName[] = [
	FeatureUsageName.AvatarUploaded,
	FeatureUsageName.ConversationsVisited,
	FeatureUsageName.AutoMessagesVisited,
	FeatureUsageName.ShortcutsPersonal,
	FeatureUsageName.ShortcutsTeam,
	FeatureUsageName.ConversationTagged,
	FeatureUsageName.VisitorListLastVisited,
]
export type FeatureUsageUser = {
	[FeatureUsageName.AvatarUploaded]: FeatureUsageInfo
	[FeatureUsageName.ConversationsVisited]: FeatureUsageInfo
	[FeatureUsageName.AutoMessagesVisited]: FeatureUsageInfo
	[FeatureUsageName.ShortcutsPersonal]: FeatureUsageInfo
	[FeatureUsageName.ShortcutsTeam]: FeatureUsageInfo
	[FeatureUsageName.ConversationTagged]: FeatureUsageInfo
	[FeatureUsageName.VisitorListLastVisited]: FeatureUsageInfo
}

export type FeatureUsageAccount = {
	[FeatureUsageName.OfficeHoursEnabled]: FeatureUsageInfo
	[FeatureUsageName.ActivateChatbot]: FeatureUsageInfo
	[FeatureUsageName.ContactFormEnabled]: FeatureUsageInfo
	[FeatureUsageName.AutoMessageCreated]: FeatureUsageInfo
}

export enum ProgressBarUserItem {
	ChatCodeInstalledFirstAt = 'installedFirstAt',
	MobileAppLastUsed = 'mobileAppLastUsed',
}

export type BasicProgressBarItemName =
	| typeof FeatureUsageName.AvatarUploaded
	| typeof FeatureUsageName.ConversationsVisited
	| typeof FeatureUsageName.AutoMessagesVisited
	| ProgressBarUserItem.ChatCodeInstalledFirstAt
	| ProgressBarUserItem.MobileAppLastUsed

export type SupportProgressBarItemName =
	| typeof FeatureUsageName.OfficeHoursEnabled
	| typeof FeatureUsageName.ShortcutsPersonal
	| typeof FeatureUsageName.ShortcutsTeam
	| typeof FeatureUsageName.ActivateChatbot
	| typeof FeatureUsageName.ConversationTagged
	| typeof FeatureUsageName.ContactFormEnabled

export type SalesProgressBarItemName =
	| typeof FeatureUsageName.AutoMessageCreated
	| typeof FeatureUsageName.VisitorListLastVisited
	| typeof FeatureUsageName.ActivateChatbot
	| typeof FeatureUsageName.ContactFormEnabled

export type ProgressBarItemName = BasicProgressBarItemName | SupportProgressBarItemName | SalesProgressBarItemName

export type ProgressBarItemsUsage = { name: ProgressBarItemName; isUsed: boolean }

export type ProgressBarCounts = { countTotal: number; countDone: number }
