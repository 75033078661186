import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { FeatureFlag } from 'models'

import { featuresSelectors } from './slice'

export const useFeatureFlag = (flag: FeatureFlag): boolean => {
	const getHasEnabledFeatureFlag = useMemo(() => featuresSelectors.makeHasEnabledFeatureFlag(flag), [flag])
	return useSelector(getHasEnabledFeatureFlag)
}
