import { createAsyncThunk } from '@reduxjs/toolkit'

import { ChatbotGroupType } from 'models'

import { automationsApi } from './api'

export const fetchAllBots = createAsyncThunk('chatbots/FETCH_ALL', () => {
	return automationsApi.getAllBotsList()
})

export const unpublishChatbots = createAsyncThunk('chatbots/UNPUBLISH', (groupType: ChatbotGroupType) => {
	return automationsApi.unpublishChatbots(groupType)
})
