import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Consent, ConsentsShowed, ConsentType, CreateConsentRequest } from 'models'
import { ApiError } from 'shared/types'
import { DashboardState } from 'types'

import { consentsApi } from './api'

export type ConsentsRootState = Pick<DashboardState, 'consents'>

type CreateConsentParams = {
	data: CreateConsentRequest
	showed?: boolean
}

const initialState = {
	isFetching: false,
	isCreating: false,
	userConsents: [] as Consent[],
	accountConsents: [] as Consent[],
	userShowedConsents: [] as ConsentsShowed,
	accountShowedConsents: [] as ConsentsShowed,
	isAccountShowed: false,
	isUserShowed: false,
}

export const fetchUserConsents = createAsyncThunk('consents/FETCH', () => {
	return consentsApi.fetchUserConsents()
})

export const createAccountConsent = createAsyncThunk<Consent, CreateConsentParams, { rejectValue: ApiError }>(
	'consents/ACCOUNT_CREATE',
	async ({ data, showed = false }, { rejectWithValue }) => {
		try {
			return { ...(await consentsApi.createAccountConsent(data)), showed }
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

export const createUserConsent = createAsyncThunk<Consent, CreateConsentParams, { rejectValue: ApiError }>(
	'consents/USER_CREATE',
	async ({ data, showed = false }, { rejectWithValue }) => {
		try {
			return { ...(await consentsApi.createUserConsent(data)), showed }
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

const slice = createSlice({
	name: 'consents',
	initialState,
	reducers: {
		initConsents: (
			state,
			{ payload }: PayloadAction<{ userConsents: ConsentsShowed; accountConsents: ConsentsShowed }>,
		) => {
			state.userShowedConsents = payload.userConsents
			state.accountShowedConsents = payload.accountConsents
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserConsents.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchUserConsents.rejected, (state) => {
				state.isFetching = false
			})
			.addCase(fetchUserConsents.fulfilled, (state, { payload }) => {
				state.userConsents = payload
				state.isFetching = false
			})
		builder
			.addCase(createAccountConsent.pending, (state) => {
				state.isCreating = true
			})
			.addCase(createAccountConsent.rejected, (state) => {
				state.isCreating = false
			})
			.addCase(createAccountConsent.fulfilled, (state, { payload, meta }) => {
				state.isCreating = false
				const response = payload
				const { showed } = meta.arg
				const index = state.accountConsents.findIndex((c) => c.type === response.type)
				if (index > -1) {
					state.accountConsents[index] = response
				} else {
					state.accountConsents.push(response)
				}
				if (showed) {
					state.accountShowedConsents.push(response.type)
				}
			})
		builder
			.addCase(createUserConsent.pending, (state) => {
				state.isCreating = true
			})
			.addCase(createUserConsent.rejected, (state) => {
				state.isCreating = false
			})
			.addCase(createUserConsent.fulfilled, (state, { payload, meta }) => {
				state.isCreating = false
				const response = payload
				const { showed } = meta.arg
				const index = state.userConsents.findIndex((c) => c.type === response.type)
				if (index > -1) {
					state.userConsents[index] = response
				} else {
					state.userConsents.push(response)
				}
				if (showed) {
					state.userShowedConsents.push(response.type)
				}
			})
	},
})

const { reducer, actions } = slice
export const { initConsents } = actions
export default reducer

const getUserConsents = (state: ConsentsRootState) => state.consents.userConsents
const getUserShowedConsents = (state: ConsentsRootState) => state.consents.userShowedConsents
const getAccountShowedConsents = (state: ConsentsRootState) => state.consents.accountShowedConsents
const isConsentFetching = (state: ConsentsRootState) => state.consents.isFetching
const isConsentCreating = (state: ConsentsRootState) => state.consents.isCreating

const getConsents = (isUserOwner: boolean) =>
	createSelector([getUserShowedConsents, getAccountShowedConsents], (userConsents, accountConsents) => {
		// Don't show consents for ROOT user
		if (!userConsents || !accountConsents) return null

		const userTermsConsent = userConsents.find((c) => c === ConsentType.Terms)
		const userMarketingConsent = userConsents.find((c) => c === ConsentType.Marketing)
		const accountTermsConsent = isUserOwner ? accountConsents.find((c) => c === ConsentType.Terms) : true

		if (!userTermsConsent || !userMarketingConsent || !accountTermsConsent) {
			return {
				hasUserTermsConsent: !!userTermsConsent,
				hasUserMarketingConsent: !!userMarketingConsent,
				hasAccountTermsConsent: !!accountTermsConsent,
			}
		}

		return null
	})

const hasUserMarketingConsent = createSelector([getUserConsents], (userConsents): boolean => {
	if (!userConsents) return false
	return userConsents.some((c) => c.type === ConsentType.Marketing && c.agreed)
})

export const consentsSelectors = {
	isConsentFetching,
	isConsentCreating,
	getConsents,
	hasUserMarketingConsent,
}
