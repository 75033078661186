import { ReactNode } from 'react'

import { Box } from 'shared/components/core/Box'
import { Flex } from 'shared/components/core/Flex'
import { Popover } from 'shared/components/core/Popover'

type Props = {
	popoverContent: ReactNode
	children: ReactNode
}

const SidebarMenuItemPopover = ({ popoverContent, children }: Props) => (
	<Popover content={popoverContent} placement="auto" offset={[0, 8]}>
		<Box as="button" w="full">
			<Flex
				transition="background .2s ease"
				alignItems="center"
				justifyContent="center"
				p="14px"
				_hover={{
					background: 'coal.800',
				}}
				mx="10px"
				borderRadius={2}
			>
				{children}
			</Flex>
		</Box>
	</Popover>
)

export default SidebarMenuItemPopover
