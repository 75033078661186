import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { AppThunkAction, DashboardState } from 'types'
import { PackageLogEventRequest } from 'shared/models/Package'
import { PaymentGateway, PaymentMethod, PaymentMethodUpdateRequest, PaymentType } from 'shared/models/Payment'
import { logPackageEvent, packageSelectors } from 'modules/package'

import { paymentMethodApi } from './api'
import { CardExpirationNotifications } from './types'

export type PaymentMethodRootState = Pick<DashboardState, 'paymentMethod'>
export type PaymentMethodState = typeof initialState

export const initialState = {
	isFetchPending: false,
	isFetchTokenPending: false,
	isCreatePending: false,
	isUpdatePending: false,
	paymentMethod: null as null | PaymentMethod,
	paymentMethodNonce3DS: null as null | string,
	enable3ds: true,
}

export const fetchPaymentMethod = createAsyncThunk('paymentMethod/FETCH', async () => {
	return paymentMethodApi.fetchPaymentMethod()
})

export const fetchPaymentOptions = createAsyncThunk('paymentOptions/FETCH', () => {
	return paymentMethodApi.fetchPaymentOptions()
})

export const postPaymentError = createAsyncThunk('paymentMethodError/POST', (data: PackageLogEventRequest['data']) => {
	return paymentMethodApi.postPaymentError(data)
})

export const updatePaymentMethod = createAsyncThunk('paymentMethod/UPDATE', async (nonce?: string) => {
	const data: PaymentMethodUpdateRequest = {}
	if (nonce) {
		data.payment_method_nonce = nonce
	}
	return paymentMethodApi.updatePaymentMethod(data)
})

export const deletePaymentMethod = createAsyncThunk('paymentMethod/DELETE', async () => {
	return paymentMethodApi.deletePaymentMethod()
})

export const fetchPaymentMethodToken = createAsyncThunk('paymentMethod/FETCH_TOKEN', async () => {
	return paymentMethodApi.fetchPaymentMethodToken()
})

export const createThreeDSecurePaymentMethod = createAsyncThunk('paymentMethod/CREATE_3DS', async (nonce?: string) => {
	const data: PaymentMethodUpdateRequest = {}
	if (nonce) {
		data.payment_method_nonce = nonce
	}
	return paymentMethodApi.createThreeDSecurePaymentMethod(data)
})

export const logPaymentMethodEvent =
	(logData: PackageLogEventRequest): AppThunkAction =>
	(dispatch) => {
		dispatch(logPackageEvent(logData))
	}

const slice = createSlice({
	name: 'paymentMethod',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// Fetch payment method
		builder
			.addCase(fetchPaymentMethod.pending, (state) => {
				state.isFetchPending = true
			})
			.addCase(fetchPaymentMethod.fulfilled, (state, { payload }) => {
				state.isFetchPending = false
				state.paymentMethod = payload.paymentMethod
			})
			.addCase(fetchPaymentMethod.rejected, (state) => {
				state.isFetchPending = false
			})

		// Update payment method
		builder
			.addCase(updatePaymentMethod.pending, (state) => {
				state.isUpdatePending = true
			})
			.addCase(updatePaymentMethod.fulfilled, (state, { payload }) => {
				state.isUpdatePending = false
				state.paymentMethod = payload.paymentMethod
			})
			.addCase(updatePaymentMethod.rejected, (state) => {
				state.isUpdatePending = false
			})

		// Fetch payment method token
		builder
			.addCase(fetchPaymentMethodToken.pending, (state) => {
				state.isFetchTokenPending = true
			})
			.addCase(fetchPaymentMethodToken.fulfilled, (state) => {
				state.isFetchTokenPending = false
			})
			.addCase(fetchPaymentMethodToken.rejected, (state) => {
				state.isFetchTokenPending = false
			})

		// Create 3DS payment method
		builder
			.addCase(createThreeDSecurePaymentMethod.pending, (state) => {
				state.isCreatePending = true
			})
			.addCase(createThreeDSecurePaymentMethod.fulfilled, (state, { payload }) => {
				state.isCreatePending = false
				state.paymentMethodNonce3DS = payload.paymentMethod.nonce
			})
			.addCase(createThreeDSecurePaymentMethod.rejected, (state) => {
				state.isCreatePending = false
			})

		// Fetch payment method option
		builder.addCase(fetchPaymentOptions.fulfilled, (state, { payload }) => {
			state.enable3ds = payload.options?.enable3ds ?? true
		})
	},
})

const { reducer } = slice
export default reducer

const getPaymentMethod = (state: PaymentMethodRootState) => state.paymentMethod.paymentMethod
const getPaymentMethodNonce3DS = (state: PaymentMethodRootState) => state.paymentMethod.paymentMethodNonce3DS
const getIsFetchPending = (state: PaymentMethodRootState) => state.paymentMethod.isFetchPending
const getIsCreatePending = (state: PaymentMethodRootState) => state.paymentMethod.isCreatePending
const getIsUpdatePending = (state: PaymentMethodRootState) => state.paymentMethod.isUpdatePending
const getIsThreeDSecureEnabled = (state: PaymentMethodRootState) => state.paymentMethod.enable3ds

const getIsGatewayBraintree = createSelector([getPaymentMethod], (paymentMethod): boolean => {
	if (!paymentMethod) return false
	return paymentMethod.gateway === PaymentGateway.Braintree
})

const getIsPaymentMethodTransfer = createSelector(
	[getPaymentMethod, packageSelectors.getPackageInfo],
	(paymentMethod, packageInfo): boolean => {
		if (!paymentMethod || !packageInfo) return false
		const { type } = paymentMethod
		return packageInfo.hasTransferGateway && type === PaymentType.Transfer
	},
)

const getIsPaymentMethodCreditCard = createSelector(
	[getPaymentMethod, getIsGatewayBraintree],
	(paymentMethod, isGatewayBraintree): boolean => {
		if (!paymentMethod) return false
		const { type } = paymentMethod
		return isGatewayBraintree && type === PaymentType.CreditCard
	},
)

const getHasPaymentMethod = createSelector([getPaymentMethod], (paymentMethod): boolean => {
	if (!paymentMethod) return false
	return !!paymentMethod.type
})

const getHasBraintreePaymentMethod = createSelector(
	[getHasPaymentMethod, getIsGatewayBraintree],
	(hasPaymentMethod, isGatewayBraintree): boolean => {
		return hasPaymentMethod && isGatewayBraintree
	},
)

const getIsCreditCardExpired = createSelector([getPaymentMethod], (paymentMethod): boolean => {
	return paymentMethod?.data?.type === PaymentType.CreditCard && paymentMethod?.data?.expired
})

const getIsCreditCardExpiring = createSelector([getPaymentMethod], (paymentMethod): boolean => {
	if (paymentMethod?.data?.type !== PaymentType.CreditCard) return false
	if (paymentMethod.data.expired) return false
	return paymentMethod.data.daysToExpiration <= 30
})

const getCardExpiryNotifications = createSelector(
	[getIsCreditCardExpired, getIsCreditCardExpiring, packageSelectors.getPackageInfo],
	(isCreditCardExpired, isCreditCardExpiring, packageInfo): CardExpirationNotifications => {
		if (!packageInfo) return { shouldShowExpiredWarning: false, shouldShowExpiringWarning: false }
		const shouldShowExpiredWarning = isCreditCardExpired && packageInfo.remainingDays <= 30
		const shouldShowExpiringWarning = isCreditCardExpiring && packageInfo.remainingDays <= 30

		return { shouldShowExpiredWarning, shouldShowExpiringWarning }
	},
)

export const paymentMethodSelectors = {
	getPaymentMethod,
	getPaymentMethodNonce3DS,
	getIsFetchPending,
	getIsCreatePending,
	getIsUpdatePending,
	getIsPaymentMethodTransfer,
	getIsGatewayBraintree,
	getIsPaymentMethodCreditCard,
	getHasPaymentMethod,
	getHasBraintreePaymentMethod,
	getIsCreditCardExpired,
	getCardExpiryNotifications,
	getIsThreeDSecureEnabled,
}
