import { Account, AccountApi, ReviewsAccount, ShopifyActivationUrl, Timezone } from 'shared/models/Account'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'account'
const API_RESOURCE_TIMEZONES = 'timezones'
const API_RESOURCE_SHOPIFY = 'shopify'
const API_RESOURCE_REVIEW = 'reviews/account'

const updateAccount = (data: AccountApi.UpdateBody) => {
	return apiClient.patch<Account>(`${API_RESOURCE}`, data)
}

const fetchAccount = (): Promise<Account> => {
	return apiClient.get<Account>(`${API_RESOURCE}`)
}

const fetchAccountReviewInfo = () => {
	return apiClient.get<ReviewsAccount>(`${API_RESOURCE_REVIEW}`)
}

const deleteAccount = (data: AccountApi.DeleteBody) => {
	return apiClient.post(`${API_RESOURCE}/delete`, data)
}

const fetchTimezones = (): Promise<Timezone[]> => {
	return apiClient.get<Timezone[]>(`${API_RESOURCE_TIMEZONES}`)
}

const fetchShopifyActivationUrl = () => {
	return apiClient.get<ShopifyActivationUrl>(`${API_RESOURCE_SHOPIFY}/onboarding`)
}

export const accountApi = {
	updateAccount,
	fetchAccount,
	fetchTimezones,
	deleteAccount,
	fetchShopifyActivationUrl,
	fetchAccountReviewInfo,
}
