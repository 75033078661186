import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { OfficeHours, OfficeHoursDayName } from 'models'
import { DashboardState } from 'types'
import { getConvertedOfficeHoursDay, getDefaultOfficeHoursDayEnabled, officeHoursApi } from 'modules/officeHours'

export type OfficeHoursRootState = Pick<DashboardState, 'officeHours'>

export const initialState = {
	officeHours: null as null | OfficeHours,
	isActive: false,
	isFetching: false,
	isUpdating: false,
	error: false,
	isFormDirty: false,
	isFetchError: false,
}

export const fetchOfficeHours = createAsyncThunk('officeHours/FETCH', () => {
	return officeHoursApi.fetchOfficeHours()
})

export const updateOfficeHours = createAsyncThunk('officeHours/PATCH', (data: Partial<OfficeHours>) => {
	return officeHoursApi.updateOfficeHours(data)
})

const officeHoursSlice = createSlice({
	name: 'officeHours',
	initialState,
	reducers: {
		setError: (state, { payload }: PayloadAction<{ error: boolean }>) => {
			const { error } = payload
			state.error = error
		},

		setIsActive: (state, { payload }: PayloadAction<{ isActive: boolean }>) => {
			const { isActive } = payload
			state.isActive = isActive
		},

		setIsFormDirty: (state, { payload }: PayloadAction<{ isFormDirty: boolean }>) => {
			const { isFormDirty } = payload
			state.isFormDirty = isFormDirty
		},
	},
	extraReducers: (builder) => {
		// FETCH
		builder
			.addCase(fetchOfficeHours.pending, (state) => {
				state.isFetching = true
				state.isFetchError = false
			})
			.addCase(fetchOfficeHours.fulfilled, (state, { payload }) => {
				state.isFetching = false
				state.officeHours = payload
				state.isActive = payload.isActive
				state.isFetchError = false
			})
			.addCase(fetchOfficeHours.rejected, (state) => {
				state.isFetching = false
				state.isFetchError = true
			})
		builder
			.addCase(updateOfficeHours.pending, (state) => {
				state.isUpdating = true
			})
			.addCase(updateOfficeHours.fulfilled, (state, { payload }) => {
				state.isUpdating = false
				state.officeHours = payload
			})
			.addCase(updateOfficeHours.rejected, (state) => {
				state.isUpdating = false
			})
	},
})

const { reducer, actions } = officeHoursSlice
export const { setError, setIsActive, setIsFormDirty } = actions
export default reducer

const getOfficeHours = (state: OfficeHoursRootState) => state.officeHours.officeHours
const getOfficeHoursIsActive = (state: OfficeHoursRootState) => state.officeHours.isActive
const getIsUpdating = (state: OfficeHoursRootState) => state.officeHours.isUpdating
const getIsFetching = (state: OfficeHoursRootState) => state.officeHours.isFetching
const getError = (state: OfficeHoursRootState) => state.officeHours.error
const getIsFormDirty = (state: OfficeHoursRootState) => state.officeHours.isFormDirty
const getIsFetchError = (state: OfficeHoursRootState) => state.officeHours.isFetchError

const getConvertedDayData = createSelector([getOfficeHours], (officeHours) => {
	const officeHoursConfig = officeHours?.config
	return {
		monday: getConvertedOfficeHoursDay(officeHoursConfig?.monday),
		tuesday: getConvertedOfficeHoursDay(officeHoursConfig?.tuesday),
		wednesday: getConvertedOfficeHoursDay(officeHoursConfig?.wednesday),
		thursday: getConvertedOfficeHoursDay(officeHoursConfig?.thursday),
		friday: getConvertedOfficeHoursDay(officeHoursConfig?.friday),
		saturday: getConvertedOfficeHoursDay(officeHoursConfig?.saturday),
		sunday: getConvertedOfficeHoursDay(officeHoursConfig?.sunday),
	}
})

const makeGetIsActiveByDay = (day: OfficeHoursDayName) => {
	return createSelector([getOfficeHours], (officeHours) => {
		return getDefaultOfficeHoursDayEnabled(officeHours?.config[day])
	})
}

export const officeHoursSelectors = {
	getOfficeHours,
	getOfficeHoursIsActive,
	getIsUpdating,
	getError,
	getIsFetching,
	getIsFetchError,
	getIsFormDirty,
	makeGetIsActiveByDay,
	getConvertedDayData,
}
