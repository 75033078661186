import { useSelector } from 'react-redux'
import { mdiInformationOutline, mdiShoppingOutline } from '@mdi/js'

import { TranslationService as T } from 'shared/services'
import { agentsSelectors } from 'modules/agents'
import { packageSelectors } from 'modules/package'
import { paymentMethodSelectors } from 'modules/paymentMethod'
import { shortcutsSelectors } from 'modules/shortcuts'

import { Box, Flex, HStack, Icon, Text, Tooltip } from 'core'
import { BillingLink, BillingLinkType } from 'components/billing'

import PackageInfoExpiration from './SidebarAgentMenuPackageInfoExpiration'

const SidebarAgentMenuPackageInfo = () => {
	const activeAgentsCount = useSelector(agentsSelectors.getActiveAgentsCount)
	const packageInfo = useSelector(packageSelectors.getPackageInfo)
	const shortcutsCount = useSelector(shortcutsSelectors.getActiveShortcutsCount)
	const hasBraintreePaymentMethod = useSelector(paymentMethodSelectors.getHasBraintreePaymentMethod)

	if (!packageInfo) return null

	const {
		agents,
		autoRenewal,
		formattedName,
		userShortcuts,
		isRemainingDaysCountLow,
		isPro,
		isTrial,
		isExpired,
		hasTransferGateway,
		chatHistory,
	} = packageInfo

	const tooltipContent = (
		<>
			<Flex justifyContent="space-between">
				<Box>{T.translate('sideBar.agents')}</Box>
				<Box ml={2}>
					{activeAgentsCount}/{agents ?? '∞'}
				</Box>
			</Flex>
			{chatHistory && (
				<Flex justifyContent="space-between">
					<Box>{T.translate('generalTab.general.chatHistory')}</Box>
					<Box ml={2}>{T.translate(`generalTab.general.chatHistory.${chatHistory}`)}</Box>
				</Flex>
			)}
			<Flex justifyContent="space-between">
				<Box>{T.translate('chat.shortcuts.title')}</Box>
				<Box ml={2}>
					{shortcutsCount}/{userShortcuts ?? '∞'}
				</Box>
			</Flex>
		</>
	)

	let actionLink
	const shouldRenderRenewBtn = !autoRenewal && !isTrial && (isRemainingDaysCountLow || isExpired)
	const shouldRenderUpgradeBtn = !isPro
	if (shouldRenderRenewBtn) {
		const isTransferExpiring = isRemainingDaysCountLow && hasTransferGateway
		const isBraintreeExpiring = isRemainingDaysCountLow && hasBraintreePaymentMethod

		let linkTo: BillingLinkType = 'checkout'
		if (isTransferExpiring) {
			linkTo = 'history'
		} else if (isBraintreeExpiring) {
			linkTo = 'profileRenewal'
		}

		actionLink = <BillingLink to={linkTo}>{T.translate('billing.profile.package.renew')}</BillingLink>
	} else if (shouldRenderUpgradeBtn) {
		actionLink = <BillingLink to="packages">{T.translate('billing.profile.package.viewAll.button')}</BillingLink>
	}

	return (
		<Flex alignItems="center" py={1.5} pl={1.5} data-testid="package-info">
			<Icon mr={1} fontSize={2} path={mdiShoppingOutline} />
			<Box>
				<HStack fontSize={0} spacing="52px">
					<Box>
						<Text>{T.translate('package.package')}</Text>
					</Box>
					<Box>{actionLink}</Box>
				</HStack>
				<Flex alignItems="center">
					<Text fontSize={2} fontWeight="bold" textTransform="uppercase">
						{formattedName}
					</Text>
					<Tooltip content={tooltipContent}>
						<Icon path={mdiInformationOutline} ml={1} textColor="gray.500" />
					</Tooltip>
				</Flex>
				<PackageInfoExpiration />
			</Box>
		</Flex>
	)
}

export default SidebarAgentMenuPackageInfo
