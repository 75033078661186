import { Tag, TagCreateBody, TagUpdateBody } from 'models/Tag'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'tags/v1'

const fetchTags = () => {
	return apiClient.get<Tag[]>(`${API_RESOURCE}/tags`)
}

const createTag = (data: TagCreateBody) => {
	return apiClient.post<Tag>(`${API_RESOURCE}/tags`, data)
}

const updateTag = (id: number, changes: TagUpdateBody) => {
	return apiClient.patch<Tag>(`${API_RESOURCE}/tags/${id}`, changes)
}

const deleteTag = (id: number) => {
	return apiClient.delete(`${API_RESOURCE}/tags/${id}`)
}

export const tagsApi = { deleteTag, fetchTags, createTag, updateTag }
