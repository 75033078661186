export { Account, AccountApi, ReviewsAccount, Timezone } from '@smartsupp/core-client-api'

export enum ChatHistory {
	Days14 = '14days',
	Months12 = '12months',
	Months36 = '36months',
	Unlimited = 'unlimited',
}

export type ShopifyActivationUrl = {
	widgetActivationUrl: string | null
}
