import { AiAssistAction, AiAssistTransformRequest, AiAssistTransformResponse } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'ai-assist/actions'
const API_RESOURCE_TRANSFORM = 'ai-assist/transform'

const fetchAiAssistActions = () => {
	return apiClient.get<AiAssistAction[]>(API_RESOURCE)
}

const transformText = (body: AiAssistTransformRequest) => {
	return apiClient.post<AiAssistTransformResponse>(API_RESOURCE_TRANSFORM, body)
}

export const aiAssistApi = { fetchAiAssistActions, transformText }
