import moment from 'moment'

import { UserPreference } from 'shared/constants'
import { loadUserPreference } from 'shared/services'
import { isNumber } from 'shared/utils'

import { LocalStorageItem, ProductNotificationType } from './types'

export const getNewLocalStorageData = (
	type: ProductNotificationType,
	data: LocalStorageItem[],
	limit?: number,
): LocalStorageItem[] => {
	const filteredData: LocalStorageItem[] = Array.isArray(data)
		? data.filter((item: LocalStorageItem) => item.type !== type)
		: []

	return [...filteredData, { type, time: moment().format('YYYY-MM-DD'), limit }]
}

export const isNotificationAllowed = (type: ProductNotificationType, limit: number | null) => {
	const localStorageData = loadUserPreference(UserPreference.ProductNotifications, true)
	const productNotifications: LocalStorageItem[] = Array.isArray(localStorageData) ? localStorageData : []
	const userPreferenceItem = productNotifications.find((item: LocalStorageItem) => item.type === type)

	const lastTimeShowed = userPreferenceItem?.time ?? null
	const isSameMonth = moment(lastTimeShowed).isSame(moment(), 'month')

	const hasLimitChanges =
		userPreferenceItem && userPreferenceItem.limit && isNumber(limit) ? userPreferenceItem.limit !== limit : true

	return !isSameMonth || hasLimitChanges
}
