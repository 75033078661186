import { App, AppsApi, IntegrationRequest } from 'shared/models/Integrations'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'apps/store/apps'
const API_RESOURCE_FAKE_DOOR = 'fake-door'

const fetchIntegrations = ({ accountId }: Pick<AppsApi.ListQuery, 'accountId'>) => {
	return apiClient.get<App[]>(`${API_RESOURCE}?accountId=${accountId}&installed=true`)
}

const sendIntegration = (data: IntegrationRequest) => {
	return apiClient.post<IntegrationRequest>(API_RESOURCE_FAKE_DOOR, data)
}

export const integrationsApi = {
	fetchIntegrations,
	sendIntegration,
}
