import { ThemingProps } from '@chakra-ui/react'

const sizes = {
	sm: '10px',
	md: '20px',
}

const baseStyle = {
	px: 1,
	borderRadius: '10px',
	fontWeight: 'bold',
	minWidth: sizes.md,
	minHeight: sizes.md,
	lineHeight: sizes.md,
}

const variantSubtle = ({ colorScheme: c }: ThemingProps) => {
	return {
		bg: `${c}.200`,
		color: `${c}.600`,
	}
}

export const Badge = {
	baseStyle,
	variants: {
		subtle: variantSubtle,
	},
	sizes: {
		sm: {
			px: 0.5,
			py: '2px',
			fontWeight: 'normal',
			minWidth: '16px',
			minHeight: sizes.sm,
			lineHeight: sizes.sm,
		},
	},
}
