import { forwardRef, Ref } from 'react'
import type { ModalContentProps } from '@chakra-ui/react'
import { ModalContent } from '@chakra-ui/react'

export type { ModalProps as CModalProps } from '@chakra-ui/react'
export {
	Modal as CModal,
	ModalBody as CModalBody,
	ModalCloseButton as CModalCloseButton,
	ModalFooter as CModalFooter,
	ModalHeader as CModalHeader,
	ModalOverlay as CModalOverlay,
} from '@chakra-ui/react'

type PropsFC = ModalContentProps & { ref?: Ref<HTMLDivElement> }

// Handles blur event, necessary for compatibility with the widget in the dashboard
export const CModalContent = forwardRef<HTMLDivElement, PropsFC>((props, ref) => {
	return <ModalContent ref={ref} {...props} onBlur={(e) => e.stopPropagation()} />
})

CModalContent.displayName = 'CModalContent'
