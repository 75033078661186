import { ReactNode } from 'react'
import { Popover as CPopover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/popover'
import { Tooltip as CTooltip, TooltipProps as CTooltipProps } from '@chakra-ui/tooltip'

export type TooltipProps = Omit<CTooltipProps, 'label' | 'content'> & {
	interactive?: boolean
	content?: ReactNode
	disabled?: boolean
	arrow?: boolean
}

const DEFAULT_OPEN_DELAY = 0
const DEFAULT_CLOSE_DELAY_INTERACTIVE = 100

export const Tooltip = ({
	interactive = false,
	arrow = true,
	children,
	content,
	disabled = false,
	placement = 'top',
	openDelay = DEFAULT_OPEN_DELAY,
	closeDelay,
	...rest
}: TooltipProps) => {
	if (!content || disabled) return <>{children}</>

	if (interactive) {
		return (
			<CPopover
				trigger="hover"
				openDelay={openDelay}
				closeDelay={closeDelay || DEFAULT_CLOSE_DELAY_INTERACTIVE}
				placement={placement}
				{...rest}
			>
				<PopoverTrigger>{children}</PopoverTrigger>
				<PopoverContent width="auto" maxW={{ base: 320, md: 360 }} borderRadius={1}>
					{arrow && <PopoverArrow />}
					<PopoverBody fontSize="sm" py={0.5} px={2}>
						{content}
					</PopoverBody>
				</PopoverContent>
			</CPopover>
		)
	}

	return (
		<CTooltip
			label={content}
			hasArrow={arrow}
			openDelay={openDelay}
			closeDelay={closeDelay}
			placement={placement}
			arrowSize={6}
			{...rest}
		>
			{children}
		</CTooltip>
	)
}
