import { AiChatbotStats, AiChatbotStatsSummary, ChatbotStatisticsDataType } from 'shared/models/ChatbotStatistics'
import { DEFAULT_STATISTICS_ARRAY, TransformedChatbotStatistic } from 'modules/chatbotStatistics'

export const transformAiChatbotStatistics = (
	stats: AiChatbotStatsSummary | AiChatbotStats,
): TransformedChatbotStatistic[] => {
	if (!stats) return DEFAULT_STATISTICS_ARRAY

	const chatbotConversations = stats.conversations.value
	const chatbotResolved = stats.resolved.value
	const chatbotEscalated = stats.handover.value
	const chatbotResolvedPercentage = `${stats.resolved.percentage}%`
	const chatbotEscalatedPercentage = `${stats.handover.percentage}%`
	const chatbotConversationsTrend = stats.conversations.trendPercentage
	const chatbotResolvedTrend = stats.resolved.trendPercentage
	const chatbotEscalatedTrend = stats.handover.trendPercentage

	return [
		{
			name: ChatbotStatisticsDataType.ChatbotConversations,
			value: chatbotConversations,
			trendPercentage: chatbotConversationsTrend,
		},
		{
			name: ChatbotStatisticsDataType.Resolved,
			value: chatbotResolved,
			percentage: chatbotResolvedPercentage,
			trendPercentage: chatbotResolvedTrend,
		},
		{
			name: ChatbotStatisticsDataType.Escalated,
			value: chatbotEscalated,
			percentage: chatbotEscalatedPercentage,
			trendPercentage: chatbotEscalatedTrend,
		},
	]
}
