export const LIMIT = 20

export const affiliateTestingAttributes = {
	activation: {
		consentCheckbox: 'affiliate-activation-checkbox',
		confirmButton: 'affiliate-activation-button',
		empty: 'affiliate-activation-empty',
	},
	overview: {
		clicksCount: 'affiliate-overview-clicksCount',
		link: 'affiliate-overview-link',
		currentReward: 'affiliate-overview-currentReward',
		waitingReward: 'affiliate-overview-waitingReward',
		totalReward: 'affiliate-overview-totalReward',
		minimalAmount: 'affiliate-overview-minimalAmount',
		payoutButton: 'affiliate-overview-payout',
	},
	clients: {
		clientsTotal: 'affiliate-clients-total',
		zeroClients: 'affiliate-clients-noResults',
		clientsTable: 'affiliate-clients-table',
		clientsTableRow: 'affiliate-clients-row',
	},
	rewards: {
		rewardsTotal: 'affiliate-rewards-total',
		zeroRewards: 'affiliate-rewards-noRewards',
		rewardsTable: 'affiliate-rewards-table',
		rewardsTableRow: 'affiliate-rewards-row',
	},
}
