import { AiChatbotApi } from 'shared/models/ChatbotStatistics'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'analytics-api/v1/ai-chatbot/stats'

const getStatistics = ({ range, fields }: AiChatbotApi.StatsQuery) => {
	const fieldsQuery = fields && fields.length > 0 ? `&fields=${fields.join(',')}` : ''
	return apiClient.get<AiChatbotApi.StatsResponse>(`${API_RESOURCE}?range=${range}${fieldsQuery}`)
}

export const aiChatbotStatsApi = { getStatistics }
