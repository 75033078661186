import { Property, PropertyCreateBody, PropertyUpdate } from 'shared/models/Property'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'properties'

const fetchProperties = () => {
	return apiClient.get<Property[]>(`${API_RESOURCE}`)
}

const createProperty = (data: PropertyCreateBody) => {
	return apiClient.post<Property>(`${API_RESOURCE}`, data)
}

const updateProperty = (id: string, data: PropertyUpdate) => {
	return apiClient.patch<Property>(`${API_RESOURCE}/${id}`, data)
}

const deleteProperty = (id: string) => {
	return apiClient.delete(`${API_RESOURCE}/${id}`)
}

export const propertiesApi = { fetchProperties, createProperty, updateProperty, deleteProperty }
