import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Account, AccountApi, Timezone } from 'models'
import { ReviewsAccount } from 'models/Account'
import { AppConfigData } from 'shared/types'
import { AppThunkAction, DashboardState } from 'types'
import { AgentEvents } from 'shared/models/Agent'
import { fetchInitData } from 'modules/app'

import { accountApi } from './api'

export type AccountRootState = Pick<DashboardState, 'account'>

const initialState = {
	timezone: null as null | Account['timezone'],
	timezones: [] as Timezone[],
	accountId: null as null | number,
	sourcePlatform: null as null | Account['sourcePlatform'],
	isBlocked: false,
	blockReason: null as AppConfigData['blockReason'],
	anonymizeIp: false,
	isPendingUpdate: false,
	allowedDomains: [] as string[],
	mainDomain: '' as string | null,
	installedDomain: null as null | string,
	isDeletingAccount: false,
	isGeneralFormDirty: false,
	isSecurityFormDirty: false,
	isGoogleAnalyticsFormDirty: false,
	shopifyActivationUrl: null as null | string,
	errors: {
		isErrorAccount: false,
		isErrorTimezones: false,
	},
	chatCodeInstalled: false,
	installedFirstAt: null as null | string,
	widgetPageUrl: null as null | string,
	accountReviewConfig: null as null | ReviewsAccount,
}

export const fetchAccount = createAsyncThunk('account/FETCH', () => {
	return accountApi.fetchAccount()
})

export const fetchAccountReviewInfo = createAsyncThunk('account/FETCH_ACCOUNT_REVIEW', () => {
	return accountApi.fetchAccountReviewInfo()
})

export const patchAccount = createAsyncThunk('account/PATCH', (values: AccountApi.UpdateBody) => {
	return accountApi.updateAccount(values)
})

export const deleteAccount = createAsyncThunk('account/DELETE', (data: AccountApi.DeleteBody) => {
	return accountApi.deleteAccount(data)
})

export const fetchTimezones = createAsyncThunk('timezone/FETCH', () => {
	return accountApi.fetchTimezones()
})

export const fetchShopifyActivationUrl = createAsyncThunk('shopify/FETCH_ACTIVATION_URL', () => {
	return accountApi.fetchShopifyActivationUrl()
})

export const onChangeIsBlockedStatus =
	(data: AgentEvents.AccountBlockStatusChanged): AppThunkAction =>
	(dispatch) => {
		dispatch(setIsBlocked(data))
	}

const slice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		setIsFormDirty: (state, { payload }: PayloadAction<{ isFormDirty: boolean }>) => {
			const { isFormDirty } = payload
			state.isGeneralFormDirty = isFormDirty
		},
		setIsBlocked: (state, { payload }: PayloadAction<AgentEvents.AccountBlockStatusChanged>) => {
			const { isBlocked, blockReason } = payload
			state.isBlocked = isBlocked
			state.blockReason = blockReason as AppConfigData['blockReason']
		},
		setIsSecurityFormDirty: (state, { payload }: PayloadAction<{ isFormDirty: boolean }>) => {
			const { isFormDirty } = payload
			state.isSecurityFormDirty = isFormDirty
		},
		setIsGoogleAnalyticsFormDirty: (state, { payload }: PayloadAction<{ isFormDirty: boolean }>) => {
			const { isFormDirty } = payload
			state.isGoogleAnalyticsFormDirty = isFormDirty
		},
	},
	extraReducers: (builder) => {
		// FETCH
		builder.addCase(fetchInitData.fulfilled, (state, { payload }) => {
			state.accountId = payload.account.id
			state.isBlocked = payload.isBlocked
			state.blockReason = payload.blockReason
		})

		builder.addCase(fetchAccount.fulfilled, (state, { payload }) => {
			state.sourcePlatform = payload.sourcePlatform
			state.timezone = payload.timezone
			state.anonymizeIp = payload.anonymizeIp
			state.allowedDomains = payload.allowedDomains
			state.mainDomain = payload.mainDomain
			state.installedDomain = payload.installedDomain
			state.errors.isErrorAccount = false
			state.chatCodeInstalled = payload.chatCodeInstalled
			state.installedFirstAt = payload.installedFirstAt
			state.widgetPageUrl = payload.widgetPageUrl
		})
		builder.addCase(fetchAccount.rejected, (state) => {
			state.errors.isErrorAccount = true
		})
		builder.addCase(fetchAccount.pending, (state) => {
			state.errors.isErrorAccount = false
		})

		// ACCOUNT
		builder
			.addCase(patchAccount.pending, (state) => {
				state.isPendingUpdate = true
			})
			.addCase(patchAccount.fulfilled, (state, { payload }) => {
				state.isPendingUpdate = false
				state.anonymizeIp = payload.anonymizeIp
				state.allowedDomains = payload.allowedDomains
				state.mainDomain = payload.mainDomain
			})
			.addCase(patchAccount.rejected, (state) => {
				state.isPendingUpdate = false
			})

		// TIMEZONES
		builder.addCase(fetchTimezones.fulfilled, (state, { payload }) => {
			state.timezones = payload
			state.errors.isErrorTimezones = false
		})
		builder.addCase(fetchTimezones.rejected, (state) => {
			state.errors.isErrorTimezones = true
		})
		builder.addCase(fetchTimezones.pending, (state) => {
			state.errors.isErrorTimezones = false
		})

		// DELETE ACCOUNT
		builder
			.addCase(deleteAccount.pending, (state) => {
				state.isDeletingAccount = true
			})
			.addCase(deleteAccount.fulfilled, (state) => {
				state.isDeletingAccount = false
			})
			.addCase(deleteAccount.rejected, (state) => {
				state.isDeletingAccount = false
			})

		// SHOPIFY ACTIVATION URL
		builder.addCase(fetchShopifyActivationUrl.fulfilled, (state, { payload }) => {
			state.shopifyActivationUrl = payload.widgetActivationUrl
		})

		builder.addCase(fetchAccountReviewInfo.fulfilled, (state, { payload }) => {
			state.accountReviewConfig = payload
		})
	},
})

const { reducer, actions } = slice
export const { setIsFormDirty, setIsBlocked, setIsSecurityFormDirty, setIsGoogleAnalyticsFormDirty } = actions
export default reducer
