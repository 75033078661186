import moment from 'moment'

const NOTIFICATION_MONTH_RANGE = 0.04

export const countNotificationInMonthRatio = (
	usage: number,
	limit: number,
	countDaysInMonth: number,
	dayInMonth: number,
): boolean => {
	const dayInMonthRatio = dayInMonth / countDaysInMonth
	const usageRatio = usage / limit

	return dayInMonthRatio - usageRatio - NOTIFICATION_MONTH_RANGE < 0
}

export const isAllowedNotificationInMonth = (usage: number, limit: number): boolean => {
	const countDaysInMonth = moment().daysInMonth()
	const dayInMonth = moment().date()

	return countNotificationInMonthRatio(usage, limit, countDaysInMonth, dayInMonth)
}
