import { ComponentProps } from 'react'
import { FormattedDate } from 'react-intl'

type Props = ComponentProps<typeof FormattedDate>

const IntlDate = (props: Props) => {
	return <FormattedDate {...props} />
}

export default IntlDate
