import { PackageLogEventRequest } from 'shared/models/Package'
import {
	PaymentErrorBody,
	PaymentMethodCreateThreeDSecureResponse,
	PaymentMethodFetchTokenResponse,
	PaymentMethodResponse,
	PaymentMethodUpdateRequest,
	PaymentOptions,
} from 'shared/models/Payment'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'payment'

const fetchPaymentMethod = () => {
	return apiClient.get<PaymentMethodResponse>(`${API_RESOURCE}`)
}

const fetchPaymentOptions = () => {
	return apiClient.get<PaymentOptions>(`${API_RESOURCE}/options?gateway=braintree`)
}

const postPaymentError = (data: PackageLogEventRequest['data']) => {
	const body: PaymentErrorBody = {
		gateway: 'braintree',
		flow: '3ds',
		data,
	}
	return apiClient.post(`${API_RESOURCE}/error`, body)
}

const updatePaymentMethod = (data: PaymentMethodUpdateRequest) => {
	return apiClient.patch<PaymentMethodResponse>(`${API_RESOURCE}`, data)
}

const deletePaymentMethod = () => {
	return apiClient.delete<PaymentMethodResponse>(`${API_RESOURCE}`)
}

const fetchPaymentMethodToken = () => {
	return apiClient.get<PaymentMethodFetchTokenResponse>(`${API_RESOURCE}/token`)
}

const createThreeDSecurePaymentMethod = (data: PaymentMethodUpdateRequest) => {
	return apiClient.post<PaymentMethodCreateThreeDSecureResponse>(`${API_RESOURCE}/three-d-secure`, data)
}

export const paymentMethodApi = {
	fetchPaymentMethod,
	fetchPaymentOptions,
	updatePaymentMethod,
	deletePaymentMethod,
	fetchPaymentMethodToken,
	postPaymentError,
	createThreeDSecurePaymentMethod,
}
