import { ConversationsStatsSummary } from 'models/ConversationsStatistics'

const DEFAULT_STATISTICS_VALUE = 0

export const transformChatbotConversationsSummaryStats = (
	summary?: ConversationsStatsSummary | null,
): ConversationsStatsSummary => {
	return {
		newConversations: {
			value: summary?.newConversations?.value ?? DEFAULT_STATISTICS_VALUE,
			trendPercentage: summary?.newConversations?.trendPercentage ?? DEFAULT_STATISTICS_VALUE,
		},
		chatbotConversations: {
			value: summary?.chatbotConversations?.value ?? DEFAULT_STATISTICS_VALUE,
			trendPercentage: summary?.chatbotConversations?.trendPercentage ?? DEFAULT_STATISTICS_VALUE,
		},
	}
}
