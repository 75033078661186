import {
	mdiAccountCircleOutline,
	mdiAccountGroupOutline,
	mdiAccountMultipleOutline,
	mdiAccountOutline,
	mdiBellOutline,
	mdiChatOutline,
	mdiCreditCardOutline,
	mdiFolderOutline,
	mdiLightningBoltOutline,
	mdiPuzzleOutline,
	mdiTagOutline,
} from '@mdi/js'

import { TranslationService as T } from 'shared/services'
import { routes } from 'configuration/routes'
import { trackIntegrationsTabClick } from 'utils'

import { PopoverMenu, PopoverMenuItem, PopoverMenuSection, Text } from 'core'
import { BillingLink } from 'components/billing'
import { SubmenuLinkContent } from 'components/layout/submenu/SubmenuLinkContent'
import RouterLink from 'components/router/RouterLink'

const SidebarSettingsMenu = () => {
	return (
		<PopoverMenu width="225px">
			<PopoverMenuSection noPadding noBorder>
				<Text fontSize={0} textColor="gray.500" mt={1} mb="0.5" ml={2}>
					{T.translate('sideBar.account.general')}
				</Text>
				<PopoverMenuItem>
					<RouterLink to={routes.accountGeneral.path} data-testid="general">
						<SubmenuLinkContent icon={mdiAccountCircleOutline}>{T.translate('sideBar.account')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem>
					<RouterLink to={routes.agents.path} data-testid="agents">
						<SubmenuLinkContent icon={mdiAccountMultipleOutline}>
							{T.translate('sideBar.account.agents')}
						</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem>
					<RouterLink to={routes.groups.path} data-testid="groups">
						<SubmenuLinkContent icon={mdiAccountGroupOutline}>
							{T.translate('sideBar.account.groups')}
						</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem>
					<RouterLink to={routes.widgetAppearance.path} data-testid="chatbox">
						<SubmenuLinkContent icon={mdiChatOutline}>{T.translate('sideBar.account.chatbox')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem>
					<BillingLink to="profile" data-testid="billing">
						<SubmenuLinkContent icon={mdiCreditCardOutline}>
							{T.translate('sideBar.account.billing')}
						</SubmenuLinkContent>
					</BillingLink>
				</PopoverMenuItem>
				<PopoverMenuItem onClick={trackIntegrationsTabClick}>
					<RouterLink to={routes.integrations.path} data-testid="submenu-integrations">
						<SubmenuLinkContent icon={mdiPuzzleOutline}>{T.translate('integrations.menu.title')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem>
					<RouterLink to={routes.tags.path} data-testid="tags">
						<SubmenuLinkContent icon={mdiTagOutline}>{T.translate('tags.title')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem>
					<RouterLink to={routes.properties.path} data-testid="properties">
						<SubmenuLinkContent icon={mdiFolderOutline}>{T.translate('properties.title')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>

				<Text fontSize={0} textColor="gray.500" mt={1} mb="0.5" ml={2}>
					{T.translate('settings.personal')}
				</Text>
				<PopoverMenuItem>
					<RouterLink to={routes.profile.path} data-testid="submenu-profile" data-userpilot="submenu-profile">
						<SubmenuLinkContent icon={mdiAccountOutline}>{T.translate('sideBar.personal.profile')}</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem>
					<RouterLink to={routes.shortcuts.path} data-testid="submenu-shortcuts" data-userpilot="submenu-shortcuts">
						<SubmenuLinkContent icon={mdiLightningBoltOutline}>
							{T.translate('sideBar.personal.shortcuts')}
						</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				<PopoverMenuItem>
					<RouterLink to={routes.notifications.path} data-testid="submenu-notifications">
						<SubmenuLinkContent icon={mdiBellOutline}>
							{T.translate('sideBar.personal.notifications')}
						</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
			</PopoverMenuSection>
		</PopoverMenu>
	)
}

export default SidebarSettingsMenu
