export const fontSizes = {
	xs: '0.625rem',
	sm: '0.75rem',
	md: '0.875rem', // Base font-size: 14px
	lg: '1rem',
	xl: '1.125rem',
	'2xl': '1.25rem',
	'3xl': '1.5rem',
	'4xl': '1.875rem',
	'5xl': '2.25rem',
	'6xl': '3rem',
	'7xl': '3.75rem',
	'8xl': '4.5rem',
	'9xl': '6rem',
	'10xl': '8rem',

	// Fallbacks to old scale
	'0': '0.75rem',
	'1': '0.875rem',
	'2': '1rem',
	'3': '1.25rem',
	'4': '1.5rem',
	'5': '2rem',
	'6': '2.5rem',
	'7': '3rem',
}

export const lineHeights = {
	// TODO: Not used by Chakra
	condensedUltra: 1,
	condensed: 1.25,
	default: 1.5,
}
