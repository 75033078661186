import {
	createAsyncThunk,
	createEntityAdapter,
	createSelector,
	createSlice,
	isFulfilled,
	isPending,
	isRejected,
	PayloadAction,
} from '@reduxjs/toolkit'

import { CreateTrainingEntryBody, SelectedEntryType, TrainingEntry, UpdateTrainingEntryBody } from 'models'
import { AppThunkAction, DashboardState } from 'types'
import { flashMessage } from 'shared/utils'
import { usageSelectors } from 'modules/usage'

import { trainingApi } from './api'
import { getErrorCode } from './utils'

export type TrainingRootState = Pick<DashboardState, 'training'>

const trainingEntriesAdapter = createEntityAdapter<TrainingEntry>()

export const initialState = trainingEntriesAdapter.getInitialState({
	isModalOpen: false,
	selectedEntry: null as null | SelectedEntryType,
	shouldDisplayInfoStrip: false,
	isFetchingEntries: false,
	isSaving: false,
	isUpsellModalOpen: false,
	entriesCount: 0 as number,
})

export const fetchTrainingEntries = createAsyncThunk('training/FETCH', () => {
	return trainingApi.fetchTrainingEntries()
})

export const createTrainingEntry = createAsyncThunk('training/CREATE', (data: CreateTrainingEntryBody) => {
	return trainingApi.createTrainingEntry(data)
})

export const editTrainingEntry = createAsyncThunk(
	'training/EDIT',
	(data: { id: number; changes: UpdateTrainingEntryBody }) => {
		return trainingApi.editTrainingEntry(data.id, data.changes)
	},
)

export const deleteTrainingEntry = createAsyncThunk('training/DELETE', (id: number) => {
	return trainingApi.deleteTrainingEntry(id)
})

export const fetchTrainingEntriesCount = createAsyncThunk('training/FETCH_COUNT', () => {
	return trainingApi.fetchTrainingEntriesCount()
})

export const submitTrainingEntryForm =
	(data: SelectedEntryType, onError: (code?: string) => void): AppThunkAction =>
	async (dispatch) => {
		const { id, question, answer } = data

		if (id) {
			const resultEdit = await dispatch(editTrainingEntry({ id, changes: { question, answer } }))
			if (editTrainingEntry.fulfilled.match(resultEdit)) {
				flashMessage.success('chatbot.save.success')
				dispatch(closeTrainingModal())
			} else {
				flashMessage.error(getErrorCode(resultEdit.error.code))
				onError(resultEdit.error.code)
			}
		} else {
			const resultCreate = await dispatch(createTrainingEntry({ question, answer }))
			if (createTrainingEntry.fulfilled.match(resultCreate)) {
				flashMessage.success('ai.training.create.success')
				dispatch(closeTrainingModal())
			} else {
				flashMessage.error(getErrorCode(resultCreate.error.code))
				onError(resultCreate.error.code)
			}
		}
	}

const slice = createSlice({
	name: 'training',
	initialState,
	reducers: {
		openTrainingModal: (state, { payload }: PayloadAction<SelectedEntryType | null>) => {
			state.isModalOpen = true
			state.selectedEntry = payload
		},
		closeTrainingModal: (state) => {
			state.isModalOpen = false
			state.selectedEntry = null
		},
		setShouldDisplayInfoStrip: (state, { payload }: PayloadAction<boolean>) => {
			state.shouldDisplayInfoStrip = payload
		},
		setTrainingUpsellModalOpen: (state, { payload }: PayloadAction<boolean>) => {
			state.isUpsellModalOpen = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchTrainingEntries.fulfilled, (state, { payload }) => {
				trainingEntriesAdapter.setAll(state, payload)
				state.isFetchingEntries = false
				state.entriesCount = payload.length
			})
			.addCase(fetchTrainingEntries.rejected, (state) => {
				state.isFetchingEntries = false
			})
			.addCase(fetchTrainingEntries.pending, (state) => {
				state.isFetchingEntries = true
			})
		builder.addCase(createTrainingEntry.fulfilled, (state, { payload }) => {
			trainingEntriesAdapter.addOne(state, payload)
			state.entriesCount = Number(state.entriesCount) + 1
		})

		builder.addCase(editTrainingEntry.fulfilled, (state, { payload }) => {
			trainingEntriesAdapter.updateOne(state, { id: payload.id, changes: payload })
		})
		builder.addCase(deleteTrainingEntry.fulfilled, (state, { meta }) => {
			trainingEntriesAdapter.removeOne(state, meta.arg)
			state.entriesCount -= 1
		})
		builder.addCase(fetchTrainingEntriesCount.fulfilled, (state, { payload }) => {
			state.entriesCount = payload.count
		})
		builder.addMatcher(isPending(createTrainingEntry, editTrainingEntry), (state) => {
			state.isSaving = true
		})
		builder.addMatcher(isRejected(createTrainingEntry, editTrainingEntry), (state) => {
			state.isSaving = false
		})
		builder.addMatcher(isFulfilled(createTrainingEntry, editTrainingEntry), (state) => {
			state.isSaving = false
		})
	},
})

const { reducer, actions } = slice
export const { openTrainingModal, closeTrainingModal, setShouldDisplayInfoStrip, setTrainingUpsellModalOpen } = actions
export default reducer

const trainingEntriesSelector = trainingEntriesAdapter.getSelectors<TrainingRootState>((state) => state.training)
const getIsTrainingModalOpen = (state: TrainingRootState) => state.training.isModalOpen
const getShouldDisplayInfoStrip = (state: TrainingRootState) => state.training.shouldDisplayInfoStrip
const getEntries = (state: TrainingRootState) => trainingEntriesSelector.selectAll(state)
const getSelectedEntry = (state: TrainingRootState) => state.training.selectedEntry
const getIsFetchingEntries = (state: TrainingRootState) => state.training.isFetchingEntries
const getIsSavingEntries = (state: TrainingRootState) => state.training.isSaving
const getIsTrainingUpsellModalOpen = (state: TrainingRootState) => state.training.isUpsellModalOpen
const getEntriesCount = (state: TrainingRootState) => state.training.entriesCount

const getEntryFormData = createSelector([getSelectedEntry], (entry) => {
	if (!entry)
		return {
			question: '',
			answer: '',
			id: null,
		}
	return entry
})

const getShouldDisplayLimitUpsell = createSelector(
	[getEntriesCount, usageSelectors.getAiTrainingEntriesLimit],
	(count, limit) => {
		if (!limit) return false
		return count >= limit
	},
)

const getSortedEntries = createSelector([getEntries], (entries) => {
	return entries.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
})

export const trainingSelectors = {
	getIsTrainingModalOpen,
	getShouldDisplayInfoStrip,
	getEntryFormData,
	getIsFetchingEntries,
	getIsSavingEntries,
	getShouldDisplayLimitUpsell,
	getIsTrainingUpsellModalOpen,
	getSortedEntries,
}
