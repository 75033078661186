import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import equal from 'fast-deep-equal'

import { BillingInfo, ConsentType, CountryCode, ExtendedBillingInfo, StripeCustomerPortalRequestData } from 'models'
import { AppThunkAction, DashboardState } from 'types'
import { ConfigurationService } from 'shared/services'
import { routes } from 'configuration/routes'
import { fetchInitData } from 'modules/app/actions'
import { BillingInfoStripeFormData, ValidationVatStatus } from 'modules/billingInfo'
import { createAccountConsent } from 'modules/consents'
import { getVatCountryCode } from 'modules/countries'
import { packageSelectors } from 'modules/package'
import { closeSystemModal, openSystemModal, SystemModalType } from 'modules/systemModal'

import { billingInfoApi } from './api'
import { emptyBillingInfo, VAT_PREFIX_LENGTH } from './constants'
import { BillingInfoFormData } from './types'
import { createVatId, transformBillingInfoFormDataToUpdateRequest, transformCustomerToBillingInfo } from './utils'

export type BillingInfoRootState = Pick<DashboardState, 'billingInfo'>

export const initialState = {
	isFetchPending: false,
	isUpdatePending: false,
	isValidatingVat: false,
	validationVatStatus: null as null | ValidationVatStatus,
	billingInfo: emptyBillingInfo,
	isBillingInfoInitialized: false, // Billing info is initialized for Checkout
	isBillingInfoFilled: false, // Billing info is loaded from DB
	isBillingInfoDirty: false, // Billing info was changed by user
}

export const initBillingInfo = (): AppThunkAction => async (dispatch, getState) => {
	dispatch(setIsInitialized(false))
	const state = getState()

	const isAllowedStripe = packageSelectors.getIsAllowedStripePaymentGateway(state)
	const isBillingInfoFilled = getIsBillingInfoFilled(state)

	if (isAllowedStripe) {
		isBillingInfoFilled ? dispatch(setIsInitialized(true)) : dispatch(initStripeCustomer())
	} else {
		await dispatch(fetchBillingInfo())
		// Update billing info with default values if it's not filled
		if (!getIsBillingInfoFilled(getState())) {
			const { countryCode, email, vatNumber } = getBillingInfo(getState())
			await dispatch(updateBillingInfo({ countryCode: countryCode || CountryCode.Czechia, email, vatNumber }))
		}
	}
}

export const fetchBillingInfo = createAsyncThunk('billingInfo/FETCH', () => {
	return billingInfoApi.fetchBillingInfo()
})

export const fetchStripeCustomer = createAsyncThunk('billingInfoStripe/FETCH', () => {
	return billingInfoApi.fetchStripeCustomer()
})

export const fetchStripeCustomerPortalLink = createAsyncThunk('customerPortal/FETCH', () => {
	const data: StripeCustomerPortalRequestData = {
		locale: ConfigurationService.getData().lang,
		returnPath: routes.billingProfile.path,
	}
	return billingInfoApi.fetchStripeCustomerPortalLink(data)
})

export const updateBillingInfo = createAsyncThunk('billingInfo/UPDATE', (data: BillingInfoFormData, { getState }) => {
	const billingInfo = getBillingInfo(getState() as DashboardState)
	const updateData = transformBillingInfoFormDataToUpdateRequest(data, billingInfo)
	return billingInfoApi.updateBillingInfo(updateData)
})

export const updateStripeCustomer = createAsyncThunk('billingInfoStripe/UPDATE', (data: BillingInfoStripeFormData) => {
	const { city, company, countryCode, email, postalCode, street, vatNumber, ico } = data
	const { lang } = ConfigurationService.getData()
	return billingInfoApi.updateStripeCustomer({
		city,
		name: company,
		country: countryCode.toUpperCase(),
		email,
		postalCode,
		street,
		taxId: createVatId(countryCode, vatNumber),
		ic: ico,
		locale: lang,
	})
})

export const validateVatNumber = createAsyncThunk('billingInfo/VALIDATE_VAT', async (vatNumber: string) => {
	return billingInfoApi.validateVatNumber(vatNumber)
})

export const initStripeCustomer = (): AppThunkAction => (dispatch, getState) => {
	const { countryCode, email } = getBillingInfo(getState())
	const country = countryCode || CountryCode.Czechia
	dispatch(
		updateStripeCustomer({
			city: '',
			company: '',
			countryCode: country.toUpperCase(),
			email,
			postalCode: '',
			street: '',
			vatNumber: '',
			ico: '',
		}),
	)
}

export const updateCustomerState =
	(data: Partial<BillingInfo>): AppThunkAction =>
	async (dispatch, getState) => {
		const billingInfo = getBillingInfo(getState())
		const billingInfoData = { ...billingInfo, ...data }
		const { city, company, countryCode, email, postalCode, street, vatNumber, ico } = billingInfoData

		if (equal(billingInfo, billingInfoData)) return

		await dispatch(
			updateStripeCustomer({
				city,
				company,
				countryCode,
				email,
				postalCode,
				street,
				vatNumber,
				ico,
			}),
		)
	}

export const showVatConfirmationModal =
	(onConfirm: () => void, text: string): AppThunkAction =>
	(dispatch) => {
		dispatch(
			openSystemModal({
				name: SystemModalType.Confirm,
				data: {
					title: 'billing.billingInfo.vatConfirm.title',
					text: `billing.billingInfo.vatConfirm.${text}`,
					buttonPrimaryText: `billing.billingInfo.vatConfirm.confirm.${text}`,
					buttonSecondaryText: 'billing.billingInfo.vatConfirm.cancel',
					onConfirm: () => {
						dispatch(createAccountConsent({ data: { type: ConsentType.TaxResponsibility, agreed: true } }))
						onConfirm()
					},
					onCancel: () => {
						dispatch(closeSystemModal())
					},
				},
			}),
		)
	}

const slice = createSlice({
	name: 'billingInfo',
	initialState,
	reducers: {
		setIsFormDirty: (state, { payload }: PayloadAction<{ isBillingInfoDirty: boolean }>) => {
			const { isBillingInfoDirty } = payload
			state.isBillingInfoDirty = isBillingInfoDirty
		},
		updateBillingInfoCountryCode: (state, { payload }: PayloadAction<BillingInfo['countryCode']>) => {
			state.billingInfo.countryCode = payload
			state.isBillingInfoDirty = true
		},
		updateBillingInfoVat: (state, { payload }: PayloadAction<string>) => {
			state.billingInfo.vatNumber = payload
			state.isBillingInfoDirty = true
		},
		resetBillingInfoTaxFields: (state) => {
			state.billingInfo.vatNumber = ''
			state.billingInfo.ico = ''
			state.isBillingInfoDirty = true
		},
		resetValidationVatStatus: (state) => {
			state.validationVatStatus = null
		},
		setIsInitialized: (state, { payload }: PayloadAction<boolean>) => {
			state.isBillingInfoInitialized = payload
		},
	},
	extraReducers: (builder) => {
		// Setup default values for billing info
		builder.addCase(fetchInitData.fulfilled, (state, { payload }) => {
			const { ownerEmail, sourceCountryCode, countryCode } = payload
			state.billingInfo.email = ownerEmail
			state.billingInfo.countryCode = sourceCountryCode || countryCode
		})

		// Fetch billing info
		builder
			.addCase(fetchBillingInfo.pending, (state) => {
				state.isFetchPending = true
			})
			.addCase(fetchBillingInfo.fulfilled, (state, { payload }) => {
				state.isFetchPending = false
				state.billingInfo = payload
				state.isBillingInfoFilled = true
				state.isBillingInfoDirty = false
				state.isBillingInfoInitialized = true
			})
			.addCase(fetchBillingInfo.rejected, (state) => {
				state.isFetchPending = false
			})

		// Fetch Stripe customer
		builder
			.addCase(fetchStripeCustomer.pending, (state) => {
				state.isFetchPending = true
			})
			.addCase(fetchStripeCustomer.fulfilled, (state, { payload }) => {
				state.isFetchPending = false
				state.billingInfo = transformCustomerToBillingInfo(state.billingInfo, payload)
				state.isBillingInfoFilled = true
				state.isBillingInfoDirty = false
				state.isBillingInfoInitialized = true
			})
			.addCase(fetchStripeCustomer.rejected, (state) => {
				state.isFetchPending = false
			})

		// Update billing info
		builder
			.addCase(updateBillingInfo.pending, (state) => {
				state.isUpdatePending = true
			})
			.addCase(updateBillingInfo.fulfilled, (state, { payload }) => {
				state.isUpdatePending = false
				state.billingInfo = payload
				state.isBillingInfoFilled = true
				state.isBillingInfoDirty = false
				state.isBillingInfoInitialized = true
			})
			.addCase(updateBillingInfo.rejected, (state) => {
				state.isUpdatePending = false
			})

		// Update stripe customer
		builder
			.addCase(updateStripeCustomer.pending, (state) => {
				state.isUpdatePending = true
			})
			.addCase(updateStripeCustomer.fulfilled, (state, { payload }) => {
				state.isUpdatePending = false
				state.billingInfo = transformCustomerToBillingInfo(state.billingInfo, payload)
				state.isBillingInfoFilled = true
				state.isBillingInfoDirty = false
				state.isBillingInfoInitialized = true
			})
			.addCase(updateStripeCustomer.rejected, (state) => {
				state.isUpdatePending = false
			})

		// Validate VAT number
		builder
			.addCase(validateVatNumber.pending, (state) => {
				state.isValidatingVat = true
			})
			.addCase(validateVatNumber.fulfilled, (state, { payload }) => {
				state.isValidatingVat = false
				if (payload.valid === null) {
					state.validationVatStatus = ValidationVatStatus.Unknown
				} else if (payload.valid) {
					state.validationVatStatus = ValidationVatStatus.Valid
				} else {
					state.validationVatStatus = ValidationVatStatus.Invalid
				}
			})
			.addCase(validateVatNumber.rejected, (state) => {
				state.isValidatingVat = false
				state.validationVatStatus = ValidationVatStatus.Invalid
			})
	},
})

const { reducer, actions } = slice
export const {
	resetValidationVatStatus,
	updateBillingInfoCountryCode,
	updateBillingInfoVat,
	setIsInitialized,
	setIsFormDirty,
	resetBillingInfoTaxFields,
} = actions
export default reducer

const getBillingInfoData = (state: BillingInfoRootState) => state.billingInfo.billingInfo
const getIsFetching = (state: BillingInfoRootState) => state.billingInfo.isFetchPending
const getIsValidatingVat = (state: BillingInfoRootState) => state.billingInfo.isValidatingVat
const getValidationVatStatus = (state: BillingInfoRootState) => state.billingInfo.validationVatStatus
const getIsUpdatePending = (state: BillingInfoRootState) => state.billingInfo.isUpdatePending
const getIsBillingInfoFilled = (state: BillingInfoRootState) => state.billingInfo.isBillingInfoFilled
const getIsBillingInfoDirty = (state: BillingInfoRootState) => state.billingInfo.isBillingInfoDirty
const getIsBillingInfoInitialized = (state: BillingInfoRootState) => state.billingInfo.isBillingInfoInitialized

export const getBillingInfo = createSelector([getBillingInfoData], (billing): ExtendedBillingInfo => {
	// Remove first 2 chars (country code) from VAT number
	let vatNumber = ''
	const hasVatNumber = !!billing.vatNumber && billing.vatNumber.length > 0
	if (hasVatNumber) {
		vatNumber = billing.vatNumber.slice(VAT_PREFIX_LENGTH)
	}

	return {
		...billing,
		hasVatNumber,
		vatNumber,
		vatNumberFormatted: hasVatNumber ? getVatCountryCode(billing.countryCode) + vatNumber : '',
	}
})

export const getBillingInfoCountryCode = createSelector([getBillingInfoData], (billing): BillingInfo['countryCode'] => {
	const { countryCode } = billing
	return countryCode
})

export const getBillingInfoVatNumber = createSelector([getBillingInfoData], (billing): BillingInfo['vatNumber'] => {
	const { vatNumber } = billing
	return vatNumber
})

export const getBillingInfoCurrency = createSelector([getBillingInfoData], (billing): BillingInfo['currency'] => {
	const { currency } = billing
	return currency
})

export const billingInfoSelectors = {
	getBillingInfo,
	getIsBillingInfoFilled,
	getIsBillingInfoDirty,
	getIsFetching,
	getIsUpdatePending,
	getIsValidatingVat,
	getValidationVatStatus,
	getIsBillingInfoInitialized,
	getBillingInfoCountryCode,
	getBillingInfoVatNumber,
	getBillingInfoCurrency,
}
