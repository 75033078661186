import { mdiAccountMultipleOutline, mdiCalendar, mdiClockOutline, mdiRobotOutline, mdiWeb } from '@mdi/js'

import {
	ChatbotTriggerConditionName,
	ChatbotTriggerConditionOpGroup,
	ChatbotTriggerConditionOpNumber,
	ChatbotTriggerConditionOpString,
	ChatbotTriggerType,
} from 'models'
import { AppLang } from 'shared/types'
import {
	ChatbotAllowedNumberConditionOperations,
	ChatbotAllowedSelectConditionOperations,
	ChatbotAllowedStringConditionOperations,
} from 'modules/chatbotSettings/types'

export const SETTINGS_FORM_ID = 'chatbot-settings-form'
export const MAX_CHATBOT_CONDITIONS = 50
export const DEFAULT_TRIGGER_TYPE = ChatbotTriggerType.PageLoad
export const DEFAULT_NUMBER_OF_VISITS_FROM = 1
export const DEFAULT_NUMBER_OF_VISITS_TO = 10
export const CONDITION_NUMBER_INPUT_MIN = 0
export const CONDITION_NUMBER_INPUT_MAX = 999
export const CONDITION_DELAY_INPUT_MIN = 1

export const DAYS_IN_WEEK = 7
export const MONTHS_IN_YEAR = 12

export enum ChatbotSettingsSection {
	When = 'when',
	Where = 'where',
	Audience = 'audience',
	Scheduling = 'scheduling',
	Behavior = 'behavior',
}

export enum ChatbotTriggerConditionGroup {
	PageConditions = 'pageConditions',
	AudienceConditions = 'audienceConditions',
}

export enum ChatbotTriggerSchedulingConditionGroup {
	Months = 'months',
	Days = 'days',
	Times = 'times',
}

export enum ChatbotTriggerFormField {
	Type = 'type',
	Delay = 'delay',
	DisableInput = 'disableInput',
	PageDisplay = 'pageDisplay',
	AccountStatus = 'accountStatus',
	VisitsCount = 'visitsCount',
	DeviceType = 'deviceType',
	VisitsRange = 'visitsRange',
	IsAllowedWhenChatIsTriggered = 'isAllowedWhenChatIsTriggered',
}

export enum ChatbotTriggerFormPageDisplayValue {
	All = 'all',
	Specific = 'specific',
}

export enum ChatbotTriggerAccountStatusValue {
	Online = 'online',
	Offline = 'offline',
	Any = 'any',
}

export const chatbotTriggerVisitsCountValue = {
	All: 0,
	New: 1,
	Returning: 2,
}

export enum ChatbotTriggerDeviceTypeValue {
	Desktop = 'desktop',
	Mobile = 'mobile',
	Both = 'both',
}

export const chatbotTriggerVisitsCountTextMap: Record<number, { name: number; text: string }> = {
	[chatbotTriggerVisitsCountValue.All]: {
		name: chatbotTriggerVisitsCountValue.All,
		text: 'chatbotSettings.visitsCount.all',
	},
	[chatbotTriggerVisitsCountValue.New]: {
		name: chatbotTriggerVisitsCountValue.New,
		text: 'chatbotSettings.visitsCount.new',
	},
	[chatbotTriggerVisitsCountValue.Returning]: {
		name: chatbotTriggerVisitsCountValue.Returning,
		text: 'chatbotSettings.visitsCount.returning',
	},
}

export const chatbotTriggerConditionGroups: Record<ChatbotTriggerConditionGroup, ChatbotTriggerConditionName[]> = {
	[ChatbotTriggerConditionGroup.PageConditions]: [
		ChatbotTriggerConditionName.PageUrl,
		ChatbotTriggerConditionName.PageTitle,
	],
	[ChatbotTriggerConditionGroup.AudienceConditions]: [
		ChatbotTriggerConditionName.Ip,
		ChatbotTriggerConditionName.City,
		ChatbotTriggerConditionName.CountryCode,
		ChatbotTriggerConditionName.VisitorGroup,
		ChatbotTriggerConditionName.Referer,
		ChatbotTriggerConditionName.PageViews,
		ChatbotTriggerConditionName.ChatsCount,
		ChatbotTriggerConditionName.Browser,
		ChatbotTriggerConditionName.Os,
	],
}

export const chatbotSettingsSectionsMap: Record<
	ChatbotSettingsSection,
	{ name: ChatbotSettingsSection; title: string; description: string; iconPath: string; isVisible: boolean }
> = {
	[ChatbotSettingsSection.When]: {
		name: ChatbotSettingsSection.When,
		title: 'chatbotSettings.when',
		description: 'chatbotSettings.when.description',
		iconPath: mdiClockOutline,
		isVisible: true,
	},
	[ChatbotSettingsSection.Where]: {
		name: ChatbotSettingsSection.Where,
		title: 'chatbotSettings.where',
		description: 'chatbotSettings.where.description',
		iconPath: mdiWeb,
		isVisible: true,
	},
	[ChatbotSettingsSection.Audience]: {
		name: ChatbotSettingsSection.Audience,
		title: 'chatbotSettings.audience',
		description: 'chatbotSettings.audience.description',
		iconPath: mdiAccountMultipleOutline,
		isVisible: true,
	},
	[ChatbotSettingsSection.Scheduling]: {
		name: ChatbotSettingsSection.Scheduling,
		title: 'chatbotSettings.scheduling',
		description: 'chatbotSettings.scheduling.description',
		iconPath: mdiCalendar,
		isVisible: true,
	},
	[ChatbotSettingsSection.Behavior]: {
		name: ChatbotSettingsSection.Behavior,
		title: 'chatbotSettings.behavior',
		description: 'chatbotSettings.behavior.description',
		iconPath: mdiRobotOutline,
		isVisible: true,
	},
}

export const chatbotTriggerAccountStatusTextMap: Record<
	ChatbotTriggerAccountStatusValue,
	{ name: ChatbotTriggerAccountStatusValue; text: string }
> = {
	[ChatbotTriggerAccountStatusValue.Online]: {
		name: ChatbotTriggerAccountStatusValue.Online,
		text: 'chatbotSettings.accountStatus.online',
	},
	[ChatbotTriggerAccountStatusValue.Offline]: {
		name: ChatbotTriggerAccountStatusValue.Offline,
		text: 'chatbotSettings.accountStatus.offline',
	},
	[ChatbotTriggerAccountStatusValue.Any]: {
		name: ChatbotTriggerAccountStatusValue.Any,
		text: 'chatbotSettings.accountStatus.onlineAndOffline',
	},
}

export const chatbotTriggerTypesMap: Record<
	ChatbotTriggerType,
	{ name: ChatbotTriggerType; text: string; delay: number }
> = {
	[ChatbotTriggerType.PageLoad]: {
		name: ChatbotTriggerType.PageLoad,
		text: 'chatbotSettings.when.timeOnWeb',
		delay: 30,
	},
	[ChatbotTriggerType.PageUnchanged]: {
		name: ChatbotTriggerType.PageUnchanged,
		text: 'chatbotSettings.when.pageUnchanged',
		delay: 30,
	},
	[ChatbotTriggerType.WidgetOpen]: {
		name: ChatbotTriggerType.WidgetOpen,
		text: 'chatbotSettings.when.widgetOpen',
		delay: 1,
	},
	[ChatbotTriggerType.Message]: {
		name: ChatbotTriggerType.Message,
		text: 'chatbotSettings.when.visitorMessage',
		delay: 1,
	},
	[ChatbotTriggerType.AgentBusy]: {
		name: ChatbotTriggerType.AgentBusy,
		text: 'chatbotSettings.when.agentBusy',
		delay: 45,
	},
	[ChatbotTriggerType.AutomationIgnored]: {
		name: ChatbotTriggerType.AutomationIgnored,
		text: 'chatbotSettings.when.automationIgnored',
		delay: 120,
	},
}

export const chatbotTriggerConditionStringOperationsMap: Record<
	ChatbotAllowedStringConditionOperations,
	{ name: ChatbotTriggerConditionOpString; text: string }
> = {
	[ChatbotTriggerConditionOpString.Eq]: {
		name: ChatbotTriggerConditionOpString.Eq,
		text: 'chatbotBuilder.triggerForm.equal',
	},
	[ChatbotTriggerConditionOpString.NotEq]: {
		name: ChatbotTriggerConditionOpString.NotEq,
		text: 'chatbotBuilder.triggerForm.notEqual',
	},
	[ChatbotTriggerConditionOpString.Contains]: {
		name: ChatbotTriggerConditionOpString.Contains,
		text: 'chatbotBuilder.triggerForm.contains',
	},
	[ChatbotTriggerConditionOpString.NotContains]: {
		name: ChatbotTriggerConditionOpString.NotContains,
		text: 'chatbotBuilder.triggerForm.doesntContain',
	},
}

export const chatbotTriggerConditionSelectOperationsMap: Record<
	ChatbotAllowedSelectConditionOperations,
	{ name: ChatbotTriggerConditionOpString; text: string }
> = {
	[ChatbotTriggerConditionOpString.Eq]: {
		name: ChatbotTriggerConditionOpString.Eq,
		text: 'chatbotBuilder.triggerForm.equal',
	},
	[ChatbotTriggerConditionOpString.NotEq]: {
		name: ChatbotTriggerConditionOpString.NotEq,
		text: 'chatbotBuilder.triggerForm.notEqual',
	},
}

export const chatbotTriggerConditionNumberOperationsMap: Record<
	ChatbotAllowedNumberConditionOperations,
	{ name: ChatbotTriggerConditionOpNumber; text: string }
> = {
	[ChatbotTriggerConditionOpNumber.Eq]: {
		name: ChatbotTriggerConditionOpNumber.Eq,
		text: 'chatbot.comparators.equals',
	},
	[ChatbotTriggerConditionOpNumber.NotEq]: {
		name: ChatbotTriggerConditionOpNumber.NotEq,
		text: 'chatbot.comparators.notEquals',
	},
	[ChatbotTriggerConditionOpNumber.Gt]: {
		name: ChatbotTriggerConditionOpNumber.Gt,
		text: 'chatbotBuilder.triggerForm.gt',
	},
	[ChatbotTriggerConditionOpNumber.Gte]: {
		name: ChatbotTriggerConditionOpNumber.Gte,
		text: 'chatbotBuilder.triggerForm.gte',
	},
	[ChatbotTriggerConditionOpNumber.Lt]: {
		name: ChatbotTriggerConditionOpNumber.Lt,
		text: 'chatbotBuilder.triggerForm.lt',
	},
	[ChatbotTriggerConditionOpNumber.Lte]: {
		name: ChatbotTriggerConditionOpNumber.Lte,
		text: 'chatbotBuilder.triggerForm.lte',
	},
}

export const chatbotTriggerConditionTextMap: Partial<
	Record<ChatbotTriggerConditionName, { text: string; tooltip: string; placeholder?: string; hint?: string }>
> = {
	[ChatbotTriggerConditionName.PageUrl]: {
		text: 'chatbotSettings.where.url',
		placeholder: 'chatbotSettings.where.urlPlaceholder',
		tooltip: 'chatbotSettings.page_url.helpTooltip',
	},
	[ChatbotTriggerConditionName.PageTitle]: {
		text: 'chatbotSettings.where.title',
		placeholder: 'chatbotSettings.where.titlePlaceholder',
		tooltip: 'chatbotSettings.page_title.helpTooltip',
	},
	[ChatbotTriggerConditionName.Ip]: {
		text: 'chatbotSettings.audience.ip',
		placeholder: 'chatbotSettings.audience.ipPlaceholder',
		hint: 'chatbotSettings.audience.ipHint',
		tooltip: 'chatbotSettings.ip.helpTooltip',
	},
	[ChatbotTriggerConditionName.City]: {
		text: 'chatbotSettings.audience.city',
		placeholder: 'chatbotSettings.audience.cityPlaceholder',
		hint: 'chatbotSettings.audience.cityHint',
		tooltip: 'chatbotSettings.city.helpTooltip',
	},
	[ChatbotTriggerConditionName.CountryCode]: {
		text: 'chatbotSettings.audience.countryCode',
		placeholder: 'chatbotSettings.audience.countryCodePlaceholder',
		hint: 'chatbotSettings.audience.countryCodeHint',
		tooltip: 'chatbotSettings.country_code.helpTooltip',
	},
	[ChatbotTriggerConditionName.VisitorGroup]: {
		text: 'visitors.group',
		hint: 'chatbotSettings.audience.groupHint',
		tooltip: 'chatbotSettings.visitor_group.helpTooltip',
	},
	[ChatbotTriggerConditionName.Referer]: {
		text: 'chatbotSettings.audience.referer',
		placeholder: 'chatbotSettings.audience.refererPlaceholder',
		hint: 'chatbotSettings.audience.refererHint',
		tooltip: 'chatbotSettings.referer.helpTooltip',
	},
	[ChatbotTriggerConditionName.ChatsCount]: {
		text: 'chatbot.conditionType.numberOfChats',
		placeholder: 'chatbotSettings.audience.numberOfChatsPlaceholder',
		hint: 'chatbotSettings.audience.numberOfChatsHint',
		tooltip: 'chatbotSettings.audience.numberOfChatsHint',
	},
	[ChatbotTriggerConditionName.PageViews]: {
		text: 'chatbot.conditionType.pageViews',
		placeholder: 'chatbotSettings.audience.pageViewsPlaceholder',
		hint: 'chatbotSettings.audience.pageViewsHint',
		tooltip: 'chatbotSettings.audience.pageViewsHint',
	},
	[ChatbotTriggerConditionName.Browser]: {
		text: 'chatbotSettings.audience.browser',
		placeholder: 'chatbotSettings.audience.browserPlaceholder',
		hint: 'chatbotSettings.audience.browserHint',
		tooltip: 'chatbotSettings.browser.helpTooltip',
	},
	[ChatbotTriggerConditionName.Os]: {
		text: 'chatbotSettings.audience.os',
		placeholder: 'chatbotSettings.audience.osPlaceholder',
		hint: 'chatbotSettings.audience.osHint',
		tooltip: 'chatbotSettings.os.helpTooltip',
	},
}

export const chatbotTriggerNewVisitorCondition = {
	name: ChatbotTriggerConditionName.VisitsCount,
	op: ChatbotTriggerConditionOpString.Eq,
	value: 1,
}

export const chatbotStringComparatorTranslationsMapper: Record<string, string> = {
	[ChatbotTriggerConditionOpString.Contains]: 'chatbot.comparators.contains',
	[ChatbotTriggerConditionOpString.NotContains]: 'chatbot.comparators.notContains',
	[ChatbotTriggerConditionOpString.Eq]: 'chatbotBuilder.triggerForm.equal',
	[ChatbotTriggerConditionOpString.NotEq]: 'chatbotBuilder.triggerForm.notEqual',
	[ChatbotTriggerConditionOpString.StartWith]: '',
	[ChatbotTriggerConditionOpString.NotStartWith]: '',
	[ChatbotTriggerConditionOpString.Regex]: '',
}

export const chatbotNumberComparatorTranslationsMapper: Record<string, string> = {
	[ChatbotTriggerConditionOpNumber.Eq]: 'chatbot.comparators.equals',
	[ChatbotTriggerConditionOpNumber.NotEq]: 'chatbot.comparators.notEquals',
	[ChatbotTriggerConditionOpNumber.Gt]: 'chatbotBuilder.triggerForm.gt',
	[ChatbotTriggerConditionOpNumber.Gte]: 'chatbotBuilder.triggerForm.gte',
	[ChatbotTriggerConditionOpNumber.Lt]: 'chatbotBuilder.triggerForm.lt',
	[ChatbotTriggerConditionOpNumber.Lte]: 'chatbotBuilder.triggerForm.lte',
}

export const chatbotGroupOperatorTranslationsMapper: Record<string, string> = {
	[ChatbotTriggerConditionOpGroup.And]: 'chatbotSettings.conditions.and',
	[ChatbotTriggerConditionOpGroup.Or]: 'chatbotSettings.conditions.or',
}

export const helpAnchorMap: Partial<Record<AppLang, string>> = {
	cs: '/#nastaveni-podminek',
	en: '/#conditions-settings',
	de: '/#einstellungen-der-bedingungen',
	es: '/#configuracion-de-condiciones',
	fr: '/#parametres-de-conditions',
	hu: '/#feltetelek-beallitasai',
	it: '/#impostazioni-delle-condizioni',
	pl: '/#ustawienia-warunkow',
}

export const helpStatisticsAnchorMap: Partial<Record<AppLang, string>> = {
	cs: '/#podrobne-statistiky',
	en: '/#detailed-statistics',
	de: '/#detaillierte-statistiken',
	es: '/#estadisticas-detalladas',
	fr: '/#statistiques-detaillees',
	hu: '/#reszletes-statisztikak',
	it: '/#statistiche-dettagliate',
}

export const monthsMap: Record<string, string> = {
	0: 'months.january',
	1: 'months.february',
	2: 'months.march',
	3: 'months.april',
	4: 'months.may',
	5: 'months.june',
	6: 'months.july',
	7: 'months.august',
	8: 'months.september',
	9: 'months.october',
	10: 'months.november',
	11: 'months.december',
}

export const daysMap: { value: string; text: string }[] = [
	{ value: '1', text: 'days.monday' },
	{ value: '2', text: 'days.tuesday' },
	{ value: '3', text: 'days.wednesday' },
	{ value: '4', text: 'days.thursday' },
	{ value: '5', text: 'days.friday' },
	{ value: '6', text: 'days.saturday' },
	{ value: '0', text: 'days.sunday' },
]

export const chatbotSettingsTestingAttributes = {
	deviceSelect: {
		selectComponent: 'chatbot-settings-select-device',
		selectButton: 'chatbot-settings-select-device',
	},
	when: {
		radio: {
			[ChatbotTriggerType.Message]: 'radio-button-message',
			[ChatbotTriggerType.PageLoad]: 'radio-button-pageLoad',
			[ChatbotTriggerType.WidgetOpen]: 'radio-button-widgetOpen',
			[ChatbotTriggerType.AgentBusy]: 'radio-button-agentBusy',
			[ChatbotTriggerType.PageUnchanged]: 'radio-button-pageUnchanged',
			[ChatbotTriggerType.AutomationIgnored]: 'radio-button-automationIgnored',
		},
		setUpPageButton: 'chatbot-settings-setup-page-button',
	},
	where: {
		allPages: 'chatbot-settings-page-display-all',
		specificPages: 'chatbot-settings-page-display-specific',
	},
	visitsType: {
		radio: 'chatbot-settings-visits-type-radio',
	},
	visitsRange: {
		visitsRangeComponent: 'chatbot-settings-visits-range',
		from: 'chatbot-settings-visits-range-from',
		to: 'chatbot-settings-visits-range-to',
		operatorSelect: 'chatbot-settings-visits-range-op-select',
	},
	accountStatus: {
		radio: 'chatbot-settings-account-status-radio',
	},
	months: {
		monthsComponent: 'chatbot-settings-select-month',
		monthsTagGroup: 'chatbot-settings-months-tag-group',
		monthTag: 'chatbot-settings-month-tag',
	},
	days: {
		daysComponent: 'chatbot-settings-select-day',
		dayTagGroup: 'chatbot-settings-days-tag-group',
		dayTag: 'chatbot-settings-day-tag',
	},
	times: {
		timesComponent: 'chatbot-settings-select-time',
		from: 'chatbot-settings-select-time-from',
		to: 'chatbot-settings-select-time-to',
		addButton: 'chatbot-settings-select-time-add-condition',
	},
	behavior: {
		switchButton: 'chatbot-settings-behavior-switch',
	},
}
