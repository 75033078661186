import { OfficeHours } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE_OFFICE_HOURS = 'officehours'

const fetchOfficeHours = () => {
	return apiClient.get<OfficeHours>(`${API_RESOURCE_OFFICE_HOURS}`)
}

const updateOfficeHours = (data: Partial<OfficeHours>): Promise<OfficeHours> => {
	return apiClient.patch<OfficeHours>(`${API_RESOURCE_OFFICE_HOURS}`, data)
}

export const officeHoursApi = { fetchOfficeHours, updateOfficeHours }
