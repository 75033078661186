import Push from 'push.js'

import { ConfigurationService } from 'shared/services'

type NotificationParams = {
	tag: string
	title: string
	body?: string
	onClick?: () => void
}

const isNotificationSupported = () => 'Notification' in window
const hasNotificationGrantedPermission = () => Push.Permission.get() === Push.Permission.GRANTED
const hasNotificationDeniedPermission = () => Push.Permission.get() === Push.Permission.DENIED

export const shouldShowNotificationPermissionRequest = () =>
	isNotificationSupported() && !hasNotificationGrantedPermission() && !hasNotificationDeniedPermission()

export const requestNotificationPermission = (onGranted?: CallableFunction, onDenied?: CallableFunction) =>
	Push.Permission.request(onGranted, onDenied)

export const createNotification = ({ tag, title, body, onClick }: NotificationParams) => {
	if (!isNotificationSupported() || !hasNotificationGrantedPermission()) {
		return null
	}

	const configData = ConfigurationService.getData()
	if (!configData) return null

	const notificationPromise = Push.create(title, {
		tag,
		body,
		icon: `${configData.distUrl}/assets/logos/smartsupp-eyes.svg`,
		requireInteraction: true,
		onClick: () => {
			window.focus && window.focus()
			onClick && onClick()
			if (notificationPromise) {
				// eslint-disable-next-line promise/catch-or-return,promise/prefer-await-to-then
				notificationPromise.then((notification) => notification.close())
			}
		},
	})
	return notificationPromise
}

export const closeNotification = (tag: string) => Push.close(tag)

export const clearNotifications = () => Push.clear()
