import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch } from 'types'
import { useDebounce, useFlashMessage } from 'shared/hooks'
import { checkoutSelectors } from 'modules/checkout/slice'
import { calculateSubscription } from 'modules/subscription'

import { useSubscriptionCalculation } from './useSubscriptionCalculation'

type Props = {
	isDisabled?: boolean
}

const DEFAULT_WAIT_MS = 500

export const useDebouncedSubscriptionCalculate = ({ isDisabled = false }: Props) => {
	const dispatch: AppDispatch = useDispatch()
	const flashMessage = useFlashMessage()
	const data = useSelector(checkoutSelectors.getSubscriptionCalculateData)

	const debouncedCalculate = useDebounce(
		useCallback(async () => {
			const resultAction = await dispatch(calculateSubscription(data))
			if (calculateSubscription.rejected.match(resultAction)) {
				flashMessage.error('general.error')
			}
		}, [flashMessage, dispatch, data]),
		DEFAULT_WAIT_MS,
	)

	useEffect(() => {
		if (isDisabled) return
		debouncedCalculate()
	}, [debouncedCalculate, isDisabled])

	return useSubscriptionCalculation()
}
