import { useLayoutEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ChakraProvider } from '@chakra-ui/react'
import { unwrapResult } from '@reduxjs/toolkit'

import { FeatureFlag } from 'models'
import { AppDispatch } from 'types'
import { UserRole } from 'shared/models/User'
import { ConfigurationService, logException } from 'shared/services'
import { FlashMessageContainer, NotificationContainer } from 'shared/utils'
import { theme } from 'styles'
import { agentClientProvider, setFavicon } from 'utils'
import { accountSelectors, fetchAccount } from 'modules/account'
import { fetchAddonsList } from 'modules/addons'
import { fetchAffiliateStatus } from 'modules/affiliate'
import { fetchAgents } from 'modules/agents'
import { fetchAiChatbotsCounts } from 'modules/aiChatbots'
import {
	appSelectors,
	fetchFileUploadConfig,
	fetchInitData,
	initAppFailure,
	initAppSuccess,
	loadTranslations,
} from 'modules/app'
import { fetchChannels } from 'modules/channels'
import { fetchUnreadChats } from 'modules/chatsSlice'
import { fetchUserConsents, initConsents } from 'modules/consents'
import { updateDataLayerFromExternalEvents, updateDataLayerGTMContent } from 'modules/dataLayer'
import { fetchFeatureFlags, useFeatureFlag } from 'modules/features'
import { fetchGroups } from 'modules/groups'
import { fetchIntegrations } from 'modules/integrations'
import { initSoundNotifications } from 'modules/notifications'
import { fetchOnboardingData } from 'modules/onboarding'
import { fetchPackage, packageSelectors } from 'modules/package'
import { fetchPackagesWithCurrency } from 'modules/packages'
import { fetchPaymentMethod } from 'modules/paymentMethod'
import { fetchProperties } from 'modules/properties'
import { fetchShortcuts } from 'modules/shortcuts'
import { fetchStripeSubscription } from 'modules/stripe'
import { fetchTags } from 'modules/tags'
import { fetchAiUsage, fetchUsage } from 'modules/usage'
import { fetchUser, userSelectors } from 'modules/user/slice'

import { Loader } from 'shared/components/core/Loader'
import { RecaptchaProvider } from 'shared/components/core/Recaptcha/RecaptchaProvider'
import ErrorBoundary from 'shared/components/ErrorBoundary'

import { Routes } from './Routes'

const PARAMS_KEYCLOAK = ['code', 'state', 'session_state']

const removeKeycloakParams = () => {
	const { pathname, search } = window.location
	const params = new URLSearchParams(search)

	PARAMS_KEYCLOAK.map((param) => {
		const queryParam = params.get(param)
		if (queryParam) {
			params.delete(param)
		}

		return param
	})

	const path = [...params].length === 0 ? pathname : `${pathname}?${params}`

	window.history.replaceState(null, '', decodeURIComponent(path))
}

const initAuthTokens = () => {
	return ConfigurationService.getRestAccessToken()
}

const App = () => {
	const dispatch: AppDispatch = useDispatch()
	const hasEnabledAiChatbots = useFeatureFlag(FeatureFlag.AiChatbots)
	const isAppInitPending = useSelector(appSelectors.isAppInitPending)
	const locale = useSelector(appSelectors.getLocale)
	const timezone = useSelector(accountSelectors.getAccountTimezone)
	const isStripeAllowed = useSelector(packageSelectors.getIsAllowedStripePaymentGateway)
	const isUserAdmin = useSelector(userSelectors.isUserAdmin)

	useLayoutEffect(() => {
		const initAppData = async () => {
			try {
				// Load init data to setup config service
				const actionResult = await dispatch(fetchInitData())
				const initData = unwrapResult(actionResult)

				ConfigurationService.setData(initData)

				// Init sounds
				await dispatch(initSoundNotifications())

				// Init consents
				dispatch(
					initConsents({
						userConsents: initData.consentsUser,
						accountConsents: initData.consentsAccount,
					}),
				)

				// Fetch data
				const [user] = await Promise.all([
					dispatch(fetchUser()),
					dispatch(fetchUserConsents()),
					dispatch(fetchUnreadChats()),
					dispatch(loadTranslations()),
				])

				await Promise.all([
					dispatch(fetchAgents()),
					dispatch(fetchGroups()),
					dispatch(fetchShortcuts()),
					dispatch(fetchChannels()),
					dispatch(fetchAccount()),
					dispatch(fetchPackage()),
					dispatch(fetchUsage()),
					hasEnabledAiChatbots && dispatch(fetchAiUsage()),
					hasEnabledAiChatbots && dispatch(fetchAiChatbotsCounts()),
					dispatch(fetchFeatureFlags()),
				])

				await dispatch(fetchPackagesWithCurrency())

				dispatch(fetchTags())
				dispatch(fetchProperties())
				dispatch(fetchIntegrations({ accountId: `${initData.account.id}` }))

				if (user.role !== UserRole.Agent) {
					await dispatch(fetchOnboardingData())
					await dispatch(fetchPaymentMethod())
					dispatch(fetchAffiliateStatus())
				}

				// Update data layer
				dispatch(updateDataLayerGTMContent())
				dispatch(updateDataLayerFromExternalEvents())

				// Init Smartsupp agent client
				await agentClientProvider.initAgentClient(dispatch, user, initData.isBlocked)

				// Set favicon based on user status
				setFavicon(user.isRoot ? 'away' : user.status, !user.active)

				// Init list of Addons (IDs)
				dispatch(fetchAddonsList())

				dispatch(fetchFileUploadConfig())

				// Initialize app
				dispatch(initAppSuccess())
			} catch (error) {
				if (error instanceof Error) {
					logException(error)
				}
				dispatch(initAppFailure())
			}
		}

		const run = async () => {
			removeKeycloakParams()
			await initAuthTokens()
			initAppData()
		}
		run()
	}, [dispatch])

	useLayoutEffect(() => {
		if (isStripeAllowed && isUserAdmin) {
			dispatch(fetchStripeSubscription())
		}
	}, [dispatch, isStripeAllowed, isUserAdmin])

	if (isAppInitPending) return <Loader />

	const { recaptchaSiteKey } = ConfigurationService.getData()

	return (
		<IntlProvider locale={locale} key={locale} timeZone={timezone}>
			<RecaptchaProvider siteKey={recaptchaSiteKey}>
				<ChakraProvider theme={theme}>
					<ErrorBoundary>
						<Routes />
						<FlashMessageContainer />
						<NotificationContainer />
					</ErrorBoundary>
				</ChakraProvider>
			</RecaptchaProvider>
		</IntlProvider>
	)
}

export default App
