import { SelectedChatbotSimple, SelectedChatbotSimpleWithStatistics } from 'models'
import { AutoMessagesStats } from 'models/AutoMessage'
import { Dictionary } from 'types'

export const getSimpleAutoMessagesWithStatistics = (
	chatbots: SelectedChatbotSimple[],
	stats: Dictionary<AutoMessagesStats> | null,
): Dictionary<SelectedChatbotSimpleWithStatistics> => {
	const chatbotsWithStatistics: Dictionary<SelectedChatbotSimpleWithStatistics> = {}

	chatbots.forEach((chatbot) => {
		const triggered = stats && stats[chatbot.id] ? stats[chatbot.id].triggeredUnique.value : 0
		const conversations = stats && stats[chatbot.id] ? stats[chatbot.id].reactions.value : 0
		chatbotsWithStatistics[chatbot.id] = {
			...chatbot,
			triggered,
			conversations,
			ratio: triggered ? (conversations / triggered) * 100 : -1,
		}
	})
	return chatbotsWithStatistics
}
