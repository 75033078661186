import { ReactNode } from 'react'
import { TooltipProps as ChakraTooltipProps } from '@chakra-ui/tooltip'
import { mdiHelpCircleOutline } from '@mdi/js'

import { Flex, FlexProps, Icon, Tooltip } from 'core'

type Props = {
	content: ReactNode
	placement?: ChakraTooltipProps['placement']
	interactive?: boolean
} & Omit<FlexProps, 'content'>

const IconHelpTooltip = ({ content, placement = 'top', interactive = false, ...rest }: Props) => {
	return (
		<Flex ml={1} color="gray.500" fontSize={2} {...rest}>
			<Tooltip content={content} placement={placement} interactive={interactive}>
				<Icon path={mdiHelpCircleOutline} tabIndex={-1} />
			</Tooltip>
		</Flex>
	)
}

export default IconHelpTooltip
