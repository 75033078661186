import { ComponentMultiStyleConfig, createMultiStyleConfigHelpers, ThemingProps } from '@chakra-ui/react'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(['container', 'cardContent'])

const baseStyle = {
	container: {
		transition: 'all 200ms',
	},
}

const sizes: ComponentMultiStyleConfig['sizes'] = {
	sm: {
		container: {
			borderRadius: 1,
		},
		cardContent: {
			p: 1,
		},
	},
	md: {
		container: {
			borderRadius: 1,
		},
		cardContent: {
			p: 2,
		},
	},
	lg: {
		container: {
			borderRadius: 2,
		},
		cardContent: {
			p: 3,
		},
	},
}

const variantOutline = (props: ThemingProps) => {
	const { colorScheme: c } = props
	const isGray = c === 'gray'
	const borderColor = isGray ? 'gray.300' : `${c}.500`

	return {
		container: {
			borderWidth: 1,
			borderColor,
			backgroundColor: 'white',
		},
	}
}

const variantSolid = (props: ThemingProps) => {
	const { colorScheme: c } = props
	const isGray = c === 'gray'
	const bgColor = isGray ? 'gray.100' : `${c}.500`

	return {
		container: {
			color: isGray ? 'gray.700' : 'white',
			borderWidth: 1,
			borderColor: bgColor,
			backgroundColor: bgColor,
		},
	}
}

const variants = {
	outline: variantOutline,
	solid: variantSolid,
}

const defaultProps = {
	size: 'md',
	variant: 'outline' as 'outline' | 'solid',
	colorScheme: 'gray',
}

export const Card = defineMultiStyleConfig({
	baseStyle,
	defaultProps,
	sizes,
	variants,
})
