import { ResponseOk } from 'models'
import { FilesUploadInitResponse } from 'shared/models/AppConfig'
import { apiClient } from 'shared/utils'

import { AssignAgentParams, JoinChatParams, SendChatTranscriptParams } from './types'

const API_RESOURCE = 'v2/conversations'
const API_EXPORT_RESOURCE = 'export/conversation'

const uploadFile = (chatId: string) => {
	return apiClient.post<FilesUploadInitResponse>(`${API_RESOURCE}/${chatId}/upload-init`)
}

const exportConversation = (chatId: string, timezone: string) => {
	return apiClient.post(`${API_EXPORT_RESOURCE}`, { conversationId: chatId, timezone })
}

const joinChat = (params: JoinChatParams) => {
	return apiClient.patch<ResponseOk>(`${API_RESOURCE}/${params.chatId}/join?required=${params.canForceJoin}`)
}

const leaveChat = (chatId: string) => {
	return apiClient.patch(`${API_RESOURCE}/${chatId}/leave`)
}

const assignAgent = (params: AssignAgentParams) => {
	return apiClient.patch(`${API_RESOURCE}/${params.chatId}/assign`, { id: `${params.agentId}` })
}

const unassignAgent = (params: AssignAgentParams) => {
	return apiClient.patch(`${API_RESOURCE}/${params.chatId}/unassign`, { id: `${params.agentId}` })
}

const sendChatTranscriptToEmail = (params: SendChatTranscriptParams) => {
	return apiClient.post(
		`${API_RESOURCE}/${params.chatId}/transcript`,
		{ email: params.email, lang: params.lang },
		params.headers,
	)
}

const resolveChat = (id: string) => {
	return apiClient.patch(`${API_RESOURCE}/${id}/close`)
}

export const chatDetailApi = {
	uploadFile,
	exportConversation,
	joinChat,
	leaveChat,
	assignAgent,
	unassignAgent,
	sendChatTranscriptToEmail,
	resolveChat,
}
