import { MetricOptions, MetricsType, StatisticsInterval } from 'shared/models/Statistics'
import { StatisticsFilter } from 'modules/statisticsFilter'

export enum Ratings {
	Happy = 'happy',
	Ok = 'ok',
	Bad = 'bad',
}

export enum RatingsValue {
	Positive = 80,
	Ok = 50,
}

export interface SummaryChartData {
	date: Date
	shortName: string
	longName: string
	key: string
	count: number
	value: number
}

export type Bucket = {
	key: string
	count: number
	value: number
}

export type MetricsData = {
	buckets: Bucket[]
	count: number
	name: string
	value: number
}

export type Record = {
	date: Date
	longName: string
	shortName: string
	value?: number
}

export type RatingResponse = {
	[Ratings.Happy]: MetricsData
	[Ratings.Ok]: MetricsData
	[Ratings.Bad]: MetricsData
}

export type RatingRequest = {
	name: string
	options: {
		timezone: string
		filters: {
			ratingValue: number[]
			groupId?: string
		}
		range?: {
			from: string
			to: string
		}
		aggs: { type: string; interval: string; format: string }[]
		query?: MetricOptions.Query[]
	}
}

export type RatingRequestBody = {
	[Ratings.Happy]: RatingRequest
	[Ratings.Ok]: RatingRequest
	[Ratings.Bad]: RatingRequest
}

export type RatingsChartData = {
	key: string
	[Ratings.Happy]: number
	[Ratings.Ok]: number
	[Ratings.Bad]: number
	values: {
		[Ratings.Happy]: number
		[Ratings.Ok]: number
		[Ratings.Bad]: number
	}
}

export type NewActivityValuesType = {
	[key: string]: number | string
}

export type OnlineActivityBucketSession = {
	[key: string]: number
}

export type MetricsRequestData = {
	items: { metricsType: MetricsType; interval: StatisticsInterval }[]
	timezone: string
	filter: StatisticsFilter
	withTrend?: boolean
}

export enum ChartType {
	NewConversation = 'newConversationChart',
	ClosedConversation = 'closedConversationChart',
	ServedConversation = 'servedConversationChart',
	ResponseTime = 'responseTimeChart',
	Rating = 'ratingChart',
	RatingPercentage = 'ratingPercentageChart',
	OnlineActivity = 'onlineActivityChart',
	Tags = 'tagsChart',
	NewContacts = 'newContactsChart',
}

export const MetricsByChartType = {
	[ChartType.NewConversation]: [MetricsType.NewConversation],
	[ChartType.ClosedConversation]: [MetricsType.ClosedConversation],
	[ChartType.ServedConversation]: [MetricsType.ServedConversation],
	[ChartType.ResponseTime]: [MetricsType.AvgFirstResponseTime, MetricsType.AvgResponseTime],
	[ChartType.OnlineActivity]: [MetricsType.OnlineTime, MetricsType.Activity],
	[ChartType.Rating]: [MetricsType.ConversationRatings],
	[ChartType.RatingPercentage]: [MetricsType.ConversationRatingsPercent],
	[ChartType.NewContacts]: [MetricsType.ContactAcquired],
}
