const baseStyleButton = {
	_hover: {
		bg: 'white',
	},
}

const baseStyle = {
	button: baseStyleButton,
}

export const Accordion = {
	baseStyle,
}
