import { WidgetConfig } from 'models'
import { WidgetAppearanceTranslations, WidgetAuthFormTranslations } from 'modules/widgetTranslations'

export type WidgetLanguageType = {
	id: string
}

export interface WidgetLanguageOption {
	label: string
	value: string
}

export interface GroupedWidgetLanguageOption {
	label: string
	options: WidgetLanguageOption[]
}

export type WidgetOptionsData = Pick<
	WidgetConfig,
	'ratingEnabled' | 'lang' | 'hideWidget' | 'hideOfflineChat' | 'consentModeEnabled' | 'mobilePopupsEnabled'
>

export type WidgetAuthFormData = Pick<
	WidgetConfig,
	| 'requireLogin'
	| 'emailControl'
	| 'nameControl'
	| 'numberControl'
	| 'privacyNoticeCheckRequired'
	| 'privacyNoticeUrl'
	| 'privacyNoticeEnabled'
	| 'groupSelectEnabled'
>

export type WidgetAuthFormMergedData = WidgetAuthFormData & WidgetAuthFormTranslations

export type WidgetAppearanceFormData = Pick<
	WidgetConfig,
	'color' | 'color2' | 'colorGradient' | 'orientation' | 'buttonStyle'
>
export type WidgetAppearanceFormMergedData = WidgetAppearanceFormData & WidgetAppearanceTranslations

export enum WidgetPreviewScenario {
	Default = 'default',
	Rating = 'rating',
	AuthForm = 'auth-form',
}
