import { DashboardState } from 'types'

export const getVisitorId = (
	state: DashboardState,
	props: { match?: { params: { visitorId?: string } }; visitor?: { id: string }; visitorId?: string | null },
) => {
	const { visitor, visitorId, match } = props
	if (visitorId) return visitorId
	if (match && match.params && match.params.visitorId) return match.params.visitorId
	if (visitor) return visitor.id
	return null
}

export const getChatId = (
	state: DashboardState,
	props: { match?: { params: { chatId?: string } }; chat?: { id: string }; chatId?: string },
) => {
	const { chat, chatId, match } = props
	if (chatId) return chatId
	if (match && match.params && match.params.chatId) return match.params.chatId
	if (chat) return chat.id
	return null
}

export const getContactId = (state: DashboardState, props: { contactId?: string | null }) => {
	const { contactId } = props
	if (contactId) return contactId
	return null
}
