import WebsocketVisitorClient from '@smartsupp/websocket-client-visitor'

import { ConfigurationService } from 'shared/services'

export const createVisitorClient = () => {
	const accountData = ConfigurationService.getAccountData()
	const connectionData = ConfigurationService.getConnectionData()
	const identityData = ConfigurationService.getIdentityData()

	const time = Date.now()
	const protocol = connectionData?.protocol ? `${connectionData.protocol}:` : ''

	const options: WebsocketVisitorClient.Options = {
		data: {
			id: `sim_${identityData?.id}_${time}`,
			key: accountData.key,
			name: `Simulator ${time}`,
			triggerable: false,
			variables: {
				simulated: true,
			},
		},
		connection: {
			url: `${protocol}//${connectionData?.url}`,
			options: {
				path: '/socket',
				forceNew: true,
				transports: ['websocket'],
			},
		},
	}

	return new WebsocketVisitorClient(options)
}
