import { BotsApi, ChatbotGroupType, ChatbotSimple } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'chatbot/bots'

const getBotsList = ({ groupType }: BotsApi.ListQuery) => {
	return apiClient.get<ChatbotSimple[]>(`${API_RESOURCE}?groupType=${groupType}`)
}

const getBotsCounts = ({ groupType, ignoreBasic = true }: BotsApi.CountsQuery) => {
	return apiClient.get<BotsApi.CountsResponse>(
		`${API_RESOURCE}/counts?groupType=${groupType}&ignoreBasic=${ignoreBasic}`,
	)
}

const getAllBotsList = () => {
	return apiClient.get<ChatbotSimple[]>(API_RESOURCE)
}

const unpublishChatbots = (groupType: ChatbotGroupType) => {
	return apiClient.post<{ ok: boolean }>(`${API_RESOURCE}/unpublish-all`, { groupType })
}

export const automationsApi = {
	getBotsList,
	getBotsCounts,
	getAllBotsList,
	unpublishChatbots,
}
