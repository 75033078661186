import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiError } from 'shared/types'
import { DashboardState } from 'types'
import {
	Properties,
	Property,
	PropertyCreateBody,
	PropertySelectOptionType,
	PropertyUpdateBody,
} from 'shared/models/Property'
import { normalize } from 'utils'
import { contactsSelectors } from 'modules/contacts'

import { propertiesApi } from './api'
import { getPreprocessProperties } from './utils'

export type PropertiesRootState = Pick<DashboardState, 'properties'>

const propertiesAdapter = createEntityAdapter<Property>()

export const initialState = propertiesAdapter.getInitialState({
	isFetching: false,
	isCreating: false,
	isUpdating: false,
	filterValue: '',
	isCreatePropertyModalOpen: false,
	isMenuOpen: false,
	propertyDraftKey: null as null | string,
})

export const fetchProperties = createAsyncThunk('PROPERTIES/FETCH', () => {
	return propertiesApi.fetchProperties()
})

export const createProperty = createAsyncThunk<Property, PropertyCreateBody, { rejectValue: ApiError }>(
	'PROPERTIES/CREATE',
	async (data, { rejectWithValue }) => {
		try {
			return await propertiesApi.createProperty(data)
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

export const updateProperty = createAsyncThunk<Property, PropertyUpdateBody, { rejectValue: ApiError }>(
	'PROPERTIES/UPDATE',
	async ({ id, updatedName }, { rejectWithValue }) => {
		try {
			return await propertiesApi.updateProperty(id, { name: updatedName })
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

export const deleteProperty = createAsyncThunk('PROPERTIES/DELETE', (id: string) => {
	propertiesApi.deleteProperty(id)
})

const slice = createSlice({
	name: 'properties',
	initialState,
	reducers: {
		setFilterValue(state, { payload }: PayloadAction<string>) {
			state.filterValue = payload
		},
		resetFilterValue(state) {
			state.filterValue = ''
		},
		createPropertiesModalOpen(state) {
			state.isCreatePropertyModalOpen = true
		},
		createPropertiesModalClose(state) {
			state.isCreatePropertyModalOpen = false
		},
		setPropertyDraft(state, { payload }: PayloadAction<string>) {
			state.propertyDraftKey = payload
		},
		removePropertyDraft(state) {
			state.propertyDraftKey = null
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchProperties.fulfilled, (state, { payload }) => {
				propertiesAdapter.setAll(state, payload)
				state.isFetching = false
			})
			.addCase(fetchProperties.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchProperties.rejected, (state) => {
				state.isFetching = false
			})

		builder
			.addCase(createProperty.fulfilled, (state, { payload }) => {
				propertiesAdapter.addOne(state, payload)
				state.isCreating = false
			})
			.addCase(createProperty.pending, (state) => {
				state.isCreating = true
			})
			.addCase(createProperty.rejected, (state) => {
				state.isCreating = false
			})

		builder
			.addCase(updateProperty.fulfilled, (state, { payload }) => {
				propertiesAdapter.updateOne(state, { id: payload.id, changes: payload })
				state.isUpdating = false
			})
			.addCase(updateProperty.pending, (state) => {
				state.isUpdating = true
			})
			.addCase(updateProperty.rejected, (state) => {
				state.isUpdating = false
			})

		builder.addCase(deleteProperty.fulfilled, (state, { meta }) => {
			propertiesAdapter.removeOne(state, meta.arg)
		})
	},
})

const entitySelectors = propertiesAdapter.getSelectors<PropertiesRootState>((state) => state.properties)
const getProperties = (state: PropertiesRootState): Property[] => entitySelectors.selectAll(state)
const getPropertyId = (state: PropertiesRootState, propertyId: string) => propertyId
const getIsFetching = (state: PropertiesRootState) => state.properties.isFetching
const getIsUpdating = (state: PropertiesRootState) => state.properties.isUpdating
const getIsCreating = (state: PropertiesRootState) => state.properties.isCreating
const getFilterValue = (state: PropertiesRootState) => state.properties.filterValue
const getIsCreatePropertyModalOpen = (state: PropertiesRootState) => state.properties.isCreatePropertyModalOpen
const getPropertyDraft = (state: PropertiesRootState) => state.properties.propertyDraftKey

const makeGetPropertyById = () => {
	return createSelector([getProperties, getPropertyId], (properties, propertyId): Property => {
		const normalizedProperties = normalize('id', properties)
		return normalizedProperties[propertyId]
	})
}

const getFilteredProperties = createSelector([getProperties], (properties): Property[] => {
	return properties.filter((property) => !property.archived)
})

const getSortedAndFilteredProperties = createSelector([getFilteredProperties], (filteredProperties): Property[] => {
	return filteredProperties.sort((a: Property, b: Property) => a.name.localeCompare(b.name))
})

const getPropertiesOptions = createSelector(
	[getSortedAndFilteredProperties],
	(properties): PropertySelectOptionType[] => {
		return properties.map((property) => ({ value: property.key, label: property.name }))
	},
)

const getFilteredPropertiesOptions = createSelector(
	[getPropertiesOptions, getFilterValue],
	(properties, filter): PropertySelectOptionType[] => {
		return properties.filter(
			(property) => property.label.toLowerCase().includes(filter) || property.value.toLowerCase().includes(filter),
		)
	},
)

const getHasProperties = createSelector([getFilteredProperties], (filteredProperties): boolean => {
	return filteredProperties.length > 0
})

const getIsUpdatingOrCreatingProperty = createSelector(
	[getIsUpdating, getIsCreating],
	(isUpdatingTag, isCreatingTag): boolean => {
		return isUpdatingTag || isCreatingTag
	},
)

const getPropertyKeyToName = (key: string) => {
	return createSelector([getFilteredProperties], (properties): string | null => {
		const foundProperty = properties.find((property) => property.key === key)
		if (!foundProperty) return null
		return foundProperty?.name
	})
}

const getPropertyKeyToType = (key: string) => {
	return createSelector([getFilteredProperties], (properties): string | null => {
		const foundProperty = properties.find((property) => property.key === key)
		if (!foundProperty) return null
		return foundProperty?.type
	})
}

const getFilteredContactProperties = createSelector(
	[getFilteredProperties, contactsSelectors.getSortedContactProperties],
	(filteredProperties, contactProperties): Properties | null => {
		if (!contactProperties) return null
		const filteredProps: Properties = {}

		Object.keys(contactProperties).forEach((key) => {
			const property = filteredProperties.find((prop) => prop.key === key)
			if (property && !property.archived) {
				filteredProps[key] = contactProperties[key]
			}
		})

		return filteredProps
	},
)

const getSortedPropertiesByName = createSelector(
	[getFilteredProperties, contactsSelectors.getSelectedContact],
	(filteredProperties, contact) => {
		if (!contact?.properties) return null
		const { properties } = contact
		const preprocessedProperties = getPreprocessProperties(filteredProperties)
		const filteredByArchived = preprocessedProperties
			.map(([_, key]) => [key, properties[key]])
			.filter(([_, value]) => value !== undefined)
		return Object.fromEntries(filteredByArchived)
	},
)

const getPropertyByKey = (key: string) => {
	return createSelector([getProperties], (properties): Property | null => {
		const foundProperty = properties.find((property) => property.key === key)
		return foundProperty || null
	})
}

const getIsPropertyArchived = (key: string) => {
	return createSelector([getFilteredProperties], (properties): boolean => {
		const propertiesKeys = properties.map((prop) => prop.key)
		if (propertiesKeys.includes(key)) return false
		return true
	})
}

const getSortedPropertiesToCopy = createSelector(
	[getFilteredProperties, contactsSelectors.getSelectedContact],
	(filteredProperties, contact): Properties | null => {
		if (!contact?.properties) return null
		const { properties } = contact
		const preprocessedProperties = getPreprocessProperties(filteredProperties)
		const filteredByArchived = preprocessedProperties
			.map(([propName, key]) => [propName, properties[key]])
			.filter(([_, value]) => value !== undefined)
		return Object.fromEntries(filteredByArchived)
	},
)

const getPropertiesKeys = createSelector([getSortedAndFilteredProperties], (properties): Set<string> => {
	return new Set(properties.map((property) => property.key))
})

const { reducer, actions } = slice
export const {
	setFilterValue,
	resetFilterValue,
	createPropertiesModalClose,
	createPropertiesModalOpen,
	setPropertyDraft,
	removePropertyDraft,
} = actions
export default reducer

export const propertiesSelectors = {
	getIsFetching,
	getProperties,
	getHasProperties,
	getSortedAndFilteredProperties,
	getPropertyId,
	makeGetPropertyById,
	getIsUpdating,
	getIsCreating,
	getIsUpdatingOrCreatingProperty,
	getPropertiesOptions,
	getFilterValue,
	getFilteredPropertiesOptions,
	getIsCreatePropertyModalOpen,
	getPropertyKeyToName,
	getPropertyKeyToType,
	getFilteredContactProperties,
	getPropertyDraft,
	getSortedPropertiesByName,
	getPropertyByKey,
	getIsPropertyArchived,
	getSortedPropertiesToCopy,
	getPropertiesKeys,
}
