import { Chatbot } from 'models'
import { apiClient } from 'shared/utils'
import { API_RESOURCE } from 'modules/chatbotDetail'

import { ChatbotTriggerUpdateParams } from './types'

const updateChatbotTrigger = ({ botId, changes }: ChatbotTriggerUpdateParams) => {
	return apiClient.patch<Chatbot['trigger']>(`${API_RESOURCE}/${botId}/trigger`, changes)
}

const getChatbotTrigger = (id: string) => {
	return apiClient.get<Chatbot['trigger']>(`${API_RESOURCE}/${id}/trigger`)
}

export const chatbotSettingsApi = {
	updateChatbotTrigger,
	getChatbotTrigger,
}
