import { batch } from 'react-redux'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ChatsSearchRequest, ChatsTab, ClosedChatsFilterType, FeatureUsageName, OpenChatsFilterType } from 'models'
import { AppThunkAction, DashboardState } from 'types'
import { routes } from 'configuration/routes'
import { navigateTo } from 'utils'
import { unselectChat } from 'modules/chatDetail'
import { chatsSelectors } from 'modules/chats'
import { fetchClosedChats, setActiveChatsTab, setActiveTab } from 'modules/chatsSlice'
import { logFeatureUsage } from 'modules/features'

import { defaultAdvancedFilter } from './constants'
import { ChatsFilterAdvanced } from './types'
import {
	createClosedChatsSearchRequestFilters,
	createClosedChatsSearchRequestQuery,
	createQueryFilter,
	createResetFilter,
	getAdvancedFilter,
	initDefaultFilter,
	isAdvancedFilterActive,
	loadClosedChatsFilter,
	loadOpenChatsFilter,
	saveAdvancedFilter,
	saveClosedChatsFilter,
	saveOpenChatsFilter,
	switchMoreAgentsFilter,
	switchMoreAutoMessagesFilter,
	switcMoreChatbotsFilter,
} from './utils'

export type ChatsFilterRootState = Pick<DashboardState, 'chatsFilter'>

const initializedDefaultFilter = initDefaultFilter()

export const initialState = {
	filter: initializedDefaultFilter,
	tempFilter: initializedDefaultFilter,
	isFilterVisible: false,
	openChatsFilter: loadOpenChatsFilter(),
	closedChatsFilter: loadClosedChatsFilter(),
	checkedChatIds: [] as string[],
	isSelectionEnabled: false,
}

const saveFilterToLocalStorage = (): AppThunkAction => (_, getState) => {
	const filter = getClosedChatsTempFilter(getState())
	saveAdvancedFilter(filter.advanced)
}

export const checkAllChats = (): AppThunkAction => (dispatch, getState) => {
	const closedChats = chatsSelectors.getClosedChats(getState())
	const ids = Object.values(closedChats).map((c) => c.id)
	dispatch(setCheckedChats({ ids }))
}

export const filterOpenChats =
	(type: OpenChatsFilterType): AppThunkAction =>
	async (dispatch) => {
		saveOpenChatsFilter(type)
		dispatch(actions.setOpenChatsFilter(type))
	}

export const filterClosedChats =
	(type: ClosedChatsFilterType): AppThunkAction =>
	async (dispatch) => {
		saveClosedChatsFilter(type)
		dispatch(actions.setClosedChatsFilter(type))
	}

export const applyChatFilter = (): AppThunkAction => (dispatch) => {
	// Save filter to local storage
	dispatch(saveFilterToLocalStorage())

	batch(() => {
		dispatch(logFeatureUsage(FeatureUsageName.ResolvedLastFilterUsed))
		dispatch(applyFilter())
		dispatch(unselectChat())
		dispatch(fetchClosedChats())
	})
}

const applyChatFilterReset = (): AppThunkAction => (dispatch) => {
	// Save filter to local storage
	dispatch(saveFilterToLocalStorage())

	batch(() => {
		dispatch(unselectChat())
		dispatch(fetchClosedChats())
	})
}

export const presetChatFilterAndShowConversations =
	(filter: Partial<ChatsFilterAdvanced>): AppThunkAction =>
	(dispatch) => {
		batch(() => {
			dispatch(resetFilter())
			dispatch(setAdvancedFilter({ filter }))
			dispatch(setActiveChatsTab(ChatsTab.Closed))
			dispatch(unselectChat())
		})
		navigateTo(routes.conversations.path)
	}

export const setTempAdvancedFilterAndApply =
	(filter: Partial<ChatsFilterAdvanced>): AppThunkAction =>
	(dispatch) => {
		batch(() => {
			dispatch(setTempAdvancedFilter({ filter }))
			dispatch(applyChatFilter())
		})
	}

export const resetChatFilter = (): AppThunkAction => (dispatch) => {
	batch(() => {
		dispatch(resetFilter())
		dispatch(applyChatFilterReset())
	})
}

export const setChatDateFilterAndApply =
	(filter: Pick<ChatsFilterAdvanced, 'dateFrom' | 'dateTo'>): AppThunkAction =>
	(dispatch) => {
		batch(() => {
			dispatch(setTempAdvancedFilter({ filter }))
			dispatch(applyChatFilter())
		})
	}

export const seeFilteredChatbotConversations =
	(id: string): AppThunkAction =>
	(dispatch) => {
		navigateTo(routes.conversations.path)
		dispatch(resetFilter())
		dispatch(setActiveTab({ tab: ChatsTab.Closed }))
		dispatch(filterClosedChats(ClosedChatsFilterType.Chatbots))
		dispatch(setTempAdvancedFilterAndApply({ botIds: [id] }))
	}

export const partiallyResetAndSaveFilter = (): AppThunkAction => (dispatch) => {
	dispatch(partiallyResetFilter())
	dispatch(saveFilterToLocalStorage())
}

export const amendFilter =
	(updatedFilter: ChatsFilterAdvanced): AppThunkAction =>
	(dispatch) => {
		dispatch(setAdvancedFilter({ filter: updatedFilter }))
		dispatch(setTempAdvancedFilter({ filter: updatedFilter }))
		saveAdvancedFilter(updatedFilter)
	}

export const amendBotsFilter =
	(botId: string): AppThunkAction =>
	(dispatch, getState) => {
		const filter = getClosedChatsTempFilter(getState())
		const amendedBotIds = filter.advanced.botIds.filter((id) => id !== botId)
		const amendedFilter: ChatsFilterAdvanced = { ...filter.advanced, botIds: amendedBotIds }
		dispatch(amendFilter(amendedFilter))
	}

export const amendAgentsFilter =
	(agentId: number): AppThunkAction =>
	(dispatch, getState) => {
		const filter = getClosedChatsTempFilter(getState())
		const amendedAgentIds = filter.advanced.agentIds.filter((id) => id !== agentId)
		const amendedFilter: ChatsFilterAdvanced = { ...filter.advanced, agentIds: amendedAgentIds }
		dispatch(amendFilter(amendedFilter))
	}

export const amendGroupFilter =
	(value: string): AppThunkAction =>
	(dispatch, getState) => {
		const filter = getClosedChatsTempFilter(getState())
		const { groupId } = filter.advanced
		if (groupId === value) {
			const amendedFilter: ChatsFilterAdvanced = { ...filter.advanced, groupId: '' }
			dispatch(amendFilter(amendedFilter))
		}
	}

export const amendTagsFilter =
	(tagId: string): AppThunkAction =>
	(dispatch, getState) => {
		const filter = getClosedChatsTempFilter(getState())
		const amendedTags = filter.advanced.tags.filter((id) => id !== tagId)
		const amendedFilter: ChatsFilterAdvanced = { ...filter.advanced, tags: amendedTags }
		dispatch(amendFilter(amendedFilter))
	}

const slice = createSlice({
	name: 'chatsFilter',
	initialState,
	reducers: {
		setOpenChatsFilter: (state, { payload }: PayloadAction<OpenChatsFilterType>) => {
			state.openChatsFilter = payload
		},
		setClosedChatsFilter: (state, { payload }: PayloadAction<ClosedChatsFilterType>) => {
			state.closedChatsFilter = payload
		},
		showFilter: (state) => {
			state.isFilterVisible = true
		},
		hideFilter: (state) => {
			state.isFilterVisible = false
		},
		setAdvancedFilter: (state, { payload }: PayloadAction<{ filter: Partial<ChatsFilterAdvanced> }>) => {
			state.filter = {
				...getAdvancedFilter(state.filter, payload.filter),
				isAdvancedFilterActive: true,
			}
		},
		setTempAdvancedFilter: (state, { payload }: PayloadAction<{ filter: Partial<ChatsFilterAdvanced> }>) => {
			state.tempFilter = getAdvancedFilter(state.tempFilter, payload.filter)
		},
		setQueryFilter: (state, { payload }: PayloadAction<{ query: string }>) => {
			state.filter = createQueryFilter(state.filter, payload.query)
			state.tempFilter = createQueryFilter(state.tempFilter, payload.query)
		},
		toggleMoreAgentsFilter: (state) => {
			state.filter = switchMoreAgentsFilter(state.filter)
			state.tempFilter = switchMoreAgentsFilter(state.tempFilter)
		},
		toggleMoreChatbotsFilter: (state) => {
			state.filter = switcMoreChatbotsFilter(state.filter)
			state.tempFilter = switcMoreChatbotsFilter(state.tempFilter)
		},
		toggleMoreAutoMessagesFilter: (state) => {
			state.filter = switchMoreAutoMessagesFilter(state.filter)
			state.tempFilter = switchMoreAutoMessagesFilter(state.tempFilter)
		},
		applyFilter: (state) => {
			state.isFilterVisible = false
			state.filter = {
				...state.tempFilter,
				isAdvancedFilterActive: isAdvancedFilterActive(state.tempFilter.advanced),
			}
		},
		resetFilter: (state) => {
			state.isFilterVisible = false
			state.filter = createResetFilter(state.filter)
			state.tempFilter = createResetFilter(state.tempFilter)
		},
		revertTempFilter: (state) => {
			state.tempFilter = state.filter
		},
		setRequestFilterParams: (state, { payload }: PayloadAction<{ requestFilterParams: ChatsSearchRequest }>) => {
			state.filter.requestFilterParams = payload.requestFilterParams
		},
		setLastResponseAfter: (state, { payload }: PayloadAction<number[]>) => {
			state.filter.lastResponseAfter = payload
		},
		partiallyResetFilter: (state) => {
			const { ratings, agentIds, pageUrl } = defaultAdvancedFilter
			const defaultFilters = {
				ratings,
				agentIds,
				pageUrl,
			}
			state.tempFilter = getAdvancedFilter(state.tempFilter, defaultFilters)
			state.filter = getAdvancedFilter(state.filter, defaultFilters)
		},
		// Chats selecting
		checkChat: (state, { payload }: PayloadAction<{ chatId: string }>) => {
			state.checkedChatIds = [...state.checkedChatIds, payload.chatId]
		},
		uncheckChat: (state, { payload }: PayloadAction<{ chatId: string }>) => {
			state.checkedChatIds = state.checkedChatIds.filter((chatId) => chatId !== payload.chatId)
			if (state.checkedChatIds.length === 0) {
				state.isSelectionEnabled = false
			}
		},
		setCheckedChats: (state, { payload }: PayloadAction<{ ids: string[] }>) => {
			state.checkedChatIds = payload.ids
		},
		uncheckAllChats: (state) => {
			state.checkedChatIds = []
		},
		enableChatsSelection: (state, { payload }: PayloadAction<boolean>) => {
			state.isSelectionEnabled = payload
		},
	},
})

const { actions, reducer } = slice

export const {
	showFilter,
	hideFilter,
	setAdvancedFilter,
	setTempAdvancedFilter,
	setQueryFilter,
	toggleMoreAgentsFilter,
	applyFilter,
	resetFilter,
	revertTempFilter,
	setRequestFilterParams,
	setLastResponseAfter,
	toggleMoreChatbotsFilter,
	toggleMoreAutoMessagesFilter,
	setClosedChatsFilter,
	partiallyResetFilter,
	checkChat,
	uncheckChat,
	setCheckedChats,
	uncheckAllChats,
	enableChatsSelection,
} = actions
export default reducer

const getOpenChatsFilter = (state: ChatsFilterRootState) => state.chatsFilter.openChatsFilter
const getClosedChatsFilter = (state: ChatsFilterRootState) => state.chatsFilter.filter
const getClosedChatsTempFilter = (state: ChatsFilterRootState) => state.chatsFilter.tempFilter
const isFilterVisible = (state: ChatsFilterRootState) => state.chatsFilter.isFilterVisible
const isChatSelectionEnabled = (state: ChatsFilterRootState) => state.chatsFilter.isSelectionEnabled
const getCheckedChatsIds = (state: ChatsFilterRootState) => state.chatsFilter.checkedChatIds
const getClosedChatsFilterByType = (state: ChatsFilterRootState) => state.chatsFilter.closedChatsFilter

const getClosedChatsDateTempFilter = createSelector([getClosedChatsTempFilter], (filter) => {
	const { dateFrom, dateTo } = filter.advanced
	return { dateFrom, dateTo }
})

const getClosedChatsSearchRequestFilters = createSelector([getClosedChatsFilter], (filter) => {
	return createClosedChatsSearchRequestFilters(filter.advanced)
})

const getIsChatbotChatsContext = createSelector([getClosedChatsFilterByType], (closedChatsFilterByType) => {
	return closedChatsFilterByType === ClosedChatsFilterType.Chatbots
})

const getClosedChatsSearchRequestQuery = createSelector(
	[getClosedChatsFilter, getIsChatbotChatsContext],
	(filter, isChatbotChatsContext) => {
		return createClosedChatsSearchRequestQuery(filter.advanced, isChatbotChatsContext)
	},
)

const getClosedChatsFilterBotIds = createSelector([getClosedChatsFilter], (filter) => {
	return filter.advanced.botIds
})

export const chatsFilterSelectors = {
	getOpenChatsFilter,
	getClosedChatsFilter,
	getClosedChatsTempFilter,
	isFilterVisible,
	getClosedChatsDateTempFilter,
	getClosedChatsSearchRequestQuery,
	getClosedChatsSearchRequestFilters,
	getClosedChatsFilterByType,
	getIsChatbotChatsContext,
	getClosedChatsFilterBotIds,
	isChatSelectionEnabled,
	getCheckedChatsIds,
}
