import { useSelector } from 'react-redux'

import { ChatbotActionType } from 'shared/models/Chatbot'
import { chatbotSelectors } from 'modules/chatbots'
import { upsellSelectors } from 'modules/upsell'

import { chatbotDetailSelectors } from './slice'

interface ChatbotDetailErrorData {
	hasChatbotDetailError: boolean
	allowPublish: boolean
}

const DEFAULT_DATA = {
	hasChatbotDetailError: false,
	allowPublish: true,
}

export const useChatbotDetailError = (): ChatbotDetailErrorData => {
	const chatbot = useSelector(chatbotDetailSelectors.getSelectedChatbot)
	const hasEnabledLeadgen = useSelector(upsellSelectors.hasEnabledChatbotLeadgen)
	const botTransferIds = useSelector(chatbotDetailSelectors.getChatbotTransferToBotIds)
	const chatbots = useSelector(chatbotSelectors.getChatbots)

	// Check leadgen data in the chatbot
	if (!chatbot) return DEFAULT_DATA
	const hasLeadgenNode = chatbot.interactions.some((interaction) =>
		interaction.actions.some((action) => action.type === ChatbotActionType.ContactRetrieveProperty),
	)
	const hasLeadgenNodeError = hasLeadgenNode && !hasEnabledLeadgen

	// Check botTransfer data in the chatbot
	const hasInvalidTransferToBotId = botTransferIds.filter(Boolean).some((botTransferId) => {
		return !chatbots.some((item) => item.id === botTransferId)
	})

	return {
		hasChatbotDetailError: hasLeadgenNodeError || hasInvalidTransferToBotId,
		allowPublish: !hasLeadgenNodeError,
	}
}
