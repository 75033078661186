import {
	mdiAccountOutline,
	mdiAccountSyncOutline,
	mdiCogOutline,
	mdiEmailOutline,
	mdiFolderOutline,
	mdiMessageTextOutline,
	mdiPhoneOutline,
	mdiPuzzleEditOutline,
	mdiRobotOutline,
	mdiTextBoxMultipleOutline,
} from '@mdi/js'

import {
	AllowedNodeTypes,
	ChatbotActionProperties,
	ChatbotGroupType,
	ChatbotTriggerConditionName,
} from 'shared/models/Chatbot'

export const CHATBOT_TITLE_ID_LENGTH = 6
export const GENERATED_INTERACTION_ID_LENGTH = 5
export const GENERATED_INTERACTION_ID_PREFIX = 'tmp-'
export const CONDITION_VALUE_MAX_LENGTH = 250
export const CHATBOT_RETRIEVE_PROPERTY_ACTIONS_NAME = 'retrievePropertyActions'

export const conditionValueMaxLengthMap: Partial<Record<ChatbotTriggerConditionName, number>> = {
	country_code: 2,
}

export const chatbotNodeTypeMap: Record<
	AllowedNodeTypes,
	{ icon: string; bgColor: string; text: string; placeholder: string }
> = {
	app: {
		icon: mdiPuzzleEditOutline,
		bgColor: 'gray.500',
		text: 'chatbotBuilder.nodes.app',
		placeholder: 'chatbotBuilder.nodes.app',
	},
	handover_v2: {
		icon: mdiAccountSyncOutline,
		bgColor: 'pink.500',
		text: 'chatbotBuilder.nodes.handover',
		placeholder: 'chatbotBuilder.nodes.handover',
	},
	message: {
		icon: mdiMessageTextOutline,
		bgColor: 'green.500',
		text: 'chatbotBuilder.nodes.chatMessage',
		placeholder: 'chatbotBuilder.nodes.chatMessage',
	},
	trigger: {
		icon: mdiCogOutline,
		bgColor: 'blue.500',
		text: 'chatbotBuilder.nodes.trigger',
		placeholder: '',
	},
	contact_retrieve_property: {
		icon: mdiTextBoxMultipleOutline,
		bgColor: 'orange.300',
		text: 'chatbotBuilder.nodes.retrieveProperty.text',
		placeholder: 'chatbotBuilder.nodes.retrieveProperty.placeholder',
	},
	bot_transfer: {
		icon: mdiRobotOutline,
		bgColor: 'red.400',
		text: 'chatbotBuilder.nodes.botTransfer',
		placeholder: 'chatbotBuilder.nodes.botTransfer',
	},
}

export const chatbotRetrievePropertyTypeMap: Record<ChatbotActionProperties, { icon: string; question: string }> = {
	name: { icon: mdiAccountOutline, question: 'chatbotBuilder.nodes.retrieveProperty.name.question' },
	email: { icon: mdiEmailOutline, question: 'chatbotBuilder.nodes.retrieveProperty.email.question' },
	phone: { icon: mdiPhoneOutline, question: 'chatbotBuilder.nodes.retrieveProperty.phone.question' },
	question: { icon: mdiFolderOutline, question: 'chatbotBuilder.nodes.retrieveProperty.custom.question' },
}

export const chatbotHeaderTitlesMap: Record<ChatbotGroupType, string> = {
	[ChatbotGroupType.Chatbot]: 'chatbotBuilder.header.title.chatbot',
	[ChatbotGroupType.Message]: 'chatbotBuilder.header.title.autoMessage',
	[ChatbotGroupType.Ai]: 'chatbotBuilder.header.title.ai',
}
