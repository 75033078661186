import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FeatureUsageName, WidgetConfig } from 'models'
import { AppThunkAction, DashboardState } from 'types'
import { flashMessage } from 'shared/utils'
import { logFeatureUsage } from 'modules/features'
import {
	getWidgetAppearanceFormData,
	getWidgetAuthFormData,
	getWidgetOptionsData,
	WIDGET_BUTTON_DEFAULT,
	WidgetAppearanceFormData,
	WidgetAppearanceFormMergedData,
	WidgetAuthFormData,
	WidgetAuthFormMergedData,
	WidgetOptionsData,
} from 'modules/widget'
import {
	getWidgetAppearanceTranslationsSubmitData,
	getWidgetAuthFormTranslationsSubmitData,
	putWidgetTranslations,
	Translations,
	widgetTranslationsSelectors,
} from 'modules/widgetTranslations'

import { widgetApi } from './api'

export const updateWidget = createAsyncThunk('widget/PATCH', (values: Partial<WidgetConfig>) => {
	return widgetApi.updateWidget(values)
})

export const fetchWidget = createAsyncThunk('widget/FETCH', () => {
	return widgetApi.fetchWidget()
})

export const submitCombinedForm =
	(
		translationsData: Translations,
		formData: WidgetAppearanceFormData | WidgetAuthFormData,
		testId: string,
		featureUsageName?: FeatureUsageName,
	): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const widgetLanguage = widgetSelectors.getWidgetLang(state)
		if (!widgetLanguage) return

		const resultActionWidget = await dispatch(updateWidget(formData))
		const resultActionTranslations = await dispatch(
			putWidgetTranslations({ lang: widgetLanguage, data: translationsData }),
		)
		if (
			updateWidget.fulfilled.match(resultActionWidget) &&
			putWidgetTranslations.fulfilled.match(resultActionTranslations)
		) {
			flashMessage.success('general.changesSaved', { testId: `flashMessage-success-widget-${testId}` })
			dispatch(setIsFormDirty({ isFormDirty: false }))
			featureUsageName && dispatch(logFeatureUsage(featureUsageName))
		} else flashMessage.error('general.error')
	}

export const submitAppearanceForm =
	(data: WidgetAppearanceFormMergedData): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const customTranslations = widgetTranslationsSelectors.getCustomWidgetTranslations(state)
		const defaultTranslations = widgetTranslationsSelectors.getDefaultWidgetTranslations(state)
		if (!customTranslations || !defaultTranslations) return

		const translationsData = getWidgetAppearanceTranslationsSubmitData(data, customTranslations, defaultTranslations)
		const widgetData = getWidgetAppearanceFormData(data)
		dispatch(submitCombinedForm(translationsData, widgetData, 'appearance'))
	}

export const submitAuthForm =
	(data: WidgetAuthFormMergedData): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const customTranslations = widgetTranslationsSelectors.getCustomWidgetTranslations(state)
		const defaultTranslations = widgetTranslationsSelectors.getDefaultWidgetTranslations(state)
		const configData = widgetSelectors.getWidgetData(state)
		if (!customTranslations || !defaultTranslations) return

		const translationsData = getWidgetAuthFormTranslationsSubmitData(data, customTranslations, defaultTranslations)
		const widgetData = getWidgetAuthFormData(data)
		const shouldLogFeatureUsage = widgetData.requireLogin !== configData?.requireLogin && widgetData.requireLogin
		dispatch(
			submitCombinedForm(
				translationsData,
				widgetData,
				'authForm',
				shouldLogFeatureUsage ? FeatureUsageName.ContactFormEnabled : undefined,
			),
		)
	}

const initialState = {
	data: null as null | WidgetConfig,
	isPending: false,
	selectedTextTabIndex: 0,
	isFormDirty: false,
	isError: false,
	isFetching: false,
}

const widgetSlice = createSlice({
	name: 'widget',
	initialState,
	reducers: {
		changeColor: (state, { payload }: PayloadAction<{ color: string }>) => {
			if (!state.data) return
			const { color } = payload
			state.data.color = color
		},
		changeColor2: (state, { payload }: PayloadAction<{ color: string | null }>) => {
			if (!state.data) return
			const { color } = payload
			state.data.color2 = color
		},
		changeGradient: (state, { payload }: PayloadAction<{ colorGradient: boolean }>) => {
			if (!state.data) return
			const { colorGradient } = payload
			state.data.colorGradient = colorGradient
		},
		changeSelectedTextTabIndex: (state, { payload }: PayloadAction<{ index: number }>) => {
			const { index } = payload
			state.selectedTextTabIndex = index
		},
		setIsFormDirty: (state, { payload }: PayloadAction<{ isFormDirty: boolean }>) => {
			const { isFormDirty } = payload
			state.isFormDirty = isFormDirty
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchWidget.fulfilled, (state, { payload }) => {
				state.data = payload
				state.isError = false
				state.isFetching = false
			})
			.addCase(fetchWidget.rejected, (state) => {
				state.isError = true
				state.isFetching = false
			})
			.addCase(fetchWidget.pending, (state) => {
				state.isError = false
				state.isFetching = true
			})
		builder
			.addCase(updateWidget.fulfilled, (state, { payload }) => {
				state.data = payload
				state.isPending = false
			})
			.addCase(updateWidget.rejected, (state) => {
				state.isPending = false
			})
			.addCase(updateWidget.pending, (state) => {
				state.isPending = true
			})
	},
})

const { reducer, actions } = widgetSlice
export const { changeColor, changeGradient, changeSelectedTextTabIndex, setIsFormDirty, changeColor2 } = actions
export default reducer

const getWidgetData = (state: DashboardState) => state.widget.data
const getIsPending = (state: DashboardState) => state.widget.isPending
const getIsFetching = (state: DashboardState) => state.widget.isFetching
const getSelectedTextTabIndex = (state: DashboardState) => state.widget.selectedTextTabIndex
const getIsFormDirty = (state: DashboardState) => state.widget.isFormDirty
const getIsError = (state: DashboardState) => state.widget.isError

const getWidgetColor = createSelector([getWidgetData], (data) => {
	if (!data) return null
	return data.color
})

const getWidgetColor2 = createSelector([getWidgetData], (data) => {
	if (!data) return null
	return data.color2
})

const getWidgetGradient = createSelector([getWidgetData], (data): boolean => {
	if (!data) return true
	return data.colorGradient
})

const getWidgetLang = createSelector([getWidgetData], (data): string | null => {
	if (!data) return null
	return data.lang
})

const getWidgetOptions = createSelector([getWidgetData], (data): WidgetOptionsData | null => {
	if (!data) return null
	return getWidgetOptionsData(data)
})

const getWidgetAuthForm = createSelector([getWidgetData], (data): WidgetAuthFormData | null => {
	if (!data) return null
	return getWidgetAuthFormData(data)
})

const getWidgetAppearance = createSelector([getWidgetData], (data): WidgetAppearanceFormData | null => {
	if (!data) return null
	return getWidgetAppearanceFormData(data)
})

const getWidgetButtonStyle = createSelector([getWidgetData], (data) => {
	if (!data) return WIDGET_BUTTON_DEFAULT
	return data.buttonStyle
})

const getWidgetGoogleAnalyticsData = createSelector([getWidgetData], (data) => {
	if (!data) return null
	return data.googleAnalyticsEnabled
})

const getWidgetCookieConsent = createSelector([getWidgetData], (data): boolean => {
	if (!data) return false
	return data.consentModeEnabled
})

const getGoogleAnalyticsManual = createSelector([getWidgetData], (data): boolean => {
	if (!data) return false
	return data.googleAnalyticsManual
})

const getGoogleAnalyticsMeasurementIds = createSelector([getWidgetData], (data): string => {
	if (!data) return ''
	return data.googleAnalyticsMeasurementIds[0] ?? ''
})

export const widgetSelectors = {
	getIsPending,
	getIsError,
	getWidgetData,
	getWidgetColor,
	getWidgetColor2,
	getWidgetGradient,
	getSelectedTextTabIndex,
	getWidgetLang,
	getWidgetOptions,
	getWidgetAuthForm,
	getWidgetAppearance,
	getWidgetButtonStyle,
	getWidgetGoogleAnalyticsData,
	getIsFormDirty,
	getWidgetCookieConsent,
	getIsFetching,
	getGoogleAnalyticsManual,
	getGoogleAnalyticsMeasurementIds,
}
