import { px } from '../../utils'

export const breakpointValues = [640, 769, 1024, 1279, 1536, 1920]

export const breakpoints = {
	sm: px(breakpointValues[0]),
	md: px(breakpointValues[1]),
	lg: px(breakpointValues[2]),
	xl: px(breakpointValues[3]),
	'2xl': px(breakpointValues[4]),
	'3xl': px(breakpointValues[5]),
}

export const mediaQueries = {
	xs: `@media (max-width: ${px(breakpointValues[0] - 1)})`, // < 576 px
	sm: `@media (min-width: ${px(breakpointValues[0])})`, // >= 576 px
	md: `@media (min-width: ${px(breakpointValues[1])})`, // >= 768 px
	lg: `@media (min-width: ${px(breakpointValues[2])})`, // >= 992 px
	xl: `@media (min-width: ${px(breakpointValues[3])})`, // >= 1200 px
	'2xl': `@media (min-width: ${px(breakpointValues[4])})`, // >= 1600 px
}
