import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DashboardState } from 'types'
import { App, AppsApi, IntegrationRequest } from 'shared/models/Integrations'

import { integrationsApi } from './api'
import { MOBILE_SDK, WHATSAPP } from './constants'

export type IntegrationsRootState = Pick<DashboardState, 'integrations'>

const initialState = {
	apps: [] as App[],
	chosenIntegration: '',
	isConnectModalOpen: false,
	isSuggestModalOpen: false,
	isMobileSdkModalOpen: false,
}

export const fetchIntegrations = createAsyncThunk(
	'integrations/FETCH',
	({ accountId }: Pick<AppsApi.ListQuery, 'accountId'>) => {
		return integrationsApi.fetchIntegrations({ accountId })
	},
)

export const sendIntegrationConnection = createAsyncThunk('integrations/SEND_CONNECT', (data: IntegrationRequest) => {
	return integrationsApi.sendIntegration(data)
})

export const sendIntegrationSuggestion = createAsyncThunk('integrations/SEND_SUGGEST', (data: IntegrationRequest) => {
	return integrationsApi.sendIntegration(data)
})

const slice = createSlice({
	name: 'integrations',
	initialState,
	reducers: {
		setChosenIntegration: (state, { payload }: PayloadAction<string>) => {
			state.chosenIntegration = payload
		},
		openIntegrationConnectModal: (state) => {
			state.isConnectModalOpen = true
		},
		closeIntegrationConnectModal: (state) => {
			state.isConnectModalOpen = false
		},
		openIntegrationSuggestModal: (state) => {
			state.isSuggestModalOpen = true
		},
		closeIntegrationSuggestModal: (state) => {
			state.isSuggestModalOpen = false
		},
		openMobileSdkModal: (state) => {
			state.isMobileSdkModalOpen = true
		},
		closeMobileSdkModal: (state) => {
			state.isMobileSdkModalOpen = false
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchIntegrations.fulfilled, (state, { payload }) => {
			state.apps = payload
		})
	},
})

const { reducer, actions } = slice
export default reducer
export const {
	setChosenIntegration,
	openIntegrationConnectModal,
	closeIntegrationConnectModal,
	openIntegrationSuggestModal,
	closeIntegrationSuggestModal,
	openMobileSdkModal,
	closeMobileSdkModal,
} = actions

const getIntegrations = (state: IntegrationsRootState) => state.integrations.apps
const getChosenIntegration = (state: IntegrationsRootState) => state.integrations.chosenIntegration
const getIsConnectIntegrationModalOpen = (state: IntegrationsRootState) => state.integrations.isConnectModalOpen
const getIsSuggestIntegrationModalOpen = (state: IntegrationsRootState) => state.integrations.isSuggestModalOpen
const getIsMobileSdkModalOpen = (state: IntegrationsRootState) => state.integrations.isMobileSdkModalOpen

const getHasUserMobileSdkInstalled = createSelector([getIntegrations], (integrations) => {
	return integrations.some((integration) => integration.title === MOBILE_SDK)
})

const getWhatsAppId = createSelector([getIntegrations], (integrations): string | null => {
	return integrations.find((i) => i.title === WHATSAPP)?.id ?? null
})

const getIsChannelWhatsApp = (id: string | null) =>
	createSelector([getWhatsAppId], (whatsappId) => {
		if (!id || !whatsappId) return false
		return id === whatsappId
	})

const getHasUserWhatsAppInstalled = createSelector([getIntegrations], (integrations) => {
	return integrations.some((integration) => integration.title === WHATSAPP)
})

export const integrationsSelectors = {
	getIntegrations,
	getChosenIntegration,
	getIsConnectIntegrationModalOpen,
	getIsSuggestIntegrationModalOpen,
	getHasUserMobileSdkInstalled,
	getWhatsAppId,
	getIsChannelWhatsApp,
	getIsMobileSdkModalOpen,
	getHasUserWhatsAppInstalled,
}
