import {
	AutoMessagesPromotedTemplateType,
	ChatbotCategory,
	ChatbotPromotedTemplateType,
	LeadgenPromotedTemplateType,
	OtherPromotedTemplateType,
	TemplatesTags,
	TemplateStorePromotedTemplateType,
} from 'models'
import { AppLang } from 'shared/types'

import { getChecklistItemStrings } from './utils'

export const CHATBOT_TEMPLATES_WIDGET_KEY = '436bb2622424259c18747e7087c615ebf9a4ade8'

export const promotedTemplates = new Set([
	ChatbotCategory.ChatbotCart,
	ChatbotCategory.ChatbotDiscountReturning,
	ChatbotCategory.ChatbotIncreaseEngagement,
])

export const leadgenPromotedTemplates = new Set([
	ChatbotCategory.ChatbotLeadCollection,
	ChatbotCategory.ChatbotNewsletterSubscribe,
	ChatbotCategory.ChatbotEmailCollection,
	ChatbotCategory.ChatbotPricelistHelp,
])

export const promotedTemplatesDataMap: Record<
	ChatbotPromotedTemplateType,
	{ checklistItems: string[]; image: string; description: string }
> = {
	[ChatbotCategory.ChatbotCart]: {
		checklistItems: getChecklistItemStrings('chatbot.templates.cart.listItem', 4),
		image: 'chatbot-templates-cart.webp',
		description: 'chatbot.templates.cart.description',
	},
	[ChatbotCategory.ChatbotDiscountReturning]: {
		checklistItems: getChecklistItemStrings('chatbot.templates.discountReturning.listItem', 3),
		image: 'chatbot-templates-returning-visitors.webp',
		description: 'chatbot.templates.discountReturning.description',
	},
	[ChatbotCategory.ChatbotIncreaseEngagement]: {
		checklistItems: getChecklistItemStrings('chatbot.templates.increaseEngagement.listItem', 4),
		image: 'chatbot-templates-increase-engagement.webp',
		description: 'chatbot.templates.increaseEngagement.description',
	},
}

export const leadgenPromotedTemplatesDataMap: Record<
	LeadgenPromotedTemplateType,
	{ checklistItems: string[]; image: string; description: string }
> = {
	[ChatbotCategory.ChatbotLeadCollection]: {
		checklistItems: getChecklistItemStrings('leadgen.templates.leadCollection.listItem', 3),
		image: 'leadgen-templates-leadCollection.webp',
		description: 'leadgen.templates.leadCollection.description',
	},
	[ChatbotCategory.ChatbotNewsletterSubscribe]: {
		checklistItems: getChecklistItemStrings('leadgen.templates.newsletter.listItem', 3),
		image: 'leadgen-templates-newsletter.webp',
		description: 'leadgen.templates.newsletter.description',
	},
	[ChatbotCategory.ChatbotEmailCollection]: {
		checklistItems: getChecklistItemStrings('leadgen.templates.emailCollection.listItem', 3),
		image: 'leadgen-templates-emailCollection.webp',
		description: 'leadgen.templates.emailCollection.description',
	},
	[ChatbotCategory.ChatbotPricelistHelp]: {
		checklistItems: getChecklistItemStrings('leadgen.templates.priceList.listItem', 3),
		image: 'leadgen-templates-priceList.webp',
		description: 'leadgen.templates.priceList.description',
	},
}

export const otherPromotedTemplatesDataMap: Record<
	OtherPromotedTemplateType,
	{ checklistItems: string[]; image: string; description: string }
> = {
	[ChatbotCategory.Faq]: {
		checklistItems: getChecklistItemStrings('other.templates.faq.listItem', 4),
		image: 'chatbot-templates-faq.webp',
		description: 'other.templates.faq.description',
	},
	[ChatbotCategory.ChatbotEshop]: {
		checklistItems: getChecklistItemStrings('other.templates.eshop.listItem', 4),
		image: 'chatbot-templates-eshopGuide.webp',
		description: 'other.templates.eshop.description',
	},
	[ChatbotCategory.ChatbotChristmas]: {
		checklistItems: getChecklistItemStrings('other.templates.christmas.listItem', 4),
		image: 'chatbot-templates-christmas.webp',
		description: 'other.templates.christmas.description',
	},
	[ChatbotCategory.ChatbotNewClients]: {
		checklistItems: getChecklistItemStrings('other.templates.newClients.listItem', 3),
		image: 'chatbot-templates-newClients.webp',
		description: 'other.templates.newClients.description',
	},
	[ChatbotCategory.ChatbotMoreMeetings]: {
		checklistItems: getChecklistItemStrings('other.templates.moreMeetings.listItem', 3),
		image: 'chatbot-templates-moreMeetings.webp',
		description: 'other.templates.moreMeetings.description',
	},
}

export const autoMessagesPromotedTemplatesDataMap: Record<
	AutoMessagesPromotedTemplateType,
	{ checklistItems: string[]; image: string; description: string }
> = {
	[ChatbotCategory.ChatbotVisitOne]: {
		checklistItems: getChecklistItemStrings('autoMessages.templates.visitOne.listItem', 3),
		image: 'autoMessage-template.webp',
		description: 'autoMessages.templates.visitOne.description',
	},
	[ChatbotCategory.ChatbotVisitTwo]: {
		checklistItems: getChecklistItemStrings('autoMessages.templates.visitTwo.listItem', 3),
		image: 'autoMessage-template.webp',
		description: 'autoMessages.templates.visitTwo.description',
	},
	[ChatbotCategory.ChatbotBusy]: {
		checklistItems: getChecklistItemStrings('autoMessages.templates.busy.listItem', 3),
		image: 'autoMessage-template.webp',
		description: 'autoMessages.templates.busy.description',
	},
	[ChatbotCategory.ChatbotOffline]: {
		checklistItems: getChecklistItemStrings('autoMessages.templates.offline.listItem', 3),
		image: 'autoMessage-template.webp',
		description: 'autoMessages.templates.offline.description',
	},
	[ChatbotCategory.ChatbotNewProduct]: {
		checklistItems: getChecklistItemStrings('autoMessages.templates.newProduct.listItem', 3),
		image: 'autoMessage-template.webp',
		description: 'autoMessages.templates.newProduct.description',
	},
	[ChatbotCategory.ChatbotFirstOrderDiscount]: {
		checklistItems: getChecklistItemStrings('autoMessages.templates.firstOrder.listItem', 3),
		image: 'autoMessage-template.webp',
		description: 'autoMessages.templates.firstOrder.description',
	},
}

export const allPromotedTemplatesDataMap: Record<
	TemplateStorePromotedTemplateType,
	{ checklistItems: string[]; image: string; description: string }
> = {
	...promotedTemplatesDataMap,
	...leadgenPromotedTemplatesDataMap,
	...otherPromotedTemplatesDataMap,
	...autoMessagesPromotedTemplatesDataMap,
}

export const promotedTemplatesPreviewBots: Record<ChatbotPromotedTemplateType, Record<AppLang, string>> = {
	[ChatbotCategory.ChatbotIncreaseEngagement]: {
		en: 'boxxrPHAX4B2',
		cs: 'bo2QKkT-kouE',
		es: 'boahI0irrdpf',
		hu: 'boalBu1nFYc6',
		nl: 'bodk1IZURNdW',
		pl: 'bo83koXBtjEX',
		it: 'boSd3VKWnPxF',
		de: 'boz9Ho96_jSe',
		fr: 'boq8u1ZTWyGE',
	},
	[ChatbotCategory.ChatbotDiscountReturning]: {
		en: 'boVqt9QDFe4O',
		cs: 'boPhDAO6r9Oz',
		es: 'booeC1Gi4H3T',
		hu: 'bokq9cLwTmyd',
		nl: 'boGGjpcYJEe5',
		pl: 'boKBrvWN9NyE',
		it: 'boTIeB_P_wnq',
		de: 'boKVM8azKqFS',
		fr: 'boMAO5sM3Xvp',
	},
	[ChatbotCategory.ChatbotCart]: {
		en: 'bosQ4R2HW69q',
		cs: 'bodipB_FBKYm',
		es: 'boz4zWZubuVk',
		hu: 'bog2wnGy_A_7',
		nl: 'bo0UrISCGlNQ',
		pl: 'boLfSiwuStQm',
		it: 'boGGGab2X0Rz',
		de: 'boebKnc5lVej',
		fr: 'boMXzGvGoSJ1',
	},
}

export const leadgenPromotedTemplatesPreviewBots: Record<LeadgenPromotedTemplateType, Record<AppLang, string>> = {
	[ChatbotCategory.ChatbotLeadCollection]: {
		en: 'bolwnemfNa4Q',
		cs: 'boG7_a3kZy_I',
		es: 'boM0hVmha_Qk',
		hu: 'bo-4WJpejtBs',
		nl: 'bo4tUbtirokC',
		pl: 'bo3gwDwwseag',
		it: 'bo30vLd59CEN',
		de: 'bopgnzbxAVOS',
		fr: 'bonc3Rn7pegV',
	},
	[ChatbotCategory.ChatbotNewsletterSubscribe]: {
		en: 'bocurGo3Y4Br',
		cs: 'boJTSCKnj24c',
		es: 'bonuxiJjixrE&',
		hu: 'bo01RSZRdvZU',
		nl: 'borva55XxIO9',
		pl: 'bol4bP1XQATT',
		it: 'boc9PnOJDGLJ',
		de: 'boWM3S2e6OED',
		fr: 'boUccrAPIqhS',
	},
	[ChatbotCategory.ChatbotEmailCollection]: {
		en: 'boby63SWhKm3',
		cs: 'boPPR_4OgfYj',
		es: 'bozRg8w5wXHv',
		hu: 'boW8iSttZngP',
		nl: 'bobWy3HgJj4L',
		pl: 'bo9vgqkJFYRB',
		it: 'bor4OymULQA3',
		de: 'bo1Vf9m0XENQ',
		fr: 'boSAcwj6nNkj',
	},
	[ChatbotCategory.ChatbotPricelistHelp]: {
		en: 'bo-h4_AEeQxL',
		cs: 'boVBpZ7axQue',
		es: 'boBuAT4BXItX',
		hu: 'boXXy5rcyqmv',
		nl: 'boGA4iz23_35',
		pl: 'boFOGGZ2b0_l',
		it: 'boOAmAvvg2zxE',
		de: 'bogl0C4ZGBGZ',
		fr: 'bozNAKc61NuT',
	},
}

export const otherPromotedTemplatesPreviewBots: Record<OtherPromotedTemplateType, Record<AppLang, string>> = {
	[ChatbotCategory.Faq]: {
		en: 'box5nHNIyB-F',
		cs: 'bomS1EWoK64d',
		es: 'bofh46ll6by3',
		hu: 'bolDDIvNc1O0',
		nl: 'boxBpIJ02ftm',
		pl: 'boVkQiRWyMAP',
		it: 'bovnjDXAujWW',
		de: 'botxe0iEkvIB',
		fr: 'boC9O4Npy5_a',
	},
	[ChatbotCategory.ChatbotEshop]: {
		en: 'bonno1s61Mw-',
		cs: 'bogkwEAxJuOz',
		es: 'boJNBllCk8aX',
		hu: 'boXOcqhNmQwD',
		nl: 'boNVanjKAZmu',
		pl: 'bo2jdUPwmf68',
		it: 'bo44VRKV9SRA',
		de: 'bolqfKSRewCr',
		fr: 'borYim9kKz4R',
	},
	[ChatbotCategory.ChatbotChristmas]: {
		en: 'boAAqJUXTrh5',
		cs: 'bozwztATBZok',
		es: 'boZOmqIWsAKR',
		hu: 'bo0De29-TrOa',
		nl: 'boX1aS0eY1e2',
		pl: 'bo6dQuPiXwQ-',
		it: 'boVn9tODaT0B',
		de: 'bo_NpX2NTDm',
		fr: 'boZsUxT33PsC',
	},
	[ChatbotCategory.ChatbotNewClients]: {
		en: 'boNaI-xyob_O',
		cs: 'bose_eZTkeIZ',
		es: 'bosCZ6czyfwY',
		hu: 'boOiIFUF5uce',
		nl: 'bo36aOlEA5vr',
		pl: 'boQ378p50CDT',
		it: 'bot72W6VGt0k',
		de: 'boQ1tJcrpjWm',
		fr: 'boPLWONTXu4I',
	},
	[ChatbotCategory.ChatbotMoreMeetings]: {
		en: 'boYpwQMWKRYJ',
		cs: 'boQe1IDyHCJB',
		es: 'boCk6cDq6C6M',
		hu: 'boZcYcSFH8SN',
		nl: 'boy9hcsxmw6h',
		pl: 'boZifCP72PzJ',
		it: 'bom75K0PoNXp',
		de: 'boQi128I3GHj',
		fr: 'bovFizYEfJS8',
	},
}

export const autoMessagesPromotedTemplatesPreviewBots: Record<
	AutoMessagesPromotedTemplateType,
	Record<AppLang, string>
> = {
	[ChatbotCategory.ChatbotVisitOne]: {
		en: 'boKr8zNnUwzZ',
		cs: 'boPhrlG0pJom',
		es: 'boo0cYkicWUn',
		hu: 'boZSPwH-vTRK',
		nl: 'boWDAySNoskh',
		pl: 'bowC_-GADPiB',
		it: 'bo_AK46fguhz',
		de: 'boPSXDJ8a5GT',
		fr: 'boUH6ptQE62h',
	},
	[ChatbotCategory.ChatbotVisitTwo]: {
		en: 'boXWPTKWUl-V',
		cs: 'boLMHVB_Z-Nf',
		es: 'boBf7Mv3Je-h',
		hu: 'boKAh67L3fZH',
		nl: 'boHA_bMGo3y7',
		pl: 'boT86Xr0efy6',
		it: 'bo9tMvsQ2BYH',
		de: 'bo9Ghgfmv-QR',
		fr: 'boUH6ptQE62h',
	},
	[ChatbotCategory.ChatbotBusy]: {
		en: 'boI1FYs2i6nu',
		cs: 'bopieFkuDQFA',
		es: 'boXDyQ1LM2aa',
		hu: 'boD4j1GNDLH6',
		nl: 'boqrCNJfoQDY',
		pl: 'boREsYwTND8v',
		it: 'boipCv_v-DJ8',
		de: 'bo3HcOFvYqnK',
		fr: 'bonyk5Ej1seJ',
	},
	[ChatbotCategory.ChatbotOffline]: {
		en: 'boFzfuGxIrRu',
		cs: 'boEA2kakVfiF',
		es: 'bo0Mpxk5_5NX',
		hu: 'bopg2wfglfGZ',
		nl: 'bo0wyvMyAIff',
		pl: 'boNJwMRrtv2f',
		it: 'bo5o17zpsv1f',
		de: 'bozbgOqU8v7c',
		fr: 'boLW4rU8Bui-',
	},
	[ChatbotCategory.ChatbotNewProduct]: {
		en: 'boyTuKdt5LlD',
		cs: 'boswZD5xDT0x',
		es: 'bo9E3Y54mi-k',
		hu: 'bonXATGaHB89',
		nl: 'boL70dRsTSJe',
		pl: 'bo8Ax0w8nRA_8',
		it: 'bol3XNnIyidP',
		de: 'bo1YEonstrHp',
		fr: 'bo89bJY38NAB',
	},
	[ChatbotCategory.ChatbotFirstOrderDiscount]: {
		en: 'borkJo1qF7sE',
		cs: 'boV7kvwImbrz',
		es: 'bo5DfxDtksRB',
		hu: 'bouaXuY7VL8L',
		nl: 'boREqrJoD9hh',
		pl: 'boJg12ZvNOMs',
		it: 'bo4x73503VYK',
		de: 'boURbkCsqF-E',
		fr: 'boILaub6VEFy',
	},
}

export const allPromotedTemplatesPreviewBots: Record<TemplateStorePromotedTemplateType, Record<AppLang, string>> = {
	...leadgenPromotedTemplatesPreviewBots,
	...promotedTemplatesPreviewBots,
	...otherPromotedTemplatesPreviewBots,
	...autoMessagesPromotedTemplatesPreviewBots,
}

export const chatbotTemplatesTestIds = {
	banner: {
		banner: 'chatbot-templates-banner',
		cta: 'chatbot-templates-banner-ctaButton',
	},
	modal: {
		modal: 'chatbot-templates-modal',
		menuItem: 'chatbot-templates-modal-menuItem',
		useTemplateButton: 'chatbot-templates-modal-useTemplateButton',
		livePreviewButton: 'chatbot-templates-modal-livePreviewButton',
	},
}

export const templatesTagsMap: Record<TemplateStorePromotedTemplateType, TemplatesTags[]> = {
	[ChatbotCategory.ChatbotLeadCollection]: [TemplatesTags.Chatbot, TemplatesTags.Leadgen, TemplatesTags.Sales],
	[ChatbotCategory.ChatbotIncreaseEngagement]: [TemplatesTags.Chatbot, TemplatesTags.Sales],
	[ChatbotCategory.ChatbotEmailCollection]: [TemplatesTags.Chatbot, TemplatesTags.Leadgen, TemplatesTags.Marketing],
	[ChatbotCategory.ChatbotPricelistHelp]: [TemplatesTags.Chatbot, TemplatesTags.CustomerCare, TemplatesTags.Sales],
	[ChatbotCategory.ChatbotNewsletterSubscribe]: [TemplatesTags.Chatbot, TemplatesTags.Marketing, TemplatesTags.Sales],
	[ChatbotCategory.ChatbotDiscountReturning]: [TemplatesTags.Chatbot, TemplatesTags.Leadgen, TemplatesTags.Sales],
	[ChatbotCategory.ChatbotCart]: [TemplatesTags.Chatbot, TemplatesTags.CustomerCare, TemplatesTags.Sales],
	[ChatbotCategory.Faq]: [TemplatesTags.Chatbot, TemplatesTags.CustomerCare],
	[ChatbotCategory.ChatbotEshop]: [TemplatesTags.Chatbot, TemplatesTags.CustomerCare, TemplatesTags.Marketing],
	[ChatbotCategory.ChatbotChristmas]: [TemplatesTags.Chatbot, TemplatesTags.Marketing, TemplatesTags.Sales],
	[ChatbotCategory.ChatbotNewClients]: [TemplatesTags.Chatbot, TemplatesTags.Leadgen, TemplatesTags.Sales],
	[ChatbotCategory.ChatbotMoreMeetings]: [TemplatesTags.Chatbot, TemplatesTags.Leadgen, TemplatesTags.Sales],
	[ChatbotCategory.ChatbotVisitOne]: [TemplatesTags.AutoMessage, TemplatesTags.CustomerCare],
	[ChatbotCategory.ChatbotVisitTwo]: [TemplatesTags.AutoMessage, TemplatesTags.Marketing, TemplatesTags.Sales],
	[ChatbotCategory.ChatbotBusy]: [TemplatesTags.AutoMessage, TemplatesTags.CustomerCare],
	[ChatbotCategory.ChatbotOffline]: [TemplatesTags.AutoMessage, TemplatesTags.CustomerCare, TemplatesTags.Sales],
	[ChatbotCategory.ChatbotNewProduct]: [TemplatesTags.AutoMessage, TemplatesTags.Marketing, TemplatesTags.Sales],
	[ChatbotCategory.ChatbotFirstOrderDiscount]: [
		TemplatesTags.AutoMessage,
		TemplatesTags.Marketing,
		TemplatesTags.Sales,
	],
}
