import { ChannelType } from 'models'
import { ChannelIconType, ChannelTypeExtensions } from 'modules/integrations'

import { Flex } from 'core'
import { IconChannel } from 'components/channels/IconChannel'

type Props = {
	channel: ChannelIconType
}

const allowedChannels = new Set<ChannelIconType>([
	ChannelType.FacebookMessenger,
	ChannelType.Email,
	ChannelType.App,
	ChannelTypeExtensions.WhatsApp,
])

export const AvatarChannelIcon = ({ channel }: Props) => {
	if (!allowedChannels.has(channel)) return null

	return (
		<Flex position="absolute" fontSize={0} right="-4px" bottom="-4px" border={1} borderColor="white" borderRadius="50%">
			<IconChannel channel={channel} />
		</Flex>
	)
}
