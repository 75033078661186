import {
	Contact,
	ContactsApi,
	ContactSearchQuery,
	ContactSearchQueryName,
	ContactSearchQueryOp,
	ContactSearchSortOrder,
} from '@smartsupp/core-client-api'

export { Contact } from '@smartsupp/core-client-api'
export { ContactChanges } from '@smartsupp/websocket-client-agent'

export type ContactInfoEditable = Pick<Contact, 'name' | 'email' | 'note' | 'phone'>
export type ContactBanResponse = ContactsApi.BanResponse
export type ContactUnbanResponse = ContactsApi.UnbanResponse
export type ContactsListBody = ContactsApi.SearchBody
export type ContactsListResponse = ContactsApi.SearchResponse
export type ContactsExportBody = ContactsApi.ExportBody
export type ContactsQueryName = ContactSearchQueryName
export type ContactsQueryOp = ContactSearchQueryOp
export type ContactsQuery = ContactSearchQuery
export type ContactsQuerySort = ContactSearchSortOrder
export type ContactsUnseenCount = ContactsApi.CountUnseenResponse
export type ContactsFindBody = ContactsApi.FindBody

export enum ContactFilterOptions {
	Equal = 'eq',
	NotEq = 'not_eq',
	Contains = 'contains',
	NotContains = 'not_contains',
	StartWith = 'start_with',
	EndWith = 'end_with',
	And = 'and',
	Or = 'or',
	Match = 'match',
}

export enum ContactQueryOptions {
	Name = 'name',
	Email = 'email',
	Phone = 'phone',
	Tags = 'tags',
	GdprApproved = 'gdprApproved',
	IsUnseen = 'isUnseen',
}

export type ReducedQueryOptions = Exclude<
	ContactQueryOptions,
	ContactQueryOptions.Tags | ContactQueryOptions.GdprApproved | ContactQueryOptions.IsUnseen
>

export type BinaryQueryOptions = Exclude<
	ContactQueryOptions,
	ContactQueryOptions.Name | ContactQueryOptions.Phone | ContactQueryOptions.Email | ContactQueryOptions.Tags
>

export enum ContactsSortOrder {
	Newest = 'desc',
	Oldest = 'asc',
}

export type ReducedFilterOptions = ContactFilterOptions.NotContains | ContactFilterOptions.Contains

export enum BinaryFilterOptions {
	Positive = 'positive',
	Negative = 'negative',
}
