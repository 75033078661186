import { forwardRef, Ref } from 'react'
import { Input, InputProps } from '@chakra-ui/react'

export {
	InputGroup as CInputGroup,
	InputLeftAddon as CInputLeftAddon,
	InputLeftElement as CInputLeftElement,
	InputRightElement as CInputRightElement,
} from '@chakra-ui/react'

export type CInputProps = {
	isInvalid?: boolean
} & InputProps

export const CInput = forwardRef<HTMLInputElement, CInputProps>(
	({ isInvalid = false, ...rest }, ref: Ref<HTMLInputElement>) => {
		return <Input {...rest} ref={ref} isInvalid={isInvalid} {...(isInvalid && { focusBorderColor: 'red.600' })} />
	},
)

CInput.displayName = 'CInput'
