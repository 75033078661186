import { createAsyncThunk, createSlice, PayloadAction, SerializedError, unwrapResult } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { SelectedUser, User, UserGroups, UserPasswords } from 'models/User'
import { ApiError } from 'shared/types'
import { AppThunkAction, DashboardState } from 'types'
import { ConfigurationService } from 'shared/services'

import { userApi } from './api'
import { isRoleAdmin, isRoleOwner } from './utils'

export const fetchUserAsync = createAsyncThunk('user/FETCH', async () => {
	return userApi.getUser()
})

export const fetchUser = (): AppThunkAction<Promise<User>> => async (dispatch) => {
	const response = await dispatch(fetchUserAsync())
	return unwrapResult(response)
}

export const updateUser = createAsyncThunk<User, Partial<User>, { rejectValue: ApiError }>(
	'user/UPDATE',
	async (values, { rejectWithValue }) => {
		try {
			return await userApi.updateUser(values)
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

export const updateUserPassword = createAsyncThunk<User, UserPasswords, { rejectValue: ApiError }>(
	'user/UPDATE_PASSWORD',
	async (values, { rejectWithValue }) => {
		try {
			return await userApi.updateUserPassword(values)
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

export const initialState = {
	error: null as null | SerializedError,
	groups: {} as UserGroups,
	isFetching: true,
	user: null as null | User,
	isPendingUpdate: false,
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		userUpdated: (state, { payload }: PayloadAction<{ values: Partial<User> }>) => {
			if (state.user) {
				state.user = { ...state.user, ...payload.values }
			}
		},
	},
	extraReducers: (builder) => {
		// Fetch user
		builder
			.addCase(fetchUserAsync.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchUserAsync.fulfilled, (state, { payload }) => {
				state.isFetching = false
				state.user = payload
				const accountData = ConfigurationService.getAccountData()
				state.groups = accountData ? accountData.groups : {}
			})
			.addCase(fetchUserAsync.rejected, (state) => {
				state.isFetching = false
			})

		// Update user
		builder
			.addCase(updateUser.pending, (state) => {
				state.isPendingUpdate = true
			})
			.addCase(updateUser.fulfilled, (state, { payload }) => {
				state.isPendingUpdate = false
				state.user = payload
			})
			.addCase(updateUser.rejected, (state) => {
				state.isPendingUpdate = false
			})

		// Update password
		builder
			.addCase(updateUserPassword.pending, (state) => {
				state.isPendingUpdate = true
			})
			.addCase(updateUserPassword.fulfilled, (state) => {
				state.isPendingUpdate = false
			})
			.addCase(updateUserPassword.rejected, (state) => {
				state.isPendingUpdate = false
			})
	},
})

const { reducer } = userSlice
export default reducer

// Selectors
const getUser = (state: DashboardState) => state.user.user
const getUserGroups = (state: DashboardState) => state.user.groups
const isPendingUpdate = (state: DashboardState) => state.user.isPendingUpdate

const getUserId = createSelector([getUser], (user) => (user ? `${user.id}` : null))

const getActiveUser = createSelector([getUser], (user): SelectedUser | null => {
	if (!user) return null
	return {
		...user,
		isAdmin: isRoleAdmin(user.role),
		isOwner: isRoleOwner(user.role),
	}
})

const hasCurrentPassword = createSelector([getUser], (user) => !user?.noPassword)

const isAvatarUploadRequired = createSelector([getUser], (user) =>
	!user || user.isRoot ? false : user.avatarUploadRequired,
)

const isUserAdmin = createSelector([getActiveUser], (user) => !!user && user.isAdmin)
const isUserOwner = createSelector([getActiveUser], (user) => !!user && user.isOwner)
const isUserRoot = createSelector([getActiveUser], (user) => !!user && user.isRoot)

const getUserEmail = createSelector([getUser], (user) => {
	if (!user) return null
	return user.email
})

export const userSelectors = {
	getUserGroups,
	getUserId,
	getActiveUser,
	isAvatarUploadRequired,
	isUserAdmin,
	isUserOwner,
	isUserRoot,
	isPendingUpdate,
	hasCurrentPassword,
	getUserEmail,
}
