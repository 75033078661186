import { isValidElement } from 'react'
import ReactDOMServer from 'react-dom/server'

import { TranslationParamValue } from 'shared/types'
import { isString } from 'shared/utils'

export type TranslationsMap = { [s: string]: string }

interface TranslationData {
	data: TranslationsMap
	defaultData: TranslationsMap
}

const innerData: TranslationData = {
	data: {},
	defaultData: {},
}

export class TranslationService {
	public static translate(key: string, params?: Record<string, TranslationParamValue>) {
		let translation = innerData.data[key] || innerData.defaultData[key] || `|${key}|`

		if (!params) return translation

		Object.keys(params).forEach((paramKey) => {
			let value = params[paramKey]

			// If param is JSX Element, convert it to string
			if (isValidElement(value)) {
				value = ReactDOMServer.renderToStaticMarkup(value)
			}

			value = String(value)
			if (isString(value)) {
				translation = translation.replaceAll(new RegExp(`{${paramKey}}`, 'gim'), value)
			}
		})

		return translation
	}

	public static translateDefault(key: string) {
		return innerData.defaultData[key] || `|${key}|`
	}

	public static translateUnsafe(key: string, params?: Record<string, TranslationParamValue>) {
		// eslint-disable-next-line react/no-danger
		return <span dangerouslySetInnerHTML={{ __html: TranslationService.translate(key, params) }} />
	}

	public static setData(translationData: TranslationsMap) {
		innerData.data = translationData
	}

	public static setDefaultLanguageData(translationData: TranslationsMap) {
		innerData.defaultData = translationData
	}
}
