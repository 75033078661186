import { Shortcut } from '@smartsupp/core-client-api'

export { Shortcut, ShortcutType } from '@smartsupp/core-client-api'

export const SHORTCUT_NAME_MAXLENGTH = 50

export interface ShortcutExtended extends Shortcut {
	hasUniqueName: boolean
}

export type ShortcutCreateRequest = Pick<Shortcut, 'name' | 'text' | 'type'>
export type ShortcutEditParams = {
	id: number
	changes: Partial<Shortcut>
}
