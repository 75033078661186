import { createAsyncThunk, createSelector, createSlice, isFulfilled, PayloadAction } from '@reduxjs/toolkit'

import { FeatureFlag, FeatureFlags, FeatureUsage, FeatureUsageName, SelectedPublicFeatureFlag } from 'models'
import { AppThunkAction, DashboardState, Dictionary } from 'types'
import { accountSelectors } from 'modules/account'
import { userSelectors } from 'modules/user'

import { featuresApi } from './api'
import { publicFeatureFlags } from './constants'
import {
	FeatureUsageAccount,
	featureUsageNamesAccount,
	featureUsageNamesUser,
	FeatureUsageUser,
	ProgressBarCounts,
	ProgressBarItemsUsage,
} from './types'
import {
	getBasicProgressBarData,
	getEnvFeatureFlags,
	getLocalStorageFeatureFlags,
	getProgressBarCounts,
	getSalesProgressBarData,
	getSupportProgressBarData,
} from './utils'

export type FeaturesRootState = Pick<DashboardState, 'features'>
export type FeaturesState = typeof initialState

export const initialState = {
	isFeaturePreviewModalOpen: false,
	isFeaturesUpdatePending: false,
	isFeatureUsageUserPending: false,
	isFeatureUsageAccountPending: false,
	isFeatureUsageUserInitialized: false,
	isFeatureUsageAccountInitialized: false,
	featureFlags: {} as FeatureFlags,
	featureUsageUser: null as null | Partial<FeatureUsageUser>,
	featureUsageAccount: null as null | Partial<FeatureUsageAccount>,
	isMobileModalOpen: false,
}

export const logFeatureUsage = createAsyncThunk('features/LOG_USAGE', (name: FeatureUsageName) => {
	return featuresApi.logFeatureUsage(name)
})

export const fetchFeatureUsageUser = createAsyncThunk('features/FETCH_USAGE_USER', () => {
	return featuresApi.fetchFeatureUsageUser(featureUsageNamesUser)
})

export const fetchFeatureUsageAccount = createAsyncThunk('features/FETCH_USAGE_ACCOUNT', () => {
	return featuresApi.fetchFeatureUsageAccount(featureUsageNamesAccount)
})

export const fetchFeatureFlags = createAsyncThunk('features/FETCH_FEATURE_FLAGS', () => {
	return featuresApi.fetchFeatureFlags()
})

export const updateFeatureFlags = createAsyncThunk(
	'features/UPDATE_FEATURE_FLAGS',
	(changes: Partial<FeatureFlags>) => {
		return featuresApi.updateFeatureFlags(changes)
	},
)

export const onChangeFeatureUsageUser =
	(data: FeatureUsage): AppThunkAction =>
	(dispatch) => {
		const featureName = Object.keys(data)[0] as unknown as FeatureUsageName
		if (featureUsageNamesUser.includes(featureName)) {
			dispatch(actions.updateFeatureUsageUser(data))
		}
	}

const slice = createSlice({
	name: 'features',
	initialState,
	reducers: {
		openFeaturePreviewModal: (state) => {
			state.isFeaturePreviewModalOpen = true
		},
		closeFeaturePreviewModal: (state) => {
			state.isFeaturePreviewModalOpen = false
		},
		updateFeatureUsageUser: (state, { payload }: PayloadAction<Partial<FeatureUsageUser>>) => {
			state.featureUsageUser = { ...state.featureUsageUser, ...payload }
		},
		updateFeatureUsageAccount: (state, { payload }: PayloadAction<Partial<FeatureUsageAccount>>) => {
			state.featureUsageAccount = { ...state.featureUsageAccount, ...payload }
		},
		openMobileModal: (state) => {
			state.isMobileModalOpen = true
		},
		closeMobileModal: (state) => {
			state.isMobileModalOpen = false
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchFeatureUsageUser.pending, (state) => {
				state.isFeatureUsageUserPending = true
			})
			.addCase(fetchFeatureUsageUser.fulfilled, (state, { payload }) => {
				state.isFeatureUsageUserPending = false
				state.isFeatureUsageUserInitialized = true
				state.featureUsageUser = payload
			})
			.addCase(fetchFeatureUsageUser.rejected, (state) => {
				state.isFeatureUsageUserPending = false
			})

		builder
			.addCase(fetchFeatureUsageAccount.pending, (state) => {
				state.isFeatureUsageAccountPending = true
			})
			.addCase(fetchFeatureUsageAccount.fulfilled, (state, { payload }) => {
				state.isFeatureUsageAccountPending = false
				state.isFeatureUsageAccountInitialized = true
				state.featureUsageAccount = payload
			})
			.addCase(fetchFeatureUsageAccount.rejected, (state) => {
				state.isFeatureUsageAccountPending = false
			})

		builder.addMatcher(isFulfilled(fetchFeatureFlags, updateFeatureFlags), (state, { payload }) => {
			state.featureFlags = payload
		})
	},
})

const { actions, reducer } = slice
export const { openFeaturePreviewModal, closeFeaturePreviewModal, openMobileModal, closeMobileModal } = actions
export default reducer

const getIsFeaturePreviewModalOpen = (state: FeaturesRootState) => state.features.isFeaturePreviewModalOpen
const getFeatureFlags = (state: FeaturesRootState) => state.features.featureFlags
const getIsFeaturesUpdatePending = (state: FeaturesRootState) => state.features.isFeaturesUpdatePending
const getFeatureUsageUser = (state: FeaturesRootState) => state.features.featureUsageUser
const getFeatureUsageAccount = (state: FeaturesRootState) => state.features.featureUsageAccount
const getIsFeatureUsageUserPending = (state: FeaturesRootState) => state.features.isFeatureUsageUserPending
const getIsFeatureUsageAccountPending = (state: FeaturesRootState) => state.features.isFeatureUsageAccountPending
const getIsFeatureUsageAccountInitialized = (state: FeaturesRootState) =>
	state.features.isFeatureUsageAccountInitialized
const getIsFeatureUsageUserInitialized = (state: FeaturesRootState) => state.features.isFeatureUsageUserInitialized
const getIsMobileModalOpen = (state: FeaturesRootState) => state.features.isMobileModalOpen

const getPublicFeatureFlags = createSelector(getFeatureFlags, (featureFlags): SelectedPublicFeatureFlag[] => {
	return publicFeatureFlags.map((flag) => ({ ...flag, isEnabled: !!featureFlags[flag.type] }))
})

const getProgressIsFeatureUsagePending = createSelector(
	[getIsFeatureUsageAccountPending, getIsFeatureUsageUserPending],
	(isAccountPending, isUserPending): boolean => {
		return isAccountPending || isUserPending
	},
)

const getBasicProgressBarItemsUsage = createSelector(
	[
		getFeatureUsageUser,
		userSelectors.getActiveUser,
		accountSelectors.getChatCodeInstalledFirstAt,
		userSelectors.isUserAdmin,
	],
	(usageUser, user, chatCodeInstalledFirstAt, isUserAdmin): Dictionary<ProgressBarItemsUsage> | null => {
		if (!user) return null

		return getBasicProgressBarData(usageUser || {}, user, chatCodeInstalledFirstAt, isUserAdmin)
	},
)

const getSupportProgressBarItemsUsage = createSelector(
	[getFeatureUsageUser, getFeatureUsageAccount],
	(usageUser, usageAccount): Dictionary<ProgressBarItemsUsage> | null => {
		return getSupportProgressBarData(usageUser || {}, usageAccount || {})
	},
)

const getSalesProgressBarItemsUsage = createSelector(
	[getFeatureUsageUser, getFeatureUsageAccount],
	(usageUser, usageAccount): Dictionary<ProgressBarItemsUsage> | null => {
		return getSalesProgressBarData(usageUser || {}, usageAccount || {})
	},
)

const getBasicProgressBarItemsCounts = createSelector(
	[getBasicProgressBarItemsUsage],
	(basicItemsUsage): ProgressBarCounts | null => {
		if (!basicItemsUsage) return null

		return getProgressBarCounts(basicItemsUsage)
	},
)

const getSupportProgressBarItemsCounts = createSelector(
	[getSupportProgressBarItemsUsage],
	(supportItemsUsage): ProgressBarCounts | null => {
		if (!supportItemsUsage) return null

		return getProgressBarCounts(supportItemsUsage)
	},
)

const getSalesProgressBarItemsCounts = createSelector(
	[getSalesProgressBarItemsUsage],
	(salesItemsUsage): ProgressBarCounts | null => {
		if (!salesItemsUsage) return null

		return getProgressBarCounts(salesItemsUsage)
	},
)

const getBasicProgressBarIsDone = createSelector([getBasicProgressBarItemsCounts], (counts): boolean => {
	if (!counts) return false

	const { countTotal, countDone } = counts
	return countDone === countTotal
})

const getSupportProgressBarIsDone = createSelector([getSupportProgressBarItemsCounts], (counts): boolean => {
	if (!counts) return false

	const { countTotal, countDone } = counts
	return countDone === countTotal
})

const getSalesProgressBarIsDone = createSelector([getSalesProgressBarItemsCounts], (counts): boolean => {
	if (!counts) return false

	const { countTotal, countDone } = counts
	return countDone === countTotal
})

const makeHasEnabledFeatureFlag = (flag: FeatureFlag) =>
	createSelector(getFeatureFlags, (featureFlags): boolean => {
		const storedFeatureFlags = getLocalStorageFeatureFlags()
		if (storedFeatureFlags.includes(flag)) return true

		const envFeatureFlags = getEnvFeatureFlags()
		if (envFeatureFlags.includes(flag)) return true

		return featureFlags[flag] ?? true
	})

const getProgressBarIsInitialized = createSelector(
	[getIsFeatureUsageAccountInitialized, getIsFeatureUsageUserInitialized, userSelectors.getActiveUser],
	(isAccountInitialized, isUserInitialized, user): boolean => {
		if (!user) return false
		if (user.isAdmin) return isAccountInitialized && isUserInitialized
		return isUserInitialized
	},
)

export const featuresSelectors = {
	getIsFeaturePreviewModalOpen,
	getIsFeaturesUpdatePending,
	getPublicFeatureFlags,
	makeHasEnabledFeatureFlag,
	getBasicProgressBarItemsUsage,
	getSupportProgressBarItemsUsage,
	getBasicProgressBarItemsCounts,
	getFeatureUsageUser,
	getFeatureUsageAccount,
	getIsFeatureUsageUserPending,
	getProgressBarIsInitialized,
	getProgressIsFeatureUsagePending,
	getBasicProgressBarIsDone,
	getSupportProgressBarItemsCounts,
	getSupportProgressBarIsDone,
	getSalesProgressBarItemsCounts,
	getSalesProgressBarIsDone,
	getSalesProgressBarItemsUsage,
	getIsMobileModalOpen,
}
