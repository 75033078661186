import { SmartsuppWindow } from 'shared/types'
import { ConfigurationService } from 'shared/services'

import { FeatureNameUserpilotType, userpilotFlowsMap } from './types'

declare let window: SmartsuppWindow

export const triggerUserPilotFlow = (name: FeatureNameUserpilotType) => {
	const { lang } = ConfigurationService.getData()

	const userPilotFlowId = userpilotFlowsMap[name][lang] ?? userpilotFlowsMap[name].en
	window.userpilot && window.userpilot.trigger(userPilotFlowId)
}
