import { ReactNode } from 'react'
import { IntlProvider } from 'react-intl'

import { ConfigurationService } from 'shared/services'

type Props = {
	children?: ReactNode
	locale?: string
}

const IntlCurrencyProvider = ({ children, locale }: Props) => {
	const { currencyLocale } = ConfigurationService.getData()
	const localeValue = locale ?? currencyLocale
	if (!localeValue) return null

	return (
		<IntlProvider locale={localeValue} key={localeValue}>
			{children}
		</IntlProvider>
	)
}

export default IntlCurrencyProvider
