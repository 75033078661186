import { AppLang } from 'shared/types'

export enum FeatureNameUserpilotType {
	AutoMessagesVisited,
	AvatarUploaded,
	OfficeHoursEnabled,
	ShortcutsTeam,
	ActivateChatbot,
	ConversationTagged,
	ContactFormEnabled,
	AutoMessageCreated,
	VisitorListLastVisited,
	ActivateChatbotSales,
	ContactFormEnabledSales,
	UseChatbotTemplate,
	UseLeadgenTemplate,
	AiReplyAssist,
}

export const userpilotFlowsMap: Record<FeatureNameUserpilotType, Partial<Record<AppLang, string>>> = {
	[FeatureNameUserpilotType.AutoMessagesVisited]: {
		en: '1675026694tZth0665',
		cs: '1672334626uMhl4125',
	},
	[FeatureNameUserpilotType.AvatarUploaded]: {
		en: '1675018545aMza5955',
		cs: '1672329028wKgg4785',
	},
	[FeatureNameUserpilotType.OfficeHoursEnabled]: {
		en: '1676538435qUkw0844',
		cs: '1676288919zGwl5874',
	},
	[FeatureNameUserpilotType.ShortcutsTeam]: {
		en: '1676538440yJwb1936',
		cs: '1676289412kWcu991',
	},
	[FeatureNameUserpilotType.ActivateChatbot]: {
		en: '1676538450hRdu2457',
		cs: '1676306506vIox5133',
	},
	[FeatureNameUserpilotType.ConversationTagged]: {
		en: '1676538452qCjz9374',
		cs: '1676306836fWal0821',
	},
	[FeatureNameUserpilotType.ContactFormEnabled]: {
		en: '1676538460wRnh6953',
		cs: '1676306991nWza3247',
	},
	[FeatureNameUserpilotType.AutoMessageCreated]: {
		en: '1676538041tPgw9827',
		cs: '1676307280iZpw8516',
	},
	[FeatureNameUserpilotType.VisitorListLastVisited]: {
		en: '1676538046tEbl9955',
		cs: '1676307480uRrv5052',
	},
	[FeatureNameUserpilotType.ActivateChatbotSales]: {
		en: '1676538052xViw8195',
		cs: '1676307687sLqk2825',
	},
	[FeatureNameUserpilotType.ContactFormEnabledSales]: {
		en: '1676538062nZgq9588',
		cs: '1676307949kBiv3786',
	},
	[FeatureNameUserpilotType.UseChatbotTemplate]: {
		en: '1686213078qFoi0155',
		cs: '1685971573qOin2415',
	},
	[FeatureNameUserpilotType.UseLeadgenTemplate]: {
		en: '1688322674iYeu661',
		cs: '1688322671kSua8043',
	},
	[FeatureNameUserpilotType.AiReplyAssist]: {
		en: 'TKz1bCOtEU',
		cs: 'SFNFkh2QPy',
	},
}
