import { createSelector } from '@reduxjs/toolkit'

import { StripeSubscriptionStatus } from 'models'

import { StripeRootState } from './slice'

const getStripePromoCode = (state: StripeRootState) => state.stripe.promoCode
const getStripePromoCodeToApply = (state: StripeRootState) => state.stripe.promoCodeToApply
const getStripeSubscription = (state: StripeRootState) => state.stripe.subscription

const getIsSubscriptionPastDue = createSelector([getStripeSubscription], (stripeSubscription) => {
	if (!stripeSubscription) return false

	return stripeSubscription.status === StripeSubscriptionStatus.PastDue
})

const getHasUnpaidInvoice = createSelector([getStripeSubscription], (subscription) => {
	return !!subscription?.unpaidInvoiceId && subscription?.status === StripeSubscriptionStatus.PastDue
})

const getUnpaidInvoiceId = createSelector([getStripeSubscription], (subscription) => {
	return subscription?.unpaidInvoiceId
})

const getIsUpdatePrevented = createSelector([getStripeSubscription], (subscription) => {
	return subscription?.updatePrevented
})

export const stripeSharedSelectors = {
	getStripePromoCode,
	getStripeSubscription,
	getIsSubscriptionPastDue,
	getStripePromoCodeToApply,
	getHasUnpaidInvoice,
	getUnpaidInvoiceId,
	getIsUpdatePrevented,
}
