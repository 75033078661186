import * as yup from 'yup'

import { BillingInfo } from 'models'

import {
	CITY_MAX_LENGTH,
	COMPANY_MAX_LENGTH,
	EMAIL_MAX_LENGTH,
	IC_MAX_LENGTH,
	POSTAL_CODE_MAX_LENGTH,
	STREET_MAX_LENGTH,
} from './constants'

export type BillingInfoFormData = Pick<BillingInfo, 'countryCode' | 'vatNumber'> &
	Partial<Pick<BillingInfo, 'email' | 'company' | 'city' | 'street' | 'postalCode' | 'ico'>>

export type BillingInfoStripeFormData = Pick<
	BillingInfo,
	'countryCode' | 'vatNumber' | 'email' | 'company' | 'city' | 'street' | 'postalCode' | 'ico'
>

export const billingInfoFormSchema = yup.object().shape({
	countryCode: yup.string().required(),
	company: yup.string().max(COMPANY_MAX_LENGTH),
	street: yup.string().max(STREET_MAX_LENGTH),
	city: yup.string().max(CITY_MAX_LENGTH),
	postalCode: yup.string().max(POSTAL_CODE_MAX_LENGTH),
	ico: yup.string().max(IC_MAX_LENGTH),
	email: yup.string().max(EMAIL_MAX_LENGTH).email(),
})

export const paymentInfoFormSchema = yup.object().shape({
	paymentCountryCode: yup.string().required(),
})

export enum ValidationVatStatus {
	Valid,
	Invalid,
	Unknown,
}
