import {
	ChatsSearchRequestQuery,
	ChatStatus,
	ClosedChatsFilterType,
	OpenChatsFilterType,
	SelectedChatbotSimple,
} from 'models'
import { UserPreference } from 'shared/constants'
import { loadUserPreference, storeUserPreference } from 'shared/services'
import { getDateQuery } from 'modules/chatsSlice'

import { defaultAdvancedFilter, defaultFilter } from './constants'
import {
	ChatsFilter,
	ChatsFilterAdvanced,
	serializedAdvancedFilterSchema,
	SerializedChatsFilterAdvanced,
} from './types'

export const isSerializedAdvancedFilterValid = (filter: unknown): boolean =>
	serializedAdvancedFilterSchema.isValidSync(filter)

export const serializeAdvancedFilter = (filter: ChatsFilterAdvanced): SerializedChatsFilterAdvanced => {
	return {
		...filter,
		dateFrom: filter.dateFrom?.getTime() ?? null,
		dateTo: filter.dateTo?.getTime() ?? null,
	}
}

export const deserializeAdvancedFilter = (filter: SerializedChatsFilterAdvanced): ChatsFilterAdvanced => {
	return {
		...filter,
		dateFrom: filter.dateFrom ? new Date(filter.dateFrom) : null,
		dateTo: filter.dateTo ? new Date(filter.dateTo) : null,
	}
}

const loadAdvancedFilter = (): ChatsFilterAdvanced | null => {
	const filter = loadUserPreference<SerializedChatsFilterAdvanced>(UserPreference.ChatsFilter)
	if (!filter || !isSerializedAdvancedFilterValid(filter)) return null
	return deserializeAdvancedFilter(filter)
}

export const saveAdvancedFilter = (filter: ChatsFilterAdvanced) => {
	storeUserPreference(UserPreference.ChatsFilter, serializeAdvancedFilter(filter))
}

export const isAdvancedFilterActive = (filter: ChatsFilterAdvanced) => {
	return (
		filter.query !== defaultAdvancedFilter.query ||
		filter.ratings !== defaultAdvancedFilter.ratings ||
		filter.agentIds !== defaultAdvancedFilter.agentIds ||
		filter.groupId !== defaultAdvancedFilter.groupId ||
		filter.pageUrl !== defaultAdvancedFilter.pageUrl ||
		filter.locIp !== defaultAdvancedFilter.locIp ||
		filter.visitorId !== defaultAdvancedFilter.visitorId ||
		filter.tags !== defaultAdvancedFilter.tags
	)
}
export const getAdvancedFilter = (filter: ChatsFilter, filterUpdates: Partial<ChatsFilterAdvanced>): ChatsFilter => {
	const isDateFilterActive =
		filterUpdates.dateFrom || filterUpdates.dateTo
			? true
			: filterUpdates.dateFrom === null && filterUpdates.dateTo === null
			? false
			: filter.isDateFilterActive

	return {
		...filter,
		isDateFilterActive,
		advanced: { ...filter.advanced, ...filterUpdates },
	}
}
export const initDefaultFilter = (): ChatsFilter => {
	const storedFilter = loadAdvancedFilter()
	if (!storedFilter) return defaultFilter

	return {
		...getAdvancedFilter(defaultFilter, storedFilter),
		isAdvancedFilterActive: isAdvancedFilterActive(storedFilter),
	}
}

export const createQueryFilter = (filter: ChatsFilter, query: string) => {
	return {
		...filter,
		advanced: { ...filter.advanced, query },
	}
}

export const switchMoreAgentsFilter = (filter: ChatsFilter) => ({
	...filter,
	moreAgentsVisible: !filter.moreAgentsVisible,
})

export const switcMoreChatbotsFilter = (filter: ChatsFilter) => ({
	...filter,
	moreChatbotsVisible: !filter.moreChatbotsVisible,
})

export const switchMoreAutoMessagesFilter = (filter: ChatsFilter) => ({
	...filter,
	moreAutoMessagesVisible: !filter.moreAutoMessagesVisible,
})

export const createResetFilter = (filter: ChatsFilter) => ({
	...filter,
	isAdvancedFilterActive: false,
	filteredVisitorName: null,
	advanced: {
		...defaultFilter.advanced,
		dateFrom: filter.advanced.dateFrom,
		dateTo: filter.advanced.dateTo,
	},
})

export const saveOpenChatsFilter = (filter: OpenChatsFilterType) => {
	storeUserPreference(UserPreference.OpenChatsFilter, filter)
}

export const saveClosedChatsFilter = (filter: ClosedChatsFilterType) => {
	storeUserPreference(UserPreference.ClosedChatsFilter, filter)
}

export const isSecondaryFiltersPanelOpen = (filter: ChatsFilter): boolean => {
	const { advanced } = filter
	if (advanced.groupId.length > 0) return true
	if (advanced.pageUrl.length > 0) return true
	if (advanced.locIp.length > 0) return true
	if (advanced.tags.length > 0) return true
	return false
}

export const createClosedChatsSearchRequestFilters = (advanced: ChatsFilterAdvanced) => {
	let advancedFilter: { [key: string]: string | string[] | number | number[] | Date } = {}
	const apiFilters = new Set(['query', 'pageUrl', 'botIds'])

	Object.entries(advanced).forEach(([key, val]) => {
		if (apiFilters.has(key) && val !== null && val !== '') {
			if (key === 'botIds') {
				if (advanced.botIds.length > 0) {
					advancedFilter = { ...advancedFilter, botIds: [...advanced.botIds] }
				}
			} else {
				advancedFilter = { ...advancedFilter, [key]: val }
			}
		}
	})

	return advancedFilter
}

export const createClosedChatsSearchRequestQuery = (advanced: ChatsFilterAdvanced, isChatbotChat?: boolean) => {
	const queryArray: ChatsSearchRequestQuery[] = isChatbotChat ? [] : [{ field: 'status', value: [ChatStatus.Closed] }]
	const { ratings, groupId, agentIds, tags, botIds, locIp, dateFrom, dateTo } = advanced

	// Ratings
	if (ratings.length > 0) {
		queryArray.push({ field: 'ratingValue', value: ratings })
	}

	// Group
	if (groupId !== '' && groupId !== null) {
		queryArray.push({ field: 'groupId', value: groupId })
	}

	// Agent
	if (agentIds.length > 0) {
		queryArray.push({ field: 'agentIds', value: agentIds })
	}

	// Tags
	if (tags.length > 0) {
		queryArray.push({ field: 'tags', value: tags })
	}

	// Chatbots
	if (isChatbotChat && botIds.length > 0) {
		queryArray.push({ field: 'botIds', value: botIds })
	}

	// IP
	if (locIp) {
		queryArray.push({ field: 'locIp', value: locIp })
	}

	// Date
	if (dateFrom || dateTo) {
		queryArray.push(getDateQuery(dateFrom, dateTo))
	}

	return queryArray
}

export const loadOpenChatsFilter = (): OpenChatsFilterType => {
	const filter = loadUserPreference<OpenChatsFilterType>(UserPreference.OpenChatsFilter)
	return filter ?? OpenChatsFilterType.All
}

export const loadClosedChatsFilter = (): ClosedChatsFilterType => {
	const filter = loadUserPreference<ClosedChatsFilterType>(UserPreference.ClosedChatsFilter)
	return filter ?? ClosedChatsFilterType.Agents
}

export const sortAutomations = (bots: SelectedChatbotSimple[]): SelectedChatbotSimple[] => {
	return bots.sort((a: SelectedChatbotSimple, b: SelectedChatbotSimple) => a.title.localeCompare(b.title))
}
