import {
	ChatHistory,
	GatewayInfoShopify,
	GatewayInfoTransfer,
	GatewayInfoTransferInvoice,
	GatewayInfoTransferOrder,
	PackageConfiguration,
	PackageInfo,
	PackageIntervalAvailable,
	PackageName,
	PackageNameUltimate,
	PackagesPackageName,
	PackageStatus,
	SelectedPackageInfo,
} from 'models'
import { differenceInDays } from 'shared/utils'
import { isCheckoutIntervalValid, isCheckoutPackageValid } from 'modules/checkout/utils'

const FREE_DOWNGRADED_DAYS = 3
const TRIAL_EXPIRING_DAYS = 7
const PACKAGE_EXPIRING_DAYS = 14
const LOW_REMAINING_DAYS = 10

const formattedPackageNamesMap = {
	[PackageName.Trial]: 'Trial',
	[PackageName.Free]: 'Free',
	[PackageName.Mini]: 'Mira AI',
	[PackageName.Basic]: 'Standard',
	[PackageName.Pro]: 'Pro',
	[PackageNameUltimate]: 'Ultimate',
}

export const isGatewayInfoTransfer = (gatewayInfo: PackageInfo['gatewayInfo']): gatewayInfo is GatewayInfoTransfer => {
	return !!gatewayInfo && gatewayInfo.type === 'transfer'
}

export const isGatewayInfoShopify = (gatewayInfo: PackageInfo['gatewayInfo']): gatewayInfo is GatewayInfoShopify => {
	return !!gatewayInfo && gatewayInfo.type === 'shopify'
}

export const formatPackageName = (packageName: PackageName | PackagesPackageName): string => {
	return formattedPackageNamesMap[packageName]
}

export const isPaidPackage = (packageName: PackageName): boolean => {
	return packageName !== PackageName.Free && packageName !== PackageName.Trial
}

export const isDowngradedToFree = (packageInfo: PackageInfo): boolean => {
	return (
		packageInfo.name === PackageName.Free &&
		differenceInDays(new Date(), new Date(packageInfo.startedDate)) <= FREE_DOWNGRADED_DAYS
	)
}

export const isTrialExpiring = (packageInfo: PackageInfo): boolean => {
	return (
		packageInfo.name === PackageName.Trial &&
		packageInfo.remainingDays <= TRIAL_EXPIRING_DAYS &&
		packageInfo.remainingDays > 0
	)
}

export const isPackageExpiring = (packageInfo: PackageInfo): boolean => {
	return (
		isPaidPackage(packageInfo.name) &&
		!packageInfo.autoRenewal &&
		packageInfo.remainingDays <= PACKAGE_EXPIRING_DAYS &&
		packageInfo.remainingDays > 0
	)
}

export const isRemainingDaysCountLow = (packageInfo: PackageInfo): boolean => {
	return packageInfo.remainingDays <= LOW_REMAINING_DAYS && packageInfo.remainingDays > 0
}

export const isPackageExpired = (packageInfo: PackageInfo): boolean => packageInfo.status === PackageStatus.Expired

export const isPackagePastDue = (packageInfo: PackageInfo): boolean => packageInfo.status === PackageStatus.PastDue

export const isTrialPackage = (packageName: PackageName): boolean => packageName === PackageName.Trial

export const isFreePackage = (packageName: PackageName): boolean => packageName === PackageName.Free

export const isMiniPackage = (packageName: PackageName): boolean => packageName === PackageName.Mini

export const isProPackage = (packageName: PackageName): boolean => packageName === PackageName.Pro

export const isBasicPackage = (packageName: PackageName): boolean => packageName === PackageName.Basic

export const hasUnpaidFirstInvoice = ({ gatewayInfo }: PackageInfo): boolean => {
	if (!isGatewayInfoTransfer(gatewayInfo)) return false
	return !!gatewayInfo.pendingCreateInvoice
}

export const hasUnpaidGeneratedInvoice = ({ gatewayInfo }: PackageInfo): boolean => {
	if (!isGatewayInfoTransfer(gatewayInfo)) return false
	return !!(gatewayInfo.pendingMigrateInvoice || gatewayInfo.pendingExtendInvoice || gatewayInfo.pendingUpdateInvoice)
}

export const getPendingInvoice = ({ gatewayInfo }: PackageInfo): GatewayInfoTransferInvoice | null => {
	if (!isGatewayInfoTransfer(gatewayInfo)) return null
	if (gatewayInfo.pendingCreateInvoice) return gatewayInfo.pendingCreateInvoice
	if (gatewayInfo.pendingMigrateInvoice) return gatewayInfo.pendingMigrateInvoice
	if (gatewayInfo.pendingUpdateInvoice) return gatewayInfo.pendingUpdateInvoice
	if (gatewayInfo.pendingExtendInvoice) return gatewayInfo.pendingExtendInvoice
	return null
}

export const getPendingOrder = ({ gatewayInfo }: PackageInfo): GatewayInfoTransferOrder | null => {
	if (!isGatewayInfoTransfer(gatewayInfo)) return null
	if (gatewayInfo.pendingCreateOrder) return gatewayInfo.pendingCreateOrder
	if (gatewayInfo.pendingMigrateOrder) return gatewayInfo.pendingMigrateOrder
	if (gatewayInfo.pendingUpdateOrder) return gatewayInfo.pendingUpdateOrder
	if (gatewayInfo.pendingExtendOrder) return gatewayInfo.pendingExtendOrder
	return null
}

export const isShopifySubscriptionUpdatable = (packageInfo: PackageInfo): boolean => packageInfo.isPaid

export const getShopifyConfirmationUrl = ({ gatewayInfo }: PackageInfo): string | null => {
	if (!isGatewayInfoShopify(gatewayInfo)) return null
	return gatewayInfo.pendingSubscriptionConfirmationUrl
}

export const getChatHistory = (name: PackageName): ChatHistory | null => {
	switch (name) {
		case PackageName.Free:
		case PackageName.Mini: {
			return ChatHistory.Days14
		}
		case PackageName.Basic: {
			return ChatHistory.Months12
		}
		case PackageName.Pro:
		case PackageName.Trial: {
			return ChatHistory.Months36
		}
		default: {
			return null
		}
	}
}

export const getTransferPackageConfiguration = ({
	pendingOrder,
	name,
	interval,
	agents,
	chatbotConversationsLimit,
	livechatConversationsLimit,
}: SelectedPackageInfo): PackageConfiguration => {
	if (pendingOrder) {
		return {
			package: pendingOrder.package as PackageName,
			interval: pendingOrder.interval as PackageIntervalAvailable,
			...(pendingOrder.agents && { agents: pendingOrder.agents }),
			...(pendingOrder.chatbotConversations && { chatbotConversations: pendingOrder.chatbotConversations }),
			...(pendingOrder.livechatConversations && { livechatConversations: pendingOrder.livechatConversations }),
		}
	}

	if (!isCheckoutPackageValid(name) || !isCheckoutIntervalValid(interval)) {
		throw new Error('Invalid package configuration')
	}

	return {
		package: name,
		interval,
		...(agents && { agents }),
		...(chatbotConversationsLimit && { chatbotConversations: chatbotConversationsLimit }),
		...(livechatConversationsLimit && { livechatConversations: livechatConversationsLimit }),
	}
}
