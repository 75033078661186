import { useCallback, useMemo } from 'react'
import { ToastId, useToast as useChakraToast } from '@chakra-ui/react'

import { NotificationProps as Props } from 'models'

import { NotificationComponent, ProductNotificationComponent } from 'shared/components/notification'

import { AiYearlyNotificationComponent } from '../components/notification/AiYearlyNotificationComponent'

export const useProductNotification = () => {
	const chakraToast = useChakraToast()

	const close = useCallback((id: ToastId) => chakraToast.close(id), [chakraToast])

	const show = useCallback(
		({ id = Math.random() + Date.now(), isClosable = true, onClickCloseButton, ...rest }: Props) => {
			chakraToast({
				id,
				position: 'bottom-left',
				duration: null,
				render: function renderToastComponent({ onClose }) {
					return (
						<ProductNotificationComponent
							{...rest}
							onClose={() => {
								onClose()
								onClickCloseButton && onClickCloseButton()
							}}
							isClosable={isClosable}
						/>
					)
				},
			})
		},
		[chakraToast],
	)

	return useMemo(() => ({ show, close }), [show, close])
}

export const useAiYearlyNotification = () => {
	const chakraToast = useChakraToast()

	const close = useCallback((id: ToastId) => chakraToast.close(id), [chakraToast])
	const show = useCallback(
		({
			id = Math.random() + Date.now(),
			duration = null,
			position = 'bottom-left',
			isClosable = true,
			onCloseComplete,
			...rest
		}: Props) => {
			chakraToast({
				id,
				position,
				duration,
				onCloseComplete,
				containerStyle: {
					m: '50px',
				},
				render: function renderToastComponent({ onClose }) {
					return <AiYearlyNotificationComponent {...rest} onClose={onClose} isClosable={isClosable} />
				},
			})
		},
		[chakraToast],
	)

	return useMemo(() => ({ show, close }), [show, close])
}

export const useNotification = () => {
	const chakraToast = useChakraToast()

	const close = useCallback((id: ToastId) => chakraToast.close(id), [chakraToast])

	const show = useCallback(
		({
			id = Math.random() + Date.now(),
			duration = 5000,
			position = 'top-right',
			isClosable = true,
			onCloseComplete,
			...rest
		}: Props) => {
			chakraToast({
				id,
				position,
				duration,
				onCloseComplete,
				render: function renderToastComponent({ onClose }) {
					return <NotificationComponent {...rest} onClose={onClose} isClosable={isClosable} />
				},
			})
		},
		[chakraToast],
	)

	const info = useCallback((props: Props) => show({ ...props, status: 'info' }), [show])
	const warning = useCallback((props: Props) => show({ ...props, status: 'warning' }), [show])
	const success = useCallback((props: Props) => show({ ...props, status: 'success' }), [show])
	const error = useCallback((props: Props) => show({ ...props, status: 'error' }), [show])

	return useMemo(() => ({ close, show, warning, success, error, info }), [close, error, info, show, success, warning])
}
