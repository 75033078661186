import { createAsyncThunk, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit'

import { AppThunkAction, DashboardState, Dictionary } from 'types'
import { CurrencyCode } from 'shared/models/Currency'
import {
	PackageInterval,
	PackagePricing,
	PackagesFeature,
	PackagesFeaturesTableV2,
	PackagesType,
} from 'shared/models/Package'
import { normalize } from 'utils'
import { billingInfoSelectors, fetchStripeCustomer } from 'modules/billingInfo'
import { packageSelectors } from 'modules/package'
import { userSelectors } from 'modules/user'

import { packagesApi } from './api'

export type PackagesRootState = Pick<DashboardState, 'packages'>
export type PackagesState = typeof initialState

const DEFAULT_INTERVAL = PackageInterval.Year

export const initialState = {
	isFetching: false,
	isError: false,
	isFetchingFeatureSet: false,
	isFetchingFeatureSetV2: false,
	packages: {} as Dictionary<PackagePricing>,
	featureSet: null as null | PackagesFeature[],
	featureSetV2: null as null | PackagesFeaturesTableV2,
	showConfirmFreeModal: false,
	interval: DEFAULT_INTERVAL as PackageInterval,
	selectedPackageType: PackagesType.Livechat,
}

export const fetchPackagesFeatureSet = createAsyncThunk('packages_feature_set/FETCH', () => {
	return packagesApi.getPackagesFeatureSet()
})

export const fetchPackagesFeatureSetV2 = createAsyncThunk('packages_feature_set_v2/FETCH', () => {
	return packagesApi.getPackagesFeatureSetV2()
})

export const fetchPackages = createAsyncThunk('packages/FETCH', (currency?: CurrencyCode) => {
	return packagesApi.getPackages(currency)
})

export const fetchPackagesWithCurrency =
	(fetchCustomer = true): AppThunkAction<Promise<boolean>> =>
	async (dispatch, getState) => {
		const state = getState()
		const isUserAdmin = userSelectors.isUserAdmin(state)
		const isStripeAllowed = packageSelectors.getIsAllowedStripePaymentGateway(state)
		const currentCurrency = billingInfoSelectors.getBillingInfoCurrency(state)

		// For Brain tree and user role agent cases
		if (!isStripeAllowed || !isUserAdmin) {
			await dispatch(fetchPackages())
			return true
		}

		// For Stripe case
		try {
			if (fetchCustomer) {
				const customerResultAction = await dispatch(fetchStripeCustomer())
				const { currency } = unwrapResult(customerResultAction)

				await dispatch(fetchPackages(currency))
			} else {
				await dispatch(fetchPackages(currentCurrency))
			}
		} catch {
			await dispatch(fetchPackages())
		}

		return true
	}

const slice = createSlice({
	name: 'packages',
	initialState,
	reducers: {
		showConfirmFreeModal: (state) => {
			state.showConfirmFreeModal = true
		},
		hideConfirmFreeModal: (state) => {
			state.showConfirmFreeModal = false
		},
		setPackagesInterval: (state, { payload }: PayloadAction<PackageInterval>) => {
			state.interval = payload
		},
		setSelectedPackageType: (state, { payload }: PayloadAction<PackagesType>) => {
			state.selectedPackageType = payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPackages.pending, (state) => {
			state.isFetching = true
			state.isError = false
		})
		builder.addCase(fetchPackages.fulfilled, (state, { payload }) => {
			state.isFetching = false
			state.isError = false
			state.packages = normalize('name', payload)
		})
		builder.addCase(fetchPackages.rejected, (state) => {
			state.isFetching = false
			state.isError = true
		})

		builder.addCase(fetchPackagesFeatureSet.pending, (state) => {
			state.isFetchingFeatureSet = true
		})
		builder.addCase(fetchPackagesFeatureSet.fulfilled, (state, { payload }) => {
			state.isFetchingFeatureSet = false
			state.featureSet = payload
		})
		builder.addCase(fetchPackagesFeatureSet.rejected, (state) => {
			state.isFetchingFeatureSet = false
		})

		builder.addCase(fetchPackagesFeatureSetV2.pending, (state) => {
			state.isFetchingFeatureSetV2 = true
		})
		builder.addCase(fetchPackagesFeatureSetV2.fulfilled, (state, { payload }) => {
			state.isFetchingFeatureSetV2 = false
			state.featureSetV2 = payload
		})
		builder.addCase(fetchPackagesFeatureSetV2.rejected, (state) => {
			state.isFetchingFeatureSetV2 = false
		})
	},
})

const { reducer, actions } = slice
export const { showConfirmFreeModal, hideConfirmFreeModal, setPackagesInterval, setSelectedPackageType } = actions
export default reducer
