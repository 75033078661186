import { ConversationsApi } from 'models/ConversationsStatistics'
import { apiClient } from 'shared/utils'

const API_RESOURCE_CONVERSATIONS_STATS = 'analytics-api/v1/conversations/stats'

const getConversationsStatistics = ({ range }: ConversationsApi.StatsQuery) => {
	return apiClient.get<ConversationsApi.StatsResponse>(`${API_RESOURCE_CONVERSATIONS_STATS}?range=${range}`)
}

export const conversationStatisticsApi = {
	getConversationsStatistics,
}
