import { BillingInfo } from 'models'

export const VAT_PREFIX_LENGTH = 2
export const VAT_NUMBER_LENGTH = 32
export const INPUT_MAX_LENGTH = 50
export const POSTAL_CODE_MAX_LENGTH = 16
export const IC_MAX_LENGTH = 16
export const EMAIL_MAX_LENGTH = 96
export const COMPANY_MAX_LENGTH = 96
export const CITY_MAX_LENGTH = 64
export const STREET_MAX_LENGTH = 100

export const FIELD_NAME = {
	vatNumber: 'vatNumber',
	ico: 'ico',
	company: 'company',
	street: 'street',
	city: 'city',
	postalCode: 'postalCode',
	email: 'email',
	countryCode: 'countryCode',
}

export const emptyBillingInfo: BillingInfo = {
	city: '',
	company: '',
	countryCode: '',
	countryName: '',
	email: '',
	ico: '',
	phone: '',
	postalCode: '',
	street: '',
	vatNumber: '',
	vatValid: false,
	vatValidStatus: null,
}
