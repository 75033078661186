import {
	createAsyncThunk,
	createEntityAdapter,
	createSelector,
	createSlice,
	isPending,
	isRejected,
	PayloadAction,
} from '@reduxjs/toolkit'

import { AppThunkAction, DashboardState } from 'types'
import { BotIdentity, BotIdentityUpdate } from 'shared/models/Chatbot'
import { flashMessage } from 'shared/utils'
import { chatbotDetailSelectors, setSelectedChatbotIdentityId } from 'modules/chatbotDetail'
import { updateChatbot } from 'modules/chatbotDetail/actions'

import { chatbotIdentityApi } from './api'
import { DEFAULT_IDENTITY } from './constants'
import { IdentitiesModalType, IdentityFormData } from './types'

type ChatbotIdentitiesState = Pick<DashboardState, 'chatbotIdentities'>

const chatbotIdentitiesAdapter = createEntityAdapter<BotIdentity>()

const initialState = chatbotIdentitiesAdapter.getInitialState({
	isFetching: false,
	isSaving: false,
	isEditingAvatar: false,
	isCreateFormDirty: false,
	modalData: {
		isOpen: false,
		selectedIdentity: DEFAULT_IDENTITY,
		modalType: IdentitiesModalType.Pick,
	},
	currentChatbotIdentity: null as null | BotIdentity,
})

export const fetchIdentities = createAsyncThunk('chatbotIdentities/FETCH_ALL', () => {
	return chatbotIdentityApi.listIdentities()
})

export const createIdentity = createAsyncThunk(
	'chatbotIdentities/CREATE',
	(data: { identity: BotIdentityUpdate; botId: string }) => {
		return chatbotIdentityApi.createIdentity(data.botId, data.identity)
	},
)

export const editIdentity = createAsyncThunk(
	'chatbotIdentities/EDIT',
	(data: { id: BotIdentity['id']; identity: BotIdentityUpdate }) => {
		return chatbotIdentityApi.editIdentity(data.id, data.identity)
	},
)

export const deleteIdentity = createAsyncThunk('chatbotIdentities/DELETE', (id: BotIdentity['id']) => {
	return chatbotIdentityApi.deleteIdentity(id)
})

export const handleIdentitySubmit =
	(data: IdentityFormData): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()

		const { modalType } = chatbotIdentitiesSelectors.getModalData(state)
		const botId = chatbotDetailSelectors.getChatbotId(state)

		const { id, ...rest } = data

		if (modalType === IdentitiesModalType.Edit) {
			const resultEdit = await dispatch(editIdentity({ id, identity: rest }))
			if (editIdentity.fulfilled.match(resultEdit)) {
				dispatch(openPickModal())
				flashMessage.success('chatbot.identity.flashMessage.edit.success')
			}
		} else {
			const resultCreate = await dispatch(createIdentity({ identity: rest, botId }))
			if (createIdentity.fulfilled.match(resultCreate)) {
				dispatch(setSelectedChatbotIdentityId(resultCreate.payload.id))
				dispatch(closeModal())
				flashMessage.success('chatbot.identity.flashMessage.create.success')
			}
		}
	}

export const handleIdentityChange = (): AppThunkAction => async (dispatch, getState) => {
	const state = getState()
	const selectedChatbot = chatbotDetailSelectors.getSelectedChatbot(state)
	const isSelectedChatbotActive = chatbotDetailSelectors.getIsSelectedChatbotActive(state)
	const identity = chatbotIdentitiesSelectors.getCurrentChatbotIdentity(state)

	if (!selectedChatbot || !identity) return
	const { id } = selectedChatbot

	const changes = { identityId: identity.id }

	const resultAction = await dispatch(updateChatbot({ botId: id, changes }))
	if (updateChatbot.fulfilled.match(resultAction)) {
		if (isSelectedChatbotActive) {
			flashMessage.success('chatbot.save.success')
		}
		dispatch(closeModal())
	} else {
		flashMessage.error('general.error')
	}
}

export const chatbotIdentitiesSlice = createSlice({
	name: 'chatbotIdentities',
	initialState,
	reducers: {
		openPickModal: (state) => {
			state.modalData.isOpen = true
			state.modalData.modalType = IdentitiesModalType.Pick
		},
		openCreateModal: (state) => {
			state.modalData.isOpen = true
			state.modalData.modalType = IdentitiesModalType.Create
			state.modalData.selectedIdentity = DEFAULT_IDENTITY
		},
		openEditModal: (state, { payload }: PayloadAction<BotIdentity>) => {
			state.modalData.isOpen = true
			state.modalData.selectedIdentity = payload
			state.modalData.modalType = IdentitiesModalType.Edit
		},
		closeModal: (state) => {
			state.modalData.isOpen = false
		},
		setIsEditingAvatar(state, { payload }: PayloadAction<boolean>) {
			state.isEditingAvatar = payload
		},
		setIsCreateFormDirty(state, { payload }: PayloadAction<boolean>) {
			state.isCreateFormDirty = payload
		},
		setCurrentChatbotIdentity: (state, { payload }: PayloadAction<BotIdentity | null>) => {
			state.currentChatbotIdentity = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchIdentities.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchIdentities.fulfilled, (state, { payload }) => {
				state.isFetching = false
				chatbotIdentitiesAdapter.setAll(state, payload)
			})
			.addCase(fetchIdentities.rejected, (state) => {
				state.isFetching = false
			})
		builder.addCase(createIdentity.fulfilled, (state, { payload }) => {
			chatbotIdentitiesAdapter.addOne(state, payload)
			state.isSaving = false
		})
		builder.addCase(editIdentity.fulfilled, (state, { payload }) => {
			chatbotIdentitiesAdapter.upsertOne(state, payload)
			state.isSaving = false
		})
		builder.addCase(updateChatbot.fulfilled, (state) => {
			state.isSaving = false
		})
		builder.addCase(deleteIdentity.fulfilled, (state, { meta }) => {
			chatbotIdentitiesAdapter.removeOne(state, meta.arg)
			if (state.currentChatbotIdentity?.id === meta.arg) {
				state.currentChatbotIdentity = null
			}
		})
		builder
			.addMatcher(isPending(createIdentity, editIdentity, updateChatbot), (state) => {
				state.isSaving = true
			})
			.addMatcher(isRejected(createIdentity, editIdentity, updateChatbot), (state) => {
				state.isSaving = false
			})
	},
})

const entitiesSelectors = chatbotIdentitiesAdapter.getSelectors<ChatbotIdentitiesState>(
	(state) => state.chatbotIdentities,
)
const getChatbotIdentities = (state: ChatbotIdentitiesState) => entitiesSelectors.selectAll(state)
const getModalData = (state: ChatbotIdentitiesState) => state.chatbotIdentities.modalData
const getCurrentChatbotIdentity = (state: ChatbotIdentitiesState) => state.chatbotIdentities.currentChatbotIdentity
const getIsSaving = (state: ChatbotIdentitiesState) => state.chatbotIdentities.isSaving
const getIsFetching = (state: ChatbotIdentitiesState) => state.chatbotIdentities.isFetching
const getIsEditingAvatar = (state: ChatbotIdentitiesState) => state.chatbotIdentities.isEditingAvatar
const getIsCreateFormDirty = (state: ChatbotIdentitiesState) => state.chatbotIdentities.isCreateFormDirty

const getIdentityData = createSelector(
	[chatbotDetailSelectors.getSelectedChatbot, getChatbotIdentities],
	(bot, identities): BotIdentity | null => {
		if (!bot) return null
		return identities.find((i) => i.id === bot.identityId) ?? null
	},
)

const getIdentityFormData = createSelector([getModalData], (modalData): IdentityFormData => {
	const { id, name, avatarUrl, gender } = modalData.selectedIdentity

	return {
		id,
		name,
		avatarImg: avatarUrl,
		gender,
	}
})

const hasCreatedIdentity = createSelector([getChatbotIdentities], (identities): boolean => identities.length > 0)
const getIsPickerFormDirty = createSelector(
	[getIdentityData, getCurrentChatbotIdentity],
	(identity, pickedIdentity) => {
		if (!pickedIdentity) return false
		return identity?.id !== pickedIdentity.id
	},
)

const { reducer, actions } = chatbotIdentitiesSlice

export const chatbotIdentitiesSelectors = {
	getChatbotIdentities,
	getModalData,
	getCurrentChatbotIdentity,
	getIdentityData,
	getIdentityFormData,
	getIsSaving,
	getIsFetching,
	getIsEditingAvatar,
	getIsCreateFormDirty,
	hasCreatedIdentity,
	getIsPickerFormDirty,
}

export const {
	openPickModal,
	openCreateModal,
	openEditModal,
	closeModal,
	setCurrentChatbotIdentity,
	setIsEditingAvatar,
	setIsCreateFormDirty,
} = actions
export default reducer
