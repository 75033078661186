import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { accountSelectors } from './selectors'

export const useAccountBlocked = () => {
	const isBlocked = useSelector(accountSelectors.getIsAccountBlocked)
	const isBlockedByAdmin = useSelector(accountSelectors.getIsAccountBlockedByAdmin)
	const isBlockedByExpiredPackage = useSelector(accountSelectors.getIsAccountBlockedByExpiredPackage)

	return useMemo(
		() => ({ isBlocked, isBlockedByAdmin, isBlockedByExpiredPackage }),
		[isBlocked, isBlockedByAdmin, isBlockedByExpiredPackage],
	)
}
