export enum GTMData {
	Accounts = 'accounts',
	Containers = 'containers',
	Workspaces = 'workspaces',
}

export type GTMField = 'account' | 'container' | 'workspace'

export type GTMError =
	| 'load'
	| 'auth'
	| 'client'
	| 'wrongApiKey'
	| 'wrongClientId'
	| 'notExist'
	| 'data'
	| 'tag'
	| 'trigger'
	| 'publish'
	| 'alreadyExists'
	| ''

export type GTMAccount = {
	accountId: string
	name: string
	path: string
}

export type GTMContainer = {
	accountId: string
	containerId: string
	fingerprint: string
	name: string
	path: string
	publicId: string
	tagManagerUrl: string
	usageContext: string[]
}

export type GTMWorkspace = {
	path: string
	accountId: string
	containerId: string
	workspaceId: string
	name: string
	fingerprint: string
	tagManagerUrl: string
}
