import { mdiChat, mdiEmail } from '@mdi/js'

import { ChannelType } from 'models'
import { getWidgetGradient } from 'shared/utils'
import { px } from 'styles'
import { ChannelIconType, ChannelTypeExtensions } from 'modules/integrations'
import { DEFAULT_COLOR } from 'modules/onboarding'

import { Flex, Icon, Image } from 'core'

type Props = {
	channel: ChannelIconType
	size?: number
	isDisabled?: boolean
}

type StylesType = {
	backgroundColor?: string
	bgGradient?: string
}

const DEFAULT_SIZE = 16

export const IconChannel = ({ channel, size = DEFAULT_SIZE, isDisabled = false }: Props) => {
	const emailBg = 'primary'
	const appBg = 'yellow.500'

	let icon = null
	let styles: StylesType = {}

	switch (channel) {
		case ChannelType.Email: {
			icon = <Icon path={mdiEmail} textColor="white" />
			styles = { backgroundColor: isDisabled ? 'gray.500' : emailBg }
			break
		}
		case ChannelType.FacebookMessenger: {
			icon = <Image path="assets/logos/other/messenger.svg" />
			break
		}
		case ChannelType.App: {
			icon = <Icon path={mdiChat} textColor="white" />
			styles = { backgroundColor: appBg }
			break
		}
		case ChannelType.Default: {
			icon = <Icon path={mdiChat} textColor="white" />
			styles = isDisabled ? { backgroundColor: 'gray.500' } : { bgGradient: getWidgetGradient(DEFAULT_COLOR) }
			break
		}
		case ChannelTypeExtensions.WhatsApp: {
			icon = <Image path="assets/logos/other/whatsapp.svg" />
			break
		}
		case ChannelTypeExtensions.GoogleAnalytics: {
			icon = <Image path="assets/logos/other/google-analytics.svg" />
			break
		}
	}

	if (!icon) return null

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			width={px(size)}
			minWidth={px(size)}
			height={px(size)}
			borderRadius="50%"
			{...styles}
		>
			{icon}
		</Flex>
	)
}
