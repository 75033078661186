import { TranslationService as T } from 'shared/services'

// TODO: refactor
export const getRatingString = (value: number | string) => {
	switch (value) {
		case 5: {
			return T.translate('rating.positive')
		}
		case 3: {
			return T.translate('rating.neutral')
		}
		case 1: {
			return T.translate('rating.negative')
		}
		default: {
			return ''
		}
	}
}
