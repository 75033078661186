import { useEffect, useRef } from 'react'

export const useUpdateEffect: typeof useEffect = (effect, deps) => {
	const isFirstMount = useFirstMountState()

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (!isFirstMount) {
			return effect()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps)
}

const useFirstMountState = (): boolean => {
	const isFirst = useRef(true)

	if (isFirst.current) {
		isFirst.current = false

		return true
	}

	return isFirst.current
}
