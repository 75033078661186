import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { DashboardState } from 'types'
import { ApnsSdkData, FirebaseSdkData, Platform } from 'shared/models/MobileSdk'

import { mobileSdkApi } from './api'

const initialState = {
	iosData: null as ApnsSdkData | null,
	androidData: null as FirebaseSdkData | null,
	isFetching: false,
}

export const getAndroidConfig = createAsyncThunk('android_data/GET', () => mobileSdkApi.getAndroidConfig())

export const getIosConfig = createAsyncThunk('ios_data/GET', () => mobileSdkApi.getIosConfig())

export const fetchAndroidConfig = createAsyncThunk('android_data/FETCH', (data: FormData) =>
	mobileSdkApi.fetchAndroidConfig(data),
)

export const fetchIosConfig = createAsyncThunk('ios_data/FETCH', (data: FormData) => mobileSdkApi.fetchIosConfig(data))

export const deleteConfig = createAsyncThunk('data/DELETE', (platform: Platform) => mobileSdkApi.deleteConfig(platform))

const slice = createSlice({
	name: 'mobileSdk',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getAndroidConfig.pending, (state) => {
			state.isFetching = true
		})
		builder.addCase(getAndroidConfig.fulfilled, (state, { payload }) => {
			state.isFetching = false
			state.androidData = payload
		})
		builder.addCase(getAndroidConfig.rejected, (state) => {
			state.isFetching = false
		})

		builder.addCase(getIosConfig.pending, (state) => {
			state.isFetching = true
		})
		builder.addCase(getIosConfig.fulfilled, (state, { payload }) => {
			state.isFetching = false
			state.iosData = payload
		})
		builder.addCase(getIosConfig.rejected, (state) => {
			state.isFetching = false
		})

		builder.addCase(deleteConfig.fulfilled, (state, { meta }) => {
			if (meta.arg === Platform.ios) {
				state.iosData = null
			}
			if (meta.arg === Platform.android) {
				state.androidData = null
			}
		})
	},
})

const isFetching = (state: DashboardState) => state.mobileSdk.isFetching
const getAndroidData = (state: DashboardState) => state.mobileSdk.androidData
const getIosData = (state: DashboardState) => state.mobileSdk.iosData

const getAndroidFormData = createSelector([getAndroidData], (data) => {
	return data?.fileName ? data : undefined
})

const getIosFormData = createSelector([getIosData], (data) => {
	return data?.fileName && data.keyId && data.bundleId && data.teamId ? data : undefined
})

const { reducer } = slice
export default reducer

export const mobileSdkSelectors = {
	isFetching,
	getAndroidFormData,
	getIosFormData,
}
