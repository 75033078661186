import { createAsyncThunk } from '@reduxjs/toolkit'

import { Chatbot, ChatbotCreateRequest, ChatbotUpdateParams } from 'models'
import { AppLang } from 'shared/types'

import { chatbotDetailApi } from './api'

export const fetchChatbot = createAsyncThunk('chatbot/FETCH', async (id: string) => {
	return chatbotDetailApi.getChatbot(id)
})

export const fetchPublishedChatbot = createAsyncThunk('chatbot/FETCH_PUBLISHED', (id: string) => {
	return chatbotDetailApi.getPublishedChatbot(id)
})

export const postChatbot = createAsyncThunk('chatbot/POST', async (data: ChatbotCreateRequest) => {
	return chatbotDetailApi.createChatbot(data)
})

export const updateChatbot = createAsyncThunk('chatbot/PATCH', ({ botId, changes }: ChatbotUpdateParams) => {
	return chatbotDetailApi.updateChatbot(botId, changes)
})

export const updateChatbotTitleThunk = createAsyncThunk(
	'chatbot/PATCH_TITLE',
	({ botId, title, isActive }: { botId: string; title: string; isActive?: boolean }) => {
		return chatbotDetailApi.updateChatbot(botId, { title, isActive })
	},
)

export const deleteChatbot = createAsyncThunk('chatbot/DELETE', async (id: Chatbot['id']) => {
	return chatbotDetailApi.deleteChatbot(id)
})

export const fetchChatbotActions = createAsyncThunk('chatbotActions/FETCH', async (lang: AppLang) => {
	return chatbotDetailApi.getChatbotActions(lang)
})
