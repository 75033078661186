import { cssVar } from '@chakra-ui/react'

import { colors } from '../foundations/colors'

const $arrowBg = cssVar('popper-arrow-bg')

// Box shadow for popover content component is set in global styles.ts

const lightBase = {
	content: {
		bg: 'white',
		color: 'black',
		[$arrowBg.variable]: 'white',
	},
}

export const Popover = {
	baseStyle: {
		content: {
			bg: 'gray.700',
			color: 'white',
			/* workaround to set arrow bg color, should be the same as content bg color
			 * https://github.com/chakra-ui/chakra-ui/issues/5821 */
			[$arrowBg.variable]: colors.gray[700],
		},
	},
	variants: {
		light: lightBase,
		textareaShortcuts: {
			...lightBase,
			popper: {
				maxWidth: 'full',
				minWidth: 'full',
				width: 'full',
				px: 2,
			},
		},
	},
}
