import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mdiPower } from '@mdi/js'

import { AgentStatus } from 'shared/models/Agent'
import { TranslationService as T } from 'shared/services'
import { agentsSelectors } from 'modules/agents'
import { updateUser, userSelectors } from 'modules/user'

import { Flex, Icon, Switch, Text } from 'core'

const SidebarAgentMenuStatus = () => {
	const dispatch = useDispatch()
	const agent = useSelector(agentsSelectors.getAgentByUser)
	const status = agent?.status ?? AgentStatus.Offline
	const isAgentOnline = status === AgentStatus.Online
	const isAgentActive = !!agent?.active
	const isUserUpdating = useSelector(userSelectors.isPendingUpdate)

	const handleChangeStatus = useCallback(() => {
		const newStatus = isAgentOnline ? AgentStatus.Offline : AgentStatus.Online
		dispatch(updateUser({ status: newStatus }))
	}, [dispatch, isAgentOnline])

	return (
		<Flex alignItems="center" justifyContent="space-between" py={1.5} pl={1.5}>
			<Flex alignItems="center">
				<Icon mr={1} fontSize={2} path={mdiPower} />
				<Text>{T.translate('sideBar.agentMenu.status.available')}</Text>
			</Flex>
			<Switch
				mr={2}
				variant="online-status"
				defaultChecked={isAgentOnline}
				isChecked={isAgentOnline}
				isDisabled={!isAgentActive || isUserUpdating}
				onChange={handleChangeStatus}
				data-testid="agent-status-switch"
			/>
		</Flex>
	)
}

export default SidebarAgentMenuStatus
