import { AgentsStatisticsSummary } from 'models'
import { MetricsType } from 'shared/models/Statistics'

import { Ratings, RatingsChartData } from './types'

export const TWO_YEARS_IN_MONTHS = 24
export const THREE_MONTHS = 3
export const DEFAULT_SUMMARY_VALUE = 0

export const ONLINE_ACTIVITY_KEY_SEPARATOR = '###'

const DEFAULT_AGENT = { id: 0, name: '', email: '' }
export const DEFAULT_AGENT_METRICS: AgentsStatisticsSummary = {
	new_conversation: 0,
	avg_time_to_close: 0,
	sum_time_to_close: 0,
	avg_first_response_time: 0,
	conversation_ratings: 0,
	conversation_ratings_count: 0,
	avg_response_time: 0,
	key: '',
	agent: { ...DEFAULT_AGENT },
}

export const DEFAULT_RATING: RatingsChartData = {
	key: '',
	[Ratings.Happy]: 0,
	[Ratings.Ok]: 0,
	[Ratings.Bad]: 0,
	values: {
		[Ratings.Happy]: 0,
		[Ratings.Ok]: 0,
		[Ratings.Bad]: 0,
	},
}

export const RatingsSmileMap = {
	[Ratings.Happy]: 'assets/illustrations/face-positive.svg',
	[Ratings.Ok]: 'assets/illustrations/face-neutral.svg',
	[Ratings.Bad]: 'assets/illustrations/face-negative.svg',
	Default: 'assets/illustrations/face-default.svg',
}

export enum StatisticSection {
	NewConversations = 'new-conversations',
	ServedConversations = 'served-conversations',
	ClosedConversations = 'closed-conversations',
	Rating = 'rating',
	ResponseTime = 'response-time',
	Agents = 'agents',
	OnlineTime = 'online-time',
	TagsUsage = 'tags-usage',
	NewContacts = 'new-contacts',
}

export const allowedStatisticsForTags: StatisticSection[] = [
	StatisticSection.NewConversations,
	StatisticSection.ServedConversations,
	StatisticSection.ClosedConversations,
	StatisticSection.Rating,
	StatisticSection.NewContacts,
]

export const allowedMetricsTypeForTags: MetricsType[] = [
	MetricsType.NewConversation,
	MetricsType.ServedConversation,
	MetricsType.ClosedConversation,
	MetricsType.ConversationRatings,
	MetricsType.ContactAcquired,
]
