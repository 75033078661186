import { useSelector } from 'react-redux'
import { mdiHome } from '@mdi/js'

import { FeatureFlag } from 'models'
import { ConfigurationService, TranslationService as T } from 'shared/services'
import { routes } from 'configuration/routes'
import { useAppTheme } from 'styles'
import { chatsSelectors } from 'modules/chats'
import { useFeatureFlag } from 'modules/features'
import { productNewsSelectors } from 'modules/productNews'
import { userSelectors } from 'modules/user'

import SidebarCustomers from 'components/sidebar/sidebarCustomers/SidebarCustomers'
import { Flex, FlexProps } from 'shared/components/core/Flex'
import { LogoEyes } from 'shared/components/core/Logo'
import { iconChat } from 'shared/components/icons'

import SidebarAgent from './sidebarAgent/SidebarAgent'
import SidebarAiAutomations from './sidebarAiAutomations/SidebarAiAutomations'
import SidebarAutomations from './sidebarAutomations/SidebarAutomations'
import SidebarMenu from './sidebarMenu/SidebarMenu'
import SidebarMenuIcon from './sidebarMenu/SidebarMenuIcon'
import SidebarMenuItem from './sidebarMenu/SidebarMenuItem'
import SidebarNeedHelp from './sidebarNeedHelp/SidebarNeedHelp'
import SidebarSettings from './sidebarSettings/SidebarSettings'
import SidebarStatistics from './sidebarStatistics/SidebarStatistics'

type Props = FlexProps

const Sidebar = (props: Props) => {
	const theme = useAppTheme()
	const allUnreadChatsCount = useSelector(chatsSelectors.getAllUnreadChatsCount)
	const unreadProductNewsCount = useSelector(productNewsSelectors.getUnreadProductNewsCount)
	const isUserAdmin = useSelector(userSelectors.isUserAdmin)
	const showAiChatbots = useFeatureFlag(FeatureFlag.AiChatbots)
	const configData = ConfigurationService.getData()
	const smartsuppUrl = 'https://www.smartsupp.com'

	return (
		<Flex as="nav" id="app-sidebar" bgColor="coal.900" zIndex={theme.zIndices.sidebar} overflow="hidden" {...props}>
			<Flex alignItems="center" flexDirection="column" w="full" overflow="auto">
				<SidebarAgent />
				<SidebarMenu>
					<SidebarMenuItem
						path={routes.smartHub.path}
						tooltip={T.translate('smarthub.header')}
						badgeDot={unreadProductNewsCount > 0}
						data-testid="sidebar-smarthub"
					>
						<SidebarMenuIcon icon={mdiHome} />
					</SidebarMenuItem>
					<SidebarMenuItem
						path={routes.conversations.path}
						badgeCount={allUnreadChatsCount}
						tooltip={T.translate('headerBar.conversations')}
						exactPath={false}
						data-testid="sidebar-conversations"
						data-userpilot="sidebar-conversations"
					>
						<SidebarMenuIcon icon={iconChat} />
					</SidebarMenuItem>
					{isUserAdmin && showAiChatbots && <SidebarAiAutomations />}
					{isUserAdmin && <SidebarAutomations />}
					<SidebarCustomers />
					{isUserAdmin && <SidebarStatistics />}
				</SidebarMenu>

				<SidebarMenu mt="auto" mb={1}>
					<SidebarSettings />
					<SidebarNeedHelp />
					<SidebarMenuItem
						path={`${smartsuppUrl}/${configData?.lang}/`}
						tooltip="Smartsupp.com"
						external
						data-testid="sidebar-logo"
					>
						<Flex flexDirection="column" alignItems="center">
							<LogoEyes />
						</Flex>
					</SidebarMenuItem>
				</SidebarMenu>
			</Flex>
		</Flex>
	)
}

export default Sidebar
