import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { Country } from 'models/Country'
import { DashboardState } from 'types'

import { countriesApi } from './api'

export type CountriesRootState = Pick<DashboardState, 'countries'>

export const initialState = {
	isFetchPending: false,
	countries: null as null | Country[],
}

export const fetchCountries = createAsyncThunk('countries/FETCH', async () => {
	return countriesApi.fetchCountries()
})

const slice = createSlice({
	name: 'countries',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCountries.pending, (state) => {
				state.isFetchPending = true
			})
			.addCase(fetchCountries.fulfilled, (state, { payload }) => {
				state.isFetchPending = false
				state.countries = payload
			})
			.addCase(fetchCountries.rejected, (state) => {
				state.isFetchPending = false
			})
	},
})

const { reducer } = slice
export default reducer

const getCountries = (state: CountriesRootState) => state.countries.countries
const getIsFetching = (state: CountriesRootState) => state.countries.isFetchPending

export const countriesSelectors = {
	getCountries,
	getIsFetching,
}
