import { ReactNode } from 'react'
import { UseToastOptions } from '@chakra-ui/react'
import { mdiAlertCircleOutline, mdiAlertOutline, mdiCheckboxMarkedCircleOutline, mdiInformationOutline } from '@mdi/js'

import { CloseButton, Flex, Icon, Stack, Text } from 'core'

type PropsToastComponent = {
	onClose: () => void
	button?: ReactNode
	testId?: string
} & UseToastOptions

export const NotificationComponent = ({
	title,
	description,
	status,
	isClosable,
	onClose,
	button,
	testId,
}: PropsToastComponent) => {
	const getIcon = () => {
		switch (status) {
			case 'error': {
				return mdiAlertCircleOutline
			}
			case 'warning': {
				return mdiAlertOutline
			}
			case 'success': {
				return mdiCheckboxMarkedCircleOutline
			}
			case 'info': {
				return mdiInformationOutline
			}
			default: {
				return mdiInformationOutline
			}
		}
	}

	return (
		<Stack
			p={2}
			mx={3}
			spacing={2}
			borderLeft="2px"
			borderColor={`${status}.500`}
			boxShadow="shadow3"
			bg="white"
			minWidth="400px"
			maxWidth="400px"
			data-testid={testId}
		>
			<Flex alignItems="flex-start" direction="row" w="full">
				<Icon path={getIcon()} textColor={`${status}.500`} fontSize={4} ml={1} mr={2} />
				<Stack flex={1} mx={1} spacing={1}>
					{title && <Text fontSize={2}>{title}</Text>}
					{description && <Text fontWeight="light">{description}</Text>}
				</Stack>
				{isClosable && <CloseButton size="sm" onClick={onClose} />}
			</Flex>
			{button && <Flex justifyContent="flex-end">{button}</Flex>}
		</Stack>
	)
}
