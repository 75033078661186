import {
	InvitationInfo,
	ResendInvitationsParams,
	ResendInvitationsRequest,
	SendInvitationsParams,
	SendInvitationsRequest,
} from 'models'
import { CustomHeader } from 'shared/config/headers'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'invitations'

const getInvitations = () => {
	return apiClient.get<InvitationInfo[]>(`${API_RESOURCE}`)
}

const sendInvitations = ({ agents, recaptchaToken }: SendInvitationsParams) => {
	const headers: Record<string, string> = {}
	if (recaptchaToken) {
		headers[CustomHeader.Recaptcha] = recaptchaToken
	}
	const data: SendInvitationsRequest = { agents }
	return apiClient.post<InvitationInfo[]>(`${API_RESOURCE}`, data, headers)
}

const resendInvitations = ({ invitationIds, recaptchaToken }: ResendInvitationsParams) => {
	const headers: Record<string, string> = {}
	if (recaptchaToken) {
		headers[CustomHeader.Recaptcha] = recaptchaToken
	}
	const data: ResendInvitationsRequest = { ids: invitationIds }
	return apiClient.post<InvitationInfo[]>(`${API_RESOURCE}/notify`, data, headers)
}

const cancelInvitations = (ids: number[]) => {
	return apiClient.post(`${API_RESOURCE}/cancel`, { ids })
}

export const invitationsApi = { getInvitations, sendInvitations, resendInvitations, cancelInvitations }
