import { Chatbot, ChatbotCreateRequest, ChatbotInstalledActionApp, ChatbotUpdateRequest } from 'models'
import { AppLang } from 'shared/types'
import { apiClient } from 'shared/utils'

import { API_CHATBOT_ACTIONS, API_RESOURCE } from './apiConstants'

const getChatbot = (id: string) => {
	return apiClient.get<Chatbot>(`${API_RESOURCE}/${id}?handover_v2=true`)
}

const getPublishedChatbot = (id: string) => {
	return apiClient.get<Chatbot>(`${API_RESOURCE}/${id}?handover_v2=true&type=published`)
}

// NOTE chatbot templates stil returns handover instead of handover_v2, so also payload is with old hanover but response already return handover_v2
const createChatbot = (data: ChatbotCreateRequest) => {
	return apiClient.post<Chatbot>(`${API_RESOURCE}?handover_v2=true`, data)
}

const updateChatbot = (id: string, changes: ChatbotUpdateRequest) => {
	return apiClient.patch<Chatbot>(`${API_RESOURCE}/${id}?handover_v2=true`, changes)
}

const deleteChatbot = (id: string) => {
	return apiClient.delete(`${API_RESOURCE}/${id}`)
}

const getChatbotActions = (lang: AppLang) => {
	return apiClient.get<ChatbotInstalledActionApp[]>(`${API_CHATBOT_ACTIONS}?lang=${lang}`)
}

export const chatbotDetailApi = {
	getChatbot,
	getPublishedChatbot,
	createChatbot,
	updateChatbot,
	deleteChatbot,
	getChatbotActions,
}
