import { DateRangePreset } from 'models'
import { RangeType } from 'shared/models/Analytics'

export type SmartHubStatisticsFilterPreset = Extract<
	DateRangePreset,
	DateRangePreset.Past7D | DateRangePreset.Past30D | DateRangePreset.PastYear
>

export const dateRangeMap: Record<SmartHubStatisticsFilterPreset, RangeType> = {
	[DateRangePreset.Past7D]: RangeType.Last7days,
	[DateRangePreset.Past30D]: RangeType.Last30days,
	[DateRangePreset.PastYear]: RangeType.LastYear,
}

export enum SmartHubStatisticsType {
	Conversations = 'conversations',
	ChatbotConversations = 'chatbotConversations',
	Rating = 'rating',
}
