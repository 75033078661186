import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunkAction, DashboardState } from 'types'
import { agentClientProvider } from 'utils'
import {
	getHasSelectedChatAvailableChannel,
	getIsSelectedChatContactBanned,
	getIsSmartsuppChannel,
} from 'modules/chats/selectors'

export type TextareaRootState = Pick<DashboardState, 'textarea'>

const initialState = {
	isAgentTyping: false,
	isTextareaFocused: false,
	textareaNextCursorPosition: 0, // Used to re-render textarea component and update cursor position
}

export const startAgentTyping =
	(chatId: string, visitorId: string): AppThunkAction =>
	(dispatch, getState) => {
		const isAgentTyping = getIsAgentTyping(getState())
		if (isAgentTyping) return

		const client = agentClientProvider.getAgentClient()
		dispatch(actions.agentTypingStarted())
		client?.chatTyping(chatId, visitorId, true)
	}

export const stopAgentTyping =
	(chatId: string, visitorId: string): AppThunkAction =>
	(dispatch, getState) => {
		const isAgentTyping = getIsAgentTyping(getState())
		if (!isAgentTyping) return

		const client = agentClientProvider.getAgentClient()
		dispatch(actions.agentTypingStopped())
		client?.chatTyping(chatId, visitorId, false)
	}

const textareaSlice = createSlice({
	name: 'textarea',
	initialState,
	reducers: {
		agentTypingStarted: (state) => {
			state.isAgentTyping = true
		},
		agentTypingStopped: (state) => {
			state.isAgentTyping = false
		},
		setTextareaFocused: (state, { payload }: PayloadAction<boolean>) => {
			state.isTextareaFocused = payload
		},
		setTextareaNextCursorPosition: (state, { payload }: PayloadAction<number>) => {
			state.textareaNextCursorPosition = payload
		},
	},
})

const { actions, reducer } = textareaSlice
export const { setTextareaFocused, setTextareaNextCursorPosition } = actions
export default reducer

const getIsTextareaFocused = (state: TextareaRootState) => state.textarea.isTextareaFocused
const getTextareaNextCursorPosition = (state: TextareaRootState) => state.textarea.textareaNextCursorPosition
const getIsAgentTyping = (state: TextareaRootState) => state.textarea.isAgentTyping

const getIsTextareaDisabled = createSelector(
	[getHasSelectedChatAvailableChannel, getIsSelectedChatContactBanned, getIsSmartsuppChannel],
	(hasAvailableChannel, isContactBanned, isSmartsuppChannel): boolean => {
		return isContactBanned || !hasAvailableChannel || isSmartsuppChannel
	},
)

export const textareaSelectors = {
	getIsTextareaFocused,
	getTextareaNextCursorPosition,
	getIsTextareaDisabled,
}
