import { Icon } from 'shared/components/core/Icon'

type Props = {
	icon: string
	isActive?: boolean
}

const SidebarMenuIcon = ({ icon, isActive }: Props) => {
	return (
		<Icon
			path={icon}
			fontSize={4}
			sx={{
				'path, rect, circle': {
					fill: isActive ? 'white' : 'coal.500',
				},
				'.is-active & path, .is-active & rect, .is-active & circle': {
					fill: 'white',
				},
			}}
		/>
	)
}

export default SidebarMenuIcon
