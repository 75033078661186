import { AgentStatus } from 'shared/models/Agent'
import { getAvatarUrl } from 'utils'
import { ChannelIconType } from 'modules/integrations'

import { Avatar, AvatarBadge, AvatarProps, Box } from 'core'
import { AvatarChannelIcon } from 'components/messages/AvatarChannelIcon'

type Props = {
	avatar: string | null
	channel?: ChannelIconType
	hasBorder?: boolean
	size?: AvatarProps['size']
	imageSize?: number
	status?: AgentStatus | null
	statusSize?: string
	statusBorderColor?: string
	onClick?: () => void
	opacity?: number
}

const DEFAULT_SIZE = '40px'
const DEFAULT_BADGE_SIZE = '12px'

const getStatusColor = (agentStatus: AgentStatus): string | undefined => {
	if (agentStatus === AgentStatus.Online) return 'green.500'
	if (agentStatus === AgentStatus.Offline) return 'red.500'
	return undefined
}

export const AgentAvatar = ({
	avatar,
	channel,
	hasBorder = false,
	size = DEFAULT_SIZE,
	imageSize,
	status,
	statusSize = DEFAULT_BADGE_SIZE,
	statusBorderColor = 'white',
	onClick,
	opacity = 1,
}: Props) => {
	return (
		<Box onClick={onClick} data-testid="agent-avatar">
			<Avatar src={getAvatarUrl(avatar, imageSize)} size={size} showBorder={hasBorder} boxSize={size} opacity={opacity}>
				{status && <AvatarBadge boxSize={statusSize} bg={getStatusColor(status)} borderColor={statusBorderColor} />}
				{channel && <AvatarChannelIcon channel={channel} />}
			</Avatar>
		</Box>
	)
}
