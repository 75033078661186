import { BotIdentity } from 'shared/models/Chatbot'

export enum IdentitiesModalType {
	Create = 'create',
	Edit = 'edit',
	Pick = 'pick',
}

export interface IdentityFormData extends Pick<BotIdentity, 'id' | 'name' | 'gender'> {
	avatarImg: BotIdentity['avatarUrl']
}
