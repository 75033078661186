import { forwardRef, Ref } from 'react'
import { Textarea as ChakraTextarea, TextareaProps } from '@chakra-ui/react'

export type { TextareaProps } from '@chakra-ui/react'

type Props = {
	isInvalid?: boolean
} & TextareaProps

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
	({ isInvalid = false, ...rest }, ref: Ref<HTMLTextAreaElement>) => {
		return (
			<ChakraTextarea
				{...rest}
				ref={ref}
				isInvalid={isInvalid}
				{...(isInvalid && { focusBorderColor: 'red.600', boxShadow: 'shadowError' })}
			/>
		)
	},
)

Textarea.displayName = 'Textarea'
