import { AgentEvents, Visitor, VisitorStatus } from '@smartsupp/websocket-client-agent'

export { Variables, Visitor, VisitorStatus } from '@smartsupp/websocket-client-agent'

export enum VisitorFilterType {
	All = 'all',
	Active = 'active',
	Chatting = 'chatting',
	Identified = 'identified',
	Triggered = 'triggered',
	Blocked = 'blocked',
}

export type VisitorFilter = {
	[VisitorStatus.Active]: boolean
	[VisitorStatus.Clicked]: boolean
	[VisitorStatus.Idle]: boolean
	[VisitorStatus.Served]: boolean
	[VisitorStatus.Triggered]: boolean
	[VisitorStatus.Unserved]: boolean
}

export type VisitorSimple = Pick<
	Visitor,
	| 'id'
	| 'connectedAt'
	| 'contactId'
	| 'createdAt'
	| 'status'
	| 'name'
	| 'email'
	| 'bannedAt'
	| 'domain'
	| 'pageUrl'
	| 'locCode'
	| 'locCountry'
	| 'referer'
	| 'chatsCount'
	| 'visitsCount'
	| 'servedBy'
	| 'group'
	| 'avatar'
	| 'locCity'
	| 'pageTitle'
	| 'viewsCount'
>

export type VisitorDisconnected = Pick<Visitor, 'id' | 'status' | 'name' | 'email' | 'bannedAt'>

export type VisitorUpdated = AgentEvents.VisitorUpdated

export interface VisitorsSearchRequestFilters {
	status?: VisitorStatus[]
	customers?: boolean
	banned?: boolean
}

type VisitorsSearchRequestQueryField = keyof Visitor

export interface VisitorsSearchRequestQuery {
	field?: VisitorsSearchRequestQueryField
	fields?: VisitorsSearchRequestQueryField[]
	value: string | number | boolean | null
	op?: 'equal' | 'not_equal' | 'contains'
}

export interface VisitorsSearchRequest {
	size: number
	after: number | null
	filters?: VisitorsSearchRequestFilters
	query?: VisitorsSearchRequestQuery[]
}

export interface VisitorsOpenChatResponse {
	id: string
	status: 'created' | 'found'
}

export interface VisitorsSearchResponse {
	total: number
	items: VisitorSimple[]
	after: number
	counters: {
		filtered: number
		searched: number
		total: number
	}
}
