import moment from 'moment'

import {
	Chatbot,
	ChatbotActionType,
	ChatbotApi,
	ChatbotReply,
	ChatbotStatisticsDataType,
	ChatbotStats,
	ChatbotStatsSummary,
	CountWithDate,
	InteractionStats,
	StatisticsInterval,
} from 'models'
import { TranslationService as T } from 'shared/services'
import { theme } from 'shared/styles'
import { getFormattedNames } from 'modules/statistics'

import { DEFAULT_STATISTICS_ARRAY } from './constants'
import { ChartData, ChartItemData, InteractionTableStats, TransformedChatbotStatistic } from './types'

export const transformChatbotStatistics = (
	stats: ChatbotStatsSummary | ChatbotStats,
): TransformedChatbotStatistic[] => {
	if (!stats) return DEFAULT_STATISTICS_ARRAY

	const chatbotTriggered = stats.triggeredUnique.value
	const chatbotConversations = stats.chatbotConversations.value
	const chatbotResolved = stats.resolvedByChatbot.value
	const chatbotEscalated = stats.escalatedToHuman.value
	const chatbotResolvedPercentage = `${stats.resolvedByChatbot.percentage}%`
	const chatbotEscalatedPercentage = `${stats.escalatedToHuman.percentage}%`
	const chatbotTriggeredTrend = stats.triggeredUnique.trendPercentage
	const chatbotConversationsTrend = stats.chatbotConversations.trendPercentage
	const chatbotResolvedTrend = stats.resolvedByChatbot.trendPercentage
	const chatbotEscalatedTrend = stats.escalatedToHuman.trendPercentage

	return [
		{ name: ChatbotStatisticsDataType.Triggered, value: chatbotTriggered, trendPercentage: chatbotTriggeredTrend },
		{
			name: ChatbotStatisticsDataType.ChatbotConversations,
			value: chatbotConversations,
			trendPercentage: chatbotConversationsTrend,
		},
		{
			name: ChatbotStatisticsDataType.Resolved,
			value: chatbotResolved,
			percentage: chatbotResolvedPercentage,
			trendPercentage: chatbotResolvedTrend,
		},
		{
			name: ChatbotStatisticsDataType.Escalated,
			value: chatbotEscalated,
			percentage: chatbotEscalatedPercentage,
			trendPercentage: chatbotEscalatedTrend,
		},
	]
}

// Interactions table utils

export const getNodeTitle = (bot: Chatbot, interactionId: InteractionStats['id']): string => {
	let replies: ChatbotReply[] = []

	const actionsData = bot.interactions.map((interaction) => {
		const messageAction = interaction.actions.find((a) => a.type === ChatbotActionType.Message)
		if (!messageAction) return []

		return messageAction.data
	})

	actionsData.forEach((item) => {
		if ('replies' in item) {
			replies = [...replies, ...item.replies]
		}
	})

	const specificReply = replies.find((reply) => reply.nextInteractionId === interactionId)
	return specificReply?.text ?? T.translate('chatbotBuilder.nodes.welcomeMessage')
}

export const getChatbotInteractionsData = (
	selectedBot: Chatbot,
	interactionsStatistics: InteractionStats[],
): InteractionTableStats[] => {
	return interactionsStatistics.map((stat) => {
		return { ...stat, title: getNodeTitle(selectedBot, stat.id) }
	})
}

export const scrollToInteractionItem = (elementId: string, interactionId: string) => {
	const interactionElement = document.querySelector(`#${elementId}-${interactionId}`)
	interactionElement && interactionElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

// Chart data utils

const convertChartDataArray = (data?: CountWithDate[]): ChartItemData[] => {
	if (!data) return []
	return data.map((item) => {
		const momentDate = moment(item.date, 'YYYY-MM-DD')
		const formattedNames = getFormattedNames(StatisticsInterval.Day, momentDate)
		return { x: formattedNames.long, y: item.count }
	})
}

export const getChartData = (data: ChatbotApi.StatsSingleAggregatedResponse): ChartData[] => {
	const { triggered, chatbotConversations, escalatedToHuman } = data

	return [
		{
			id: ChatbotStatisticsDataType.Triggered,
			label: T.translate('statistics.chatbot.triggered'),
			color: theme.colors.blue[500],
			data: convertChartDataArray(triggered),
		},
		{
			id: ChatbotStatisticsDataType.ChatbotConversations,
			label: T.translate('statistics.chatbot.chatbotConversations'),
			color: theme.colors.pink[500],
			data: convertChartDataArray(chatbotConversations),
		},
		{
			id: ChatbotStatisticsDataType.Escalated,
			label: T.translate('statistics.chatbot.escalated'),
			color: theme.colors.yellow[500],
			data: convertChartDataArray(escalatedToHuman),
		},
	]
}

// Testing utils
const generateRandomNumber = (max: number) => Math.floor(Math.random() * max)
const generateRandomTrend = () => Math.floor(Math.random() * 201) - 100

export const getFakeChatbotStatistics = (): TransformedChatbotStatistic[] => {
	const MAX_NUMBER = 99999999999
	const chatbotTriggered = generateRandomNumber(MAX_NUMBER)
	const chatbotReactions = generateRandomNumber(MAX_NUMBER)
	const chatbotResolved = generateRandomNumber(MAX_NUMBER)
	const chatbotEscalated = generateRandomNumber(MAX_NUMBER)
	const chatbotResolvedPercentage = `${generateRandomNumber(100)}%`
	const chatbotEscalatedPercentage = `${generateRandomNumber(100)}%`
	const chatbotTriggeredTrend = generateRandomTrend()
	const chatbotReactionsTrend = generateRandomTrend()
	const chatbotResolvedTrend = generateRandomTrend()
	const chatbotEscalatedTrend = generateRandomTrend()

	return [
		{ name: ChatbotStatisticsDataType.Triggered, value: chatbotTriggered, trendPercentage: chatbotTriggeredTrend },
		{
			name: ChatbotStatisticsDataType.ChatbotConversations,
			value: chatbotReactions,
			trendPercentage: chatbotReactionsTrend,
		},
		{
			name: ChatbotStatisticsDataType.Resolved,
			value: chatbotResolved,
			percentage: chatbotResolvedPercentage,
			trendPercentage: chatbotResolvedTrend,
		},
		{
			name: ChatbotStatisticsDataType.Escalated,
			value: chatbotEscalated,
			percentage: chatbotEscalatedPercentage,
			trendPercentage: chatbotEscalatedTrend,
		},
	]
}
