import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DashboardState } from 'types'
import { getPast30DaysDateBegin, getTodayDateEnd } from 'utils'

import { PartialStatisticsFilter, StatisticsFilter } from './types'

export type StatisticsFilterRootState = Pick<DashboardState, 'statisticsFilter'>

export const initialState = {
	filter: {
		group: '',
		tags: [] as string[],
		from: getPast30DaysDateBegin().toDate(),
		to: getTodayDateEnd().toDate(),
	} as StatisticsFilter,
	isLongTermOnlineTime: false,
	isLongTermDateRange: false,
}

const slice = createSlice({
	name: 'statisticsFilter',
	initialState,
	reducers: {
		setFilter(state, { payload }: PayloadAction<PartialStatisticsFilter>) {
			state.filter = { ...state.filter, ...payload }
		},
		setIsLongTermRange(
			state,
			{ payload }: PayloadAction<{ isLongTermDateRange: boolean; isLongTermOnlineTime: boolean }>,
		) {
			state.isLongTermDateRange = payload.isLongTermDateRange
			state.isLongTermOnlineTime = payload.isLongTermOnlineTime
		},
	},
})

const { reducer, actions } = slice
export const { setFilter, setIsLongTermRange } = actions
export default reducer

const getFilter = (state: StatisticsFilterRootState) => state.statisticsFilter.filter
const getFilterGroup = (state: StatisticsFilterRootState) => state.statisticsFilter.filter.group
const getFilterTags = (state: StatisticsFilterRootState) => state.statisticsFilter.filter.tags
const getIsLongTermRange = (state: StatisticsFilterRootState) => state.statisticsFilter.isLongTermDateRange
const getIsLongTermOnlineTime = (state: StatisticsFilterRootState) => state.statisticsFilter.isLongTermOnlineTime

export const statisticsFilterSelectors = {
	getFilter,
	getIsLongTermRange,
	getIsLongTermOnlineTime,
	getFilterGroup,
	getFilterTags,
}
