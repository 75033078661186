import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
	EntityId,
	isFulfilled,
	isPending,
	isRejected,
	PayloadAction,
} from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import {
	ChatbotGroupType,
	ChatbotSimple,
	ListQueryGroupType,
	SelectedChatbotSimple,
	SelectedChatbotSimpleWithStatistics,
} from 'models'
import { AppLang } from 'shared/types'
import { DashboardState, Dictionary } from 'types'
import { normalize } from 'utils'
import {
	AutomationSorting,
	defaultSorting,
	fetchAllBots,
	makeSortAutomations,
	transformSimpleChatbot,
	unpublishChatbots,
} from 'modules/automations'
import { automationsApi } from 'modules/automations/api'
import { deleteChatbot, postChatbot, updateChatbot, updateChatbotTitleThunk } from 'modules/chatbotDetail/actions'
import {
	chatbotApi,
	DEFAULT_CHATBOT_ACTIVE_COUNT,
	getChatbotUsageLimits,
	getSimpleChatbotsWithStatistics,
	isChatbotTemplateTypeOnboarding,
} from 'modules/chatbots'
import { chatbotStatisticsSelectors } from 'modules/chatbotStatistics'
import { chatsFilterSelectors, sortAutomations } from 'modules/chatsFilter'
import { packageSelectors } from 'modules/package'
import { FeatureUsageLimitsType } from 'modules/usage'

import { getFilterChatbotIds } from './utils'

export type ChatbotRootState = Pick<DashboardState, 'chatbots'>
export type ChatbotState = typeof initialState

const chatbotsAdapter = createEntityAdapter<ChatbotSimple>()

export const initialState = chatbotsAdapter.getInitialState({
	chatbotsLastFetchedTime: null as null | number,
	activeChatbots: DEFAULT_CHATBOT_ACTIVE_COUNT,
	isFetchingChatbots: true,
	sorting: defaultSorting,
	automationsLastFetchedTime: null as null | number,
})

export const fetchChatbotsOnboarding = createAsyncThunk('chatbots/FETCH_ONBOARDING', () => {
	return automationsApi.getBotsList({ groupType: ListQueryGroupType.Chatbot })
})

export const initChatbotsFromOnboarding = createAsyncThunk('chatbots/INIT', (lang: AppLang) => {
	return chatbotApi.initChatbots(lang)
})

export const fetchChatbots = createAsyncThunk(
	'chatbots/FETCH',
	async () => {
		return automationsApi.getBotsList({ groupType: ListQueryGroupType.Chatbot })
	},
	{
		condition: (_, { getState }) => {
			const state = getState() as DashboardState
			const lastFetchedTime = getChatbotsLastFetchedTime(state)
			const currentTime = Date.now()
			return !lastFetchedTime || currentTime - lastFetchedTime > 10000
		},
	},
)

export const fetchChatbotsCounts = createAsyncThunk('chatbots/FETCH_COUNTS', () => {
	return automationsApi.getBotsCounts({ groupType: ChatbotGroupType.Chatbot })
})

const chatbotSlice = createSlice({
	name: 'chatbots',
	initialState,
	reducers: {
		setChatbotsSorting: (state, { payload }: PayloadAction<AutomationSorting>) => {
			state.sorting = payload
		},
		setAutomationsLastFetchedTime: (state) => {
			state.automationsLastFetchedTime = Date.now()
		},
	},
	extraReducers: (builder) => {
		// Chatbot detail reducers
		builder.addCase(postChatbot.fulfilled, (state, { payload }) => {
			if (payload.groupType === ChatbotGroupType.Chatbot) {
				chatbotsAdapter.addOne(state, payload)
			}
		})
		builder.addCase(updateChatbot.fulfilled, (state, { payload }) => {
			chatbotsAdapter.updateOne(state, { id: payload.id, changes: payload })
		})
		builder.addCase(deleteChatbot.fulfilled, (state, { meta }) => {
			chatbotsAdapter.removeOne(state, meta.arg)
		})
		builder.addCase(fetchChatbotsCounts.fulfilled, (state, { payload }) => {
			state.activeChatbots = payload.active
		})
		builder.addCase(fetchAllBots.fulfilled, (state, { payload }) => {
			const filteredPayload = payload.filter((bot) => bot.groupType === ChatbotGroupType.Chatbot)
			chatbotsAdapter.setAll(state, filteredPayload)
			state.isFetchingChatbots = false
		})
		builder.addCase(fetchAllBots.pending, (state) => {
			state.isFetchingChatbots = true
		})
		builder.addCase(fetchAllBots.rejected, (state) => {
			state.isFetchingChatbots = false
		})
		builder.addCase(unpublishChatbots.fulfilled, (state) => {
			state.activeChatbots = 0
		})
		builder
			.addMatcher(isPending(fetchChatbots, fetchChatbotsOnboarding), (state) => {
				state.isFetchingChatbots = true
			})
			.addMatcher(isFulfilled(fetchChatbots, fetchChatbotsOnboarding), (state, { payload }) => {
				chatbotsAdapter.setAll(state, payload)
				state.isFetchingChatbots = false
				state.chatbotsLastFetchedTime = Date.now()
			})
			.addMatcher(isRejected(fetchChatbots, fetchChatbotsOnboarding), (state) => {
				state.isFetchingChatbots = false
			})
		builder.addMatcher(isFulfilled(postChatbot, deleteChatbot, fetchChatbots), (state) => {
			state.activeChatbots = Object.values(state.entities).filter((e) => e?.isActive).length
		})
		builder.addMatcher(isFulfilled(updateChatbot, updateChatbotTitleThunk), (state, { payload }) => {
			if (payload.groupType === ChatbotGroupType.Ai) return
			state.activeChatbots = Object.values(state.entities).filter((e) => e?.isActive).length
		})
	},
})

const { actions, reducer } = chatbotSlice
export const { setChatbotsSorting, setAutomationsLastFetchedTime } = actions
export default reducer

const entitySelectors = chatbotsAdapter.getSelectors<ChatbotRootState>((state) => state.chatbots)
const getChatbotsList = (state: ChatbotRootState) => entitySelectors.selectAll(state)
const getIsFetchingChatbots = (state: ChatbotRootState) => state.chatbots.isFetchingChatbots
const getChatbotsSorting = (state: ChatbotRootState) => state.chatbots.sorting
const getChatbotsLastFetchedTime = (state: ChatbotRootState) => state.chatbots.chatbotsLastFetchedTime
const getActiveChatbotsCount = (state: ChatbotRootState) => state.chatbots.activeChatbots
const getAutomationsLastFetchedTime = (state: ChatbotRootState) => state.chatbots.automationsLastFetchedTime

const getChatbots = createSelector([getChatbotsList], (chatbots): SelectedChatbotSimple[] => {
	return chatbots.map(transformSimpleChatbot)
})

const getMaxActiveChatbotsCount = createSelector([packageSelectors.getPackageInfo], (packageInfo): number | null => {
	if (!packageInfo) return DEFAULT_CHATBOT_ACTIVE_COUNT
	return packageInfo.maxActiveChatbots
})

const getCanActivateChatbot = createSelector(
	[getActiveChatbotsCount, getMaxActiveChatbotsCount],
	(activeChatbotsCount, maxActiveChatbotsCount) => {
		if (maxActiveChatbotsCount === null) return true // Unlimited chatbots
		return activeChatbotsCount < maxActiveChatbotsCount
	},
)

const getBotsWithStatistics = createSelector(
	[getChatbots, chatbotStatisticsSelectors.getBots],
	(chatbots, stats): Dictionary<SelectedChatbotSimpleWithStatistics> => {
		return getSimpleChatbotsWithStatistics(chatbots, stats)
	},
)

const getSortedFaqChatbots = createSelector(
	[getBotsWithStatistics, getChatbotsSorting],
	(chatbots, sorting): SelectedChatbotSimpleWithStatistics[] => {
		return Object.values(chatbots).sort(makeSortAutomations(sorting))
	},
)

const getChatbotId = (state: ChatbotRootState, { chatbotId }: { chatbotId: EntityId }) => chatbotId

const makeGetChatbotById = () =>
	createSelector([getChatbots, getChatbotId], (chatbots, chatbotId: EntityId): SelectedChatbotSimple | null => {
		const bots = normalize('id', chatbots)
		return bots[chatbotId] ?? null
	})

const isFaqChatbotCreated = createSelector([getChatbots], (chatbots): boolean => {
	return chatbots.length > 0
})

const getChatbotOnboarding = createSelector([getChatbots], (chatbots): ChatbotSimple | null => {
	const filteredChatbots = chatbots.filter(isChatbotTemplateTypeOnboarding)
	if (filteredChatbots.length > 0) return filteredChatbots[0]
	return null
})

const getChatbotLimits = createSelector(
	[getActiveChatbotsCount, getMaxActiveChatbotsCount],
	(activeChatbots, maxChatbots): FeatureUsageLimitsType => {
		return getChatbotUsageLimits(activeChatbots, maxChatbots)
	},
)

const getChatbotIds = createSelector([getChatbots], (chatbots) => {
	return chatbots.map((bot) => bot.id)
})

const getChatbotIdsInFilter = createSelector(
	[getChatbotIds, chatsFilterSelectors.getClosedChatsFilterBotIds],
	(chatbotsIds, filterBotsIds) => {
		return getFilterChatbotIds(filterBotsIds, chatbotsIds)
	},
)

const getChatbotsCount = createSelector([getChatbots], (chatbots) => {
	return chatbots.length
})

const getChatbotsSortedAlphabetically = createSelector([getChatbots], (chatbots) => {
	return sortAutomations(chatbots)
})

export const chatbotSelectors = {
	getIsFetchingChatbots,
	getChatbots,
	getChatbotsSorting,
	getCanActivateChatbot,
	isFaqChatbotCreated,
	makeGetChatbotById,
	getChatbotOnboarding,
	getActiveChatbotsCount,
	getMaxActiveChatbotsCount,
	getSortedFaqChatbots,
	getChatbotLimits,
	getChatbotIdsInFilter,
	getChatbotsCount,
	getAutomationsLastFetchedTime,
	getChatbotsSortedAlphabetically,
}
