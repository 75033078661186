import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { DashboardState } from 'types'
import { groupsSelectors } from 'modules/groups'
import { upsellSelectors } from 'modules/upsell'

interface ChatbotInteractionGroupStates {
	hasEnabledGroups: boolean
	hasInvalidGroup: boolean
	hasGroupWhileDisabled: boolean
}

export const useChatbotInteractionGroup = (interactionGroup: string | null): ChatbotInteractionGroupStates => {
	const hasEnabledGroups = useSelector(upsellSelectors.hasEnabledGroupsFeature)
	const getIsGroupValid = useMemo(() => groupsSelectors.makeGetIsGroupValidById(interactionGroup), [interactionGroup])
	const isGroupValid = useSelector((state: DashboardState) => getIsGroupValid(state))

	const hasInvalidGroup = interactionGroup ? !isGroupValid : false

	return {
		hasEnabledGroups,
		hasInvalidGroup,
		hasGroupWhileDisabled: !hasEnabledGroups && !!interactionGroup,
	}
}
