import { MetricName as MetricsType, MetricOptions } from '@smartsupp/core-client-api'

export { MetricOptions, MetricName as MetricsType } from '@smartsupp/core-client-api'

export enum StatisticsInterval {
	Day = 'day',
	Week = 'week',
	Month = 'month',
}

export interface StatisticsItem {
	key: string
	value: number
}

export interface UsageStatistics {
	groups: []
	usage: {
		duration: StatisticsItem[]
		firstResponse: StatisticsItem[]
		hours: StatisticsItem[]
	}
}

export interface AgentsStatisticsAgent {
	email: string
	id: number
	name: string
}

export interface AgentsStatisticsSummary {
	agent: AgentsStatisticsAgent
	key: string
	new_conversation: number
	avg_first_response_time: number
	avg_response_time: number
	avg_time_to_close: number
	sum_time_to_close: number
	conversation_ratings: number
	conversation_ratings_count: number
}

export interface AgentsStatistics {
	groups: []
	summary: AgentsStatisticsSummary[]
}

// --- New API ---

export enum MetricsTooltipType {
	Response = 'response',
	Conversation = 'conversation',
	Tags = 'tags',
	ChatbotStatistics = 'chatbotStatistics',
	AutoMessageStatistics = 'autoMessageStatistics',
	NewContacts = 'newContacts',
}

export interface MetricsRequestBodyParams {
	name: MetricsType
	options: {
		timezone: string
		filters?: {
			groupId?: string
			ratingValue?: number[]
			agentIds?: string[]
		}
		range?: {
			from: string
			to: string
		}
		aggs: MetricsAggregate[]
		withTrend?: boolean
		query?: MetricOptions.Query[]
	}
}

export interface AggregateByInterval {
	type: string
	interval: string
	format: string
}

export interface AggregateByAgent {
	type: string
	field: string
}

export interface MetricsAggregate {
	type: string
	interval?: StatisticsInterval
	format?: string
	field?: string
}

export interface MetricsBucketSession {
	duration: number
	startedAt: string
	stoppedAt: string
	type: string
}

export interface MetricsBucket {
	key: string
	count: number
	value: number
	sessions?: MetricsBucketSession[]
}

export interface MetricsResponse {
	name: MetricsType
	value: number
	count: number
	buckets: MetricsBucket[]
	trend?: number | null
}

export type SeriesItem = {
	name: string
	y: number
}

export type OverallChartData = {
	id: string
	data: Coordinate[]
}

type Coordinate = {
	x: string
	y: number | null
}
