import { ReactNode } from 'react'

import { Flex } from 'core'

type Props = {
	children?: ReactNode
}

const TabsContent = ({ children }: Props) => {
	return (
		<Flex flexDirection="column" flex={1} overflowY="hidden">
			{children}
		</Flex>
	)
}

export default TabsContent
