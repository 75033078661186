export const zIndices = {
	appLayoutContent: 1,
	avatarOverlay: 1,
	chatbotCardDropdown: 1,
	colorPicker: 1,
	conversationListItemResolvePanel: 1,
	loadingBar: 1,
	messageActionsSaveShortcut: 1,
	avatarRemoveBtn: 2,
	badge: 2,
	contactAvatar: 2,
	conversationListItem: 2,
	agentsPanel: 3,
	conversationAttachTags: 3,
	contactUnreadMessages: 5,
	chatbotDrawer: 6,
	infoPanelDrawer: 6,
	agentInactiveOverlay: 7,
	needHelpPanel: 7,
	productNews: 7,
	upsellOverlay: 9,
	upsellBar: 9,
	overlayContentContainer: 10,
	packageExpiredOverlay: 11,
	sidebar: 12,
	consentModal: 9999,
}
