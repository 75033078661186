import {
	ChatbotCategory,
	ChatbotSimple,
	ChatbotStats,
	ChatbotTemplateSimple,
	SelectedChatbotSimple,
	SelectedChatbotSimpleWithStatistics,
} from 'models'
import { Dictionary } from 'types'
import { FeatureUsageLimitsType, MAX_USAGE_THRESHOLD_75, MAX_USAGE_THRESHOLD_90 } from 'modules/usage'

import { CHATBOT_LIST_STAT_COLUMN_W, CHATBOT_LIST_STAT_FIRST_COLUMN_W, CHATBOT_TEMPLATE_PREFIX } from './constants'

export const isChatbotTemplateTypeOnboarding = (chatbot: ChatbotSimple): boolean =>
	chatbot.templateType === ChatbotCategory.ChatbotOnboarding

export const isChatbotTemplate = (template: ChatbotTemplateSimple): boolean => {
	return template.type === ChatbotCategory.Faq || template.type.startsWith(CHATBOT_TEMPLATE_PREFIX)
}

export const getSimpleChatbotsWithStatistics = (
	chatbots: SelectedChatbotSimple[],
	stats: Dictionary<ChatbotStats> | null,
): Dictionary<SelectedChatbotSimpleWithStatistics> => {
	const chatbotsWithStatistics: Dictionary<SelectedChatbotSimpleWithStatistics> = {}

	chatbots.forEach((chatbot) => {
		const triggered = stats && stats[chatbot.id] ? stats[chatbot.id].triggeredUnique.value : 0
		const conversations = stats && stats[chatbot.id] ? stats[chatbot.id].chatbotConversations.value : 0
		chatbotsWithStatistics[chatbot.id] = {
			...chatbot,
			triggered,
			conversations,
			ratio: triggered ? (conversations / triggered) * 100 : -1,
		}
	})
	return chatbotsWithStatistics
}

export const getChatbotUsageLimits = (activeBots: number, limit: number | null): FeatureUsageLimitsType => {
	const isOverThreshold75 = limit ? activeBots / limit >= MAX_USAGE_THRESHOLD_75 : false
	const isOverThreshold90 = limit ? activeBots / limit >= MAX_USAGE_THRESHOLD_90 : false

	return {
		count: activeBots,
		limit,
		isOverThreshold75,
		isOverThreshold90,
	}
}

export const getAutomationTranslation = (translation: string, isAutoMessage: boolean): string => {
	if (translation === '') {
		return ''
	}
	if (isAutoMessage) {
		return `${translation}.autoMessage`
	}
	return translation
}

export const getAutomationListStatColumnWidth = (index: number) => {
	if (index === 0) {
		return CHATBOT_LIST_STAT_FIRST_COLUMN_W
	}
	return CHATBOT_LIST_STAT_COLUMN_W
}

export const getFilterChatbotIds = (botsInFilterIds: string[], allBotsIds: string[]) => {
	return botsInFilterIds.filter((id) => allBotsIds.includes(id))
}
