import { PayloadChatTextareaResponse } from '@smartsupp/addons-client-api/dist/types'

import { AddonComponent, ComponentType, Event, EventType, PayloadFormSubmit } from 'shared/models/Addon'

import { DataType } from './types'

const isObject = (value: unknown) => typeof value === 'object' && value !== null

type ObjectType = { [key: string]: any }

export const getCommonProps = (obj1: ObjectType, obj2: ObjectType) => {
	const result: ObjectType = {}

	if ([obj1, obj2].every(isObject)) {
		Object.keys(obj1).forEach((key) => {
			const valueObj1 = obj1[key]
			const valueObj2 = obj2[key]

			if (key in obj2) {
				if (isObject(valueObj1)) {
					result[key] = valueObj2 === null ? valueObj1 : getCommonProps(valueObj1, valueObj2)
				} else {
					result[key] = valueObj1
				}
			}
		})
	}

	return result
}

export const getEventDataValues = (event: Event): PayloadFormSubmit.Values | PayloadChatTextareaResponse | null => {
	switch (event.name) {
		case EventType.ChatTextareaResponse: {
			return event.data
		}
		case EventType.FormSuccess: {
			return event.data.values
		}
		case EventType.FormCancel: {
			return null
		}
		default: {
			return null
		}
	}
}

export const getAddonFormData = (components?: AddonComponent[]) => {
	let values: DataType = {}
	if (!components) return values

	// Find form component in the array of components
	const formComponent = components.find((c) => c.type === ComponentType.Form)

	// Get default value for all the input components of the form
	if (formComponent && 'components' in formComponent) {
		formComponent.components.forEach((comp) => {
			if (comp.type === ComponentType.Textarea || comp.type === ComponentType.Checkbox) {
				values = { ...values, [comp.name]: comp.value ?? '' }
			}
		})
	}

	return values
}
