import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DashboardState } from 'types'
import { ConfigurationService } from 'shared/services'
import { installMethodVideos, PanelName } from 'modules/installMethods'

const DEFAULT_PANEL_KEY = 'code'

const initialState = {
	activePanelName: DEFAULT_PANEL_KEY as PanelName | null,
}
const installMethodsSlice = createSlice({
	name: 'installMethods',
	initialState,
	reducers: {
		setActivePanelName: (state, { payload }: PayloadAction<{ activePanelName: PanelName | null }>) => {
			const { activePanelName } = payload
			state.activePanelName = activePanelName
		},
	},
})

const { reducer, actions } = installMethodsSlice
export const { setActivePanelName } = actions
export default reducer

const getActivePanelName = (state: DashboardState) => state.installMethods.activePanelName

// returns plugin video ID according to panel name and current language
const getVideoId = createSelector([getActivePanelName], (activePanelName): string | null => {
	if (!activePanelName) return null

	const { lang } = ConfigurationService.getData()
	const videoId = installMethodVideos[activePanelName][lang]

	if (videoId === null) {
		return installMethodVideos[activePanelName].en
	}

	return videoId
})

export const installMethodsSelectors = {
	getActivePanelName,
	getVideoId,
}
