import { DemoRequestSource } from 'components/shared/DemoRequestButton'

export enum PackagesListSource {
	TrialExpired,
	TrialExpiring,
	BillingPackages,
	BillingProfile,
}

export const packagesListSourcesPrefixMap: Record<PackagesListSource, string> = {
	[PackagesListSource.TrialExpired]: 'trial-expired',
	[PackagesListSource.TrialExpiring]: 'trial-expiring',
	[PackagesListSource.BillingPackages]: 'billing-packages',
	[PackagesListSource.BillingProfile]: 'billing-profile',
}

export const demoRequestSourcesMap: Record<PackagesListSource, DemoRequestSource> = {
	[PackagesListSource.TrialExpired]: DemoRequestSource.TrialExpiredCard,
	[PackagesListSource.TrialExpiring]: DemoRequestSource.TrialExpirationModal,
	[PackagesListSource.BillingPackages]: DemoRequestSource.Packages,
	[PackagesListSource.BillingProfile]: DemoRequestSource.BillingProfilePackages,
}
