export const checkFirstCharSharp = (string: string | null): string | null => {
	if (!string || string.length === 0) return null
	if (string.charAt(0) === '#') {
		return string.slice(1)
	}
	return string
}

export const sortByString = (a: string, b: string) => {
	const upperA = a.toUpperCase()
	const upperB = b.toUpperCase()
	if (upperA < upperB) return -1
	if (upperA > upperB) return 1
	return 0
}
