import { WidgetConfig } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'widget'

const updateWidget = (data: Partial<WidgetConfig>) => {
	return apiClient.patch<WidgetConfig>(`${API_RESOURCE}`, data)
}

const fetchWidget = () => {
	return apiClient.get<WidgetConfig>(`${API_RESOURCE}`)
}

export const widgetApi = { updateWidget, fetchWidget }
