import { MetricsRequestBodyParams, MetricsResponse } from 'models'
import { apiClient } from 'shared/utils'

import { RatingRequestBody, RatingResponse } from './types'

const API_RESOURCE = 'v2/metrics'

const getMetrics = (body: { items: MetricsRequestBodyParams[] }) => {
	return apiClient.post<MetricsResponse[]>(`${API_RESOURCE}/bulk`, body)
}

const getMetricsRating = (body: { items: RatingRequestBody }) => {
	return apiClient.post<RatingResponse>(`${API_RESOURCE}/bulk`, body)
}

export const statisticsApi = { getMetricsRating, getMetrics }
