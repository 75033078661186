import { apiClient } from 'shared/utils'
import { Translations, WidgetTranslations } from 'modules/widgetTranslations'

const API_RESOURCE = 'widget/translates/lang'

const fetchCustomWidgetTranslations = (lang: string) => {
	return apiClient.get<Translations>(`${API_RESOURCE}/${lang}/${WidgetTranslations.Custom}`)
}

const fetchDefaultWidgetTranslations = (lang: string) => {
	return apiClient.get<Translations>(`${API_RESOURCE}/${lang}/${WidgetTranslations.Defaults}`)
}

const putWidgetTranslations = (lang: string, data: Translations) => {
	return apiClient.put(`${API_RESOURCE}/${lang}/${WidgetTranslations.Custom}`, data)
}

export const widgetTranslationsApi = {
	fetchCustomWidgetTranslations,
	fetchDefaultWidgetTranslations,
	putWidgetTranslations,
}
