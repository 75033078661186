import {
	ChatbotChatsDeleteBody,
	ChatbotChatSearchRequest,
	ChatsFetchResponse,
	ChatsFetchUnreadResponse,
	ChatsSearchResponse,
} from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE_BOTS = 'bot-conversations'
const API_RESOURCE_CHATS = 'v2/conversations'

const searchClosedChatbotChats = (requestParams: ChatbotChatSearchRequest) => {
	return apiClient.post<ChatsSearchResponse>(
		`${API_RESOURCE_BOTS}/search?contactDetails=true&visitorDetails=true&lastMessage=true`,
		requestParams,
	)
}

const fetchChatbotChat = (id: string) => {
	return apiClient.get<ChatsFetchResponse>(
		`${API_RESOURCE_BOTS}/${id}?lastMessage=true&visitorDetails=true&messages=true`,
	)
}

const deleteChatbotChat = (id: string) => {
	return apiClient.delete(`${API_RESOURCE_BOTS}/${id}`)
}

const deleteChat = (id: string) => {
	return apiClient.delete(`${API_RESOURCE_CHATS}/${id}`)
}

const deleteMultipleChatbotChats = (ids: ChatbotChatsDeleteBody) => {
	return apiClient.post(`${API_RESOURCE_BOTS}/delete`, ids)
}

const deleteMultipleChats = (ids: ChatbotChatsDeleteBody) => {
	return apiClient.post(`${API_RESOURCE_CHATS}/delete`, ids)
}

const fetchUnreadChatsCount = () => {
	return apiClient.get<ChatsFetchUnreadResponse>(`${API_RESOURCE_CHATS}/unread-chats`)
}

export const chatsSliceApi = {
	searchClosedChatbotChats,
	fetchChatbotChat,
	deleteChatbotChat,
	deleteMultipleChats,
	fetchUnreadChatsCount,
	deleteMultipleChatbotChats,
	deleteChat,
}
