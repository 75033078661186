import { Chatbot, ChatbotWorkflow } from 'shared/models/Chatbot'
import { Source } from 'shared/models/Source'

import { ChatbotWorkflowSection } from './types'

export const getNextWorkflowSection = (currentSection: ChatbotWorkflowSection): ChatbotWorkflowSection => {
	const sections = Object.values(ChatbotWorkflowSection)
	const currentSectionIndex = sections.indexOf(currentSection)
	const nextSectionIndex = currentSectionIndex + 1
	return sections[nextSectionIndex] || currentSection
}

export const getPreviousWorkflowSection = (currentSection: ChatbotWorkflowSection): ChatbotWorkflowSection => {
	const sections = Object.values(ChatbotWorkflowSection)
	const currentSectionIndex = sections.indexOf(currentSection)
	const previousSectionIndex = currentSectionIndex - 1
	return sections[previousSectionIndex] || currentSection
}

export const processSources = (formSources: Source['id'][], sources: Source[]): Source['id'][] => {
	// Returns existing sources only
	return formSources.filter((sourceId) => sources.some((source) => source.id === sourceId))
}

export const getWorkflowSubmitData = (workflow: ChatbotWorkflow, sources: Source[]): ChatbotWorkflow => {
	return {
		...workflow,
		sources: processSources(workflow.sources, sources),
	}
}

export const getComparableAiBot = (bot: Chatbot | null): Chatbot | null => {
	if (!bot) return null

	// Trigger ID is different for draft and published versions of the chatbot
	return {
		...bot,
		trigger: {
			...bot.trigger,
			id: null,
		},
	}
}
