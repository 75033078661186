import { routes } from 'configuration/routes'

export const MOBILE_SDK = 'Mobile SDK'
export const WHATSAPP = 'WhatsApp'
export const MOBILE_APP = 'Smartsupp Mobile App'
export const MOBILE_SDK_TITLE = `Smartsupp ${MOBILE_SDK}`

export enum ChannelTypeExtensions {
	WhatsApp = 'WhatsApp',
	GoogleAnalytics = 'GoogleAnalytics',
}

export const integrationPathMap: { [key: string]: string } = {
	'mobile-sdk': routes.mobileSdk.path,
}
