import { Tab, TabProps } from '@chakra-ui/tabs'

export { TabList as CTabList, TabPanel as CTabPanel, TabPanels as CTabPanels, Tabs as CTabs } from '@chakra-ui/react'

export const CTab = ({ ...props }: TabProps) => {
	return (
		<Tab
			fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
			borderBottom="3px solid"
			borderColor="transparent"
			color="gray.600"
			fontWeight="500"
			_active={{
				bg: 'transparent',
			}}
			_selected={{
				color: 'blue.500',
				borderBottomColor: 'blue.500',
			}}
			_hover={{
				borderBottomColor: 'blue.500',
			}}
			{...props}
		/>
	)
}
