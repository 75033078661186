import { ChatbotCategory, ChatbotTemplateType, TemplateStorePromotedTemplateType } from 'models'
import { leadgenPromotedTemplates, promotedTemplates } from 'modules/chatbotTemplates/constants'

export const isSalesPromotedTemplate = (templateType: ChatbotTemplateType) => {
	return promotedTemplates.has(templateType)
}

export const isLeadgenPromotedTemplate = (templateType: ChatbotTemplateType) => {
	return leadgenPromotedTemplates.has(templateType)
}

export const getChecklistItemStrings = (poString: string, numberOfItems: number) => {
	return Array.from({ length: numberOfItems }, (_, i) => `${poString}${i + 1}`)
}

export const getShouldAttachRibbon = (templateType: TemplateStorePromotedTemplateType) => {
	switch (templateType) {
		case ChatbotCategory.ChatbotLeadCollection:
		case ChatbotCategory.ChatbotNewClients:
		case ChatbotCategory.ChatbotMoreMeetings: {
			return true
		}
		default: {
			return false
		}
	}
}
