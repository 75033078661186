import { forwardRef } from 'react'

import { Box, BoxProps } from 'core'

type CodeProps = BoxProps

const Code = forwardRef<HTMLDivElement, CodeProps>((props, ref) => {
	return (
		<Box
			as="pre"
			ref={ref}
			p={2}
			bgColor="gray.100"
			fontSize={0}
			border={1}
			borderColor="gray.300"
			borderRadius={1}
			overflow="auto"
			{...props}
		/>
	)
})

Code.displayName = 'Code'

export default Code
