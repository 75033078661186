import moment from 'moment'

import 'moment/locale/cs'
import 'moment/locale/es'
import 'moment/locale/pt'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/pl'
import 'moment/locale/hu'

// Update EN locale to match ISO-8601, Europe
moment.updateLocale('en', {
	week: {
		dow: 1, // First day of week is Monday
		doy: 4, // First week of year must contain 4 January (7 + 1 - 4)
	},
})
