import { ApnsSdkFormData, FirebaseSdkFormData } from 'shared/models/MobileSdk'
import { getMobileSdkDocsLink } from 'configuration/links'

export const createPostFormData = (data: ApnsSdkFormData | FirebaseSdkFormData) => {
	const postFormData = new FormData()
	Object.keys(data).forEach((key) => {
		if (key === 'file') {
			postFormData.append(key, data[key], data[key].name)
		} else {
			postFormData.append(key, data[key as keyof typeof data] as string)
		}
	})
	return postFormData
}

export const onDocumentationClick = () => {
	window.open(getMobileSdkDocsLink(), '_blank')
}
