import { batch } from 'react-redux'
import { createAsyncThunk, createSelector, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit'

import { AgentEvents, Contact, DashboardContentEventType, VisitorSimple } from 'models'
import { ApiError } from 'shared/types'
import { AppThunkAction, DashboardState, Dictionary } from 'types'
import {
	Visitor,
	VisitorDisconnected,
	VisitorFilterType,
	VisitorsSearchRequest,
	VisitorUpdated,
} from 'shared/models/Visitor'
import { flashMessage, pick } from 'shared/utils'
import { getVisitorId } from 'selectors/commonSelectors'
import { agentClientProvider, createSystemMessage, normalize, sortDictionary } from 'utils'
import { selectChat, setChannelOnVisitorChange } from 'modules/chatDetail'
import { chatsSelectors } from 'modules/chats'
import { chatUpdated, visitorDetailsUpdated } from 'modules/chats/actions'
import { updateContact } from 'modules/contacts'
import { messageReceived } from 'modules/messages'
import { userSelectors } from 'modules/user'

import { visitorsApi } from './api'
import {
	createVisitorFilter,
	getUpdatedVisitorFilterResult,
	isVisitorsFilterActive,
	loadVisitorsFilter,
	storeVisitorsFilter,
	visitorsFilterToRequestParams,
	visitorsSorter,
} from './utils'

export type VisitorsRootState = Pick<DashboardState, 'visitors'>
const VISITORS_PER_PAGE = 100

type VisitorUpdateData = Partial<Pick<Visitor, 'contactId'>>

const initialState = {
	filter: loadVisitorsFilter(),
	textFilter: '',
	// Fetched single visitors
	visitors: {} as Dictionary<Visitor>,
	// Fetched visitors from search request
	visitorList: {} as Dictionary<VisitorSimple>,
	visitorsTotal: 0,
	visitorsFilteredCount: 0, // Number of visitors after applying base filter (without applying text filter)
	visitorsSearchedCount: 0, // Number of visitors after applying base filter and text filter
	lastResponseAfter: null as null | number,
	isSearchingVisitors: false,
	isDrawerOpen: false,
	selectedVisitorId: null as null | string,
	selectedContactId: null as null | string,
	visitorsLoaded: false,
	isOpeningChat: false,
}

export const searchVisitorsList = createAsyncThunk(
	'visitors/SEARCH_VISITORS',
	async (data: VisitorsSearchRequest, { rejectWithValue }) => {
		try {
			return await visitorsApi.searchVisitors(data)
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

export const fetchVisitor = createAsyncThunk('visitors/FETCH_VISITOR', (visitorId: string) => {
	return visitorsApi.fetchVisitor(visitorId)
})

export const fetchVisitorThunk =
	(visitorId: string): AppThunkAction<Promise<Visitor>> =>
	async (dispatch) => {
		const response = await dispatch(fetchVisitor(visitorId))
		return unwrapResult(response)
	}

export const saveVisitorsFilter =
	(filter: VisitorFilterType): AppThunkAction =>
	(dispatch) => {
		storeVisitorsFilter(filter)
		dispatch(visitorsFilterChanged(filter))
	}

/**
 * API calls
 */

export const searchVisitors = (): AppThunkAction => async (dispatch, getState) => {
	const state = getState()
	const visitorsFilter = visitorsSelectors.getFilter(state)
	const visitorsTextFilter = visitorsSelectors.getTextFilter(state)
	const filterParams = visitorsFilterToRequestParams(visitorsFilter, visitorsTextFilter)
	const data: VisitorsSearchRequest = { size: VISITORS_PER_PAGE, after: null, ...filterParams }
	const resultAction = await dispatch(searchVisitorsList(data))
	if (searchVisitorsList.fulfilled.match(resultAction)) {
		dispatch(setVisitorsLoaded())
	}
}

export const searchMoreVisitors = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const after = visitorsSelectors.getLastResponseAfter(state)

	// Don't send request if page is last
	if (after === -1) return
	const data: VisitorsSearchRequest = { size: VISITORS_PER_PAGE, after }
	dispatch(searchVisitorsList(data))
}

export const openChat =
	(visitorId: string): AppThunkAction =>
	async (dispatch) => {
		dispatch(setIsOpeningChat(true))
		try {
			const response = await visitorsApi.getVisitorChatId(visitorId)
			if (response.id) {
				dispatch(selectChat(response.id))
			}
		} catch {
			flashMessage.error('general.error')
		} finally {
			dispatch(setIsOpeningChat(false))
		}
	}

export const identifyVisitor =
	(visitorId: string, data: Partial<Contact>): AppThunkAction =>
	async (dispatch) => {
		try {
			const response = await visitorsApi.identifyVisitor(visitorId)
			if (response.id) {
				dispatch(updateContact({ id: response.id, changes: data }))
				dispatch(updateVisitor(visitorId, { contactId: response.id }))
			}
		} catch {
			flashMessage.error('general.error')
		}
	}

export const fetchVisitorDetails =
	(visitorId: string | null): AppThunkAction<Promise<Visitor | null>> =>
	async (dispatch) => {
		if (!visitorId) return null

		try {
			const response = await dispatch(fetchVisitorThunk(visitorId))
			if (response) {
				dispatch(updateVisitorDetails(response.id, { connectedAt: response.connectedAt }))
				if (response.chatId) {
					dispatch(setChannelOnVisitorChange(response.chatId, !!response.connectedAt))
				}
			}
			return response
		} catch {
			return null
		}
	}

export const updateVisitor =
	(visitorId: string, data: VisitorUpdateData): AppThunkAction =>
	(dispatch, getState) => {
		const getVisitorById = makeGetVisitorById()
		const visitor = getVisitorById(getState(), { visitorId })

		if (visitor) {
			const updatedVisitor = pick(visitor, 'id', 'name', 'email', 'status', 'bannedAt', 'connectedAt')
			dispatch(onVisitorUpdated({ ...updatedVisitor, changes: data, previous: visitor }))
		}
	}

export const updateVisitorDetails =
	(visitorId: string, values: Pick<Visitor, 'connectedAt'>): AppThunkAction =>
	(dispatch, getState) => {
		const getChatsByVisitorId = chatsSelectors.makeGetChatsByVisitorId()
		const chats = getChatsByVisitorId(getState(), { visitorId })

		// update visitorDetails for each filtered chat by visitorId
		chats.forEach((chat) => {
			dispatch(visitorDetailsUpdated({ chatId: chat.id, values }))
		})
	}

export const trackVisitors = (track: boolean) => {
	const agentClient = agentClientProvider.getAgentClient()
	agentClient?.setTrackVisitors(track)
}

/**
 * Websocket events
 */
export const onVisitorConnected =
	(visitor: AgentEvents.VisitorConnected): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const selectedChatId = chatsSelectors.getChatDetailId(state)

		batch(() => {
			dispatch(visitorConnected({ visitor }))
			dispatch(sortVisitors())
			dispatch(updateVisitorDetails(visitor.id, { connectedAt: visitor.connectedAt }))
			if (visitor.chatId) {
				dispatch(setChannelOnVisitorChange(visitor.chatId, true))
			}
		})

		const userId = userSelectors.getUserId(state)
		if (!userId || !visitor?.chatId) return

		if (selectedChatId === visitor.chatId) {
			dispatch(
				messageReceived({
					chatId: visitor.chatId,
					userId,
					message: createSystemMessage({
						type: DashboardContentEventType.VisitorConnect,
						id: visitor.id,
						chatId: visitor.chatId,
					}),
				}),
			)
		}
	}

export const onVisitorDisconnected =
	(disconnectedVisitor: AgentEvents.VisitorDisconnected): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const getVisitorById = visitorsSelectors.makeGetVisitorById()
		const visitor = getVisitorById(state, { visitorId: disconnectedVisitor.id })
		const selectedChatId = chatsSelectors.getChatDetailId(state)
		const userId = userSelectors.getUserId(state)
		if (!userId) return

		batch(() => {
			dispatch(visitorDisconnected({ disconnectedVisitor, chatId: visitor?.chatId }))
			dispatch(updateVisitorDetails(disconnectedVisitor.id, { connectedAt: null }))
			if (visitor && visitor.chatId) {
				dispatch(setChannelOnVisitorChange(visitor.chatId, false))
			}
		})

		if (!visitor?.chatId) return

		if (selectedChatId === visitor.chatId) {
			dispatch(chatUpdated({ chatId: visitor.chatId, values: { paths: visitor.paths, variables: visitor.variables } }))

			dispatch(
				messageReceived({
					chatId: visitor.chatId,
					userId,
					message: createSystemMessage({
						type: DashboardContentEventType.VisitorDisconnect,
						id: disconnectedVisitor.id,
						chatId: visitor.chatId,
					}),
				}),
			)
		}
	}

export const onVisitorUpdated =
	(updatedVisitor: AgentEvents.VisitorUpdated): AppThunkAction =>
	async (dispatch, getState) => {
		const { id, changes, connectedAt } = updatedVisitor
		const state = getState()
		const userId = userSelectors.getUserId(state)
		const visitorList = visitorsSelectors.getVisitorList(state)
		const getVisitorById = visitorsSelectors.makeGetVisitorById()

		let visitor = getVisitorById(state, { visitorId: id })

		// If visitor isn't in the store, decide if he should be fetched and possibly displayed in visitor list
		if (!visitor && !visitorList[id]) {
			const minVisitorConnectedTime = getMinVisitorConnectedTime(state)
			const visitorListSize = getVisitorListSize(state)
			const visitorConnectedTime = connectedAt ? new Date(connectedAt).getTime() : Number.POSITIVE_INFINITY
			const couldBeVisibleInVisitorList =
				visitorListSize < VISITORS_PER_PAGE || visitorConnectedTime > minVisitorConnectedTime

			if (couldBeVisibleInVisitorList) {
				visitor = await visitorsApi.fetchVisitor(id)
			}
		}

		// Try to find chat ID for visitor
		let chatId
		if (visitor?.chatId) {
			chatId = visitor.chatId
		} else {
			const getChatByVisitorId = chatsSelectors.makeGetChatByVisitorId()
			const chat = getChatByVisitorId(state, { visitorId: id })
			chatId = chat?.id ?? null
		}

		dispatch(visitorUpdated({ updatedVisitor, chatId }))
		dispatch(updateVisitorDetails(id, { connectedAt }))

		// Sort visitor list when visitor status was changed
		if ('status' in changes) {
			dispatch(sortVisitors())
		}

		if (!userId) return

		// Create system message when pageUrl was changed
		if (chatId && 'pageUrl' in changes) {
			dispatch(
				messageReceived({
					chatId,
					userId,
					message: createSystemMessage({ type: DashboardContentEventType.VisitorChangeUrl, data: changes, id, chatId }),
				}),
			)
		}
	}

const slice = createSlice({
	name: 'visitors',
	initialState,
	reducers: {
		setDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
			state.isDrawerOpen = payload
		},
		setSelectedVisitorId: (state, { payload }: PayloadAction<string | null>) => {
			state.selectedVisitorId = payload
		},
		setSelectedContactId: (state, { payload }: PayloadAction<string | null>) => {
			state.selectedContactId = payload
		},
		visitorConnected: (state, { payload }: PayloadAction<{ visitor: Visitor }>) => {
			const { visitor } = payload
			const isFilterActive = isVisitorsFilterActive(state.filter, state.textFilter)
			state.visitors[visitor.id] = visitor
			state.visitorsTotal += 1

			// Add visitor to list only if filter is not active or visitor conforms all filter conditions
			const isVisitorFilteredByAllFilters = createVisitorFilter(state.filter, state.textFilter)
			if (!isFilterActive || isVisitorFilteredByAllFilters(visitor)) {
				state.visitorList[visitor.id] = visitor
				state.visitorsSearchedCount += 1
			}

			// Increment filtered visitors count if visitor conforms base filter conditions (without text filter)
			const isVisitorFilteredByBaseFilter = createVisitorFilter(state.filter, '')
			if (!isFilterActive || isVisitorFilteredByBaseFilter(visitor)) {
				state.visitorsFilteredCount += 1
			}
		},
		visitorDisconnected: (
			state,
			{ payload }: PayloadAction<{ disconnectedVisitor: VisitorDisconnected; chatId?: string | null }>,
		) => {
			const { disconnectedVisitor } = payload
			const { id } = disconnectedVisitor
			const isFilterActive = isVisitorsFilterActive(state.filter, state.textFilter)

			state.visitorsTotal -= 1
			if (state.visitorList[id]) {
				delete state.visitorList[id]
			}

			// Decrement filtered visitors count if visitor conforms to base filter (without text filter)
			const isVisitorFiltered = createVisitorFilter(state.filter, '')
			if (isFilterActive && isVisitorFiltered(disconnectedVisitor)) {
				state.visitorsFilteredCount -= 1
			}

			// Decrement searched visitors count if visitor conforms all filter conditions
			const isVisitorFilteredByAllFilters = createVisitorFilter(state.filter, state.textFilter)
			if (isFilterActive && isVisitorFilteredByAllFilters(disconnectedVisitor)) {
				state.visitorsSearchedCount -= 1
			}

			if (state.visitors[id]) {
				// state.visitors[id].connectedAt = null
				delete state.visitors[id]
			}
		},
		visitorUpdated: (
			state,
			{
				payload,
			}: PayloadAction<{
				updatedVisitor: VisitorUpdated
				chatId?: string | null
			}>,
		) => {
			const { updatedVisitor } = payload
			const { id, changes } = updatedVisitor
			const isFilterActive = isVisitorsFilterActive(state.filter, state.textFilter)

			// Update visitor
			const visitor = state.visitors[id]
			if (visitor) {
				state.visitors[id] = { ...visitor, ...changes }

				// Handle page url change
				if ('pageUrl' in changes && changes.pageTitle && changes.pageUrl) {
					const { paths } = state.visitors[id]
					if (paths) {
						paths.push({ title: changes.pageTitle, url: changes.pageUrl, createdAt: new Date().toISOString() })
					} else {
						state.visitors[id].paths = [
							{
								title: changes.pageTitle,
								url: changes.pageUrl,
								createdAt: new Date().toISOString(),
							},
						]
					}
				}
			}

			/**
			 * Updated visitor is in visitor list
			 */
			const visitorListItem = state.visitorList[id]
			if (visitorListItem) {
				const allFiltersResult = getUpdatedVisitorFilterResult(state.filter, state.textFilter, updatedVisitor)

				// Update visitor if he conforms to filter, otherwise remove him from the list
				if (!isFilterActive || allFiltersResult.isFilteredAfterChanges) {
					state.visitorList[id] = { ...visitorListItem, ...changes }
				} else {
					delete state.visitorList[visitorListItem.id]
					state.visitorsFilteredCount -= 1
				}
				return
			}

			/**
			 * Updated visitor is not in visitor list
			 */
			if (!isFilterActive) return

			// Get filter result for updated visitor if he conforms to filter now (after changes) or conformed to filter before (before changes)
			const baseFilterResult = getUpdatedVisitorFilterResult(state.filter, '', updatedVisitor)

			// Visitor now conforms to base filter
			if (baseFilterResult.isFilteredAfterChanges && !baseFilterResult.isFilteredBeforeChanges) {
				state.visitorsFilteredCount += 1

				// Try to add visitor to visitor list
				// Visitor data should be available in the store from fetch in visitorUpdate action
				const storedUpdatedVisitor = state.visitors[id]
				if (storedUpdatedVisitor) {
					// Text filter is not active, add visitor to list
					if (state.textFilter.length === 0) {
						state.visitorList[id] = storedUpdatedVisitor
						return
					}

					// Text filter is active, check if visitor conforms also to text filter
					const allFiltersResult = getUpdatedVisitorFilterResult(state.filter, state.textFilter, updatedVisitor)
					if (allFiltersResult.isFilteredAfterChanges) {
						state.visitorList[id] = storedUpdatedVisitor
					}
				}
				return
			}

			// Visitor doesn't conform to filter anymore
			if (!baseFilterResult.isFilteredAfterChanges && baseFilterResult.isFilteredBeforeChanges) {
				state.visitorsFilteredCount -= 1
			}
		},
		visitorsFilterChanged: (state, { payload }: PayloadAction<VisitorFilterType>) => {
			state.filter = payload
		},
		visitorsTextFilterChanged: (state, { payload }: PayloadAction<string>) => {
			state.textFilter = payload
		},
		sortVisitors: (state) => {
			state.visitorList = sortDictionary(state.visitorList, visitorsSorter)
		},
		setVisitorsLoaded: (state) => {
			state.visitorsLoaded = true
		},
		setIsOpeningChat: (state, { payload }: PayloadAction<boolean>) => {
			state.isOpeningChat = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(searchVisitorsList.fulfilled, (state, { payload, meta }) => {
				const { after, items, counters } = payload
				const loadMoreVisitors = !!meta?.arg?.after
				state.isSearchingVisitors = false
				if (loadMoreVisitors) {
					const mergedVisitors = { ...state.visitorList, ...normalize('id', items) }
					state.visitorList = sortDictionary(mergedVisitors, visitorsSorter)
					state.lastResponseAfter = after
				} else {
					const sortedVisitors = items.sort(visitorsSorter)
					state.visitorList = normalize('id', sortedVisitors)
					state.lastResponseAfter = after
					state.visitorsTotal = counters.total
					state.visitorsFilteredCount = counters.filtered
					state.visitorsSearchedCount = counters.searched
				}
			})
			.addCase(searchVisitorsList.pending, (state) => {
				state.isSearchingVisitors = true
			})
			.addCase(searchVisitorsList.rejected, (state) => {
				state.isSearchingVisitors = false
			})
		builder.addCase(fetchVisitor.fulfilled, (state, { payload }) => {
			const visitor = payload
			state.visitors[visitor.id] = visitor
		})
	},
})

export const getVisitorsData = (state: DashboardState) => state.visitors.visitors
export const getVisitorList = (state: DashboardState) => state.visitors.visitorList
export const getTotalVisitors = (state: DashboardState) => state.visitors.visitorsTotal
export const getFilteredVisitorsCount = (state: DashboardState) => state.visitors.visitorsFilteredCount
export const getSearchedVisitorsCount = (state: DashboardState) => state.visitors.visitorsSearchedCount
export const getLastResponseAfter = (state: DashboardState) => state.visitors.lastResponseAfter
export const getFilter = (state: DashboardState) => state.visitors.filter
export const getTextFilter = (state: DashboardState) => state.visitors.textFilter
export const isSearchingVisitors = (state: DashboardState) => state.visitors.isSearchingVisitors
export const getIsDrawerOpen = (state: DashboardState) => state.visitors.isDrawerOpen
export const getSelectedVisitorId = (state: DashboardState) => state.visitors.selectedVisitorId
export const getSelectedContactId = (state: DashboardState) => state.visitors.selectedContactId
export const getVisitorsLoaded = (state: DashboardState) => state.visitors.visitorsLoaded
const getIsOpeningChat = (state: DashboardState) => state.visitors.isOpeningChat

export const getVisitorListArray = createSelector([getVisitorList], (visitors) => Object.values(visitors))

export const makeGetVisitorById = () => {
	return createSelector([getVisitorsData, getVisitorId], (visitors, visitorId) => {
		if (!visitorId) return null
		return visitors[visitorId]
	})
}

export const makeGetVisitorByIdTwo = (visitorId: string | null) => {
	return createSelector([getVisitorsData], (visitors) => {
		if (!visitorId) return null
		return visitors[visitorId]
	})
}

export const getMinVisitorConnectedTime = createSelector([getVisitorListArray], (visitors) => {
	return visitors.reduce((min, { connectedAt }) => {
		if (!connectedAt) return min
		const time = new Date(connectedAt).getTime()
		return min > time ? time : min
	}, Number.POSITIVE_INFINITY)
})

export const getVisitorListSize = createSelector([getVisitorListArray], (visitors) => visitors.length)

export const getIsVisitorsFilterActive = createSelector([getFilter, getTextFilter], (filter, textFilter): boolean => {
	return isVisitorsFilterActive(filter, textFilter)
})

export const getCombinedFilteredVisitorsCount = createSelector(
	[getIsVisitorsFilterActive, getTotalVisitors, getFilteredVisitorsCount],
	(isFilterActive, totalVisitors, filteredCount): number => {
		return isFilterActive ? filteredCount : totalVisitors
	},
)

const { reducer, actions } = slice
export const {
	visitorConnected,
	sortVisitors,
	visitorUpdated,
	visitorDisconnected,
	visitorsFilterChanged,
	visitorsTextFilterChanged,
	setDrawerOpen,
	setSelectedVisitorId,
	setSelectedContactId,
	setVisitorsLoaded,
	setIsOpeningChat,
} = actions
export default reducer
export const visitorsSelectors = {
	getFilter,
	getTextFilter,
	getLastResponseAfter,
	getVisitorsData,
	getVisitorList,
	getTotalVisitors,
	getFilteredVisitorsCount,
	isSearchingVisitors,
	getIsDrawerOpen,
	getSelectedVisitorId,
	getSelectedContactId,
	getVisitorListArray,
	makeGetVisitorById,
	getMinVisitorConnectedTime,
	getVisitorListSize,
	getIsVisitorsFilterActive,
	getSearchedVisitorsCount,
	getCombinedFilteredVisitorsCount,
	makeGetVisitorByIdTwo,
	getVisitorsLoaded,
	getIsOpeningChat,
}
