import { useSelector } from 'react-redux'

import { agentsSelectors } from 'modules/agents'

import { Box, Text } from 'core'

const SidebarAgentMenuGroups = () => {
	const agent = useSelector(agentsSelectors.getAgentByUser)

	if (!agent) return null

	const { groups } = agent
	if (groups.length === 0) return null

	return (
		<Box mt={1}>
			<Text as="p" mb={0} fontSize={0} textColor="gray.500" textAlign="center">
				{groups.map((group) => group.name).join(', ')}
			</Text>
		</Box>
	)
}

export default SidebarAgentMenuGroups
