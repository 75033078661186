import { AffiliateApi, AffilOverview, AffilStatus } from 'shared/models/Affiliate'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'affiliate'

const fetchAffiliateOverview = () => {
	return apiClient.get<AffilOverview>(`${API_RESOURCE}/overview`)
}

const activateAffiliate = () => {
	return apiClient.post(`${API_RESOURCE}/activate`)
}

const fetchAffiliateClients = ({ limit, offset }: AffiliateApi.GetClientsQuery) => {
	return apiClient.get<AffiliateApi.GetClientsResponse>(`${API_RESOURCE}/clients?limit=${limit}&offset=${offset}`)
}

const fetchAffiliateRewards = ({ limit, offset }: AffiliateApi.GetRevenuesQuery) => {
	return apiClient.get<AffiliateApi.GetRevenuesResponse>(`${API_RESOURCE}/revenues?limit=${limit}&offset=${offset}`)
}

const payoutAffiliate = () => {
	return apiClient.post(`${API_RESOURCE}/payout`)
}

const fetchAffiliateStatus = () => {
	return apiClient.get<AffilStatus>(`${API_RESOURCE}/status`)
}

export const affiliateApi = {
	activateAffiliate,
	fetchAffiliateClients,
	fetchAffiliateOverview,
	fetchAffiliateRewards,
	payoutAffiliate,
	fetchAffiliateStatus,
}
