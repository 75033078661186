declare const __HASH__: string

export enum CustomHeader {
	ApiVersion = 'X-Api-Version',
	ClientVersion = 'X-Client-Version',
	Recaptcha = 'x-recaptcha-token',
}

export const getHttpHeaders = () => {
	const headers: Record<string, string> = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		[CustomHeader.ClientVersion]: __HASH__ || '',
	}
	return headers
}
