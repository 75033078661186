import { createSlice } from '@reduxjs/toolkit'

import { DashboardState } from 'types'

export const initialState = {
	updatePaymentModal: false,
	autoRenewalSurveyModal: false,
	pauseSubscriptionModal: false,
	resumeExpiredSubscriptionModal: false,
}

const slice = createSlice({
	name: 'billingProfile',
	initialState,
	reducers: {
		showUpdatePaymentModal: (state) => {
			state.updatePaymentModal = true
		},
		hideUpdatePaymentModal: (state) => {
			state.updatePaymentModal = false
		},
		showAutoRenewalSurveyModal: (state) => {
			state.autoRenewalSurveyModal = true
		},
		hideAutoRenewalSurveyModal: (state) => {
			state.autoRenewalSurveyModal = false
		},
		showPauseSubscriptionModal: (state) => {
			state.pauseSubscriptionModal = true
		},
		hidePauseSubscriptionModal: (state) => {
			state.pauseSubscriptionModal = false
		},
		showResumeExpiredSubscriptionModal: (state) => {
			state.resumeExpiredSubscriptionModal = true
		},
		hideResumeExpiredSubscriptionModal: (state) => {
			state.resumeExpiredSubscriptionModal = false
		},
	},
})

const { reducer, actions } = slice
export const {
	showUpdatePaymentModal,
	hideUpdatePaymentModal,
	showAutoRenewalSurveyModal,
	hideAutoRenewalSurveyModal,
	showPauseSubscriptionModal,
	hidePauseSubscriptionModal,
	showResumeExpiredSubscriptionModal,
	hideResumeExpiredSubscriptionModal,
} = actions
export default reducer

const getIsUpdatePaymentOpen = (state: DashboardState) => state.billingProfile.updatePaymentModal
const getIsAutoRenewalSurveyModalOpen = (state: DashboardState) => state.billingProfile.autoRenewalSurveyModal
const getIsPauseSubscriptionModalOpen = (state: DashboardState) => state.billingProfile.pauseSubscriptionModal
const getIsResumeExpiredSubscriptionModalOpen = (state: DashboardState) =>
	state.billingProfile.resumeExpiredSubscriptionModal

export const billingProfileSelectors = {
	getIsUpdatePaymentOpen,
	getIsAutoRenewalSurveyModalOpen,
	getIsPauseSubscriptionModalOpen,
	getIsResumeExpiredSubscriptionModalOpen,
}
