const sizes = {
	lg: {
		label: {
			fontSize: 1,
		},
	},
}

const baseStyle = {
	control: {
		border: 1,
		borderColor: 'gray.300',
	},
}

export const Checkbox = {
	sizes,
	baseStyle,
}
