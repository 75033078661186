import { useSelector } from 'react-redux'
import { useBreakpointValue } from '@chakra-ui/react'
import { mdiCog } from '@mdi/js'

import { TranslationService as T } from 'shared/services'
import { routes } from 'configuration/routes'
import { userSelectors } from 'modules/user'

import { Box } from 'shared/components/core/Box'

import SidebarMenuIcon from '../sidebarMenu/SidebarMenuIcon'
import SidebarMenuItem from '../sidebarMenu/SidebarMenuItem'
import SidebarMenuItemPopover from '../sidebarMenu/SidebarMenuItemPopover'

import SidebarSettingsMenu from './SidebarSettingsMenu'

const SettingsIcon = () => (
	<SidebarMenuItem path={routes.settings.path} exactPath={false} tooltip={T.translate('headerBar.settings')}>
		<SidebarMenuIcon icon={mdiCog} />
	</SidebarMenuItem>
)

const SidebarSettings = () => {
	const isMobile = useBreakpointValue({ base: true, lg: false })
	const isUserAdmin = useSelector(userSelectors.isUserAdmin)

	const settingsAdmin = isMobile ? (
		<SidebarMenuItemPopover popoverContent={<SidebarSettingsMenu />}>
			<SidebarMenuIcon icon={mdiCog} />
		</SidebarMenuItemPopover>
	) : (
		<SettingsIcon />
	)

	const settingsLimited = isMobile ? null : <SettingsIcon />

	return (
		<Box data-testid="sidebar-settings" data-userpilot="sidebar-settings">
			{isUserAdmin ? settingsAdmin : settingsLimited}
		</Box>
	)
}

export default SidebarSettings
