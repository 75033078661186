import { createSelector, createSlice, isFulfilled, PayloadAction } from '@reduxjs/toolkit'
import equal from 'fast-deep-equal'

import { AppThunkAction, DashboardState } from 'types'
import { Chatbot, ChatbotWorkflow } from 'shared/models/Chatbot'
import { Source } from 'shared/models/Source'
import { flashMessage } from 'shared/utils'
import { aiChatbotsSelectors } from 'modules/aiChatbots'
import { chatbotDetailSelectors } from 'modules/chatbotDetail'
import { fetchChatbot, fetchPublishedChatbot, updateChatbot } from 'modules/chatbotDetail/actions'
import { sourcesSelectors } from 'modules/sources'
import { usageSelectors } from 'modules/usage'
import { AiPreviewState } from 'modules/widgetPreview'

import { DEFAULT_SKILL, DEFAULT_WORKFLOW } from './constants'
import { ChatbotWorkflowSection, ChatbotWorkflowSkills } from './types'
import { getComparableAiBot, getNextWorkflowSection, getPreviousWorkflowSection, getWorkflowSubmitData } from './utils'

type ChatbotWorkflowState = Pick<DashboardState, 'chatbotWorkflow'>

const initialState = {
	chatbotWorkflow: DEFAULT_WORKFLOW,
	selectedSection: ChatbotWorkflowSection.Basics,
	selectedSkillsSection: DEFAULT_SKILL,
	isWorkflowFormDirty: false,
	isPending: false,
	selectedSources: [] as Source['id'][],
	isPublishButtonLoading: false,
	isFetchingPublishedAiBot: false,
	chatbotVersions: {
		draft: null as null | Chatbot,
		published: null as null | Chatbot,
	},
}

export const submitChatbotWorkflowForm =
	(workflow: ChatbotWorkflow, next?: () => void): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const chatbot = chatbotDetailSelectors.getSelectedChatbot(state)
		const sources = sourcesSelectors.getSources(state)

		if (!chatbot) return

		const workflowData = getWorkflowSubmitData(workflow, sources)
		const resultAction = await dispatch(
			updateChatbot({
				botId: chatbot.id,
				changes: { workflow: workflowData },
			}),
		)

		if (updateChatbot.fulfilled.match(resultAction)) {
			if (next) {
				next()
			} else {
				flashMessage.success('chatbot.save.success', {
					duration: 3000,
					testId: 'flashMessage-success-chatbot-workflow-update',
				})
			}
		} else {
			flashMessage.error('general.error', { testId: 'flashMessage-error-chatbot-workflow-update' })
		}
	}

const chatbotWorkflowSlice = createSlice({
	name: 'chatbotWorkflow',
	initialState,
	reducers: {
		setSelectedSection: (state, { payload }: PayloadAction<ChatbotWorkflowSection>) => {
			state.selectedSection = payload
		},
		setSelectedSkillsSection: (state, { payload }: PayloadAction<ChatbotWorkflowSkills>) => {
			state.selectedSkillsSection = payload
		},
		selectNextSection: (state) => {
			state.selectedSection = getNextWorkflowSection(state.selectedSection)
		},
		selectPreviousSection: (state) => {
			state.selectedSection = getPreviousWorkflowSection(state.selectedSection)
		},
		setWorkflowFormDirty: (state, { payload }: PayloadAction<boolean>) => {
			state.isWorkflowFormDirty = payload
		},
		setSelectedSources(state, { payload }: PayloadAction<Source['id'][]>) {
			state.selectedSources = payload
		},
		setIsPublishButtonLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isPublishButtonLoading = payload
		},
		setWorkflowChatbotPublishedVersion: (state, { payload }: PayloadAction<Chatbot>) => {
			state.chatbotVersions.published = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPublishedChatbot.fulfilled, (state, { payload }) => {
				state.chatbotVersions.published = payload
				state.isFetchingPublishedAiBot = false
			})
			.addCase(fetchPublishedChatbot.pending, (state) => {
				state.isFetchingPublishedAiBot = true
			})
			.addCase(fetchPublishedChatbot.rejected, (state) => {
				state.isFetchingPublishedAiBot = false
			})
		builder.addCase(updateChatbot.pending, (state) => {
			state.isPending = true
		})
		builder.addCase(updateChatbot.rejected, (state) => {
			state.isPending = false
		})
		builder.addMatcher(isFulfilled(fetchChatbot, updateChatbot), (state, { payload }) => {
			state.isPending = false
			state.chatbotVersions.draft = payload
			if (payload.workflow) {
				state.chatbotWorkflow = payload.workflow
			}
		})
	},
})

const getChatbotWorkflow = (state: ChatbotWorkflowState) => state.chatbotWorkflow.chatbotWorkflow
const getSelectedSection = (state: ChatbotWorkflowState) => state.chatbotWorkflow.selectedSection
const getSelectedSkillsSection = (state: ChatbotWorkflowState) => state.chatbotWorkflow.selectedSkillsSection
const getIsWorkflowFormDirty = (state: ChatbotWorkflowState) => state.chatbotWorkflow.isWorkflowFormDirty
const getIsWorkflowFormPending = (state: ChatbotWorkflowState) => state.chatbotWorkflow.isPending
const getSelectedSources = (state: ChatbotWorkflowState) => state.chatbotWorkflow.selectedSources
const getChatbotVersions = (state: ChatbotWorkflowState) => state.chatbotWorkflow.chatbotVersions
const getIsFetchingPublishedAiBot = (state: ChatbotWorkflowState) => state.chatbotWorkflow.isFetchingPublishedAiBot
const getIsPublishButtonLoading = (state: ChatbotWorkflowState) => state.chatbotWorkflow.isPublishButtonLoading

const getIsSelectedLastSection = createSelector([getSelectedSection], (selectedSection) => {
	const sections = Object.values(ChatbotWorkflowSection)
	return sections.indexOf(selectedSection) === sections.length - 1
})

const getIsSelectedFirstSection = createSelector([getSelectedSection], (selectedSection) => {
	const sections = Object.values(ChatbotWorkflowSection)
	return sections.indexOf(selectedSection) === 0
})

const getHasSelectedSources = createSelector([getSelectedSources], (selectedSources) => selectedSources.length > 0)

const getHasSelectedPendingSources = createSelector(
	[getSelectedSources, sourcesSelectors.getPendingSources],
	(selectedSources, pendingSources) => {
		return selectedSources.some((sourceId) => pendingSources.includes(sourceId))
	},
)

const getHasSelectedFailedSources = createSelector(
	[getSelectedSources, sourcesSelectors.getFailedSources],
	(selectedSources, pendingSources) => {
		return selectedSources.some((sourceId) => pendingSources.includes(sourceId))
	},
)

const getSelectedSourcesState = createSelector(
	[getHasSelectedPendingSources, getHasSelectedFailedSources, usageSelectors.getIsAiPreviewLimitReached],
	(hasPendingSources, hasFailedSources, isPreviewLimitReached): AiPreviewState => {
		if (hasFailedSources) return 'failed'
		if (hasPendingSources) return 'pending'
		if (isPreviewLimitReached) return 'limitReached'
		return 'ready'
	},
)

// Compares the draft version and the published version of the bot for unpublished changes, necessary to display correct version of publish action
const getHasUnpublishedChanges = createSelector([getChatbotVersions], (versions) => {
	const { draft, published } = versions
	if (!published) return false

	return !equal(getComparableAiBot(draft), getComparableAiBot(published))
})

const getIsPublishButtonDisabled = createSelector(
	[
		aiChatbotsSelectors.getCanPublishAiBot,
		getHasSelectedPendingSources,
		getHasSelectedFailedSources,
		getHasUnpublishedChanges,
		getChatbotVersions,
	],
	(canActivateAiBot, hasPendingSources, hasFailedSources, hasUnpublishedChanges, { draft }): boolean => {
		const isActive = draft?.isActive ?? false
		const hasSourcesProblem = hasPendingSources || hasFailedSources

		if (isActive) {
			return hasUnpublishedChanges && hasSourcesProblem
		}
		return hasSourcesProblem || !canActivateAiBot
	},
)

const { reducer, actions } = chatbotWorkflowSlice

export const {
	setSelectedSection,
	setSelectedSkillsSection,
	selectNextSection,
	selectPreviousSection,
	setWorkflowFormDirty,
	setSelectedSources,
	setWorkflowChatbotPublishedVersion,
	setIsPublishButtonLoading,
} = actions

export const chatbotWorkflowSelectors = {
	getChatbotWorkflow,
	getSelectedSection,
	getSelectedSkillsSection,
	getIsSelectedLastSection,
	getIsSelectedFirstSection,
	getIsWorkflowFormDirty,
	getIsWorkflowFormPending,
	getHasSelectedSources,
	getHasSelectedPendingSources,
	getHasSelectedFailedSources,
	getHasUnpublishedChanges,
	getIsFetchingPublishedAiBot,
	getIsPublishButtonLoading,
	getSelectedSourcesState,
	getIsPublishButtonDisabled,
}

export default reducer
