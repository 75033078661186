import { UseToastOptions } from '@chakra-ui/react'
import { mdiAlert, mdiAlertCircle, mdiCheckboxMarkedCircle, mdiInformation } from '@mdi/js'

import { TranslationService as T } from 'shared/services'
import { isString } from 'shared/utils'

import { CloseButton, Flex, Icon, Stack, Text } from 'core'

type PropsToastComponent = {
	testId?: string
	isRawData?: boolean
	onClose: () => void
} & UseToastOptions

export const FlashMessageComponent = ({
	title,
	status,
	testId,
	isRawData,
	onClose,
	isClosable,
}: PropsToastComponent) => {
	const getIcon = () => {
		switch (status) {
			case 'error': {
				return mdiAlertCircle
			}
			case 'warning': {
				return mdiAlert
			}
			case 'success': {
				return mdiCheckboxMarkedCircle
			}
			case 'info': {
				return mdiInformation
			}
			default: {
				return mdiInformation
			}
		}
	}

	return (
		<Stack
			px={2}
			py={1.5}
			spacing={1}
			boxShadow="shadow3"
			bg="white"
			data-testid={testId}
			alignItems="center"
			direction="row"
		>
			<Icon path={getIcon()} textColor={`${status}.500`} fontSize={3} />
			<Flex flex={1}>
				<Text fontSize={1}>{isString(title) && !isRawData ? T.translate(title) : title}</Text>
			</Flex>
			{isClosable && <CloseButton size="sm" onClick={onClose} />}
		</Stack>
	)
}
