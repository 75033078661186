import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'

import { DashboardState } from 'types'
import { Invoice, InvoiceFormat, InvoicePayment, InvoiceStatus, UpdateInvoiceParams } from 'shared/models/Invoice'

import { invoicesApi } from './api'
import { MAX_DAYS_TO_SYNC_INVOICE } from './constants'

export type InvoicesRootState = Pick<DashboardState, 'invoices'>

const invoicesAdapter = createEntityAdapter<Invoice>()

export const initialState = invoicesAdapter.getInitialState({
	isFetchPending: false,
	invoicePayment: null as null | InvoicePayment,
	isInvoicePaymentPending: false,
})

export const fetchInvoices = createAsyncThunk('invoices/FETCH', () => {
	return invoicesApi.fetchInvoices()
})

export const fetchInvoicePayment = createAsyncThunk('invoices/FETCH_PAYMENT', (id: number) => {
	return invoicesApi.fetchInvoicePayment(id)
})

export const cancelInvoice = createAsyncThunk('invoices/CANCEL', (id: number) => {
	return invoicesApi.cancelInvoice(id)
})

export const syncInvoice = createAsyncThunk('invoices/SYNC', (id: number) => {
	return invoicesApi.syncInvoice(id)
})

export const updateInvoice = createAsyncThunk('invoices/UPDATE', (data: UpdateInvoiceParams) => {
	return invoicesApi.updateInvoice(data)
})

const slice = createSlice({
	name: 'invoices',
	initialState,
	reducers: {
		setInvoiceAsCanceled: (state, { payload }: PayloadAction<{ id: number }>) => {
			invoicesAdapter.updateOne(state, { id: payload.id, changes: { status: InvoiceStatus.Canceled } })
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchInvoices.pending, (state) => {
				state.isFetchPending = true
			})
			.addCase(fetchInvoices.fulfilled, (state, { payload }) => {
				state.isFetchPending = false
				invoicesAdapter.setAll(state, payload)
			})
			.addCase(fetchInvoices.rejected, (state) => {
				state.isFetchPending = false
			})

		builder
			.addCase(fetchInvoicePayment.pending, (state) => {
				state.isInvoicePaymentPending = true
			})
			.addCase(fetchInvoicePayment.fulfilled, (state, { payload }) => {
				state.isInvoicePaymentPending = false
				state.invoicePayment = payload
			})
			.addCase(fetchInvoicePayment.rejected, (state) => {
				state.isInvoicePaymentPending = false
			})

		builder.addCase(updateInvoice.fulfilled, (state, { payload }) => {
			invoicesAdapter.updateOne(state, { id: payload.id, changes: payload })
		})
	},
})

const { reducer, actions } = slice
export const { setInvoiceAsCanceled } = actions
export default reducer

const entitySelectors = invoicesAdapter.getSelectors<InvoicesRootState>((state) => state.invoices)
const getInvoices = (state: InvoicesRootState) => entitySelectors.selectAll(state)
const getIsFetching = (state: InvoicesRootState) => state.invoices.isFetchPending
const getIsInvoicePaymentFetching = (state: InvoicesRootState) => state.invoices.isInvoicePaymentPending
const getInvoicePayment = (state: InvoicesRootState) => state.invoices.invoicePayment

const getFilteredInvoices = createSelector([getInvoices], (invoices) => {
	return invoices.filter((invoice) => invoice.status !== InvoiceStatus.Canceled)
})

const getLastPendingInvoice = createSelector([getInvoices], (invoices) => {
	return invoices.filter((invoice) => invoice.status === InvoiceStatus.Pending).pop()
})

const getHasInvoices = createSelector([getFilteredInvoices], (invoices) => {
	return invoices.length > 0
})

const getInvoiceToSync = createSelector([getInvoices, getHasInvoices], (invoices, hasInvoices) => {
	if (hasInvoices) {
		return invoices.find((invoice) => {
			const currentDate = moment()
			const invoiceCreated = moment(invoice.createdAt)
			const diff = currentDate.diff(invoiceCreated, 'days')
			return invoice.format === InvoiceFormat.Version_2 && diff <= MAX_DAYS_TO_SYNC_INVOICE
		})
	}

	return null
})

export const invoicesSelectors = {
	getIsFetching,
	getFilteredInvoices,
	getInvoiceToSync,
	getHasInvoices,
	getIsInvoicePaymentFetching,
	getInvoicePayment,
	getLastPendingInvoice,
}
