import { ConfigurationService } from 'shared/services'

export const setFavicon = (status: string, isAgentDisabled = false) => {
	const configData = ConfigurationService.getData()
	if (!configData) return

	const newStatus = isAgentDisabled ? 'away' : status
	const url = `${configData.distUrl}/assets/icons/favicon-${newStatus}.ico`
	const faviconEl = document.querySelector('#favicon') as HTMLLinkElement
	if (!faviconEl) {
		// eslint-disable-next-line no-console
		console.warn('Favicon element not found.')
		return
	}

	faviconEl.href = url
}
