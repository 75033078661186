import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiError } from 'shared/types'
import { DashboardState } from 'types'
import { ChatbotActionType } from 'shared/models/Chatbot'
import { Tag, TagCreateBody } from 'shared/models/Tag'
import { normalize } from 'utils'

import { tagsApi } from './api'
import { TagSelectOptionType, TagUpdateParams } from './types'

export type TagsRootState = Pick<DashboardState, 'tags'>
export type TagsState = typeof initialState

const tagsAdapter = createEntityAdapter<Tag>()

export const initialState = tagsAdapter.getInitialState({
	isFetching: false,
	isCreating: false,
	isUpdating: false,
	isCreateTagContactModalOpen: false,
	isCreateTagConversationModalOpen: false,
	isCreateTagChatbotModalOpen: { isOpen: false, source: ChatbotActionType.ChatAddTags as ChatbotActionType },
	createdTag: null as null | string,
	filterValue: '',
})

export const fetchTags = createAsyncThunk('TAGS/FETCH', () => {
	return tagsApi.fetchTags()
})

export const createTag = createAsyncThunk<Tag, TagCreateBody, { rejectValue: ApiError }>(
	'TAGS/CREATE',
	async (data, { rejectWithValue }) => {
		try {
			return await tagsApi.createTag(data)
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

export const updateTag = createAsyncThunk<Tag, TagUpdateParams, { rejectValue: ApiError }>(
	'TAGS/PATCH',
	async ({ id, changes }, { rejectWithValue }) => {
		try {
			return await tagsApi.updateTag(id, changes)
		} catch (error) {
			return rejectWithValue(error as ApiError)
		}
	},
)

export const deleteTag = createAsyncThunk('TAGS/DELETE', (tagId: number) => {
	return tagsApi.deleteTag(tagId)
})

const tagsSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {
		createTagContactModalOpen(state) {
			state.isCreateTagContactModalOpen = true
		},
		createTagConversationModalOpen(state) {
			state.isCreateTagConversationModalOpen = true
		},
		createTagChatbotModalOpen(state, { payload }: PayloadAction<{ source: ChatbotActionType }>) {
			state.isCreateTagChatbotModalOpen = { isOpen: true, source: payload.source }
		},
		createTagModalClose(state) {
			state.isCreateTagContactModalOpen = false
			state.isCreateTagConversationModalOpen = false
			state.isCreateTagChatbotModalOpen.isOpen = false
		},
		setFilterValue(state, { payload }: PayloadAction<string>) {
			state.filterValue = payload
		},
		resetFilterValue(state) {
			state.filterValue = ''
		},
		setCreatedTag(state, { payload }: PayloadAction<string | null>) {
			state.createdTag = payload
		},
	},
	extraReducers: (builder) => {
		// Fetch tags
		builder
			.addCase(fetchTags.fulfilled, (state, { payload }) => {
				tagsAdapter.setAll(state, payload)
				state.isFetching = false
			})
			.addCase(fetchTags.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchTags.rejected, (state) => {
				state.isFetching = false
			})

		// Create tag
		builder
			.addCase(createTag.fulfilled, (state, { payload }) => {
				tagsAdapter.addOne(state, payload)
				state.isCreating = false
			})
			.addCase(createTag.pending, (state) => {
				state.isCreating = true
			})
			.addCase(createTag.rejected, (state) => {
				state.isCreating = false
			})

		// Update tag
		builder
			.addCase(updateTag.fulfilled, (state, { payload }) => {
				tagsAdapter.updateOne(state, { id: payload.id, changes: payload })
				state.isUpdating = false
			})
			.addCase(updateTag.pending, (state) => {
				state.isUpdating = true
			})
			.addCase(updateTag.rejected, (state) => {
				state.isUpdating = false
			})

		// Delete tag
		builder.addCase(deleteTag.fulfilled, (state, { meta }) => {
			tagsAdapter.removeOne(state, meta.arg)
		})
	},
})

const { actions, reducer } = tagsSlice
export const {
	createTagContactModalOpen,
	createTagConversationModalOpen,
	createTagChatbotModalOpen,
	createTagModalClose,
	setFilterValue,
	resetFilterValue,
	setCreatedTag,
} = actions
export default reducer

const entitySelectors = tagsAdapter.getSelectors<TagsRootState>((state) => state.tags)
const getTags = (state: TagsRootState): Tag[] => entitySelectors.selectAll(state)
const getTagId = (state: TagsRootState, tagId: number) => tagId
const getIsFetching = (state: TagsRootState) => state.tags.isFetching
const getIsUpdating = (state: TagsRootState) => state.tags.isUpdating
const getIsCreating = (state: TagsRootState) => state.tags.isCreating
const getIsCreateTagContactModalOpen = (state: TagsRootState) => state.tags.isCreateTagContactModalOpen
const getIsCreateTagConversationModalOpen = (state: TagsRootState) => state.tags.isCreateTagConversationModalOpen
const getIsCreateTagChatbotModalOpen = (state: TagsRootState) => state.tags.isCreateTagChatbotModalOpen
const getFilterValue = (state: TagsRootState) => state.tags.filterValue
const getCreatedTag = (state: TagsRootState) => state.tags.createdTag

const makeGetTagById = () => {
	return createSelector([getTags, getTagId], (tags, tagId): Tag => {
		const normalizedTags = normalize('id', tags)
		return normalizedTags[tagId]
	})
}

const getSortedTags = createSelector([getTags], (tags): Tag[] => {
	return tags.sort((a: Tag, b: Tag) => a.name.localeCompare(b.name))
})

const getTagsOptions = createSelector([getSortedTags], (tags): TagSelectOptionType[] => {
	return tags.map((tag) => ({ value: tag.key, label: tag.name, color: tag.color }))
})

const getFilteredTagsOptions = createSelector(
	[getTagsOptions, getFilterValue],
	(tags, filter): TagSelectOptionType[] => {
		return tags.filter((tag) => tag.label.toLowerCase().includes(filter))
	},
)

const hasTags = createSelector([getTags], (tags): boolean => {
	return tags.length > 0
})

const getIsUpdatingOrCreatingTag = createSelector(
	[getIsUpdating, getIsCreating],
	(isUpdatingTag, isCreatingTag): boolean => {
		return isUpdatingTag || isCreatingTag
	},
)

const getContactsTagsLabels = (filteredTags: string[]) => {
	return createSelector([getTagsOptions], (tags): string[] => {
		const labels = filteredTags.map((filteredTag) => {
			const foundTag = tags.find((tag) => tag.value === filteredTag)
			return foundTag ? foundTag.label : undefined
		})
		return labels.filter((label) => label !== undefined)
	})
}

export const tagsSelectors = {
	getSortedTags,
	getIsFetching,
	getIsUpdating,
	getIsCreating,
	getIsCreateTagContactModalOpen,
	getIsCreateTagConversationModalOpen,
	getIsCreateTagChatbotModalOpen,
	getFilteredTagsOptions,
	getIsUpdatingOrCreatingTag,
	getTagsOptions,
	hasTags,
	makeGetTagById,
	getFilterValue,
	getCreatedTag,
	getTags,
	getContactsTagsLabels,
}
