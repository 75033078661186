export const notificationsAttributes = {
	desktop: {
		browserNotificationAlert: 'notifications-desktop-alert',
		browserNotificationButton: 'notifications-desktop',
		linkHelpNotifications: 'link-help-notifications',
	},
	form: {
		soundNotifications: 'sound-notifications',
		offlineEmailNotification: 'offline-email-notification',
		missedEmailNotification: 'missed-email-notifications',
		contactAcquiredNotification: 'contact-acquired-notification',
		linkStoreGoogle: 'link-store-google',
		linkStoreApple: 'link-store-apple',
		buttonSave: 'notifications-save-button',
	},
}
