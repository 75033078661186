export enum WidgetSettingsTranslationsType {
	OnlineTitle = 'topBar.online.hello',
	OnlineStatus = 'statusBar.onlineText',
	Textarea = 'chatInput.placeholder',
	OfflineTitle = 'topBar.offline.hello',
	OfflineStatus = 'statusBar.offlineText',
	ChatButton = 'button.greeting',
	Rating = 'rating.wasItHelpful',
	AuthFormGreeting = 'authForm.greeting',
	AuthFormDescription = 'authForm.description',
	AuthFormName = 'authForm.yourName',
	AuthFormEmail = 'authForm.yourEmail',
	AuthFormPhone = 'authForm.yourPhone',
	AuthFormGroup = 'authForm.group',
	Gdpr = 'topBar.gdprTitle',
}

export const ONLINE_TITLE = 'onlineTitle'
export const OFFLINE_TITLE = 'offlineTitle'
export const ONLINE_STATUS = 'onlineStatus'
export const OFFLINE_STATUS = 'offlineStatus'
export const WIDGET_TEXTAREA = 'textarea'
export const CHAT_BUTTON = 'chatButton'
export const AUTH_FORM_TITLE = 'authFormTitle'
export const AUTH_FORM_DESC = 'authFormDesc'

export const WIDGET_TITLE_MAX_LENGTH = 35
export const WIDGET_STATUS_MAX_LENGTH = 45
export const WIDGET_TEXTAREA_MAX_LENGTH = 45
export const WIDGET_BUTTON_MAX_LENGTH = 15
export const WIDGET_AUTH_FORM_MAX_LENGTH = 40
export const WIDGET_AUTH_FORM_DESC_MAX_LENGTH = 150
