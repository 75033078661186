import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DashboardState } from 'types'
import { AffilClient, AffiliateApi, AffilOverview, AffilRevenue } from 'shared/models/Affiliate'

import { affiliateApi } from './api'

export type AffiliateRootState = Pick<DashboardState, 'affiliate'>

const initialState = {
	affiliateActive: null as null | boolean,
	clients: {
		items: [] as AffilClient[],
		total: 0,
	},
	isClientsRequestDone: false,
	isRewardsRequestDone: false,
	overview: {} as AffilOverview,
	isPending: {
		isPendingClients: false,
		isPendingOverview: false,
		isPendingRewards: false,
		isPendingStatus: false,
		isPendingActivate: false,
		isPendingPayout: false,
	},
	errors: {
		isErrorOverview: false,
		isErrorClients: false,
		isErrorRewards: false,
		isErrorStatus: false,
	},
	rewards: {
		items: [] as AffilRevenue[],
		total: 0,
	},
	page: 1,
}

export const activateAffiliate = createAsyncThunk('affiliate/ACTIVATE', () => {
	return affiliateApi.activateAffiliate()
})

export const fetchAffiliateOverview = createAsyncThunk('affiliate/FETCH', () => {
	return affiliateApi.fetchAffiliateOverview()
})

export const fetchAffiliateClients = createAsyncThunk(
	'affiliate/FETCH_CLIENTS',
	({ limit, offset }: AffiliateApi.GetClientsQuery) => {
		return affiliateApi.fetchAffiliateClients({ limit, offset })
	},
)

export const fetchAffiliateStatus = createAsyncThunk('affiliate/FETCH_STATUS', () => {
	return affiliateApi.fetchAffiliateStatus()
})

export const fetchAffiliateRewards = createAsyncThunk(
	'affiliate/FETCH_REVENUES',
	({ limit, offset }: AffiliateApi.GetRevenuesQuery) => {
		return affiliateApi.fetchAffiliateRewards({ limit, offset })
	},
)

export const payoutAffiliate = createAsyncThunk('affiliate/PAYOUT_REWARDS', () => {
	return affiliateApi.payoutAffiliate()
})

const slice = createSlice({
	name: 'affiliate',
	initialState,
	reducers: {
		setPage: (state, { payload }: PayloadAction<number>) => {
			state.page = payload
		},
	},
	extraReducers: (builder) => {
		// ACTIVATE
		builder.addCase(activateAffiliate.fulfilled, (state) => {
			state.affiliateActive = true
			state.isPending.isPendingActivate = false
		})
		builder.addCase(activateAffiliate.rejected, (state) => {
			state.isPending.isPendingActivate = false
		})
		builder.addCase(activateAffiliate.pending, (state) => {
			state.isPending.isPendingActivate = true
		})

		// CLIENTS
		builder.addCase(fetchAffiliateClients.fulfilled, (state, { payload }) => {
			state.clients = payload
			state.isPending.isPendingClients = false
			state.errors.isErrorClients = false
			state.isClientsRequestDone = true
		})
		builder.addCase(fetchAffiliateClients.rejected, (state) => {
			state.errors.isErrorClients = true
			state.isPending.isPendingClients = false
			state.isClientsRequestDone = true
		})
		builder.addCase(fetchAffiliateClients.pending, (state) => {
			state.isPending.isPendingClients = true
			state.errors.isErrorClients = false
		})

		// OVERVIEW
		builder.addCase(fetchAffiliateOverview.fulfilled, (state, { payload }) => {
			state.overview = payload
			state.isPending.isPendingOverview = false
			state.errors.isErrorOverview = false
		})
		builder.addCase(fetchAffiliateOverview.rejected, (state) => {
			state.errors.isErrorOverview = true
			state.isPending.isPendingOverview = false
		})
		builder.addCase(fetchAffiliateOverview.pending, (state) => {
			state.errors.isErrorOverview = false
			state.isPending.isPendingOverview = true
		})

		// STATUS
		builder.addCase(fetchAffiliateStatus.fulfilled, (state, { payload }) => {
			state.affiliateActive = payload.active
			state.isPending.isPendingStatus = false
			state.errors.isErrorStatus = false
		})
		builder.addCase(fetchAffiliateStatus.rejected, (state) => {
			state.errors.isErrorStatus = true
			state.isPending.isPendingStatus = false
		})
		builder.addCase(fetchAffiliateStatus.pending, (state) => {
			state.isPending.isPendingStatus = true
			state.errors.isErrorStatus = false
		})

		// REVENUES
		builder.addCase(fetchAffiliateRewards.fulfilled, (state, { payload }) => {
			state.rewards = payload
			state.isPending.isPendingRewards = false
			state.errors.isErrorRewards = false
			state.isRewardsRequestDone = true
		})
		builder.addCase(fetchAffiliateRewards.rejected, (state) => {
			state.errors.isErrorRewards = true
			state.isPending.isPendingRewards = false
			state.isRewardsRequestDone = true
		})
		builder.addCase(fetchAffiliateRewards.pending, (state) => {
			state.isPending.isPendingRewards = true
			state.errors.isErrorRewards = false
		})

		// PAYOUT
		builder.addCase(payoutAffiliate.fulfilled, (state) => {
			state.isPending.isPendingPayout = false
		})
		builder.addCase(payoutAffiliate.rejected, (state) => {
			state.isPending.isPendingPayout = false
		})
		builder.addCase(payoutAffiliate.pending, (state) => {
			state.isPending.isPendingPayout = true
		})
	},
})

const { reducer, actions } = slice
export const { setPage } = actions
export default reducer

const getClients = (state: AffiliateRootState) => state.affiliate.clients.items
const getClientsTotal = (state: AffiliateRootState) => state.affiliate.clients.total
const getOverview = (state: AffiliateRootState) => state.affiliate.overview
const getIsActive = (state: AffiliateRootState) => state.affiliate.affiliateActive
const getIsPendingClients = (state: AffiliateRootState) => state.affiliate.isPending.isPendingClients
const getIsPendingStatus = (state: AffiliateRootState) => state.affiliate.isPending.isPendingStatus
const getIsPendingActivate = (state: AffiliateRootState) => state.affiliate.isPending.isPendingActivate
const getIsPendingOverview = (state: AffiliateRootState) => state.affiliate.isPending.isPendingOverview
const getIsPendingRewards = (state: AffiliateRootState) => state.affiliate.isPending.isPendingRewards
const getIsPendingPayout = (state: AffiliateRootState) => state.affiliate.isPending.isPendingPayout
const getIsErrorStatus = (state: AffiliateRootState) => state.affiliate.errors.isErrorStatus
const getIsErrorClients = (state: AffiliateRootState) => state.affiliate.errors.isErrorClients
const getIsErrorOverview = (state: AffiliateRootState) => state.affiliate.errors.isErrorOverview
const getIsErrorRewards = (state: AffiliateRootState) => state.affiliate.errors.isErrorRewards
const getRewards = (state: AffiliateRootState) => state.affiliate.rewards.items
const getRewardsTotal = (state: AffiliateRootState) => state.affiliate.rewards.total
const getPage = (state: AffiliateRootState) => state.affiliate.page
const getIsClientsRequestDone = (state: AffiliateRootState) => state.affiliate.isClientsRequestDone
const getIsRewardsRequestDone = (state: AffiliateRootState) => state.affiliate.isRewardsRequestDone

export const affiliateSelectors = {
	getClients,
	getClientsTotal,
	getOverview,
	getIsPendingClients,
	getIsActive,
	getIsPendingStatus,
	getIsPendingActivate,
	getIsErrorStatus,
	getIsErrorClients,
	getIsErrorOverview,
	getIsErrorRewards,
	getIsPendingOverview,
	getIsPendingRewards,
	getIsPendingPayout,
	getRewards,
	getRewardsTotal,
	getPage,
	getIsClientsRequestDone,
	getIsRewardsRequestDone,
}
