import { ReactNode } from 'react'

import { Flex, FlexProps } from 'shared/components/core/Flex'
import { Icon } from 'shared/components/core/Icon'

type Props = {
	children: ReactNode
	icon: string
} & FlexProps

export const SubmenuLinkContent = ({ children, icon, ...rest }: Props) => {
	return (
		<Flex alignItems="center" justifyContent="space-between" pr={1}>
			<Flex py={1} pl={1} alignItems="center" {...rest}>
				<Icon fontSize={2} mr={1} path={icon} />
				{children}
			</Flex>
		</Flex>
	)
}
