import store from 'store'

import { isString } from 'shared/utils'

import { UserPreference } from '../constants'

import { ConfigurationService } from './ConfigurationService'

const getItemKey = (key: UserPreference, useHash: boolean) => {
	const identityData = ConfigurationService.getIdentityData()
	return useHash && identityData?.id ? `${key}-${identityData.id}` : key
}

export const loadUserPreference = <T extends unknown>(key: UserPreference, useHash = false) => {
	const item: T = store.get(getItemKey(key, useHash))
	if (typeof item === 'object') return item

	if (typeof item !== 'boolean' && !isString(item)) {
		return null
	}
	return item
}

export const storeUserPreference = (key: UserPreference, value: unknown, useHash = false) => {
	store.set(getItemKey(key, useHash), value)
}
