export const Drawer = {
	parts: ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],
	sizes: {
		sm: {
			header: {
				fontSize: 1,
				px: 1,
				py: 1,
			},
		},
		md: {
			header: {
				fontSize: 2,
				px: 2,
				pt: 2,
				pb: 2,
			},
			body: {
				px: 2,
				py: 2,
			},
			footer: {
				px: 0,
				py: 0,
			},
		},
	},
	defaultProps: {
		size: 'md',
	},
}
