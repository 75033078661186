import { FC, forwardRef } from 'react'
import { Icon as ChakraIcon, IconProps as ChakraIconProps, keyframes } from '@chakra-ui/react'

export type IconProps = {
	path: string
	rotate?: number
	spin?: boolean
} & ChakraIconProps

export const Icon: FC<IconProps> = forwardRef<SVGSVGElement, IconProps>(
	({ path, rotate = 0, spin = false, ...rest }, ref) => {
		const pathElement = <path d={path} />

		return (
			<ChakraIcon
				ref={ref}
				role="presentation"
				display="inline-flex"
				fill="currentColor"
				sx={{
					path: { transition: 'fill 200ms ease' },
				}}
				{...(rotate !== 0 && getRotateProps(rotate))}
				{...(spin && getSpinProps())}
				{...rest}
			>
				{pathElement}
			</ChakraIcon>
		)
	},
)

const getRotateProps = (rotate: number) => {
	return {
		transition: 'transform 200ms ease',
		transform: `rotate(${rotate}deg)`,
		transformOrigin: 'center',
	}
}

const spinKeyframes = keyframes({ to: { transform: `rotate(360deg)` } })

const getSpinProps = () => {
	return {
		animation: `${spinKeyframes} linear 1s infinite`,
	}
}

Icon.displayName = 'Icon'
