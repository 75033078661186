import { SurveyKey, UsedSurveyKey } from 'models'

import { SurveyModalData, SurveyOption } from './types'
import { getRandomlySortedOptionArray } from './utils'

export const MAX_TEXTAREA_LENGTH = 500

const deleteAccountV2Survey: SurveyOption[] = getRandomlySortedOptionArray([
	{
		label: 'survey.autoRenewalV2.option.first',
		value: 'A',
		introduction: 'survey.general.introduction',
		subOptionsName: `${SurveyKey.DeleteAccountV2}-1`,
		subOptions: getRandomlySortedOptionArray([
			{ label: 'survey.autoRenewalV2.option.first.sublist.first', value: 'A1' },
			{ label: 'survey.autoRenewalV2.option.first.sublist.second', value: 'A2' },
			{ label: 'survey.autoRenewalV2.option.first.sublist.third', value: 'A3' },
			{ label: 'survey.autoRenewalV2.option.first.sublist.fourth', value: 'A4' },
			{
				label: 'survey.autoRenewalV2.option.first.sublist.fifth',
				value: 'A5',
				rating: true,
				disableTextarea: true,
			},
			{ label: 'survey.general.other', value: 'A6', mandatoryText: true },
		]),
	},
	{
		label: 'survey.autoRenewalV2.option.second',
		value: 'B',
		introduction: 'survey.general.introduction',
		subOptionsName: `${SurveyKey.DeleteAccountV2}-2`,
		subOptions: getRandomlySortedOptionArray([
			{ label: 'survey.autoRenewalV2.option.second.sublist.first', value: 'B1' },
			{ label: 'survey.autoRenewalV2.option.second.sublist.second', value: 'B2' },
			{ label: 'survey.autoRenewalV2.option.second.sublist.third', value: 'B3' },
			{ label: 'survey.autoRenewalV2.option.second.sublist.fourth', value: 'B4' },
			{ label: 'survey.general.other', value: 'B5', mandatoryText: true },
		]),
	},
	{
		label: 'survey.deleteAccountV2.option.third',
		value: 'C',
		introduction: 'survey.general.introduction',
		subOptionsName: `${SurveyKey.DeleteAccountV2}-3`,
		subOptions: getRandomlySortedOptionArray([
			{ label: 'survey.autoRenewalV2.option.third.sublist.first', value: 'C1' },
			{ label: 'survey.autoRenewalV2.option.third.sublist.second', value: 'C2' },
			{ label: 'survey.autoRenewalV2.option.third.sublist.third', value: 'C3' },
			{ label: 'survey.autoRenewalV2.option.third.sublist.fourth', value: 'C4' },
			{ label: 'survey.autoRenewalV2.option.third.sublist.fifth', value: 'C5' },
			{ label: 'survey.general.other', value: 'C6', mandatoryText: true },
		]),
	},
	{
		label: 'survey.autoRenewalV2.option.fourth',
		value: 'D',
		introduction: 'survey.autoRenewalV2.option.fourth.introduction',
		textarea: true,
	},
	{
		label: 'survey.autoRenewalV2.option.fifth',
		value: 'E',
		introduction: 'survey.autoRenewalV2.option.fifth.introduction',
		subOptionsName: `${SurveyKey.DeleteAccountV2}-5`,
		subOptions: getRandomlySortedOptionArray([
			{ label: 'survey.autoRenewalV2.option.fifth.sublist.first', value: 'E1' },
			{ label: 'survey.autoRenewalV2.option.fifth.sublist.second', value: 'E2' },
			{ label: 'survey.autoRenewalV2.option.fifth.sublist.third', value: 'E3' },
			{ label: 'survey.autoRenewalV2.option.fifth.sublist.fourth', value: 'E4' },
			{ label: 'survey.autoRenewalV2.option.fifth.sublist.fifth', value: 'E5' },
			{ label: 'survey.general.other', value: 'E6', mandatoryText: true },
		]),
	},
	{
		label: 'survey.general.other',
		value: 'F',
		textarea: true,
	},
])

const autoRenewalV2Survey: SurveyOption[] = getRandomlySortedOptionArray([
	{
		label: 'survey.autoRenewalV2.option.first',
		value: 'A',
		introduction: 'survey.general.introduction',
		subOptionsName: `${SurveyKey.AutoRenewalV2}-1`,
		subOptions: getRandomlySortedOptionArray([
			{ label: 'survey.autoRenewalV2.option.first.sublist.first', value: 'A1' },
			{ label: 'survey.autoRenewalV2.option.first.sublist.second', value: 'A2' },
			{ label: 'survey.autoRenewalV2.option.first.sublist.third', value: 'A3' },
			{ label: 'survey.autoRenewalV2.option.first.sublist.fourth', value: 'A4' },
			{
				label: 'survey.autoRenewalV2.option.first.sublist.fifth',
				value: 'A5',
				rating: true,
				disableTextarea: true,
			},
			{ label: 'survey.general.other', value: 'A6', mandatoryText: true },
		]),
	},
	{
		label: 'survey.autoRenewalV2.option.second',
		value: 'B',
		introduction: 'survey.general.introduction',
		subOptionsName: `${SurveyKey.AutoRenewalV2}-2`,
		subOptions: getRandomlySortedOptionArray([
			{ label: 'survey.autoRenewalV2.option.second.sublist.first', value: 'B1' },
			{ label: 'survey.autoRenewalV2.option.second.sublist.second', value: 'B2' },
			{ label: 'survey.autoRenewalV2.option.second.sublist.third', value: 'B3' },
			{ label: 'survey.autoRenewalV2.option.second.sublist.fourth', value: 'B4' },
			{ label: 'survey.general.other', value: 'B5', mandatoryText: true },
		]),
	},
	{
		label: 'survey.autoRenewalV2.option.third',
		value: 'C',
		introduction: 'survey.general.introduction',
		subOptionsName: `${SurveyKey.AutoRenewalV2}-3`,
		subOptions: getRandomlySortedOptionArray([
			{ label: 'survey.autoRenewalV2.option.third.sublist.first', value: 'C1' },
			{ label: 'survey.autoRenewalV2.option.third.sublist.second', value: 'C2' },
			{ label: 'survey.autoRenewalV2.option.third.sublist.third', value: 'C3' },
			{ label: 'survey.autoRenewalV2.option.third.sublist.fourth', value: 'C4' },
			{ label: 'survey.autoRenewalV2.option.third.sublist.fifth', value: 'C5' },
			{ label: 'survey.general.other', value: 'C6', mandatoryText: true },
		]),
	},
	{
		label: 'survey.autoRenewalV2.option.fourth',
		value: 'D',
		introduction: 'survey.autoRenewalV2.option.fourth.introduction',
		textarea: true,
	},
	{
		label: 'survey.autoRenewalV2.option.fifth',
		value: 'E',
		introduction: 'survey.autoRenewalV2.option.fifth.introduction',
		subOptionsName: `${SurveyKey.AutoRenewalV2}-5`,
		subOptions: getRandomlySortedOptionArray([
			{ label: 'survey.autoRenewalV2.option.fifth.sublist.first', value: 'E1' },
			{ label: 'survey.autoRenewalV2.option.fifth.sublist.second', value: 'E2' },
			{ label: 'survey.autoRenewalV2.option.fifth.sublist.third', value: 'E3' },
			{ label: 'survey.autoRenewalV2.option.fifth.sublist.fourth', value: 'E4' },
			{ label: 'survey.autoRenewalV2.option.fifth.sublist.fifth', value: 'E5' },
			{ label: 'survey.general.other', value: 'E6', mandatoryText: true },
		]),
	},
	{
		label: 'survey.general.other',
		value: 'F',
		textarea: true,
	},
])

const aiAssistantUpgrade: SurveyOption[] = [
	{
		label: 'survey.aiAssistantUpgrade.option.yes',
		labelEmoji: '👍',
		value: 'yes',
	},
	{
		label: 'survey.aiAssistantUpgrade.option.unsure',
		labelEmoji: '🤔',
		value: 'unsure',
	},
	{
		label: 'survey.aiAssistantUpgrade.option.no',
		labelEmoji: '👎',
		value: 'no',
	},
]

const aiAssistantQuality: SurveyOption[] = [
	{
		label: 'survey.aiAssistantQuality.option.great',
		labelEmoji: '👍',
		value: 'great',
	},
	{
		label: 'survey.aiAssistantQuality.option.fine',
		labelEmoji: '🤔',
		value: 'fine',
	},
	{
		label: 'survey.aiAssistantQuality.option.poor',
		labelEmoji: '👎',
		value: 'poor',
	},
	{
		label: 'survey.general.other',
		labelEmoji: '✏️',
		value: 'other',
		textarea: true,
	},
]

export const surveyData: Record<UsedSurveyKey, SurveyModalData> = {
	[SurveyKey.AutoRenewalV2]: {
		title: 'survey.autoRenewal.title',
		onSubmitLabel: 'survey.autoRenewal.action.secondary',
		onCloseLabel: 'survey.autoRenewal.action.primary',
		modalTitle: 'survey.autoRenewal.modalTitle',
		introduction: 'survey.autoRenewal.introduction',
		options: autoRenewalV2Survey,
	},
	[SurveyKey.DeleteAccountV2]: {
		title: 'survey.deleteAccount.title',
		onSubmitLabel: 'survey.deleteAccount.action.secondary',
		onCloseLabel: 'survey.deleteAccount.action.primary',
		modalTitle: 'survey.deleteAccount.modalTitle',
		introduction: 'survey.deleteAccount.introduction',
		options: deleteAccountV2Survey,
	},
	[SurveyKey.AiAssistantUpgrade]: {
		title: 'survey.aiAssistantUpgrade.title',
		onSubmitLabel: '',
		onCloseLabel: '',
		modalTitle: 'survey.aiAssistantUpgrade.name',
		introduction: '',
		options: aiAssistantUpgrade,
	},
	[SurveyKey.AiAssistantQuality]: {
		title: 'survey.aiAssistantQuality.title',
		onSubmitLabel: '',
		onCloseLabel: '',
		modalTitle: 'survey.aiAssistantQuality.name',
		introduction: '',
		options: aiAssistantQuality,
	},
}
