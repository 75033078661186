import { ChatbotWizardSource } from 'modules/chatbotWizard/types'
import { StepTextsForReplies, StepTextsType } from 'modules/onboarding'

export const DEFAULT_STEP = 0

export const chatbotWizardAttributes = {
	messageStepIds: {
		textarea: 'chatbot-wizard-message-textarea',
		btnBack: 'chatbot-wizard-message-btn-back',
		btnContinue: 'chatbot-wizard-message-btn-continue',
		btnSkip: 'chatbot-wizard-message-btn-skip',
	},
	replyStepIds: {
		input: 'chatbot-wizard-question-input',
		textarea: 'chatbot-wizard-reply-textarea',
		btnBack: 'chatbot-wizard-question-btn-back',
		btnContinue: 'chatbot-wizard-question-btn-continue',
		btnNext: 'chatbot-wizard-question-btn-next',
		btnLater: 'chatbot-wizard-question-btn-later',
		btnSaveLater: 'chatbot-wizard-question-btn-savelater',
		alert: 'chatbot-wizard-alert',
	},
}

const replyStepTextsWizard: StepTextsForReplies = {
	1: {
		title: 'chatbot.wizard.question.title',
		text: 'chatbot.wizard.question.text',
		buttonText: 'onboard.chatbot.btn.next',
	},
	2: {
		title: 'chatbot.wizard.question.title',
		text: 'chatbot.wizard.question.text',
		buttonText: 'onboard.chatbot.btn.next',
	},
	3: {
		title: 'chatbot.wizard.question.title',
		text: 'chatbot.wizard.question3.text',
		buttonText: 'onboard.chatbot.btn.finish',
	},
}

const replyStepTextsOnboarding: StepTextsForReplies = {
	1: {
		title: 'onboard.chatbot.question.title',
		text: 'onboard.chatbot.question.text',
		buttonText: 'onboard.chatbot.btn.next',
	},
	2: {
		title: 'onboard.chatbot.question.title',
		text: 'onboard.chatbot.question2.text',
		buttonText: 'onboard.chatbot.btn.next',
	},
	3: {
		title: 'onboard.chatbot.question.title',
		text: 'onboard.chatbot.lastQuestion.text',
		buttonText: 'onboard.chatbot.btn.setup',
	},
}

export const replyStepTextsMap: Record<Exclude<ChatbotWizardSource, 'aiOnboarding'>, StepTextsForReplies> = {
	[ChatbotWizardSource.Url]: replyStepTextsWizard,
	[ChatbotWizardSource.Onboarding]: replyStepTextsOnboarding,
}

export const messageStepTextsMap: Record<ChatbotWizardSource, StepTextsType> = {
	[ChatbotWizardSource.Url]: {
		title: 'chatbot.wizard.message.title',
		text: 'chatbot.wizard.message.text',
		buttonText: 'general.buttons.continue',
	},
	[ChatbotWizardSource.Onboarding]: {
		title: 'onboard.chatbot.title',
		text: 'onboard.chatbot.text',
		buttonText: 'onboard.chatbot.btn.setup',
	},
	[ChatbotWizardSource.AiOnboarding]: {
		title: 'onboard.mira.message.title',
		text: 'onboard.mira.message.text',
		buttonText: 'ai.onboarding.preview.button.continue',
	},
}
