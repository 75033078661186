import { AppLang } from 'shared/types'

import { PanelName, Plugin, VideoIdPerLang } from './types'

// Plugins - panels content
export const plugins: Record<string, Plugin> = {
	[PanelName.Epages]: {
		name: PanelName.Epages,
		title: PanelName.Epages,
		link: PanelName.Epages,
		steps: ['step1', 'step2', 'step3', 'step4'],
		icon: 'epages-icon.svg',
	},
	[PanelName.PrestaShop]: {
		name: PanelName.PrestaShop,
		title: PanelName.PrestaShop,
		link: PanelName.PrestaShop,
		steps: ['step1', 'step2', 'step3', 'step4'],
		icon: 'prestashop-icon.svg',
	},
	[PanelName.Shoper]: {
		name: PanelName.Shoper,
		title: PanelName.Shoper,
		link: PanelName.Shoper,
		steps: ['step1', 'step2', 'step3', 'step4'],
		icon: 'shoper-icon.svg',
	},
	[PanelName.Shopify]: {
		name: PanelName.Shopify,
		title: PanelName.Shopify,
		link: PanelName.Shopify,
		steps: ['step1', 'step2', 'step3', 'step4'],
		icon: 'shopify-icon.svg',
	},
	[PanelName.Shoprenter]: {
		name: PanelName.Shoprenter,
		title: PanelName.Shoprenter,
		link: PanelName.Shoprenter,
		steps: ['step1', 'step2', 'step3', 'step4'],
		icon: 'shoprenter-icon.svg',
	},
	[PanelName.Shoptet]: {
		name: PanelName.Shoptet,
		title: PanelName.Shoptet,
		link: PanelName.Shoptet,
		steps: ['step1', 'step2', 'step3', 'step4'],
		icon: 'shoptet-icon.svg',
	},
	[PanelName.Shopware]: {
		name: PanelName.Shopware,
		title: PanelName.Shopware,
		link: PanelName.Shopware,
		steps: ['step1', 'step2', 'step3', 'step4'],
		icon: 'shopware-icon.svg',
	},
	[PanelName.WordPress]: {
		name: PanelName.WordPress,
		title: PanelName.WordPress,
		link: PanelName.WordPress,
		steps: ['step1', 'step2', 'step3', 'step4'],
		icon: 'WP-icon.svg',
	},
}

export const pluginsPerLang: Record<AppLang, Plugin[]> = {
	en: [plugins.wordpress, plugins.prestashop, plugins.shoptet],
	cs: [plugins.shoptet, plugins.wordpress, plugins.prestashop],
	de: [plugins.shopware, plugins.epages, plugins.wordpress],
	es: [plugins.prestashop, plugins.epages, plugins.wordpress],
	fr: [plugins.prestashop, plugins.epages, plugins.wordpress],
	hu: [plugins.wordpress, plugins.shoprenter, plugins.prestashop],
	it: [plugins.wordpress, plugins.epages, plugins.prestashop],
	nl: [plugins.wordpress, plugins.epages, plugins.prestashop],
	pl: [plugins.prestashop, plugins.shoper, plugins.wordpress],
}

export const installMethodVideos: Record<PanelName, VideoIdPerLang> = {
	[PanelName.Code]: {
		en: 'yFF3lRDSOpc',
		cs: 'LAUwf2eg4Jg',
		de: null,
		es: null,
		fr: null,
		hu: null,
		it: null,
		nl: null,
		pl: null,
	},
	[PanelName.Gtm]: {
		en: null,
		cs: null,
		de: null,
		es: null,
		fr: null,
		hu: null,
		it: null,
		nl: null,
		pl: null,
	},
	[PanelName.Epages]: {
		en: null,
		cs: null,
		de: null,
		es: null,
		fr: null,
		hu: null,
		it: null,
		nl: null,
		pl: null,
	},
	[PanelName.PrestaShop]: {
		en: 'iaP8cNYRVns',
		cs: 'tLCnunf8nzw',
		de: null,
		es: null,
		fr: null,
		hu: null,
		it: null,
		nl: null,
		pl: null,
	},
	[PanelName.Shoper]: {
		en: null,
		cs: null,
		de: null,
		es: null,
		fr: null,
		hu: null,
		it: null,
		nl: null,
		pl: null,
	},
	[PanelName.Shopify]: {
		en: null,
		cs: null,
		de: null,
		es: null,
		fr: null,
		hu: null,
		it: null,
		nl: null,
		pl: null,
	},
	[PanelName.Shoprenter]: {
		en: null,
		cs: null,
		de: null,
		es: null,
		fr: null,
		hu: null,
		it: null,
		nl: null,
		pl: null,
	},
	[PanelName.Shoptet]: {
		en: null,
		cs: 'OVNBPTIwb40',
		de: null,
		es: null,
		fr: null,
		hu: null,
		it: null,
		nl: null,
		pl: null,
	},
	[PanelName.Shopware]: {
		en: null,
		cs: null,
		de: null,
		es: null,
		fr: null,
		hu: null,
		it: null,
		nl: null,
		pl: null,
	},
	[PanelName.WordPress]: {
		en: 'REhL21Wa0lo',
		cs: '14S19ZxlzOY',
		de: null,
		es: null,
		fr: null,
		hu: null,
		it: null,
		nl: null,
		pl: null,
	},
	[PanelName.OtherPlugins]: {
		en: null,
		cs: null,
		de: null,
		es: null,
		fr: null,
		hu: null,
		it: null,
		nl: null,
		pl: null,
	},
	[PanelName.ChatKey]: {
		en: null,
		cs: null,
		de: null,
		es: null,
		fr: null,
		hu: null,
		it: null,
		nl: null,
		pl: null,
	},
}
