const baseStyle = {
	list: {
		py: 1,
	},
	item: {
		py: 1,
	},
}

export const Menu = {
	baseStyle,
}
