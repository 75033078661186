import { useSelector } from 'react-redux'

import { TranslationService as T } from 'shared/services'
import { packageSelectors } from 'modules/package'

import { Flex, Text } from 'core'
import { IntlDate, IntlRelativeTime } from 'components/intl'

const SidebarAgentMenuPackageInfoExpiration = () => {
	const packageInfo = useSelector(packageSelectors.getPackageInfo)
	if (!packageInfo) return null

	const { autoRenewal, billingPeriodEndDate, nextBillingDate, remainingDays, isRemainingDaysCountLow, isExpired } =
		packageInfo

	const isLastMonth = remainingDays <= 30
	const isAutoRenewalComing = remainingDays <= 7
	const shouldShowDangerColor = !autoRenewal && (isRemainingDaysCountLow || isExpired)

	// Don't show expiration info for unlimited packages
	if (!billingPeriodEndDate) return null

	let content
	if (isExpired) {
		content = <>{T.translate('notification.packageExpired.headline')}</>
	} else if (autoRenewal) {
		content = (
			<>
				{T.translate('billing.profile.package.renewsOn')}{' '}
				{isAutoRenewalComing ? (
					<IntlRelativeTime value={remainingDays} unit="day" />
				) : (
					<>
						{nextBillingDate && (
							<IntlDate value={new Date(nextBillingDate)} day="numeric" month="numeric" year="numeric" />
						)}
					</>
				)}
			</>
		)
	} else if (isLastMonth) {
		content = (
			<>
				{T.translate('billing.profile.package.ends')} <IntlRelativeTime value={remainingDays} unit="day" />
			</>
		)
	} else {
		content = (
			<>
				{T.translate('billing.profile.package.validUntil')}{' '}
				<IntlDate value={new Date(billingPeriodEndDate)} day="numeric" month="long" year="numeric" />
			</>
		)
	}

	return (
		<Flex fontSize={0}>
			<Text textColor={shouldShowDangerColor ? 'red.500' : 'gray.500'}>{content}</Text>
		</Flex>
	)
}

export default SidebarAgentMenuPackageInfoExpiration
