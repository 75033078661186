import { Invoice, InvoicePayment, UpdateInvoiceParams } from 'models/Invoice'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'invoices'

const fetchInvoices = () => {
	return apiClient.get<Invoice[]>(`${API_RESOURCE}`)
}

const fetchInvoicePayment = (id: number) => {
	return apiClient.get<InvoicePayment>(`${API_RESOURCE}/${id}/payment`)
}

const cancelInvoice = (id: number) => {
	return apiClient.patch<{ ok: boolean }>(`${API_RESOURCE}/${id}/cancel`)
}

const updateInvoice = (data: UpdateInvoiceParams) => {
	return apiClient.patch<Invoice>(`${API_RESOURCE}/${data.id}`, data.changes)
}

const syncInvoice = (id: number) => {
	return apiClient.patch<{ ok: boolean }>(`${API_RESOURCE}/${id}/sync`)
}

export const invoicesApi = {
	fetchInvoices,
	cancelInvoice,
	syncInvoice,
	updateInvoice,
	fetchInvoicePayment,
}
