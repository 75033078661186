import { CreateTrainingEntryBody, TrainingEntriesCount, TrainingEntry, UpdateTrainingEntryBody } from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'automation/ai-sources/sources/custom-responses'

const fetchTrainingEntries = () => {
	return apiClient.get<TrainingEntry[]>(API_RESOURCE)
}

const createTrainingEntry = (data: CreateTrainingEntryBody) => {
	return apiClient.post<TrainingEntry>(API_RESOURCE, data)
}

const editTrainingEntry = (id: number, data: UpdateTrainingEntryBody) => {
	return apiClient.patch<TrainingEntry>(`${API_RESOURCE}/${id}`, data)
}

const deleteTrainingEntry = (id: number) => {
	return apiClient.delete(`${API_RESOURCE}/${id}`)
}

const fetchTrainingEntriesCount = () => {
	return apiClient.get<TrainingEntriesCount>(`${API_RESOURCE}/count`)
}

export const trainingApi = {
	fetchTrainingEntries,
	createTrainingEntry,
	editTrainingEntry,
	deleteTrainingEntry,
	fetchTrainingEntriesCount,
}
