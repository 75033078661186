import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { UserRole } from 'shared/models/User'
import { userSelectors } from 'modules/user'

type OwnProps = {
	children: ReactNode
	onAuth: (userRole: UserRole) => boolean
	redirectPath?: string
}

type Props = OwnProps & RouteProps

const REDIRECT_PATH = '/'

const AuthorizedRoute = ({ children, onAuth, redirectPath, ...rest }: Props) => {
	const user = useSelector(userSelectors.getActiveUser)
	const isAuthorized = user && user.role ? onAuth(user.role) : false

	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAuthorized ? (
					children
				) : (
					<Redirect to={{ pathname: redirectPath ?? REDIRECT_PATH, state: { from: location } }} />
				)
			}
		/>
	)
}

export default AuthorizedRoute
