import { Dispatch, useCallback, useEffect, useState } from 'react'

import { UserPreference } from '../constants'

export const useLocalStorage = (key: UserPreference, initialValue = ''): [string, Dispatch<string>] => {
	const [value, setValue] = useState(() => window.localStorage.getItem(key) || initialValue)

	const setItem = (newValue: string) => {
		setValue(newValue)
		window.localStorage.setItem(key, newValue)
	}

	useEffect(() => {
		const newValue = window.localStorage.getItem(key)
		if (value !== newValue) {
			setValue(newValue || initialValue)
		}
	}, [initialValue, key, value, setValue])

	const handleStorage = useCallback(
		(event: StorageEvent) => {
			if (event.key === key && event.newValue !== value) {
				setValue(event.newValue || initialValue)
			}
		},
		[key, initialValue, value],
	)

	useEffect(() => {
		window.addEventListener('storage', handleStorage)
		return () => window.removeEventListener('storage', handleStorage)
	}, [handleStorage])

	return [value, setItem]
}
