import { useBreakpointValue } from '@chakra-ui/react'
import { mdiAccountMultiple } from '@mdi/js'

import { TranslationService as T } from 'shared/services'
import { routes } from 'configuration/routes'

import { Box } from 'shared/components/core/Box'

import SidebarMenuIcon from '../sidebarMenu/SidebarMenuIcon'
import SidebarMenuItem from '../sidebarMenu/SidebarMenuItem'
import SidebarMenuItemPopover from '../sidebarMenu/SidebarMenuItemPopover'

import SidebarCustomersMenu from './SidebarCustomersMenu'

const SidebarCustomersMenuItem = () => (
	<SidebarMenuItem path={routes.customers.path} exactPath={false} tooltip={T.translate('headerBar.customers')}>
		<SidebarMenuIcon icon={mdiAccountMultiple} />
	</SidebarMenuItem>
)

const SidebarCustomers = () => {
	const isMobile = useBreakpointValue({ base: true, lg: false })

	return (
		<Box data-testid="sidebar-customers" data-userpilot="sidebar-customers">
			{isMobile ? (
				<SidebarMenuItemPopover popoverContent={<SidebarCustomersMenu />}>
					<SidebarMenuIcon icon={mdiAccountMultiple} />
				</SidebarMenuItemPopover>
			) : (
				<SidebarCustomersMenuItem />
			)}
		</Box>
	)
}

export default SidebarCustomers
