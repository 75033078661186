import {
	StripeChurnkeyAuthResponse,
	StripeCustomerBalance,
	StripeInvoice,
	StripePaymentIntent,
	StripeSubscription,
	StripeSubscriptionCalculationRequest,
	StripeSubscriptionCalculationResponse,
	StripeSubscriptionSaveRequest,
} from 'models'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'billing'
const API_RESOURCE_CUSTOMER = 'billing/customer'

const createSubscription = (data: StripeSubscriptionSaveRequest) => {
	return apiClient.post<{ stripeClientSecret: string }>(`${API_RESOURCE}/subscription`, data)
}

const getSubscription = () => {
	return apiClient.get<StripeSubscription>(`${API_RESOURCE}/subscription`)
}

const getBalance = () => {
	return apiClient.get<StripeCustomerBalance>(`${API_RESOURCE_CUSTOMER}/balance`)
}

const setSubscriptionAutoRenewal = (enabled: boolean) => {
	return apiClient.post(`${API_RESOURCE}/subscription/auto-renewal`, { enabled })
}

const calculateSubscription = (data: StripeSubscriptionCalculationRequest) => {
	return apiClient.post<StripeSubscriptionCalculationResponse>(`${API_RESOURCE}/subscription/calculation`, data)
}

const getInvoice = (invoiceId: string) => {
	return apiClient.get<StripeInvoice>(`${API_RESOURCE}/invoices/${invoiceId}`)
}

const getPaymentIntent = (paymentIntentId: string) => {
	return apiClient.get<StripePaymentIntent>(`${API_RESOURCE}/payment-intents/${paymentIntentId}`)
}

const getChurnkeyAuth = () => {
	return apiClient.get<StripeChurnkeyAuthResponse>(`${API_RESOURCE}/churnkey/auth`)
}

export const stripeApi = {
	calculateSubscription,
	createSubscription,
	setSubscriptionAutoRenewal,
	getSubscription,
	getBalance,
	getInvoice,
	getPaymentIntent,
	getChurnkeyAuth,
}
